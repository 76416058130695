import { fetchWrapper } from 'helpers';

/**
 * [API] Запрос на повторную отправку СМС-кода для авторизованного подтверждения документа
 * @param {number} id - ID, полученный в ответе на запрос первичной отправки смс с кодом
 * @return {data<obj>}
 */
export const reSendSmsAPI = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/pinaccept/resend/${id}`;

    const response = await fetchWrapper(url);
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};
