import { createSlice } from '@reduxjs/toolkit';

import { loginApiThunk, loginSmsConfirmAPIThunk } from './thunk';

export const STATES_LOGIN = {
  LOGIN: 'LOGIN',
  LOGIN_SMS_CONFIRM: 'LOGIN_SMS_CONFIRM',
  AUTH: 'AUTH',
};

const initialState = {
  loginData: { data: null },
  loginLoading: {
    state: STATES_LOGIN.LOGIN,
    fetching: false,
    success: false,
    fail: false,
    error: null,
  },
};

const loginSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    clearAction: () => initialState,
  },
  extraReducers: {
    [loginApiThunk.pending]: (state) => {
      state.loginLoading = {
        state: STATES_LOGIN.LOGIN,
        fetching: true,
        success: false,
        fail: false,
        error: null,
      };
    },
    [loginApiThunk.fulfilled]: (state, action) => {
      if (action.payload && action.payload.login && action.payload.group) {
        state.loginLoading = {
          state: STATES_LOGIN.AUTH,
          fetching: false,
          success: true,
          fail: false,
        };
      } else {
        state.loginLoading = {
          state: action.payload ? STATES_LOGIN.LOGIN_SMS_CONFIRM : state.loginLoading.state,
          fetching: false,
          success: true,
          fail: false,
        };
      }
      state.loginData = action.payload;
    },
    [loginApiThunk.rejected]: (state, action) => {
      state.loginLoading = {
        state: STATES_LOGIN.LOGIN,
        fetching: false,
        success: false,
        fail: true,
        error: action.payload,
      };
    },
    [loginSmsConfirmAPIThunk.pending]: (state) => {
      state.loginLoading = {
        state: STATES_LOGIN.LOGIN_SMS_CONFIRM,
        fetching: true,
        success: false,
        fail: false,
        error: null,
      };
    },
    [loginSmsConfirmAPIThunk.fulfilled]: (state, action) => {
      state.loginLoading = {
        state: STATES_LOGIN.AUTH,
        fetching: false,
        success: true,
        fail: false,
      };
      if (window?.ym && !!process.env.REACT_APP_YANDEX_METRIC_ID) {
        window.ym(Number(process.env.REACT_APP_YANDEX_METRIC_ID), 'reachGoal', 'loginSuccessfull');
      }
      state.loginData = action.payload;
    },
    [loginSmsConfirmAPIThunk.rejected]: (state, action) => {
      state.loginLoading = {
        state: STATES_LOGIN.LOGIN_SMS_CONFIRM,
        fetching: false,
        success: false,
        fail: true,
        error: action.payload,
      };
    },
  },
});

export const {
  actions: { clearAction },
} = loginSlice;

const { reducer } = loginSlice;
export { reducer as loginReducer };
