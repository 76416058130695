import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './ManualProgressSteps.scss';

const ManualProgressSteps = ({ activeButton, setStepByNavClick }) => {
  const manualProgressSteps = [
    {
      id: 1,
      title: 'Получение статуса самозанятого',
    },
    {
      id: 2,
      title: (
        <>
          Регистрация
          <br />в кошельке
        </>
      ),
    },
    {
      id: 3,
      title: (
        <>
          Подтверждение статуса
          <br />
          самозанятого в кошельке
        </>
      ),
    },
    {
      id: 4,
      title: (
        <>
          Сотрудничество
          <br />с компаниями
        </>
      ),
    },
  ];

  return (
    <div className="manual-progress-steps">
      {manualProgressSteps.map(item => (
        <div
          className={cx('manual-progress-steps__item', {
            'manual-progress-steps__item_active': activeButton === item.id,
            'manual-progress-steps__item_passed':
              (activeButton === 2 && item.id === 1) ||
              (activeButton === 3 && (item.id === 1 || item.id === 2)) ||
              (activeButton === 4 && (item.id === 1 || item.id === 2 || item.id === 3)),
          })}
          key={item.id}
        >
          <button
            className="manual-progress-steps__item-number"
            type="button"
            onClick={() => setStepByNavClick(item.id)}
          >
            {item.id}
          </button>

          <div className="manual-progress-steps__item-title">{item.title}</div>
        </div>
      ))}
    </div>
  );
};

ManualProgressSteps.propTypes = {
  activeButton: PropTypes.number,
  setStepByNavClick: PropTypes.func.isRequired,
};

ManualProgressSteps.defaultProps = {
  activeButton: 1,
};

export default ManualProgressSteps;
