import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Misc
import { disableRussianLetters, disableSpaceEnter } from 'helpers';

// Icons
import { ReactComponent as IconPasswordClosed } from 'static/assets/icon-password-closed.svg';
import { ReactComponent as IconPasswordOpen } from 'static/assets/icon-password-open.svg';

// Styles
import './PasswordInput.scss';

const PasswordInput = ({
  className,
  onEnterOldPassword,
  onKeyUp,
  placeholder,
  isError,
  value,
  autocomplete,
}) => {
  const [isStartEnteringPassword, setIsStartEnteringPassword] = useState(false);
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);

  /** Check if user started to enter password */
  useEffect(() => {
    if (value && value.length >= 1) {
      setIsStartEnteringPassword(true);
    } else {
      setIsStartEnteringPassword(false);
    }
  }, [value]);

  const handlePasswordVisibilityClick = () => {
    setIsPasswordVisible(prevState => !prevState);
  };

  const handleChange = event => {
    onEnterOldPassword(disableSpaceEnter(disableRussianLetters(event.target.value)));
  };

  return (
    <div
      className={cx('password-input', {
        [className]: className,
      })}
      data-testid="password-input-wrap"
    >
      <input
        type={isPasswordVisible ? 'text' : 'password'}
        maxLength={28}
        className={cx('password-input__itself', {
          'password-input__itself_password-visible': isPasswordVisible,
          'password-input__itself_error': isError,
        })}
        value={value}
        onChange={handleChange}
        onKeyUp={onKeyUp}
        placeholder={placeholder}
        autoComplete={autocomplete}
      />
      {isStartEnteringPassword && (
        <div className="password-input__password-controls">
          <button
            className="password-input__password-visibility"
            type="button"
            onClick={handlePasswordVisibilityClick}
            data-testid="password-input-password-visibility-button"
          >
            {!isPasswordVisible && (
              <IconPasswordClosed className="password-input__password-visibility-icon password-input__password-visibility-icon_closed" />
            )}
            {isPasswordVisible && (
              <IconPasswordOpen className="password-input__password-visibility-icon password-input__password-visibility-icon_opened" />
            )}
          </button>
        </div>
      )}
    </div>
  );
};

PasswordInput.propTypes = {
  className: PropTypes.string,
  placeholder: PropTypes.string,
  onEnterOldPassword: PropTypes.func,
  onKeyUp: PropTypes.func,
  isError: PropTypes.bool,
  autocomplete: PropTypes.string,
  value: PropTypes.string.isRequired,
};

PasswordInput.defaultProps = {
  className: null,
  isError: null,
  autocomplete: null,
  onEnterOldPassword: () => {},
  onKeyUp: () => {},
  placeholder: null,
};

export default PasswordInput;
