import React from 'react';
import { Switch, Route } from 'react-router-dom';

import ShowCase from 'modules/cashbox/components/ShowCase/ShowCase';

/**
 * ШАБЛОН:ВИТРИНА
 */
export const ShowCaseTemplate = () => (
  <Switch>
    {/* РЕЗУЛЬТАТ ПЕЕРЕВОДА ДЕНЕГ НА ВИТРИНУ */}
    <Route
      exact
      path={[
        '/pay-it/cashbox/:operationId/wait',
        '/pay-it/cashbox/:operationId/error/:showCaseId',
        '/pay-it/cashbox/error',
        '/pay-it/cashbox/:showCaseId/error',
        '/pay-it/cashbox/deal/:registeredCheckId/:registeredCheckHash/:showCaseId',
        '/pay-it/cashbox/:operationId/not-register-deal/:showCaseId',
      ]}
    >
      <ShowCase />
    </Route>
    {/* РЕЗУЛЬТАТ ПЕЕРЕВОДА ДЕНЕГ НА ВИТРИНУ */}

    <Route path="/kassa/:showCaseId">
      <ShowCase />
    </Route>

    <Route path="/cashbox/:showCaseId">
      <ShowCase />
    </Route>
  </Switch>
);
