import React from 'react';

const HintWhyCheckIsSoLong = () => (
  <div className="hint-auth__content-text">
    Если в течение нескольких минут не пришел запрос в приложение &quot;Мой Налог&quot;, то возможно
    указан чужой ИНН и запрос отправлен другому лицу. Если указан не ваш ИНН, то отправьте
    правильный запрос, нажав на иконку &quot;карандаш&quot;. Попробуйте найти платформу
    самозанятые.рф в списке партнеров ФНС и предоставить для нее все права или отправьте запрос
    повторно
  </div>
);

export default HintWhyCheckIsSoLong;
