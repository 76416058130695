/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { LineText, TitleText } from 'ui';

import { PTAccountType } from '../../../../utils/propTypes';
import { ValueOfKeyList } from '../../../ui';

const groupName = {
  REGISTERED: 'Неавторизованная',
  EDO: 'Доступ к ЭДО',
  AUTHORIZED: 'Авторизованная',
  IDENTIFIED: 'Идентифицированная',
};

export const AccountInfo = ({ groupType, optionsLimit }) => (
  <WraperMainStyled>
    <WraperTitleStyled>
      <LineText width="rubik-regular" color="deep-sky-blue" size="12px">
        Получите статус записи
      </LineText>
      <TitleTextStyled>{groupName[groupType]}</TitleTextStyled>
    </WraperTitleStyled>
    <WraperTableStyled>
      <ValueOfKeyListStyled list={optionsLimit} />
    </WraperTableStyled>
  </WraperMainStyled>
);

AccountInfo.propTypes = {
  groupType: PTAccountType.isRequired,
  optionsLimit: PropTypes.arrayOf(
    PropTypes.shape({ key: PropTypes.string, value: PropTypes.string }),
  ).isRequired,
};

const WraperMainStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background: #f2f8ff;
  border-radius: 8px;
  width: 100%;
  margin-bottom: 30px;
  padding: 18px 20px;
  @media (max-width: 1250px) {
    flex-direction: column;
  }
  * > {
    margin-right: 59px;
  }
`;

const WraperTitleStyled = styled.div`
  display: flex;
  flex-direction: column;
  padding-right: 40px;
  @media (max-width: 1250px) {
    margin-bottom: 12px;
  }
`;

const TitleTextStyled = styled(TitleText)`
  margin-top: 4px;
`;

const WraperTableStyled = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media (max-width: 715px) {
    flex-direction: column;
  }
`;

const ValueOfKeyListStyled = styled(ValueOfKeyList)`
  margin-bottom: 0;
  margin-right: 40px;
  &:last-of-type {
    margin-right: 0;
  }
`;
