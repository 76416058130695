import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './AcquiringTimeLineStep.scss';

const AcquiringTimeLineStep = ({ text, background, date, width, isLastStep }) => {
  return (
    <div
      className={cx('acquiring-time-line-step', {
        'acquiring-time-line-step_is-last': isLastStep,
      })}
      style={{ width: isLastStep ? width : `${width}%` }}
      data-testid="acquiring-time-line-step"
    >
      <div className="acquiring-time-line-step__time-line">
        <div
          className="acquiring-time-line-step__circle"
          style={{ background: isLastStep ? background : 'var(--turquoise-blue)' }}
          data-testid="acquiring-time-line-step-circle"
        />
        {!isLastStep && (
          <div
            className="acquiring-time-line-step__line"
            style={{ background: background }}
            data-testid="acquiring-time-line-step-line"
          />
        )}
      </div>

      <div className="acquiring-time-line-step__text-wrap">
        <div className="acquiring-time-line-step__title">{text}</div>
        <div className="acquiring-time-line-step__date">{date}</div>
      </div>
    </div>
  );
};

AcquiringTimeLineStep.propTypes = {
  text: PropTypes.oneOfType([PropTypes.string, PropTypes.shape()]),
  background: PropTypes.string.isRequired,
  date: PropTypes.string,
  width: PropTypes.number.isRequired,
  isLastStep: PropTypes.bool.isRequired,
};

AcquiringTimeLineStep.defaultProps = {
  text: null,
  date: null,
};

export default AcquiringTimeLineStep;
