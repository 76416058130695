/*
	Базовый компонент для всех карточек
*/

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import './Card.scss';

const Card = ({ children, className, style }) => (
  <div className={cx('money-transfer-card', className)} style={style}>
    {children}
  </div>
);

Card.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  style: PropTypes.object,
};

export default Card;
