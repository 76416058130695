/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { getFont } from 'tools';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTChildrenElement, PTClassNames, PTColor, PTCWeight } from 'tools/types/propTypes';

export const LineText = ({ children, color, className, weight, size }) => (
  <TextStyled size={size} color={color} weight={weight} className={className}>
    {children}
  </TextStyled>
);

LineText.propTypes = {
  children: PTChildrenElement.isRequired,
  weight: PTCWeight,
  color: PTColor,
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.string,
  className: PTClassNames,
};

LineText.defaultProps = {
  weight: WEIGHT['circe-regular'],
  color: COLOR['dark-slate-blue'],
  className: null,
};

export const LineTextMixin = css`
  color: var(--${({ color }) => color});
  ${({ size }) => (size ? `font-size: ${size};` : '')}
  ${({ weight }) => getFont(weight)}
`;

const TextStyled = styled.span`
  ${LineTextMixin}
`;
