import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Popup from 'modules/shared/components/Popup';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import { logOut } from 'modules/authLegasy/actions';
import styles from './PopupSuccess.module.scss';

const PopupSuccess = ({ dispatch }) => {
  React.useEffect(() => {
    /** Разлогинить пользователя, когда этот компонент будет размонтирован. */
    return () => {
      dispatch(logOut());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Popup mobileOnly={true}>
      <div className={styles.popupSuccess}>
        <h1 className={styles.popupSuccessTitle}>До новых встреч</h1>
        <div className={styles.popupSuccessContent}>
          <p>Вы успешно удалили Кошелек. Его данные и денежные переводы больше недоступны.</p>
        </div>
        <StandardButton
          className={styles.popupSuccessButton}
          isLink={true}
          href="/"
          title="На главную"
        />
      </div>
    </Popup>
  );
};

PopupSuccess.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(PopupSuccess);
