import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { animated, useSpring } from 'react-spring';

import StatusBadge from 'modules/acquiring/components/StatusBadge/StatusBadge';

// Styles
import './ProfileSettingsNav.scss';

export const ProfileSettingsNav = ({ className, activeTab, topList, settingsList, shopStatus }) => {
  const transY = (y) => `translateY(${y}px)`;
  const windowSize = useWindowSize();

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <animated.div
      className={cx('profile-settings-nav', {
        [className]: className,
      })}
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate(transY),
      }}
    >
      <div className="profile-settings-nav__block">
        <div className="profile-settings-nav__list">
          {topList.map((item) => (
            <div key={item.id} className="profile-settings-nav__item-wrap">
              <button
                className={cx('profile-settings-nav__item', {
                  'profile-settings-nav__item_active': activeTab === item.id,
                })}
                type="button"
                data-testid="profile-settings-nav-item"
                onClick={item.onClick}
              >
                <div className="profile-settings-nav__item-icon-wrap">
                  {activeTab === item.id ? item.activeIcon : item.icon}
                </div>

                <p className="profile-settings-nav__item-title">{item.title}</p>
              </button>
            </div>
          ))}
        </div>
      </div>
      {!!settingsList && (
        <div className="profile-settings-nav__block">
          <p className="profile-settings-nav__block-title">Настройки</p>

          <div className="profile-settings-nav__list">
            {settingsList?.map((item) => (
              <div key={item.id} className="profile-settings-nav__item-wrap">
                <button
                  className={cx('profile-settings-nav__item', {
                    'profile-settings-nav__item_active': activeTab === item.id,
                  })}
                  type="button"
                  data-testid="profile-settings-nav-item"
                  onClick={item.onClick}
                >
                  <div className="profile-settings-nav__item-icon-wrap">
                    {activeTab === item.id ? item.activeIcon : item.icon}
                  </div>

                  <p className="profile-settings-nav__item-title">{item.title}</p>
                </button>

                {shopStatus && item.title === 'Статус магазина' && windowSize.innerWidth > 1100 && (
                  <StatusBadge
                    status={shopStatus}
                    className="profile-settings-nav__item-sop-status"
                  />
                )}
              </div>
            ))}
          </div>
        </div>
      )}
    </animated.div>
  );
};

ProfileSettingsNav.propTypes = {
  className: PropTypes.string,
  activeTab: PropTypes.string.isRequired,
  topList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.shape({}),
      activeIcon: PropTypes.shape({}),
      onClick: PropTypes.func,
    }),
  ).isRequired,
  settingsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      title: PropTypes.string,
      icon: PropTypes.shape({}),
      activeIcon: PropTypes.shape({}),
      onClick: PropTypes.func,
    }),
  ),
  shopStatus: PropTypes.string,
};

ProfileSettingsNav.defaultProps = {
  className: null,
  shopStatus: null,
  settingsList: null,
};

export default ProfileSettingsNav;
