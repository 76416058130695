const downloadFile = (blob, file, openInTab) => {
  const link = document.createElement('a');

  link.href = URL.createObjectURL(blob);
  link.rel = 'nofollow noopener';

  if (!openInTab) {
    link.download = file;
  } else {
    link.target = '_blank';
  }

  link.click();
};

export default downloadFile;
