import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';
import useWindowSize from '@rehooks/window-size';
import { CSSTransition } from 'react-transition-group';

import WarningText from 'modules/profileLegasy/components/Warning/WarningText';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import profileBg from 'static/assets/profile-bg.png';
import penImg from 'static/assets/pen-img.png';
import { ReactComponent as LogoGosuslugi } from 'static/assets/gosuslugi-logo-desktop.svg';
import { ReactComponent as LogoGosuslugiMobile } from 'static/assets/gosuslugi-logo-mobile.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';

const AuthStatusGosUslugiFail = ({ className, onEnterPassportData, resetAuthStep }) => {
  const windowSize = useWindowSize();

  const [isRendered, setIsRendered] = useState(false);

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 100);
  }, []);

  return (
    <div
      className={cx('auth-status', {
        [className]: className,
      })}
      data-testid="auth-status-gosuslugi"
    >
      {/* ХЭДЕР */}
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="auth-status__header animation-from-top-to-bottom">
          <div className="auth-status__header-container container container_small">
            <div className="auth-status__header-content">
              <h1 className="auth-status__header-title">
                Не удалось подтвердить данные через ГосУслуги
              </h1>

              {windowSize.innerWidth > 980 && (
                <div className="auth-status__header-desc">
                  Мы не смогли подтвердить вашу личность. Попробуйте выбрать другой способ или
                  повторите попытку
                  <IconInfo className="auth-status__header-desc-warning" />
                </div>
              )}

              {windowSize.innerWidth < 980 && (
                <div className="auth-status__header-desc">
                  Мы не смогли подтвердить вашу личность
                </div>
              )}
            </div>
          </div>

          <img src={profileBg} alt="Profile bg" className="auth-status__header-bg" />

          {/* КНОПКА ЗАКРЫТИЯ - ПЕРЕХОД НА ПРОФИЛЬ */}
          <Link
            to="/lk/profile-settings/account-type"
            className="auth-status__header-close"
            onClick={resetAuthStep}
            data-testid="return-to-profile-settings"
          >
            <IconClose className="auth-status__header-close-icon" />
          </Link>
          {/* /КНОПКА ЗАКРЫТИЯ - ПЕРЕХОД НА ПРОФИЛЬ */}
        </div>
      </CSSTransition>
      {/* /ХЭДЕР */}

      {/* ОСНОВНОЙ КОНТЕНТ */}
      <div className="auth-status__content container container_small">
        {/* ВОРНИНГ - ПРИ ШИРИНЕ ЭКРАНА < 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth < 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <WarningText text="Попробуйте выбрать другой способ или повторите попытку" />
        </CSSTransition>
        {/* /ВОРНИНГ - ПРИ ШИРИНЕ ЭКРАНА < 980 */}

        <div className="auth-status__ways-list">
          {/* ПЕРЕХОД НА ПРОХОЖДЕНИЕ АУТЕНТИФИКАЦИИ ЧЕРЕЗ АНКЕТУ */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <button
              className="auth-status__ways-item"
              type="button"
              onClick={onEnterPassportData}
              data-testid="go-to-fill-profile-button"
            >
              <p className="auth-status__ways-item-title">Заполнить анкету</p>
              <div className="auth-status__ways-item-desc">
                Запрос на проверку может обрабатываться до 24 часов
              </div>
              <img
                src={penImg}
                alt=""
                className="auth-status__ways-item-img auth-status__ways-item-img_pen"
              />
            </button>
          </CSSTransition>
          {/* /ПЕРЕХОД НА ПРОХОЖДЕНИЕ АУТЕНТИФИКАЦИИ ЧЕРЕЗ АНКЕТУ */}

          {/* ПЕРЕХОД НА ПРОХОЖДЕНИЕ АУТЕНТИФИКАЦИИ ЧЕРЕЗ ГОСУСЛУГИ */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <button className="auth-status__ways-item" type="button">
              <p className="auth-status__ways-item-title">Через ГосУслуги</p>
              <div className="auth-status__ways-item-desc">
                Самый быстрый способ, если у вас уже есть подтвержденная учетная запись на портале
              </div>

              {windowSize.innerWidth > 980 && (
                <LogoGosuslugi className="auth-status__ways-item-img auth-status__ways-item-img_gosuslugi" />
              )}
              {windowSize.innerWidth < 980 && (
                <LogoGosuslugiMobile className="auth-status__ways-item-img auth-status__ways-item-img_gosuslugi" />
              )}
            </button>
          </CSSTransition>
          {/* /ПЕРЕХОД НА ПРОХОЖДЕНИЕ АУТЕНТИФИКАЦИИ ЧЕРЕЗ ГОСУСЛУГИ */}
        </div>
      </div>
      {/* /ОСНОВНОЙ КОНТЕНТ */}
    </div>
  );
};

AuthStatusGosUslugiFail.propTypes = {
  className: PropTypes.string.isRequired,
  onEnterPassportData: PropTypes.func.isRequired,
  resetAuthStep: PropTypes.func.isRequired,
};

export default AuthStatusGosUslugiFail;
