import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { getUser, setMenuOpen } from 'modules/authLegasy/actions';
import TransferResult from 'modules/profileLegasy/components/TransferResult/TransferResult';
import BlockedUserText from 'modules/shared/components/BlockedUserText/BlockedUserText';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CircleWithEmoji from 'modules/shared/components/CircleWithEmoji/CircleWithEmoji';
import PayedInvoiceText from 'modules/shared/components/PayedInvoiceText/PayedInvoiceText';
import PaymentBox from 'modules/shared/components/PaymentBox/PaymentBox';
import TransferResultErrorMessage from 'modules/shared/components/TransferResultErrorMessage/TransferResultErrorMessage';

import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import emojiPuzzled from 'static/assets/icon-emoji-puzzled.png';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconMasterCard } from 'static/assets/icon-mastercard.svg';
import { ReactComponent as IconMir } from 'static/assets/icon-mir.svg';
import { ReactComponent as IconPci } from 'static/assets/icon-pci.svg';
import { ReactComponent as IconVisa } from 'static/assets/icon-visa-verified.svg';
import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';
import { ReactComponent as IconLogoSmall } from 'static/assets/logo-small-white.svg';
import profileBg from 'static/assets/profile-bg.png';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './MoneyRequestLink.scss';
import { getMoneyRequestTransactionOperationStatus } from '../../actions';
import { WindowUI } from '../../../../ui';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';

const MoneyRequestLink = ({
  user,
  dispatch,
  isMenuOpen,
  className,
  opData,
  requestId,
  userAvatar,
  isMoneyTransferOk,
  isMoneyTransferFailed,
  isSending,
  isUserBlocked,
  isReplenishmentBlocked,
  isNotAvailable,
  isPayed,
}) => {
  const windowSize = useWindowSize();
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isIframeSBPLoading, setIsIframeSBPLoading] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [showIframeSBP, setShowIframeSBP] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToErrorPage, setRedirectToErrorPage] = useState(false);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(null);
  const [isOpenPopupIsNotAvailable, setOpenPopupIsNotAvailable] = useState(false);

  useEffect(() => {
    if (window && window.sessionStorage) {
      const localErrorMessage =
        window && window.sessionStorage
          ? window.sessionStorage.getItem('transactionStateMessage')
          : null;
      if (localErrorMessage) {
        setErrorMessage(localErrorMessage);
        window.sessionStorage.removeItem('transactionStateMessage');
      }
    }
  }, []);

  const onPayByCard = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    setShowIframe(true);
    setIsIframeLoading(true);
  };

  const onPayBySBP = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    setShowIframeSBP(true);
    setIsIframeSBPLoading(true);
  };

  const resetPay = () => {
    setShowIframe(false);
    setIsIframeLoading(false);
    setShowIframeSBP(false);
    setIsIframeSBPLoading(false);
  };

  const updateTimeout = useRef(null);
  const updateOperationStatus = useCallback(
    (operationId) => {
      if (!operationId) {
        clearTimeout(updateTimeout.current);
        return;
      }

      dispatch(getMoneyRequestTransactionOperationStatus(operationId))
        .then((res) => {
          if (res && res.data) {
            if (res.data.state === STATUS_WAIT) {
              updateTimeout.current = setTimeout(() => updateOperationStatus(operationId), 1000);
            } else if (res.data.state === STATUS_ERROR) {
              setRedirectToErrorPage(true);
            } else {
              const url = `/pay-it/money/${operationId}/success`;
              setRedirectToSuccessPage(url);
              dispatch(getUser()).catch(() => {});
            }

            if (res.data.transactionStateMessage) {
              window.sessionStorage.setItem(
                'transactionStateMessage',
                res.data.transactionStateMessage,
              );
            }
          }
        })
        .catch(() => {
          setRedirectToErrorPage(true);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const stateListener = (e) => {
      if (!process.env.REACT_APP_PCI_DSS_IFRAME.includes(e.origin)) return;
      if (e.data.operationId) {
        clearTimeout(updateTimeout?.current);
        updateOperationStatus(e.data.operationId);
      } else if (e.data.linkUrl) {
        window.open(e.data.linkUrl, e.data.target || '_blank');
      }
    };
    if (showIframeSBP) {
      window.addEventListener('message', stateListener);
    }
    return () => {
      window.removeEventListener('message', stateListener);
      clearTimeout(updateTimeout?.current);
    };
  }, [showIframeSBP, updateOperationStatus, dispatch]);

  /** Устанавливаем isMenuOpen при клике на бургер */
  const handleMenuOpenClick = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  if (redirectToErrorPage) {
    return <Redirect to="/pay-it/money/error" />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  return (
    <>
      {isOpenPopupIsNotAvailable && isNotAvailable && (
        <WindowUI.WindowUniversalError
          header={'Ошибка оплаты'}
          text={'Оплата невозможна из-за превышения лимитов у получателя.'}
          buttonText={'Хорошо'}
          onClose={() => {
            setOpenPopupIsNotAvailable(false);
          }}
        />
      )}

      <div
        className={cx('money-request-link', {
          [className]: className,
        })}
      >
        {/* ХЭДЕР */}
        <div
          className={cx('money-request-link__header', {
            'money-request-link__header_menu-open': isMenuOpen,
          })}
        >
          {/* НАВИГАЦИЯ С ЛОКАЛЬНЫМ МЕНЮ БУРГЕРОМ ДЛЯ ШИРИНЫ ЭКРАНА < 980 */}
          {windowSize.innerWidth <= 980 && (
            <div className={cx('money-request-link__top-nav')}>
              {user ? (
                <>
                  {showIframe || showIframeSBP ? (
                    <div className="money-request-link__back-button" onClick={resetPay}>
                      <IconArrow
                        className={cx('money-request-link__back-button-icon', {
                          'money-request-link__back-button-icon_menu-open': isMenuOpen,
                        })}
                      />
                    </div>
                  ) : (
                    <Link className="money-request-link__back-button" to="/">
                      <IconArrow
                        className={cx('money-request-link__back-button-icon', {
                          'money-request-link__back-button-icon_menu-open': isMenuOpen,
                        })}
                      />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {showIframe || showIframeSBP ? (
                    <div className="money-request-link__back-button" onClick={resetPay}>
                      <IconArrow
                        className={cx('money-request-link__back-button-icon', {
                          'money-request-link__back-button-icon_menu-open': isMenuOpen,
                        })}
                      />
                    </div>
                  ) : (
                    <Link className="money-request-link__whiteLogoWrap" to="/">
                      <IconLogoSmall />
                    </Link>
                  )}
                </>
              )}

              <div className="money-request-link__top-nav-right">
                <button
                  className={cx('money-request-link__burger', {
                    'money-request-link__burger_menu-open': isMenuOpen,
                  })}
                  type="button"
                  onClick={handleMenuOpenClick}
                >
                  <IconLine1 className="money-request-link__burger-span" />
                  <IconLine2 className="money-request-link__burger-span" />
                  <IconLine3 className="money-request-link__burger-span" />
                </button>
              </div>
            </div>
          )}
          {/* /НАВИГАЦИЯ С ЛОКАЛЬНЫМ МЕНЮ БУРГЕРОМ ДЛЯ ШИРИНЫ ЭКРАНА < 980 */}

          {windowSize.innerWidth > 980 && (showIframe || showIframeSBP) && (
            <div className={cx('money-request-link__top-nav')}>
              <div className="money-request-link__back-button" onClick={resetPay}>
                <IconArrow
                  className={cx('money-request-link__back-button-icon', {
                    'money-request-link-icon_menu-open': false,
                  })}
                />{' '}
                <span className="money-request-link__back-button-text">Назад</span>
              </div>
            </div>
          )}

          {!isMenuOpen && (
            <img
              src={profileBg}
              alt="Фирменный бэкгранунд Самозанятые.рф"
              className="money-request-link__header-bg"
            />
          )}
        </div>
        {/* /ХЭДЕР */}

        {/* КОНТЕНТ */}
        <div className="money-request-link__content">
          {/* АВАТАР */}
          {(isUserBlocked || isReplenishmentBlocked) && (
            <CircleWithEmoji
              emoji={emojiSad}
              className="money-request-link__avatar-wrap money-request-link__avatar-money-request-link-to-pay"
            />
          )}

          {isPayed && (
            <CircleWithEmoji
              emoji={emojiPuzzled}
              className="money-request-link__avatar-wrap money-request-link__avatar-money-request-link-to-pay"
            />
          )}

          {!isUserBlocked && !isPayed && !isReplenishmentBlocked && (
            <div className="money-request-link__avatar-wrap money-request-link__avatar-money-request-link-to-pay">
              <img
                src={userAvatar ? userAvatar : stdAvatar}
                alt="Аватар юзера"
                className="money-request-link__avatar"
              />
            </div>
          )}

          {/* /АВАТАР */}

          {/* ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
          {!isSending &&
            !isMoneyTransferOk &&
            !isMoneyTransferFailed &&
            !isUserBlocked &&
            !isReplenishmentBlocked &&
            !isPayed && (
              <>
                <p className="money-request-link__content-text">
                  {`Перевод пользователю на кошелек *${opData.loginLastChars}`}{' '}
                </p>

                {showIframe && (
                  <div className="money-request-link__iframe-wrap">
                    {isIframeLoading && (
                      <div className="money-request-link__iframe-loader">
                        <CircleLoader />
                      </div>
                    )}

                    <iframe
                      title="MoneyRequest"
                      className="money-request-link__iframe"
                      src={`${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/pay-it-money?account=${
                        user ? user.login : 'anonymous'
                      }&payItUID=${requestId}&amount${opData.sum}`}
                      frameBorder="0"
                      onLoad={() => {
                        setIsIframeLoading(false);
                      }}
                    />
                  </div>
                )}

                {/* IFRAME PSI DSS SBP*/}
                {showIframeSBP && (
                  <div className="money-request-link__iframe-wrap-sbp">
                    {isIframeSBPLoading && (
                      <div className="money-request-link__iframe-loader">
                        <CircleLoader />
                      </div>
                    )}

                    <iframe
                      title="MoneyRequest"
                      className="money-request-link__iframe"
                      src={`${
                        process.env.REACT_APP_PCI_DSS_IFRAME
                      }iframe/pay-it-money-sbp?account=${
                        user ? user.login : 'anonymous'
                      }&payItUID=${requestId}&amount${opData.sum}`}
                      frameBorder="0"
                      onLoad={() => {
                        setIsIframeSBPLoading(false);
                      }}
                    />
                  </div>
                )}
                {/* /IFRAME PSI DSS SBP*/}

                {!showIframe && !showIframeSBP && (
                  <>
                    {/* ИНФОРМАЦИЯ О ЦЕЛЯХ ПЛАТЕЖА */}
                    <div className="money-request-link__info">
                      <p className="money-request-link__info-title">Вы совершаете перевод на:</p>

                      <div className="money-request-link__info-purpose">{opData.commentary}</div>

                      <div className="money-request-link__info-sum">
                        {formatNumber(opData.sum / 100)} ₽
                      </div>
                    </div>
                    {/* /ИНФОРМАЦИЯ О ЦЕЛЯХ ПЛАТЕЖА */}

                    {/* КНОПКА ОТПРАВКИ ПЕРЕВОДА */}
                    <div className="money-request-link__btn-wrap">
                      <PaymentBox
                        isOneColumn
                        amount={opData.sum / 100 || 0}
                        isDisabled={!opData.sum}
                        onPayByCard={onPayByCard}
                        onPayBySBP={onPayBySBP}
                        hideCard
                        hideApplePay
                        hideSamsungPay
                      />
                    </div>
                    {/* /КНОПКА ОТПРАВКИ ПЕРЕВОДА */}
                  </>
                )}

                {/* ССЫЛКА НА ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ПРИ ЭКРАНЕ > 980 */}
                {windowSize.innerWidth > 980 && (
                  <div className="money-request-link__attention-text">
                    Обратите внимание: Деньги поступят в личный кошелёк получателя, дальше всё
                    зависит от его добросовестности. Вы платите человеку, а не компании
                  </div>
                )}
                {/* /ССЫЛКА НА ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ПРИ ЭКРАНЕ > 980 */}
              </>
            )}
          {/* /ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}

          {isUserBlocked && <BlockedUserText />}

          {isReplenishmentBlocked && (
            <BlockedUserText
              title={'Пополнение кошелька заблокировано'}
              desc={'Пополнение кошелька заблокировано и нельзя совершить платеж.'}
            />
          )}

          {isPayed && <PayedInvoiceText />}

          {/* ЛОАДЕР ИЛИ РЕЗУЛЬТАТ */}
          {(isSending || isMoneyTransferOk || isMoneyTransferFailed) && (
            <TransferResult
              isMoneyTransferOk={isMoneyTransferOk}
              isMoneyTransferFailed={isMoneyTransferFailed}
              isLoading={isSending}
              linkToPayAgainPage={requestId && `/pay-it/${requestId}`}
              textForBayAgainButton={requestId && 'Вернуться к запросу на деньги'}
            >
              {/* ЛОАДЕР */}
              {isSending && (
                <div className="transfer-result__text">
                  Платеж обрабатывается. Пожалуйста подождите, это может занять некоторое время.
                </div>
              )}
              {/* /ЛОАДЕР */}

              {/* ОПЛАТА ПРОШЛА УСПЕШНО */}
              {isMoneyTransferOk && (
                <div className="transfer-result__text">
                  Оплата прошла успешно. Пользователь получит средства в свой кошелек
                </div>
              )}
              {/* /ОПЛАТА ПРОШЛА УСПЕШНО */}

              {/* ОПЛАТА НЕ ПРОШЛА */}
              {isMoneyTransferFailed && <TransferResultErrorMessage errorMessage={errorMessage} />}
              {/* /ОПЛАТА НЕ ПРОШЛА */}
            </TransferResult>
          )}
          {/* /ЛОАДЕР ИЛИ РЕЗУЛЬТАТ */}
        </div>
        {/* /ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
      </div>

      {/* ИКОНКИ СОПСОБОВ ОПЛАТЫ И ССЫЛКИ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
      {!isMoneyTransferOk &&
        !isMoneyTransferFailed &&
        !isSending &&
        !isUserBlocked &&
        !isReplenishmentBlocked &&
        !showIframeSBP && (
          <>
            <div className="money-request-container__payment-ways">
              <IconVisa className="money-request-container__payment-ways-item money-request-container__payment-ways-item_visa" />
              <IconMasterCard className="money-request-container__payment-ways-item money-request-container__payment-ways-item_msc" />
              <IconMir className="money-request-container__payment-ways-item money-request-container__payment-ways-item_mir" />
              <IconPci className="money-request-container__payment-ways-item bank-card-payment__payment-ways-item_pci" />
            </div>

            {windowSize.innerWidth <= 980 && !isPayed && (
              <div className="money-request-link__attention-text">
                Обратите внимание: Деньги поступят в личный кошелёк получателя, дальше всё зависит
                от его добросовестности. Вы платите человеку, а не компании
              </div>
            )}

            {!isPayed && (
              <>
                <div className="money-request-container__info">
                  Совершая платеж, вы соглашаетесь с{' '}
                  <a
                    href="https://самозанятые.рф/legal/walletoffer.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="money-request-container__info-link"
                  >
                    публичной офертой
                  </a>
                  ,{' '}
                  <a
                    href="https://самозанятые.рф/legal/walletterms.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="money-request-container__info-link"
                  >
                    пользовательским соглашением
                  </a>
                  <span> и </span>
                  <a
                    href={`${process.env.REACT_APP_URL}privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="money-request-container__info-link"
                  >
                    политикой конфиденциальности
                  </a>
                </div>
              </>
            )}
          </>
        )}
    </>
  );
};

MoneyRequestLink.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    login: PropTypes.string,
  }),
  isMenuOpen: PropTypes.bool.isRequired,
  userAvatar: PropTypes.string,
  opData: PropTypes.shape({
    avatarInBase64: PropTypes.string,
    commentary: PropTypes.string,
    currency: PropTypes.string,
    loginLastChars: PropTypes.string,
    sum: PropTypes.number,
  }),
  requestId: PropTypes.string,
  isMoneyTransferOk: PropTypes.bool,
  isMoneyTransferFailed: PropTypes.bool,
  isSending: PropTypes.bool,
  isUserBlocked: PropTypes.bool,
  isReplenishmentBlocked: PropTypes.bool,
  isPayed: PropTypes.bool,
};

MoneyRequestLink.defaultProps = {
  className: null,
  user: null,
  requestId: null,
  opData: null,
  userAvatar: null,
  isMoneyTransferOk: false,
  isMoneyTransferFailed: false,
  isSending: false,
  isUserBlocked: false,
  isReplenishmentBlocked: false,
  isPayed: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(MoneyRequestLink);
