export const setCookie = (name, value, options = {}) => {
  options = {
    path: '/',
    ...options,
  };

  if (options.expires instanceof Date) {
    options.expires = options.expires.toUTCString();
  }

  let updatedCookie = encodeURIComponent(name) + '=' + encodeURIComponent(value);

  for (let optionKey in options) {
    updatedCookie += '; ' + optionKey;
    let optionValue = options[optionKey];
    if (optionValue !== true) {
      updatedCookie += '=' + optionValue;
    }
  }

  document.cookie = updatedCookie;
};

export const getScript = (connectId, methodNameArr = []) => `!(function () {
  function t(t, e) {
    return function () {
      window.carrotquestasync.push(t, arguments);
    };
  }
  if ('undefined' == typeof carrotquest) {
    var e = document.createElement('script');
    (e.type = 'text/javascript'),
      (e.async = !0),
      (e.src = 'https://cdn.carrotquest.app/api.min.js'),
      document.getElementsByTagName('head')[0].appendChild(e),
      (window.carrotquest = {}),
      (window.carrotquestasync = []),
      (carrotquest.settings = {});
    for (
      var n = [${methodNameArr.reduce((acc, curr) => `${acc}"${curr}", `, '')}],
        a = 0;
      a < n.length;
      a++
    )
      carrotquest[n[a]] = t(n[a]);
  }
})(),
  carrotquest.connect("${connectId}");`;
