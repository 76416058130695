import * as types from '../actions/action-types';

const requestStages = {
  fetching: false,
  success: false,
  fail: false,
};

const initialState = {
  isPopupIdentificationRequestVisible: false,
  isPopupFoundMistakeVisible: false,
  isPopupCheckedSuccessVisible: false,
  isPopupDeclineIdentificationVisible: false,

  requestData: {},

  getIdentificationRequestData: { ...requestStages },
  confirmIdentificationRequest: { ...requestStages },
  declineIdentificationRequest: { ...requestStages },
};

const identificationRequestRedicer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_IDENTIFICATION_REQUEST_DATA_FETCHING:
      return {
        ...state,
        getIdentificationRequestData: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_IDENTIFICATION_REQUEST_DATA_SUCCESS:
      return {
        ...state,
        getIdentificationRequestData: {
          fetching: false,
          success: true,
          fail: false,
        },
        requestData: action.payload,
      };

    case types.GET_IDENTIFICATION_REQUEST_DATA_FAIL:
      return {
        ...state,
        getIdentificationRequestData: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CONFIRM_IDENTIFICATION_REQUEST_FETCHING:
      return {
        ...state,
        confirmIdentificationRequest: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.CONFIRM_IDENTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        confirmIdentificationRequest: {
          fetching: false,
          success: true,
          fail: false,
        },
        isNotificationVisible: false,
      };

    case types.CONFIRM_IDENTIFICATION_REQUEST_FAIL:
      return {
        ...state,
        confirmIdentificationRequest: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.DECLINE_IDENTIFICATION_REQUEST_FETCHING:
      return {
        ...state,
        declineIdentificationRequest: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.DECLINE_IDENTIFICATION_REQUEST_SUCCESS:
      return {
        ...state,
        declineIdentificationRequest: {
          fetching: false,
          success: true,
          fail: false,
        },
        isNotificationVisible: false,
      };

    case types.DECLINE_IDENTIFICATION_REQUEST_FAIL:
      return {
        ...state,
        declineIdentificationRequest: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.SHOW_IDENTIFICATION_REQUEST_POPUP:
      return {
        ...state,
        isPopupIdentificationRequestVisible: true,
      };

    case types.HIDE_IDENTIFICATION_REQUEST_POPUP:
      return {
        ...state,
        isPopupIdentificationRequestVisible: false,
      };

    case types.SHOW_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP:
      return {
        ...state,
        isPopupFoundMistakeVisible: true,
      };

    case types.HIDE_IDENTIFICATION_REQUEST_FOUND_MISTAKE_POPUP:
      return {
        ...state,
        isPopupFoundMistakeVisible: false,
      };

    case types.SHOW_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP:
      return {
        ...state,
        isPopupCheckedSuccessVisible: true,
      };

    case types.HIDE_IDENTIFICATION_REQUEST_CHECKED_SUCCESS_POPUP:
      return {
        ...state,
        isPopupCheckedSuccessVisible: false,
      };

    case types.SHOW_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP:
      return {
        ...state,
        isPopupDeclineIdentificationVisible: true,
      };

    case types.HIDE_IDENTIFICATION_REQUEST_DECLINE_IDENTIFICATION_POPUP:
      return {
        ...state,
        isPopupDeclineIdentificationVisible: false,
      };

    case types.RESET_IDENTIFICATION_REQUEST_STATE:
      return {
        ...initialState,
      };

    default:
      return state;
  }
};

export default identificationRequestRedicer;
