import React, { useEffect, useState } from 'react';
import useWindowSize from '@rehooks/window-size';
import { animated, useSpring } from 'react-spring';

import './IntegrationWidget.scss';

import {
  deleteIntegrationToken,
  generateIntegrationToken,
  getIntegrationTokenList,
} from '../../../../api/profile';
import styled from 'styled-components';
import { CircleLoader, LineText, WindowUI } from '../../../../ui';
import StandardButton from '../../../shared/components/StandardButton/StandardButton';
import { formatDateHuman } from '../../../../helpers';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import * as copyClipboard from 'copy-to-clipboard';
import { ReactComponent as IconCopyContentGrey } from 'static/assets/icon-copy-content-grey.svg';
import { ReactComponent as IconTrashGrey } from 'static/assets/icon-trash-grey.svg';
import EmojiOk from 'static/assets/icon-emoji-ok.png';
import EmojiPuzzled from 'static/assets/icon-emoji-puzzled.png';
import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';

export const IntegrationWidget = () => {
  const windowSize = useWindowSize();
  const [tokenList, setTokenList] = useState(null);
  const [isCopied, setIsCopied] = useState(null);
  const [deletePopupToken, setDeletePopupToken] = useState(null);
  const [isDeleteRequestError, setDeleteRequestError] = useState(null);

  const [isAddIntegrationScreen, setAddIntegrationScreen] = useState(false);
  const [isAddIntegrationNameValue, setAddIntegrationNameValue] = useState('');
  const [isAddIntegrationLoading, setAddIntegrationLoading] = useState(false);
  const [isAddIntegrationSuccess, setAddIntegrationSuccess] = useState({
    visible: false,
    token: null,
  });
  const [isAddIntegrationRequestError, setAddIntegrationRequestError] = useState(null);

  const copy = (text) => {
    setIsCopied(text);
  };

  useEffect(() => {
    getIntegrationTokenList().then((tokens) => {
      setTokenList(tokens);
    });
  }, []);

  const closeDeletePopup = () => {
    setDeleteRequestError(null);
    setDeletePopupToken(null);
  };

  const closeAddSuccessPopup = (withCopy = false) => {
    if (withCopy) {
      copyClipboard(isAddIntegrationSuccess.token);
    }
    setAddIntegrationScreen(false);
    setAddIntegrationSuccess({ visible: false, token: null });
    setAddIntegrationNameValue('');
  };

  const generateToken = async (name) => {
    try {
      setAddIntegrationLoading(true);
      setAddIntegrationRequestError(null);
      const result = await generateIntegrationToken(name);
      setAddIntegrationSuccess({ visible: true, token: result.authToken });
      setTokenList(null);
      getIntegrationTokenList().then((tokens) => {
        setTokenList(tokens);
      });
    } catch (e) {
      setAddIntegrationRequestError(e);
    } finally {
      setAddIntegrationLoading(false);
    }
  };

  const deleteToken = async (token) => {
    try {
      await deleteIntegrationToken(token);
      setTokenList(null);
      getIntegrationTokenList().then((tokens) => {
        setTokenList(tokens);
      });
      closeDeletePopup();
    } catch (e) {
      setDeleteRequestError(e);
    }
  };

  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <animated.div
      className="integration-widget"
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate(transY),
      }}
    >
      {isAddIntegrationScreen && (
        <>
          <h2 className="integration-widget__title">Новый токен</h2>

          <div className="integration-widget__input-holder">
            <input
              type="text"
              className="integration-widget__input-name"
              placeholder="Название"
              onChange={(event) => setAddIntegrationNameValue(event.target.value)}
              value={isAddIntegrationNameValue}
              readOnly={isAddIntegrationLoading}
              maxLength={50}
            />
            {<IconEdit className="integration-widget__input-edit" />}
          </div>

          <StandardButton
            title="Добавить"
            onClick={() => {
              generateToken(isAddIntegrationNameValue);
            }}
            disabled={!isAddIntegrationNameValue || isAddIntegrationLoading}
            isLink={false}
            className="integration-widget__btn-add"
          />

          {isAddIntegrationRequestError && (
            <div className="integration-widget__error-add">{isAddIntegrationRequestError}</div>
          )}

          {isAddIntegrationSuccess.visible && (
            <WindowUI.Alert
              header="Токен выпущен"
              emoji={
                <EmojiWrapperStyled>
                  <OkEmojiStyled />
                </EmojiWrapperStyled>
              }
              buttonText="Скопировать"
              onClose={() => closeAddSuccessPopup()}
              onClick={() => closeAddSuccessPopup(true)}
            >
              <LineTextStyled>
                Отлично! Ваш токен добавлен. Вы можете скопировать его.
              </LineTextStyled>
            </WindowUI.Alert>
          )}
        </>
      )}

      {!isAddIntegrationScreen && (
        <>
          {windowSize.innerWidth > 980 && <h2 className="integration-widget__title">Интеграция</h2>}
          <div className="integration-widget__content-wrap">
            <div className="integration-widget__create-container">
              <div className="integration-widget__create-title">
                Здесь вы можете создать интеграцию
              </div>
              <StandardButton
                title="Создать"
                onClick={() => {
                  setAddIntegrationScreen(true);
                }}
                isLink={false}
                className="integration-widget__btn-create"
              />
            </div>

            {!tokenList && <CircleLoaderStyled />}

            {!!tokenList?.length && (
              <div className="integration-widget__bottom-container">
                <div className="integration-widget__divider"></div>
                <div className="integration-widget__list-title">Токены доступа</div>
                <div className="integration-widget__list">
                  {tokenList.map((item, id) => (
                    <div className="integration-widget__list-item" key={item.authToken}>
                      {/* <div className="integration-widget__list-item-field token"> */}
                      {/*   <div className="integration-widget__list-item-name">Токен</div> */}
                      {/*   <div className="integration-widget__list-item-value">{item.authToken}</div> */}
                      {/* </div> */}
                      <div className="integration-widget__list-item-field name">
                        <div className="integration-widget__list-item-name">Описание</div>
                        <div className="integration-widget__list-item-value">{item.name}</div>
                      </div>
                      <div className="integration-widget__list-item-field date">
                        <div className="integration-widget__list-item-name">Дата создания</div>
                        <div className="integration-widget__list-item-value">
                          {formatDateHuman(item.dateAdd)}
                        </div>
                      </div>

                      <div className="integration-widget__list-item-actions">
                        <CopyToClipboard text={item.authToken} onCopy={() => copy(item.authToken)}>
                          <button type="button" className="integration-widget__list-item-btn">
                            <IconCopyContentGrey />
                            {isCopied === item.authToken && (
                              <p className="integration-widget__list-item-copied">Скопировано!</p>
                            )}
                          </button>
                        </CopyToClipboard>

                        <button
                          type="button"
                          className="integration-widget__list-item-btn"
                          onClick={() => setDeletePopupToken(item.authTokenId)}
                        >
                          <IconTrashGrey />
                        </button>
                        {deletePopupToken && (
                          <WindowUI.Confirm
                            header="Вы точно хотите удалить токен?"
                            emoji={
                              <EmojiWrapperStyled>
                                <PuzzledEmojiStyled />
                              </EmojiWrapperStyled>
                            }
                            approveButtonText="Удалить"
                            rejektButtonText="Отмена"
                            onClose={() => closeDeletePopup()}
                            onApprove={() => {
                              deleteToken(deletePopupToken);
                            }}
                            onRejekt={() => closeDeletePopup()}
                          >
                            <LineTextStyled>
                              Вы собираетесь удалить API-токен, если вы продолжите, он будет удален.
                            </LineTextStyled>
                            {isDeleteRequestError && (
                              <LineTextErrorStyled>{isDeleteRequestError}</LineTextErrorStyled>
                            )}
                          </WindowUI.Confirm>
                        )}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            )}
          </div>
        </>
      )}
    </animated.div>
  );
};

const CircleLoaderStyled = styled(CircleLoader)`
  margin: 143px auto;
`;

IntegrationWidget.propTypes = {};

IntegrationWidget.defaultProps = {};

const EmojiWrapperStyled = styled.div`
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PuzzledEmojiStyled = styled.img.attrs(() => ({ src: EmojiPuzzled }))`
  width: 40px;
  height: 40px;
`;

const OkEmojiStyled = styled.img.attrs(() => ({ src: EmojiOk }))`
  width: 40px;
  height: 40px;
`;

const LineTextStyled = styled(LineText)`
  text-align: center;
  color: var(--bluey-grey);
`;

const LineTextErrorStyled = styled(LineTextStyled)`
  color: #ff2d55;
  text-align: center;
  margin-top: 16px;
`;
