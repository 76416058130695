import React from 'react';
import { Link } from 'react-router-dom';

const HintWhyRegisterIncome = () => {
  return (
    <>
      <div className="hint__text-wrap">
        <div className="hint__text">
          <p>
            Если вы состоите на учете ФНС, как плательщик налога на профессиональный доход, то вам
            необходимо регистрировать полученный доход от коммерческой деятельности.
          </p>

          <p>
            В этом разделе, вы можете легко зарегистрировать доход, сформировать чек и отправить его
            покупателю. Полученные данные будут сразу переданы в ФНС. Используйте этот раздел, если
            принимаете оплату напрямую (наличными или на любую карту). А если хотите создать ссылку
            на оплату с регистрацией дохода в ФНС, при этом покупатель получает чек автоматически
            после оплаты, тогда воспользуйтесь разделом -
            <Link to="/lk/bill-creation" className="hint__text-link">
              «Выставить счет»
            </Link>
          </p>
        </div>
      </div>
    </>
  );
};

export default HintWhyRegisterIncome;
