import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { ReactComponent as IconCopy } from 'static/assets/icon-copy-content.svg';

import './CopiedUrlHover.scss';

const CopiedUrlHover = ({ isVisible, className, title }) => {
  return (
    <div
      className={cx('copied-url-hover', {
        'copied-url-hover_open': isVisible,
        [className]: className,
      })}
      data-testid="copied-url-hover"
    >
      <IconCopy className="copied-url-hover__icon" />
      <p className="copied-url-hover__text">{title || 'Ссылка скопирована'}</p>
    </div>
  );
};

CopiedUrlHover.propTypes = {
  isVisible: PropTypes.bool.isRequired,
  className: PropTypes.string,
  title: PropTypes.string,
};

CopiedUrlHover.defaultProps = {
  className: null,
  title: null,
};

export default CopiedUrlHover;
