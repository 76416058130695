import { fetchWrapper } from 'helpers';

export const getDocumentList = async (params, page = 1) => {
  let url = `${process.env.REACT_APP_API_URL}/partnerships/documents?`;
  url += new URLSearchParams({
    ...params,
    pageSize: 50,
    page,
  });

  const response = await fetchWrapper(url);

  return response.data;
};

export const generateIntegrationToken = async (name) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/external-integration/v1/token`;
    const body = {
      tokenName: name,
    };
    const response = await fetchWrapper(url, { method: 'POST', body: JSON.stringify(body) });
    return response.data;
  } catch (error) {
    throw error?.result?.message || error;
  }
};

export const getIntegrationTokenList = async (params, page = 1) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/external-integration/v1/tokens?`;
    url += new URLSearchParams({
      ...params,
      pageSize: 10,
      page,
    });

    const response = await fetchWrapper(url);
    return response.data;
  } catch (e) {
    return [];
  }
};

export const getTaxHoldList = async (
  params,
  page = 1,
): Promise<
  {
    amount: number,
    comment: string,
    idOperation: number,
    legal: { id: number, fullName: string, inn: string },
    platform: string,
    registrationDate: string,
    status: number,
    taxEstimated: number,
  }[],
> => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/partnerships/tax-charges?`;
    url += new URLSearchParams({
      ...params,
      pageSize: 10,
      page,
    });

    const response = await fetchWrapper(url);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const getTaxHoldValue = async (): Promise<{ amount: number }> => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/partnerships/tax-hold-amount`;
    const response = await fetchWrapper(url);
    return response.data;
  } catch (e) {
    throw e;
  }
};

export const deleteIntegrationToken = async (tokenId) => {
  try {
    let url = `${process.env.REACT_APP_API_URL}/external-integration/v1/token`;
    const body = {
      authTokenId: tokenId,
      blocked: true,
    };
    const response = await fetchWrapper(url, { method: 'PATCH', body: JSON.stringify(body) });
    return response.data;
  } catch (error) {
    throw error?.result?.message || error;
  }
};
