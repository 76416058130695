import React from 'react';

import { ReactComponent as IconAuthFilled } from 'static/assets/profile-settings/auth-filled.svg';
import { ReactComponent as IconAuthOutline } from 'static/assets/profile-settings/auth-outline.svg';
import { ReactComponent as IconBriefcaseFilled } from 'static/assets/profile-settings/briefcase-filled.svg';
import { ReactComponent as IconBriefcaseOutline } from 'static/assets/profile-settings/briefcase-outline.svg';
import { ReactComponent as IconFileFilled } from 'static/assets/profile-settings/file-filled.svg';
import { ReactComponent as IconFileOutline } from 'static/assets/profile-settings/file-outline.svg';
import { ReactComponent as IconTaxHoldOutline } from 'static/assets/profile-settings/tax-outline.svg';
import { ReactComponent as IconTaxHoldFilled } from 'static/assets/profile-settings/tax-filled.svg';
// import { ReactComponent as IconIntegrationFilled } from 'static/assets/profile-settings/integration-filled.svg';
// import { ReactComponent as IconIntegrationOutline } from 'static/assets/profile-settings/integration-outline.svg';
import { ReactComponent as IconFnsFilled } from 'static/assets/profile-settings/fns-filled.svg';
import { ReactComponent as IconFnsOutline } from 'static/assets/profile-settings/fns-outline.svg';
import { ReactComponent as IconPersonFilled } from 'static/assets/profile-settings/person-filled.svg';
import { ReactComponent as IconPersonOutline } from 'static/assets/profile-settings/person-outline.svg';
import { ReactComponent as IconSettingsFilled } from 'static/assets/profile-settings/settings-filled.svg';
import { ReactComponent as IconSettingsOutline } from 'static/assets/profile-settings/settings-outline.svg';

export const NAV_LIST = [
  {
    text: 'Настройки профиля',
    icon: <IconSettingsOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconSettingsFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings',
  },
  {
    text: 'Личные данные',
    icon: <IconPersonOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconPersonFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings/personal',
  },
  {
    text: 'Тип учетной записи',
    icon: <IconAuthOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconAuthFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings/account-type',
  },
  {
    text: 'Статус самозанятого',
    icon: <IconFnsOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconFnsFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings/status',
  },
  {
    text: 'Партнеры',
    icon: <IconBriefcaseOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconBriefcaseFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings/partners',
  },
  {
    text: 'Документы',
    icon: <IconFileOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconFileFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings/documents',
  },

  {
    text: 'Налоговая копилка',
    icon: <IconTaxHoldOutline className="profile-settings-nav__item-icon" />,
    activeIcon: <IconTaxHoldFilled className="profile-settings-nav__item-icon" />,
    link: '/lk/profile-settings/tax-hold',
  },

  // {
  //   text: 'Интеграция',
  //   icon: <IconIntegrationOutline className="profile-settings-nav__item-icon" />,
  //   activeIcon: <IconIntegrationFilled className="profile-settings-nav__item-icon" />,
  //   link: '/lk/profile-settings/integration',
  // },
];
