import React from 'react';

const HintWrongINN = () => (
  <ul className="hint-auth__list">
    <li className="hint-auth__list-item">1. Нажмите на «карандаш» в поле ввода</li>
    <li className="hint-auth__list-item">2. Сформируйте новый запрос</li>
    <li className="hint-auth__list-item">3. Убедитесь в том, что указан правильный ИНН</li>
    <li className="hint-auth__list-item">4. Отправьте запрос снова</li>
    <li className="hint-auth__list-item">5. Дождитесь ответа</li>
  </ul>
);

export default HintWrongINN;
