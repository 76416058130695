import { LOG_OUT_SUCCESS } from 'modules/authLegasy/actions/action-types';
import * as types from 'modules/tax/actions/action-types';

const initialState = {
  totalTaxList: [],
  taxesToPay: [],
};

const taxReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_TAXES_TO_PAY_SUCCESS:
      return {
        ...state,
        totalTaxList: action.payload.data,
      };

    case types.SET_TAXES_TO_PAY_LIST:
      return {
        ...state,
        taxesToPay: action.payload.taxesToPay,
      };

    case LOG_OUT_SUCCESS:
      return {
        ...state,
        totalTaxList: {},
      };

    default:
      return state;
  }
};

export default taxReducer;
