import React from 'react';

const HintCommissionAndLimits = ({ additionalText }) => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      <p>
        Комиссия на вывод средств на банковскую карту или через СБП составляет 3,5%. Минимальная
        комиссия - 40 ₽
      </p>
      <p>{additionalText}</p>
      <p>Комиссия на пополнение Кошелька отсутствует.</p>
    </div>
  </div>
);

export default HintCommissionAndLimits;
