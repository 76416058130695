import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import appStore from 'static/assets/apple-store-img.png';
import googlePay from 'static/assets/google-pay.png';
import phone1 from 'static/assets/phone-1.png';
import phone2 from 'static/assets/phone-2.png';

const ManualPageStepMyTax1 = ({ returnBack, setStepGoToMyTax2 }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          {windowSize.innerWidth > 760 && (
            <>
              <ReturnButton onClick={returnBack} className="manual-page__return-link" />

              <h1 className="manual-page__title">
                Регистрация
                <br />
                через «Мой налог»
              </h1>
            </>
          )}

          <div className="manual-page__text">
            «Мой налог» — это официальное приложение ФНС России. С его помощью можно в том числе
            зарегистрироваться в качестве самозанятого. Для начала установите приложение себе на
            телефон.
          </div>

          <div className="manual-page__app-buttons-list">
            <a
              href="https://apps.apple.com/ru/app/%D0%BC%D0%BE%D0%B9-%D0%BD%D0%B0%D0%BB%D0%BE%D0%B3/id1437518854?l=en"
              rel="noopener noreferrer"
              target="_blank"
              className="manual-page__app-buttons-item"
            >
              <img src={appStore} className="manual-page__app-buttons-item-img" alt="" />
            </a>

            <a
              href="https://play.google.com/store/apps/details?id=com.gnivts.selfemployed&hl=en"
              className="manual-page__app-buttons-item"
              rel="noopener noreferrer"
              target="_blank"
            >
              <img src={googlePay} className="manual-page__app-buttons-item-img" alt="" />
            </a>
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="Установил. Что дальше?"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToMyTax2}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          {windowSize.innerWidth <= 760 && (
            <>
              <ReturnButton onClick={returnBack} className="manual-page__return-link" />

              <h1 className="manual-page__title">
                Регистрация
                <br />
                через «Мой налог»
              </h1>
            </>
          )}

          <img src={phone1} alt="" className="manual-page__phone manual-page__phone_1" />
          <img src={phone2} alt="" className="manual-page__phone manual-page__phone_2" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepMyTax1.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToMyTax2: PropTypes.func.isRequired,
};

export default ManualPageStepMyTax1;
