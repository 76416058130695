import { PasswordInput } from './PasswordInput';
import { PhoneInput } from './PhoneInput';
import { CodeInput } from './CodeInput';
import { MaskInput } from './MaskInput';

export const InputUI = {
  Phone: PhoneInput,
  Password: PasswordInput,
  Code: CodeInput,
  Mask: MaskInput,
};
