/*
  Компонент для ввода кода произвольной длины (на данный момент используется для подтверждения
  номера телефона при регистрации, а также для подтверждения перевода денег)
*/

import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './CodeInput.scss';

const BACKSPACE_KEY_CODE = 8;
const ENTER_KEY_CODE = 13;
const LEFT_ARROW_KEY_CODE = 37;
const RIGHT_ARROW_KEY_CODE = 39;
const V_KEY_CODE = 86;

const CodeInput = ({
  // длина кода
  codeLength,
  // пометить код как невалидный
  isValid,
  // автофокус
  autoFocus,
  // вводимый код
  value,
  // запретить вводить
  isDisabled,
  // колбэк, вызываемый при вводе или удалении
  onChange,
  // колбэк, вызываемый при нажатии enter или вводе полного кода
  onCodeEnter,
  // имя класса
  className,
}) => {
  /* eslint-disable-next-line react-hooks/rules-of-hooks */
  const inputs = [...Array(codeLength)].map(() => React.useRef());

  React.useEffect(() => {
    inputs.forEach((ref, index) => (ref.current.value = value[index] || ''));
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [value]);

  // получить код со всех ref'ов
  function getCode() {
    return inputs.reduce((acc, ref) => {
      acc += ref.current.value || ' ';
      return acc;
    }, '');
  }

  // проверить, состоит ли код из одних цифр
  function isCodeConsistOfNumbers() {
    return getCode()
      .split('')
      .every((number) => /\d/.test(number));
  }

  // очистить код от пробелов
  // очистить код от не чисел
  function trimCode(code) {
    return code.replace(' ', '').replace(/\D/g, '');
  }

  // установить фокус на следующей ячейке для ввода
  function focusNextInput(index) {
    inputs[index + 1].current.focus();
  }

  // установить фокус на предыдущей ячейке для ввода
  function focusPreviousInput(index) {
    inputs[index - 1].current.focus();
  }

  // убрать фокус с текущей ячейки
  function blurCurrentInput(index) {
    inputs[index].current.blur();
  }

  function onPaste(index) {
    return function onPasteHandler(event) {
      event.preventDefault();

      // берем вставляемые данные
      // убираем из них все кроме чисел
      const pasteCode = trimCode(event.clipboardData.getData('text'));

      inputs.slice(index).forEach((ref, index) => {
        if (!pasteCode[index]) {
          return;
        }

        ref.current.value = pasteCode[index];

        // сфокусироваться на следующем элементе
        inputs[index + 1] ? focusNextInput(index) : blurCurrentInput(index);
        // обновить состояние
        onChange(getCode());
      });

      // если вставка производилась в первую ячейку
      // а также длина вставляемого кода >= codeLength, то
      // убрать фокус
      if (index === 0 && pasteCode.length >= codeLength) {
        blurCurrentInput(index);
        onCodeEnter(trimCode(getCode()));
      }
    };
  }

  function onKeyDown(index) {
    return function onKeyDownHandler(event) {
      const { key, keyCode, ctrlKey, metaKey } = event;

      // нажат Ctrl + V (вставка)
      // event.preventDefault не исользуется, т.к. в противном случае
      // обработчик onPaste не сработает
      if (keyCode === V_KEY_CODE && (ctrlKey || metaKey)) {
        return;
      }

      event.preventDefault();

      // нажат BACKSPACE
      if (keyCode === BACKSPACE_KEY_CODE) {
        // делаем текущий ref пустым
        inputs[index].current.value = '';

        // если есть ячейка левее текущей, то устанавливаем фокус на нее
        if (inputs[index - 1]) {
          focusPreviousInput(index);
        }

        onChange(getCode());
      }

      // нажат ENTER
      else if (keyCode === ENTER_KEY_CODE) {
        blurCurrentInput(index);
        onCodeEnter(trimCode(getCode()));
      }

      // нажата стрелка влево
      else if (keyCode === LEFT_ARROW_KEY_CODE) {
        inputs[index - 1] ? focusPreviousInput(index) : blurCurrentInput(index);
      }

      // нажата стрелка вправо
      else if (keyCode === RIGHT_ARROW_KEY_CODE) {
        inputs[index + 1] ? focusNextInput(index) : blurCurrentInput(index);
      }

      // коды цифр на клавиатуре сверху:
      // 48 => 0
      // 49 => 1
      // ...
      // 57 => 9
      //
      // коды цифр на клавиатуре справа (numpad)
      // 96 => 0
      // 97 => 1
      // ...
      // 105 => 9
      //
      // нажата одна из цифр выше
      else if ((keyCode >= 48 && keyCode <= 57) || (keyCode >= 96 && keyCode <= 105)) {
        // поскольку мы отменили поведение обработчика события по-умолчанию, то
        // символы не будут печататься сами. Необходимо значение input менять вручную
        inputs[index].current.value = key;

        // после ввода символа необходимо сделать фокус на следующей ячейке,
        // либо убрать фокус вовсе в случае если текущий элемент - последний
        inputs[index + 1] ? focusNextInput(index) : blurCurrentInput(index);

        onChange(getCode());

        // если весь код состоит из цифр, и активна последняя ячейка
        // то вызвать onCodeEnter
        if (isCodeConsistOfNumbers() && !inputs[index + 1]) {
          onCodeEnter(getCode());
        }
      }
    };
  }

  return (
    <div
      className={cx(
        'code-input',
        !isValid && 'code-input_invalid',
        isDisabled && 'code-input_disabled',
        className,
      )}
    >
      {inputs.map((ref, index) => (
        <input
          key={index}
          type="number"
          ref={ref}
          autoFocus={autoFocus && index === 0}
          disabled={isDisabled}
          onPaste={onPaste(index)}
          onKeyDown={onKeyDown(index)}
          placeholder={isValid ? ' ' : '●'}
        />
      ))}
    </div>
  );
};

CodeInput.defaultProps = {
  codeLength: 4,
  autoFocus: true,
};

CodeInput.propTypes = {
  codeLength: PropTypes.number.isRequired,
  isValid: PropTypes.bool.isRequired,
  autoFocus: PropTypes.bool.isRequired,
  value: PropTypes.string.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  onCodeEnter: PropTypes.func,
  className: PropTypes.string,
};

export default CodeInput;
