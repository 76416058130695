import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import cx from 'classnames';
import DialogHeader from '../Popup/PopupHeader';
import DialogFooter from '../Popup/PopupFooter';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import styles from './Dialog.module.scss';

const modalRootElement = document.body;

const Dialog = ({ onClose, className, children }) => {
  const ref = React.useRef();

  React.useEffect(() => {
    /**
     * Click event handler.
     * @param {MouseEvent} event
     */
    function onClickOut(event) {
      const { target } = event;

      if (!ref?.current.contains(target)) {
        onCloseButtonClick();
      }
    }

    document.addEventListener('click', onClickOut);
    return () => document.removeEventListener('click', onClickOut);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onCloseButtonClick() {
    if (onClose) {
      onClose();
    }
  }

  return createPortal(
    <div className={cx(styles.dialog, className)}>
      <div className={styles.dialogBg}></div>
      <div className={styles.dialogContainer}>
        <div ref={ref} className={styles.dialogWrapper}>
          <DialogHeader
            className={styles.dialogMobileHeader}
            onClose={onCloseButtonClick}
            href="/lk/profile-settings"
          />
          <button className={styles.dialogCloseButton} onClick={onCloseButtonClick}>
            <IconClose className={styles.dialogCloseButtonIcon} />
          </button>
          <div className={styles.dialogContent}>{children}</div>
        </div>

        <DialogFooter className={styles.dialogMobileFooter} />
      </div>
    </div>,
    modalRootElement,
  );
};

Dialog.propTypes = {
  onClose: PropTypes.func,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Dialog;
