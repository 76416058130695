import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';

export const useDocumentListSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData?.renderItems || {},
      (renderItems) => renderItems,
    ),
    shallowEqual,
  );

export const useHasMoreDocumentsSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.hasMore,
      (hasMore) => hasMore,
    ),
  );

export const useHasDocumentsSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.renderItems.length,
      (length) => !!length,
    ),
    shallowEqual,
  );

export const useCompanyNameFilterSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.filter.companyName,
      (companyName) => companyName,
    ),
    shallowEqual,
  );

export const useStatusFilterSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.filter.status,
      (companyName) => companyName,
    ),
    shallowEqual,
  );

export const usePartnerIdFilterSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.filter.partnerId,
      (partnerId) => partnerId,
    ),
    shallowEqual,
  );

export const usePlatformNameFilterSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.filter.partnerName,
      (partnerName) => partnerName,
    ),
    shallowEqual,
  );

export const useIsDocumentListLoadingFetchingSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentListLoading.fetching,
      (fetching) => fetching,
    ),
  );

export const useIsDocumentListLoadingSuccessSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentListLoading.success,
      (success) => success,
    ),
  );

export const useIsDocumentListLoadingFailSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentListLoading.fail,
      (fail) => fail,
    ),
  );

export const useIsFetchingByFilterSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentListLoading.fetchingByFilter,
      (fetchingByFilter) => fetchingByFilter,
    ),
  );

export const useErrorSelector = () =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentListLoading.error,
      (error) => error,
    ),
  );

export const useDocumentById = (docId) =>
  useSelector(
    createSelector(
      (state) => state.documentList.documentsData.items,
      (items) => items.filter((item) => item.id === docId)[0],
    ),
  );
