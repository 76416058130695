import { fetchWrapper } from 'helpers';
/**
 * Описание  api
 * @param {string} login - логин пользователя
 * @param {string} password - пароль пользователя
 * @return {obj || Error} - return api
 */
export const loginAPI = async ({ login, password }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/login`;
    const body = JSON.stringify({
      login,
      password,
    });

    const response = await fetchWrapper(`${url}`, {
      method: 'POST',
      body,
    });
    if (response.result.code !== 200) throw new Error(response.result);
    return response.data;
  } catch (error) {
    throw error?.result || error;
  }
};

/**
 * Описание  api
 * @param {number} id - логин пользователя
 * @param {string} pin - пароль пользователя
 * @return {obj || Error} - return api
 */
export const loginSmsConfirmAPI = async ({ id, pin }) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/login/sms/confirm`;
    const body = JSON.stringify({
      id,
      pin,
    });

    const response = await fetchWrapper(`${url}`, {
      method: 'POST',
      body,
    });
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};
