import PropTypes from 'prop-types';
import React from 'react';

import logo from 'static/assets/samsung-pay.svg';

import './SamsungPayButton.scss';

const SamsungPayButton = ({ disableSamsungPayButton }) => {
  const clickHandler = () => {
    // todo: add logic
  };

  return (
    <button
      type="button"
      className="samsung-pay-button"
      disabled={disableSamsungPayButton}
      onClick={clickHandler}
    >
      <img className="samsung-pay-button__logo" src={logo} alt="Samsung Pay" />
    </button>
  );
};

SamsungPayButton.propTypes = {
  // amount: PropTypes.number.isRequired,
  disableSamsungPayButton: PropTypes.bool,
};

SamsungPayButton.defaultProps = {
  disableSamsungPayButton: false,
};

export default SamsungPayButton;
