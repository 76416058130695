import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import Footer from 'modules/authLegasy/components/Footer/Footer';
import { setTaxPayingProgress } from 'modules/profileLegasy/actions';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import TaxPayment from 'modules/tax/components/TaxPayment/TaxPayment';
import TaxPaymentCard from 'modules/tax/components/TaxPaymentCard/TaxPaymentCard';
import TaxPaymentSz from 'modules/tax/components/TaxPaymentSz/TaxPaymentSz';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

// Styles
import './TaxPaymentContainer.scss';

const TaxPaymentContainer = ({ history, dispatch, taxPayingProgress, taxesToPay }) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);
  const [isRedirectToTaxList, setIsRedirectToTaxList] = useState(false);
  const [sumToPay, setSumToPay] = useState(0);
  const [isMultipleTaxes, setIsMultipleTaxes] = useState(false);

  const mobileView = windowSize.innerWidth <= 980;

  /** Устанавливаем прогресс оплаты налога в 0 */
  useEffect(() => {
    dispatch(setTaxPayingProgress(0));
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    if (taxesToPay && taxesToPay.length) {
      taxesToPay.forEach((item) => {
        setSumToPay((prevState) => prevState + item.amount);
      });

      if (taxesToPay.length > 1) {
        setIsMultipleTaxes(true);
      }
    } else {
      setIsRedirectToTaxList(true);
    }
  }, [taxesToPay]);

  /**
   * Если в сторе нет списка налогов, выбранных для
   * оплаты (taxesToPay), редиректим на список налогов
   * */
  if (isRedirectToTaxList) {
    return <Redirect to="/lk/tax-list" />;
  }

  return createPortal(
    <div className="tax-payment-container">
      <div className="tax-payment-container__container">
        {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="tax-payment-container__header-wrap">
            <PopupHeaderWithBalance className="tax-payment-container__header" />

            {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
            <PopupCloseWithText isLink href="/lk/tax-list" />
            {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
          </div>
        </CSSTransition>
        {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

        <>
          {mobileView ? (
            <Switch>
              <Route
                exact
                path="/lk/tax-payment"
                history={history}
                render={() => (
                  <TaxPayment
                    history={history}
                    taxPayingProgress={taxPayingProgress}
                    sumToPay={sumToPay}
                    isMultipleTaxes={isMultipleTaxes}
                    taxesToPay={taxesToPay}
                  />
                )}
              />
              <Route
                path="/lk/tax-payment/bank-card"
                history={history}
                render={() => <TaxPaymentCard sumToPay={sumToPay} />}
              />
              <Route
                path="/lk/tax-payment/sz"
                history={history}
                render={() => <TaxPaymentSz sumToPay={sumToPay} />}
              />
            </Switch>
          ) : (
            <Switch>
              <Route
                exact
                path="/lk/tax-payment"
                render={() => (
                  <TaxPayment
                    history={history}
                    taxPayingProgress={taxPayingProgress}
                    sumToPay={sumToPay}
                    isMultipleTaxes={isMultipleTaxes}
                  />
                )}
              />
              <Redirect from="/lk/tax-payment/bank-card" to="/lk/tax-payment" />
              <Redirect from="/lk/tax-payment/sz" to="/lk/tax-payment" />
            </Switch>
          )}
        </>
      </div>

      {/* ФУТЕР */}
      <Footer />
      {/* /ФУТЕР */}
    </div>,
    document.body,
  );
};

TaxPaymentContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  history: PropTypes.shape({
    goBack: PropTypes.func,
  }).isRequired,
  taxPayingProgress: PropTypes.number,
  taxesToPay: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      sum: PropTypes.number,
    }),
  ),
};

TaxPaymentContainer.defaultProps = {
  taxPayingProgress: 0,
  taxesToPay: [],
};

const mapStateToProps = (state) => ({
  taxPayingProgress: state.profile.taxPayingProgress,
  taxesToPay: state.tax.taxesToPay,
});

export default connect(mapStateToProps)(withRouter(TaxPaymentContainer));
