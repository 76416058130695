import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { setHintOpen } from 'modules/authLegasy/actions';
import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import HintNotRegisteredInFNS from 'modules/authLegasy/components/Hint/HintNotRegisteredInFNS';
import HintWrongINN from 'modules/authLegasy/components/Hint/HintWrongINN';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { CONSISTENT_ANIMATIONS_DELAY, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';
import iconSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

export const SelfEmployedFailed = ({ className, userINN, onChangeINN, isMenuOpen, dispatch }) => {
  const circleLoaderValue = 0;
  const [currentHintId, setCurrentHintId] = useState(null);
  const [isRendered, setIsRendered] = useState(false);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  const onCloseBtnClick = () => {
    closeExplicitly();
    setCurrentHintId(null);
  };

  const toggleHint1 = () => {
    toggleDrop();
    setCurrentHintId(1);
  };

  const toggleHint2 = () => {
    toggleDrop();
    setCurrentHintId(2);
  };

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  const hints = [
    {
      id: 1,
      title: 'Неверно указан ИНН',
      titleForPopup: 'Неверно указан ИНН',
      action: toggleHint1,
      openCondition: isDropOpen && currentHintId === 1,
      popup: <HintWrongINN />,
      dropEl: dropEl,
    },
    {
      id: 2,
      title: 'Вы не состоите на учете ФНС',
      titleForPopup: 'Вы не состоите на учете ФНС',
      action: toggleHint2,
      openCondition: isDropOpen && currentHintId === 2,
      popup: <HintNotRegisteredInFNS />,
      dropEl: dropEl,
    },
  ];

  return (
    <div
      className={cx('se-register', {
        [className]: className,
      })}
      data-testid="se-register"
      data-cy="se-register-fail"
    >
      <div className="se-register__container container">
        <div className="se-register__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="se-register__title" data-cy="se-register-fail-title">
              Мы не смогли подтвердить ваш статус в ФНС
            </h1>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="se-register__desc-wrap animation-from-bottom-to-top">
              <div className="se-register__desc">
                Вы можете подтвердить статус самозанятого в настройках личного кабинета позднее
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="se-register__form">
              <div className="form form_register">
                <div className="form__block">
                  <div className="form__input-holder">
                    <InputMask
                      mask="9999-9999-9999"
                      type="text"
                      className="form__input form__input_fail"
                      defaultValue={userINN}
                      disabled
                      readOnly
                      data-testid="se-inn-input"
                    />
                    <button className="form__input-edit" type="button" onClick={onChangeINN}>
                      <IconEdit />
                    </button>
                  </div>

                  {windowSize.innerWidth > 700 && (
                    <div className="form__loader-wrap">
                      <CircleProgress
                        value={circleLoaderValue}
                        color="var(--reddish-pink)"
                        className="form__loader"
                        radius={28}
                      />
                      <IconLockClosed className="form__password-secure-lock form__password-secure-lock_red" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered && windowSize.innerWidth > 700}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <div className="se-register__btn">
              <StandardButton
                isLink
                title="Продолжить как физ.лицо"
                className="js-have-to-handle"
                href="/lk"
                bgType={GREY_BORDER}
                data-cy="go-to-wallet-link"
              />
            </div>
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <div className="se-register__hint-block">
            {windowSize.innerWidth > 700 && (
              <div className="se-register__hint">
                <p className="se-register__hint-text">
                  Ой!
                  <img
                    src={iconSad}
                    alt=""
                    className="se-register__hint-icon se-register__hint-icon_sad"
                    data-testid="icon-sad"
                  />
                  Мы не смогли вас узнать! Мы подготовили список шагов, которые могут вам помочь
                </p>

                <IconTriangle className="se-register__hint-triangle" />
              </div>
            )}

            <div className="se-register__error-list-wrap">
              <HintsList
                hints={hints}
                closeHintHandler={onCloseBtnClick}
                title="Возможные причины"
              />
            </div>
          </div>
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && isRendered && windowSize.innerWidth <= 700}
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <div className="se-register__btn">
            <StandardButton
              isLink
              title="Продолжить как физ.лицо"
              className="js-have-to-handle"
              href="/lk"
              bgType={GREY_BORDER}
            />
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

SelfEmployedFailed.propTypes = {
  className: PropTypes.string.isRequired,
  userINN: PropTypes.string.isRequired,
  onChangeINN: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userINN: state.auth.INN,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(SelfEmployedFailed);
