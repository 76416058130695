import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import checkImg from 'static/assets/check-img.png';
import manualSzStatus from 'static/assets/manual-sz-status.png';
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3-small.png';
import shape4 from 'static/assets/shape-4.png';

const ManualPageStepCheckCitizenship = ({
  returnBack,
  setStepGoToRegForRf,
  setStepGoToRegForEEU,
}) => {
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__content manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">Имеется ли у вас гражданство Российской Федерации?</h1>

          <div className="manual-page__button-list">
            <StandardButton
              title="Да, я гражданин РФ"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToRegForRf}
              bgType={BLUE_BORDER}
            />

            <StandardButton
              title="Нет, я гражданин ЕАЭС"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToRegForEEU}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={checkImg} className="manual-page__check" alt="" />

          <img src={manualSzStatus} className="manual-page__status" alt="" />

          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_1" />
          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_2" />
          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_3" />
          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_4" />

          <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_1" />
          <img src={shape4} alt="" className="manual-page__ruble manual-page__ruble_2" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepCheckCitizenship.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToRegForRf: PropTypes.func.isRequired,
  setStepGoToRegForEEU: PropTypes.func.isRequired,
};

export default ManualPageStepCheckCitizenship;
