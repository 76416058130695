/* eslint-disable import/prefer-default-export */
import React from 'react';

import { ReactComponent as Form } from 'static/assets/form-icon.svg';
import { ReactComponent as RussianPost } from 'static/assets/russian-post-logo-icon.svg';
import { ReactComponent as Sam } from 'static/assets/sam-logo-icon.svg';
import { ReactComponent as Svyaznoy } from 'static/assets/svyaznoy-logo-icon.svg';
import { AUTHORIZED, EDO } from 'constants/index.js';

export const IDENTIFICATION_ENUM = {
  inSelfwork: 'inSelfwork',
  inSvyaznoy: 'inSvyaznoy',
  inLetter: 'inLetter',
};

export const DATA_CATDS_OBJ = {
  EDO: [
    {
      icon: <Form />,
      title: 'Заполнить анкету',
      text: 'Заполнение анкеты не займет более 10 минут, приготовьте ваш паспорт и номер ИНН',
      url: `/get-authorization?type=${EDO}`,
    },
  ],
  AUTHORIZED: [
    {
      icon: <Form />,
      title: 'Заполнить анкету',
      text: 'Заполнение анкеты не займет более 10 минут, приготовьте ваш паспорт и номер ИНН',
      url: `/get-authorization?type=${AUTHORIZED}`,
    },
  ],
  IDENTIFIED: [
    {
      type: IDENTIFICATION_ENUM.inSelfwork,
      icon: <Sam />,
      title: 'В офисе Самозанятые.РФ',
      text: 'Запишитесь на встречу, приходите с паспортом и номером кошелька.',
    },
    {
      type: IDENTIFICATION_ENUM.inSvyaznoy,
      icon: <Svyaznoy />,
      title: 'В офисе Связного',
      text: 'Приходите с паспортом в ближайший офис Связного и заполните заявление.',
    },
    {
      type: IDENTIFICATION_ENUM.inLetter,
      icon: <RussianPost />,
      title: 'По почте',
      text: 'Заверьте копию паспорта, заполните заявление, отправьте нам почтой.',
    },
  ],
};
