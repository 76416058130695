import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import Overlay from '../Overlay';
import iconEmojiOk from 'static/assets/icon-emoji-ok.png';

import { getPartnerNameByPartnershipId } from '../../selectors';
import { hidePopupEditBankCard, hidePopupEditBankCardSuccess } from '../../actions';

import styles from './PopupEditBankCardSuccess.module.scss';

const PopupEditBankCardSuccess = ({ dispatch }) => {
  const { params } = useRouteMatch('/lk/partner/:platformName/:partnershipId');
  const history = useHistory();
  const { platformName, partnershipId } = params;
  const partnerName = useSelector(getPartnerNameByPartnershipId(platformName, partnershipId));
  const text = `Новая банковская карта была успешно привязана. Следующие выплаты от ${partnerName} вы будете получать на нее.`;

  function onClose() {
    dispatch(hidePopupEditBankCard());
    dispatch(hidePopupEditBankCardSuccess());
  }

  function onCloseSuccessEdit() {
    dispatch(hidePopupEditBankCard());
    dispatch(hidePopupEditBankCardSuccess());
    history.replace('/lk/profile-settings/partners');
  }

  return createPortal(
    <Overlay
      title="Карта сохранена"
      icon={iconEmojiOk}
      text={() => text}
      nav={(props) => <PopupEditBankCardSuccessNav {...props} />}
      onClose={onClose}
      onSuccess={onCloseSuccessEdit}
    />,
    document.body,
  );
};

PopupEditBankCardSuccess.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

const PopupEditBankCardSuccessNav = ({ onSuccess }) => {
  return (
    <div className={styles.popupEditBankCardSuccessNav}>
      <button onClick={onSuccess}>К списку партнеров</button>
    </div>
  );
};

PopupEditBankCardSuccessNav.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

export default connect()(PopupEditBankCardSuccess);
