import React from 'react';
import Select from 'react-select';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Misc
import { selectStyles } from 'static/styles/select';

// Styles
import './FormSelect.scss';

const FormSelect = ({
  options,
  className,
  handleSelectChange,
  currentSelectedOption,
  placeholder,
  disabled,
  showDefault = true,
  isSearchable = false,
}) => {
  return (
    <Select
      isSearchable={isSearchable}
      options={options}
      defaultValue={
        showDefault && currentSelectedOption >= 1 ? options[currentSelectedOption - 1] : null
      }
      onChange={handleSelectChange}
      styles={selectStyles}
      isDisabled={disabled}
      placeholder={placeholder}
      noOptionsMessage={() => 'Не найдено'}
      className={cx('', {
        [className]: className,
      })}
      classNamePrefix="form-select"
    />
  );
};

FormSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  disabled: PropTypes.bool,
  showDefault: PropTypes.bool,
  isSearchable: PropTypes.bool,
  currentSelectedOption: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
};

FormSelect.defaultProps = {
  disabled: false,
  className: null,
};

export default FormSelect;
