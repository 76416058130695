import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import { setTaxPayingProgress } from 'modules/profileLegasy/actions';
import PaymentMessage from 'modules/profileLegasy/components/PaymentMessage/PaymentMessage';
import PaymentSummary from 'modules/profileLegasy/components/PaymentSummary/PaymentSummary';
import ResponsiveHeader from 'modules/shared/components/ResponsiveHeader/ResponsiveHeader';
import { payTaxBySzCard } from 'modules/tax/actions';
import TaxPaymentActionButtons from 'modules/tax/components/TaxPaymentActionButtons/TaxPaymentActionButtons';

import {
  CONSISTENT_ANIMATIONS_DELAY,
  PAYMENT_TRANSFER_FAIL,
  PAYMENT_TRANSFER_LOADING,
  PAYMENT_TRANSFER_OK,
} from 'constants/index';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as Logo } from 'static/assets/logo-white.svg';
import profileBg from 'static/assets/profile-bg.png';

// Styles
import './TaxPaymentSz.scss';

const TaxPaymentSz = ({
  dispatch,
  user,
  taxPayingProgress,
  taxInfoSumToPay,
  taxesToPay,
  oneTaxChangedSum,
  isMultipleTaxes,
}) => {
  const [paymentStatus, setPaymentStatus] = useState(null);
  const [isTransferPreApproved, setIsTransferPreApproved] = useState(false);
  const [finalTaxesToPay, setFinalTaxesToPay] = useState(null);

  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    if (oneTaxChangedSum) {
      setFinalTaxesToPay([
        {
          ...taxesToPay[0],
          amount: oneTaxChangedSum * 100,
        },
      ]);
    }
  }, [oneTaxChangedSum, taxesToPay]);

  const summaryFieldsSucceed = [
    {
      source: 'Налоговый период',
      title: 'Налоговый период',
      value: 'Ноябрь 2019',
    },
    {
      source: 'Сумма',
      title: 'Сумма',
      value: '2010 ₽',
    },
  ];

  /** Клик по кнопке перевести */
  const payTaxClick = () => {
    setPaymentStatus(PAYMENT_TRANSFER_LOADING);
    dispatch(setTaxPayingProgress(66));

    dispatch(payTaxBySzCard(isMultipleTaxes ? taxesToPay : finalTaxesToPay))
      .then((res) => {
        if (
          res &&
          ((res.data && res.data.url.indexOf('error') > -1) ||
            (res.result && res.result.code === 400))
        ) {
          setPaymentStatus(PAYMENT_TRANSFER_FAIL);
          dispatch(setTaxPayingProgress(66));
        }
        // setPaymentStatus(PAYMENT_TRANSFER_OK);
        // dispatch(setTaxPayingProgress(100));
      })
      .catch(() => {
        setPaymentStatus(PAYMENT_TRANSFER_FAIL);
        dispatch(setTaxPayingProgress(66));
      });
  };

  /** Активируем кнопку "Перевести" если все поля валидны */
  useEffect(() => {
    setIsTransferPreApproved(false);
    dispatch(setTaxPayingProgress(0));

    if (
      user &&
      user.login &&
      user.clientAccounts &&
      Array.isArray(user.clientAccounts) &&
      user.clientAccounts[0] &&
      user.clientAccounts[0].balance / 100 >= taxInfoSumToPay / 100
    ) {
      setIsTransferPreApproved(true);
      dispatch(setTaxPayingProgress(33));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [user]);
  /* eslint-disable react-hooks/exhaustive-deps */

  /** Клик по кнопке попробовать снова */
  const tryAgainClick = () => {
    setPaymentStatus(null);
    dispatch(setTaxPayingProgress(0));
  };

  return (
    <div className="tax-payment-sz" data-testid="tax-payment-sz">
      {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <ResponsiveHeader
          className="tax-payment-sz__responsive-header"
          isCloseButtonLink
          href="/lk/tax-payment"
        />
      </CSSTransition>
      {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}

      {/* MOBILE HEADER */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
        unmountOnExit
      >
        <div className="tax-payment-sz__top">
          <h1 className="tax-payment-sz__title">По номеру карты</h1>

          {!paymentStatus && <div className="tax-payment-sz__subtitle">Введите данные карты</div>}

          {paymentStatus === PAYMENT_TRANSFER_LOADING && (
            <div className="tax-payment-sz__subtitle">Идет процесс оплаты</div>
          )}

          {paymentStatus === PAYMENT_TRANSFER_OK && (
            <div className="tax-payment-sz__subtitle">Платеж успешно отправлен</div>
          )}

          {paymentStatus === PAYMENT_TRANSFER_FAIL && (
            <div className="tax-payment-sz__subtitle">Ошибка отправки</div>
          )}

          <div className="tax-payment-sz__top-progress">
            <div
              className="tax-payment-sz__top-progress-bar"
              style={{ width: `${taxPayingProgress}%` }}
            />
          </div>
        </div>
      </CSSTransition>
      {/* /MOBILE HEADER */}

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
        unmountOnExit
      >
        <div
          className={cx('tax-payment-sz__card', {
            'tax-payment-sz__card_with-shadow': paymentStatus,
          })}
        >
          {/* КОНТЕНТ ПЕРЕД ОПЛАТОЙ ЧЕРЕЗ КОЕШЕЛК СЗ */}
          {!paymentStatus && (
            <>
              <div className="tax-payment-sz__header">
                <Logo className="tax-payment-sz__header-logo" />

                <img
                  src={profileBg}
                  alt="Фирменный бэкгранунд Самозаняты.рф"
                  className="tax-payment-sz__header-bg"
                />
              </div>

              {user && user.login && (
                <div className="tax-payment-sz__info">
                  <p className="tax-payment-sz__info-title">Номер кошелька</p>
                  <p className="tax-payment-sz__info-number">{user.login}</p>
                </div>
              )}
            </>
          )}
          {/* /КОНТЕНТ ПЕРЕД ОПЛАТОЙ ЧЕРЕЗ КОЕШЕЛК СЗ */}

          {/* ПЛАТЕЖ ОБРАБАТЫВАЕТСЯ (КРУТИЛКА) */}
          {paymentStatus === PAYMENT_TRANSFER_LOADING && (
            <>
              <div className="tax-payment-sz__header">
                <Logo className="tax-payment-sz__header-logo" />

                <img
                  src={profileBg}
                  alt="Фирменный бэкгранунд Самозаняты.рф"
                  className="tax-payment-sz__header-bg"
                />
              </div>

              <PaymentMessage
                className="tax-payment-sz__payment-message"
                status={paymentStatus}
                text="Платеж обрабатывается. Это может занять некоторое время. Пожалуйста, подождите."
              />
            </>
          )}
          {/* /ПЛАТЕЖ ОБРАБАТЫВАЕТСЯ (КРУТИЛКА) */}

          {/* ПЛАТЕЖ УДАЧА */}
          {paymentStatus === PAYMENT_TRANSFER_OK && (
            <>
              <div className="tax-payment-card__success">
                <div className="tax-payment-card__success-icon-wrap">
                  <CircleProgress
                    className="tax-payment-card__success-progress"
                    color="var(--turquoise-blue)"
                    value={66}
                  />
                  <IconCheck className="tax-payment-card__success-icon" />
                </div>

                <div className="tax-payment-card__success-text">
                  <p className="tax-payment-card__success-text-title">Ваш платеж</p>
                  <p className="tax-payment-card__success-text-result">Успешно отправлен</p>
                </div>
              </div>
              <PaymentSummary summaryFields={summaryFieldsSucceed} />
            </>
          )}
          {/* /ПЛАТЕЖ УДАЧА */}

          {/* ПЛАТЕЖ НЕУДАЧА */}
          {paymentStatus === PAYMENT_TRANSFER_FAIL && (
            <>
              <div className="tax-payment-sz__header">
                <Logo className="tax-payment-sz__header-logo" />

                <img
                  src={profileBg}
                  alt="Фирменный бэкгранунд Самозаняты.рф"
                  className="tax-payment-sz__header-bg"
                />
              </div>

              <PaymentMessage
                className="tax-payment-sz__payment-message"
                status={paymentStatus}
                text="Упс. Что-то пошло не так и мы не смогли совершить платеж. Если проблема не будет решена, напишите нам"
              />
            </>
          )}
          {/* /ПЛАТЕЖ НЕУДАЧА */}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
        unmountOnExit
      >
        <TaxPaymentActionButtons
          isTransferPreApproved={isTransferPreApproved}
          payTaxClick={payTaxClick}
          paymentStatus={paymentStatus}
          tryAgainClick={tryAgainClick}
        />
      </CSSTransition>
    </div>
  );
};

TaxPaymentSz.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    login: PropTypes.string,
    clientAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
  }).isRequired,
  taxPayingProgress: PropTypes.number,
  taxInfoSumToPay: PropTypes.number,
  taxesToPay: PropTypes.arrayOf(
    PropTypes.shape({
      documentIndex: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
  oneTaxChangedSum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  isMultipleTaxes: PropTypes.bool,
};

TaxPaymentSz.defaultProps = {
  taxPayingProgress: 0,
  taxInfoSumToPay: 0,
  taxesToPay: [],
  oneTaxChangedSum: [],
  isMultipleTaxes: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  taxPayingProgress: state.profile.taxPayingProgress,
  taxesToPay: state.tax.taxesToPay,
});

export default connect(mapStateToProps)(TaxPaymentSz);
