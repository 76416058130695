/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { Card, CircleLoader } from 'ui';

export const LoadingDataCards = ({ length }) =>
  Array(length)
    .fill(1)
    .map((i, id) => (
      // eslint-disable-next-line react/no-array-index-key
      <CardStyled key={`${i}_${id}`} type="actived">
        <CircleLoaderStyled />
      </CardStyled>
    ));

LoadingDataCards.propTypes = {
  length: PropTypes.number,
};

LoadingDataCards.defaultProps = {
  length: 3,
};

const CardStyled = styled(Card)`
  min-width: 250px;
  min-height: 350px;
  display: flex;
  place-items: center;
  justify-content: center;
  margin: 30px 20px 20px 0;
  & > *:first-child {
    margin-bottom: 20px;
  }
  &:last-of-type {
    margin-right: 0;
    margin-top: 40px;
  }
  @media (max-width: 650px) {
    width: 100%;
  }
`;

const CircleLoaderStyled = styled(CircleLoader)`
  width: 16px;
  height: 16px;
`;
