import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import screen4 from 'static/assets/manual-screen-4.png';
import phone11 from 'static/assets/phone-11.png';

const ManualPageStepSzStatus1 = ({ returnBack, setStepGoToSzConfirmation }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  const szStatus1List = [
    {
      id: 1,
      text: (
        <>
          Со страницы своего профиля зайти на <b>Статус Самозанятого</b> и нажать <b>Получить</b>;
        </>
      ),
    },
    {
      id: 2,
      text: (
        <>
          В правом верхнем углу нажать на свою учетную запись (обычно это номер вашего телефона) и в
          выпавшем меню нажать на <b> Физ лицо.</b>
        </>
      ),
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">
            Подтверждение
            <br />
            статуса Самозанятого
            <br />в кошельке
          </h1>

          {windowSize.innerWidth <= 760 && (
            <div className="manual-page__images-container">
              <img src={phone11} alt="" className="manual-page__phone manual-page__phone_11" />
              <img src={screen4} alt="" className="manual-page__screen manual-page__screen_4" />
            </div>
          )}

          <div className="manual-page__text">
            Начать подтверждение статуса можно любым из следующих способов:
          </div>

          <ol className="manual-page__text-list">
            {szStatus1List.map(item => (
              <li className="manual-page__list-item" key={item.id}>
                <p className="manual-page__list-item-number">—</p>
                <div className="manual-page__list-item-text">{item.text}</div>
              </li>
            ))}
          </ol>

          <div className="manual-page__button-list">
            <StandardButton
              title="Далее"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToSzConfirmation}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered && windowSize.innerWidth > 760}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={phone11} alt="" className="manual-page__phone manual-page__phone_11" />
          <img src={screen4} alt="" className="manual-page__screen manual-page__screen_4" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepSzStatus1.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToSzConfirmation: PropTypes.func.isRequired,
};

export default ManualPageStepSzStatus1;
