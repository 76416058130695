import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Styles
import './DashedCreateNewItemButton.scss';

const DashedCreateNewItemButton = ({ className, disabled, title, onClick, isLink, href }) => {
  return (
    <>
      {isLink && href ? (
        <Link
          to={href}
          className={cx('dashed-create-new-item-button', {
            [className]: className,
          })}
          onClick={onClick}
        >
          {title}
        </Link>
      ) : (
        <button
          className={cx('dashed-create-new-item-button', {
            [className]: className,
          })}
          type="button"
          disabled={disabled}
          onClick={onClick}
        >
          {title}
        </button>
      )}
    </>
  );
};

DashedCreateNewItemButton.propTypes = {
  className: PropTypes.string,
  disabled: PropTypes.bool,
  title: PropTypes.string.isRequired,
  onClick: PropTypes.func,
  isLink: PropTypes.bool,
  href: PropTypes.string,
};

DashedCreateNewItemButton.defaultProps = {
  className: null,
  disabled: false,
  isLink: false,
  href: null,
  onClick: () => {},
};

export default DashedCreateNewItemButton;
