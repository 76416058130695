import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { CSSTransition } from 'react-transition-group';

import {
  deleteCheck,
  getCertificateRegistration,
  getIncomeStatement,
  getLastDateOfOperations,
  getTaxes,
  getTaxInfo,
  setIncomeCheckData,
} from 'modules/profileLegasy/actions';
import Check from 'modules/profileLegasy/components/Check/Check';
import Deals from 'modules/profileLegasy/components/Deals/Deals';
import HintAnnulCheck from 'modules/profileLegasy/components/HintAnnulCheck/HintAnnulCheck';
import IncomeCertificateCard from 'modules/profileLegasy/components/IncomeCertificateCard/IncomeCertificateCard';
import LockForNonSelfEmployed from 'modules/profileLegasy/components/LockForNonSelfEmployed/LockForNonSelfEmployed';
import Service from 'modules/profileLegasy/components/Service/Service';
import Taxes from 'modules/profileLegasy/components/Taxes/Taxes';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';
import TaxesIncomeCard from 'modules/tax/components/TaxesIncomeCard/TaxesIncomeCard';
import TaxesToPayCard from 'modules/tax/components/TaxesToPayCard/TaxesToPayCard';

import {
  CONSISTENT_ANIMATIONS_DELAY,
  HINT_TIMEOUTS,
  PARTLY_YES,
  PAYMENT_TRANSFER_FAIL as STATUS_FAIL,
  PAYMENT_TRANSFER_LOADING as STATUS_LOADING,
  PAYMENT_TRANSFER_OK as STATUS_OK,
  YES,
} from 'constants/index';

import emodjiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconServiceDocument2 } from 'static/assets/icon-service-document-2.svg';

// Styles
import './Accounting.scss';

const Accounting = ({
  dispatch,
  startZoneOffset,
  startCurrentTime,
  userTaxStatus,
  szStatusFromDb,
}) => {
  const [isHintOpen, hintEl, closeHintExplicitly] = useToggleAndOutClick();
  const [currentFormTab, setCurrentFormTab] = useState(0);
  const [isRendered, setIsRendered] = useState(false);

  const [now, setNow] = useState(new Date(startCurrentTime));
  const [zoneOffset, setZoneOffset] = useState(startZoneOffset);
  const [taxInfo, setTaxInfo] = useState(null);
  const [isTaxInfoLoading, setIsTaxInfoLoading] = useState(false);
  const [isTaxInfoError, setIsTaxInfoError] = useState(false);
  const [taxInfoErrorMessage, setTaxInfoErrorMessage] = useState(null);

  const [annulCheckStatus, setAnnulCheckStatus] = useState(null);
  const [annulCheckError, setAnnulCheckError] = useState(null);

  const [isCheckOpen, setIsCheckOpen] = useState(false);
  const [isFnsError, setIsFnsError] = useState(false);

  const [isErrorFnsPopupOpen, setIsErrorFnsPopupOpen] = useState(true);

  const [yearIncomes, setYearIncomes] = useState(null);
  const [prevMonthTax, setPrevMonthTax] = useState(null);
  const [isYearIncomesLoading, setIsYearIncomesLoading] = useState(false);
  const [isYearIncomesFail, setIsYearIncomesFail] = useState(false);

  /**
   * Берем  startZoneOffset и startCurrentTime с сервера, если они не равны нулю
   * записываем их в zoneOffset и now для построения фильтров, если же они равны 0
   * берем инфу о zoneOffset и now с браузера
   * */
  useEffect(() => {
    if (startZoneOffset !== 0) {
      setZoneOffset(startZoneOffset);
    } else {
      setZoneOffset(now.getTimezoneOffset());
    }

    if (startCurrentTime !== 0) {
      setNow(new Date(startCurrentTime));
    } else {
      setNow(new Date());
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startZoneOffset, startCurrentTime]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Берем с сервера инфу по налогу к оплате */
  const getTaxInfoFunction = () => {
    setIsTaxInfoLoading(true);

    dispatch(getTaxInfo(zoneOffset))
      .then((res) => {
        setIsTaxInfoLoading(false);
        setTaxInfo(res.data);
      })
      .catch((res) => {
        setIsTaxInfoLoading(false);
        setIsTaxInfoError(true);

        if (res && res.result && res.result.message) {
          setTaxInfoErrorMessage(res.result.message);
        } else {
          setTaxInfoErrorMessage('Сервис ФНС не доступен, эти данные появятся позже');
        }
      });
  };

  /** Аннулировать чек */
  const handleAnnulButtonClick = (event, reason) => {
    setAnnulCheckError(null);
    event.stopPropagation();

    if (!checkData) {
      return;
    }

    setAnnulCheckStatus(STATUS_LOADING);
    dispatch(deleteCheck(checkData.id, reason))
      .then(() => {
        setCheckData((prevState) => ({
          ...prevState,
          canceled: true,
        }));
        setAnnulCheckStatus(STATUS_OK);
      })
      .catch((res) => {
        setAnnulCheckStatus(STATUS_FAIL);
        setAnnulCheckError(res ? res.message : 'Чек не был аннулирован, попробуйте еще');
      });
  };

  useEffect(() => {
    getTaxInfoFunction();

    dispatch(getLastDateOfOperations()).then(() => {});
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  /**
   * Проверяем дотсупны ои для скачивания справка
   * о доходе и о постановке на учет
   * Если не доступны, то блокируем их
   * */
  const checkIfIDocumentsAreAvailableToDownload = () => {
    setIsFnsError(false);

    dispatch(getIncomeStatement())
      .then(() => {})
      .catch((res) => {
        if (res && res.data && res.data === 'connection.fns.error') {
          setIsFnsError(true);
        }
      });

    dispatch(getCertificateRegistration())
      .then(() => {})
      .catch((res) => {
        if (res && res.data && res.data === 'connection.fns.error') {
          setIsFnsError(true);
        }
      });
  };

  /**
   * Запускаем функцию в зависимости от того,
   * когда меняются userTaxStatus и  szStatusFromDb
   * */
  useEffect(() => {
    checkIfIDocumentsAreAvailableToDownload();

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userTaxStatus, szStatusFromDb]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const [checkData, setCheckData] = useState(null);

  /** Клик на сделку */
  const dealsItemClickHandler = (checkInfo) => {
    setCheckData(checkInfo);
    setIsCheckOpen(true);
  };

  /** Клик на кнопку закрытия в чеке при ширине экрана < 800 */
  const closeCheckButtonClick = () => {
    setIsCheckOpen(false);
  };

  /** Считаем годовой доход юзера + налог за предыдщуий месяц */
  useEffect(() => {
    setIsYearIncomesLoading(true);
    setIsYearIncomesFail(false);

    dispatch(getTaxes(12, new Date().getFullYear()))
      .then((res) => {
        setIsYearIncomesLoading(false);
        if (res && res.data) {
          res.data.forEach((item) => {
            setYearIncomes((prevState) => prevState + item.totalAmount);
          });

          /** Проверяем является ли текущий месяц первым в году */
          new Date().getMonth() !== 0
            ? setPrevMonthTax(res.data[new Date().getMonth() - 1].tax)
            : getTaxesFromPrevPeriod();
        }
      })
      .catch(() => {
        setIsYearIncomesLoading(false);
        setIsYearIncomesFail(true);
      });

    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** налог за предыдщуий месяц, если предыдцщий месяц бл в прошлом году */
  const getTaxesFromPrevPeriod = () => {
    setIsYearIncomesLoading(true);
    dispatch(getTaxes(12, new Date().getFullYear() - 1))
      .then((res) => {
        setIsYearIncomesLoading(false);
        setPrevMonthTax(res.data[11].tax);
      })
      .catch(() => {
        setIsYearIncomesLoading(false);
        setIsYearIncomesFail(true);
      });
  };

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const taxesTabClick = () => {
    setCurrentFormTab(0);
    setIsRenderedBetweenTabs(true);
    setIsCheckOpen(false);
  };

  const dealsTabClick = () => {
    setCurrentFormTab(1);
    setIsRenderedBetweenTabs(true);
  };

  const navTabsArray = [
    {
      title: 'Налоги',
      click: taxesTabClick,
    },
    {
      title: 'Сделки',
      click: dealsTabClick,
    },
  ];

  /** ДЛЯ АНИМАЦИЙ */
  const [isRenderedBetweenTabs, setIsRenderedBetweenTabs] = useState(false);

  const [tab0Delay1, setTab0Delay1] = useState(100);
  const [tab0Delay2, setTab0Delay2] = useState(150);
  const [tab0Delay3, setTab0Delay3] = useState(200);
  const [tab0Delay4, setTab0Delay4] = useState(250);
  const [tab0Delay5, setTab0Delay5] = useState(300);

  const [tab1Delay1, setTab1Delay1] = useState(100);

  useEffect(() => {
    setTab0Y(currentFormTab !== 1 ? 0 : 70);
    setTab0Opacity(currentFormTab !== 1 ? 1 : 0);

    setTab1Y(currentFormTab !== 0 ? 0 : 70);
    setTab1Opacity(currentFormTab !== 0 ? 1 : 0);

    if (isRenderedBetweenTabs) {
      setTab0Delay1(currentFormTab === 0 ? 0 : 0);
      setTab0Delay2(currentFormTab === 0 ? 0 : 0);
      setTab0Delay3(currentFormTab === 0 ? 0 : 0);
      setTab0Delay4(currentFormTab === 0 ? 0 : 0);
      setTab0Delay5(currentFormTab === 0 ? 0 : 0);

      setTab1Delay1(currentFormTab === 1 ? 0 : 0);
    }
  }, [currentFormTab, isRenderedBetweenTabs]);

  const transTab0Y = (Tab0Y) => `translateY(${Tab0Y}px)`;
  const transTab1Y = (Tab1Y) => `translateY(${Tab1Y}px)`;
  const [Tab0Opacity, setTab0Opacity] = useState(0);
  const [Tab1Opacity, setTab1Opacity] = useState(0);
  const [Tab0Y, setTab0Y] = useState(50);
  const [Tab1Y, setTab1Y] = useState(50);

  const springOptions = {
    from: {
      opacity: 0,
      Tab0Y: 50,
    },
    Tab0Opacity,
    Tab0Y,
    config: { mass: 1, tension: 280, friction: 120 },
  };

  const springOptions2 = {
    from: {
      opacity: 0,
      Tab1Y: 50,
    },
    Tab1Opacity,
    Tab1Y,
    config: { mass: 1, tension: 280, friction: 120 },
  };

  const spring1 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    delay: tab0Delay1,
  });

  const spring2 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    delay: tab0Delay2,
    config: { mass: 0.6, tension: 200, friction: 60 },
  });

  const spring3 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    delay: tab0Delay3,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring4 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    delay: tab0Delay4,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring5 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    delay: tab0Delay5,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring6 = useSpring({
    ...springOptions2,
    opacity: Tab1Opacity,
    delay: tab1Delay1,
  });

  /**
   * Устанавливаем нужную вкладку в Отчетности,
   *  если в localStorage приходит параметр accountingTab
   *  */
  useEffect(() => {
    const accountingTab =
      window && window.localStorage ? window.localStorage.getItem('accountingTab') : '0';

    if (accountingTab && (accountingTab === '0' || accountingTab === '1')) {
      setCurrentFormTab(+accountingTab);
      if (window && window.localStorage) {
        window.localStorage.removeItem('accountingTab');
      }
    }
  }, []);

  const [redirect, setRedirect] = useState(false);

  /**
   * Записываем в localStorage, что мы переходим на страницу
   * Регистрация дохода со страницы Отчетность/Сделки
   * * */
  const handleRequestsIncomeRegistrationLink = (data) => {
    setAnnulCheckStatus(null);
    setAnnulCheckError(null);

    window.localStorage.setItem('isIncomeRegistered', '1');
    dispatch(setIncomeCheckData(data));
    setRedirect(true);
  };

  if (redirect) {
    return <Redirect to="/lk/income-registration" />;
  }

  return (
    <div className="accounting">
      {/* ЗАГОЛОВОК */}
      <CSSTransition
        classNames="animation-text"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="accounting__top">
          <h1 className="accounting__title">Отчетность</h1>
        </div>
      </CSSTransition>
      {/* /ЗАГОЛОВОК */}

      {/* НАВИГАЦИЯ: НАЛОГИ/СДЕЛКИ */}
      <CSSTransition
        classNames="animation-text"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
        unmountOnExit
      >
        <TabsWithScrollableActiveState
          className="accounting__nav-wrap"
          tabArray={navTabsArray}
          currentTabId={currentFormTab}
          isRendered={isRendered}
        />
      </CSSTransition>
      {/* /НАВИГАЦИЯ: НАЛОГИ/СДЕЛКИ */}

      <div className="accounting__content-wrap">
        {/* ЕСЛИ ЮЗЕР - САМОЗАНЯТЫЙ - ПОКАЗЫВАЕМ ОТЧЕТНОСТЬ */}
        {(userTaxStatus === YES || (szStatusFromDb && szStatusFromDb === PARTLY_YES)) && (
          <div
            className={cx('accounting__content', {
              accounting__content_deals: currentFormTab === 1,
            })}
          >
            {currentFormTab === 0 && (
              <div className="accounting__content-tab">
                {/* НАЛОГ К ОПЛАТЕ */}
                <animated.div
                  className="accounting__item accounting__item_taxes-to-pay-card"
                  style={{
                    opacity: spring1.opacity,
                    transform: spring1.Tab0Y.interpolate(transTab0Y),
                  }}
                >
                  <TaxesToPayCard
                    taxInfo={taxInfo}
                    isTaxInfoLoading={isTaxInfoLoading}
                    isTaxInfoError={isTaxInfoError}
                    taxInfoErrorMessage={taxInfoErrorMessage}
                    prevMonthTax={prevMonthTax}
                    isPrevMonthTaxLoading={isYearIncomesLoading}
                  />
                </animated.div>
                {/* /НАЛОГ К ОПЛАТЕ */}

                <div className="accounting__content-tab-list">
                  {/* НАЛОГИ - СТАТИСТИКА */}
                  <animated.div
                    className="accounting__item accounting__item_taxes"
                    style={{
                      opacity: spring2.opacity,
                      transform: spring2.Tab0Y.interpolate(transTab0Y),
                    }}
                  >
                    <Taxes />
                  </animated.div>
                  {/* /НАЛОГИ - СТАТИСТИКА */}

                  <animated.div
                    className="accounting__item accounting__item_income-card"
                    style={{
                      opacity: spring3.opacity,
                      transform: spring3.Tab0Y.interpolate(transTab0Y),
                    }}
                  >
                    <TaxesIncomeCard
                      now={now}
                      taxInfo={taxInfo}
                      isTaxInfoLoading={isTaxInfoLoading}
                      isTaxInfoError={isTaxInfoError}
                      taxInfoErrorMessage={taxInfoErrorMessage}
                      yearIncomes={yearIncomes}
                      isYearIncomesLoading={isYearIncomesLoading}
                      isYearIncomesFail={isYearIncomesFail}
                    />
                  </animated.div>

                  {/* СПРАВКА О ДОХОДЕ */}
                  <animated.div
                    className="accounting__item"
                    style={{
                      opacity: spring4.opacity,
                      transform: spring4.Tab0Y.interpolate(transTab0Y),
                    }}
                  >
                    <IncomeCertificateCard isFnsError={isFnsError} />
                  </animated.div>
                  {/* /СПРАВКА О ДОХОДЕ */}

                  {/* О ПОСТАНОВКЕ НА УЧЕТ */}
                  <animated.div
                    className="accounting__item"
                    style={{
                      opacity: spring5.opacity,
                      transform: spring5.Tab0Y.interpolate(transTab0Y),
                    }}
                  >
                    <Service
                      className="service_small"
                      title="О постановке на учет"
                      desc="Получить для банков или заключения договоров"
                      isWhite
                      icon={<IconServiceDocument2 className="service__icon" />}
                      disabled={isFnsError}
                      isOutAppLink
                      isLink
                      href={
                        !isFnsError
                          ? `${
                              process.env.REACT_APP_API_URL
                            }/certificate/registration?p=${new Date().getTime()}`
                          : null
                      }
                    />
                  </animated.div>
                  {/* /О ПОСТАНОВКЕ НА УЧЕТ */}
                </div>
              </div>
            )}

            {currentFormTab === 1 && (
              <div className="accounting__content-tab">
                {/* СДЕЛКИ */}
                <animated.div
                  className="accounting__deals-wrap"
                  style={{
                    opacity: spring6.opacity,
                    transform: spring6.Tab1Y.interpolate(transTab1Y),
                  }}
                >
                  <Deals
                    dealsItemClickHandler={dealsItemClickHandler}
                    isCheckOpen={isCheckOpen}
                    className="accounting__deals"
                  />

                  {/* ЧЕК */}
                  {currentFormTab === 1 && checkData && isCheckOpen && (
                    <div className="accounting__check-wrap">
                      <Check
                        className="accounting__check"
                        checkData={checkData}
                        isCheckOpen={isCheckOpen}
                        closeCheckButtonClick={closeCheckButtonClick}
                        isOnDealsPage
                      />

                      <div className="accounting__annul-check-button-wrap">
                        <button
                          type="button"
                          className="accounting__annul-check-button"
                          onClick={() => handleRequestsIncomeRegistrationLink(checkData)}
                        >
                          Подробнее
                        </button>
                      </div>
                    </div>
                  )}
                  {/* /ЧЕК */}
                </animated.div>
                {/* /СДЕЛКИ */}
              </div>
            )}
          </div>
        )}
        {/* /АННУЛИРОВАТЬ ЧЕК */}

        {/* НЕСАМОЗАНЯТЫЙ - ПОКАЗЫВАЕМ СООБЩЕНИЕ ОБ ЭТОМ */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={
            isRendered &&
            (!userTaxStatus ||
              (userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES)))
          }
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <LockForNonSelfEmployed
            className="accounting__lock animation-from-bottom-to-to"
            link="/self-employment-from-lk"
          />
        </CSSTransition>
        {/* /НЕСАМОЗАНЯТЫЙ - ПОКАЗЫВАЕМ СООБЩЕНИЕ ОБ ЭТОМ */}
      </div>

      {/* ПОПАП - АННУЛИРОВАТЬ ЧЕК */}
      {checkData && (
        <CSSTransition in={isHintOpen} timeout={HINT_TIMEOUTS} unmountOnExit classNames="hint-auth">
          <HintAuth ref={hintEl} onCloseBtnClick={closeHintExplicitly} className="accounting__hint">
            <HintAnnulCheck
              onSubmit={handleAnnulButtonClick}
              status={annulCheckStatus}
              error={annulCheckError}
              key={checkData.id}
              closeExplicitly={closeHintExplicitly}
              szStatusFromDb={szStatusFromDb}
            />
          </HintAuth>
        </CSSTransition>
      )}
      {/* /ПОПАП - АННУЛИРОВАТЬ ЧЕК */}

      <CSSTransition
        in={
          window &&
          window.location &&
          window.location.href.indexOf('fns-problem') > -1 &&
          isErrorFnsPopupOpen
        }
        timeout={HINT_TIMEOUTS}
        unmountOnExit
      >
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          mounted={
            window &&
            window.location &&
            window.location.href.indexOf('fns-problem') > -1 &&
            isErrorFnsPopupOpen
          }
          emoji={emodjiSad}
          closePopup={() => setIsErrorFnsPopupOpen(false)}
          title="Упс!"
          buttonArray={[
            {
              id: 1,
              title: 'Вернуться',
              action: () => setIsErrorFnsPopupOpen(false),
              colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
            },
          ]}
        >
          <p>
            К сожалению сервис ФНС не доступен на данный момент, попробуйте скачать справку позже.
            Если проблема не решится, <span className="js-help">напишите нам</span>
          </p>
        </SmallPopupWithEmoji>
      </CSSTransition>
    </div>
  );
};

Accounting.propTypes = {
  userTaxStatus: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  startZoneOffset: PropTypes.number.isRequired,
  startCurrentTime: PropTypes.number.isRequired,
  szStatusFromDb: PropTypes.string,
};

Accounting.defaultProps = {
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  startZoneOffset: state.auth.startZoneOffset || 0,
  startCurrentTime: state.auth.startCurrentTime,
  szStatusFromDb: state.auth.szStatusFromDb,
});

export default connect(mapStateToProps)(Accounting);
