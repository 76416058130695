import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from '@rehooks/window-size';
import { Link } from 'react-router-dom';

import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import { setHintOpen } from 'modules/authLegasy/actions';

import HintWrongINN from 'modules/authLegasy/components/Hint/HintWrongINN';
import HintNotRegisteredInFNS from 'modules/authLegasy/components/Hint/HintNotRegisteredInFNS';
import WarningText from 'modules/profileLegasy/components/Warning/WarningText';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SESteps from 'modules/profileLegasy/components/SESteps/SESteps';
import Balloon from 'modules/shared/components/Balloon/Balloon';
import GetSzInnInput from 'modules/shared/components/GetSzInnInput/GetSzInnInput';

import { CONSISTENT_ANIMATIONS_DELAY, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as Close } from 'static/assets/icon-close.svg';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';
import profileBg from 'static/assets/profile-bg.png';
import iconSad from 'static/assets/icon-emoji-sad.png';

const FAIL = 'FAIL';

export const SEFromLKAlreadyRegistered = ({ className, userINN, onChangeINN, dispatch }) => {
  const [currentHintId, setCurrentHintId] = useState(null);
  const [isRendered, setIsRendered] = useState(false);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  const onCloseBtnClick = () => {
    closeExplicitly();
    setCurrentHintId(null);
  };

  const toggleHint1 = () => {
    toggleDrop();
    setCurrentHintId(1);
  };

  const toggleHint2 = () => {
    toggleDrop();
    setCurrentHintId(2);
  };

  const hints = [
    {
      id: 1,
      title: 'Неверно указан ИНН',
      titleForPopup: 'Неверно указан ИНН',
      action: toggleHint1,
      openCondition: currentHintId === 1 && isDropOpen,
      popup: <HintWrongINN />,
      dropEl: dropEl,
    },
    {
      id: 2,
      title: 'Вы не состоите на учете ФНС',
      titleForPopup: 'Вы не состоите на учете ФНС',
      action: toggleHint2,
      openCondition: currentHintId === 2 && isDropOpen,
      popup: <HintNotRegisteredInFNS />,
      dropEl: dropEl,
    },
  ];

  return (
    <div
      className={cx('se-from-lk', {
        [className]: className,
      })}
      data-testid="se-from-lk-already-registered"
    >
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="se-from-lk__header">
          <div className="se-from-lk__header-container container container_small">
            <div className="se-from-lk__header-content">
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <SESteps className="se-from-lk__header-step" currentStep={2} totalSteps={3} />
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <h1 className="se-from-lk__header-title">
                  Данный ИНН закреплен к другой учетной записи
                </h1>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="se-from-lk__header-desc">
                  <IconInfo className="se-from-lk__header-desc-warning-icon" />
                  Такой пользователь уже зарегистрирован. Войдите под своей учетной записью или
                  напишите письмо в поддержку клиентов
                </div>
              </CSSTransition>

              {windowSize.innerWidth > 980 && (
                <>
                  <div className="se-from-lk__form">
                    <GetSzInnInput
                      value={userINN}
                      status={FAIL}
                      disabled
                      readOnly
                      className="se-from-lk__form-input-holder"
                      isEditButton
                      onChangeInn={onChangeINN}
                    />
                  </div>

                  <Balloon className="se-from-lk__info">
                    <div className="balloon__text">
                      Нельзя создавать несколько учетных записей для одного пользователя
                    </div>

                    <img
                      src={iconSad}
                      alt=""
                      className="balloon__icon se-from-lk__info-icon_wink"
                    />
                  </Balloon>
                </>
              )}
            </div>
          </div>

          <img src={profileBg} alt="Profile bg" className="se-from-lk__header-bg" />

          <Link to="/lk" className="se-from-lk__header-close">
            {windowSize.innerWidth > 980 ? (
              <Close className="partial-status__header-close-icon" />
            ) : (
              <IconArrow className="se-from-lk__header-close-icon" />
            )}
          </Link>

          {windowSize.innerWidth <= 980 && (
            <SESteps className="se-from-lk__header-step" currentStep={2} totalSteps={3} />
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
        unmountOnExit
      >
        <WarningText
          text="Такой пользователь уже зарегистрирован. Войдите под своей учетной записью или
                напишите письмо в поддержку клиентов"
          className="warning_static"
        />
      </CSSTransition>

      <div className="se-from-lk__content container container_small">
        <div className="se-from-lk__form">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <GetSzInnInput
              value={userINN}
              status={FAIL}
              disabled
              readOnly
              className="se-from-lk__form-input-holder"
              isEditButton
              onChangeInn={onChangeINN}
              isLockVisible={false}
            />
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
          unmountOnExit
        >
          <div className="se-from-lk__hint-block">
            <HintsList hints={hints} closeHintHandler={onCloseBtnClick} title="Возможные причины" />
          </div>
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
          unmountOnExit
        >
          <StandardButton
            title="Продолжить как физ.лицо"
            isLink
            href="/lk"
            bgType={GREY_BORDER}
            className="se-from-lk__btn-wrap js-have-to-handle"
          />
        </CSSTransition>
      </div>
    </div>
  );
};

SEFromLKAlreadyRegistered.propTypes = {
  className: PropTypes.string.isRequired,
  userINN: PropTypes.string.isRequired,
  onChangeINN: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userINN: state.auth.INN,
});

export default connect(mapStateToProps)(SEFromLKAlreadyRegistered);
