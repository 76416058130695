import { fetchWrapper } from 'helpers';

export const getContext = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/moneybox/context`;
  return fetchWrapper(url);
};

export const getMoneybox = (id) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/moneybox/${id}`;
  return fetchWrapper(url);
};
