export const SET_ACQUIRING_ACTIVE_TAB = 'SET_ACQUIRING_ACTIVE_TAB';
export const CREATE_DOMAIN_TOKEN_SUCCESS = 'CREATE_DOMAIN_TOKEN_SUCCESS';
export const CREATE_DOMAIN_TOKEN_FAIL = 'CREATE_DOMAIN_TOKEN_FAIL';
export const CONFIRM_DOMAIN_TOKEN_SUCCESS = 'CONFIRM_DOMAIN_TOKEN_SUCCESS';
export const CONFIRM_DOMAIN_TOKEN_FAIL = 'CONFIRM_DOMAIN_TOKEN_FAIL';
export const DOWNLOAD_DOMAIN_TOKEN_SUCCESS = 'DOWNLOAD_DOMAIN_TOKEN_SUCCESS';
export const DOWNLOAD_DOMAIN_TOKEN_FAIL = 'DOWNLOAD_DOMAIN_TOKEN_FAIL';
export const SET_CHOSEN_PAYMENT_WAYS_FILTERS = 'SET_CHOSEN_PAYMENT_WAYS_FILTERS';
export const SET_CHOSEN_PAYMENT_STATUS_FILTERS = 'SET_CHOSEN_PAYMENT_STATUS_FILTERS';
export const SET_CHOSEN_SUM_FROM_FILTERS = 'SET_CHOSEN_SUM_FROM_FILTERS';
export const SET_CHOSEN_SUM_UNTIL_FILTERS = 'SET_CHOSEN_SUM_UNTIL_FILTERS';
export const SET_SEARCH_WORD = 'SET_SEARCH_WORD';
export const CHANGE_ACQUIRING_SETTINGS_SUCCESS = 'CHANGE_ACQUIRING_SETTINGS_SUCCESS';
export const CHANGE_ACQUIRING_SETTINGS_FAIL = 'CHANGE_ACQUIRING_SETTINGS_FAIL';
export const GET_ACQUIRING_SETTINGS_SUCCESS = 'GET_ACQUIRING_SETTINGS_SUCCESS';
export const GET_ACQUIRING_SETTINGS_FAIL = 'GET_ACQUIRING_SETTINGS_FAIL';
export const UPDATE_MERCHANT_PAGE_STYLE_SUCCESS = 'UPDATE_MERCHANT_PAGE_STYLE_SUCCESS';
export const UPDATE_MERCHANT_PAGE_STYLE_FAIL = 'UPDATE_MERCHANT_PAGE_STYLE_FAIL';
export const GENERATE_MERCHANT_API_KEY_SUCCESS = 'GENERATE_MERCHANT_API_KEY_SUCCESS';
export const GENERATE_MERCHANT_API_KEY_FAIL = 'GENERATE_MERCHANT_API_KEY_FAIL';
export const SET_SEARCH_DATES_RANGE = 'SET_SEARCH_DATES_RANGE';
export const GENERATE_MERCHANTS_LIST_SUCCESS = 'GENERATE_MERCHANTS_LIST_SUCCESS';
export const GENERATE_MERCHANTS_LIST_FAIL = 'GENERATE_MERCHANTS_LIST_FAIL';
export const SET_ANALYTICS_DATES_RANGE = 'SET_ANALYTICS_DATES_RANGE';
export const SET_IS_OPERATIONS_CALENDAR_FILTER_OPEN = 'SET_IS_OPERATIONS_CALENDAR_FILTER_OPEN';
export const GET_MERCHANTS_OPERATIONS_LIST_SUCCESS = 'GET_MERCHANTS_OPERATIONS_LIST_SUCCESS';
export const GET_MERCHANTS_OPERATIONS_LIST_FAIL = 'GET_MERCHANTS_OPERATIONS_LIST_FAIL';
export const UPDATE_MERCHANT_PAYMENT_WAYS_SUCCESS = 'UPDATE_MERCHANT_PAYMENT_WAYS_SUCCESS';
export const UPDATE_MERCHANT_PAYMENT_WAYS_FAIL = 'UPDATE_MERCHANT_PAYMENT_WAYS_FAIL';
export const SET_FAKE_OPERATIONS = 'SET_FAKE_OPERATIONS';
export const SET_CHOSEN_SHOP = 'SET_CHOSEN_SHOP';
export const SET_CHOSEN_SHOP_STATUS = 'SET_CHOSEN_SHOP_STATUS';
