import PropTypes from 'prop-types';
import React from 'react';

// Styles
import './ApplePaySetupButton.scss';

const ApplePaySetupButton = ({ disableApplePayButton }) => {
  /** Клик по кнопке Apple Pay Setup */
  const handleApplePaySetup = () => {
    window.ApplePaySession.openPaymentSetup('merchant.ru.selfwork')
      .then(success => {
        if (success) {
          /* eslint-disable no-console */
          console.log('payment setup success');
        } else {
          console.log('payment setup failed');
        }
      })
      .catch(error => console.log(error));
    /* eslint-enable no-console */
  };

  return (
    <button
      aria-label="Настроить Apple Pay"
      type="button"
      className="apple-pay-set-up-button"
      onClick={handleApplePaySetup}
      disabled={disableApplePayButton}
    />
  );
};

ApplePaySetupButton.propTypes = {
  disableApplePayButton: PropTypes.bool,
};

ApplePaySetupButton.defaultProps = {
  disableApplePayButton: false,
};

export default ApplePaySetupButton;
