import { fetchWrapper } from 'helpers';

/**
 * [API] URL для скачивания документа
 * @param {'PERSONAL'|'BUSINESS'} docPlatform - платформа
 * @param {number} docId - ID документа
 * @return {string}
 */
export const getUrlOfDownloadDocumentAPI = (docPlatform, docId) =>
  `${process.env.REACT_APP_API_URL}/partnerships/${docPlatform}/documents/${docId}/download`;

/**
 * [API] Запрос на отправку СМС-кода для авторизованного подтверждения документа
 * @param {'PERSONAL'|'BUSINESS'} docPlatform - платформа
 * @param {number} docId - ID документа
 * @return {data<obj>}
 */
export const signOfConfirmDocumentAPI = async (docPlatform, docId) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partnerships/${docPlatform}/documents/${docId}/sign`;

    const response = await fetchWrapper(url, { method: 'POST' });
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};

/**
 * [API] Запрос на повторную отправку СМС-кода для авторизованного подтверждения документа
 * @param {number} id - ID документа
 * @return {data<obj>}
 */
export const resignOfConfirmDocumentAPI = async (id) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/pinaccept/resend/${id}`;

    const response = await fetchWrapper(url);
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};

/**
 * [API] Запрос для авторизованного подтверждения документа после получения СМС
 * @param {number} actionPinAcceptId - id операции из запроса getWalletClosingSmsCode
 * @param {string} pin - пин-код из смс
 * @return {data<obj>}
 */
export const signConfirmDocumentAPI = async (actionPinAcceptId, pin) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partnerships/documents/sign/confirm`;
    const body = JSON.stringify({
      actionPinAcceptId,
      pin,
    });

    const response = await fetchWrapper(url, { method: 'POST', body });
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};

/**
 * [API] Запрос для отклонения документа
 * @param {'PERSONAL'|'BUSINESS'} docPlatform - платформа
 * @param {number} docId - ID документа
 * @return {data<obj>}
 */
export const declineDocumentAPI = async (docPlatform, docId) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partnerships/${docPlatform}/documents/${docId}/decline`;

    const response = await fetchWrapper(url, { method: 'POST' });
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};
