import React from 'react';
import { ReactComponent as PostEmptyIcon } from 'static/assets/icon-post-empty.svg';
import styles from './StubPayments.module.scss';

const StubPayments = () => (
  <div className={styles.stubPayments}>
    <PostEmptyIcon className={styles.stubPaymentsIcon} />
    <div className={styles.stubPaymentsText}>Здесь отображаются полученные выплаты</div>
  </div>
);

export default StubPayments;
