import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';

import Service from 'modules/profileLegasy/components/Service/Service';

import { ReactComponent as IconServiceDocument } from 'static/assets/icon-service-document.svg';

import './IncomeCertificateCard.scss';

const IncomeCertificateCard = ({ isFnsError }) => {
  const [isYearsListShown, setIsYearsListShown] = useState(false);
  const [years, setYears] = useState([]);
  const startYear = 2019;
  const currentYear = new Date().getFullYear();

  useEffect(() => {
    for (let i = startYear; i <= currentYear; i++) {
      setYears((prevState) =>
        [...prevState].concat([
          {
            id: i,
            title: i,
          },
        ]),
      );
    }
  }, [startYear, currentYear]);

  return (
    <>
      {isYearsListShown && !isFnsError ? (
        <div className="income-certificate-card__card">
          <h3 className="income-certificate-card__title">Выберите год формирования</h3>

          <div className="income-certificate-card__list">
            {years &&
              Array.isArray(years) &&
              years.length >= 1 &&
              years.map((item) => (
                <a
                  href={`${process.env.REACT_APP_API_URL}/certificate/income-statement?year=${
                    item.title
                  }&p=${new Date().getTime()}`}
                  className="income-certificate-card__year"
                  target="_blank"
                  rel="noopener noreferrer"
                  key={item.id}
                  onClick={() => setIsYearsListShown(false)}
                >
                  {item.title}
                </a>
              ))}
          </div>
        </div>
      ) : (
        <Service
          className="service_small"
          title="Справка о доходе"
          desc="Получить справку о доходах. Она необходима для получения кредита"
          isWhite
          icon={<IconServiceDocument className="service__icon" />}
          disabled={isFnsError}
          isLink={false}
          handleClick={() => setIsYearsListShown(true)}
        />
      )}
    </>
  );
};

IncomeCertificateCard.propTypes = {
  isFnsError: PropTypes.bool.isRequired,
};

export default IncomeCertificateCard;
