import React from 'react';
import { ReactComponent as UmoneyLogo } from 'static/assets/umoney-logo.svg';
import { ReactComponent as WalletIcon } from 'static/assets/wallet.svg';
import './CardHeaderUMoney.scss';

const CardHeaderUMoney = () => (
  <div className="money-transfer-card-header-umoney">
    <UmoneyLogo className="money-transfer-card-header-umoney__logo" />
    <WalletIcon className="money-transfer-card-header-umoney__wallet" />
  </div>
);

export default CardHeaderUMoney;
