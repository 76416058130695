/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

import {
  declineDocumentAPIThunk,
  resignOfConfirmDocumentAPIThunk,
  signConfirmDocumentAPIThunk,
  signOfConfirmDocumentAPIThunk,
} from './thunk';

export const REQUEST_TYPE = {
  signOfApprov: 'signOfApprov',
  approv: 'approv',
  reject: 'reject',
  error: null,
};

const initialState = {
  confirmDocumentData: null,
  confirmDocumentRequest: {
    state: null,
    pending: false,
    fulfilled: false,
    rejected: false,
  },
};

const rejectedAction = (state, action) => {
  state.confirmDocumentRequest.pending = false;
  state.confirmDocumentRequest.rejected = true;
  state.confirmDocumentRequest.error = action.payload;
};

const pendingAction = (type) => (state) => {
  state.confirmDocumentRequest = {
    requestType: type,
    pending: true,
    fulfilled: false,
    rejected: false,
    error: null,
  };
};

const fulfilledAction =
  (isSaveData = true) =>
  (state, action) => {
    state.confirmDocumentRequest.pending = false;
    state.confirmDocumentRequest.fulfilled = true;
    if (isSaveData) state.confirmDocumentData = action.payload;
  };

const confirmDocumentSlice = createSlice({
  name: 'confirmDocument',
  initialState,
  reducers: {
    clearAction: () => initialState,
  },
  extraReducers: {
    [signOfConfirmDocumentAPIThunk.pending]: pendingAction(REQUEST_TYPE.signOfApprov),
    [signOfConfirmDocumentAPIThunk.fulfilled]: fulfilledAction(),
    [signOfConfirmDocumentAPIThunk.rejected]: rejectedAction,
    [resignOfConfirmDocumentAPIThunk.pending]: pendingAction(REQUEST_TYPE.signOfApprov),
    [resignOfConfirmDocumentAPIThunk.fulfilled]: fulfilledAction(false),
    [resignOfConfirmDocumentAPIThunk.rejected]: rejectedAction,
    [signConfirmDocumentAPIThunk.pending]: pendingAction(REQUEST_TYPE.approv),
    [signConfirmDocumentAPIThunk.fulfilled]: fulfilledAction(),
    [signConfirmDocumentAPIThunk.rejected]: rejectedAction,
    [declineDocumentAPIThunk.pending]: pendingAction(REQUEST_TYPE.reject),
    [declineDocumentAPIThunk.fulfilled]: fulfilledAction(),
    [declineDocumentAPIThunk.rejected]: rejectedAction,
  },
});

export const { clearAction } = confirmDocumentSlice.actions;
const { reducer } = confirmDocumentSlice;
export { reducer as confirmDocumentReducer };
