import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { getLimits, setHintOpen } from 'modules/authLegasy/actions';
import HintSelfEmployed from 'modules/authLegasy/components/Hint/HintSelfEmployed';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

// Misc
import { CONSISTENT_ANIMATIONS_DELAY, HINT_TIMEOUTS } from 'constants/index';

const RegistrationSuccess = ({ className, isMenuOpen, dispatch }) => {
  // eslint-disable-next-line
  const [isDropOpened, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpened));
    /* eslint-disable */
  }, [isDropOpened]);
  /* eslint-enable */

  /** Делаем запрос на получение данных по лимитам */
  useEffect(() => {
    dispatch(getLimits()).catch(() => {});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  return (
    <>
      <div
        className={cx('register', {
          [className]: className,
        })}
      >
        <div className="register__container container">
          <div className="register__content">
            <CSSTransition
              classNames="animation-text"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
              unmountOnExit
            >
              {() => <h1 className="register__title animation-text">Ваш счет открыт!</h1>}
            </CSSTransition>

            <CSSTransition
              classNames="animation-text"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="register__desc-wrap">
                <div className="register__desc">
                  Поздравляем с успешной регистрацией, теперь можно войти и начать пользоваться
                  Кошельком
                </div>
              </div>
            </CSSTransition>

            <CSSTransition
              classNames="animation-text"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="register__btn-list">
                <div className="register__btn">
                  <StandardButton isLink title="Войти в Кошелек" href="/login" />
                </div>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>

      <CSSTransition in={isDropOpened} timeout={HINT_TIMEOUTS} unmountOnExit classNames="hint-auth">
        <HintAuth
          ref={dropEl}
          onCloseBtnClick={closeExplicitly}
          title="Что дает статус самозанятого?"
          className="register__self-empliyment-sidebar"
          classNameInner="register__self-empliyment-inner"
        >
          <HintSelfEmployed />
        </HintAuth>
      </CSSTransition>
    </>
  );
};

RegistrationSuccess.propTypes = {
  className: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  phone: state.auth.phone,
  password: state.auth.password,
});

export default connect(mapStateToProps)(RegistrationSuccess);
