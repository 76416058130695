import 'react-app-polyfill/ie9';
import 'react-app-polyfill/stable';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import * as Sentry from '@sentry/react';
import { App } from './App';
import * as serviceWorker from './serviceWorker';
import TagManager from 'react-gtm-module';

if (process.env.REACT_APP_IS_PROD_BUILD === 'Y') {
  Sentry.init({
    beforeSend(event, hint) {
      if (hint.originalException === 'Timeout') return null;
      return event;
    },
    allowUrls: ['localhost', 'https://pro.selfwork.ru', 'https://test.pro.selfwork.ru'],
    ignoreErrors: [
      'top.GLOBALS',
      'originalCreateNotification',
      'canvas.contentDocument',
      'MyApp_RemoveAllHighlights',
      'http://tt.epicplay.com',
      "Can't find variable: ZiteReader",
      'jigsaw is not defined',
      'ComboSearch is not defined',
      'http://loading.retry.widdit.com/',
      'atomicFindClose',
      'fb_xd_fragment',
      'bmi_SafeAddOnload',
      'EBCallBackMessageReceived',
      'conduitPage',
      'Script error.',
      '_avast_submit',
      'Failed to fetch',
      '/^resource:\\/\\//i',
      '{"error":"NotAuthenticated","error_message":"Учетные данные не были предоставлены.","status":401}',
      'Non-Error promise rejection captured with keys: data',
      'Content-Security-Policy',
      'BotsNotAllowedError',
      'processRandomSelector',
      'ReferenceError',
      'NotAllowedError',
      'SecurityError',
      'Timeout',
      'ServiceWorker',
      'TypeError: undefined is not a function',
      'SyntaxError: In strict mode code',
      "Failed to read the 'contentDocument' property from 'HTMLIFrameElement'",
      'carrotquest',
      'runCustomize',
      "Unexpected token 'function'",
      'Unexpected identifier',
    ],
    denyUrls: [
      /pagead\/js/i,
      /graph\.facebook\.com/i,
      /connect\.facebook\.net\/en_US\/all\.js/i,
      /eatdifferent\.com\.woopra-ns\.com/i,
      /static\.woopra\.com\/js\/woopra\.js/i,
      /extensions\//i,
      /^chrome:\/\//i,
      /127\.0\.0\.1:4001\/isrunning/i,
      /webappstoolbarba\.texthelp\.com\//i,
      /metrics\.itunes\.apple\.com\.edgesuite\.net\//i,
      /mc\.yandex\.ru/i,
      /api\.carrotquest\.app/i,
      /api\.carrottrack\.app/i,
      /cdn\.carrotquest\.app/i,
    ],
    dsn: 'https://3e99d5d3ce674cf08c8a555e29b8a542@o346501.ingest.sentry.io/4504876909395968',
    integrations: [],
    tracesSampleRate: 0.5,
    replaysSessionSampleRate: 0,
    replaysOnErrorSampleRate: 0,
  });

  const tagManagerArgs = {
    gtmId: 'GTM-M9D6SRZ5',
  };

  TagManager.initialize(tagManagerArgs);
}

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
