import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import PartnerBankCard from '../PartnerBankCard';
import { getEditedPartnership } from 'modules/partners/selectors';
import { editPartnerBankCard, hidePopupEditBankCard } from '../../actions';

const PopupEditBankCard = ({ dispatch, partnership }) => {
  const notice = 'Вы можете изменить карту, на которую будете получать выплаты в любой момент.';

  function onEditCard(cardNumber) {
    dispatch(editPartnerBankCard(cardNumber, partnership.partner.platform, partnership.id));
  }

  function onClose() {
    dispatch(hidePopupEditBankCard());
  }

  return createPortal(
    <PartnerBankCard
      title="Карта для выплат:"
      notice={notice}
      buttonText="Сохранить"
      onFinish={onEditCard}
      onClose={onClose}
    />,
    document.body,
  );
};

PopupEditBankCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  partnership: PropTypes.shape({}).isRequired,
};

const mapStateToProps = (state) => ({
  partnership: getEditedPartnership()(state),
});

export default connect(mapStateToProps)(PopupEditBankCard);
