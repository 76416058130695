import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as IconReturn } from 'static/assets/icon-return-arrow.svg';

import './ReturnButton.scss';

const ReturnButton = ({ className, onClick }) => {
  return (
    <button
      type="button"
      className={cx('return-button', {
        [className]: className,
      })}
      onClick={onClick}
      data-testid="return-button"
    >
      <span className="return-button__text">Вернутся</span>
      <IconReturn className="return-button__icon" />
    </button>
  );
};

ReturnButton.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

ReturnButton.defaultProps = {
  className: null,
};

export default ReturnButton;
