import React from 'react';
import { Switch, Route } from 'react-router-dom';
import SaveMoney from 'modules/cashbox/components/SaveMoney/SaveMoney';

/**
 * ШАБЛОН: КОПИЛКА
 */
export const SaveMoneyTemplate = () => (
  <Switch>
    {/* СТРАНИЦЫ РЕЗУЛЬТАТА ПЕЕРЕВОДА ДЕНЕГ НА КОПИЛКУ */}
    <Route
      exact
      path={[
        '/pay-it/moneybox/:operationId/wait',
        '/pay-it/moneybox/:operationId/error/:moneyboxId',
        '/pay-it/moneybox/:moneyboxId/error',
        '/pay-it/moneybox/error',
        '/pay-it/moneybox/:operationId/success/:moneyboxId',
      ]}
    >
      <SaveMoney />
    </Route>
    {/* /СТРАНИЦЫ РЕЗУЛЬТАТА ПЕЕРЕВОДА ДЕНЕГ НА КОПИЛКУ */}

    <Route path="/kopim/:moneyboxId">
      <SaveMoney /> {/* КОПИЛКА */}
    </Route>

    <Route path="/moneybox/:moneyboxId">
      <SaveMoney />
    </Route>
  </Switch>
);
