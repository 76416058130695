import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';

import { BLUE_BORDER } from 'constants/index';

import './TaxList.scss';

const TaxList = ({
  className,
  taxList,
  addTaxToACheckedList,
  checkedTaxes,
  saveSumByClickOnOnePayButton,
}) => {
  const windowSize = useWindowSize();

  return (
    <div
      className={cx('tax-list', {
        [className]: className,
      })}
      data-testid="tax-list"
    >
      {windowSize.innerWidth > 980 && (
        <div className="tax-list__header">
          <div className="tax-list__column">Назначение</div>
          <div className="tax-list__column">Тип налога</div>
          <div className="tax-list__column">Сумма</div>
        </div>
      )}

      <div className="tax-list__main">
        {taxList &&
          taxList.length >= 1 &&
          taxList.map((item) => (
            <label
              key={item.documentIndex}
              className={cx('tax-list__item', {
                'tax-list__item_not_only-child': taxList.length > 1,
              })}
              data-testid="tax-list-item"
            >
              {windowSize.innerWidth > 980 ? (
                <>
                  <div className="tax-list__column tax-list__purpose-wrap">
                    {taxList.length > 1 && (
                      <button
                        role="checkbox"
                        type="button"
                        aria-label="Добавляем налог в список для оплаты"
                        aria-checked={
                          checkedTaxes &&
                          Object.prototype.hasOwnProperty.call(
                            checkedTaxes,
                            `tax-${item.documentIndex}`,
                          )
                        }
                        className="tax-list__checkbox"
                        onClick={() => addTaxToACheckedList(item.documentIndex, item.amount)}
                      />
                    )}

                    <div className="tax-list__purpose">{item.recipient}</div>
                  </div>
                  <div className="tax-list__column tax-list__type">
                    {item.type === 'DEBT' && 'Здолженность'}
                    {item.type === 'PENALTY' && 'Пеня'}
                    {item.type === 'TAX' && 'НПД'}
                    {item.type !== 'TAX' &&
                      item.type !== 'PENALTY' &&
                      item.type !== 'DEBT' &&
                      item.type}
                  </div>
                  <div className="tax-list__column">
                    <TaxesAmount className="tax-list__sum" amount={item.amount} />

                    <Link
                      className="tax-list__pay-button"
                      to="/lk/tax-payment"
                      onClick={() =>
                        saveSumByClickOnOnePayButton([
                          { documentIndex: item.documentIndex, amount: item.amount },
                        ])
                      }
                      data-testid="tax-list-pay-button"
                    >
                      Оплатить
                    </Link>
                  </div>
                </>
              ) : (
                <>
                  <div className="tax-list__item-main" data-testid="tax-list-item-main">
                    <div className="tax-list__column tax-list__purpose-wrap">
                      {taxList.length > 1 && (
                        <button
                          role="checkbox"
                          type="button"
                          aria-label="Добавляем налог в список для оплаты"
                          aria-checked={
                            checkedTaxes &&
                            Object.prototype.hasOwnProperty.call(
                              checkedTaxes,
                              `tax-${item.documentIndex}`,
                            )
                          }
                          className="tax-list__checkbox"
                          onClick={() => addTaxToACheckedList(item.documentIndex, item.amount)}
                        />
                      )}

                      <div className="tax-list__purpose">{item.recipient}</div>
                    </div>

                    <div className="tax-list__column">
                      <TaxesAmount className="tax-list__sum" amount={item.amount} />

                      <div className="tax-list__type">
                        {item.type === 'DEBT' && 'Здолженность'}
                        {item.type === 'PENALTY' && 'Пеня'}
                        {item.type === 'TAX' && 'НПД'}
                        {item.type !== 'TAX' &&
                          item.type !== 'PENALTY' &&
                          item.type !== 'DEBT' &&
                          item.type}
                      </div>
                    </div>
                  </div>

                  {taxList.length > 1 && (
                    <div className="tax-list__item-bottom">
                      <StandardButton
                        isLink
                        href="/lk/tax-payment"
                        title="Оплатить"
                        onClick={() =>
                          saveSumByClickOnOnePayButton([
                            { documentIndex: item.documentIndex, amount: item.amount },
                          ])
                        }
                        bgType={BLUE_BORDER}
                        className="tax-list__item-button"
                      />
                    </div>
                  )}
                </>
              )}
            </label>
          ))}
      </div>
    </div>
  );
};

TaxList.propTypes = {
  className: PropTypes.string,
  taxList: PropTypes.arrayOf(
    PropTypes.shape({
      documentIndex: PropTypes.string,
      recipient: PropTypes.string,
      type: PropTypes.string,
      amount: PropTypes.number,
      month: PropTypes.string,
    }),
  ),
  checkedTaxes: PropTypes.shape({
    id: PropTypes.number,
    sum: PropTypes.number,
  }),
  addTaxToACheckedList: PropTypes.func.isRequired,
  saveSumByClickOnOnePayButton: PropTypes.func.isRequired,
};

TaxList.defaultProps = {
  className: null,
  checkedTaxes: {},
  taxList: [],
};

export default TaxList;
