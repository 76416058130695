import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import useWindowSize from '@rehooks/window-size';
import { CSSTransition } from 'react-transition-group';
import { connect } from 'react-redux';

// Components
import SmallCircleLoader from 'modules/authLegasy/components/SmallCircleLoader/SmallCircleLoader';

// Hooks
import useFocus from 'hooks/useFocus';

// Icons
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';

// Styles
import './CodeBlock.scss';

const CodeBlock = (props) => {
  const {
    isMenuOpen,
    title,
    infoText,
    onSendCode,
    onRequestNewSms,
    className,
    isError,
    errorMessage,
    isCodeValid,
    isCodeChecking,
    isUserBlocked,
    isTimeEnded,
    seconds,
    isInSidebar,
  } = props;
  const windowSize = useWindowSize();

  const [pin, setPin] = useState(new Array(4).fill(''));

  const codeInput1 = useRef(null);
  const codeInput2 = useRef(null);
  const codeInput3 = useRef(null);
  const codeInput4 = useRef(null);
  const codeInputs = [codeInput1, codeInput2, codeInput3, codeInput4];
  const [currentInputIndex, setCurrentInputIndex] = useState(0);

  const [isRendered, setIsRendered] = useState(false);
  const [isCodeCSSTransEntered, setIsCodeCSSTransEntered] = useState(false);

  const duration = [
    {
      enter: 100,
      exit: 0,
    },
    {
      enter: 200,
      exit: 0,
    },
    {
      enter: 300,
      exit: 0,
    },
    {
      enter: 400,
      exit: 0,
    },
    {
      enter: 500,
      exit: 0,
    },
    {
      enter: 600,
      exit: 0,
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    if (!isCodeCSSTransEntered) {
      return;
    }

    /** Set focus on next input automatically */
    if (codeInputs[currentInputIndex].current.value.length === 1 && currentInputIndex < 3) {
      setCurrentInputIndex(currentInputIndex + 1);
      codeInputs[currentInputIndex + 1].current.focus();
    }

    /** Check code when it's full */
    if (pin && pin.every((symbol) => symbol !== '')) {
      onSendCode(pin);
    }

    /* eslint-disable */
  }, [pin, currentInputIndex, isCodeCSSTransEntered]);
  /* eslint-enable */

  useEffect(() => {
    if (isError) {
      setPin(new Array(4).fill(''));
      setCurrentInputIndex(0);

      if (document.activeElement) {
        document.activeElement.blur();
      }
    }
  }, [isError]);

  /** Pressing backspace */
  const handleBackSpacePress = (e) => {
    if (e.keyCode === 8 && currentInputIndex >= 1) {
      setCurrentInputIndex(currentInputIndex - 1);
      codeInputs[currentInputIndex - 1].current.focus();
    }
  };

  /** Focus state of all inputs */
  const focused = useFocus(codeInputs, [isCodeCSSTransEntered]);

  /** Prevent user fill symbols except numbers */
  const handleChangePin = (value, pinIndex) => {
    setPin((prevState) =>
      prevState.map((item, index) => (index === pinIndex ? value.replace(/[\D]/g, '') : item)),
    );
  };

  return (
    <div
      className={cx('code-block', {
        [className]: className,
        'code-block_sidebar': isInSidebar,
      })}
    >
      {/* TITLE */}
      <CSSTransition
        classNames="animation-text"
        in={!isMenuOpen && isRendered && !isUserBlocked}
        timeout={duration[0]}
        unmountOnExit
      >
        <h1 className="code-block__title animation-text">{title}</h1>
      </CSSTransition>
      {/* /TITLE */}

      {/* INFO */}
      <CSSTransition
        classNames="code-block__info animation-text"
        in={!isMenuOpen && isRendered}
        timeout={duration[1]}
        unmountOnExit
      >
        <p className="code-block__info animation-text">{infoText}</p>
      </CSSTransition>
      {/* /INFO */}

      <CSSTransition
        classNames="animation-text"
        in={!isMenuOpen && isRendered}
        timeout={duration[2]} //
        unmountOnExit
        onEntered={() => {
          setIsCodeCSSTransEntered(true);
        }}
      >
        <div className="code-block__content">
          <div className="code-block__block">
            <div
              className={cx('code-block__input-holder', {
                'code-block__input-holder_blur': !focused[0] && !pin[0],
                'code-block__input-holder_error': isUserBlocked || isError,
              })}
            >
              <input
                type="text"
                inputMode="decimal"
                className={cx('code-block__input', {
                  'code-block__input_fail': isError,
                  'code-block__input_disabled code-block__input_fail': isUserBlocked,
                })}
                disabled={isUserBlocked}
                maxLength={1}
                ref={codeInputs[0]}
                onChange={({ target }) => {
                  handleChangePin(target.value.slice(0, 1).replace(/\D/, ''), 0);
                }}
                value={pin[0]}
                data-cy="code-block-input-1"
              />
            </div>

            <div
              className={cx('code-block__input-holder', {
                'code-block__input-holder_blur': !focused[1] && !pin[1],
                'code-block__input-holder_error': isUserBlocked || isError,
              })}
            >
              <input
                type="text"
                inputMode="decimal"
                className={cx('code-block__input', {
                  'code-block__input_fail': isError,
                  'code-block__input_disabled code-block__input_fail': isUserBlocked,
                })}
                disabled={isUserBlocked}
                max={1}
                ref={codeInputs[1]}
                onChange={({ target }) => {
                  handleChangePin(target.value.slice(0, 1).replace(/\D/, ''), 1);
                }}
                value={pin[1]}
                onKeyDown={handleBackSpacePress}
                data-cy="code-block-input-2"
              />
            </div>

            <div
              className={cx('code-block__input-holder', {
                'code-block__input-holder_blur': !focused[2] && !pin[2],
                'code-block__input-holder_error': isUserBlocked || isError,
              })}
            >
              <input
                type="text"
                inputMode="decimal"
                className={cx('code-block__input', {
                  'code-block__input_fail': isError,
                  'code-block__input_disabled code-block__input_fail': isUserBlocked,
                })}
                disabled={isUserBlocked}
                max={1}
                ref={codeInputs[2]}
                onChange={({ target }) => {
                  handleChangePin(target.value.slice(0, 1).replace(/\D/, ''), 2);
                }}
                value={pin[2]}
                onKeyDown={handleBackSpacePress}
                data-cy="code-block-input-3"
              />
            </div>

            <div
              className={cx('code-block__input-holder', {
                'code-block__input-holder_blur': !focused[3] && !pin[3],
                'code-block__input-holder_error': isUserBlocked || isError,
              })}
            >
              <input
                type="text"
                inputMode="decimal"
                className={cx('code-block__input', {
                  'code-block__input_fail': isError,
                  'code-block__input_disabled code-block__input_fail': isUserBlocked,
                })}
                disabled={isUserBlocked}
                max={1}
                ref={codeInputs[3]}
                onChange={({ target }) => {
                  handleChangePin(target.value.slice(0, 1).replace(/\D/, ''), 3);
                }}
                value={pin[3]}
                onKeyDown={handleBackSpacePress}
                data-cy="code-block-input-4"
              />
            </div>

            {isCodeChecking && (
              <div className="code-block__small-loader">
                <SmallCircleLoader />
              </div>
            )}
            {isCodeValid && <IconCheck className="code-block__input-check" />}
          </div>

          {errorMessage && (
            <div className="code-block__error-wrap">
              <div className="code-block__error-message">{errorMessage}</div>
            </div>
          )}

          <CSSTransition
            classNames="animation-text"
            in={!isMenuOpen && isRendered && !isUserBlocked}
            timeout={duration[3]}
            unmountOnExit
          >
            <div className="code-block__tip animation-text">
              {!isTimeEnded && (
                <div className="code-block__tip-link">
                  Запросить повторно можно через 00:
                  {`${seconds.toString().padStart(2, '0')}`}
                </div>
              )}
              {isTimeEnded && (
                <button
                  className="code-block__tip-link__btn"
                  type="button"
                  onClick={onRequestNewSms}
                >
                  Запросить повторно
                </button>
              )}
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-text"
            in={
              isUserBlocked && windowSize.innerWidth < 950 && className === 'register__code-block'
            }
            timeout={duration[4]}
            unmountOnExit
          >
            <div className="code-block__hint-block">
              <div className="code-block__hint">
                <p className="code-block__hint-text">
                  Повторную регистрацию можно будет осуществить через 4 часа
                </p>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-text"
            in={!isMenuOpen && isRendered && isUserBlocked}
            timeout={duration[5]} //
            unmountOnExit
          >
            <div className="code-block__help-block">
              <p className="code-block__help-title">Что можно сделать?</p>
              <div className="code-block__help-content">
                <div className="code-block__help-link-wrap">
                  <button type="button" className="dotted-link js-help">
                    Написать в поддержку
                  </button>
                </div>
              </div>
            </div>
          </CSSTransition>
        </div>
      </CSSTransition>
    </div>
  );
};

CodeBlock.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  title: PropTypes.string,
  infoText: PropTypes.string,
  onSendCode: PropTypes.func.isRequired,
  onRequestNewSms: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  isError: PropTypes.bool.isRequired,
  isCodeValid: PropTypes.bool.isRequired,
  isCodeChecking: PropTypes.bool.isRequired,
  isUserBlocked: PropTypes.bool,
  errorMessage: PropTypes.string,
  seconds: PropTypes.number.isRequired,
  isTimeEnded: PropTypes.bool.isRequired,
  isOnRegistrationEnterCode: PropTypes.bool,
  userPhone: PropTypes.string,
  registrationStep: PropTypes.number,
  isInSidebar: PropTypes.bool,
};

CodeBlock.defaultProps = {
  title: null,
  infoText: null,
  errorMessage: '',
  isUserBlocked: null,
  isOnRegistrationEnterCode: false,
  userPhone: null,
  registrationStep: null,
  isInSidebar: false,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  phone: state.auth.phone,
  userPhone: state.auth.phone,
  registrationStep: state.auth.registrationStep,
});

export default connect(mapStateToProps)(CodeBlock);
