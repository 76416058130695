import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './ProfileSettingsDropdown.scss';

const ProfileSettingsDropdown = ({ activeTab, buttonsList }) => {
  return (
    <div className="profile-settings-dropdown" data-testid="profile-settings-dropdown">
      {buttonsList &&
        Array.isArray(buttonsList) &&
        buttonsList.map((item) => (
          <div className="profile-settings-dropdown__item-wrap" key={item.id}>
            <button
              type="button"
              className={cx('profile-settings-dropdown__item', {
                'profile-settings-dropdown__item_active': item.id === activeTab,
              })}
              onClick={item.onClick}
              aria-label="profile-settings-dropdown-button"
            >
              {item.title}
            </button>
          </div>
        ))}
    </div>
  );
};

ProfileSettingsDropdown.propTypes = {
  activeTab: PropTypes.string.isRequired,
  buttonsList: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.String,
      title: PropTypes.String,
      onClick: PropTypes.func,
    }),
  ).isRequired,
};

export default ProfileSettingsDropdown;
