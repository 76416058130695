import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import NumberFormat from 'react-number-format';

import './MoneyInput.scss';

const MoneyInput = ({ className, value, onChange, currencySign, isValid }) => {
  function isAllowed(values) {
    const { value } = values;

    if (value[0] === '.') {
      return false;
    }

    if (value[0] === '0') {
      return value[1] === '.';
    }

    return true;
  }

  return (
    <div className={classnames('money-input', className, !isValid && 'money-input_invalid')}>
      <NumberFormat
        type="text"
        inputMode={'decimal'}
        className="money-input__form"
        value={value}
        placeholder="Введите сумму"
        thousandSeparator=" "
        allowNegative={false}
        allowEmptyFormatting={false}
        decimalScale={2}
        isNumericString
        allowedDecimalSeparators={[',', '.']}
        onValueChange={onChange}
        isAllowed={isAllowed}
      />
      {value.length > 0 && <span className="money-input__currency">{currencySign}</span>}
    </div>
  );
};

MoneyInput.defaultProps = {
  currencySign: '₽',
};

MoneyInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  currencySign: PropTypes.string,
  isValid: PropTypes.bool,
};

export default MoneyInput;
