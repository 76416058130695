import PropTypes from 'prop-types';
import React from 'react';
import { PTChildrenElement } from 'tools/types/propTypes';
import styled from 'styled-components';

import { ButtonUI } from '../../../../organisms';
import { TitleText } from '../../../TitleText';
import { WindowModal } from '../atoms';

export const WindowConfirm = ({
  onClose,
  header,
  emoji,
  children,
  approveButtonText,
  onApprove,
  rejektButtonText,
  onRejekt,
}) => {
  return (
    <WindowModal
      header={
        <HeaderWraperStyled>
          <TitleTextStyled>{header}</TitleTextStyled>
        </HeaderWraperStyled>
      }
      footer={
        <FooterWraperStyled>
          {!!rejektButtonText && (
            <ButtonUI.Light onClick={onRejekt}>{rejektButtonText}</ButtonUI.Light>
          )}
          {!!approveButtonText && (
            <ButtonUI.Normal onClick={onApprove}>{approveButtonText}</ButtonUI.Normal>
          )}
        </FooterWraperStyled>
      }
      onClose={onClose}
    >
      <BodyWraperStyled>
        <>
          {emoji}
          {children}
        </>
      </BodyWraperStyled>
    </WindowModal>
  );
};

WindowConfirm.propTypes = {
  header: PropTypes.string,
  emoji: PTChildrenElement,
  children: PTChildrenElement,
  onApprove: PropTypes.func.isRequired,
  onRejekt: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  approveButtonText: PropTypes.string.isRequired,
  rejektButtonText: PropTypes.string.isRequired,
};

WindowConfirm.defaultProps = {
  header: '',
  emoji: null,
  children: null,
};

const HeaderWraperStyled = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 400px) {
    margin-bottom: 30px;
  }
`;

const TitleTextStyled = styled(TitleText)`
  font-size: 36px;
  line-height: 40px;
  @media (max-width: 400px) {
    font-size: 22px;
    line-height: 26px;
  }
`;

const BodyWraperStyled = styled.div`
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  @media (max-width: 400px) {
    margin-bottom: 40px;
  }
`;
const FooterWraperStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
  & > * ~ * {
    margin-left: 20px;
  }
`;
