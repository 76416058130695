import { useState, useEffect } from 'react';

export const getLocalStorageValue = (key, defaultValue) => {
  // getting stored value
  if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined' &&
    window.localStorage?.getItem
  ) {
    const saved = window.localStorage.getItem(key);
    const initial = saved !== null ? JSON.parse(saved) : defaultValue;
    return initial;
  } else {
    return defaultValue;
  }
};

export const setLocalStorageValue = (key, value) => {
  // getting stored value
  if (
    typeof window !== 'undefined' &&
    typeof window.localStorage !== 'undefined' &&
    window.localStorage?.setItem
  ) {
    return window.localStorage.setItem(key, JSON.stringify(value));
  }
};

export const useLocalStorage = (key, defaultValue) => {
  const [value, setValue] = useState(() => {
    return getLocalStorageValue(key, defaultValue);
  });

  useEffect(() => {
    // storing input name
    if (
      typeof window !== 'undefined' &&
      typeof window.localStorage !== 'undefined' &&
      window.localStorage?.getItem
    ) {
      window.localStorage.setItem(key, JSON.stringify(value));
    }
  }, [key, value]);

  return [value, setValue];
};
