import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './TaxesToPayTooltip.scss';

const TaxesToPayTooltip = React.forwardRef((props, ref) => {
  const { isTooltipOpen, type } = props;

  const accountingList = [
    {
      id: 1,
      text: '- Сумма налога, которую нужно будет уплатить за прошлый месяц',
    },
    {
      id: 2,
      text: '- Ваш доход, полученный в текущем месяце',
    },
    {
      id: 3,
      text:
        '- Налоговая задолженность, т.е. сумма налога, которая не была уплачена в установленный срок',
    },
  ];
  const taxesToPayList = [
    {
      id: 1,
      text: '- Сумма налога за текущий месяц',
    },
    {
      id: 2,
      text: '- Сумма задолженности по уплате налога',
    },
    {
      id: 3,
      text: '- Сумма начисленных пени на задолженность',
    },
    {
      id: 4,
      text: '- Сумма корректировок из-за изменения региона работы',
    },
  ];
  const taxInfoCardList = [
    {
      id: 1,
      text: '- Предварительная сумма налога, которую нужно будет уплатить за текущий месяц',
    },
    {
      id: 2,
      text: '- Ваш доход, полученный в текущем месяце',
    },
    {
      id: 3,
      text:
        'Налоговая задолженность, т.е. сумма налога, которая не была уплачена в установленный срок',
    },
  ];

  return (
    <div
      className={cx('taxes-to-pay-tooltip', {
        'taxes-to-pay-tooltip_open': isTooltipOpen,
        'taxes-to-pay-tooltip_dark': type === 'TAXES_TO_PAY',
      })}
      ref={ref}
      data-testid="taxes-to-pay-tooltip"
    >
      {type === 'TAXES_TO_PAY' ? (
        <>
          <p className="taxes-to-pay-tooltip__text-title">Эта сумма считается исходя из:</p>
          <div className="taxes-to-pay-tooltip__text-wrap">
            {taxesToPayList.map(item => (
              <p className="taxes-to-pay-tooltip__text" key={item.id}>
                {item.text}
              </p>
            ))}
          </div>
        </>
      ) : (
        <>
          <p className="taxes-to-pay-tooltip__text-title">Здесь отображается:</p>
          <div className="taxes-to-pay-tooltip__text-wrap">
            {type === 'TAXES_INFO_CARD' ? (
              <>
                {taxInfoCardList.map(item => (
                  <p className="taxes-to-pay-tooltip__text" key={item.id}>
                    {item.text}
                  </p>
                ))}
              </>
            ) : (
              <>
                {accountingList.map(item => (
                  <p className="taxes-to-pay-tooltip__text" key={item.id}>
                    {item.text}
                  </p>
                ))}
              </>
            )}
          </div>
        </>
      )}
    </div>
  );
});

TaxesToPayTooltip.propTypes = {
  isTooltipOpen: PropTypes.bool.isRequired,
  type: PropTypes.string,
};

TaxesToPayTooltip.defaultProps = {
  type: 'ACCOUNTING',
};

export default TaxesToPayTooltip;
