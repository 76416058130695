import React from 'react';
import { Switch, Route } from 'react-router-dom';
import InvoicePaymentRequestContainer from 'modules/profileLegasy/components/InvoicePaymentRequestContainer/InvoicePaymentRequestContainer';

/**
 * ШАБЛОН: ОПЛАТА СЧЕТА
 */
export const InvoicePaymenTemplate = () => {
  return (
    <Switch>
      <Route path={'/invoice/:checkId'}>
        <InvoicePaymentRequestContainer />
      </Route>

      <Route
        exact
        path={[
          '/pay-it/invoice/:operationId/wait',
          '/pay-it/invoice/deal/:registeredCheckId/:registeredCheckHash',
          '/pay-it/invoice/:operationId/not-register-deal',
          '/pay-it/invoice/:checkId/error', // ЭКРАН ОШИБКИ ПЕРЕВОДА
          '/pay-it/invoice/error',
        ]}
      >
        <InvoicePaymentRequestContainer />
      </Route>
    </Switch>
  );
};
