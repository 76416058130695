import cx from 'classnames';
import { setStatusBg, setStatusTitle } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';

import './StatusBadge.scss';

const StatusBadge = ({ status, className }) => {
  return (
    <div
      className={cx('status-badge', {
        [className]: className,
      })}
      style={{ background: setStatusBg(status) }}
      data-testid="shops-dropdown-item-status"
    >
      {setStatusTitle(status)}
    </div>
  );
};

StatusBadge.propTypes = {
  status: PropTypes.string.isRequired,
  className: PropTypes.string,
};

StatusBadge.defaultProps = {
  className: null,
};

export default StatusBadge;
