import PropTypes from 'prop-types';
import React from 'react';
import AriaModal from 'react-aria-modal';
import styled from 'styled-components';
import { PTChildrenElement, PTClassNames } from 'tools/types/propTypes';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

export const WindowModal = ({ onClose, header, children, footer, className }) => (
  <AriaModal
    onExit={onClose}
    titleText="ModalWindow"
    focusDialog={true}
    underlayStyle={{
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    }}
  >
    <WrapperStyled className={className}>
      <IconClose onClick={onClose} />
      {header && <header>{header}</header>}
      {children && <section>{children}</section>}
      {footer && <footer>{footer}</footer>}
    </WrapperStyled>
  </AriaModal>
);

WindowModal.propTypes = {
  onClose: PropTypes.func.isRequired,
  header: PTChildrenElement,
  children: PTChildrenElement,
  footer: PTChildrenElement,
  className: PTClassNames,
};

WindowModal.defaultProps = {
  header: null,
  children: null,
  footer: null,
  className: '',
};

const WrapperStyled = styled.div`
  padding: 40px 70px;
  margin-left: 20px;
  margin-right: 20px;
  background: #ffffff;
  border-radius: 10px;
  position: relative;
  @media (max-width: 980px) {
    padding: 50px 20px;
  }
  @media (max-width: 400px) {
    padding: 50px 20px;
  }
  & > svg {
    width: 19px;
    height: 19px;
    position: absolute;
    top: 23px;
    right: 23px;
    cursor: pointer;
  }
`;
