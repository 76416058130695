import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import './ProgressLine.scss';

const ProgressLine = ({ currentStep }) => {
  const [value, setValue] = React.useState(0);
  const { paymentMethod } = useParams();

  React.useEffect(() => {
    if (!paymentMethod) {
      setValue(0);
      return;
    }

    if (currentStep < 2) {
      setValue(0);
    }

    if (currentStep === 2) {
      setValue(33);
    }

    if (currentStep > 2 && currentStep < 5) {
      setValue(66);
    }

    if (currentStep === 5) {
      setValue(100);
    }
  }, [currentStep, paymentMethod]);

  return (
    <div className="money-transfer-progress-line">
      <div className="money-transfer-progress-line__wrap">
        <div className="money-transfer-progress-line__value" style={{ width: value + '%' }} />
      </div>
    </div>
  );
};

ProgressLine.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  currentStep: state.moneyTransfer.currentStep,
});

export default connect(mapStateToProps)(ProgressLine);
