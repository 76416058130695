import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from '@rehooks/window-size';

import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';

import { BLUE_BORDER } from 'constants/index';

import nalog1 from 'static/assets/nalog-1.png';
import nalog2 from 'static/assets/nalog-2.png';
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3-small.png';
import shape4 from 'static/assets/shape-4.png';

const ManualPageStepCooperation = ({ returnBack, setStepCooperation2 }) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__content manual-page__col">
          {windowSize.innerWidth > 760 && (
            <>
              <ReturnButton onClick={returnBack} className="manual-page__return-link" />

              <h1 className="manual-page__title">
                Сотрудничество
                <br />с компаниями
              </h1>
            </>
          )}

          <div className="manual-page__text">
            После того, как создан кошелек и подтвержден статус самозанятого в нем, вы можете начать
            сотрудничать не только с физическими лицами, но и с компаниями.
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="Как начать сотрудничество"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepCooperation2}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          {windowSize.innerWidth <= 760 && (
            <>
              <ReturnButton onClick={returnBack} className="manual-page__return-link" />

              <h1 className="manual-page__title">
                Сотрудничество
                <br />с компаниями
              </h1>
            </>
          )}

          <img src={nalog1} className="manual-page__nalog1" alt="" />

          <img src={nalog2} className="manual-page__nalog2" alt="" />

          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_1" />
          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_2" />
          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_3" />
          <img src={shape1} alt="" className="manual-page__shape manual-page__shape_4" />

          <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_1" />
          <img src={shape4} alt="" className="manual-page__ruble manual-page__ruble_2" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepCooperation.propTypes = {
  setStepCooperation2: PropTypes.func.isRequired,
  returnBack: PropTypes.func.isRequired,
};

export default ManualPageStepCooperation;
