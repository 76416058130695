import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { checkINNStatus, setHintOpen, setInnWasSentOnCheck } from 'modules/authLegasy/actions';
import HintHowToGiveRights from 'modules/authLegasy/components/Hint/HintHowToGiveRights';
import HintWhyCheckIsSoLong from 'modules/authLegasy/components/Hint/HintWhyCheckIsSoLong';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import HintsList from 'modules/shared/components/HintsList/HintsList';

import {
  ALIEN_INN,
  CONSISTENT_ANIMATIONS_DELAY,
  ERROR,
  NO,
  NO_PERMISSION,
  NOT_FOUND,
  NOT_MATCH,
  NOT_READY,
  NOT_READY_PERMISSION_REQUESTED,
  YES,
} from 'constants/index.js';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';
import iconWink from 'static/assets/icon-emoji-wink.png';
import { ReactComponent as IconInformation } from 'static/assets/icon-information.svg';
import { ReactComponent as IconLockClosed } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

export const SelfEmployedWaiting = ({
  className,
  INN,
  dispatch,
  onSuccessSERegistration,
  onFailedSERegistration,
  onINNNumberChange,
  isMenuOpen,
  onAlreadyRegistered,
  onNoSz,
  onNoRights,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentHintId, setCurrentHintId] = useState(null);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  /** Отправляем запрос на проверку ИНН при маунте компонента */
  useEffect(() => {
    setIsLoading(true);

    requestInnCheck();

    /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */

  const requestInnCheck = () => {
    setIsLoading(true);

    dispatch(checkINNStatus())
      .then((statusData) => {
        setIsLoading(false);
        if (statusData && statusData.data.status === YES) {
          dispatch(setInnWasSentOnCheck(false));
          onSuccessSERegistration();
        }

        if (
          statusData.data.status === NOT_READY ||
          statusData.data.status === NOT_READY_PERMISSION_REQUESTED
        ) {
          requestInnCheck();
        }

        if (
          statusData &&
          statusData.data.status &&
          (statusData.data.status === NOT_FOUND ||
            statusData.data.status === ERROR ||
            statusData.data.status === NOT_MATCH)
        ) {
          dispatch(setInnWasSentOnCheck(false));
          onFailedSERegistration();
        }

        if (statusData && statusData.data.status === NO) {
          dispatch(setInnWasSentOnCheck(false));
          onNoSz();
        }

        if (statusData && statusData.data.status === NO_PERMISSION) {
          dispatch(setInnWasSentOnCheck(false));
          onNoRights();
        }

        if (statusData && statusData.data.status === ALIEN_INN) {
          dispatch(setInnWasSentOnCheck(false));
          onAlreadyRegistered();
        }
      })
      .catch((result) => {
        setErrorMessage((result && result.result && result.result.message) || 'Ошибка');
        setIsLoading(false);
        onFailedSERegistration();
      });
  };

  const onCloseBtnClick = () => {
    closeExplicitly();
    setCurrentHintId(null);
  };

  const toggleHint1 = () => {
    toggleDrop();
    setCurrentHintId(1);
  };

  const toggleHint2 = () => {
    toggleDrop();
    setCurrentHintId(2);
  };

  const hints = [
    {
      id: 1,
      title: 'Как предоставить права?',
      titleForPopup: 'Как предоставить права?',
      action: toggleHint1,
      openCondition: isDropOpen && currentHintId === 1,
      popup: <HintHowToGiveRights />,
      dropEl,
    },
    {
      id: 2,
      title: 'Почему проверка длится долго?',
      titleForPopup: 'Почему проверка длится долго?',
      action: toggleHint2,
      openCondition: isDropOpen && currentHintId === 2,
      popup: <HintWhyCheckIsSoLong />,
      dropEl,
    },
  ];

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  return (
    <div
      className={cx('se-register', {
        [className]: className,
      })}
      data-testid="se-register"
      data-cy="se-register-waiting"
    >
      <div className="se-register__container container">
        <div className="se-register__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="se-register__title" data-cy="se-register-waiting-title">
              Ваш запрос отправлен на проверку в ФНС
            </h1>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="se-register__warning-wrap">
              <div className="se-register__warning">
                <IconInformation className="se-register__warning-icon" />
                Вам необходимо предоставить для нас права в приложении
                <a
                  href="https://npd.nalog.ru/app/"
                  className="se-register__warning-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  &quot;Мой Налог&quot;
                </a>
                или на
                <a
                  href="https://lknpd.nalog.ru/auth/login"
                  className="se-register__warning-link"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  сайте ФНС
                </a>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="se-register__form">
              <form className="form form_register">
                <div className="form__block">
                  <div className="form__input-holder">
                    <InputMask
                      type="text"
                      mask="9999-9999-9999"
                      className="form__input form__input_disabled"
                      defaultValue={INN}
                      disabled
                      readOnly
                    />

                    <button className="form__input-edit" type="button" onClick={onINNNumberChange}>
                      <IconEdit />
                    </button>

                    {isLoading && windowSize.innerWidth < 700 && (
                      <div className="form__small-loader">
                        <CircleLoader
                          className="se-register__loader"
                          width={15}
                          height={15}
                          borderWidth={1}
                        />
                      </div>
                    )}
                  </div>

                  {isLoading && windowSize.innerWidth > 700 && (
                    <div className="form__loader-wrap">
                      <CircleLoader className="se-register__loader" width={60} height={60} />
                      <IconLockClosed className="form__password-secure-lock form__password-secure-lock_grey" />
                    </div>
                  )}
                </div>

                {errorMessage && <div className="form__error-message">{errorMessage}</div>}
              </form>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered && windowSize.innerWidth < 1100}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <div className="se-register__hint-block">
              <div className="se-register__hint">
                <p className="se-register__hint-text">
                  Проверка статуса может занять некоторое время - это нормально
                  <img
                    src={iconWink}
                    alt=""
                    className="se-register__hint-icon se-register__hint-icon_wink"
                  />
                </p>

                <IconTriangle className="se-register__hint-triangle" />
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <div className="se-register__error-list-wrap">
              <HintsList
                hints={hints}
                closeHintHandler={onCloseBtnClick}
                title="Вам будет полезно"
              />
            </div>
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && isRendered && windowSize.innerWidth > 1100}
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <div className="se-register__hint-block">
            <div className="se-register__hint">
              <p className="se-register__hint-text">
                Проверка статуса может занять некоторое время - это нормально
                <img
                  src={iconWink}
                  alt=""
                  className="se-register__hint-icon se-register__hint-icon_wink"
                />
              </p>

              <IconTriangle className="se-register__hint-triangle" />
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

SelfEmployedWaiting.propTypes = {
  className: PropTypes.string.isRequired,
  INN: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSuccessSERegistration: PropTypes.func.isRequired,
  onFailedSERegistration: PropTypes.func.isRequired,
  onAlreadyRegistered: PropTypes.func.isRequired,
  onNoSz: PropTypes.func.isRequired,
  onNoRights: PropTypes.func.isRequired,
  onINNNumberChange: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  INN: state.auth.INN,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(SelfEmployedWaiting);
