import { createAsyncThunk } from '@reduxjs/toolkit';
import { loginAPI, loginSmsConfirmAPI } from './api';
import { setUserPhone, getLimits, getUser } from 'modules/authLegasy/actions';

/**
 * Запрос авторизации клиента
 * @param {number} props - props api
 */
export const loginApiThunk = createAsyncThunk(
  'login/loginAPI',
  async ({ login, password }, { rejectWithValue, dispatch }) => {
    try {
      const rs = await loginAPI({ login, password });
      if (rs && rs.login && rs.group) {
        await dispatch(setUserPhone(login));
        await dispatch(getUser());
        await dispatch(getLimits());
      } else {
        await dispatch(setUserPhone(login));
      }
      return rs;
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);

/**
 * Запрос подтверждение авторизации с передачей кода полученного в смс
 * @param {number} props - props api
 */
export const loginSmsConfirmAPIThunk = createAsyncThunk(
  'login/loginSmsConfirmAPI',
  async ({ id, pin }, { dispatch, rejectWithValue }) => {
    try {
      const rs = await loginSmsConfirmAPI({ id, pin });
      await dispatch(getUser());
      await dispatch(getLimits());
      return rs;
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);
