import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

// Icons
import { ReactComponent as IconLoader } from 'static/assets/icon-loader.svg';

import './CircleLoader.scss';

const CircleLoader = ({ className }) => {
  return (
    <IconLoader
      className={cx('spinner', {
        [className]: className,
      })}
    />
  );
};

CircleLoader.propTypes = {
  className: PropTypes.string,
};

CircleLoader.defaultProps = {
  className: null,
};

export default CircleLoader;
