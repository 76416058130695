import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './BlockedUserText.scss';

const BlockedUserText = ({ className, title, desc }) => {
  return (
    <div
      className={cx('blocked-user-text', {
        [className]: className,
      })}
      data-testid="blocked-user-text"
    >
      <h2 className="blocked-user-text__title">{title || 'Кошелек удален или заблокирован'}</h2>

      <div className="blocked-user-text__desc">
        {desc || 'Возможно ситуация носит временный характер.'} {'  '}
        Попробуйте связаться с владельцем кошелька.
        {/* Попробуйте связаться с пользователем кошелька или{' '} */}
        {/* <span className="js-help blocked-user-text__link" data-testid="help-link"> */}
        {/*   службой поддержки */}
        {/* </span> */}
      </div>
    </div>
  );
};

BlockedUserText.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string,
  desc: PropTypes.string,
};

BlockedUserText.defaultProps = {
  className: null,
  title: null,
  desc: null,
};

export default BlockedUserText;
