import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import PaymentMessage from 'modules/profileLegasy/components/PaymentMessage/PaymentMessage';
import PaymentSummary from 'modules/profileLegasy/components/PaymentSummary/PaymentSummary';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import ResponsiveHeader from 'modules/shared/components/ResponsiveHeader/ResponsiveHeader';

import {
  CONSISTENT_ANIMATIONS_DELAY,
  PAYMENT_TRANSFER_FAIL,
  PAYMENT_TRANSFER_LOADING,
  PAYMENT_TRANSFER_OK,
} from 'constants/index';

import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as Logo } from 'static/assets/logo-white.svg';
import profileBg from 'static/assets/profile-bg.png';

// Styles
import './TaxPaymentCard.scss';

const TaxPaymentCard = ({
  taxPayingProgress,
  paymentStatus,
  sumToPay,
  userPhone,
  oneTaxChangedSum,
  taxesToPay,
}) => {
  const [isIframeLoading, setIsIframeLoading] = useState(true);

  const summaryFieldsSucceed = [
    {
      source: 'Налоговый период',
      title: 'Налоговый период',
      value: 'Ноябрь 2019',
    },
    {
      source: 'Сумма',
      title: 'Сумма',
      value: '2010 ₽',
    },
  ];

  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="tax-payment-card" data-testid="tax-payment-card">
      {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <ResponsiveHeader
          className="tax-payment-card__responsive-header"
          isCloseButtonLink
          href="/lk/tax-payment"
        />
      </CSSTransition>
      {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА  < 980 */}

      {/* MOBILE HEADER */}
      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
        unmountOnExit
      >
        <div className="tax-payment-card__top">
          <h1 className="tax-payment-card__title">По номеру карты</h1>

          {!paymentStatus && <div className="tax-payment-card__subtitle">Введите данные карты</div>}

          {paymentStatus === PAYMENT_TRANSFER_LOADING && (
            <div className="tax-payment-card__subtitle">Идет процесс оплаты</div>
          )}

          {paymentStatus === PAYMENT_TRANSFER_OK && (
            <div className="tax-payment-card__subtitle">Платеж успешно отправлен</div>
          )}

          {paymentStatus === PAYMENT_TRANSFER_FAIL && (
            <div className="tax-payment-card__subtitle">Ошибка отправки</div>
          )}

          <div className="bank-card-payment__top-progress">
            <div
              className="bank-card-payment__top-progress-bar"
              style={{ width: `${taxPayingProgress}%` }}
            />
          </div>
        </div>
      </CSSTransition>
      {/* /MOBILE HEADER */}

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
        unmountOnExit
      >
        <div
          className={cx('tax-payment-card__card', {
            'tax-payment-card__card_with-shadow': paymentStatus,
          })}
        >
          {/* ФОРМА ВВОДА РЕКВИЗИТОВ БАНКОВСКОЙ КАРТЫ */}
          {!paymentStatus && (
            <div className="tax-payment-card__iframe-wrap">
              {isIframeLoading && (
                <div className="tax-payment-card__iframe-loader">
                  <CircleLoader />
                </div>
              )}

              <iframe
                title="TaxPayment"
                className="tax-payment-card__iframe"
                src={`${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/tax?tax-uin=${
                  taxesToPay && taxesToPay.length >= 1 && taxesToPay[0].documentIndex
                }&account=${userPhone || 'anonymous'}&amount${
                  sumToPay === Number(oneTaxChangedSum) * 100
                    ? sumToPay
                    : Number(oneTaxChangedSum) * 100
                }`}
                frameBorder="0"
                onLoad={() => {
                  setIsIframeLoading(false);
                }}
              />
            </div>
          )}
          {/* /ФОРМА ВВОДА РЕКВИЗИТОВ БАНКОВСКОЙ КАРТЫ */}

          {/* ПЛАТЕЖ ОБРАБАТЫВАЕТСЯ (КРУТИЛКА) */}
          {paymentStatus === PAYMENT_TRANSFER_LOADING && (
            <>
              <div className="tax-payment-card__header">
                <Logo className="tax-payment-card__header-logo" />

                <img
                  src={profileBg}
                  alt="Фирменный бэкгранунд Самозаняты.рф"
                  className="tax-payment-card__header-bg"
                />
              </div>

              <PaymentMessage
                className="tax-payment-card__payment-message"
                status={paymentStatus}
                text="Платеж обрабатывается. Это может занять некоторое время. Пожалуйста, подождите."
              />
            </>
          )}
          {/* /ПЛАТЕЖ ОБРАБАТЫВАЕТСЯ (КРУТИЛКА) */}

          {/* ПЛАТЕЖ УДАЧА */}
          {paymentStatus === PAYMENT_TRANSFER_OK && (
            <>
              <div className="tax-payment-card__success">
                <div className="tax-payment-card__success-icon-wrap">
                  <CircleProgress
                    className="tax-payment-card__success-progress"
                    color="var(--turquoise-blue)"
                    value={66}
                  />
                  <IconCheck className="tax-payment-card__success-icon" />
                </div>

                <div className="tax-payment-card__success-text">
                  <p className="tax-payment-card__success-text-title">Ваш платеж</p>
                  <p className="tax-payment-card__success-text-result">Успешно отправлен</p>
                </div>
              </div>
              <PaymentSummary summaryFields={summaryFieldsSucceed} />
            </>
          )}
          {/* /ПЛАТЕЖ УДАЧА */}

          {/* ПЛАТЕЖ НЕУДАЧА */}
          {paymentStatus === PAYMENT_TRANSFER_FAIL && (
            <>
              <div className="tax-payment-card__header">
                <Logo className="tax-payment-card__header-logo" />

                <img
                  src={profileBg}
                  alt="Фирменный бэкгранунд Самозаняты.рф"
                  className="tax-payment-card__header-bg"
                />
              </div>

              <PaymentMessage
                className="tax-payment-card__payment-message"
                status={paymentStatus}
                text="Упс. Что-то пошло не так и мы не смогли совершить платеж. Если проблема не будет решена, напишите нам"
              />
            </>
          )}
          {/* /ПЛАТЕЖ НЕУДАЧА */}
        </div>
      </CSSTransition>
    </div>
  );
};

TaxPaymentCard.propTypes = {
  paymentStatus: PropTypes.string,
  taxPayingProgress: PropTypes.number,
  sumToPay: PropTypes.number,
  userPhone: PropTypes.string,
  oneTaxChangedSum: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  taxesToPay: PropTypes.arrayOf(
    PropTypes.shape({
      documentIndex: PropTypes.string,
      amount: PropTypes.number,
    }),
  ),
};

TaxPaymentCard.defaultProps = {
  paymentStatus: null,
  taxPayingProgress: 0,
  sumToPay: 0,
  userPhone: null,
  taxesToPay: null,
};

const mapStateToProps = (state) => ({
  taxPayingProgress: state.profile.taxPayingProgress,
  userPhone: state.auth.user && state.auth.user.login ? state.auth.user.login : null,
});

export default connect(mapStateToProps)(TaxPaymentCard);
