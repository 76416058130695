import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import SzStatusCard from 'modules/profileLegasy/components/SzStatusCard/SzStatusCard';
import Service from 'modules/profileLegasy/components/Service/Service';

import { ReactComponent as IconService4 } from 'static/assets/icon-service-4.svg';

import { PARTLY_YES, YES } from 'constants/index';
import { ReactComponent as IconService3 } from 'static/assets/icon-service-3.svg';

import './ProfileSettingsSzTab.scss';

export const ProfileSettingsSzTab = ({ userTaxStatus, szStatusFromDb }) => {
  const windowSize = useWindowSize();
  const isCardDisabled =
    userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES);

  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <>
      <animated.div
        className="profile-settings-sz-tab"
        style={{
          opacity: spring.opacity,
          transform: spring.y.interpolate(transY),
        }}
        data-testid="profile-settings-sz-tab"
      >
        {windowSize.innerWidth > 980 && (
          <h2 className="profile-settings-sz-tab__title">Статус самозанятого</h2>
        )}

        <div className="profile-settings-sz-tab__main">
          {/* ИНФОРМАЦИЯ О СТАТУСЕ САМОЗАНЯТОГО */}
          <div className="profile-settings-sz-tab__item">
            <SzStatusCard className="profile-settings-sz-tab__card" />
          </div>
          {/* /ИНФОРМАЦИЯ О СТАТУСЕ САМОЗАНЯТОГО */}

          {windowSize.innerWidth <= 780 &&
            userTaxStatus !== YES &&
            (!szStatusFromDb || szStatusFromDb !== PARTLY_YES) && (
              <p className="profile-settings-sz-tab__services-title">Вам станут доступны:</p>
            )}

          {/* СЕРВИСЫ */}
          <div className="profile-settings-sz-tab__item">
            {/* РЕГИСТРАЦИЯ ДОХОДА */}
            <div className="profile-settings-sz-tab__service">
              <Service
                className="service_small"
                title="Регистрация дохода"
                desc="Сформировать чек и отправить его покупателю на почту или ссылкой"
                isWhite
                isLink
                href="/lk/income-registration"
                icon={<IconService3 className="service__icon" />}
                disabled={isCardDisabled}
              />

              {isCardDisabled && (
                <div className="profile-settings-sz-tab__service-notice">
                  Необходим статус самозанятого
                </div>
              )}
            </div>
            {/* /РЕГИСТРАЦИЯ ДОХОДА */}

            {/* ВЫСТАВИТЬ СЧЕТ */}
            <div className="profile-settings-sz-tab__service">
              <Service
                className="service_small"
                title="Выставить счет"
                desc="Отправьте покупателю платежную форму и после оплаты он получит чек, а вы - деньги"
                isWhite
                isLink
                href="/lk/bill-creation"
                icon={<IconService4 className="service__icon" />}
                disabled={isCardDisabled}
              />

              {isCardDisabled && (
                <div className="profile-settings-sz-tab__service-notice">
                  Необходим статус самозанятого
                </div>
              )}
            </div>
            {/* /ВЫСТАВИТЬ СЧЕТ */}
          </div>
          {/* /СЕРВИСЫ */}
        </div>
      </animated.div>
    </>
  );
};

ProfileSettingsSzTab.propTypes = {
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
};

ProfileSettingsSzTab.defaultProps = {
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  szStatusFromDb: state.auth.szStatusFromDb,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
});

export default connect(mapStateToProps)(ProfileSettingsSzTab);
