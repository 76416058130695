import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './TitleWithProgressBar.scss';

const TitleWithProgressBar = ({ className, title, progress }) => {
  return (
    <div
      className={cx('title-with-progress-bar', {
        [className]: className,
      })}
    >
      <h1 className="title-with-progress-bar__title" data-testid="progress-bar-title">
        {title}
      </h1>

      <div className="title-with-progress-bar__progress">
        <div
          className="title-with-progress-bar__progress-bar"
          style={{ width: `${progress}%` }}
          data-testid="progress-bar"
        />
      </div>
    </div>
  );
};

TitleWithProgressBar.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  progress: PropTypes.number.isRequired,
};

TitleWithProgressBar.defaultProps = {
  className: null,
};

export default TitleWithProgressBar;
