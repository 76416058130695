import acquiring from 'modules/acquiring/reducer';
import { loginReducer } from 'modules/auth/models/login';
import { reSendSmsReducer } from 'modules/auth/models/reSendSms/slice';
import auth from 'modules/authLegasy/reducer';
import cashBox from 'modules/cashbox/reducer';
import { documentsCertificateReducer } from 'modules/documents';
import identificationRequest from 'modules/identification-request/reducer';
import moneyTransfer from 'modules/money-transfer/reducer';
import partners from 'modules/partners/reducer';
import { confirmDocumentReducer } from 'modules/profile/models/document';
import { documentListReducer } from 'modules/profile/models/documentList';
import profile from 'modules/profileLegasy/reducer';
import tax from 'modules/tax/reducer';
import walletClosing from 'modules/wallet-closing/reducer';

const rootReducer = {
  auth,
  profile,
  cashBox,
  acquiring,
  tax,
  moneyTransfer,
  login: loginReducer,
  reSendSms: reSendSmsReducer,
  partners,
  identificationRequest,
  walletClosing,
  documentList: documentListReducer,
  confirmDocument: confirmDocumentReducer,
  documentsCertificate: documentsCertificateReducer,
};

export default rootReducer;
