import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { NavLink } from 'react-router-dom';

import { animated, useSpring } from 'react-spring';

import styles from './style.module.scss';

export const ProfileNavigationWidget = ({ className, navList, closeHandler }) => {
  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: -50 },
    config: { mass: 1, tension: 280, friction: 40 },
  });

  return (
    <animated.section
      className={cx(styles.profileNavigationWidget, {
        [className]: className,
      })}
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate(transY),
      }}
    >
      <nav>
        <ul>
          {navList.map((item) => (
            <li key={item.text}>
              <NavLink
                to={item.link}
                exact
                className={styles.link}
                activeClassName={styles.activeLink}
                onClick={() => closeHandler()}
              >
                <span className={cx(styles.icon, styles.defaultIcon)}>{item.icon}</span>
                <span className={cx(styles.icon, styles.activeIcon)}>{item.activeIcon}</span>
                {item.text}
              </NavLink>
            </li>
          ))}
        </ul>
      </nav>
    </animated.section>
  );
};

ProfileNavigationWidget.propTypes = {
  className: PropTypes.string,
  navList: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string,
      icon: PropTypes.shape({}),
      activeIcon: PropTypes.shape({}),
      link: PropTypes.string,
    }),
  ).isRequired,
  closeHandler: PropTypes.func,
};

ProfileNavigationWidget.defaultProps = {
  closeHandler: (f) => f,
};
