import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import './Title.scss';

const Title: React.FC = ({
  currentStep,
  requestMoneyTransferFail,
  checkMoneyTransferConfirmationViaPushFail,
  checkMoneyTransferStatusFail,
}) => {
  const [title, setTitle] = React.useState('');
  const [subTitle, setSubTitle] = React.useState('');
  const { paymentMethod } = useParams();

  React.useEffect(() => {
    if (!paymentMethod) {
      setTitle('Перевод денег');
      setSubTitle('Выберите способ');
      return;
    }

    switch (paymentMethod) {
      case 'bankcard':
        setTitle('По номеру карты');
        break;
      case 'sz':
        setTitle('Кошелек Самозанятых');
        break;
      case 'sbp':
        setTitle('По СБП');
        break;
      case 'umoney':
        setTitle('На ЮMoney');
        break;
      case 'webmoney':
        setTitle('Webmoney');
        break;
      case 'qiwi':
        setTitle('QIWI кошелек');
        break;
      default:
        setTitle('Перевод денег');
    }

    switch (currentStep) {
      case 1:
        setSubTitle(
          `Введите номер ${
            paymentMethod === 'bankcard'
              ? 'карты'
              : paymentMethod === 'sbp'
              ? 'телефона'
              : 'кошелька'
          } получателя`,
        );
        break;
      case 2:
        setSubTitle('Проверьте данные перед отправкой');
        break;
      case 3:
        setSubTitle('Подтвердите перевод');
        break;
      case 4:
        setSubTitle('Идет процесс оплаты');
        break;
      case 5:
        setSubTitle('Платеж успешно отправлен');
        break;
      default:
        setSubTitle('Выберите способ');
    }

    if (
      requestMoneyTransferFail ||
      checkMoneyTransferConfirmationViaPushFail ||
      checkMoneyTransferStatusFail
    ) {
      setSubTitle('Ошибка отправки');
    }
  }, [
    paymentMethod,
    currentStep,
    requestMoneyTransferFail,
    checkMoneyTransferConfirmationViaPushFail,
    checkMoneyTransferStatusFail,
  ]);

  return (
    <div className="money-transfer-title">
      <div className="money-transfer-title__desktop">
        <h1>Перевод денег</h1>
      </div>
      <div className="money-transfer-title__mobile">
        <h1>{title}</h1>
        <h2>{subTitle}</h2>
      </div>
    </div>
  );
};

Title.propTypes = {
  dispatch: PropTypes.func.isRequired,
  currentStep: PropTypes.number.isRequired,
  requestMoneyTransferFail: PropTypes.bool.isRequired,
  checkMoneyTransferConfirmationViaPushFail: PropTypes.bool.isRequired,
  checkMoneyTransferStatusFail: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  currentStep: state.moneyTransfer.currentStep,
  requestMoneyTransferFail: state.moneyTransfer.requestMoneyTransfer.fail,
  checkMoneyTransferConfirmationViaPushFail:
    state.moneyTransfer.checkMoneyTransferConfirmationViaPush.fail,
  checkMoneyTransferStatusFail: state.moneyTransfer.checkMoneyTransferStatus.fail,
});

export default connect(mapStateToProps)(Title);
