import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AriaModal from 'react-aria-modal';
import { connect } from 'react-redux';

import {
  getMerchantsList,
  setAcquiringActiveTab,
  setChosenShop,
  setChosenShopStatus,
} from 'modules/acquiring/actions';
import AcquiringSettingsStep3 from 'modules/acquiring/components/AcquiringSettingsStep3/AcquiringSettingsStep3';
// import AnalyticsTab from 'modules/acquiring/components/AnalyticsTab/AnalyticsTab';
import OperationsTab from 'modules/acquiring/components/OperationsTab/OperationsTab';
import PaymentPagesTab from 'modules/acquiring/components/PaymentPagesTab/PaymentPagesTab';
import PaymentWaysTab from 'modules/acquiring/components/PaymentWaysTab/PaymentWaysTab';
import ShopsDropdown from 'modules/acquiring/components/ShopsDropdown/ShopsDropdown';
import ShopStatusTab from 'modules/acquiring/components/ShopStatusTab/ShopStatusTab';
import ProfileSettingsDropdown from 'modules/profileLegasy/components/ProfileSettingsDropdown/ProfileSettingsDropdown';
import ProfileSettingsNav from 'modules/profileLegasy/components/ProfileSettingsNav/ProfileSettingsNav';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';

import {
  ANALYTICS_ACQUIRING_TAB,
  OPERATIONS_ACQUIRING_TAB,
  PARTLY_YES,
  PAYMENT_PAGES_ACQUIRING_TAB,
  PAYMENT_WAYS_ACQUIRING_TAB,
  SHOP_PARAMETERS_ACQUIRING_TAB,
  SHOP_STATUS_ACQUIRING_TAB,
  YES,
} from 'constants/index';

import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';
import { ReactComponent as IconDetail } from 'static/assets/icon-profile-settings-detail.svg';
import { ReactComponent as IconShield } from 'static/assets/icon-shield.svg';
import { ReactComponent as IconPayment } from 'static/assets/icon-payment-process.svg';

import './Acquiring.scss';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';

const Acquiring = ({
  dispatch,
  isMenuOpen,
  acquiringActiveTab,
  chosenShop,
  statusOfChoseShop,
  userTaxStatus,
  szStatusFromDb,
  user,
}) => {
  const windowSize = useWindowSize();
  const [currentTabId, setCurrentTabId] = useState(0);
  const [isRendered, setIsRendered] = useState(false);
  const [isProfileSettingsDropdownOpened, setIsProfileSettingsDropdownOpened] = useState(false);
  const isUerSz = userTaxStatus === YES || (szStatusFromDb && szStatusFromDb === PARTLY_YES);
  const [shopsDropdownList, setShopsDropdownList] = useState([]);
  const [isShopSelfEmployed, setIsShopSelfEmployed] = useState(false);

  const navList = [
    {
      id: OPERATIONS_ACQUIRING_TAB,
      title: 'Операции',
      icon: <IconBag className="acquiring__tabs__nav-icon" />,
      activeIcon: (
        <IconBag className="acquiring__tabs__nav-icon acquiring__tabs__nav-icon__active" />
      ),
      onClick: () => {
        dispatch(setAcquiringActiveTab(OPERATIONS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
      click: () => {
        dispatch(setAcquiringActiveTab(OPERATIONS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
    },
    {
      id: ANALYTICS_ACQUIRING_TAB,
      title: 'Аналитика',
      icon: <IconShield className="acquiring__tabs__nav-icon" />,
      activeIcon: (
        <IconShield className="acquiring__tabs__nav-icon acquiring__tabs__nav-icon__active" />
      ),
      onClick: () => {
        dispatch(setAcquiringActiveTab(ANALYTICS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
      click: () => {
        dispatch(setAcquiringActiveTab(ANALYTICS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
    },
    {
      id: SHOP_STATUS_ACQUIRING_TAB,
      title: 'Статус магазина',
      icon: <IconShield className="acquiring__tabs__nav-icon" />,
      activeIcon: (
        <IconShield className="acquiring__tabs__nav-icon acquiring__tabs__nav-icon__active" />
      ),
      onClick: () => {
        dispatch(setAcquiringActiveTab(SHOP_STATUS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
      click: () => {
        dispatch(setAcquiringActiveTab(SHOP_STATUS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
    },
    // {
    //   id: PAYMENT_WAYS_ACQUIRING_TAB,
    //   title: 'Способы платежей',
    //   icon: <IconWallet className="acquiring__tabs__nav-icon" />,
    //   activeIcon: (
    //     <IconWallet className="acquiring__tabs__nav-icon acquiring__tabs__nav-icon__active" />
    //   ),
    //   onClick: () => {
    //     dispatch(setAcquiringActiveTab(PAYMENT_WAYS_ACQUIRING_TAB));
    //     setIsProfileSettingsDropdownOpened(false);
    //   },
    //   click: () => {
    //     dispatch(setAcquiringActiveTab(PAYMENT_WAYS_ACQUIRING_TAB));
    //     setIsProfileSettingsDropdownOpened(false);
    //   },
    // },
    {
      id: PAYMENT_PAGES_ACQUIRING_TAB,
      title: 'Страница оплаты',
      icon: <IconPayment className="acquiring__tabs__nav-icon" />,
      activeIcon: (
        <IconPayment className="acquiring__tabs__nav-icon acquiring__tabs__nav-icon__active" />
      ),
      onClick: () => {
        dispatch(setAcquiringActiveTab(PAYMENT_PAGES_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
      click: () => {
        dispatch(setAcquiringActiveTab(PAYMENT_PAGES_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
    },
    {
      id: SHOP_PARAMETERS_ACQUIRING_TAB,
      title: 'Параметры магазина',
      icon: <IconDetail className="acquiring__tabs__nav-icon" />,
      activeIcon: (
        <IconDetail className="acquiring__tabs__nav-icon acquiring__tabs__nav-icon__active" />
      ),
      onClick: () => {
        dispatch(setAcquiringActiveTab(SHOP_PARAMETERS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
      click: () => {
        dispatch(setAcquiringActiveTab(SHOP_PARAMETERS_ACQUIRING_TAB));
        setIsProfileSettingsDropdownOpened(false);
      },
    },
  ];

  useEffect(() => {
    if (acquiringActiveTab === OPERATIONS_ACQUIRING_TAB) {
      setCurrentTabId(0);
    }

    if (acquiringActiveTab === ANALYTICS_ACQUIRING_TAB) {
      setCurrentTabId(1);
    }

    if (acquiringActiveTab === SHOP_STATUS_ACQUIRING_TAB) {
      setCurrentTabId(2);
    }

    if (acquiringActiveTab === PAYMENT_WAYS_ACQUIRING_TAB) {
      setCurrentTabId(3);
    }

    if (acquiringActiveTab === PAYMENT_PAGES_ACQUIRING_TAB) {
      setCurrentTabId(4);
    }

    if (acquiringActiveTab === SHOP_PARAMETERS_ACQUIRING_TAB) {
      setCurrentTabId(5);
    }
  }, [acquiringActiveTab]);

  useEffect(() => {
    if (windowSize.innerWidth <= 800 && currentTabId !== 0 && currentTabId !== 1) {
      dispatch(setAcquiringActiveTab(OPERATIONS_ACQUIRING_TAB));
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [windowSize, currentTabId]);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    dispatch(getMerchantsList())
      .then((res) => {
        if (res && res.data && Array.isArray(res.data)) {
          if (res.data.length) {
            setShopsDropdownList(
              res.data.map((item) => {
                return {
                  ...item,
                  status:
                    item.selfEmployed && !isUerSz && item.status === 'SUCCESS'
                      ? 'NEED_TO_SET_UP'
                      : item.status,
                  onClick: () => {
                    dispatch(setChosenShop(item.uuid));
                    dispatch(setChosenShopStatus(item.status));
                    setIsShopSelfEmployed(item.selfEmployed);
                  },
                };
              }),
            );
            dispatch(setChosenShop(res.data[0].uuid));
            dispatch(setChosenShopStatus(res.data[0].status));
          }
        }
      })
      .catch(() => {});
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 1000);
  }, []);

  return (
    <>
      <PaymentDisabledPopup redirect={'/lk'} />
      {checkPaymentEnabled() && <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />}
      <div className="acquiring">
        {/* КНОПКА ДЛЯ ОТКРЫТИЯ ВЫПАДАШКИ С НАВИГАЦИЕЙ */}
        {windowSize.innerWidth <= 1100 && (
          <ShopsDropdown
            className="acquiring__shops-dropdown"
            shopsDropdownList={shopsDropdownList}
            chosenShop={chosenShop}
          />
        )}
        {/* /КНОПКА ДЛЯ ОТКРЫТИЯ ВЫПАДАШКИ С НАВИГАЦИЕЙ */}

        {windowSize.innerWidth <= 800 && (currentTabId === 0 || currentTabId === 1) && (
          <div className="acquiring__tabs">
            <TabsWithScrollableActiveState
              currentTabId={currentTabId}
              // Temporarily hide analytics from users if not a test domain
              tabArray={navList.slice(0, 1)}
              isRendered={isRendered}
            />
          </div>
        )}

        {/* ВЫПАДАШКА С ПУНКТАМИ НАВИГАЦИИ ПО ПРОФИЛЮ */}
        <AriaModal
          underlayClass="acquiring__dropdown"
          onExit={() => setIsProfileSettingsDropdownOpened(false)}
          titleText=" "
          underlayColor="rgba(10, 17, 37, 0.5)"
          underlayStyle={{ top: windowSize.innerWidth > 800 ? '140px' : '120px' }}
          mounted={windowSize.innerWidth <= 1194 && isProfileSettingsDropdownOpened && !isMenuOpen}
          focusTrapPaused
        >
          <ProfileSettingsDropdown activeTab={acquiringActiveTab} buttonsList={navList} />
        </AriaModal>
        {/* /ВЫПАДАШКА С ПУНКТАМИ НАВИГАЦИИ ПО ПРОФИЛЮ */}

        <div className="acquiring__container">
          {/* НАВИГАЦИЯ */}
          {windowSize.innerWidth > 800 && (
            <div className="acquiring__sidebar">
              {windowSize.innerWidth > 1100 && (
                <ShopsDropdown
                  className="acquiring__shops-dropdown"
                  shopsDropdownList={shopsDropdownList}
                  chosenShop={chosenShop}
                />
              )}

              <ProfileSettingsNav
                className="acquiring__nav"
                topList={navList.slice(0, 1)}
                settingsList={navList.slice(2, navList.length)}
                activeTab={acquiringActiveTab}
                shopStatus={
                  shopsDropdownList.filter((item) => item.uuid === chosenShop) &&
                  shopsDropdownList.filter((item) => item.uuid === chosenShop)[0] &&
                  shopsDropdownList.filter((item) => item.uuid === chosenShop)[0].status
                }
              />
            </div>
          )}
          {/* /НАВИГАЦИЯ */}

          {/* ОСНОВНОЙ КОНТЕНТ */}
          {chosenShop && (
            <div
              className={cx('acquiring__main', {
                acquiring__main_analytics: acquiringActiveTab === ANALYTICS_ACQUIRING_TAB,
              })}
            >
              {acquiringActiveTab === OPERATIONS_ACQUIRING_TAB && (
                <OperationsTab chosenShop={chosenShop} />
              )}
              {/* Temporarily hide analytics from users if not a test domain */}
              {/*{acquiringActiveTab === ANALYTICS_ACQUIRING_TAB && (*/}
              {/*  <AnalyticsTab id={chosenShop} />*/}
              {/*)}*/}

              {windowSize.innerWidth > 800 && (
                <>
                  {acquiringActiveTab === SHOP_STATUS_ACQUIRING_TAB && (
                    <ShopStatusTab
                      status={
                        isShopSelfEmployed && !isUerSz && statusOfChoseShop === 'SUCCESS'
                          ? 'NEED_TO_SET_UP'
                          : statusOfChoseShop
                      }
                    />
                  )}
                  {acquiringActiveTab === PAYMENT_WAYS_ACQUIRING_TAB && (
                    <PaymentWaysTab chosenShop={chosenShop} />
                  )}
                  {acquiringActiveTab === PAYMENT_PAGES_ACQUIRING_TAB && (
                    <PaymentPagesTab chosenShop={chosenShop} />
                  )}

                  {acquiringActiveTab === SHOP_PARAMETERS_ACQUIRING_TAB && (
                    <AcquiringSettingsStep3 isSettingsForExistingMerchant id={chosenShop} />
                  )}
                </>
              )}
            </div>
          )}
          {/* /ОСНОВНОЙ КОНТЕНТ */}
        </div>
      </div>
    </>
  );
};

Acquiring.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  acquiringActiveTab: PropTypes.string.isRequired,
  chosenShop: PropTypes.string,
  statusOfChoseShop: PropTypes.string,
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  user: PropTypes.object.isRequired,
};

Acquiring.defaultProps = {
  chosenShop: null,
  statusOfChoseShop: null,
  userTaxStatus: null,
  szStatusFromDb: '',
};

const mapStateToProps = (state) => ({
  acquiringActiveTab: state.acquiring.acquiringActiveTab,
  isMenuOpen: state.auth.isMenuOpen,
  chosenShop: state.acquiring.chosenShop,
  statusOfChoseShop: state.acquiring.statusOfChoseShop,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  szStatusFromDb: state.auth.szStatusFromDb,
  user: state.auth.user,
});

export default connect(mapStateToProps)(Acquiring);
