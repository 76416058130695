import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Icons
import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';

// Styles
import './LockForNonSelfEmployed.scss';

const LockForNonSelfEmployed = ({ className, link }) => {
  return (
    <div
      className={cx('lock-for-non-se', {
        [className]: className,
      })}
      data-testid="lock-for-non-se"
    >
      <div className="lock-for-non-se__icon-wrapper">
        <IconBag className="lock-for-non-se__icon" />
      </div>

      <div className="lock-for-non-se__text">
        Раздел отчетности будет доступен после <br /> подтверждения{' '}
        <Link to={link} className="lock-for-non-se__text-link" data-testid="lock-for-non-se-link">
          статуса «Самозанятого»
        </Link>
      </div>
    </div>
  );
};

LockForNonSelfEmployed.propTypes = {
  className: PropTypes.string,
  link: PropTypes.string.isRequired,
};

LockForNonSelfEmployed.defaultProps = {
  className: null,
};

export default LockForNonSelfEmployed;
