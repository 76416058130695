import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { animated, useSpring } from 'react-spring';
import { CSSTransition } from 'react-transition-group';

import Service from 'modules/profileLegasy/components/Service/Service';
import Warning from 'modules/profileLegasy/components/Warning/Warning';
import { toggleBusinessCard } from 'modules/profileLegasy/actions';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';
import { showIdentificationRequestPopup } from 'modules/identification-request/actions';

import { CONSISTENT_ANIMATIONS_DELAY, PARTLY_YES, YES } from 'constants/index';

import { ReactComponent as IconService1 } from 'static/assets/icon-service-1.svg';
import { ReactComponent as IconService2 } from 'static/assets/icon-service-2.svg';
import { ReactComponent as IconService3 } from 'static/assets/icon-service-3.svg';
import { ReactComponent as IconService4 } from 'static/assets/icon-service-4.svg';
import { ReactComponent as IconServiceCashbox } from 'static/assets/icon-service-cashbox.svg';
import { ReactComponent as IconServiceSaveMoney } from 'static/assets/icon-service-save-money.svg';
import { ReactComponent as LineGroup } from 'static/assets/line-group.svg';
import ImageService4 from 'static/assets/service4-img.png';
import ImageService6 from 'static/assets/service6-img.png';

import './GetMoney.scss';

const GetMoney = ({ dispatch, szStatusFromDb, userTaxStatus, isNotificationVisible }) => {
  const isUserSz = !(userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES));
  const [currentTabId, setCurrentTabId] = useState(0);

  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);

  const warningAnimationDuration = {
    enter: 600,
    exit: 200,
  };

  /** Set isRendered for animations start */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    /* eslint-disable no-nested-ternary */
    setTab0Y(isUserSz ? (currentTabId === 0 ? 70 : 0) : currentTabId === 0 ? 0 : 70);
    setTab0Opacity(isUserSz ? (currentTabId === 0 ? 0 : 1) : currentTabId === 0 ? 1 : 0);

    setTab1Y(isUserSz ? (currentTabId === 1 ? 70 : 0) : currentTabId === 1 ? 0 : 70);
    setTab1Opacity(isUserSz ? (currentTabId === 1 ? 0 : 1) : currentTabId === 1 ? 1 : 0);
    /* eslint-enable no-nested-ternary */
  }, [currentTabId, isUserSz]);

  const transTab0Y = (Tab0Y) => `translateY(${Tab0Y}px)`;
  const transTab1Y = (Tab1Y) => `translateY(${Tab1Y}px)`;
  const [Tab0Opacity, setTab0Opacity] = useState(0);
  const [Tab1Opacity, setTab1Opacity] = useState(0);
  const [Tab0Y, setTab0Y] = useState(50);
  const [Tab1Y, setTab1Y] = useState(50);

  const springOptions = {
    from: {
      opacity: 0,
      Tab0Y: 50,
    },
    Tab0Opacity,
    Tab0Y,
    config: { mass: 1, tension: 280, friction: 120 },
  };

  const springOptions2 = {
    from: {
      opacity: 0,
      Tab1Y: 50,
    },
    Tab1Opacity,
    Tab1Y,
    config: { mass: 1, tension: 280, friction: 120 },
  };

  const spring1 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
  });

  const spring2 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    config: { mass: 0.6, tension: 200, friction: 60 },
  });

  const spring3 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring4 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring5 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring6 = useSpring({
    ...springOptions,
    opacity: Tab0Opacity,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring7 = useSpring({
    ...springOptions2,
    opacity: Tab1Opacity,
  });

  const spring8 = useSpring({
    ...springOptions2,
    opacity: Tab1Opacity,
    config: { mass: 0.6, tension: 200, friction: 60 },
  });

  const spring9 = useSpring({
    ...springOptions2,
    opacity: Tab1Opacity,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const spring10 = useSpring({
    ...springOptions2,
    opacity: Tab1Opacity,
    config: { mass: 0.7, tension: 300, friction: 90 },
  });

  const tabCLick = (index) => {
    setCurrentTabId(index);
  };

  const navTabsArray = [
    {
      title: isUserSz ? 'Для самозанятых' : 'Физическим лицам',
      click: tabCLick.bind(null, 0),
    },
    {
      title: isUserSz ? 'Физическим лицам' : 'Для самозанятых',
      click: tabCLick.bind(null, 1),
    },
  ];

  return (
    <>
      <CSSTransition
        classNames="fade"
        in={windowSize.innerWidth > 1000 && currentTabId === 0 && isNotificationVisible}
        timeout={0}
        unmountOnExit
      >
        <Warning
          customText={
            <div className={'get-money__warning__container'}>
              <h6 className={'get-money__warning__title'}>Заявка на идентификацию</h6>
              <p className={'get-money__warning__text'}>
                Поступила заявка на подтверждение ПД БПА Связной{' '}
                <button onClick={() => dispatch(showIdentificationRequestPopup())}>
                  Подтвердить
                </button>
              </p>
            </div>
          }
        />
      </CSSTransition>
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={windowSize.innerWidth > 1000 && currentTabId === 1 && !isUserSz}
        timeout={warningAnimationDuration}
        unmountOnExit
      >
        <Warning className="animation-from-top-to-bottom" />
      </CSSTransition>

      <div className="get-money container">
        <CSSTransition
          classNames="animation-text"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="get-money__top">
            <h1 className="get-money__title">Прием денег</h1>
            <Link
              to="/lk/money-transfer"
              className="get-money__withdrawal-btn"
              data-cy="withdrawal-btn"
            >
              Вывести деньги
            </Link>
          </div>
        </CSSTransition>

        {/* НАВИГАЦИЯ - ФИЗИЧЕСКИМ ЛИЦАМ / ДЛЯ САМОЗАНЯТЫХ */}
        <CSSTransition
          classNames="animation-text"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <div className="get-money__nav-wrap">
            <TabsWithScrollableActiveState
              className="get-money__nav"
              tabArray={navTabsArray}
              currentTabId={currentTabId}
              isRendered={isRendered}
            />
          </div>
        </CSSTransition>
        {/* /НАВИГАЦИЯ - ФИЗИЧЕСКИМ ЛИЦАМ / ДЛЯ САМОЗАНЯТЫХ */}
        <div className="get-money__content-wrap">
          <div className="get-money__content">
            <div
              className={cx('get-money__content-tab', {
                'get-money__content-tab_active':
                  (currentTabId === 0 && !isUserSz) || (currentTabId === 1 && isUserSz),
              })}
            >
              {windowSize.innerWidth <= 1000 && (
                <animated.div
                  className="get-money__item"
                  style={{
                    opacity: spring1.opacity,
                    transform: spring1.Tab0Y.interpolate(transTab0Y),
                  }}
                >
                  <>
                    {isNotificationVisible && (
                      <Warning
                        positionAbsolute={false}
                        customText={
                          <div className={'get-money__warning__container'}>
                            <h6 className={'get-money__warning__title'}>Заявка на идентификацию</h6>
                            <p className={'get-money__warning__text'}>
                              Поступила заявка на подтверждение ПД БПА Связной{' '}
                              <button onClick={() => dispatch(showIdentificationRequestPopup())}>
                                Подтвердить
                              </button>
                            </p>
                          </div>
                        }
                      />
                    )}
                  </>
                </animated.div>
              )}

              {/* ССЫЛКА-ВИЗИТКА */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring1.opacity,
                  transform: spring1.Tab0Y.interpolate(transTab0Y),
                }}
              >
                <Service
                  title="Ссылка - визитка"
                  desc="Пришлите друзьям с просьбой отправить деньги"
                  icon={<IconService1 className="service__icon" />}
                  isWhite
                  isBig
                  handleClick={() => dispatch(toggleBusinessCard())}
                />
              </animated.div>
              {/* /ССЫЛКА-ВИЗИТКА */}

              {/* ЗАПРОС ДЕНЕГ */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring2.opacity,
                  transform: spring2.Tab0Y.interpolate(transTab0Y),
                }}
              >
                <Service
                  isLink
                  title="Запрос денег"
                  desc="Для некоммерческих целей - мы создадим ссылку на форму оплаты"
                  isWhite
                  isBig
                  icon={<IconService2 className="service__icon" />}
                  href="/lk/money-request"
                />
              </animated.div>
              {/* /ЗАПРОС ДЕНЕГ */}

              {/* СЕРВИС КОПИМ */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring3.opacity,
                  transform: spring3.Tab0Y.interpolate(transTab0Y),
                }}
              >
                <Service
                  title="Сервис «Копим»"
                  isLink
                  isWhite
                  isBig
                  desc="Скиньтесь на подарок или другое благое дело"
                  icon={<IconServiceSaveMoney className="service__icon" />}
                  href="/lk/kopim"
                />
              </animated.div>
              {/* /СЕРВИС КОПИМ */}

              {/* ЭКВАЙРИНГ */}

              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring4.opacity,
                  transform: spring4.Tab0Y.interpolate(transTab0Y),
                }}
              >
                <Service
                  className="service_small"
                  title="Эквайринг"
                  isLink
                  href="/lk/acquiring"
                  isWhite
                  desc="Принимайте оплату на некоммерческие цели как физ.лицо."
                  isNew
                >
                  <LineGroup className="service__line-group" />
                </Service>
              </animated.div>

              {/* /ЭКВАЙРИНГ */}

              {/* СКОРО ВЫПУСК КАРТ */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring5.opacity,
                  transform: spring5.Tab0Y.interpolate(transTab0Y),
                }}
              >
                <Service
                  className="service_small service_cards"
                  title="Скоро выпуск карт"
                  desc="Скоро выпуск платежных карт. Выгодно, удобно и безопасно"
                >
                  <img
                    src={ImageService4}
                    alt="банковская карта"
                    className="service__img service__img_card"
                  />
                </Service>
              </animated.div>
              {/* /СКОРО ВЫПУСК КАРТ */}

              {/* ОПЛАТА ЖКХ, ТЕЛЕФОНА И ТД */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring6.opacity,
                  transform: spring6.Tab0Y.interpolate(transTab0Y),
                }}
              >
                <Service
                  className="service_small service_zkh"
                  title="Оплата услуг"
                  desc="Скоро – оплата ЖКХ, телефона, интернета, штрафов, налогов"
                >
                  <img
                    src={ImageService6}
                    alt="Смартфон с приложением"
                    className="service__img service__img_phone"
                  />
                </Service>
              </animated.div>
              {/* /ОПЛАТА ЖКХ, ТЕЛЕФОНА И ТД */}
            </div>

            <div
              className={cx('get-money__content-tab', {
                'get-money__content-tab_active':
                  (currentTabId === 1 && !isUserSz) || (currentTabId === 0 && isUserSz),
              })}
            >
              {windowSize.innerWidth <= 1000 && (
                <animated.div
                  className="get-money__item"
                  style={{
                    opacity: spring7.opacity,
                    transform: spring7.Tab1Y.interpolate(transTab1Y),
                  }}
                >
                  {currentTabId === 1 && (
                    <>
                      {isNotificationVisible && (
                        <Warning
                          positionAbsolute={false}
                          customText={
                            <div className={'get-money__warning__container'}>
                              <h6 className={'get-money__warning__title'}>
                                Заявка на идентификацию
                              </h6>
                              <p className={'get-money__warning__text'}>
                                Поступила заявка на подтверждение ПД БПА Связной{' '}
                                <button onClick={() => dispatch(showIdentificationRequestPopup())}>
                                  Подтвердить
                                </button>
                              </p>
                            </div>
                          }
                        />
                      )}
                      {!isUserSz && (
                        <Warning
                          className="animation-from-top-to-bottom"
                          positionAbsolute={false}
                        />
                      )}
                    </>
                  )}
                </animated.div>
              )}

              {/* РЕГИСТРАЦИЯ ДОХОДА */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring7.opacity,
                  transform: spring7.Tab1Y.interpolate(transTab1Y),
                }}
              >
                <Service
                  title="Регистрация дохода"
                  desc="Сформировать чек и отправить его покупателю на почту или ссылкой"
                  isWhite
                  isBig
                  isLink
                  href="/lk/income-registration"
                  icon={<IconService3 className="service__icon" />}
                  disabled={!isUserSz}
                />
              </animated.div>
              {/* /РЕГИСТРАЦИЯ ДОХОДА */}

              {/* ВЫСТАВИТЬ СЧЕТ */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring8.opacity,
                  transform: spring8.Tab1Y.interpolate(transTab1Y),
                }}
              >
                <Service
                  title="Выставить счет"
                  desc="Отправьте покупателю платежную форму и после оплаты он получит чек, а вы - деньги"
                  isWhite
                  isBig
                  isLink
                  href="/lk/bill-creation"
                  icon={<IconService4 className="service__icon" />}
                  disabled={!isUserSz}
                />
              </animated.div>
              {/* /ВЫСТАВИТЬ СЧЕТ */}

              {/* СЕРВИС КАССА */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring9.opacity,
                  transform: spring9.Tab1Y.interpolate(transTab1Y),
                }}
              >
                <Service
                  title="Касса"
                  desc="Принимайте платежи за товары и услуги, а покупатели получат от вас чеки"
                  isLink
                  isBig
                  isWhite
                  href="/lk/kassa"
                  disabled={!isUserSz}
                  icon={<IconServiceCashbox className="service__icon" />}
                />
              </animated.div>
              {/* /СЕРВИС КАССА */}

              {/* ЭКВАЙРИНГ */}
              <animated.div
                className="get-money__item"
                style={{
                  opacity: spring10.opacity,
                  transform: spring10.Tab1Y.interpolate(transTab1Y),
                }}
              >
                <Service
                  // className="service_small"
                  title="Эквайринг"
                  isLink
                  href="/lk/acquiring"
                  isWhite
                  disabled={!isUserSz}
                  desc="Подключите оплату от клиентов на сайте или интернет-магазине"
                  isNew
                >
                  <LineGroup className="service__line-group" />
                </Service>
              </animated.div>
              {/* /ЭКВАЙРИНГ */}

              {/* СЧЕТА ДЛЯ КОМПАНИЙ */}
              {/* <animated.div */}
              {/*   className="get-money__item" */}
              {/*   style={{ */}
              {/*     opacity: spring10.opacity, */}
              {/*     transform: spring10.Tab1Y.interpolate(transTab1Y), */}
              {/*   }} */}
              {/* > */}
              {/*   <Service */}
              {/*     className="service_small service_company" */}
              {/*     title="Счет для компаний" */}
              {/*     isWhite */}
              {/*     isLink */}
              {/*     href="/lk/bill-for-company" */}
              {/*     disabled={!isUserSz} */}
              {/*     desc="Принимайте платежи с расчетного счета, а мы автоматически отправим чеки" */}
              {/*   /> */}
              {/* </animated.div> */}
              {/* СЧЕТА ДЛЯ КОМПАНИЙ */}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

GetMoney.propTypes = {
  dispatch: PropTypes.func.isRequired,
  szStatusFromDb: PropTypes.string,
  userTaxStatus: PropTypes.string,
  isNotificationVisible: PropTypes.bool.isRequired,
};

GetMoney.defaultProps = {
  szStatusFromDb: '',
  userTaxStatus: null,
};

const mapStateToProps = (state) => ({
  szStatusFromDb: state.auth.szStatusFromDb,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  isNotificationVisible: state.auth?.user?.activeReqPrivChangeType === 'FULL',
});

export default connect(mapStateToProps)(GetMoney);
