import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';
import useToggleAndOutClick from 'use-toggle-and-outclick';

import {
  changeAcquiringSettings,
  generateMerchantApiKey,
  getAcquiringSettings,
} from 'modules/acquiring/actions';
//import HintAcquiringCommissionsAndLimits from 'modules/acquiring/components/Hints/HintAcquiringCommissionsAndLimits';
//import HintConnectionsWays from 'modules/acquiring/components/Hints/HintConnectionsWays';
//import HintHowToConnectTheShop from 'modules/acquiring/components/Hints/HintHowToConnectTheShop';
//import HintPurposeOfRedirectPage from 'modules/acquiring/components/Hints/HintPurposeOfRedirectPage';
//import HintWhatAreRegisters from 'modules/acquiring/components/Hints/HintWhatAreRegisters';
import HintWhatIsAcquiring from 'modules/acquiring/components/Hints/HintWhatIsAcquiring';
//import HintWhatIsApiKey from 'modules/acquiring/components/Hints/HintWhatIsApiKey';
//import HintWhatIsCallbackNotification from 'modules/acquiring/components/Hints/HintWhatIsCallbackNotification';
import CheckboxWithLabel from 'modules/cashbox/components/CheckboxWithLabel/CheckboxWithLabel';
import ProfileErrorMessage from 'modules/profileLegasy/components/ProfileErrorMessage/ProfileErrorMessage';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CopiedUrlHover from 'modules/shared/components/CopiedUrlHover/CopiedUrlHover';
import CopyItemButton from 'modules/shared/components/CopyItemButton/CopyItemButton';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import PageTitle from 'modules/shared/components/PageTitle/PageTitle';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import Switcher from 'modules/shared/components/Switcher/Switcher';

import { BLUE, FAIL, GREY_BORDER, LOADING } from 'constants/index';

import { ReactComponent as IconEdit } from 'static/assets/icon-edit.svg';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconShowKey } from 'static/assets/icon-password-closed.svg';
import { ReactComponent as IconHideKey } from 'static/assets/icon-password-open.svg';
import { ReactComponent as IconRegenerate } from 'static/assets/icon-regenerate.svg';

import './AcquiringSettingsStep3.scss';

const SMART_PAYMENT = 'SMART_PAYMENT';
const WIDGET = 'WIDGET';

const AcquiringSettingsStep3 = ({ id, isSettingsForExistingMerchant }) => {
  const dispatch = useDispatch();
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [redirectToAcquiring, setRedirectToAcquiring] = useState(false);
  const [isFailToSaveSettings, setIsFailToSaveSettings] = useState(false);
  const [currentOpenPopupId, setCurrentOpenPopupId] = useState(null);
  const [name, setName] = useState('');
  const [domain, setDomain] = useState('');
  const [creationTime, setCreationTime] = useState('');
  const [paymentWay, setPaymentWay] = useState(SMART_PAYMENT);
  const [registryEmail, setRegistryEmail] = useState('');
  const [apiKey, setApiKey] = useState('');
  const [redirect, setRedirect] = useState('');
  const [isImmediateRedirectOnMyPage, setIsImmediateRedirectOnMyPage] = useState(false);
  const [isPushPaymentError, setIsPushPaymentError] = useState(false);
  const [isPushRefund, setIsPushRefund] = useState(false);
  const [isCallbackSuccessPayment, setIsCallbackSuccessPayment] = useState(false);
  const [isCallbackCancelledPayment, setIsCallbackCancelledPayment] = useState(false);
  const [apiKeyGenerationState, setApiKeyGenerationState] = useState(null);
  const [isApiKeyVisible, setIsApiKeyVisible] = useState(false);
  const [callbackUrl, setCallbackUrl] = useState('');
  const [merchantData, setMerchantData] = useState(null);
  const [isLoadingMerchantsData, setIsLoadingMerchantsData] = useState(false);
  const [copiedField, setCopiedField] = useState(null);

  const windowSize = useWindowSize();

  /** Массив подсказок в попапе */
  const hints = [
    {
      id: 0,
      title: 'Что такое эквайринг?',
      titleForPopup: 'Что такое эквайринг?',
      action: () => {
        setCurrentOpenPopupId(0);
        toggleDrop();
      },
      openCondition: currentOpenPopupId === 0 && isDropOpen,
      popup: <HintWhatIsAcquiring />,
      dropEl,
    },
    {
      id: 99,
      title: 'Документация',
      titleForPopup: 'Документация',
      action: () => {
        //setCurrentOpenPopupId(99);
        //toggleDrop();
        window.open('https://docs.selfwork.ru/api/v/acquiring/', '_blank');
      },
      openCondition: currentOpenPopupId === 99 && isDropOpen,
      popup: <></>,
      dropEl,
    },
    {
      id: 98,
      title: 'Список запрещенных товаров и услуг',
      titleForPopup: 'Список запрещенных товаров и услуг',
      action: () => {
        //setCurrentOpenPopupId(98);
        //toggleDrop();
        window.open('https://xn--80aapgyievp4gwb.xn--p1ai/legal/forbidden.pdf', '_blank');
      },
      openCondition: currentOpenPopupId === 98 && isDropOpen,
      popup: <></>,
      dropEl,
    },
    // {
    //   id: 1,
    //   title: 'Какие есть способы подключения?',
    //   titleForPopup: 'Какие есть способы подключения?',
    //   action: () => {
    //     setCurrentOpenPopupId(1);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 1 && isDropOpen,
    //   popup: <HintConnectionsWays />,
    //   dropEl,
    // },
    // {
    //   id: 2,
    //   title: 'Комиссия и лимиты',
    //   titleForPopup: 'Комиссия и лимиты',
    //   action: () => {
    //     setCurrentOpenPopupId(2);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 2 && isDropOpen,
    //   popup: <HintAcquiringCommissionsAndLimits />,
    //   dropEl,
    // },
    // {
    //   id: 3,
    //   title: 'Как подключить магазин?',
    //   titleForPopup: 'Как подключить магазин?',
    //   action: () => {
    //     setCurrentOpenPopupId(3);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 3 && isDropOpen,
    //   popup: <HintHowToConnectTheShop />,
    //   dropEl,
    // },
    // {
    //   id: 4,
    //   title: 'Что такое ключ API и для чего он?',
    //   titleForPopup: 'Что такое ключ API и для чего он?',
    //   action: () => {
    //     setCurrentOpenPopupId(4);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 4 && isDropOpen,
    //   popup: <HintWhatIsApiKey />,
    //   dropEl,
    // },
    // {
    //   id: 5,
    //   title: 'Что такое реестры?',
    //   titleForPopup: 'Что такое реестры?',
    //   action: () => {
    //     setCurrentOpenPopupId(5);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 5 && isDropOpen,
    //   popup: <HintWhatAreRegisters />,
    //   dropEl,
    // },
    // {
    //   id: 6,
    //   title: 'Callback уведомления? Это что?',
    //   titleForPopup: 'Callback уведомления? Это что?',
    //   action: () => {
    //     setCurrentOpenPopupId(6);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 6 && isDropOpen,
    //   popup: <HintWhatIsCallbackNotification />,
    //   dropEl,
    // },
    // {
    //   id: 7,
    //   title: 'Для чего нужна страница редиректа?',
    //   titleForPopup: 'Для чего нужна страница редиректа?',
    //   action: () => {
    //     setCurrentOpenPopupId(7);
    //     toggleDrop();
    //   },
    //   openCondition: currentOpenPopupId === 7 && isDropOpen,
    //   popup: <HintPurposeOfRedirectPage />,
    //   dropEl,
    // },
  ];

  /** Массив для способов подключения: Смарт оплата, Виджет */
  const paymentWaysList = [
    {
      id: SMART_PAYMENT,
      title: 'Смарт оплата',
      desc: 'Направим покупателя на веб-страницу',
      onClick: () => setPaymentWay(SMART_PAYMENT),
      disabled: false,
    },
    {
      id: WIDGET,
      title: 'Виджет Сам.Эквайринг',
      desc: 'Дадим нативную форму на ваш сайт',
      onClick: () => setPaymentWay(WIDGET),
      disabled: false,
    },
  ];

  useEffect(() => {
    if (merchantData) {
      setName(merchantData.name);
      setDomain(merchantData.webDomain);
      setCreationTime(merchantData.dateAdd);
      setPaymentWay(merchantData.connectionMethod);
      setRegistryEmail(merchantData.email || '');
      setApiKey(merchantData.secretKey);
      setRedirect(merchantData.successAndFailResultUrl || `${domain}`);
      setIsImmediateRedirectOnMyPage(merchantData.redirectImmediately);
      setIsPushPaymentError(merchantData.paymentErrorNotify);
      setIsPushRefund(merchantData.paymentRevertNotify);
      setIsCallbackSuccessPayment(merchantData.paymentSucceeded);
      setIsCallbackCancelledPayment(merchantData.paymentCancelled);
      setCallbackUrl(merchantData.callbackUrl || `${domain}`);
    }
  }, [merchantData, domain]);

  /** Закрываем любую подсказку */
  const closePopupHandler = () => {
    closeExplicitly();
    setCurrentOpenPopupId(null);
  };

  /** Сохраняем настройки */
  const saveSettings = () => {
    setIsLoadingMerchantsData(true);
    dispatch(
      changeAcquiringSettings(
        id,
        callbackUrl || `${domain}`,
        paymentWay,
        registryEmail || null,
        name,
        isCallbackCancelledPayment,
        isPushPaymentError,
        isPushRefund,
        isCallbackSuccessPayment,
        isImmediateRedirectOnMyPage,
        redirect || `${domain}`,
      ),
    )
      .then(() => {
        if (
          window &&
          window.location &&
          window.location.href.indexOf('/lk/acquiring-settings') > -1
        ) {
          setRedirectToAcquiring(true);
        }
        setRedirect(redirect || `${domain}`);
        setCallbackUrl(callbackUrl || `${domain}`);
        setIsLoadingMerchantsData(false);
      })
      .catch(() => {
        setIsFailToSaveSettings(true);
        setIsLoadingMerchantsData(false);
      });
  };

  /** Функция для генерации API ключа */
  const generateApiKey = () => {
    setApiKeyGenerationState(LOADING);
    dispatch(generateMerchantApiKey())
      .then((res) => {
        setApiKeyGenerationState(null);
        if (res && res.data) {
          setApiKey(res.data);
        }
      })
      .catch(() => {
        setApiKeyGenerationState(FAIL);
      });
  };

  /** Берем данные по мерчанту */
  const getMerchantsData = () => {
    setIsLoadingMerchantsData(true);
    dispatch(getAcquiringSettings(id))
      .then((res) => {
        setMerchantData(res.data);
        setIsLoadingMerchantsData(false);
      })
      .catch(() => {
        setIsLoadingMerchantsData(false);
      });
  };

  /**
   * Генерируем API ключ и берем данные по мерчанту
   *  при заходе на страницу
   *  */
  useEffect(() => {
    if (!isSettingsForExistingMerchant) {
      generateApiKey();
    }
    getMerchantsData();
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [isSettingsForExistingMerchant, id]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const copyText = (field) => {
    setCopiedField(field);
    setTimeout(() => {
      setCopiedField(null);
    }, 1000);
  };

  if (redirectToAcquiring) {
    return <Redirect to="/lk/acquiring" />;
  }

  return (
    <div
      className={cx('acquiring-getting-step-3', {
        'acquiring-getting-step-3_step': !isSettingsForExistingMerchant,
      })}
    >
      <PageTitle title="Настройка магазина" className="acquiring-getting-step-3__title" />

      {isLoadingMerchantsData ? (
        <div className="acquiring-getting-step-3__loader">
          <CircleLoader />
        </div>
      ) : (
        <div className="acquiring-getting-step-3__content">
          {windowSize.innerWidth <= 1194 && !isSettingsForExistingMerchant && (
            <ProfileErrorMessage animationStart className="acquiring-getting-step-3__message">
              <div className="profile-error-message__text">
                Внимательно заполните и подключите данные, если появятся вопросы, обратитесь в{' '}
                {'  '}
                <span className="js-help profile-error-message__link">службу поддержки</span>.
              </div>
            </ProfileErrorMessage>
          )}

          <div className="acquiring-getting-step-3__inputs">
            <div className="acquiring-getting-step-3__inputs-block">
              <InputWithLabelAnimation
                value={name}
                label="Название магазина"
                className="acquiring-getting-step-3__input"
                onChange={(event) => setName(event.target.value)}
                name="Store title"
                autoComplete="new-store-name"
              >
                <IconEdit className="acquiring-getting-step-3__input-edit-icon" />
              </InputWithLabelAnimation>

              <InputWithLabelAnimation
                value={domain}
                label="Сайт"
                className="acquiring-getting-step-3__input"
                disabled
                customRightPadding={40}
              >
                <CopyItemButton
                  text={domain}
                  copyClick={() => copyText('DOMAIN')}
                  className="acquiring-getting-step-3__input-copy-button acquiring-getting-step-3__input-copy-button_alone"
                  textWithoutMainRoute
                />
                <CopiedUrlHover
                  isVisible={copiedField === 'DOMAIN'}
                  className="acquiring-getting-step-3__copied-hover"
                />
              </InputWithLabelAnimation>

              <InputWithLabelAnimation
                value={`ID ${id}`}
                label="ID магазина"
                className="acquiring-getting-step-3__input"
                disabled
              />

              <InputWithLabelAnimation
                value={creationTime}
                label="Дата создания"
                className="acquiring-getting-step-3__input"
                disabled
              />
            </div>

            <div className="acquiring-getting-step-3__inputs-block">
              <h3 className="acquiring-getting-step-3__inputs-title">Ключ API</h3>

              <div className="acquiring-getting-step-3__inputs-text">
                Нужен для отправки запросов в API, позволяет совершать операции в магазине от вашего
                имени.
              </div>

              <InputWithLabelAnimation
                value={apiKey}
                label="секретный ключ"
                className="acquiring-getting-step-3__input acquiring-getting-step-3__input_password"
                type={isApiKeyVisible ? 'text' : 'password'}
                name="Api key"
                disabled
                customRightPadding={93}
              >
                <button
                  className="acquiring-getting-step-3__input-show-value"
                  type="button"
                  onClick={() => setIsApiKeyVisible((prevState) => !prevState)}
                >
                  {isApiKeyVisible ? (
                    <IconHideKey className="acquiring-getting-step-3__input-show-value-icon" />
                  ) : (
                    <IconShowKey className="acquiring-getting-step-3__input-show-value-icon" />
                  )}
                </button>

                <CopyItemButton
                  text={apiKey}
                  copyClick={() => copyText('API_KEY')}
                  className="acquiring-getting-step-3__input-copy-button"
                  textWithoutMainRoute
                  isDisabled={apiKeyGenerationState === LOADING}
                />

                <button
                  className="acquiring-getting-step-3__input-regenerate"
                  type="button"
                  onClick={generateApiKey}
                >
                  <IconRegenerate className="acquiring-getting-step-3__input-regenerate-icon" />
                </button>

                <CopiedUrlHover
                  isVisible={copiedField === 'API_KEY'}
                  className="acquiring-getting-step-3__copied-hover"
                  title="API ключ скопирован"
                />
              </InputWithLabelAnimation>
            </div>

            <div className="acquiring-getting-step-3__inputs-block">
              <h3 className="acquiring-getting-step-3__inputs-title">Способ подключения</h3>

              <div className="acquiring-getting-step-3__inputs-block-list">
                {paymentWaysList.map((item) => (
                  <button
                    className={cx('acquiring-getting-step-3__page-way', {
                      'acquiring-getting-step-3__page-way_active': paymentWay === item.id,
                      'acquiring-getting-step-3__page-way_disabled': item.disabled,
                    })}
                    type="button"
                    onClick={item.onClick}
                    key={item.id}
                  >
                    <span className="acquiring-getting-step-3__page-way-checkbox" />
                    <span className="acquiring-getting-step-3__page-way-title">{item.title}</span>
                    <span className="acquiring-getting-step-3__page-way-desc">{item.desc}</span>
                  </button>
                ))}
              </div>
            </div>

            {/* <div className="acquiring-getting-step-3__inputs-block"> */}
            {/*   <h3 className="acquiring-getting-step-3__inputs-title">Реестры</h3> */}
            {/*   <InputWithLabelAnimation */}
            {/*     value={registryEmail} */}
            {/*     label="E-mail для реестров" */}
            {/*     className="acquiring-getting-step-3__input" */}
            {/*     onChange={(event) => setRegistryEmail(event.target.value)} */}
            {/*     placeholder="Selfwork@selfwork.com" */}
            {/*     customRightPadding={35} */}
            {/*   > */}
            {/*     <IconEdit className="acquiring-getting-step-3__input-edit-icon" /> */}
            {/*   </InputWithLabelAnimation> */}
            {/* </div> */}

            <div className="acquiring-getting-step-3__inputs-block">
              <h3 className="acquiring-getting-step-3__inputs-title">Редирект</h3>
              <InputWithLabelAnimation
                value={redirect}
                label="Ссылка на успешную и неудачную оплату"
                className="acquiring-getting-step-3__input"
                onChange={(event) => setRedirect(event.target.value)}
                placeholder="https://success.selfwork.ru"
                customRightPadding={35}
              >
                <IconEdit className="acquiring-getting-step-3__input-edit-icon" />
              </InputWithLabelAnimation>

              <CheckboxWithLabel
                onClick={() => setIsImmediateRedirectOnMyPage((prevState) => !prevState)}
                title="Сразу отправлять на мою страницу"
                isChecked={isImmediateRedirectOnMyPage}
                ariaLabel="Сразу отправлять на мою страницу"
                className="acquiring-getting-step-3__redirect-checkbox"
              />
            </div>

            {/* <div className="acquiring-getting-step-3__inputs-block"> */}
            {/*   <h3 className="acquiring-getting-step-3__inputs-title"> */}
            {/*     Внутренние и PUSH уведомления */}
            {/*   </h3> */}

            {/*   <Switcher */}
            {/*     isChecked={isPushPaymentError} */}
            {/*     ariaLabel="Ошибка платежа" */}
            {/*     setIsChecked={() => setIsPushPaymentError((prevState) => !prevState)} */}
            {/*     className="acquiring-getting-step-3__switcher-item" */}
            {/*     title="Ошибка платежа" */}
            {/*   /> */}
            {/*   <Switcher */}
            {/*     isChecked={isPushRefund} */}
            {/*     ariaLabel="Возврат денег покупателю" */}
            {/*     setIsChecked={() => setIsPushRefund((prevState) => !prevState)} */}
            {/*     className="acquiring-getting-step-3__switcher-item" */}
            {/*     title="Возврат денег покупателю" */}
            {/*   /> */}
            {/* </div> */}

            <div className="acquiring-getting-step-3__inputs-block">
              <h3 className="acquiring-getting-step-3__inputs-title">Callback-уведомления</h3>

              <InputWithLabelAnimation
                value={callbackUrl}
                label="Ссылка на уведомления"
                className="acquiring-getting-step-3__input"
                onChange={(event) => setCallbackUrl(event.target.value)}
                placeholder="https://success.selfwork.ru"
                customRightPadding={35}
              >
                <IconEdit className="acquiring-getting-step-3__input-edit-icon" />
              </InputWithLabelAnimation>

              <Switcher
                isChecked={isCallbackSuccessPayment}
                ariaLabel="payment.succeeded"
                setIsChecked={() => setIsCallbackSuccessPayment((prevState) => !prevState)}
                className="acquiring-getting-step-3__switcher-item"
                title="payment.succeeded"
              />
              {/* <Switcher */}
              {/*   isChecked={isCallbackCancelledPayment} */}
              {/*   ariaLabel="payment.canceled" */}
              {/*   setIsChecked={() => setIsCallbackCancelledPayment((prevState) => !prevState)} */}
              {/*   className="acquiring-getting-step-3__switcher-item" */}
              {/*   title="payment.canceled" */}
              {/* /> */}
            </div>

            <div className="acquiring-getting-step-3__buttons-list">
              {isSettingsForExistingMerchant ? (
                <>
                  <StandardButton
                    title="Сохранить"
                    isLink={false}
                    className="acquiring-getting-step-3__buttons-item"
                    bgType={BLUE}
                    onClick={saveSettings}
                  />
                </>
              ) : (
                <>
                  <StandardButton
                    title="Пропустить"
                    isLink
                    href="/lk/acquiring"
                    className="acquiring-getting-step-3__buttons-item"
                    bgType={GREY_BORDER}
                  />
                  <StandardButton
                    title="Сохранить"
                    isLink={false}
                    className="acquiring-getting-step-3__buttons-item"
                    bgType={BLUE}
                    onClick={saveSettings}
                  />
                </>
              )}
            </div>
          </div>
          <div className="acquiring-getting-step-3__side">
            {windowSize.innerWidth > 1194 && !isSettingsForExistingMerchant && (
              <ProfileErrorMessage animationStart className="acquiring-getting-step-3__message">
                <div className="profile-error-message__text">
                  Внимательно заполните и подключите данные, если появятся вопросы, обратитесь в{' '}
                  {'  '}
                  <span className="js-help profile-error-message__link">службу поддержки</span>.
                </div>
              </ProfileErrorMessage>
            )}

            <HintsList
              className="acquiring-getting-step-3__hints"
              hints={hints}
              noTitle
              closeHintHandler={closePopupHandler}
            />
          </div>
        </div>
      )}

      <SmallPopupWithEmoji
        title="Упс!"
        emoji={emojiSad}
        closePopup={() => setIsFailToSaveSettings(false)}
        className="small-popup-with-emoji"
        mounted={isFailToSaveSettings}
        buttonArray={[
          {
            id: 1,
            title: 'Пропустить сохранение',
            action: () => {
              setIsFailToSaveSettings(false);
              setRedirectToAcquiring(true);
            },
            colorType: 'grey-border',
          },
          {
            id: 2,
            title: 'Попробовать еще раз',
            action: () => setIsFailToSaveSettings(false),
            colorType: 'blue',
          },
        ]}
      >
        <p>
          К сожалению, что-то пошло не так и нам не удалось отправить данные на сервер. Попробуйте
          сохранить настройки позже. У вас всегда будет возможность сделать это в личном кабинете
          эквайинга
        </p>
      </SmallPopupWithEmoji>
    </div>
  );
};

AcquiringSettingsStep3.propTypes = {
  id: PropTypes.string,
  isSettingsForExistingMerchant: PropTypes.bool,
};

AcquiringSettingsStep3.defaultProps = {
  id: '',
  isSettingsForExistingMerchant: false,
};

export default AcquiringSettingsStep3;
