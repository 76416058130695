import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './ServiceCardContent.scss';

const ServiceCardContent = ({ title, desc, className, isWhite }) => {
  return (
    <div
      className={cx('service-card-content', {
        [className]: className,
        'service-card-content_white-bg': isWhite,
      })}
      data-testid="service-card-content"
    >
      <h3 className="service-card-content__title">{title}</h3>
      <div className="service-card-content__desc">{desc}</div>
    </div>
  );
};

ServiceCardContent.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string.isRequired,
  isWhite: PropTypes.bool,
};

ServiceCardContent.defaultProps = {
  className: null,
  isWhite: false,
};

export default ServiceCardContent;
