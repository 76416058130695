import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

// Components
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Misc
import { GREY_BORDER, BLUE, BLUE_BORDER, LIGHT_BLUE, VIOLET } from 'constants/index';

// Style
import './StandardButton.scss';

const StandardButton = ({
  isLink,
  title,
  href,
  onClick,
  className,
  bgType,
  target,
  disabled,
  isOutAppLink,
  isLoading,
  onKeyUp,
}) => {
  return (
    <>
      {isLink && href && !disabled ? (
        <>
          {isOutAppLink ? (
            <a
              href={href}
              className={cx('standard-button', {
                [className]: className,
                'standard-button_blue': bgType === BLUE,
                'standard-button_grey-border': bgType === GREY_BORDER,
                'standard-button_blue-border': bgType === BLUE_BORDER,
                'standard-button_light-blue': bgType === LIGHT_BLUE,
                'standard-button_violet': bgType === VIOLET,
              })}
              data-testid="standard-button"
              target={target}
              rel={target === '_blank' ? 'noopener noreferrer' : ''}
              data-cy="standard-button"
            >
              {title}
            </a>
          ) : (
            <Link
              to={href}
              className={cx('standard-button', {
                [className]: className,
                'standard-button_blue': bgType === BLUE,
                'standard-button_grey-border': bgType === GREY_BORDER,
                'standard-button_blue-border': bgType === BLUE_BORDER,
                'standard-button_light-blue': bgType === LIGHT_BLUE,
                'standard-button_violet': bgType === VIOLET,
              })}
              data-testid="standard-button"
              data-cy="standard-button"
              onClick={onClick}
              onKeyUp={onKeyUp}
            >
              {title}
            </Link>
          )}
        </>
      ) : (
        <button
          type="button"
          onClick={onClick}
          onKeyUp={onKeyUp}
          className={cx('standard-button', {
            [className]: className,
            'standard-button_blue': bgType === BLUE,
            'standard-button_grey-border': bgType === GREY_BORDER,
            'standard-button_blue-border': bgType === BLUE_BORDER,
            'standard-button_light-blue': bgType === LIGHT_BLUE,
            'standard-button_violet': bgType === VIOLET,
          })}
          data-testid="standard-button"
          disabled={disabled || isLoading}
          data-cy="standard-button"
        >
          {isLoading ? <CircleLoader /> : title}
        </button>
      )}
    </>
  );
};

StandardButton.propTypes = {
  className: PropTypes.string,
  bgType: PropTypes.string,
  isLink: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  onKeyUp: PropTypes.func,
  target: PropTypes.string,
  disabled: PropTypes.bool,
  isOutAppLink: PropTypes.bool,
  isLoading: PropTypes.bool,
};

StandardButton.defaultProps = {
  bgType: BLUE,
  className: null,
  href: null,
  onClick: () => {},
  onKeyUp: () => {},
  target: null,
  disabled: false,
  isOutAppLink: false,
  isLoading: false,
};

export default StandardButton;
