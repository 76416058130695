import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import screen3 from 'static/assets/manual-screen-3.png';
import phone10 from 'static/assets/phone-10.png';

const ManualPageStepWallet = ({ returnBack, setStepGoToSzConfirmation }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  const walletList = [
    {
      id: 1,
      text: (
        <>
          Перейдите {'  '}
          <a href="https://pro.selfwork.ru/" rel="noopener noreferrer" target="_blank">
            на сайт кошелька
          </a>
          {'  '}и нажмите на {'  '}
          <b>Открыть кошелек.</b>
        </>
      ),
    },
    {
      id: 2,
      text:
        'Далее вам нужно будет указать номер своего телефона, после чего вам придет код по смс для его подтверждения. Это защита от регистрации без вашего ведома. Именно этот номер будет указываться для авторизации в кошельке в дальнейшем.',
    },
    {
      id: 3,
      text:
        'После успешной авторизации вам будет предложено подтвердить статус самозанятого. Вы можете это сделать сразу же, или в любой момент, следуя инструкциям ниже.',
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">
            Регистрация
            <br />в сервисе “Кошелек”
          </h1>

          {windowSize.innerWidth <= 760 && (
            <div className="manual-page__images-container">
              <img src={phone10} alt="" className="manual-page__phone manual-page__phone_10" />
              <img src={screen3} alt="" className="manual-page__screen manual-page__screen_3" />
            </div>
          )}

          <ol className="manual-page__text-list">
            {walletList.map(item => (
              <li className="manual-page__list-item" key={item.id}>
                <p className="manual-page__list-item-number">{item.id}</p>
                <div className="manual-page__list-item-text">{item.text}</div>
              </li>
            ))}
          </ol>

          <div className="manual-page__button-list">
            <StandardButton
              title="Как подтвердить статус в кошельке"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToSzConfirmation}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered && windowSize.innerWidth > 760}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={phone10} alt="" className="manual-page__phone manual-page__phone_10" />
          <img src={screen3} alt="" className="manual-page__screen manual-page__screen_3" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepWallet.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToSzConfirmation: PropTypes.func.isRequired,
};

export default ManualPageStepWallet;
