import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Styles
import './FnsErrorMessage.scss';

const FnsErrorMessage = ({ className, text }) => {
  return (
    <div
      className={cx('fns-error-message', {
        [className]: className,
      })}
    >
      {text ? (
        <div className="fns-error-message__text">
          {text}. Попробуйте позже, а если ошибка не пройдет{' '}
          <span className="fns-error-message__text-link js-help">напишите нам</span>.
        </div>
      ) : (
        <div className="fns-error-message__text">
          Произошла какая-то ошибка при обращении к ФНС. Не удалось загрузить данные. Попробуйте
          позже, а если ошибка не пройдет{' '}
          <span className="fns-error-message__text-link js-help">напишите нам</span>.
        </div>
      )}
    </div>
  );
};

FnsErrorMessage.propTypes = {
  className: PropTypes.string,
  text: PropTypes.string,
};

FnsErrorMessage.defaultProps = {
  className: null,
  text: null,
};

export default FnsErrorMessage;
