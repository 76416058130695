import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import React, { useEffect, useState } from 'react';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import ManualPageStepCheckCitizenship from 'modules/manual/components/ManualPageStepCheckCitizenship/ManualPageStepCheckCitizenship';
import ManualPageStepMyTax1 from 'modules/manual/components/ManualPageStepMyTax1/ManualPageStepMyTax1';
import ManualPageStepMyTax2 from 'modules/manual/components/ManualPageStepMyTax2/ManualPageStepMyTax2';
import ManualPageStepMyTaxGosuslugi from 'modules/manual/components/ManualPageStepMyTaxGosuslugi/ManualPageStepMyTaxGosuslugi';
import ManualPageStepMyTaxLk from 'modules/manual/components/ManualPageStepMyTaxLk/ManualPageStepMyTaxLk';
import ManualPageStepMyTaxPassport from 'modules/manual/components/ManualPageStepMyTaxPassport/ManualPageStepMyTaxPassport';
import ManualPageStepRegistrationForEuu from 'modules/manual/components/ManualPageStepRegistrationForEuu/ManualPageStepRegistrationForEuu';
import ManualPageStepRegistrationForRf from 'modules/manual/components/ManualPageStepRegistrationForRf/ManualPageStepRegistrationForRf';
import ManualPageStepStart from 'modules/manual/components/ManualPageStepStart/ManualPageStepStart';
import ManualPageStepSzStatus1 from 'modules/manual/components/ManualPageStepSzStatus1/ManualPageStepSzStatus1';
import ManualPageStepSzStatus2 from 'modules/manual/components/ManualPageStepSzStatus2/ManualPageStepSzStatus2';
import ManualPageStepSzStatus3 from 'modules/manual/components/ManualPageStepSzStatus3/ManualPageStepSzStatus3';
import ManualPageStepWallet from 'modules/manual/components/ManualPageStepWallet/ManualPageStepWallet';
import ManualPageStepWebVersion from 'modules/manual/components/ManualPageStepWebVersion/ManualPageStepWebVersion';
import ManualPageStepWebVersionGosuslugi from 'modules/manual/components/ManualPageStepWebVersionGosuslugi/ManualPageStepWebVersionGosuslugi';
import ManualPageStepWebVersionLk from 'modules/manual/components/ManualPageStepWebVersionLk/ManualPageStepWebVersionLk';
import ManualProgressSteps from 'modules/manual/components/ManualProgressSteps/ManualProgressSteps';
import ManualPageStepCooperation from 'modules/manual/components/ManualPageStepCooperation/ManualPageStepCooperation';
import ManualPageStepCooperation2 from 'modules/manual/components/ManualPageStepCooperation2/ManualPageStepCooperation2';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import ManualDropdown from 'modules/manual/components/ManualDropdown/ManualDropdown';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';

// TODO: PRO-384
// import iconHand from 'static/assets/icon-emoji-hand.png';
import { ReactComponent as IconLogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as IconLogo } from 'static/assets/logo.svg';
import { ReactComponent as IconCaret } from 'static/assets/icon-dropdown-arrow.svg';

import './ManualPage.scss';

const START = 'START';
const CHECK_CITIZENSHIP = 'CHECK_CITIZENSHIP';
const REGISTRATION_FOR_RF = 'REGISTRATION_FOR_RF';
const REGISTRATION_FOR_EEU = 'REGISTRATION_FOR_EEU';
const MY_TAX_1 = 'MY_TAX_1';
const MY_TAX_2 = 'MY_TAX_2';
const MY_TAX_PASSPORT = 'MY_TAX_PASSPORT';
const MY_TAX_LK = 'MY_TAX_LK';
const MY_TAX_GOSUSLUGI = 'MY_TAX_GOSUSLUGI';
const WEB_VERSION = 'WEB_VERSION';
const WEB_VERSION_LK = 'WEB_VERSION_LK';
const WEB_VERSION_GOSUSLUGI = 'WEB_VERSION_GOSUSLUGI';
const WALLET = 'WALLET';
const SZ_STATUS_CONFIRMATION_1 = 'SZ_STATUS_CONFIRMATION_1';
const SZ_STATUS_CONFIRMATION_2 = 'SZ_STATUS_CONFIRMATION_2';
const SZ_STATUS_CONFIRMATION_3 = 'SZ_STATUS_CONFIRMATION_3';
const COOPERATION_1 = 'COOPERATION_1';
const COOPERATION_2 = 'COOPERATION_2';

const ManualPage = () => {
  const [redirectOnLk, setRedirectOnLk] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();
  const [step, setStep] = useState(START);
  const [stepOnCitizenship, setStepOnCitizenship] = useState(null);
  const [stepOnStart, setStepOnStart] = useState(null);
  const [isDropOpen, dropEl, toggleDrop, closeDrop] = useToggleAndOutClick();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step]);

  const detectActiveStep = () => {
    if (step === WALLET) {
      return 2;
    }
    if (
      step === SZ_STATUS_CONFIRMATION_1 ||
      step === SZ_STATUS_CONFIRMATION_2 ||
      step === SZ_STATUS_CONFIRMATION_3
    ) {
      return 3;
    }
    if (step === COOPERATION_1 || step === COOPERATION_2) {
      return 4;
    }
    return 1;
  };

  const setStepByNavClick = (id) => {
    if (id === 1) {
      setStep(CHECK_CITIZENSHIP);
    } else if (id === 2) {
      setStep(WALLET);
    } else if (id === 3) {
      setStep(SZ_STATUS_CONFIRMATION_1);
    } else if (id === 4) {
      setStep(COOPERATION_1);
    } else {
      setStep(START);
    }

    closeDrop();
  };

  /** Редирект на ЛК */
  if (redirectOnLk) {
    return <Redirect to="/lk" />;
  }

  return (
    <div
      className={cx('manual-page', {
        'manual-page_start': step === START,
        'manual-page_check-citizenship': step === CHECK_CITIZENSHIP,
        'manual-page_registration': step === REGISTRATION_FOR_RF || step === REGISTRATION_FOR_EEU,
        'manual-page_registration_2': step === REGISTRATION_FOR_EEU,
        'manual-page_my-tax-1': step === MY_TAX_1,
        'manual-page_my-tax-2': step === MY_TAX_2,
        'manual-page_my-tax-passport': step === MY_TAX_PASSPORT,
        'manual-page_my-tax-lk': step === MY_TAX_LK,
        'manual-page_my-tax-gosuslugi': step === MY_TAX_GOSUSLUGI,
        'manual-page_web-version': step === WEB_VERSION,
        'manual-page_web-lk': step === WEB_VERSION_LK,
        'manual-page_web-gosuslugi': step === WEB_VERSION_GOSUSLUGI,
        'manual-page_wallet': step === WALLET,
        'manual-page_sz-status-confirmation-1': step === SZ_STATUS_CONFIRMATION_1,
        'manual-page_sz-status-confirmation-2': step === SZ_STATUS_CONFIRMATION_2,
        'manual-page_sz-status-confirmation-3': step === SZ_STATUS_CONFIRMATION_3,
        'manual-page_cooperation-1': step === COOPERATION_1,
        'manual-page_cooperation-2': step === COOPERATION_2,
      })}
    >
      <div className="manual-page__top-wrap">
        {/* ХЭДЕР */}
        <CSSTransition
          classNames="animation-manual"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="manual-page__header-wrap">
            <div className="manual-page__header-left">
              <Link to="/" className="manual-page__header-logo-link">
                {windowSize.innerWidth > 1024 ? (
                  <IconLogo className="manual-page__header-logo" />
                ) : (
                  <IconLogoResponsive className="manual-page__header-logo" />
                )}
              </Link>

              {step !== START && windowSize.innerWidth <= 1024 && (
                <>
                  <button
                    className="manual-page__dropdown-button"
                    onClick={toggleDrop}
                    type="button"
                  >
                    <p className="manual-page__dropdown-button-text">Перейти к разделу</p>
                    <IconCaret className="manual-page__dropdown-button-caret" />
                  </button>
                </>
              )}
            </div>

            {step !== START && windowSize.innerWidth > 1024 && (
              <ManualProgressSteps
                className="manual-page__progress"
                activeButton={detectActiveStep()}
                setStepByNavClick={setStepByNavClick}
              />
            )}

            <PopupCloseWithText closePopupHandler={() => setRedirectOnLk(true)} />
          </div>
        </CSSTransition>
        {/* /ХЭДЕР */}

        <CSSTransition
          classNames="animation-manual"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <div className="manual-page__container">
            {step === START && (
              <ManualPageStepStart
                setStepCheckCitizenship={() => {
                  setStep(CHECK_CITIZENSHIP);
                  setStepOnStart(WEB_VERSION);
                }}
                setStepWallet={() => {
                  setStep(WALLET);
                  setStepOnStart(START);
                }}
              />
            )}

            {step === CHECK_CITIZENSHIP && (
              <ManualPageStepCheckCitizenship
                setStepGoToRegForEEU={() => {
                  setStep(REGISTRATION_FOR_EEU);
                  setStepOnCitizenship(REGISTRATION_FOR_EEU);
                }}
                setStepGoToRegForRf={() => {
                  setStep(REGISTRATION_FOR_RF);
                  setStepOnCitizenship(REGISTRATION_FOR_RF);
                }}
                returnBack={() => setStep(START)}
              />
            )}

            {step === REGISTRATION_FOR_RF && (
              <ManualPageStepRegistrationForRf
                returnBack={() => setStep(CHECK_CITIZENSHIP)}
                setStepGoToMyTax1={() => setStep(MY_TAX_1)}
                setStepGoToWebVersion={() => setStep(WEB_VERSION)}
              />
            )}

            {step === REGISTRATION_FOR_EEU && (
              <ManualPageStepRegistrationForEuu
                returnBack={() => setStep(CHECK_CITIZENSHIP)}
                setStepGoToMyTax1={() => setStep(MY_TAX_1)}
                setStepGoToWebVersion={() => setStep(WEB_VERSION)}
              />
            )}

            {step === MY_TAX_1 && (
              <ManualPageStepMyTax1
                returnBack={() => setStep(stepOnCitizenship)}
                setStepGoToMyTax2={() => setStep(MY_TAX_2)}
              />
            )}

            {step === MY_TAX_2 && (
              <ManualPageStepMyTax2
                returnBack={() => setStep(MY_TAX_1)}
                setStepGoToMyTaxPassport={() => setStep(MY_TAX_PASSPORT)}
                setStepGoToMyTaxLk={() => setStep(MY_TAX_LK)}
                setStepGoToMyTaxGosuslugi={() => setStep(MY_TAX_GOSUSLUGI)}
              />
            )}

            {step === MY_TAX_PASSPORT && (
              <ManualPageStepMyTaxPassport
                returnBack={() => setStep(MY_TAX_2)}
                setStepGoToWallet={() => setStep(WALLET)}
              />
            )}

            {step === MY_TAX_LK && (
              <ManualPageStepMyTaxLk
                returnBack={() => setStep(MY_TAX_2)}
                setStepGoToWallet={() => setStep(WALLET)}
              />
            )}

            {step === MY_TAX_GOSUSLUGI && (
              <ManualPageStepMyTaxGosuslugi
                returnBack={() => setStep(MY_TAX_2)}
                setStepGoToWallet={() => setStep(WALLET)}
              />
            )}

            {step === WEB_VERSION && (
              <ManualPageStepWebVersion
                returnBack={() => setStep(stepOnCitizenship)}
                setStepGoToWebVersionLk={() => setStep(WEB_VERSION_LK)}
                setStepGoToWebVersionGosuslugi={() => setStep(WEB_VERSION_GOSUSLUGI)}
              />
            )}

            {step === WEB_VERSION_LK && (
              <ManualPageStepWebVersionLk
                returnBack={() => setStep(WEB_VERSION)}
                setStepGoToWallet={() => setStep(WALLET)}
              />
            )}

            {step === WEB_VERSION_GOSUSLUGI && (
              <ManualPageStepWebVersionGosuslugi
                returnBack={() => setStep(WEB_VERSION)}
                setStepGoToWallet={() => setStep(WALLET)}
              />
            )}

            {step === WALLET && (
              <ManualPageStepWallet
                returnBack={() => setStep(stepOnStart)}
                setStepGoToSzConfirmation={() => setStep(SZ_STATUS_CONFIRMATION_1)}
              />
            )}

            {step === SZ_STATUS_CONFIRMATION_1 && (
              <ManualPageStepSzStatus1
                returnBack={() => setStep(WEB_VERSION)}
                setStepGoToSzConfirmation={() => setStep(SZ_STATUS_CONFIRMATION_2)}
              />
            )}

            {step === SZ_STATUS_CONFIRMATION_2 && (
              <ManualPageStepSzStatus2
                returnBack={() => setStep(SZ_STATUS_CONFIRMATION_1)}
                setStepGoToSzConfirmation={() => setStep(SZ_STATUS_CONFIRMATION_3)}
              />
            )}

            {step === SZ_STATUS_CONFIRMATION_3 && (
              <ManualPageStepSzStatus3
                returnBack={() => setStep(SZ_STATUS_CONFIRMATION_2)}
                setStepCooperation={() => setStep(COOPERATION_1)}
              />
            )}

            {step === COOPERATION_1 && (
              <ManualPageStepCooperation
                returnBack={() => setStep(SZ_STATUS_CONFIRMATION_3)}
                setStepCooperation2={() => setStep(COOPERATION_2)}
              />
            )}

            {step === COOPERATION_2 && (
              <ManualPageStepCooperation2 returnBack={() => setStep(COOPERATION_1)} />
            )}
          </div>
        </CSSTransition>
      </div>

      {/* TODO: PRO-384 */}
      {/* <div className="manual-page__chat-button-wrap">
        <button className="manual-page__chat-button js-help" type="button">
          Чат поддержки{' '}
          <div className="manual-page__chat-button-image">
            <img src={iconHand} alt="" className="manual-page__chat-button-icon" />
          </div>
        </button>
      </div> */}
      {isDropOpen && (
        <ManualDropdown
          activeButton={detectActiveStep()}
          setStepByNavClick={setStepByNavClick}
          ref={dropEl}
        />
      )}
    </div>
  );
};

export default ManualPage;
