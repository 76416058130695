import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './SupportButton.module.scss';

/**
 * Компонент для вызова окна с поддержкой.
 */
const SupportButton = ({ className, children, onClick }) => (
  <button className={cx('js-help', styles.supportButton, className)} onClick={onClick}>
    {children}
  </button>
);

SupportButton.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  onClick: PropTypes.func,
};

export default SupportButton;
