import React, { useState, useEffect, useRef } from 'react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';

// Icons
import { ReactComponent as IconMasterCardLogo } from 'static/assets/mastercard-logo.svg';
import { ReactComponent as IconMaestroLogo } from 'static/assets/maestro-logo.svg';
import { ReactComponent as IconMirLogo } from 'static/assets/mir-logo.svg';
import { ReactComponent as IconVisaLogo } from 'static/assets/visa-logo.svg';

// Styles
import './BankCard.scss';

export const BankCard = ({
  className,
  isEnableToPay,
  bankCardData,
  onChangeCardNumberInput,
  onChangeCardMonthInput,
  onChangeCardYearInput,
  onChangeCardCvvInput,
  handleChangeMonthOnBlur,
  cardNumber,
  cardMonth,
  cardYear,
  cardCvv,
  isExpireDateValid,
}) => {
  const [cardBg, setCardBg] = useState(
    'linear-gradient(252deg, rgba(208, 240, 254, 1) 0%, rgba(181, 217, 255, 1) 100%)',
  );
  const [cardBankLogo, setCardBankLogo] = useState(null);
  const [cardBankName, setCardBankName] = useState(null);
  const [cardTextColor, setCardTextColor] = useState(null);
  const [cardBrandNameLogo, setCardBrandNameLogo] = useState(null);
  const [brandName, setBrandName] = useState(null);
  const [brandAlias, setBrandAlias] = useState(null);

  const cardNumberRef = useRef();
  const cardYearRef = useRef();
  const cardMonthRef = useRef();
  const cardCvvRef = useRef();

  useEffect(() => {
    setCardBg('linear-gradient(252deg, rgba(208, 240, 254, 1) 0%, rgba(181, 217, 255, 1) 100%)');
    setBrandAlias(null);

    if (bankCardData && bankCardData.bankColors && Array.isArray(bankCardData.bankColors)) {
      setCardBg(bankCardData.bankColors[0]);
    }

    if (
      bankCardData &&
      bankCardData.bankColors &&
      Array.isArray(bankCardData.bankColors) &&
      bankCardData.bankColors.length === 0 &&
      bankCardData.bankColor
    ) {
      setCardBg(bankCardData.bankColor);
    }

    setCardBankLogo(bankCardData && bankCardData.bankLogoBigLightSvg);
    setCardBankName(bankCardData && bankCardData.bankName);
    /**
     * Цвет с сервиса приходит иногда неподходящий, поэтому
     * заменим его на белый, а если не будет приходить, то на серый
     * */
    setCardTextColor(bankCardData && bankCardData.formTextColor ? '#ffffff' : '');
    setCardBrandNameLogo(bankCardData && bankCardData.brandLogoOriginalSvg);
    setBrandName(bankCardData && bankCardData.brandName);

    if (bankCardData && bankCardData.brandAlias && cardNumber.length >= 1) {
      setBrandAlias(bankCardData.brandAlias);
    }
    /* eslint-disable */
  }, [bankCardData, cardNumber]);
  /* eslint-enable */

  useEffect(() => {
    if (cardNumber.length === 16) {
      cardMonthRef.current.focus();
    }
  }, [cardNumber]);

  useEffect(() => {
    if (cardMonth.length === 2) {
      cardYearRef.current.focus();
    }
  }, [cardMonth]);

  useEffect(() => {
    if (cardYear.length === 2) {
      cardCvvRef.current.focus();
    }
  }, [cardYear]);

  const beforeMaskedValueChange = (newState, oldState, userInput) => {
    let { value } = newState;
    if (userInput && userInput.length > 2) {
      value = userInput.slice(2);
    }
    return {
      ...newState,
      value,
    };
  };

  return (
    <div
      className={cx('bank-card', {
        [className]: className,
      })}
      data-testid="bank-card"
      style={{ background: cardBg }}
    >
      {cardBankLogo && <img className="bank-card__logo" src={cardBankLogo} alt={cardBankName} />}

      <div className="bank-card__number">
        <div className="bank-card__input-holder">
          <InputMask
            className="bank-card__input"
            type="tel"
            placeholder="Номер карты"
            maskChar={null}
            mask="7999 - 9999 - 9999 - 9999"
            value={cardNumber}
            name="ccnum"
            x-autocompletetype="cc-number"
            onChange={onChangeCardNumberInput}
            onFocus={(e) => {
              e.target.placeholder = '';
            }}
            onBlur={(e) => {
              e.target.placeholder = 'Номер карты';
            }}
            readOnly={!isEnableToPay}
            inputRef={(node) => {
              cardNumberRef.current = node;
            }}
            formatChars={{
              '9': '[0-9]',
              a: '[A-Za-z]',
              '*': '[A-Za-z0-9]',
              '7': '[5,4,2]',
            }}
          />
        </div>
      </div>

      <div className="bank-card__bottom">
        <div className="bank-card__expire">
          <div
            className={cx('bank-card__input-holder', {
              'bank-card__input-holder_error': !isExpireDateValid,
            })}
            data-testid="bank-card-month-holder"
          >
            <InputMask
              type="tel"
              className="bank-card__input"
              placeholder="ММ"
              maskChar={null}
              mask="99"
              value={cardMonth}
              name="ccmonth"
              onChange={onChangeCardMonthInput}
              onFocus={({ target }) => {
                target.placeholder = '';
              }}
              onBlur={({ target }) => {
                target.placeholder = 'ММ';
                handleChangeMonthOnBlur(target.value);
              }}
              x-autocompletetype="cc-exp-month"
              readOnly={!isEnableToPay}
              inputRef={(node) => {
                cardMonthRef.current = node;
              }}
            />
          </div>

          <span className="bank-card__expire-text" style={{ color: cardTextColor }}>
            /
          </span>

          <div
            className={cx('bank-card__input-holder', {
              'bank-card__input-holder_error': !isExpireDateValid,
            })}
            data-testid="bank-card-year-holder"
          >
            <InputMask
              type="tel"
              className="bank-card__input"
              placeholder="ГГ"
              maskChar={null}
              mask="99"
              value={cardYear}
              name="ccyear"
              onChange={onChangeCardYearInput}
              beforeMaskedValueChange={beforeMaskedValueChange}
              onFocus={(e) => {
                e.target.placeholder = '';
              }}
              onBlur={(e) => {
                e.target.placeholder = 'ГГ';
              }}
              x-autocompletetype="cc-exp-year"
              readOnly={!isEnableToPay}
              inputRef={(node) => {
                cardYearRef.current = node;
              }}
            />
          </div>

          {!isExpireDateValid && (
            <p className="bank-card__error-message">Введенный срок действия карты не валиден</p>
          )}
        </div>

        <div className="bank-card__cvv">
          <p className="bank-card__cvv-text" style={{ color: cardTextColor }}>
            Три цифры на обороте карты
          </p>
          <div className="bank-card__input-holder">
            <input
              type="tel"
              className="bank-card__input"
              placeholder="CVV"
              name="cvv"
              pattern="[0-9]*"
              value={cardCvv}
              onChange={onChangeCardCvvInput}
              onFocus={(e) => {
                e.target.placeholder = '';
              }}
              onBlur={(e) => {
                e.target.placeholder = 'CVV';
              }}
              readOnly={!isEnableToPay}
              ref={cardCvvRef}
            />
          </div>
        </div>
      </div>

      {cardNumber.length >= 1 && bankCardData && brandAlias === 'mastercard' && (
        <IconMasterCardLogo className="bank-card__brand-name" />
      )}

      {cardNumber.length >= 1 && bankCardData && brandAlias === 'maestro' && (
        <IconMaestroLogo className="bank-card__brand-name" />
      )}

      {cardNumber.length >= 1 && bankCardData && brandAlias === 'mir' && (
        <IconMirLogo className="bank-card__brand-name" />
      )}

      {cardNumber.length >= 1 && bankCardData && brandAlias === 'visa' && (
        <IconVisaLogo
          className="bank-card__brand-name bank-card__brand-name_visa"
          fill={cardTextColor || '#284195'}
        />
      )}

      {cardNumber.length >= 1 &&
        bankCardData &&
        brandAlias !== 'mastercard' &&
        brandAlias !== 'maestro' &&
        brandAlias !== 'mir' &&
        brandAlias !== 'visa' &&
        cardBrandNameLogo && (
          <img
            className="bank-card-only-number__brand-name"
            src={cardBrandNameLogo}
            alt={brandName}
          />
        )}
    </div>
  );
};

BankCard.propTypes = {
  className: PropTypes.string,
  isEnableToPay: PropTypes.bool,
  onChangeCardNumberInput: PropTypes.func.isRequired,
  onChangeCardMonthInput: PropTypes.func.isRequired,
  onChangeCardYearInput: PropTypes.func.isRequired,
  onChangeCardCvvInput: PropTypes.func.isRequired,
  handleChangeMonthOnBlur: PropTypes.func.isRequired,
  bankCardData: PropTypes.shape({
    bankColors: PropTypes.arrayOf(PropTypes.string),
    formTextColor: PropTypes.string,
    bankColor: PropTypes.string,
    bankLogoBigLightSvg: PropTypes.string,
    bankName: PropTypes.string,
    brandLogoOriginalSvg: PropTypes.string,
    brandName: PropTypes.string,
    brandAlias: PropTypes.string,
  }),
  cardNumber: PropTypes.string.isRequired,
  cardMonth: PropTypes.string.isRequired,
  cardYear: PropTypes.string.isRequired,
  cardCvv: PropTypes.string.isRequired,
  isExpireDateValid: PropTypes.bool.isRequired,
};

BankCard.defaultProps = {
  className: null,
  bankCardData: null,
  isEnableToPay: true,
};

const mapStateToProps = (state) => ({
  bankCardData: state.profile.bankCardData,
});

export default connect(mapStateToProps)(BankCard);
