import React from 'react';

const HintHowTaxIsCalculated = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      <p>Налог высчитывается по ставкам:</p>
      <p>
        - 4% используется при расчете налога с продаж товаров и услуг физическим лицам (российским и
        иностранным);
      </p>
      <p>
        - 6% используется при расчете налога с продаж товаров и услуг компаниям (российским и
        иностранным) и индивидуальным предпринимателям.
      </p>

      <p>Также предусмотрен налоговый вычет (бонус):</p>
      <p>
        1. Для предоставления налогового вычета в отношении налога, исчисленного по ставке 4%,
        налоговая ставка уменьшается на 1%, таким образом, сумма налога исчисляется по ставке 3%.
        Далее предельная величина налогового вычета (10.000 рублей) уменьшается на разницу между
        суммой налога исчисленной по ставке 4% и суммой налога, исчисленной по ставке 3%.
      </p>
      <p>
        2. Для предоставления налогового вычета в отношении налога, исчисленного по ставке 6%,
        налоговая ставка уменьшается на 2%, таким образом, сумма налога исчисляется по ставке 4%.
        Далее предельная величина налогового вычета (10.000 рублей) уменьшается на разницу между
        суммой налога исчисленной по ставке 6% и суммой налога, исчисленной по ставке 4%.
      </p>
    </div>
  </div>
);

export default HintHowTaxIsCalculated;
