import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import {
  hidePopupAddPartner,
  hidePopupAddPartnerFail,
  hidePopupAddPartnerSuccess,
  hidePopupDeclinePartner,
} from 'modules/partners/actions';
import PopupAddPartner from 'modules/partners/components/PopupAddPartner';
import PopupAddPartnerFail from 'modules/partners/components/PopupAddPartnerFail';
import PopupAddPartnerSuccess from 'modules/partners/components/PopupAddPartnerSuccess';
import PopupDeclinePartner from 'modules/partners/components/PopupDeclinePartner';
import {
  getPartnerList,
  /* clearPartnerList, */
} from 'modules/profileLegasy/actions';
import ProfileSettingsPartnersTabPartners from 'modules/profileLegasy/components/ProfileSettingsPartnersTabPartners/ProfileSettingsPartnersTabPartners';
import ProfileSettingsPartnersTabRequests from 'modules/profileLegasy/components/ProfileSettingsPartnersTabRequests/ProfileSettingsPartnersTabRequests';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { ReactComponent as IconPostEmpty } from 'static/assets/icon-post-empty.svg';

// Styles
import './ProfileSettingsPartnersTab.scss';

const ProfileSettingsPartnersTab = ({
  dispatch,
  isPopupAddPartnerVisible,
  isPopupAddPartnerSuccessVisible,
  isPopupAddPartnerFailVisible,
  isPopupDeclinePartnerVisible,
  isGetPartnerListFetching,
  isGetPartnerListSuccess,
  isGetPartnerListFail,
}) => {
  useEffect(() => {
    dispatch(getPartnerList());
    return () => {
      dispatch(hidePopupAddPartner());
      dispatch(hidePopupAddPartnerSuccess());
      dispatch(hidePopupAddPartnerFail());
      dispatch(hidePopupDeclinePartner());
    };
  }, [dispatch]);

  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <>
      <animated.div
        className="profile-settings-partners-tab"
        style={{
          opacity: spring.opacity,
          transform: spring.y.interpolate(transY),
        }}
      >
        <h2 className="profile-settings-partners-tab__title">Партнеры</h2>
        <div className="profile-settings-partners-tab__container">
          {(isGetPartnerListFetching || isGetPartnerListFail) && (
            <div className="profile-settings-partners-tab__container__table">
              {isGetPartnerListFetching && <CircleLoader />}
              {isGetPartnerListFail && (
                <>
                  <IconPostEmpty />
                  <div className="profile-settings-partners-tab__error">
                    У вас нет партнеров. Они появятся, когда вы примите запрос на подключение от
                    компании.
                  </div>
                </>
              )}
            </div>
          )}
          {isGetPartnerListSuccess && (
            <>
              {/* Список запросов */}
              <ProfileSettingsPartnersTabRequests />
              {/* /Список запросов */}

              {/* Список партнеров */}
              <ProfileSettingsPartnersTabPartners />
              {/* /Список партнеров */}
            </>
          )}
        </div>
      </animated.div>

      {isPopupAddPartnerVisible && <PopupAddPartner />}
      {isPopupAddPartnerSuccessVisible && <PopupAddPartnerSuccess />}
      {isPopupAddPartnerFailVisible && <PopupAddPartnerFail />}
      {isPopupDeclinePartnerVisible && <PopupDeclinePartner />}
    </>
  );
};

ProfileSettingsPartnersTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  isPopupAddPartnerVisible: PropTypes.bool.isRequired,
  isPopupAddPartnerSuccessVisible: PropTypes.bool.isRequired,
  isPopupAddPartnerFailVisible: PropTypes.bool.isRequired,
  isPopupDeclinePartnerVisible: PropTypes.bool.isRequired,
  isGetPartnerListFetching: PropTypes.bool.isRequired,
  isGetPartnerListSuccess: PropTypes.bool.isRequired,
  isGetPartnerListFail: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isPopupAddPartnerVisible: state.partners.layers.popupAddPartnerVisible,
  isPopupAddPartnerSuccessVisible: state.partners.layers.popupAddPartnerSuccessVisible,
  isPopupAddPartnerFailVisible: state.partners.layers.popupAddPartnerFailVisible,
  isPopupDeclinePartnerVisible: state.partners.layers.popupDeclinePartnerVisible,
  isGetPartnerListFetching: state.profile.getPartnerList.fetching,
  isGetPartnerListSuccess: state.profile.getPartnerList.success,
  isGetPartnerListFail: state.profile.getPartnerList.fail,
});

export default connect(mapStateToProps)(ProfileSettingsPartnersTab);
