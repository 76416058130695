import React from 'react';
import { Redirect } from 'react-router-dom';
import { ReactComponent as IconBasket } from 'static/assets/icon-basket.svg';
import './ProfileSettingsWalletClosingButton.scss';
// import { WindowUI } from '../../../../ui';

const ProfileSettingsWalletClosingButton = () => {
  const [isRedirected, setIsRedirected] = React.useState(false);

  function onCloseWalletClick() {
    setIsRedirected(true);
  }

  if (isRedirected) {
    return <Redirect to="/lk/close-wallet" />;
  }

  return (
    <div>
      <div>
        {/* {isRedirected && ( */}
        {/*   <WindowUI.WindowUniversal */}
        {/*     header={'Удаление кошелька'} */}
        {/*     text={'Для удаления кошелька необходимо обратиться в техническую поддержку'} */}
        {/*     buttonText={'Хорошо'} */}
        {/*     onClose={() => { */}
        {/*       setIsRedirected(false); */}
        {/*     }} */}
        {/*   /> */}
        {/* )} */}
      </div>
      <div className="profile-settings-wallet-closing-button">
        <button onClick={onCloseWalletClick}>
          <IconBasket />
          <span>Хочу удалить кошелек</span>
        </button>
      </div>
    </div>
  );
};

export default ProfileSettingsWalletClosingButton;

// import React from 'react';
// import { Redirect } from 'react-router-dom';
// import { ReactComponent as IconBasket } from 'static/assets/icon-basket.svg';
// import './ProfileSettingsWalletClosingButton.scss';
//
// const ProfileSettingsWalletClosingButton = () => {
//   const [isRedirected, setIsRedirected] = React.useState(false);
//
//   function onCloseWalletClick() {
//     setIsRedirected(true);
//   }
//
//   if (isRedirected) {
//     return <Redirect to="/lk/close-wallet" />;
//   }
//
//   return (
//     <div className="profile-settings-wallet-closing-button">
//       <button onClick={onCloseWalletClick}>
//         <IconBasket />
//         <span>Хочу удалить кошелек</span>
//       </button>
//     </div>
//   );
// };
//
// export default ProfileSettingsWalletClosingButton;
