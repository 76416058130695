import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Components
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Misc
import { HINT_TYPES } from 'constants/index';

// Styles
import './NotificationsDropdown.scss';

const NotificationsDropdown = React.forwardRef((props, ref) => {
  const {
    className,
    closeDrop,
    unreadMessages,
    readAllMessagesHandler,
    isNotificationsLoading,
    handleNotificationClick,
    readMessages,
    style,
  } = props;

  return (
    <div
      ref={ref}
      className={cx('notifications-dropdown', {
        [className]: className,
      })}
      style={style}
    >
      {!isNotificationsLoading &&
        unreadMessages &&
        Array.isArray(unreadMessages.messages) &&
        unreadMessages.messages.some((item) => !item.read && !readMessages[item.id]) && (
          <button
            className="notifications-dropdown__mark-all-as-read-btn"
            type="button"
            onClick={readAllMessagesHandler}
          >
            <i className="notifications-dropdown__mark-all-as-read-icon" />
            <span className="notifications-dropdown__mark-all-as-read-text">
              отметить все прочитанными
            </span>
          </button>
        )}

      {!isNotificationsLoading && unreadMessages && Array.isArray(unreadMessages.messages) && (
        <div className="notifications-dropdown__list">
          {unreadMessages.messages.map((item) => (
            <button
              key={item.id}
              type="button"
              className={cx('notifications-dropdown__item', {
                'notifications-dropdown__item_unread': !item.read && !readMessages[item.id],
              })}
              onClick={(e) => handleNotificationClick(e, item)}
            >
              <p className="notifications-dropdown__item-date">{item.dateTimeAdd}</p>
              <p className="notifications-dropdown__item-title">{item.title}</p>

              {/* ЕСТЬ ТИП И ЕСТЬ ОПИСАНИЕ ДЛЯ ТИПА */}
              {item.type && typeof item.type === 'number' && HINT_TYPES[item.type] && (
                <p className="notifications-dropdown__item-type">{HINT_TYPES[item.type].title}</p>
              )}
              {/* /ЕСТЬ ТИП И ЕСТЬ ОПИСАНИЕ ДЛЯ ТИПА */}

              {/* ЕСТЬ ТИП И НЕТ ОПИСАНИЯ ДЛЯ ТИПА */}
              {item.type && typeof item.type === 'number' && !HINT_TYPES[item.type] && (
                <p className="notifications-dropdown__item-type">{HINT_TYPES.default.title}</p>
              )}
              {/* /ЕСТЬ ТИП И НЕТ ОПИСАНИЯ ДЛЯ ТИПА */}
            </button>
          ))}
        </div>
      )}

      {!isNotificationsLoading &&
        !(
          unreadMessages &&
          Array.isArray(unreadMessages.messages) &&
          unreadMessages.messages.length
        ) && (
          <div className="notifications-dropdown__no-messages-wrap">
            <p className="notifications-dropdown__no-messages">Нет новых уведомлений</p>
          </div>
        )}

      {isNotificationsLoading && (
        <div className="notifications-dropdown__loader-wrap">
          <CircleLoader className="notifications-dropdown__loader" />
        </div>
      )}

      {!isNotificationsLoading && (
        <Link
          to="/lk/notifications"
          className="notifications-dropdown__show-more js-have-to-handle"
          onClick={closeDrop}
        >
          посмотреть все
        </Link>
      )}
    </div>
  );
});

NotificationsDropdown.propTypes = {
  className: PropTypes.string.isRequired,
  closeDrop: PropTypes.func.isRequired,
  unreadMessages: PropTypes.shape({
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        dateTimeAdd: PropTypes.string,
        id: PropTypes.number,
        message: PropTypes.string,
        title: PropTypes.string,
        read: PropTypes.bool,
      }),
    ),
  }).isRequired,
  readAllMessagesHandler: PropTypes.func.isRequired,
  isNotificationsLoading: PropTypes.bool,
  handleNotificationClick: PropTypes.func,
  readMessages: PropTypes.shape({}).isRequired,
  style: PropTypes.shape({
    right: PropTypes.number,
  }).isRequired,
};

NotificationsDropdown.defaultProps = {
  isNotificationsLoading: false,
  handleNotificationClick: () => {},
};

export default NotificationsDropdown;
