import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { getPartnerRequestList } from 'modules/profileLegasy/selectors';
import { showPopupAddPartner, showPopupDeclinePartner } from 'modules/partners/actions';

import './ProfileSettingsPartnersTabRequests.scss';

const ProfileSettingsPartnersTabRequests = ({ dispatch, requestList }) => {
  function confirmRequest(requestId) {
    return () => dispatch(showPopupAddPartner(requestId));
  }

  function declineRequest(requestId) {
    return () => dispatch(showPopupDeclinePartner(requestId));
  }

  return (
    <div className="profile-settings-partners-tab__requests">
      {requestList.map((item) => (
        <div key={item.id} className="profile-settings-partners-tab__requests__item">
          <div className="profile-settings-partners-tab__requests__info">
            <div className="profile-settings-partners-tab__requests__status">Новый запрос</div>
            <div className="profile-settings-partners-tab__requests__name">
              {item.partner.fullName}
            </div>
          </div>
          <nav className="profile-settings-partners-tab__requests__nav">
            <button
              className="profile-settings-partners-tab__requests__confirm-button"
              onClick={confirmRequest(item.id)}
            >
              Принять
            </button>
            <button
              className="profile-settings-partners-tab__requests__decline-button"
              onClick={declineRequest(item.id)}
            >
              Отклонить
            </button>
          </nav>
        </div>
      ))}
    </div>
  );
};

ProfileSettingsPartnersTabRequests.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestList: PropTypes.arrayOf(PropTypes.shape({})),
};

const mapStateToProps = (state) => ({
  requestList: getPartnerRequestList()(state),
});

export default connect(mapStateToProps)(ProfileSettingsPartnersTabRequests);
