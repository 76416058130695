import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link } from 'react-router-dom';

// Misc
import { REGISTERED, AUTHORIZED, YES } from 'constants/index.js';
import formatNumber from 'helpers/formatNumber';

// Icon
import { ReactComponent as IconShield } from 'static/assets/icon-shield.svg';
import { ReactComponent as IconUser } from 'static/assets/icon-user.svg';
import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';

// Images
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './UserBalanceCard.scss';

export const UserBalanceCard = ({ user, className }) => {
  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/`;

  return (
    <div
      className={cx('user-balance-card', {
        [className]: className,
      })}
    >
      <div className="user-balance-card__top">
        <div className="user-balance-card__top-main">
          <div className="user-balance-card__avatar-wrap">
            <img
              src={user && user.avatar ? avatarUrl + user.avatar : stdAvatar}
              alt="Автвар юзера"
              className="user-balance-card__avatar"
            />
          </div>

          <div className="user-balance-card__profile-status-wrap">
            {user && user.group && user.group === AUTHORIZED && (
              <IconShield className="user-balance-card__profile-status-icon user-balance-card__profile-status-icon_auth-status" />
            )}
            {user && user.group && user.group === REGISTERED && (
              <IconUser className="user-balance-card__profile-status-icon user-balance-card__profile-status-icon_test" />
            )}

            {user && user.group && user.group === REGISTERED && (
              <p className="user-balance-card__profile-status-text">Неавторизованная</p>
            )}

            {user && user.group && user.group === AUTHORIZED && (
              <p className="user-balance-card__profile-status-text">Авторизованная</p>
            )}
          </div>
        </div>

        {user && user.taxStatus && user.taxStatus.status && user.taxStatus.status === YES && (
          <div className="user-balance-card__status">
            <IconBag className="user-balance-card__status-icon" />
          </div>
        )}
      </div>

      <div className="user-balance-card__balance-wrap">
        <p className="user-balance-card__balance-text">Ваш баланс</p>

        {user &&
          user.clientAccounts &&
          Array.isArray(user.clientAccounts) &&
          user.clientAccounts[0] && (
            <div className="user-balance-card__balance">
              {user.clientAccounts[0].balance !== 0 && (
                <>
                  <span
                    className="user-balance-card__balance-rubles"
                    data-testid="user-balance-card-rubles"
                  >
                    {formatNumber(Math.floor(user.clientAccounts[0].balance / 100))}
                  </span>
                  ,
                  <span
                    className="user-balance-card__balance-penny"
                    data-testid="user-balance-card-penny"
                  >
                    {user.clientAccounts[0].balance.toString().slice(-2)} {'    '}
                  </span>
                </>
              )}
              {user.clientAccounts[0].balance === 0 && (
                <>
                  <span
                    className="user-balance-card__balance-rubles"
                    data-testid="user-balance-card-rubles-zero"
                  >
                    {user.clientAccounts[0].balance}
                  </span>
                  ,
                  <span
                    className="user-balance-card__balance-penny"
                    data-testid="user-balance-card-penny-zero"
                  >
                    00
                  </span>
                </>
              )}
              ₽
            </div>
          )}
      </div>

      <div className="user-balance-card__replenish-link-wrap">
        <Link to="/lk/money-transfer" className="user-balance-card__replenish-link">
          Вывести на карту
        </Link>
      </div>
    </div>
  );
};

UserBalanceCard.propTypes = {
  user: PropTypes.shape({
    taxStatus: PropTypes.shape({
      status: PropTypes.string,
    }),
    group: PropTypes.string,
    avatar: PropTypes.string,
    clientAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
  }),
  className: PropTypes.string,
};

UserBalanceCard.defaultProps = {
  user: null,
  className: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(UserBalanceCard);
