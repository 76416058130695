/* eslint-disable import/prefer-default-export */
import { PropTypes } from 'prop-types';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import { CircleLoader } from 'ui';

import { getOptionsLimit } from '../../models/identifications/selectors';
import { ACCOUNTS_TYPES } from '../../utils/constants';
import { PTAccountType } from '../../utils/propTypes';
import { WidgetWraper } from '../ui';
import { AccountInfo, CardsOfGetAccount } from './ui';
import { DATA_CATDS_OBJ } from './utils';

export const AccountTypeDetailsContsiner = ({
  filterGroup,
  urlToGetIdentificationOfSelfWorkPage,
  urlToGetIdentificationOfSvyaznoyPage,
  urlToGetIdentificationOfLetterPage,
}) => {
  const optionsLimit = useSelector((state) => getOptionsLimit(state, filterGroup), shallowEqual);

  return (
    <WidgetWraper
      title={`Способы ${
        filterGroup === ACCOUNTS_TYPES.IDENTIFIED
          ? 'идентификации'
          : filterGroup === ACCOUNTS_TYPES.EDO
          ? 'получения доступа к ЭДО'
          : 'авторизации'
      }`}
    >
      <AccountInfo groupType={filterGroup} optionsLimit={optionsLimit} />
      {Array.isArray(DATA_CATDS_OBJ[filterGroup]) ? (
        <CardsOfGetAccount
          dataCards={DATA_CATDS_OBJ[filterGroup]}
          urlToGetIdentificationOfSelfWorkPage={urlToGetIdentificationOfSelfWorkPage}
          urlToGetIdentificationOfSvyaznoyPage={urlToGetIdentificationOfSvyaznoyPage}
          urlToGetIdentificationOfLetterPage={urlToGetIdentificationOfLetterPage}
        />
      ) : (
        <CircleLoader />
      )}
    </WidgetWraper>
  );
};

AccountTypeDetailsContsiner.propTypes = {
  filterGroup: PTAccountType.isRequired,
  urlToGetIdentificationOfSelfWorkPage: PropTypes.string,
  urlToGetIdentificationOfSvyaznoyPage: PropTypes.string,
  urlToGetIdentificationOfLetterPage: PropTypes.string,
};

AccountTypeDetailsContsiner.defaultProps = {
  urlToGetIdentificationOfSelfWorkPage: null,
  urlToGetIdentificationOfSvyaznoyPage: null,
  urlToGetIdentificationOfLetterPage: null,
};
