/* eslint-disable import/prefer-default-export */
import React from 'react';
import { SIZE } from 'tools/constants';

import {
  ItemListMarkerOfBlog,
  LinkOfBlog,
  ListOfBlog,
  ParagraphTextOfBlog,
  SupportLinkOfBlog,
  TitleTextOfBlog,
  WidgetWraper,
} from '../ui';

export const GetIdentificationOfSelfWorkBlogContsiner = () => (
  <WidgetWraper title="Идентификация через офис Самозанятые.РФ">
    <ParagraphTextOfBlog>
      <b>Кому подходит:</b> жителям Москвы и Московской области.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      <b>Стоимость:</b> бесплатно.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      <b>Длительность:</b> сотрудник в течение 10-15 минут идентифицирует ваш аккаунт. В отдельных
      случаях проверка может занять два рабочих дня.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      <b>Место:</b> в московском офисе Самозанятые.рф по адресу БЦ «Гранд Сетунь Плаза», ул.
      Горбунова, д. 2 стр. 3, офис № 311
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      <b>Что делать:</b> оставьте заявку по{' '}
      <LinkOfBlog type="link" url="/">
        ссылке
      </LinkOfBlog>
      . В течение часа с вами свяжется менеджер, чтобы подтвердить встречу.
    </ParagraphTextOfBlog>

    <TitleTextOfBlog size={SIZE.sm}>Необходимые документы:</TitleTextOfBlog>
    <ListOfBlog>
      <ItemListMarkerOfBlog>
        <ParagraphTextOfBlog>
          для граждан РФ — паспорт, водительское удостоверение, паспорт моряка или военный билет.
        </ParagraphTextOfBlog>
      </ItemListMarkerOfBlog>
      <ItemListMarkerOfBlog>
        <ParagraphTextOfBlog>
          иностранным гражданам — паспорт с нотариально заверенным переводом, загранпаспорт с визой,
          если вы въехали в РФ по визе, вид на жительство или разрешение на временное проживание в
          РФ, миграционная карта.
        </ParagraphTextOfBlog>
      </ItemListMarkerOfBlog>
    </ListOfBlog>

    <ParagraphTextOfBlog>
      После встречи в офисе, вам придет уведомление о том, что аккаунт идентифицирован.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Нажмите на «Подтвердите, что все верно». Если все данные введены корректно, завершите процесс
      идентификации.
    </ParagraphTextOfBlog>

    <ParagraphTextOfBlog>
      Если вы нашли ошибку — свяжитесь со <SupportLinkOfBlog text={'службой поддержки'} />.
    </ParagraphTextOfBlog>
  </WidgetWraper>
);
