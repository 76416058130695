import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';

import { getUser, setMenuOpen } from 'modules/authLegasy/actions';
import Check from 'modules/profileLegasy/components/Check/Check';
import TransferResult from 'modules/profileLegasy/components/TransferResult/TransferResult';
import BlockedUserText from 'modules/shared/components/BlockedUserText/BlockedUserText';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CircleWithEmoji from 'modules/shared/components/CircleWithEmoji/CircleWithEmoji';
import PayedInvoiceText from 'modules/shared/components/PayedInvoiceText/PayedInvoiceText';
import PaymentBox from 'modules/shared/components/PaymentBox/PaymentBox';
import TransferResultErrorMessage from 'modules/shared/components/TransferResultErrorMessage/TransferResultErrorMessage';

import downloadFile from 'helpers/downloadFile';
import formatNumber from 'helpers/formatNumber';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import emojiPuzzled from 'static/assets/icon-emoji-puzzled.png';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconMasterCard } from 'static/assets/icon-mastercard.svg';
import { ReactComponent as IconMir } from 'static/assets/icon-mir.svg';
import { ReactComponent as IconPci } from 'static/assets/icon-pci.svg';
import { ReactComponent as IconVisa } from 'static/assets/icon-visa-verified.svg';
import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';
import { ReactComponent as IconLogoSmall } from 'static/assets/logo-small-white.svg';
import profileBg from 'static/assets/profile-bg.png';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './InvoicePaymentRequestLink.scss';

import { getInvoiceTransactionOperationStatus } from '../../actions';
import { WindowUI } from '../../../../ui';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';

const InvoicePaymentRequestLink = ({
  user,
  dispatch,
  isMenuOpen,
  className,
  opData,
  checkId,
  checkData,
  isMoneyTransferOk,
  isMoneyTransferFailed,
  isRegisteredCheckFailed,
  isCheckSuccessPage,
  isSending,
  userAvatar,
  isCheckLoading,
  isUserBlocked,
  isReplenishmentBlocked,
  isPayed,
  isNotAvailable,
}) => {
  const windowSize = useWindowSize();
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isIframeSBPLoading, setIsIframeSBPLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [showIframe, setShowIframe] = useState(false);
  const [showIframeSBP, setShowIframeSBP] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [redirectToErrorPage, setRedirectToErrorPage] = useState(false);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(null);

  const [isOpenPopupIsNotAvailable, setOpenPopupIsNotAvailable] = useState(false);

  useEffect(() => {
    if (window && window.sessionStorage) {
      const localErrorMessage =
        window && window.sessionStorage
          ? window.sessionStorage.getItem('transactionStateMessage')
          : null;
      if (localErrorMessage) {
        setErrorMessage(localErrorMessage);
        window.sessionStorage.removeItem('transactionStateMessage');
      }
    }
  }, []);

  const onPayByCard = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    setShowIframe(true);
    setIsIframeLoading(true);
  };

  const onPayBySBP = () => {
    if (isNotAvailable) {
      setOpenPopupIsNotAvailable(true);
      return;
    }
    setShowIframeSBP(true);
    setIsIframeSBPLoading(true);
  };

  const resetPay = () => {
    setShowIframe(false);
    setIsIframeLoading(false);
    setShowIframeSBP(false);
    setIsIframeSBPLoading(false);
  };

  const updateTimeout = useRef(null);
  const updateOperationStatus = useCallback(
    (operationId) => {
      if (!operationId) {
        clearTimeout(updateTimeout.current);
        return;
      }

      dispatch(getInvoiceTransactionOperationStatus(operationId))
        .then((res) => {
          if (res && res.data) {
            if (res.data.state === STATUS_WAIT) {
              updateTimeout.current = setTimeout(() => updateOperationStatus(operationId), 1000);
            } else if (res.data.state === STATUS_ERROR) {
              setRedirectToErrorPage(true);
            } else {
              const url = `/pay-it/invoice/${operationId}/wait`;
              setRedirectToSuccessPage(url);
              dispatch(getUser()).catch(() => {});
            }

            if (res.data.transactionStateMessage) {
              window.sessionStorage.setItem(
                'transactionStateMessage',
                res.data.transactionStateMessage,
              );
            }
          }
        })
        .catch(() => {
          setRedirectToErrorPage(true);
        });
    },
    [dispatch],
  );

  useEffect(() => {
    const stateListener = (e) => {
      if (!process.env.REACT_APP_PCI_DSS_IFRAME.includes(e.origin)) return;
      if (e.data.operationId) {
        clearTimeout(updateTimeout?.current);
        updateOperationStatus(e.data.operationId);
      } else if (e.data.linkUrl) {
        window.open(e.data.linkUrl, e.data.target || '_blank');
      }
    };
    if (showIframeSBP) {
      window.addEventListener('message', stateListener);
    }
    return () => {
      window.removeEventListener('message', stateListener);
      clearTimeout(updateTimeout?.current);
    };
  }, [showIframeSBP, updateOperationStatus, dispatch]);

  /** Устанавливаем isMenuOpen при клике на бургер */
  const handleMenuOpenClick = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  /** Скачиваем картинку чека */
  const downloadImageCheck = (downloadUrl, fileName) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', downloadUrl, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        const file = new Blob([xhr.response], { type: 'image/jpeg' });
        downloadFile(file, `${fileName}.jpg`);
      }
    };

    xhr.send();
  };

  if (redirectToErrorPage) {
    return <Redirect to="/pay-it/invoice/error" />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  return (
    <>
      {isOpenPopupIsNotAvailable && isNotAvailable && (
        <WindowUI.WindowUniversalError
          header={'Ошибка оплаты'}
          text={'Оплата невозможна из-за превышения лимитов у получателя.'}
          buttonText={'Хорошо'}
          onClose={() => {
            setOpenPopupIsNotAvailable(false);
          }}
        />
      )}

      <div
        className={cx('invoice-payment-request-link', {
          [className]: className,
        })}
      >
        {/* ХЭДЕР */}
        <div
          className={cx('invoice-payment-request-link__header', {
            'invoice-payment-request-link__header_menu-open': isMenuOpen,
          })}
        >
          {/* НАВИГАЦИЯ С ЛОКАЛЬНЫМ МЕНЮ БУРГЕРОМ ДЛЯ ШИРИНЫ ЭКРАНА < 980 */}
          {windowSize.innerWidth <= 980 && (
            <div className={cx('invoice-payment-request-link__top-nav')}>
              {user ? (
                <>
                  {showIframe || showIframeSBP ? (
                    <div className="invoice-payment-request-link__back-button" onClick={resetPay}>
                      <IconArrow
                        className={cx('invoice-payment-request-link__back-button-icon', {
                          'invoice-payment-request-link__back-button-icon_menu-open': isMenuOpen,
                        })}
                      />
                    </div>
                  ) : (
                    <Link className="invoice-payment-request-link__back-button" to="/">
                      <IconArrow
                        className={cx('invoice-payment-request-link__back-button-icon', {
                          'invoice-payment-request-link__back-button-icon_menu-open': isMenuOpen,
                        })}
                      />
                    </Link>
                  )}
                </>
              ) : (
                <>
                  {showIframe || showIframeSBP ? (
                    <div className="invoice-payment-request-link__back-button" onClick={resetPay}>
                      <IconArrow
                        className={cx('invoice-payment-request-link__back-button-icon', {
                          'invoice-payment-request-link__back-button-icon_menu-open': isMenuOpen,
                        })}
                      />
                    </div>
                  ) : (
                    <Link className="invoice-payment-request-link__whiteLogoWrap" to="/">
                      <IconLogoSmall />
                    </Link>
                  )}
                </>
              )}

              <div className="invoice-payment-request-link__top-nav-right">
                <button
                  className={cx('invoice-payment-request-link__burger', {
                    'invoice-payment-request-link__burger_menu-open': isMenuOpen,
                  })}
                  type="button"
                  onClick={handleMenuOpenClick}
                >
                  <IconLine1 className="invoice-payment-request-link__burger-span" />
                  <IconLine2 className="invoice-payment-request-link__burger-span" />
                  <IconLine3 className="invoice-payment-request-link__burger-span" />
                </button>
              </div>
            </div>
          )}
          {/* /НАВИГАЦИЯ С ЛОКАЛЬНЫМ МЕНЮ БУРГЕРОМ ДЛЯ ШИРИНЫ ЭКРАНА < 980 */}

          {windowSize.innerWidth > 980 && (showIframe || showIframeSBP) && (
            <div className={cx('invoice-payment-request-link__top-nav')}>
              <div className="invoice-payment-request-link__back-button" onClick={resetPay}>
                <IconArrow
                  className={cx('invoice-payment-request-link__back-button-icon', {
                    'invoice-payment-request-link__back-button-icon_menu-open': false,
                  })}
                />{' '}
                <span className="invoice-payment-request-link__back-button-text">Назад</span>
              </div>
            </div>
          )}

          {!isMenuOpen && (
            <img
              src={profileBg}
              alt="Фирменный бэкгранунд Самозаняты.рф"
              className="invoice-payment-request-link__header-bg"
            />
          )}
        </div>
        {/* /ХЭДЕР */}

        {/* КОНТЕНТ */}
        <div className="invoice-payment-request-link__content">
          {/* АВАТАР */}
          {(isUserBlocked || isReplenishmentBlocked) && (
            <CircleWithEmoji
              emoji={emojiSad}
              className="invoice-payment-request-link__avatar-wrap invoice-payment-request-link__avatar-invoice-payment-request-link-to-pay"
            />
          )}

          {isPayed && (
            <CircleWithEmoji
              emoji={emojiPuzzled}
              className="invoice-payment-request-link__avatar-wrap invoice-payment-request-link__avatar-invoice-payment-request-link-to-pay"
            />
          )}

          {!isUserBlocked && !isPayed && !isReplenishmentBlocked && (
            <div className="invoice-payment-request-link__avatar-wrap invoice-payment-request-link__avatar-invoice-payment-request-link-to-pay">
              <img
                src={userAvatar ? userAvatar : stdAvatar}
                alt="Аватар юзера"
                className="invoice-payment-request-link__avatar"
              />
            </div>
          )}

          {/* /АВАТАР */}

          {/* ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
          {!isSending &&
            !isMoneyTransferOk &&
            !isMoneyTransferFailed &&
            !isRegisteredCheckFailed &&
            !isUserBlocked &&
            !isReplenishmentBlocked &&
            !isPayed && (
              <>
                <p className="invoice-payment-request-link__content-text">
                  {`Перевод пользователю *${opData.loginLastChars}`}
                </p>

                {showIframe && (
                  <div className="invoice-payment-request-link__iframe-wrap">
                    {isIframeLoading && (
                      <div className="invoice-payment-request-link__iframe-loader">
                        <CircleLoader />
                      </div>
                    )}
                    <iframe
                      title="InvoicePaymentRequest"
                      className="invoice-payment-request-link__iframe"
                      src={`${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/pay-it-invoice?account=${
                        user ? user.login : 'anonymous'
                      }&payItUID=${checkId}&email=${email}&amount=${opData.sum}`}
                      frameBorder="0"
                      onLoad={() => {
                        setIsIframeLoading(false);
                      }}
                    />
                  </div>
                )}

                {/* IFRAME PSI DSS SBP*/}
                {showIframeSBP && (
                  <div className="invoice-payment-request-link__iframe-wrap-sbp">
                    {isIframeSBPLoading && (
                      <div className="invoice-payment-request-link__iframe-loader">
                        <CircleLoader />
                      </div>
                    )}
                    <iframe
                      title="InvoicePaymentRequestSBP"
                      className="invoice-payment-request-link__iframe"
                      src={`${
                        process.env.REACT_APP_PCI_DSS_IFRAME
                      }iframe/pay-it-invoice-sbp?account=${
                        user ? user.login : 'anonymous'
                      }&payItUID=${checkId}&email=${email}&amount=${opData.sum}`}
                      frameBorder="0"
                      onLoad={() => {
                        setIsIframeSBPLoading(false);
                      }}
                    />
                  </div>
                )}
                {/* /IFRAME PSI DSS SBP*/}

                {/* ИНФОРМАЦИЯ О ЦЕЛЯХ ПЛАТЕЖА */}
                {!showIframe && !showIframeSBP && (
                  <>
                    <div className="invoice-payment-request-link__info">
                      <p className="invoice-payment-request-link__info-title">
                        {'Перевод за оказанные услуги или продажу товара:'}
                      </p>

                      <div className="invoice-payment-request-link__info-purpose">
                        {opData.commentary}
                      </div>

                      <div className="invoice-payment-request-link__email">
                        <div className="invoice-payment-request-link__email-input-holder">
                          <input
                            type="text"
                            className="invoice-payment-request-link__email-input"
                            placeholder="E-mail"
                            autoComplete="email"
                            inputMode="email"
                            onChange={({ target }) => setEmail(target.value)}
                            value={email}
                          />
                        </div>

                        <div className="invoice-payment-request-link__email-desc"></div>
                      </div>

                      <div className="invoice-payment-request-link__info-sum">
                        {formatNumber(opData.sum / 100)} ₽
                      </div>
                    </div>

                    <div className="invoice-payment-request-link__btn-wrap">
                      <PaymentBox
                        isOneColumn
                        amount={opData.sum / 100 || 0}
                        isDisabled={!opData.sum}
                        onPayByCard={onPayByCard}
                        onPayBySBP={onPayBySBP}
                        hideApplePay
                        hideSamsungPay
                      />
                    </div>
                  </>
                )}

                {/* ССЫЛКА НА ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ПРИ ЭКРАНЕ > 980 */}
                {windowSize.innerWidth > 980 && (
                  <div className="invoice-payment-request-link__attention-text">
                    Обратите внимание: Вы производите оплату услуги или товара пользователю,
                    имеющему официальный статус самозанятого (НПД) в ФНС. После оплаты, вы
                    автоматически получите чек от пользователя
                  </div>
                )}
                {/* /ССЫЛКА НА ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ПРИ ЭКРАНЕ > 980 */}
              </>
            )}
          {/* /ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}

          {isUserBlocked && <BlockedUserText />}
          {isReplenishmentBlocked && (
            <BlockedUserText
              title={'Пополнение кошелька заблокировано'}
              desc={'Пополнение кошелька заблокировано и нельзя совершить платеж.'}
            />
          )}

          {isPayed && <PayedInvoiceText />}

          {/* ЛОАДЕР ИЛИ РЕЗУЛЬТАТ */}
          {(isSending || isMoneyTransferOk || isMoneyTransferFailed || isRegisteredCheckFailed) && (
            <TransferResult
              isMoneyTransferOk={
                (isMoneyTransferOk && !isCheckLoading && !isCheckSuccessPage) ||
                isRegisteredCheckFailed
              }
              isMoneyTransferFailed={isMoneyTransferFailed}
              isLoading={isSending || isCheckLoading}
              className="transfer-result_has-invoice-inside"
              linkToPayAgainPage={checkId ? `/invoice/${checkId}` : null}
              textForBayAgainButton={checkId && 'Вернуться к счету'}
            >
              {/* ЛОАДЕР */}
              {isSending && (
                <div className="transfer-result__text">
                  Платеж обрабатывается. Пожалуйста подождите, это может занять некоторое время.
                </div>
              )}
              {/* /ЛОАДЕР */}

              {/* ЛОАДЕР ДЛЯ ЧЕКА */}
              {isCheckLoading && (
                <div className="transfer-result__text">Пожалуйста подождите, получаем чек...</div>
              )}
              {/* /ЛОАДЕР ДЛЯ ЧЕКА */}

              {/* ОПЛАТА ПРОШЛА УСПЕШНО */}
              {((isMoneyTransferOk && !isCheckLoading) || isRegisteredCheckFailed) && (
                <>
                  <div className="transfer-result__text">
                    Оплата прошла успешно.
                    {checkData
                      ? ' Пользователь создал для вас чек. Теперь вы можете скачать его.'
                      : ' К сожалению, не удалось создать чек для операции.'}
                  </div>

                  {checkData && (
                    <>
                      <Check className="invoice-payment-request-link__bill" checkData={checkData} />

                      <div className="invoice-payment-request-link__btn-wrap">
                        <button
                          onClick={() => downloadImageCheck(checkData.link, checkData.id)}
                          type="button"
                          className="invoice-payment-request-link__btn"
                        >
                          Скачать чек
                        </button>
                      </div>
                    </>
                  )}
                </>
              )}
              {/* /ОПЛАТА ПРОШЛА УСПЕШНО */}

              {/* ОПЛАТА НЕ ПРОШЛА */}
              {isMoneyTransferFailed && <TransferResultErrorMessage errorMessage={errorMessage} />}
              {/* /ОПЛАТА НЕ ПРОШЛА */}
            </TransferResult>
          )}
          {/* /ЛОАДЕР ИЛИ РЕЗУЛЬТАТ */}
        </div>
        {/* /КОНТЕНТ */}
      </div>
      {/* /МЕНЮ ПРИ ШИРИНЕ ЭКРАНА < 980 */}

      {/* ИКОНКИ СОПСОБОВ ОПЛАТЫ И ССЫЛКИ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
      {!isMoneyTransferOk &&
        !isMoneyTransferFailed &&
        !isSending &&
        !isUserBlocked &&
        !isReplenishmentBlocked &&
        !showIframeSBP && (
          <>
            <div className="money-request-container__payment-ways">
              <IconVisa className="money-request-container__payment-ways-item money-request-container__payment-ways-item_visa" />
              <IconMasterCard className="money-request-container__payment-ways-item money-request-container__payment-ways-item_msc" />
              <IconMir className="money-request-container__payment-ways-item money-request-container__payment-ways-item_mir" />
              <IconPci className="money-request-container__payment-ways-item bank-card-payment__payment-ways-item_pci" />
            </div>

            {windowSize.innerWidth <= 980 && !isPayed && (
              <div className="invoice-payment-request-link__attention-text">
                Обратите внимание: Вы производите оплату услуги или товара пользователю, имеющему
                официальный статус самозанятого (НПД) в ФНС. После оплаты, вы автоматически получите
                чек от пользователя
              </div>
            )}

            {!isPayed && (
              <div className="money-request-container__info">
                Совершая платеж, вы соглашаетесь с{' '}
                <a
                  href="https://самозанятые.рф/legal/walletoffer.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="money-request-container__info-link"
                >
                  публичной офертой
                </a>
                ,{' '}
                <a
                  href="https://самозанятые.рф/legal/walletterms.pdf"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="money-request-container__info-link"
                >
                  пользовательским соглашением
                </a>
                <span> и </span>
                <a
                  href={`${process.env.REACT_APP_URL}privacy-policy`}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="money-request-container__info-link"
                >
                  политикой конфиденциальности
                </a>
              </div>
            )}
          </>
        )}
      {/* /ИКОНКИ СОПСОБОВ ОПЛАТЫ И ССЫЛКИ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
    </>
  );
};

InvoicePaymentRequestLink.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    login: PropTypes.string,
  }),
  isMenuOpen: PropTypes.bool.isRequired,
  userAvatar: PropTypes.string,
  opData: PropTypes.shape({
    avatarInBase64: PropTypes.string,
    commentary: PropTypes.string,
    currency: PropTypes.string,
    loginLastChars: PropTypes.string,
    sum: PropTypes.number,
  }),
  checkId: PropTypes.string,
  checkData: PropTypes.shape({
    link: PropTypes.string,
    id: PropTypes.string,
  }),
  isMoneyTransferOk: PropTypes.bool,
  isMoneyTransferFailed: PropTypes.bool,
  isRegisteredCheckFailed: PropTypes.bool,
  isCheckSuccessPage: PropTypes.bool,
  isSending: PropTypes.bool,
  isCheckLoading: PropTypes.bool,
  isUserBlocked: PropTypes.bool,
  isReplenishmentBlocked: PropTypes.bool,
  isPayed: PropTypes.bool,
  isNotAvailable: PropTypes.bool,
};

InvoicePaymentRequestLink.defaultProps = {
  className: null,
  user: null,
  opData: null,
  isMoneyTransferOk: false,
  isMoneyTransferFailed: false,
  isRegisteredCheckFailed: false,
  isCheckSuccessPage: false,
  isSending: false,
  isCheckLoading: false,
  userAvatar: null,
  checkId: null,
  checkData: null,
  isUserBlocked: false,
  isReplenishmentBlocked: false,
  isPayed: false,
  isNotAvailable: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(InvoicePaymentRequestLink);
