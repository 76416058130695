import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import styled, { css } from 'styled-components';
import { PTClassNames } from 'tools/types/propTypes';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';

import { CircleLoader, LineText } from '../';
import { useState } from 'react';

export const MaskInput = ({
  onFinish,
  placeholder,
  maskPlaceholder,
  isError,
  isLoading,
  isSuccess,
  required,
  disabled,
  className,
}) => {
  const [isFocus, setIsFocus] = useState(null);
  const [value, setValue] = useState(null);

  const onCurrentChange = (event) => {
    setValue(event.target.value);
    if (event.target.value.length === 4) {
      onFinish(event.target.value);
    }
  };

  return (
    <WraperInputStyled className={className}>
      {placeholder && (
        <PlaceholderStyled isValue={!!value} isFocus={isFocus} isError={isError}>
          {placeholder}
        </PlaceholderStyled>
      )}
      <InputStyled
        onInput={onCurrentChange}
        isValue={!!value}
        isError={isError}
        isSuccess={isSuccess}
        isFocus={isFocus}
        onFocus={() => setIsFocus(true)}
        onBlur={() => setIsFocus(false)}
        maskplaceholder={maskPlaceholder}
        required={required}
        disabled={disabled || isLoading}
      />
      {isLoading && <CircleLoaderStyled />}
    </WraperInputStyled>
  );
};

MaskInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  onFinish: PropTypes.func,
  placeholder: PropTypes.string,
  mask: PropTypes.string,
  maskPlaceholder: PropTypes.string,
  isError: PropTypes.bool,
  isLoading: PropTypes.bool,
  isSuccess: PropTypes.bool,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  className: PTClassNames,
};

MaskInput.defaultProps = {
  onFinish: (f) => f,
  placeholder: null,
  mask: null,
  maskPlaceholder: '_',
  isError: null,
  isLoading: null,
  isSuccess: null,
  required: false,
  disabled: false,
  className: '',
};

const WraperInputStyled = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  position: relative;
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 8px;
`;

const InputStyled = styled(({ isValue, isError, isSuccess, isFocus, ...props }) => (
  <InputMask {...props} />
))`
  ${getFont(WEIGHT['rubik-regular'])}
  position: relative;
  height: 60px;
  border: none;
  background: none;
  border-radius: 8px;
  width: 100%;
  color: #1d2e69;
  padding: 18px 0 18px 20px;
  font-size: 16px;
  line-height: 24px;
  box-sizing: border-box;
  box-shadow: 0 0 0 0 #e8ecf2;
  border: 1px var(--dark-slate-blue) solid;
  border-radius: 8px;
  transition: box-shadow 0.3s, border-width 0.3s, border-color 0.3s;
  &:focus,
  &:active {
    box-shadow: 0 4px 27px #e8ecf2;
    border: 1px #1d2e6900 solid;
  }
  ${({ isValue, isFocus }) =>
    (isValue || isFocus) &&
    css`
      padding: 26px 0 10px 20px;
    `}
  ${({ isError }) =>
    isError &&
    css`
      border: solid 1px var(--reddish-pink);
    `}
`;

const PlaceholderStyled = styled(LineText)`
  ${getFont(WEIGHT['rubik-regular'])}
  color: #616A88;
  position: absolute;
  left: 20px;
  top: 22px;
  transition: font-size 0.3s, line-height 0.3s, transform 0.3s;
  width: calc(100% - 20px);
  font-size: 16px;
  ${({ isValue, isFocus }) =>
    (isValue || isFocus) &&
    css`
      font-size: 12px;
      line-height: 16px;
      transform: translateY(-16px);
    `};
  ${({ isError }) =>
    isError &&
    css`
      color: var(--reddish-pink);
    `}
`;

const CircleLoaderStyled = styled(CircleLoader)`
  height: 16px;
  width: 16px;
  position: absolute;
  right: 18px;
`;
