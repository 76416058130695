import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from '@rehooks/window-size';
import { Link } from 'react-router-dom';

import WarningText from 'modules/profileLegasy/components/Warning/WarningText';
import HintNotRegisteredInFNS from 'modules/authLegasy/components/Hint/HintNotRegisteredInFNS';
import Balloon from 'modules/shared/components/Balloon/Balloon';
import GetSzInnInput from 'modules/shared/components/GetSzInnInput/GetSzInnInput';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SESteps from 'modules/profileLegasy/components/SESteps/SESteps';

import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import { setHintOpen } from 'modules/authLegasy/actions';

import { CONSISTENT_ANIMATIONS_DELAY, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';
import profileBg from 'static/assets/profile-bg.png';
import iconForce from 'static/assets/icon-emoji-force.png';

const FAIL = 'FAIL';

export const SEFromLKNoSz = ({ className, userINN, onChangeINN, dispatch }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  const hints = [
    {
      id: 1,
      title: 'Как получить статус самозанятого?',
      titleForPopup: 'Как получить статус самозанятого?',
      action: toggleDrop,
      openCondition: isDropOpen,
      popup: <HintNotRegisteredInFNS />,
      dropEl: dropEl,
    },
    {
      id: 2,
      title: 'Если ошибка не проходит, напишите нам',
      isHelpLink: true,
      dropEl,
    },
  ];

  return (
    <div
      className={cx('se-from-lk', {
        [className]: className,
      })}
      data-testid="se-from-lk-no-sz"
    >
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="se-from-lk__header">
          <div className="se-from-lk__header-container container container_small">
            <div className="se-from-lk__header-content">
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <SESteps currentStep={2} totalSteps={3} className="se-from-lk__header-step" />
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <h1 className="se-from-lk__header-title">
                  Вы не состоите на учете в ФНС как самозанятый
                </h1>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="se-from-lk__header-desc">
                  <IconInfo className="se-from-lk__header-desc-warning-icon" />
                  Вы не являетесь налогоплательщиком НПД. Пройдите регистрацию в приложении
                  &laquo;Мой&nbsp;налог&raquo; или на сайте ФНС
                </div>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                unmountOnExit
              >
                <div className="se-from-lk__form">
                  <GetSzInnInput
                    value={userINN}
                    status={FAIL}
                    onChangeInn={onChangeINN}
                    disabled
                    readOnly
                    className="se-from-lk__form-input-holder"
                    isEditButton
                  />
                </div>
              </CSSTransition>

              {windowSize.innerWidth > 980 && (
                <Balloon className="se-from-lk__info">
                  <div className="balloon__text">
                    Станьте самозанятым, и вы сможете пользоваться всеми разделами сервиса
                  </div>

                  <img
                    src={iconForce}
                    alt=""
                    className="balloon__icon se-from-lk__info-icon_wink"
                    data-testid="balloon-emoji"
                  />
                </Balloon>
              )}
            </div>
          </div>
          <img src={profileBg} alt="Profile bg" className="se-from-lk__header-bg" />

          <Link to="/lk" className="se-from-lk__header-close" type="button">
            {windowSize.innerWidth > 980 ? (
              <IconClose className="partial-status__header-close-icon" />
            ) : (
              <IconArrow className="se-from-lk__header-close-icon" />
            )}
          </Link>

          {windowSize.innerWidth <= 980 && (
            <SESteps currentStep={2} totalSteps={3} className="se-from-lk__header-step" />
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-from-bottom-to-top"
        in={isRendered && windowSize.innerWidth <= 980}
        timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
        unmountOnExit
      >
        <WarningText
          text="Вы не являетесь налогоплательщиком НПД. Пройдите регистрацию в приложении
                  &laquo;Мой&nbsp;налог&raquo; или на сайте ФНС"
          className="warning_static"
        />
      </CSSTransition>

      <div className="se-from-lk__content container container_small">
        <div className="se-from-lk__form">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <GetSzInnInput
              value={userINN}
              status={FAIL}
              onChangeInn={onChangeINN}
              disabled
              readOnly
              className="se-from-lk__form-input-holder"
              isEditButton
              isLockVisible={false}
            />
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
          unmountOnExit
        >
          <HintsList
            hints={hints}
            closeHintHandler={closeExplicitly}
            className="se-from-lk__hint-block"
          />
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
          unmountOnExit
        >
          <StandardButton
            title="Продолжить как физ.лицо"
            isLink
            href="/lk"
            bgType={GREY_BORDER}
            className="se-from-lk__btn-wrap js-have-to-handle"
          />
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
          unmountOnExit
        >
          <StandardButton
            title="Написать в поддержку"
            isLink
            href="/"
            bgType={GREY_BORDER}
            className="se-from-lk__btn-wrap se-from-lk__btn-wrap_help js-help"
          />
        </CSSTransition>
      </div>
    </div>
  );
};

SEFromLKNoSz.propTypes = {
  className: PropTypes.string.isRequired,
  userINN: PropTypes.string.isRequired,
  onChangeINN: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  userINN: state.auth.INN,
});

export default connect(mapStateToProps)(SEFromLKNoSz);
