import React from 'react';
import PropTypes from 'prop-types';
import { useParams, Redirect, Link } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';

import Card from '../Card/Card';
import PaymentSummary from 'modules/profileLegasy/components/PaymentSummary/PaymentSummary';
import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import pennyToRubles from '../../helpers/pennyToRubles';
import formatNumber from 'helpers/formatNumber';

import {
  getCommissionByMoneyTransferMethod,
  getTotalSumByMoneyTransferMethod,
} from 'modules/money-transfer/selectors';

import { resetMoneyTransferSteps } from 'modules/money-transfer/actions';

import './StatusSuccessCard.scss';

const StatusSuccessCard = ({ dispatch, cardOrWalletNumber, sum, sbpBank }) => {
  const [canRedirectToLk, setCanRedirectToLk] = React.useState(false);

  const { paymentMethod } = useParams();
  // используются хуки зместо connect, т.к. с помощью connect нет доступа к параметру paymentMethod
  const totalSum = pennyToRubles(useSelector(getTotalSumByMoneyTransferMethod(paymentMethod)));
  const commissionFee = pennyToRubles(
    useSelector(getCommissionByMoneyTransferMethod(paymentMethod)),
  );

  const summaryFields = [
    {
      source: cardOrWalletNumber,
      title:
        paymentMethod === 'bankcard'
          ? 'Номер карты получателя'
          : paymentMethod === 'sbp'
          ? 'Номер телефона'
          : paymentMethod === 'umoney'
          ? 'Номер кошелька ЮMoney'
          : paymentMethod === 'webmoney'
          ? 'Номер кошелька Webmoney'
          : paymentMethod === 'qiwi'
          ? 'Номер кошелька QIWI'
          : 'Номер кошелька',
      value:
        paymentMethod === 'bankcard'
          ? `${cardOrWalletNumber.slice(0, 4)} ${cardOrWalletNumber.slice(
              4,
              6,
            )}** **** ${cardOrWalletNumber.slice(12, 16)}`
          : cardOrWalletNumber,
    },
    {
      source: sum,
      title: 'Сумма',
      value: `${formatNumber(Number(sum).toFixed(2))} ₽`,
    },
    {
      source: commissionFee,
      title: 'Комиссия',
      value: commissionFee ? `${formatNumber(Number(commissionFee).toFixed(2))} ₽` : '0 ₽',
    },
    {
      source: totalSum,
      title: 'Списано',
      value: `${formatNumber(totalSum.toFixed(2))} ₽`,
    },
  ];

  if (paymentMethod === 'sbp') {
    summaryFields.push({
      source: sbpBank,
      title: 'Банк',
      value: sbpBank.name,
    });
  }

  function redirectToLk() {
    setCanRedirectToLk(true);
  }

  function retry() {
    dispatch(resetMoneyTransferSteps());
  }

  if (canRedirectToLk) {
    return <Redirect to="/lk" />;
  }

  return (
    <div className="money-transfer-status-success-card">
      <Card className="money-transfer-status-success-card__card">
        <header className="money-transfer-status-success-card__header">
          <div className="money-transfer-status-success-card__header-icon">
            <CircleProgress
              className="money-transfer-status-success-card__header-icon__circle"
              color="var(--turquoise-blue)"
              value={75}
            />
            <IconCheck className="money-transfer-status-success-card__header-icon__check-icon" />
          </div>
          <div className="money-transfer-status-success-card__header-text">
            <p>Ваш перевод</p>
            <p>Успешно отправлен</p>
          </div>
        </header>

        <div className="money-transfer-status-success-card__content">
          <PaymentSummary summaryFields={summaryFields} />
        </div>
      </Card>

      <div className="money-transfer-status-success-card__nav">
        <button onClick={redirectToLk}>Вернуться в кошелек</button>
        <button onClick={retry}>Повторить перевод</button>
      </div>

      <div className="money-transfer-status-success-card__notice">
        <p>
          {paymentMethod === 'bankcard' &&
            'Банк обработает ваш перевод в течение 3-х рабочих дней. '}
          Информацию об операции вы найдете в разделе <Link to="/lk/history">История</Link>.
        </p>
      </div>
    </div>
  );
};

StatusSuccessCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  cardOrWalletNumber: PropTypes.string.isRequired,
  sbpBank: PropTypes.object.isRequired,
  sum: PropTypes.number.isRequired,
};

const mapStateToProps = (state) => ({
  cardOrWalletNumber: state.moneyTransfer.payment.cardOrWalletNumber,
  sbpBank: state.moneyTransfer.payment.sbpBank,
  sum: pennyToRubles(state.moneyTransfer.payment.sum),
});

export default connect(mapStateToProps)(StatusSuccessCard);
