import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import {
  EmailShareButton,
  //TODO?: функционал был выключен в рамках задачи SWPS-192
  //TODO?: функционал был выключен в в связи блокировкой на территории РФ
  // FacebookShareButton,
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';

import { getUserLimits } from 'modules/profileLegasy/selectors';
import { getUser, setMenuOpen } from 'modules/authLegasy/actions';
import {
  getTransactionOperationStatus,
  transferMoneyByBusinessCard,
} from 'modules/profileLegasy/actions';

import TransferResult from 'modules/profileLegasy/components/TransferResult/TransferResult';
import BlockedUserText from 'modules/shared/components/BlockedUserText/BlockedUserText';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CircleWithEmoji from 'modules/shared/components/CircleWithEmoji/CircleWithEmoji';
import PaymentBox from 'modules/shared/components/PaymentBox/PaymentBox';
import TransferResultErrorMessage from 'modules/shared/components/TransferResultErrorMessage/TransferResultErrorMessage';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import { ReactComponent as IconCopyContent } from 'static/assets/icon-copy-content.svg';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconEnvelope } from 'static/assets/icon-envelope.svg';
//TODO?: функционал был выключен в рамках задачи SWPS-192
//TODO?: функционал был выключен в в связи блокировкой на территории РФ
// import { ReactComponent as IconFbShare } from 'static/assets/icon-fb-share.svg';
import { ReactComponent as IconMasterCard } from 'static/assets/icon-mastercard.svg';
import { ReactComponent as IconMir } from 'static/assets/icon-mir.svg';
import { ReactComponent as IconPci } from 'static/assets/icon-pci.svg';
import { ReactComponent as IconVisa } from 'static/assets/icon-visa-verified.svg';
import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';
import { ReactComponent as IconLogoSmall } from 'static/assets/logo-small-white.svg';
import { ReactComponent as IconPaymentProcess } from 'static/assets/icon-payment-process.svg';
import profileBg from 'static/assets/profile-bg.png';

import { ReactComponent as IconShareTg } from 'static/assets/icon-share-tg.svg';
import { ReactComponent as IconShareVk } from 'static/assets/icon-share-vk.svg';
import { ReactComponent as IconShareWa } from 'static/assets/icon-share-wa.svg';
import { ReactComponent as IconShareOk } from 'static/assets/icon-share-ok.svg';

import { useHistory } from 'react-router-dom';

// Styles
import './BusinessCard.scss';
import * as types from '../../actions/action-types';
import stdAvatar from '../../../../static/assets/std-avatar.png';
import { maxSumDefault } from '../../../../tools/constants';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';

const BusinessCard = ({
  closeDrop,
  user,
  dispatch,
  isMenuOpen,
  isEnableToPay,
  className,
  isOpenByMe,
  userData,
  clientUniqueId,
  isMoneyTransferOk,
  isMoneyTransferFailed,
  isSending,
  url,
  userAvatar,
  isAvatarError,
  isUserBlocked,
  isReplenishmentBlocked,
  userLimits,
}) => {
  const windowSize = useWindowSize();
  const [isUserCanGetMoney, setIsUserCanGetMoney] = useState(true);
  const [isIframeLoading, setIsIframeLoading] = useState(false);
  const [isIframeSBPLoading, setIsIframeSBPLoading] = useState(false);
  const [showApplePay, setShowApplePay] = useState(false);

  /** Стейт филд для инпута */
  const [sum, setSum] = useState('');
  const [minSum, setMinSum] = useState(1);
  const [maxSum, setMaxSum] = useState(Infinity);

  /** Сумма с комиссией */
  const [message, setMessage] = useState('');
  const [isCopied, setIsCopied] = useState(false);
  const [showIframe, setShowIframe] = useState(false);
  const [showIframeSBP, setShowIframeSBP] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const history = useHistory();

  const avatarUrl = userAvatar
    ? `${process.env.REACT_APP_API_URL}/settings/avatar/${userAvatar}`
    : stdAvatar;

  const toggleBusinessCard = () => ({
    type: types.TOGGLE_BUSINESS_CARD,
  });

  useEffect(() => {
    if (window && window.localStorage) {
      const localErrorMessage =
        window && window.localStorage
          ? window.localStorage.getItem('transactionStateMessage')
          : null;
      if (localErrorMessage) {
        setErrorMessage(localErrorMessage);
        window.localStorage.removeItem('transactionStateMessage');
      }
    }
  }, []);

  useEffect(() => {
    if (
      window &&
      window.location &&
      window.location.href.indexOf('/apple-pay-business-card/') > -1
    ) {
      setShowApplePay(true);
    } else {
      setShowApplePay(false);
    }
  }, []);

  useEffect(() => {
    if (!userData || !userData.commission) {
      return;
    }

    setMinSum(userData.minSum);
    setMaxSum(Math.min(maxSumDefault, userData.maxLimitSum));
  }, [userData, userLimits]);

  useEffect(() => {
    if (
      userData &&
      userData.hasOwnProperty('maxLimitSum') &&
      (userData.maxLimitSum.toString() === '0' || userData.maxLimitSum === 0)
    ) {
      setIsUserCanGetMoney(false);
    }
  }, [userData]);

  const updateTimeout = useRef(null);
  const updateOperationStatus = useCallback(
    (operationId) => {
      if (!operationId) {
        clearTimeout(updateTimeout.current);
        return;
      }

      dispatch(getTransactionOperationStatus(operationId))
        .then((res) => {
          if (res && res.data) {
            if (res.data.state === STATUS_WAIT) {
              updateTimeout.current = setTimeout(() => updateOperationStatus(operationId), 1000);
            } else if (res.data.state === STATUS_ERROR) {
              dispatch(toggleBusinessCard());
              history.push('/receipt/businesscard/error');
            } else {
              dispatch(toggleBusinessCard());
              history.push(`/receipt/businesscard/${operationId}/success`);
              dispatch(getUser()).catch(() => {});
            }

            if (res.data.transactionStateMessage) {
              window.localStorage.setItem(
                'transactionStateMessage',
                res.data.transactionStateMessage,
              );
            }
          }
        })
        .catch(() => {
          dispatch(toggleBusinessCard());
          history.push('/receipt/businesscard/error');
        });
    },
    [dispatch, history],
  );

  useEffect(() => {
    const stateListener = (e) => {
      if (!process.env.REACT_APP_PCI_DSS_IFRAME.includes(e.origin)) return;
      if (e.data.operationId) {
        clearTimeout(updateTimeout?.current);
        updateOperationStatus(e.data.operationId);
      } else if (e.data.linkUrl) {
        window.open(e.data.linkUrl, e.data.target || '_blank');
      }
    };
    if (showIframeSBP) {
      window.addEventListener('message', stateListener);
    }
    return () => {
      window.removeEventListener('message', stateListener);
      clearTimeout(updateTimeout?.current);
    };
  }, [showIframeSBP, updateOperationStatus, dispatch]);

  /** Click on burger to set isMenuOpen */
  const handleMenuOpenClick = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  const payByApplePay = (signature) => {
    /* eslint-disable no-console */
    console.log('PAY BY APPLE PAY IN BUSINESS CARD COMPONENT', signature);
    dispatch(
      transferMoneyByBusinessCard({
        clientUniqueId: isOpenByMe ? (user || {}).randomUniqueId : clientUniqueId,
        message,
        payToken: {
          token: signature,
          type: 'APPLE',
        },
        sum: +sum * 100,
      }),
    )
      .then((res) => {
        // /** Устанавливаем редирект */
        // if (res && res.result && res.result.code >= 200 && res.result.code < 300 && res.data) {
        //   setRedirectData(res.data);
        // } else {
        //   throw new Error('Bad response');
        // }
        console.log('SUCCESS in api/receipt/businesscard', res);
      })
      .catch((err) => {
        // setIsLoading(false);
        // setIsInitialMoneyTransferFailed(true);
        console.log('ERROR in api/receipt/businesscard', err);
      });
    /* eslint-enable no-console */
  };

  /** Сбрасываем стейт в false при клике на кнопку "Попробовать снова" */
  const handleTryAgainButtonClick = (event) => {
    event.stopPropagation();
    setSum('');
  };

  /** Валидируем введенную сумму */
  const handleSumChange = (values) => {
    const { floatValue, value } = values;

    if (floatValue < minSum / 100) {
      setSum(String(minSum / 100));
    } else if (floatValue > maxSum / 100) {
      setSum(String(maxSum / 100));
    } else if (value === '') {
      setSum('');
    } else {
      setSum(value);
    }
  };

  const onPayByCard = () => {
    setShowIframe(true);
    setIsIframeLoading(true);
  };

  const onPayBySBP = () => {
    setShowIframeSBP(true);
    setIsIframeSBPLoading(true);
  };

  const resetPay = () => {
    setShowIframe(false);
    setIsIframeLoading(false);
    setShowIframeSBP(false);
    setIsIframeSBPLoading(false);
  };

  return (
    <div
      className={cx('business-card', {
        [className]: className,
      })}
    >
      {/* ХЭДЕР */}
      <div
        className={cx('business-card__header', {
          'business-card__header_menu-open': isMenuOpen,
          'business-card__header_another': !isOpenByMe,
        })}
      >
        {/* ЛОКАЛЬНАЯ НАВИГАЦИЯ ПРИ < 980 и не своей визитке */}
        {windowSize.innerWidth <= 980 && !isOpenByMe && (
          <div
            className={cx('business-card__top-nav', {
              'business-card__top-nav_no-user': !user,
              'business-card__top-nav_another': !isOpenByMe,
            })}
          >
            {user ? (
              <>
                {showIframe || showIframeSBP ? (
                  <div className="business-card__back-button" onClick={resetPay}>
                    <IconArrow
                      className={cx('business-card__back-button-icon', {
                        'business-card__back-button-icon_menu-open': isMenuOpen,
                      })}
                    />
                  </div>
                ) : (
                  <Link className="business-card__back-button" to="/">
                    <IconArrow
                      className={cx('business-card__back-button-icon', {
                        'business-card__back-button-icon_menu-open': isMenuOpen,
                      })}
                    />
                  </Link>
                )}
              </>
            ) : (
              <>
                {showIframe || showIframeSBP ? (
                  <div className="business-card__back-button" onClick={resetPay}>
                    <IconArrow
                      className={cx('business-card__back-button-icon', {
                        'business-card__back-button-icon_menu-open': isMenuOpen,
                      })}
                    />
                  </div>
                ) : (
                  <Link className="business-card__whiteLogoWrap" to="/">
                    <IconLogoSmall />
                  </Link>
                )}
              </>
            )}

            <div className="business-card__top-nav-right">
              {/* ЛОКАЛЬНЫЙ БУРГЕР ДЛЯ ОТКРЫТИЯ МЕНЮ */}
              <button
                className={cx('business-card__burger', {
                  'business-card__burger_menu-open': isMenuOpen,
                })}
                type="button"
                onClick={handleMenuOpenClick}
              >
                <IconLine1 className="business-card__burger-span" />
                <IconLine2 className="business-card__burger-span" />
                <IconLine3 className="business-card__burger-span" />
              </button>
              {/* /ЛОКАЛЬНЫЙ БУРГЕР ДЛЯ ОТКРЫТИЯ МЕНЮ */}
            </div>
          </div>
        )}
        {/* /ЛОКАЛЬНАЯ НАВИГАЦИЯ ПРИ < 980 и не своей визитке */}

        {windowSize.innerWidth > 980 && isOpenByMe && (
          <div className={cx('business-card__top-nav')}>
            {(showIframe || showIframeSBP) && (
              <div className="business-card__back-button" onClick={resetPay}>
                <IconArrow
                  className={cx('business-card__back-button-icon', {
                    'business-card__back-button-icon_menu-open': false,
                  })}
                />{' '}
                <span className="business-card__back-button-text">Назад</span>
              </div>
            )}
          </div>
        )}

        {windowSize.innerWidth <= 980 && isOpenByMe && (
          <div className={cx('business-card__top-nav')}>
            {(showIframe || showIframeSBP) && (
              <div className="business-card__back-button" onClick={resetPay}>
                <IconArrow
                  className={cx('business-card__back-button-icon', {
                    'business-card__back-button-icon_menu-open': false,
                  })}
                />
              </div>
            )}
          </div>
        )}

        {windowSize.innerWidth <= 980 && isOpenByMe && (
          <div className="business-card__close-wrapper">
            <button
              className="business-card__close"
              type="button"
              onClick={closeDrop}
              aria-label="Закрытие попапа"
            >
              <IconClose className="business-card__close-icon" />
            </button>
          </div>
        )}

        {windowSize.innerWidth > 980 && (showIframe || showIframeSBP) && !isOpenByMe && (
          <div className={cx('business-card__top-nav')}>
            <div className="business-card__back-button" onClick={resetPay}>
              <IconArrow
                className={cx('business-card__back-button-icon', {
                  'business-card__back-button-icon_menu-open': false,
                })}
              />{' '}
              <span className="business-card__back-button-text">Назад</span>
            </div>
          </div>
        )}

        {/* ЗАГОЛОВОК ДЛЯ СВОЕЙ ССЫЛКИ-ВИЗИТКИ */}
        {isOpenByMe && !isSending && !isMoneyTransferFailed && (
          <>
            <p className="business-card__header-title">Ваша визитка</p>
            <p className="business-card__header-subtitle">
              Вы человеку — эту ссылку, он вам — деньги
            </p>
          </>
        )}
        {/* /ЗАГОЛОВОК ДЛЯ СВОЕЙ ССЫЛКИ-ВИЗИТКИ */}

        {windowSize.innerWidth > 980 && isOpenByMe && !isSending && !isMoneyTransferFailed && (
          <>
            {/* БЛОК КОПИРОВАНИЯ ССЫЛКИ В КЛИПБОАРД */}
            <div className="business-card__header-link-block">
              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <button type="button" className="business-card__header-link-block-button">
                  <IconCopyContent className="business-card__header-link-block-icon" />
                </button>
              </CopyToClipboard>

              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <button type="button" className="business-card__header-link-block-url">
                  {url}
                </button>
              </CopyToClipboard>

              {isCopied && <p className="business-card__header-link-block-copied">скопировано</p>}
            </div>
            {/* /БЛОК КОПИРОВАНИЯ ССЫЛКИ В КЛИПБОАРД */}

            {/* РАСШАРИВАНИЕ ССЫЛКИ */}
            <div className="business-card__share-block">
              <EmailShareButton className="business-card__share-item" url={url}>
                <IconEnvelope className="business-card__share-item-icon business-card__share-item-icon_envelope" />
              </EmailShareButton>

              <VKShareButton className="business-card__share-item" url={url}>
                <IconShareVk className="business-card__share-item-icon business-card__share-item-icon_vk" />
              </VKShareButton>

              <TelegramShareButton className="business-card__share-item" url={url}>
                <IconShareTg className="business-card__share-item-icon business-card__share-item-icon_tg" />
              </TelegramShareButton>

              <OKShareButton className="business-card__share-item" url={url}>
                <IconShareOk className="business-card__share-item-icon business-card__share-item-icon_ok" />
              </OKShareButton>

              <WhatsappShareButton className="business-card__share-item" url={url}>
                <IconShareWa className="business-card__share-item-icon business-card__share-item-icon_wa" />
              </WhatsappShareButton>

              {
                //TODO?: функционал был выключен в рамках задачи SWPS-192
                //TODO?: функционал был выключен в в связи блокировкой на территории РФ
              }
              {/* <FacebookShareButton className="business-card__share-item" url={url}>
                <IconFbShare className="business-card__share-item-icon business-card__share-item-icon_fb" />
              </FacebookShareButton> */}
            </div>
            {/* /РАСШАРИВАНИЕ ССЫЛКИ */}
          </>
        )}

        {!isMenuOpen && (
          <img
            src={profileBg}
            alt="Фирменный бэкгранунд Самозанятых.рф"
            className="business-card__header-bg"
          />
        )}
      </div>
      {/* /ХЭДЕР */}

      {/* КОНТЕНТ */}
      <div className="business-card__content">
        {/* АВАТАР */}
        {isOpenByMe && !isSending && !isMoneyTransferFailed && (
          <>
            <div className="business-card__avatar-wrap">
              <img
                src={avatarUrl}
                alt="Изображение пользователя"
                className="business-card__avatar-wrap_avatar"
              />
            </div>
          </>
        )}
        {/* /АВАТАР */}

        {/* АВАТАР ЮЗЕРА, КОТОРОМУ БУДУТ ОТПРАВЛЯТЬ ДЕНЬГИ */}
        {!isOpenByMe && !isUserBlocked && !isReplenishmentBlocked && (
          <div className="business-card__avatar-wrap business-card__avatar-wrap_icon-payment business-card__avatar-wrap_business-card-to-pay">
            <IconPaymentProcess className="business-card__avatar" />
          </div>
        )}

        {(isUserBlocked || isReplenishmentBlocked) && (
          <CircleWithEmoji
            emoji={emojiSad}
            className="business-card__avatar-wrap business-card__avatar-wrap_business-card-to-pay"
          />
        )}
        {/* /АВАТАР ЮЗЕРА, КОТОРОМУ БУДУТ ОТПРАВЛЯТЬ ДЕНЬГИ */}

        {/* РАСШАРИВАНИЕ ССЫЛКИ ПРИ ЭКРАНЕ < 980 */}
        {windowSize.innerWidth <= 980 && isOpenByMe && !isSending && !isMoneyTransferFailed && (
          <>
            <div className="business-card__header-link-block">
              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <button type="button" className="business-card__header-link-block-button">
                  <IconCopyContent className="business-card__header-link-block-icon" />
                </button>
              </CopyToClipboard>

              <CopyToClipboard text={url} onCopy={() => setIsCopied(true)}>
                <button type="button" className="business-card__header-link-block-url">
                  {url}
                </button>
              </CopyToClipboard>
            </div>

            {isCopied && <p className="business-card__header-link-block-copied">скопировано</p>}

            <div className="business-card__share-block">
              <EmailShareButton className="business-card__share-item" url={url}>
                <IconEnvelope className="business-card__share-item-icon business-card__share-item-icon_envelope" />
              </EmailShareButton>

              <VKShareButton className="business-card__share-item" url={url}>
                <IconShareVk className="business-card__share-item-icon business-card__share-item-icon_vk" />
              </VKShareButton>

              <TelegramShareButton className="business-card__share-item" url={url}>
                <IconShareTg className="business-card__share-item-icon business-card__share-item-icon_tg" />
              </TelegramShareButton>

              <OKShareButton className="business-card__share-item" url={url}>
                <IconShareOk className="business-card__share-item-icon business-card__share-item-icon_ok" />
              </OKShareButton>

              <WhatsappShareButton className="business-card__share-item" url={url}>
                <IconShareWa className="business-card__share-item-icon business-card__share-item-icon_wa" />
              </WhatsappShareButton>

              {
                //TODO?: функционал был выключен в рамках задачи SWPS-192
                //TODO?: функционал был выключен в в связи блокировкой на территории РФ
              }
              {/* <FacebookShareButton className="business-card__share-item" url={url}>
                <IconFbShare className="business-card__share-item-icon business-card__share-item-icon_fb" />
              </FacebookShareButton> */}
            </div>
          </>
        )}
        {/* /РАСШАРИВАНИЕ ССЫЛКИ ПРИ ЭКРАНЕ < 980 */}

        {/* ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
        {!isSending &&
          !isMoneyTransferOk &&
          !isMoneyTransferFailed &&
          !isUserBlocked &&
          !isReplenishmentBlocked && (
            <>
              {isEnableToPay && !isOpenByMe && Boolean(userData) && (
                <>
                  {isUserCanGetMoney ? (
                    <div className="business-card__content-text">
                      <div className="business-card__content-text-title">Перевод пользователю</div>
                      {`на кошелек *${userData.account}`}
                    </div>
                  ) : (
                    <p className="business-card__content-text business-card__content-text_error">
                      {`Пользователь кошелька ****${userData.account} превысил лимит на пополнение`}
                    </p>
                  )}
                </>
              )}

              {isEnableToPay && isOpenByMe && (
                <>
                  {isUserCanGetMoney ? (
                    <p className="business-card__content-text">Пополнение баланса кошелька</p>
                  ) : (
                    <p className="business-card__content-text business-card__content-text_error">
                      Вы превысили лимит на пополнение
                    </p>
                  )}
                </>
              )}

              {!showIframe && !showIframeSBP && (
                <div className="business-card__block-list">
                  <div className="business-card__block">
                    <div
                      className={cx('business-card__input-holder', {
                        'business-card__input-holder_focused': sum !== '',
                      })}
                    >
                      <NumberFormat
                        className="business-card__input"
                        readOnly={!isEnableToPay}
                        value={sum}
                        thousandSeparator=" "
                        allowNegative={false}
                        allowEmptyFormatting={false}
                        decimalScale={2}
                        isNumericString
                        suffix=" ₽"
                        allowedDecimalSeparators={[',', '.']}
                        type="tel"
                        onValueChange={(values) => handleSumChange(values)}
                        isAllowed={(values) => {
                          const { value } = values;

                          if (value.charAt(0) === '0') {
                            return value.charAt(1) === '.';
                          }

                          return true;
                        }}
                        disabled={!isUserCanGetMoney}
                      />
                      {<p className="business-card__label">Сумма</p>}
                    </div>

                    {parseInt(sum) > maxSum / 100 && isUserCanGetMoney ? (
                      <p className="business-card__block-text business-card__block-text_error">
                        {`Лимит пополнения = ${maxSum / 100} ₽`}
                      </p>
                    ) : (
                      <p className="business-card__block-text">
                        {`Минимальная сумма для отправки = ${minSum / 100} ₽`}
                      </p>
                    )}
                  </div>
                  <div className="business-card__block">
                    <div
                      className={cx('business-card__input-holder', {
                        'business-card__input-holder_focused': message !== '',
                      })}
                    >
                      <input
                        type="text"
                        className="business-card__input business-card__input_message"
                        readOnly={!isEnableToPay}
                        value={message}
                        maxLength={50}
                        onChange={(event) => setMessage(event.target.value)}
                        disabled={!isUserCanGetMoney}
                      />
                      {<p className="business-card__label">Комментарий</p>}
                    </div>
                    <p className="business-card__block-text">
                      Вы можете отправить комментарий получателю, но это необязательно
                    </p>
                  </div>
                  <div className="business-card__btn-wrap">
                    <PaymentBox
                      isOneColumn
                      amount={parseInt(sum)}
                      isDisabled={
                        !(isEnableToPay && sum && !Number.isNaN(sum)) || !isUserCanGetMoney
                      }
                      onPayByCard={onPayByCard}
                      onPayBySBP={onPayBySBP}
                      hideApplePay={!showApplePay}
                      hideSamsungPay
                      hideCard
                      payByApplePay={payByApplePay}
                    />
                  </div>
                </div>
              )}

              {/* IFRAME PSI DSS */}
              {showIframe && (
                <div className="business-card__iframe-wrap">
                  {isIframeLoading && (
                    <div className="business-card__iframe-loader">
                      <CircleLoader />
                    </div>
                  )}

                  <iframe
                    title="BusinessCard"
                    className="business-card__iframe"
                    onLoad={() => {
                      setIsIframeLoading(false);
                    }}
                    src={`${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/business-card-fix?account=${
                      user ? user.login : 'anonymous'
                    }&recipientUniqueId=${clientUniqueId || (user && user.randomUniqueId)}&amount=${
                      sum * 100
                    }&comment=${message}`}
                    frameBorder="0"
                  />
                </div>
              )}
              {/* /IFRAME PSI DSS */}

              {/* IFRAME PSI DSS SBP*/}
              {showIframeSBP && (
                <div className="business-card__iframe-wrap-sbp">
                  {isIframeSBPLoading && (
                    <div className="business-card__iframe-loader">
                      <CircleLoader />
                    </div>
                  )}

                  <iframe
                    id="BusinessCardSBP"
                    title="BusinessCardSBP"
                    className="business-card__iframe"
                    onLoad={() => {
                      setIsIframeSBPLoading(false);
                    }}
                    src={`${process.env.REACT_APP_PCI_DSS_IFRAME}iframe/business-card-sbp?account=${
                      user ? user.login : 'anonymous'
                    }&recipientUniqueId=${clientUniqueId || (user && user.randomUniqueId)}&amount=${
                      sum * 100
                    }&comment=${message}`}
                    frameBorder="0"
                  />
                </div>
              )}
              {/* /IFRAME PSI DSS SBP*/}

              {/* ИКОНКИ СОПСОБОВ ОПЛАТЫ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
              {!showIframeSBP && (
                <div className="business-card__payment-ways">
                  <IconVisa className="business-card__payment-ways-item business-card__payment-ways-item_visa" />
                  <IconMasterCard className="business-card__payment-ways-item business-card__payment-ways-item_msc" />
                  <IconMir className="business-card__payment-ways-item business-card__payment-ways-item_mir" />
                  <IconPci className="business-card__payment-ways-item bank-card-payment__payment-ways-item_pci" />
                </div>
              )}
              {/* /ИКОНКИ СОПСОБОВ ОПЛАТЫ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}

              {/* ССЫЛКА НА ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
              {!showIframe && !showIframeSBP && (
                <div className="business-card__info">
                  Совершая платеж, вы соглашаетесь с{' '}
                  <a
                    href="https://самозанятые.рф/legal/walletoffer.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="business-card__info-link"
                  >
                    публичной офертой
                  </a>
                  ,{' '}
                  <a
                    href="https://самозанятые.рф/legal/walletterms.pdf"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="business-card__info-link"
                  >
                    пользовательским соглашением
                  </a>
                  <span> и </span>
                  <a
                    href={`${process.env.REACT_APP_URL}privacy-policy`}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="business-card__info-link"
                  >
                    политикой конфиденциальности
                  </a>
                </div>
              )}
              {/* /ССЫЛКА НА ПОЛИТИКУ КОНФИДЕНЦИАЛЬНОСТИ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}
            </>
          )}
        {/* /ОСНОВНОЙ КОНТЕНТ ДО ОТПРАВКИ ЗАПРОСА НА ОПЛАТУ */}

        {isUserBlocked && <BlockedUserText />}

        {isReplenishmentBlocked && (
          <BlockedUserText
            title={'Пополнение кошелька заблокировано'}
            desc={'Пополнение кошелька заблокировано и нельзя совершить платеж.'}
          />
        )}

        {/* ЛОАДЕР ИЛИ РЕЗУЛЬТАТ */}
        {(isSending || isMoneyTransferOk || isMoneyTransferFailed) && (
          <TransferResult
            isMoneyTransferOk={isMoneyTransferOk}
            isMoneyTransferFailed={isMoneyTransferFailed}
            isLoading={isSending}
            handleTryAgainButtonClick={handleTryAgainButtonClick}
            closeModal={closeDrop}
          >
            {/* ЛОАДЕР */}
            {isSending && (
              <div className="transfer-result__text">
                {'Платеж обрабатывается. Пожалуйста подождите, это может занять некоторое время.'}
              </div>
            )}
            {/* /ЛОАДЕР */}

            {/* ОПЛАТА ПРОШЛА УСПЕШНО */}
            {isMoneyTransferOk && (
              <div className="transfer-result__text">
                {'Оплата прошла успешно. Пользователь получит средства в свой кошелек'}
              </div>
            )}
            {/* /ОПЛАТА ПРОШЛА УСПЕШНО */}

            {/* ОПЛАТА НЕ ПРОШЛА */}
            {isMoneyTransferFailed && <TransferResultErrorMessage errorMessage={errorMessage} />}
            {/* /ОПЛАТА НЕ ПРОШЛА */}
          </TransferResult>
        )}
        {/* /ЛОАДЕР ПРИ ОТПРАВКЕ ЗАПРОСА НА ОПЛАТУ */}
      </div>
      {/* /КОНТЕНТ */}

      {/* КНОПКА ЗАКРЫТИЯ ССЫЛКИ-ВИЗИТКИ ПРИ РЕДАКТИРОВАНИИ СВОЕЙ ССЫЛКИ-ВИЗИТКИ */}
      {windowSize.innerWidth > 980 && isOpenByMe && (
        <button
          className="business-card__close"
          type="button"
          onClick={closeDrop}
          aria-label="Закрытие попапа"
        >
          <IconClose className="business-card__close-icon" />
        </button>
      )}
      {/* /КНОПКА ЗАКРЫТИЯ ССЫЛКИ-ВИЗИТКИ ПРИ РЕДАКТИРОВАНИИ СВОЕЙ ССЫЛКИ-ВИЗИТКИ */}
    </div>
  );
};

BusinessCard.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  closeDrop: PropTypes.func,
  user: PropTypes.shape({
    avatar: PropTypes.string,
    randomUniqueId: PropTypes.string,
    login: PropTypes.string,
  }),
  isMenuOpen: PropTypes.bool.isRequired,
  isEnableToPay: PropTypes.bool,
  isOpenByMe: PropTypes.bool,
  userAvatar: PropTypes.string,
  userData: PropTypes.shape({
    account: PropTypes.string,
    avatar: PropTypes.string,
    currency: PropTypes.string,
    commission: PropTypes.shape({
      fixValue: PropTypes.number,
      maxComm: PropTypes.number,
      minComm: PropTypes.number,
      roundOrder: PropTypes.number,
      type: PropTypes.number,
      value: PropTypes.number,
    }),
    minSum: PropTypes.number,
    maxSum: PropTypes.number,
    maxLimitSum: PropTypes.number,
  }),
  clientUniqueId: PropTypes.string,
  isMoneyTransferOk: PropTypes.bool,
  isMoneyTransferFailed: PropTypes.bool,
  isSending: PropTypes.bool,
  url: PropTypes.string,
  isAvatarError: PropTypes.bool.isRequired,
  isUserBlocked: PropTypes.bool,
  isReplenishmentBlocked: PropTypes.bool,
  userLimits: PropTypes.shape({
    minSum: PropTypes.number.isRequired,
    balanceLimit: PropTypes.number.isRequired,
  }).isRequired,
};

BusinessCard.defaultProps = {
  className: null,
  user: null,
  closeDrop: () => {},
  isEnableToPay: true,
  isOpenByMe: false,
  clientUniqueId: null,
  userData: null,
  isMoneyTransferOk: false,
  isMoneyTransferFailed: false,
  isSending: false,
  url: null,
  isUserBlocked: false,
  isReplenishmentBlocked: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMenuOpen: state.auth.isMenuOpen,
  userAvatar: state.auth.user && state.auth.user.avatar ? state.auth.user.avatar : null,
  isAvatarError: state.profile.isAvatarError,
  userLimits: getUserLimits()(state),
});

export default connect(mapStateToProps)(BusinessCard);
