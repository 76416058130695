export const getDaysLeft = date => {
  return Math.round((new Date(date).setHours(23, 59, 59, 999) - new Date()) / 1000 / 60 / 60 / 24);
};

export const diffInDays = (dateStart, dateEnd) => {
  return Math.round(
    (new Date(dateEnd).setHours(23, 59, 59, 999) - new Date(dateStart).setHours(23, 59, 59, 999)) /
      1000 /
      60 /
      60 /
      24,
  );
};

export const dateProgress = (dateStart, dateEnd) => {
  const diff = diffInDays(dateStart, dateEnd);
  const left = getDaysLeft(dateEnd);

  return ((diff - left) / diff) * 100;
};
