import { fetchWrapper } from 'helpers';
import * as types from './action-types';

export const logOut = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/logout`;

  return new Promise((resolve, reject) => {
    //  TODO: PRO-384
    //  /** Обновляем интерком для неавторизованного пользователя */
    //  if (process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom) {
    //   window.Intercom('shutdown');
    //   window.Intercom('boot', {
    //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
    //     custom_launcher_selector: '.js-help',
    //   });
    // }

    fetchWrapper(url, {
      method: 'POST',
    })
      .then(resolve)
      .catch(reject)
      .finally(() => {
        dispatch({
          type: types.LOG_OUT_SUCCESS,
        });
      });
  });
};

export const getUser = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/client/info?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((user) => {
        if (user.result.message === 'Preauthorized') {
          dispatch(logOut());
          throw user;
        } else {
          dispatch({
            type: types.GET_USER_SUCCESS,
            payload: {
              user,
            },
          });
        }

        //  TODO: PRO-384
        //  /** Включаем интерком для авторизованного пользователя */
        //  if (process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom) {
        //   let userPhone = '';
        //   let userEmail = '';
        //   let userName = '';
        //   let userId = '';
        //   if (user && user.data) {
        //     userPhone = user.data.login;
        //     userEmail = user.data.email;
        //     userId = user.data.randomUniqueId;
        //     if (user.data.personal && user.data.personal.firstName && user.data.personal.lastName) {
        //       userName = `${user.data.personal.firstName} ${user.data.personal.lastName}`;
        //     }
        //   }

        //   window.Intercom('boot', {
        //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        //     custom_launcher_selector: '.js-help',
        //     email: userEmail,
        //     phone: userPhone,
        //     name: userName.slice(0, 1).toUpperCase() + userName.slice(1, userName.length) || '',
        //     user_id: userId,
        //   });
        // }

        resolve(user);
      })
      .catch((err) => {
        if (err?.data === 'client.not-found') dispatch(logOut());

        dispatch({
          type: types.GET_USER_FAIL,
        });

        //  TODO: PRO-384
        // /** Включаем интерком для неавторизованного пользователя */
        // if (process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom) {
        //   window.Intercom('boot', {
        //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        //     custom_launcher_selector: '.js-help',
        //   });
        // }
      });
  });
};

export const setUserPhone = (phone) => ({
  type: types.SET_PHONE,
  payload: {
    phone,
  },
});

export const setUserBalance = (userBalance) => ({
  type: types.SET_USER_BALANCE,
  payload: {
    userBalance,
  },
});

export const setUserNewMessages = () => ({
  type: types.SET_NEW_USER_MESSAGES,
});

export const setChangedTaxStatus = (taxStatus) => ({
  type: types.SET_CHANGED_TAX_STATUS,
  payload: {
    taxStatus,
  },
});

export const setUserChangedAuthStatus = (changedAuthStatus) => ({
  type: types.SET_CHANGED_AUTH_STATUS,
  payload: {
    changedAuthStatus,
  },
});

export const setSzStatusFromDb = (szStatusFromDb) => ({
  type: types.SET_SZ_STATUS_FROM_DB,
  payload: {
    szStatusFromDb,
  },
});

export const requestSMS = (phone, token) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/registration/sms/send`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        phone,
        accept: true,
        code: token,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.REQUEST_SMS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.REQUEST_SMS_FAIL,
        });
        reject(err);
      });
  });
};

export const checkCode = (actionPinAcceptId, pin) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/registration/sms/confirm`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        actionPinAcceptId,
        pin,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHECK_CODE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_CODE_FAIL,
        });
        reject(err);
      });
  });
};

export const setUserPassword = (password) => ({
  type: types.SET_PASSWORD,
  payload: {
    password,
  },
});

export const registerUser = (actionPinAcceptId, password, phone) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/registration`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        actionPinAcceptId,
        password,
        phone,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.REGISTER_USER_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.REGISTER_USER_FAIL,
        });

        if (
          err.result.message ===
          'Пожалуйста, проверьте ваш телефон. SMS с паролем уже было выслано при первой регистрации'
        )
          reject({
            data: null,
            result: {
              code: 400,
              message:
                'Вы уже зарегистрировались. Войдите в систему. В случаи утери пароля - напишите поддержку',
            },
          });

        reject(err);
      });
  });
};

export const setUserEmail = (email) => ({
  type: types.SET_EMAIL,
  payload: {
    email,
  },
});

export const logIn = (login, password) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/login?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        login,
        password,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.LOG_IN_SUCCESS,
          payload: {
            data,
          },
        });

        //  TODO: PRO-384
        // /** Обновляем интерком для авторизованного пользователя */
        // if (process.env.REACT_APP_INTERCOM_APP_ID && window.Intercom) {
        //   let userPhone = '';
        //   let userEmail = '';
        //   let userName = '';
        //   let userId = '';
        //   if (data && data.data) {
        //     userPhone = data.data.login;
        //     userEmail = data.data.email;
        //     userId = data.data.randomUniqueId;
        //     if (data.data.personal) {
        //       userName = `${data.data.personal.firstName} ${data.data.personal.lastName}`;
        //     }
        //   }
        //   window.Intercom('shutdown');
        //   window.Intercom('boot', {
        //     app_id: process.env.REACT_APP_INTERCOM_APP_ID,
        //     custom_launcher_selector: '.js-help',
        //     email: userEmail,
        //     phone: userPhone,
        //     name: userName,
        //     user_id: userId,
        //   });
        // }

        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.LOG_IN_FAIL,
        });
        reject(err);
      });
  });
};

export const firstLogIn = (login, password) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/login`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        login,
        password,
      }),
    })
      .then((user) => {
        dispatch({
          type: types.FIRST_LOG_IN_SUCCESS,
          payload: {
            user,
          },
        });
        resolve(user);
      })
      .catch((err) => {
        dispatch({
          type: types.LOG_IN_FAIL,
        });
        reject(err);
      });
  });
};

export const loginAfterRegistration = (redirectTo = null) => ({
  type: types.LOG_IN_AFTER_REGISTRATION_SUCCESS,
  payload: {
    redirectTo,
  },
});

export const removeRedirect = () => ({
  type: types.REMOVE_REDIRECT,
});

export const saveEmail = (email) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/registration/email/save`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        email,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.SAVE_EMAIL_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.SAVE_EMAIL_FAIL,
        });
        reject(err);
      });
  });
};

export const startRecoveryPassword = (phone, token) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/password/forgot/sms/send`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
      body: JSON.stringify({
        code: token,
        phone,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.START_RECOVERY_PASSWORD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.START_RECOVERY_PASSWORD_FAIL,
        });
        reject(err);
      });
  });
};

export const checkRecoveryPasswordCode = (actionPinAcceptId, pin) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/password/forgot/sms/confirm`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        actionPinAcceptId,
        pin,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHECK_CODE_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_CODE_FAIL,
        });
        reject(err);
      });
  });
};

export const confirmRecoveryPassword = (actionPinAcceptId, password, phone) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/password/forgot/new`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        actionPinAcceptId,
        password,
        phone,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CONFIRM_RECOVERY_PASSWORD_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CONFIRM_RECOVERY_PASSWORD_FAIL,
        });
        reject(err);
      });
  });
};

export const checkINN = (inn) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/selfemployed/update`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        inn,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHECK_INN_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_INN_FAIL,
        });
        reject(err);
      });
  });
};

export const checkINNStatus = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/selfemployed/info`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'GET',
    })
      .then((data) => {
        dispatch({
          type: types.CHECK_INN_STATUS_SUCCESS,
          payload: {
            data,
          },
        });
        setTimeout(() => {
          resolve(data);
        }, 6000);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_INN_STATUS_FAIL,
        });
        setTimeout(() => {
          reject(err);
        }, 6000);
      });
  });
};

export const checkInnWithRequestInFns = (inn) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/selfemployed/update/with/permissions`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        inn,
      }),
    })
      .then((data) => {
        dispatch({
          type: types.CHECK_INN_WITH_REQUEST_IN_FNS_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch((err) => {
        dispatch({
          type: types.CHECK_INN_WITH_REQUEST_IN_FNS_FAIL,
        });
        reject(err);
      });
  });
};

export const setInnWasSentOnCheck = (isInnSentOnCheck) => ({
  type: types.SET_INN_SENT_ON_CHECK,
  payload: {
    isInnSentOnCheck,
  },
});

export const setINN = (INN) => ({
  type: types.SET_INN,
  payload: {
    INN,
  },
});

export const setMenuOpen = (isMenuOpen) => ({
  type: types.SET_MENU_OPEN,
  payload: {
    isMenuOpen,
  },
});

export const setMenuOpenWithTransition = (isMenuOpen) => ({
  type: types.SET_MENU_OPEN_WITH_TRANSITION,
  payload: {
    isMenuOpen,
  },
});

export const setHintOpen = (isHintOpen) => ({
  type: types.SET_HINT_OPEN,
  payload: {
    isHintOpen,
  },
});

export const setRegistrationStep = (registrationStep) => ({
  type: types.SET_REGISTRATION_STEP,
  payload: {
    registrationStep,
  },
});

export const getLimits = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/identifications?p=${new Date().getTime()}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((limits) => {
        dispatch({
          type: types.GET_LIMITS_SUCCESS,
          payload: {
            limits,
          },
        });
        resolve(limits);
      })
      .catch(() => {
        reject();
      });
  });
};

export const acceptPrivacyPolicy = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/oferta-accept`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'PUT',
    })
      .then((data) => {
        dispatch({
          type: types.ACCEPT_PRIVACY_POLICY_SUCCESS,
          payload: {
            data,
          },
        });
        resolve(data);
      })
      .catch(() => {
        reject();
      });
  });
};
