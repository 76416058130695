/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { TitleText } from 'ui';

export const Plate = ({ resolved, rejected, active, text }) => (
  <PlateStyled resolved={resolved} rejected={rejected} active={active}>
    <PlateTextStyled>{text}</PlateTextStyled>
  </PlateStyled>
);

Plate.propTypes = {
  text: PropTypes.string,
  active: PropTypes.bool,
  rejected: PropTypes.bool,
  resolved: PropTypes.bool,
};

Plate.defaultProps = {
  text: '',
  active: false,
  rejected: false,
  resolved: false,
};

const PlateStyled = styled.div`
  border-radius: 61px;
  ${({ active }) =>
    active &&
    css`
      background: #00adb5;
    `}
  ${({ resolved }) =>
    resolved &&
    css`
      background: #9da6ba;
    `}
  ${({ rejected }) =>
    rejected &&
    css`
      background: #ff2d55;
    `}
`;

const PlateTextStyled = styled(TitleText)`
  display: flex;
  color: white;
  font-size: 12px;
  line-height: 7px;
  padding: 8.7px 12px;
  text-wrap: nowrap;
`;
