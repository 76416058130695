import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';

import { CANCELED, ERROR, PROCESS, NOT_PAYED, REJECTED, SUCCESS } from 'constants/index';

import { ReactComponent as IconDownloadCheck } from 'static/assets/icon-download-check.svg';
import AcquiringOperationsTimeLine from 'modules/acquiring/components/AcquiringOperationsTimeLine/AcquiringOperationsTimeLine';
import { ReactComponent as IconPayBack } from 'static/assets/icon-pay-back.svg';

import './AcquiringOperationItem.scss';

const AcquiringOperationItem = ({ item, currentOpenItem, onClick, downloadCheck }) => {
  const windowSize = useWindowSize();
  const getColorFromStatusForColorLine = () => {
    switch (item.state) {
      case SUCCESS:
        return 'var(--turquoise-blue)';
      case PROCESS:
        return 'var(--grey-blue)';
      case ERROR:
        return 'var(--light-mustard)';
      case REJECTED:
        return 'var(--reddish-pink)';
      case NOT_PAYED:
        return 'var(--grey-blue)';
      case CANCELED:
        return 'var(--light-mustard)';
      default:
        return 'var(--grey-blue)';
    }
  };
  const getColorFromStatusForStatusText = () => {
    switch (item.state) {
      case SUCCESS:
        return 'var(--dark-slate-blue)';
      case PROCESS:
        return 'var(--grey-blue)';
      case ERROR:
        return 'var(--light-mustard)';
      case REJECTED:
        return 'var(--reddish-pink)';
      case NOT_PAYED:
        return 'var(--grey-blue)';
      case CANCELED:
        return 'var(--light-mustard)';
      default:
        return 'var(--grey-blue)';
    }
  };

  return (
    <div
      className={cx('acquiring-operation-item', {
        'acquiring-operation-item_open': item.id === currentOpenItem && item.state !== NOT_PAYED,
        'acquiring-operation-item_not-payed': item.state === NOT_PAYED,
      })}
      onClick={onClick}
      onKeyDown={onClick}
      aria-hidden="true"
      data-testid="acquiring-operation-item"
    >
      {/* ЦВЕТНАЯ ПОЛОСКА СЛЕВА */}
      {windowSize.innerWidth > 1100 && (
        <div
          className="acquiring-operation-item__colored-line"
          style={{ background: getColorFromStatusForColorLine() }}
          data-testid="acquiring-operation-item-colored-line"
        />
      )}
      {/* /ЦВЕТНАЯ ПОЛОСКА СЛЕВА */}

      <div className="acquiring-operation-item__top">
        {/* ID И ДАТА СОЗДАНИЯ ОПЕРАЦИИ */}
        <div className="acquiring-operation-item__column">
          <div className="acquiring-operation-item__id-wrap">
            <div className="acquiring-operation-item__id">{item.merchantOrderId}</div>

            {windowSize.innerWidth <= 1100 && (
              <div
                className="acquiring-operation-item__colored-circle"
                style={{ background: getColorFromStatusForColorLine() }}
                data-testid="acquiring-operation-item-colored-circle"
              />
            )}
          </div>
          <div className="acquiring-operation-item__creation-date">
            {/* {operationsDateView(item.dateTimeAdd)} */}
            {item.dateTimeAdd}
          </div>
        </div>
        {/* /ID И ДАТА СОЗДАНИЯ ОПЕРАЦИИ */}

        {/* НОМЕР ПЛАТЕЛЬЩИКА */}
        {windowSize.innerWidth > 1100 && item.id && (
          <div className="acquiring-operation-item__column">
            <div className="acquiring-operation-item__payer-number">{item.id}</div>
          </div>
        )}
        {/* /НОМЕР ПЛАТЕЛЬЩИКА */}

        {/* СТАТУС ПЛАТЕЖА + ДАТА ПОСЛЕДНЕГО ОБНОВЛЕНИЯ СТАТУСА */}
        {windowSize.innerWidth > 1100 && (
          <div className="acquiring-operation-item__column">
            <div
              className="acquiring-operation-item__status"
              style={{ color: getColorFromStatusForStatusText() }}
            >
              {item.state === SUCCESS && 'Оплачен'}
              {item.state === PROCESS && 'Ожидает подтверждения'}
              {item.state === ERROR && 'Ошибка оплаты'}
              {item.state === REJECTED && 'Возвращëн'}
              {item.state === NOT_PAYED && 'Не оплачен'}
              {item.state === CANCELED && 'Отменëн'}
            </div>

            <div className="acquiring-operation-item__last-gotten-date">
              {/* {operationsDateView(item.lastGottenStatusDate)} */}
              {item.dateTimeFinished}
            </div>
          </div>
        )}
        {/* /СТАТУС ПЛАТЕЖА + ДАТА ПОСЛЕДНЕГО ОБНОВЛЕНИЯ СТАТУСА */}

        {/* СУММА И НОМЕР КАРТОЧКИ */}
        <div className="acquiring-operation-item__column">
          <TaxesAmount
            amount={item.sum}
            isLoading={false}
            className="acquiring-operation-item__amount"
          />
          <div className="acquiring-operation-item__card-number">{item.cardPan}</div>
        </div>
        {/* /СУММА И НОМЕР КАРТОЧКИ */}

        {windowSize.innerWidth > 1100 && (
          <div className="acquiring-operation-item__column">
            {item.id === currentOpenItem && (
              <button className="acquiring-operation-item__refund" type="button">
                <IconPayBack className="acquiring-operation-item__refund-icon" />
              </button>
            )}
          </div>
        )}
      </div>

      {item.state !== NOT_PAYED && (
        <div className="acquiring-operation-item__bottom">
          <div className="acquiring-operation-item__bottom-inner">
            {item.state === SUCCESS && windowSize.innerWidth > 1100 && (
              <button
                className="acquiring-operation-item__download-check"
                type="button"
                onClick={downloadCheck}
              >
                <span className="acquiring-operation-item__download-check-text">Скачать чек</span>
                <IconDownloadCheck className="acquiring-operation-item__download-check-icon" />
              </button>
            )}

            <div className="acquiring-operation-item__details">
              <p className="acquiring-operation-item__bottom-title">Детали платежа</p>
              <div className="acquiring-operation-item__details-list">
                {windowSize.innerWidth <= 1100 && (
                  <div className="acquiring-operation-item__details-item">
                    <div className="acquiring-operation-item__details-key">Статус</div>
                    <div
                      className="acquiring-operation-item__details-value"
                      style={{ color: getColorFromStatusForStatusText() }}
                    >
                      {item.state === SUCCESS && 'Оплачен'}
                      {item.state === PROCESS && 'Ожидает подтверждения'}
                      {item.state === ERROR && 'Ошибка оплаты'}
                      {item.state === REJECTED && 'Возвращëн'}
                      {item.state === NOT_PAYED && 'Не оплачен'}
                      {item.state === CANCELED && 'Отменëн'}
                    </div>
                  </div>
                )}

                {item.parentRrn && (
                  <div className="acquiring-operation-item__details-item">
                    <div className="acquiring-operation-item__details-key">RRN</div>
                    <div className="acquiring-operation-item__details-value">{item.parentRrn}</div>
                  </div>
                )}

                {item.merchantOrderId && (
                  <div className="acquiring-operation-item__details-item">
                    <div className="acquiring-operation-item__details-key">Номер заказа</div>
                    <div className="acquiring-operation-item__details-value">
                      {item.merchantOrderId}
                    </div>
                  </div>
                )}

                {item.parentMaskedWallet && (
                  <div className="acquiring-operation-item__details-item">
                    <div className="acquiring-operation-item__details-key">Способ оплаты</div>
                    <div className="acquiring-operation-item__details-value">
                      {item.parentMaskedWallet && 'Кошелек'}
                    </div>
                  </div>
                )}

                {item.parentMaskedPan && (
                  <div className="acquiring-operation-item__details-item">
                    <div className="acquiring-operation-item__details-key">Способ оплаты</div>
                    <div className="acquiring-operation-item__details-value">
                      {item.parentMaskedPan && 'Карта'}
                    </div>
                  </div>
                )}

                {/* {item.merchantOrderId && (
                  <div className="acquiring-operation-item__details-item">
                    <div className="acquiring-operation-item__details-key">Плательщик</div>
                    <div className="acquiring-operation-item__details-value">
                      {item.merchantOrderId}
                    </div>
                  </div>
                )}*/}
              </div>
            </div>
            <div className="acquiring-operation-item__history">
              <p className="acquiring-operation-item__bottom-title">История операции</p>

              <AcquiringOperationsTimeLine
                status={item.state}
                amount={item.sum}
                // startDate={operationsDateView(item.dateTimeAdd)}
                startDate={item.dateTimeAdd}
                lastDate={item.dateTimeFinished}
                processDate={item.dateTimeProcessed}
              />
            </div>

            {windowSize.innerWidth <= 1100 && (
              <div className="acquiring-operation-item__service">
                <button className="acquiring-operation-item__refund" type="button">
                  <IconPayBack className="acquiring-operation-item__refund-icon" />

                  <span className="acquiring-operation-item__refund-text">Сделать возврат</span>
                </button>

                {item.state === SUCCESS && (
                  <button
                    className="acquiring-operation-item__download-check"
                    type="button"
                    onClick={downloadCheck}
                  >
                    <span className="acquiring-operation-item__download-check-text">
                      Скачать чек
                    </span>
                    <IconDownloadCheck className="acquiring-operation-item__download-check-icon" />
                  </button>
                )}
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

AcquiringOperationItem.propTypes = {
  item: PropTypes.shape({
    id: PropTypes.number,
    logo: PropTypes.string,
    name: PropTypes.string,
    dateTimeAdd: PropTypes.string,
    sum: PropTypes.number,
    writeOff: PropTypes.bool,
    currency: PropTypes.string,
    state: PropTypes.string,
    id1: PropTypes.string,
    cardPan: PropTypes.string,
    merchantOrderId: PropTypes.string,
    dateTimeFinished: PropTypes.string,
    dateTimeProcessed: PropTypes.string,
    dateSuccess: PropTypes.string,
    dateCancelled: PropTypes.string,
    parentRrn: PropTypes.string,
    parentMaskedWallet: PropTypes.string,
    parentMaskedPan: PropTypes.string,
  }).isRequired,
  currentOpenItem: PropTypes.number,
  onClick: PropTypes.func.isRequired,
  downloadCheck: PropTypes.func.isRequired,
};

AcquiringOperationItem.defaultProps = {
  currentOpenItem: null,
};

export default AcquiringOperationItem;
