import * as types from '../actions/action-types';
import omit from '../helpers/omit';

// TODO: вынести куда-нибудь
const requestStages = {
  fetching: false,
  success: false,
  fail: false,
};

const infoService = {
  available: false,
  comission: null,
  maxSum: null,
  minSum: null,
};

const initialState = {
  payment: {
    // id платежа
    id: 0,
    // метод подтверждения
    confirmationMethod: '',
    // кол-во попыток ввода
    attempts: 0,
    // длина кода
    codeLength: 0,
    // время, через которое можно будет отправить снова код
    resendTime: 0,
    // id кода для подтверждения
    pinMessageId: 0,
    // сообщение, которое будет прикреплено к переводу
    message: '',
    // номер карты или кошелька
    cardOrWalletNumber: '',
    // сумма (в копейках)
    sum: 0,
    // статус
    status: '',
    duplicate: false,
  },

  // info
  info: {
    // валюта
    currency: '',
    // лимит на сумму для перевода (в копейках)
    maxLimitSum: 0,
    // сервисы
    services: {
      // банковская карта
      bankCard: { ...infoService },
      // ЮMoney
      uMoney: { ...infoService },
      // кошелек самозанятых
      cz: { ...infoService },
      // webMoney
      webMoney: { ...infoService },
      // qiwi
      qiwi: { ...infoService },
      // sbp
      sbp: { ...infoService },
    },
  },

  currentStep: 1,
  bankCardInfo: {
    name: '',
    nameEn: '',
    backgroundColor: '',
    backgroundColors: [],
    redError: false,
    whiteLogoColor: false,
    // ссылка на иконку
    logo: '',
    // провайдер (visa, master card, ...)
    bankCardType: '',
  },

  // асинхронные действия
  getMoneyTransferInfo: { ...requestStages },
  requestMoneyTransfer: { ...requestStages },
  checkMoneyTransferConfirmationViaPush: { ...requestStages },
  recreateMoneyTransferConfirmationViaSms: { ...requestStages },
  confirmMoneyTransferViaSms: { ...requestStages },
  sendMoneyTransferSmsCode: { ...requestStages },
  checkMoneyTransferStatus: { ...requestStages },
  getMoneyTransferBankCardInfo: { ...requestStages },
};

const moneyTransferReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_MONEY_TRANSFER_INFO_FETCHING:
      return {
        ...state,
        getMoneyTransferInfo: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_MONEY_TRANSFER_INFO_SUCCESS: {
      const services = action.payload.serviceData.reduce((acc, service) => {
        const { code } = service;
        const omitedService = omit(service, ['code']);
        let key;

        switch (code) {
          case 'bankcard':
            key = 'bankCard';
            break;
          case 'yamoney':
            key = 'uMoney';
            break;
          case 'sz':
            key = 'sz';
            break;
          case 'sbp':
            key = 'sbp';
            break;
          case 'webmoney':
            key = 'webMoney';
            break;
          case 'qiwi':
            key = 'qiwi';
            break;
          default:
            key = '';
        }

        acc[key] = { ...omitedService };

        return acc;
      }, {});

      return {
        ...state,
        getMoneyTransferInfo: {
          fetching: false,
          success: true,
          fail: false,
        },
        info: {
          currency: action.payload.currency,
          maxLimitSum: action.payload.maxLimitSum,
          services,
        },
      };
    }

    case types.GET_MONEY_TRANSFER_INFO_FAIL:
      return {
        ...state,
        getMoneyTransferInfo: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.REQUEST_MONEY_TRANSFER_FETCHING:
      return {
        ...state,
        requestMoneyTransfer: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.REQUEST_MONEY_TRANSFER_SUCCESS:
      return {
        ...state,
        requestMoneyTransfer: {
          fetching: false,
          success: true,
          fail: false,
        },
        payment: {
          ...state.payment,
          id: action.payload.id,
          ...(action.payload.confirm
            ? {
                confirmationMethod: action.payload.confirm.messageType,
                attempts: action.payload.confirm.attempts,
                codeLength: action.payload.confirm.length,
                resendTime: action.payload.confirm.resendTime,
                pinMessageId: action.payload.confirm.pinMessageId,
              }
            : {}),
        },
      };

    case types.REQUEST_MONEY_TRANSFER_FAIL:
      return {
        ...state,
        requestMoneyTransfer: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.REQUEST_MONEY_TRANSFER_FAIL_DUPLICATE:
      return {
        ...state,
        requestMoneyTransfer: {
          fetching: false,
          success: false,
          fail: false,
          fail_duplicate: true,
        },
      };

    case types.CONFIRM_MONEY_TRANSFER_DUPLICATE:
      return {
        ...state,
        payment: {
          ...state.payment,
          duplicate: action.payload,
        },
      };

    case types.CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FETCHING:
      return {
        ...state,
        checkMoneyTransferConfirmationViaPush: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_SUCCESS:
      return {
        ...state,
        checkMoneyTransferConfirmationViaPush: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FAIL:
      return {
        ...state,
        checkMoneyTransferConfirmationViaPush: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FETCHING:
      return {
        ...state,
        recreateMoneyTransferConfirmationViaSms: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          pinMessageId: action.payload.confirm.pinMessageId,
        },
        recreateMoneyTransferConfirmationViaSms: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FAIL:
      return {
        ...state,
        recreateMoneyTransferConfirmationViaSms: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CONFIRM_MONEY_TRANSFER_VIA_SMS_FETCHING:
      return {
        ...state,
        confirmMoneyTransferViaSms: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.CONFIRM_MONEY_TRANSFER_VIA_SMS_SUCCESS:
      return {
        ...state,
        confirmMoneyTransferViaSms: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.CONFIRM_MONEY_TRANSFER_VIA_SMS_FAIL:
      return {
        ...state,
        payment: {
          ...state.payment,
          attempts: state.payment.attempts - 1 < 0 ? 0 : state.payment.attempts - 1,
        },
        confirmMoneyTransferViaSms: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.SEND_MONEY_TRANSFER_SMS_CODE_FETCHING:
      return {
        ...state,
        sendMoneyTransferSmsCode: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.SEND_MONEY_TRANSFER_SMS_CODE_SUCCESS:
      return {
        ...state,
        sendMoneyTransferSmsCode: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.SEND_MONEY_TRANSFER_SMS_CODE_FAIL:
      return {
        ...state,
        sendMoneyTransferSmsCode: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CHECK_MONEY_TRANSFER_STATUS_FETCHING:
      return {
        ...state,
        checkMoneyTransferStatus: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.CHECK_MONEY_TRANSFER_STATUS_SUCCESS:
      return {
        ...state,
        payment: {
          ...state.payment,
          status: action.payload.status,
        },
        checkMoneyTransferStatus: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.CHECK_MONEY_TRANSFER_STATUS_FAIL:
      return {
        ...state,
        checkMoneyTransferStatus: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.RESET_MONEY_TRANSFER:
      return JSON.parse(JSON.stringify(initialState));

    case types.RESET_MONEY_TRANSFER_STEPS:
      return {
        ...state,
        currentStep: 1,
        payment: { ...initialState.payment },
        bankCardInfo: {
          name: '',
          nameEn: '',
          backgroundColor: '',
          backgroundColors: [],
          redError: false,
          whiteLogoColor: false,
          logo: '',
          bankCardType: '',
        },
        requestMoneyTransfer: { ...requestStages },
        checkMoneyTransferConfirmationViaPush: { ...requestStages },
        recreateMoneyTransferConfirmationViaSms: { ...requestStages },
        confirmMoneyTransferViaSms: { ...requestStages },
        sendMoneyTransferSmsCode: { ...requestStages },
        checkMoneyTransferStatus: { ...requestStages },
        getMoneyTransferBankCardInfo: { ...requestStages },
      };

    case types.SET_MONEY_TRANSFER_NEXT_STEP:
      let nextStep = state.currentStep + 1;
      if (state.currentStep === 2 && !state.payment.confirmationMethod) {
        nextStep += 1;
      }
      return {
        ...state,
        currentStep: nextStep,
      };

    case types.SET_MONEY_TRANSFER_PREVIOUS_STEP:
      return {
        ...state,
        currentStep: state.currentStep - 1 < 0 ? 0 : state.currentStep - 1,
      };

    case types.SET_MONEY_TRANSFER_SUM:
      return {
        ...state,
        payment: {
          ...state.payment,
          sum: action.payload.sum,
        },
      };

    case types.SET_MONEY_TRANSFER_MESSAGE:
      return {
        ...state,
        payment: {
          ...state.payment,
          message: action.payload.message,
        },
      };

    case types.SET_MONEY_TRANSFER_CARD_OR_WALLET_NUMBER:
      return {
        ...state,
        payment: {
          ...state.payment,
          cardOrWalletNumber: action.payload.number,
        },
      };

    case types.SET_MONEY_TRANSFER_SBP_BANK:
      return {
        ...state,
        payment: {
          ...state.payment,
          sbpBank: {
            id: action.payload.id,
            name: action.payload.name,
          },
        },
      };

    case types.CHANGE_MONEY_TRANSFER_CONFIRMATION_METHOD:
      return {
        ...state,
        payment: {
          ...state.payment,
          confirmationMethod: action.payload.method,
        },
      };

    case types.GET_MONEY_TRANSFER_BANK_CARD_INFO_FETCHING:
      return {
        ...state,
        getMoneyTransferBankCardInfo: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_MONEY_TRANSFER_BANK_CARD_INFO_SUCCESS:
      return {
        ...state,
        getMoneyTransferBankCardInfo: {
          fetching: false,
          success: true,
          fail: false,
        },
        bankCardInfo: { ...action.payload },
      };

    case types.GET_MONEY_TRANSFER_BANK_CARD_INFO_FAIL: {
      return {
        ...state,
        getMoneyTransferBankCardInfo: {
          fetching: false,
          success: false,
          fail: true,
        },
        bankCardInfo: {
          name: '',
          nameEn: '',
          backgroundColor: '',
          backgroundColors: [],
          redError: false,
          whiteLogoColor: false,
          logo: '',
          bankCardType: '',
        },
      };
    }

    default:
      return state;
  }
};

export default moneyTransferReducer;
