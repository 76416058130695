import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { createPortal } from 'react-dom';

// Components
import Loader from 'modules/authLegasy/components/Loader/Loader';
import BusinessCard from 'modules/profileLegasy/components/BusinessCard/BusinessCard';

// Images
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './BusinessCardPopupContainer.scss';
import { checkPaymentEnabled, PaymentDisabledPopup } from '../../../../tools/utils/payments';
import {
  checkPaymentOnlyAuthorizedIdentifyEnabled,
  OnlyAuthorizedIdentifyPopup,
} from '../../../../tools/utils/only-authorized';

const BusinessCardPopupContainer = ({ closeDrop, user, getBusinessCardInfo }) => {
  const containerRef = useRef();

  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const url = `${window.location.origin}${process.env.PUBLIC_URL}/to/${user.randomUniqueId}`;
  /** Узнаем детали кошелька пользователя по уникальному id юзера */
  useEffect(() => {
    setIsLoading(true);
    getBusinessCardInfo(user.randomUniqueId)
      .then((res) => {
        try {
          setUserData(res.data);

          /**
           * Записываем аватар в localStorage, чтобы после
           * возвращения с 3ds он отображался правильно
           *  */
          window.localStorage.setItem(
            'businessCardAvatar',
            res.data && res.data.avatar ? `${res.data.avatar}` : stdAvatar,
          );
        } catch (_error) {
          setError('К сожалению, пользователь с таким идентификатором не найден');
        }
      })
      .catch(() => {
        setError('К сожалению, пользователь с таким идентификатором не найден');
      })
      .then(() => {
        setIsLoading(false);
      });
    // }, [getBusinessCardInfo, user.randomUniqueId]);
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  /** Блочим body */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  /** Навешивание событий для аутклик */
  useEffect(() => {
    const containerDOMNode = containerRef.current;
    if (containerDOMNode) {
      containerDOMNode.addEventListener('click', handleContainerClick);
    }
    return () => {
      if (containerDOMNode) {
        containerDOMNode.removeEventListener('click', handleContainerClick);
      }
    };
  });

  /** Хэндлер для аутклика */
  const handleContainerClick = (event) => {
    if (event.target === containerRef.current) {
      closeDrop();
    }
  };

  return createPortal(
    <div>
      <PaymentDisabledPopup user={user} redirect={'/lk'} onClose={closeDrop} />
      {checkPaymentEnabled() && (
        <>
          <OnlyAuthorizedIdentifyPopup
            user={user}
            redirect={'/lk'}
            noHistoryBack
            onClose={closeDrop}
          />
          {checkPaymentOnlyAuthorizedIdentifyEnabled(user) && (
            <div className="business-card-popup-container" ref={containerRef}>
              <div className="business-card-popup-container__inner">
                {isLoading && <Loader className="business-card-popup-container__loader" inverted />}

                {error && (
                  <div className="business-card-popup-container__error">
                    <p className="business-card-popup-container__error-text">{error}</p>
                  </div>
                )}

                {!isLoading && !error && userData && (
                  <BusinessCard
                    closeDrop={closeDrop}
                    isOpenByMe
                    userData={userData}
                    url={url}
                    userAvatar={
                      window &&
                      window.localStorage &&
                      window.localStorage.getItem('businessCardAvatar')
                    }
                  />
                )}
              </div>
            </div>
          )}
        </>
      )}
    </div>,
    document.body,
  );
};

BusinessCardPopupContainer.propTypes = {
  closeDrop: PropTypes.func.isRequired,
  user: PropTypes.shape({
    randomUniqueId: PropTypes.string,
  }).isRequired,
  getBusinessCardInfo: PropTypes.func.isRequired,
};

export default BusinessCardPopupContainer;
