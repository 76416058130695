import { getShowCase } from 'api/cashbox';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt, Redirect, useLocation, useParams } from 'react-router-dom';

import { getShowCaseTransactionOperationStatus } from 'modules/cashbox/actions';
import ShowCasePreview from 'modules/cashbox/components/ShowCasePreview/ShowCasePreview';
import { getInvoiceTransactionCheck } from 'modules/profileLegasy/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import downloadFile from 'helpers/downloadFile';

import './ShowCase.scss';
import { maxSumDefault } from '../../../../tools/constants';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

const ShowCase = ({ user }) => {
  const location = useLocation();
  const { operationId, showCaseId, registeredCheckId, registeredCheckHash } = useParams();

  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isReplenishmentBlocked, setIsReplenishmentBlocked] = useState(false);
  const [isNotAvailable, setIsNotAvailable] = useState(false);

  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [showCaseData, setShowCaseData] = useState({});

  const [isSending, setIsSending] = useState(false);

  const [redirectToErrorPage, setRedirectToErrorPage] = useState(null);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(null);
  const [redirectToSuccessPageNoCheck, setRedirectToSuccessPageNoCheck] = useState(null);

  const isMoneyTransferOk = Boolean(registeredCheckId && registeredCheckHash);
  const isMoneyTransferOkNoCheck = location.pathname.indexOf('not-register-deal') > -1;
  const isMoneyTransferFail =
    location.pathname.indexOf(`/pay-it/cashbox/${operationId}/error`) > -1 ||
    location.pathname === '/pay-it/cashbox/error' ||
    location.pathname === `/pay-it/cashbox/${showCaseId}/error`;

  const [currentShowCaseId, setCurrentShowCaseId] = useState(showCaseId);
  const [amountFromLocalStorage, setAmountFromLocalStorage] = useState(0);

  const [isCheckLoading, setIsCheckLoading] = useState(false);
  const [isCheckError, setIsCheckError] = useState(false);
  const [checkData, setCheckData] = useState(null);

  const getShowCaseById = () => {
    setIsLoading(true);

    getShowCase(currentShowCaseId)
      .then((res) => {
        try {
          if (!res.data) {
            throw new Error();
          }
          setShowCaseData(res.data);

          if (res.data && res.data.clientBlockedByAdmin) {
            setIsUserBlocked(true);
          } else if (res.data && res.data.clientBlockedReceipt) {
            setIsReplenishmentBlocked(true);
          } else if (res.data && !res.data.available) {
            setIsNotAvailable(true);
          }

          setIsLoading(false);
          window.sessionStorage.setItem(
            'showCaseData',
            JSON.stringify({ ...res.data, cashBoxId: currentShowCaseId }),
          );
        } catch (_error) {
          setIsError(true);
        }
      })
      .catch(() => {
        setIsError(true);
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!currentShowCaseId) {
      return;
    }

    if (
      location.pathname.indexOf('/pay-it/cashbox/') <= -1 ||
      location.pathname === `/pay-it/cashbox/${showCaseId}/error`
    ) {
      getShowCaseById();
    } else if (window.sessionStorage && window.sessionStorage.getItem('showCaseData')) {
      const showCaseDataFromStorage = JSON.parse(window.sessionStorage.getItem('showCaseData'));
      setShowCaseData(showCaseDataFromStorage);
    } else {
      getShowCaseById();
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [currentShowCaseId, location.pathname, window.sessionStorage]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const updateTimeout = useRef(null);

  const updateOperationStatus = useCallback(() => {
    if (!(operationId && location.pathname.indexOf(`/pay-it/cashbox/${operationId}/wait`) > -1)) {
      clearTimeout(updateTimeout.current);
      return;
    }

    setIsSending(true);
    getShowCaseTransactionOperationStatus(operationId)
      .then((res) => {
        if (res && res.data) {
          setCurrentShowCaseId(res.data.uuid);

          if (res.data.state === STATUS_WAIT) {
            updateTimeout.current = setTimeout(updateOperationStatus, 1000);
          } else if (res.data.state === STATUS_ERROR) {
            setRedirectToErrorPage(`/pay-it/cashbox/${operationId}/error/${res.data.uuid}`);
          } else if (res.data.state === STATUS_SUCCESS) {
            if (res.data.url) {
              setRedirectToSuccessPage(`${res.data.url}/${res.data.uuid}`);
              // setRedirectToSuccessPage(`/pay-it/cashbox/${operationId}/success/${res.data.uuid}`);
            } else {
              setRedirectToSuccessPageNoCheck(
                `/pay-it/cashbox/${operationId}/not-register-deal/${res.data.uuid}`,
              );
            }
          }

          if (res.data.transactionStateMessage) {
            window.sessionStorage.setItem(
              'transactionStateMessage',
              res.data.transactionStateMessage,
            );
          }
        }
      })
      .catch(() => {
        setRedirectToErrorPage(true);
      });
  }, [location.pathname, operationId]);

  /** Узнаем результаты проведения операции при нахождении на /wait/ */
  useEffect(() => {
    updateOperationStatus();
    return () => {
      clearTimeout(updateTimeout.current);
    };
  }, [updateOperationStatus, location.pathname, operationId]);

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    if (isSending) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  useEffect(() => {
    if (window && window.sessionStorage) {
      setAmountFromLocalStorage(+window.sessionStorage.getItem('cashBoxTransferAmount'));
    }
  }, []);

  /** Запрашиваем чек при нахождении на соотв. урле */
  useEffect(() => {
    if (registeredCheckId && registeredCheckHash) {
      setIsCheckLoading(true);

      getInvoiceTransactionCheck(registeredCheckId, registeredCheckHash)
        .then((res) => {
          if (res && res.data && res.data.id) {
            setCheckData(res.data);
          }
        })
        .catch(() => {
          setIsCheckError(true);
        })
        .then(() => {
          setIsCheckLoading(false);
        });
    }
  }, [location.pathname, registeredCheckId, registeredCheckHash]);

  /** Запрос на генерацию PDF для скачивания чека */
  const handleDownloadInvoice = () => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'arraybuffer';
    xhr.open('GET', checkData.link, true);

    xhr.onreadystatechange = () => {
      if (xhr.readyState === xhr.DONE) {
        const file = new Blob([xhr.response], { type: 'image/jpeg' });
        downloadFile(file, `${checkData.id}.jpg`);
      }
    };

    xhr.send();
  };

  if (redirectToErrorPage) {
    return <Redirect to={redirectToErrorPage} />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  if (redirectToSuccessPageNoCheck) {
    return <Redirect to={redirectToSuccessPageNoCheck} />;
  }

  if (isError) {
    return (
      <div className="money-request-container__error">
        <p className="money-request-container__error-text">
          К сожалению, витрина с таким идентификатором не найдена.
        </p>
      </div>
    );
  }

  return (
    <div className="show-case">
      {/* ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}
      <Prompt
        when={isSending}
        message="Операция выполняется, вы уверены что хотите уйти со страницы не дожидаясь результата?"
      />
      {/* /ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}

      {isLoading ? (
        <div className="show-case__loader">
          <CircleLoader />
        </div>
      ) : (
        <ShowCasePreview
          name={showCaseData.name}
          sumHint={showCaseData.sumHint}
          sum={showCaseData.sum}
          maxLimitSum={showCaseData.maxLimitSum || maxSumDefault}
          isNameVisible={Boolean(showCaseData.fio)}
          isPhoneVisible={Boolean(showCaseData.phone)}
          isEmailVisible={Boolean(showCaseData.email)}
          isPreview={false}
          fio={showCaseData.fio}
          userLogin={showCaseData.phone}
          userEmail={showCaseData.email}
          isUserLoggedIn={Boolean(user)}
          showCaseId={showCaseId}
          loginLastChars={showCaseData.loginLastChars}
          isMoneyTransferOk={isMoneyTransferOk}
          isMoneyTransferOkNoCheck={isMoneyTransferOkNoCheck}
          isMoneyTransferFail={isMoneyTransferFail}
          isSending={isSending}
          urlForRepeatPayment={`/kassa/${showCaseData.cashBoxId || showCaseId}` || null}
          amountFromLocalStorage={amountFromLocalStorage}
          isCheckLoading={isCheckLoading}
          checkData={checkData}
          isCheckError={isCheckError}
          handleDownloadInvoice={handleDownloadInvoice}
          isUserBlocked={isUserBlocked}
          isReplenishmentBlocked={isReplenishmentBlocked}
          isNotAvailable={isNotAvailable}
          isPayerEmailNeeded={showCaseData.needEmail}
          isPayerNameNeeded={showCaseData.needFio}
          isPayerPhoneNeeded={showCaseData.needPhone}
        />
      )}
    </div>
  );
};

ShowCase.propTypes = {
  user: PropTypes.shape({}),
};

ShowCase.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(ShowCase);
