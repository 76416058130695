import React from 'react';
import { Switch, Route } from 'react-router-dom';

import PartnerAboutTab from '../PartnerAboutTab';
import PartnerDocumentsTab from '../PartnerDocumentsTab';
import PartnerPaymentsTab from '../PartnerPaymentsTab';

import styles from './PartnerNavContainer.module.scss';

const PartnerNavContainer = () => {
  return (
    <div className={styles.partnerNavContainer}>
      <Switch>
        <Route path="/lk/partner/:platformName/:partnershipId" exact>
          <PartnerAboutTab />
        </Route>
        <Route path="/lk/partner/:platformName/:partnershipId/documents" exact>
          <PartnerDocumentsTab />
        </Route>
        <Route path="/lk/partner/:platformName/:partnershipId/payments" exact>
          <PartnerPaymentsTab />
        </Route>
      </Switch>
    </div>
  );
};

export default PartnerNavContainer;
