import cx from 'classnames';
import { validatePhoneNumber } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useRecaptcha } from 'react-recaptcha-hook';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { ButtonUI } from 'ui';
import { requestSMS } from 'modules/authLegasy/actions';
import RegistrationPhoneInput from 'modules/authLegasy/components/RegistrationPhoneInput/RegistrationPhoneInput';
import StandardHoverButton from 'modules/shared/components/StandardHoverButton/StandardHoverButton';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

const RegistrationEnterPhone = ({
  className,
  phone,
  onNextStepButtonClick,
  dispatch,
  isMenuOpen,
}) => {
  const [isValid, setIsValid] = useState(false);
  const [isWrongPhoneMessageShown, setIsWrongPhoneMessageShown] = useState(false);
  const [isAgreementCheckboxChecked, setIsAgreementCheckboxChecked] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  /** Инициализация капчи */
  const captchaExecute = useRecaptcha({
    sitekey: process.env.REACT_APP_RECAPTCHA_SITE_KEY,
    hideDefaultBadge: false,
  });

  const [isRendered, setIsRendered] = useState(false);

  /** Удаляем скрипты и элемент с капчей если уходим с этого щага регистрации */
  useEffect(() => {
    return () => {
      const nodeBadge = document.querySelector('.grecaptcha-badge');
      if (nodeBadge) {
        document.body.removeChild(nodeBadge.parentNode);
      }

      const scripts = document.querySelectorAll("script[src*='recaptcha");
      if (scripts) {
        for (let i = 0; i < scripts.length; i += 1) {
          scripts[i].remove();
        }
      }
    };
  }, []);

  useEffect(() => {
    if (String(process.env.REACT_APP_SHOW_AGREEMENT).toLowerCase() !== 'true') {
      setIsAgreementCheckboxChecked(true);
    }
  }, []);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Проверяем телефон */
  useEffect(() => {
    setIsValid(phone && phone.length === 11 && validatePhoneNumber(phone));

    setIsWrongPhoneMessageShown(
      Boolean(phone && phone.length === 11) && !validatePhoneNumber(phone),
    );
  }, [phone]);

  /** Request sms */
  const handleClick = () => {
    setIsLoading(true);
    setErrorMessage(null);

    captchaExecute('RegistrationEnterPhone')
      .then((token) => dispatch(requestSMS(phone, token)))
      .then(() => {
        setIsLoading(false);

        if (window && window.yaCounter64391668) {
          window.yaCounter64391668.reachGoal('3click_wallet');
        }

        onNextStepButtonClick();
      })
      .catch((result) => {
        if (result && result.result && result.result.message === 'Forbidden') {
          setErrorMessage(
            'Упс! Что-то пошло не так. Пожалуйста, попробуйте обновить страницу и отправить запрос снова',
          );
        } else {
          setErrorMessage(
            (result && result.result && result.result.message) ||
              'Произошла ошибка. Пожалуйста, обновите страницу и попробуйте снова',
          );
        }

        setIsLoading(false);
      });
  };

  return (
    <div
      className={cx('register', {
        [className]: className,
      })}
    >
      <div className="register__container container">
        <div className="register__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="register__title">Открытие нового счета</h1>
          </CSSTransition>

          <div className="register__desc-wrap">
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="register__desc">
                Укажите номер телефона. Мы пришлем СМС с кодом на этот номер
              </div>
            </CSSTransition>
          </div>

          <div className="register__form">
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
              unmountOnExit
            >
              <RegistrationPhoneInput isValid={Boolean(isValid)} />
            </CSSTransition>

            {/* КНОПКА ДАЛЕЕ НА МОБАЙЛЕ */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <StandardHoverButton
                title="Подтвердить"
                isLink={false}
                disabled={!isAgreementCheckboxChecked || !isValid || isLoading}
                className="register__hover-button"
                onClick={handleClick}
                isLoading={isLoading}
              />
            </CSSTransition>
            {/* /КНОПКА ДАЛЕЕ НА МОБАЙЛЕ */}

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={Boolean(errorMessage)}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="form__error-message">{errorMessage}</div>
            </CSSTransition>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isWrongPhoneMessageShown}
              timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
              unmountOnExit
            >
              <div className="form__error-message">
                Неверный формат номера. Проверьте правильность введенных данных.
              </div>
            </CSSTransition>

            {String(process.env.REACT_APP_SHOW_AGREEMENT).toLowerCase() === 'true' && (
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <div className="form__agreement-wrap register__form__agreement">
                  <div className="form__agreement">
                    <button
                      className="form__agreement-checkbox"
                      role="checkbox"
                      type="button"
                      aria-label="Я принимаю пользовательское соглашение"
                      aria-checked={isAgreementCheckboxChecked}
                      onClick={() => setIsAgreementCheckboxChecked((prevState) => !prevState)}
                      data-cy="register-agreement-checkbox"
                    />
                    <div className="form__agreement-text-wrap">
                      <span className="form__agreement-text i-agree">Я принимаю:</span>
                      <ul>
                        <li>
                          <a
                            href="https://самозанятые.рф/legal/walletterms.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cx('form__agreement-text', {
                              checked: isAgreementCheckboxChecked,
                            })}
                          >
                            пользовательское соглашение
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://самозанятые.рф/legal/rules-communication-customer-executor.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cx('form__agreement-text')}
                          >
                            правила взаимодействия заказчика и исполнителя
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://самозанятые.рф/legal/walletoffer.pdf"
                            target="_blank"
                            rel="noopener noreferrer"
                            className={cx('form__agreement-text')}
                          >
                            оферту на оказание услуг по осуществлению переводов
                          </a>
                        </li>
                      </ul>
                      {/*<span className="form__agreement-text">Я принимаю</span>*/}
                      {/*<a*/}
                      {/*  href="https://самозанятые.рф/legal/walletterms.pdf"*/}
                      {/*  target="_blank"*/}
                      {/*  rel="noopener noreferrer"*/}
                      {/*  className={cx('form__agreement-text', {*/}
                      {/*    checked: isAgreementCheckboxChecked,*/}
                      {/*  })}*/}
                      {/*>*/}
                      {/*  пользовательское соглашение*/}
                      {/*</a>*/}

                      {/*<span className="form__agreement-text"> и </span>*/}
                      {/*<a*/}
                      {/*  href="https://самозанятые.рф/legal/rules-communication-customer-executor.pdf"*/}
                      {/*  target="_blank"*/}
                      {/*  rel="noopener noreferrer"*/}
                      {/*  className={cx('form__agreement-text')}*/}
                      {/*>*/}
                      {/*  правила взаимодействия заказчика и исполнителя*/}
                      {/*</a>*/}
                      {/*<span className="form__agreement-text">, а так же </span>*/}
                      {/*<a*/}
                      {/*  href="https://самозанятые.рф/legal/walletoffer.pdf"*/}
                      {/*  target="_blank"*/}
                      {/*  rel="noopener noreferrer"*/}
                      {/*  className={cx('form__agreement-text')}*/}
                      {/*>*/}
                      {/*  оферту на оказание услуг по осуществлению переводов*/}
                      {/*</a>*/}
                    </div>
                  </div>
                </div>
              </CSSTransition>
            )}

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
              unmountOnExit
            >
              <div className={'register__form__open-login'}>
                <span>
                  У вас уже есть профиль?{' '}
                  {<ButtonUI.RouteLink to={'/login'}>Войдите</ButtonUI.RouteLink>}
                </span>
              </div>
            </CSSTransition>
          </div>
        </div>
      </div>
    </div>
  );
};

RegistrationEnterPhone.propTypes = {
  onNextStepButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  phone: state.auth.phone,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(RegistrationEnterPhone);
