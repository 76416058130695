import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import Hammer from 'hammerjs';

// Components
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Icons
import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';

// Styles
import './MonthNavigation.scss';

const monthsRU = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

const MonthNavigation = props => {
  const {
    handlePrevMonthClick,
    handleNextMonthClick,
    month,
    year,
    className,
    isLoading,
    isPrevButtonDisabled,
    isNextButtonDisabled,
    isStartDateLoading,
  } = props;

  const container = useRef(null);

  /** Навешиваем события свайпа */
  useEffect(() => {
    const ref = container.current;
    let manager;
    if (ref) {
      manager = new Hammer.Manager(ref);
      const swipe = new Hammer.Swipe();
      manager.add(swipe);

      manager.on('swipe', e => {
        const direction = e.offsetDirection;
        if (direction === 2 && !isPrevButtonDisabled) {
          handlePrevMonthClick();
        }
        if (direction === 4 && !isNextButtonDisabled) {
          handleNextMonthClick();
        }
      });
    }

    return () => {
      if (ref && manager) {
        manager.off('swipe');
      }
    };
  }, [handleNextMonthClick, handlePrevMonthClick, isPrevButtonDisabled, isNextButtonDisabled]);

  return (
    <div
      className={cx('month-navigation', {
        [className]: className,
      })}
      ref={container}
    >
      <button
        className={cx('month-navigation__btn month-navigation__btn_prev', {
          'month-navigation__btn_invisible': isPrevButtonDisabled,
        })}
        type="button"
        onClick={handlePrevMonthClick}
        disabled={isLoading || isPrevButtonDisabled}
        aria-label="Предыдущий месяц"
      >
        <IconArrow className="month-navigation__btn-icon" />
      </button>

      {isStartDateLoading ? (
        <CircleLoader
          width={15}
          height={15}
          borderWidth={1}
          mainColor="rgba(40, 65, 149, 0.1)"
          spinnerColor="#b0b5c4"
          className="deals__loading-loader"
        />
      ) : (
        <p className="month-navigation__date">
          {monthsRU[month - 1]} {year}
        </p>
      )}

      <button
        className={cx('month-navigation__btn month-navigation__btn_next', {
          'month-navigation__btn_invisible': isNextButtonDisabled,
        })}
        type="button"
        onClick={handleNextMonthClick}
        disabled={isLoading || isNextButtonDisabled}
        aria-label="Следующий месяц"
      >
        <IconArrow className="month-navigation__btn-icon" />
      </button>
    </div>
  );
};

MonthNavigation.propTypes = {
  handlePrevMonthClick: PropTypes.func.isRequired,
  handleNextMonthClick: PropTypes.func.isRequired,
  month: PropTypes.number,
  year: PropTypes.number,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isPrevButtonDisabled: PropTypes.bool,
  isNextButtonDisabled: PropTypes.bool,
  isStartDateLoading: PropTypes.bool,
};

MonthNavigation.defaultProps = {
  className: null,
  isLoading: false,
  isPrevButtonDisabled: false,
  isNextButtonDisabled: false,
  isStartDateLoading: false,
  month: null,
  year: null,
};

export default MonthNavigation;
