import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './TextareaWithLabelAnimation.scss';

const TextareaWithLabelAnimation = ({
  className,
  label,
  value,
  onChange,
  disabled,
  placeholder,
  children,
  customRightPadding,
  maxLength,
}) => {
  return (
    <div
      className={cx('textarea-with-label-animation', {
        [className]: className,
        'textarea-with-label-animation_focused': value !== '',
      })}
      data-testid="textarea-holder"
    >
      <textarea
        className="textarea-with-label-animation__textarea"
        value={value}
        onChange={(event) => onChange(event)}
        disabled={disabled}
        placeholder={placeholder}
        style={{ paddingRight: `${customRightPadding}px` }}
        cols="30"
        rows="10"
        maxLength={maxLength}
        aria-label="textarea"
      />
      <p className="textarea-with-label-animation__label">{label}</p>

      {children}
    </div>
  );
};

TextareaWithLabelAnimation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  customRightPadding: PropTypes.number,
  maxLength: PropTypes.number,
};

TextareaWithLabelAnimation.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  onChange: () => {},
  placeholder: '',
  customRightPadding: null,
  maxLength: 10000,
};

export default TextareaWithLabelAnimation;
