/*
  Компонент со ссылками на приложения в магазинах:
  - Google Play
  - App Store
*/

import React from 'react';

// Icons
import { ReactComponent as IconAppStore } from 'static/assets/icon-app-store.svg';
import { ReactComponent as IconPlayStore } from 'static/assets/icon-play-store.svg';

// Styles
import './FooterAppLinks.scss';

const FooterAppLinks = () => (
  <div className="footer-items">
    <div className="footer-app-links">
      <p className="footer-app-links__text">Мы в Google Play и AppStore</p>
      <div className="footer-app-links__list">
        <a
          href="https://apps.apple.com/ru/app/id1474600470"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-app-links__link"
        >
          <IconAppStore className="footer-app-links__icon footer-app-links__icon_app-store" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.selfwork.android"
          target="_blank"
          rel="noopener noreferrer"
          className="footer-app-links__link"
        >
          <IconPlayStore className="footer-app-links__icon footer-app-links__icon_play-store" />
        </a>
      </div>
    </div>
  </div>
);

export default FooterAppLinks;
