import PropTypes from 'prop-types';
import React, { memo, useEffect } from 'react';
import { shallowEqual, useDispatch } from 'react-redux';
import styled from 'styled-components';
import { CircleLoader } from 'ui';
import { PTClassNames } from 'tools/types/propTypes';

import {
  addStatusFilterAction,
  clearAction,
  documentListGetRequestAPIThunk,
  useCompanyNameFilterSelector,
  useErrorSelector,
  useHasDocumentsSelector,
  useIsDocumentListLoadingFailSelector,
  useIsDocumentListLoadingFetchingSelector,
  useIsDocumentListLoadingSuccessSelector,
  useIsFetchingByFilterSelector,
  usePartnerIdFilterSelector,
  useStatusFilterSelector,
  usePlatformNameFilterSelector,
} from '../../models/documentList';
import { DOC_STATUSES } from '../../utils/constants';
import { WidgetWraper } from '../ui';
import { ContentTable } from './containers';
import { Dropdown, NotDocuments } from './ui';

export const DocumentsTabledWidget = memo(
  ({
    className,
    isNotWidgetTitle,
    includesColumns,
    companyNameDefaultFilter,
    stateDefaultFilter,
    partnerIdDefaultFilter,
    platformNameDefaultFilter,
  }) => {
    const isDocumentListLoadingFetching = useIsDocumentListLoadingFetchingSelector();
    const isDocumentListLoadingSuccess = useIsDocumentListLoadingSuccessSelector();
    const isDocumentListLoadingFail = useIsDocumentListLoadingFailSelector();
    const hasDocuments = useHasDocumentsSelector();
    const companyNameFilter = useCompanyNameFilterSelector();
    const statusIdFilter = useStatusFilterSelector();
    const partnerIdFilter = usePartnerIdFilterSelector();
    const platformNameFilter = usePlatformNameFilterSelector();
    const idFetchingByFilter = useIsFetchingByFilterSelector();
    const error = useErrorSelector();

    const dispatch = useDispatch();

    /* eslint-disable-next-line react-hooks/exhaustive-deps */
    useEffect(() => () => dispatch(clearAction()), []);

    useEffect(() => {
      dispatch(
        documentListGetRequestAPIThunk({
          companyName: companyNameFilter || companyNameDefaultFilter || null,
          state: statusIdFilter || stateDefaultFilter || null,
          partnerId: partnerIdFilter || partnerIdDefaultFilter || null,
          platformName: platformNameFilter || platformNameDefaultFilter || null,
        }),
      );
      // eslint-disable-next-line
    }, [
      companyNameFilter,
      statusIdFilter,
      partnerIdFilter,
      platformNameFilter,
      companyNameDefaultFilter,
      stateDefaultFilter,
      partnerIdDefaultFilter,
      platformNameDefaultFilter,
    ]);

    return (
      <WidgetWraper
        title={!isNotWidgetTitle && 'Документы'}
        disabledOfSwitchBack
        className={className}
      >
        <Dropdown
          title="Статус"
          values={Object.fromEntries(DOC_STATUSES)}
          currentValue={statusIdFilter}
          placeholder="Любой"
          changeValue={(status) =>
            dispatch(addStatusFilterAction(!(status === 'ANY') ? status : null))
          }
        />
        <TableStyled>
          {!!hasDocuments && (
            <ContentTable
              onLoadDocumentList={() =>
                dispatch(
                  documentListGetRequestAPIThunk({
                    companyName: companyNameFilter || companyNameDefaultFilter || null,
                    state: statusIdFilter || stateDefaultFilter || null,
                    partnerId: partnerIdFilter || partnerIdDefaultFilter || null,
                    platformName: platformNameFilter || platformNameDefaultFilter || null,
                  }),
                )
              }
              includesColumns={includesColumns}
            />
          )}
          {((isDocumentListLoadingSuccess && !hasDocuments) ||
            (isDocumentListLoadingFail && error)) && <NotDocuments text={error} />}
          {isDocumentListLoadingFetching && !idFetchingByFilter && <CircleLoaderStyled />}
        </TableStyled>
      </WidgetWraper>
    );
  },
  shallowEqual,
);

DocumentsTabledWidget.propTypes = {
  includesColumns: PropTypes.shape({
    documentName: PropTypes.bool,
    companyName: PropTypes.bool,
    data: PropTypes.bool,
    state: PropTypes.bool,
  }),
  companyNameDefaultFilter: PropTypes.bool || null,
  stateDefaultFilter: PropTypes.bool || null,
  partnerIdDefaultFilter: PropTypes.number || null,
  isNotWidgetTitle: PropTypes.bool,
  className: PTClassNames,
};

DocumentsTabledWidget.defaultProps = {
  includesColumns: {
    documentName: true,
    companyName: true,
    data: true,
    state: true,
  },
  companyNameDefaultFilter: null,
  stateDefaultFilter: null,
  partnerIdDefaultFilter: null,
  isNotWidgetTitle: false,
  className: '',
};

const TableStyled = styled.div`
  position: relative;
  margin-top: 13px;
  min-height: 320px;
  border-radius: 12px;
  box-shadow: 0 16px 60px rgba(0, 22, 98, 0.07);
  background-color: var(--white);
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
  @media (max-width: 600px) {
    border-radius: 0;
    box-shadow: none;
    background-color: transparent;
  }
`;

const CircleLoaderStyled = styled(CircleLoader)`
  margin: 143px auto;
`;
