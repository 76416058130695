import React from 'react';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';

// Styles
import './Warning.scss';
import cx from 'classnames';

const WarningText = (props) => {
  const { text, className } = props;
  return (
    <div
      className={cx('warning warning_text', {
        [className]: className,
      })}
    >
      <div className="warning__container">
        <IconInfo className="warning__icon" />
        <div className="warning__text">{text}</div>
      </div>
    </div>
  );
};

WarningText.propTypes = {
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
};

WarningText.defaultProps = {
  className: null,
};

export default WarningText;
