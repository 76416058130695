import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './CheckboxWithLabel.scss';

const CheckboxWithLabel = ({ ariaLabel, onClick, title, isChecked, className }) => {
  return (
    <label
      className={cx('checkbox-with-label', {
        [className]: className,
      })}
      data-testid="checkbox-with-label"
    >
      <button
        className="checkbox-with-label__checkbox"
        role="checkbox"
        type="button"
        aria-label={ariaLabel}
        aria-checked={isChecked}
        onClick={onClick}
      />

      <div className="checkbox-with-label__text-wrap">
        <p className="checkbox-with-label__text">{title}</p>
      </div>
    </label>
  );
};

CheckboxWithLabel.propTypes = {
  ariaLabel: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

CheckboxWithLabel.defaultProps = {
  className: null,
};

export default CheckboxWithLabel;
