/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import format from 'date-fns/format';
import useTimer from 'hooks/useTimer';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo } from 'react';
import styled from 'styled-components';
import { LineText, Link } from 'ui';

export const ReSendInfoOfChangeStatus = ({ isOnTimer, time, onResendSMS }) => {
  const [onTimer, currentTime, isTimer] = useTimer();

  const currentTimeFormated = useMemo(
    () => Number(currentTime) && format(new Date(0, 0, 0, 0, 0, currentTime), 'mm:ss'),
    [currentTime],
  );

  useEffect(() => {
    if (isOnTimer && time) onTimer(time);
  }, [isOnTimer, time, onTimer]);

  return (
    <>
      {isTimer && (
        <LineTextStopWatchStyled>
          Введите код из СМС. <u>Запросить повторно можно через {currentTimeFormated}</u>
        </LineTextStopWatchStyled>
      )}
      {!isTimer && (
        <LineTextStopWatchStyled>
          {'Введите код из СМС. '}
          <LinkStyled onClick={() => onResendSMS()}>
            <u>Запросить повторно</u>
          </LinkStyled>
        </LineTextStopWatchStyled>
      )}
    </>
  );
};

ReSendInfoOfChangeStatus.propTypes = {
  isOnTimer: PropTypes.bool.isRequired,
  time: PropTypes.number.isRequired,
  onResendSMS: PropTypes.func.isRequired,
};

const LinkStyled = styled(Link)`
  * {
    font-size: 14px;
    line-height: 20px;
    color: #616a88;
  }
`;

const LineTextStopWatchStyled = styled(LineText)`
  font-size: 14px;
  line-height: 20px;
  color: #b0b5c4;
`;
