import { LOG_OUT_SUCCESS } from 'modules/authLegasy/actions/action-types';
import * as types from 'modules/cashbox/actions/action-types';

const initialState = {
  moneyboxes: [],
  showCases: [],
  isUrlLinkError: false,
  isUrlLinkLoading: false,
};

const cashBoxReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.SAVE_MONEY_GET_ALL_MONEYBOX_SUCCESS:
      return {
        ...state,
        moneyboxes: action.payload,
      };

    case types.GET_ALL_SHOW_CASE_SUCCESS:
      return {
        ...state,
        showCases: action.payload.res.data,
      };

    case types.SAVE_MONEY_ADD_MONEYBOX_SUCCESS:
      return {
        ...state,
        moneyboxes: state.moneyboxes.concat(action.payload),
      };

    case types.DELETE_PAYIT_SUCCESS:
      return {
        ...state,
        moneyboxes:
          action.payload.payItType === 'MONEY_BOX'
            ? state.moneyboxes.filter((item) => item.uid !== action.payload.uid)
            : state.moneyboxes,
        showCases:
          action.payload.payItType === 'CASHBOX'
            ? state.showCases.filter((item) => item.uid !== action.payload.uid)
            : state.showCases,
      };

    case LOG_OUT_SUCCESS:
      return {
        ...state,
        moneyboxes: [],
        showCases: [],
        isUrlLinkError: false,
      };

    case types.SET_URL_LINK_ERROR:
      return {
        ...state,
        isUrlLinkError: action.payload.isUrlLinkError,
      };

    case types.SET_URL_LINK_LOADING:
      return {
        ...state,
        isUrlLinkLoading: action.payload.isUrlLinkLoading,
      };

    default:
      return state;
  }
};

export default cashBoxReducer;
