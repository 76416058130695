import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';

// Components
import AuthStatusResult from 'modules/shared/components/AuthStatusResult/AuthStatusResult';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

// Images
import profileBg from 'static/assets/profile-bg.png';

const AuthStatusAnotherInn = ({ className, resetAuthStep }) => {
  const [isRendered, setIsRendered] = useState(false);

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 100);
  }, []);

  return (
    <>
      <div
        className={cx('auth-status', {
          [className]: className,
        })}
        data-testid="auth-status-another-inn"
      >
        <CSSTransition
          classNames="animation-from-top-to-bottom"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="auth-status__header animation-from-top-to-bottom">
            <img src={profileBg} alt="Profile bg" className="auth-status__header-bg" />
          </div>
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="auth-status__content animation-from-bottom-to-top">
            <AuthStatusResult
              resetAuthStep={resetAuthStep}
              className="auth-status__fail auth-status__fail_another-inn"
              title="Такой пользователь уже зарегистрирован"
            >
              <div className="auth-status-result__desc">
                Возможно, причина в том, что ваш ИНН используется в другой учетной записи. Войдите
                под своей учетной записью или{' '}
                <span className="auth-status-result__desc js-help">
                  напишите письмо в поддержку клиентов
                </span>
                .
              </div>
            </AuthStatusResult>
          </div>
        </CSSTransition>
      </div>
    </>
  );
};

AuthStatusAnotherInn.propTypes = {
  className: PropTypes.string.isRequired,
  resetAuthStep: PropTypes.func.isRequired,
};

export default AuthStatusAnotherInn;
