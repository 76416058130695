import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';

import ProfileErrorMessage from 'modules/profileLegasy/components/ProfileErrorMessage/ProfileErrorMessage';
// import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CopiedUrlHover from 'modules/shared/components/CopiedUrlHover/CopiedUrlHover';
import PageTitle from 'modules/shared/components/PageTitle/PageTitle';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE, GREY_BORDER } from 'constants/index';

import { ReactComponent as IconCopy } from 'static/assets/icon-copy-content.svg';

import './AcquiringSettingsStep2.scss';

const LOADING = 'LOADING';
const FAIL = 'FAIL';

const AcquiringSettingsStep2 = ({
  returnBack,
  submitStep2,
  domain,
  confirmToken,
  domainConfirmationStatus,
  downloadFileForConfirmation,
  downloadFileStatus,
}) => {
  const code1 = '</head>';
  const code2 = `<meta name="selfwork.ru" content="${confirmToken}" />`;
  const [isCopied, setIsCopied] = useState(false);

  /** Клик на копирование ссылки */
  const copyClick = () => {
    setIsCopied(true);

    setTimeout(() => {
      setIsCopied(false);
    }, 2000);
  };

  return (
    <div className="acquiring-settings-step-2">
      <PageTitle className="acquiring-settings-step-2__title" title="Подтверждение магазина" />

      <div className="acquiring-settings-step-2__content">
        <div className="acquiring-settings-step-2__block">
          <div className="acquiring-settings-step-2__text">
            Процедура подтверждения магазина необходима для того, чтобы мы смогли удостовериться,
            что вы обладаете соответствующими правами на управление данным доменом. Подтвердить свои
            права на домен можно с помощью meta-тега.
          </div>
          <div className="acquiring-settings-step-2__text">
            Для подтверждения магазина вам необходим доступ к хостингу и работающий сайт на домене.
            Если у вас такого доступа нет, тогда обратитесь к вашем техническому специалисту.
          </div>
        </div>

        <div className="acquiring-settings-step-2__block">
          <h3 className="acquiring-settings-step-2__block-title">
            Подтверждение с помощью meta-тега
          </h3>

          <ul className="acquiring-settings-step-2__block-list">
            <li className="acquiring-settings-step-2__block-list-item">
              Добавьте перед закрывающим тэгом {'  '} {code1} {'  '} код:
              <CopyToClipboard
                text={code2}
                className="acquiring-settings-step-2__code-wrap"
                onCopy={() => {
                  copyClick(code2);
                }}
                data-testid="acquiring-settings-copy-code"
              >
                <button
                  type="button"
                  name="copyButton"
                  onClick={(event) => {
                    event.preventDefault();
                    event.stopPropagation();
                  }}
                >
                  <div className="acquiring-settings-step-2__code">{code2}</div>
                  <span className="acquiring-settings-step-2__icon-wrap">
                    <IconCopy className="acquiring-settings-step-2__code-copy-icon" />
                  </span>

                  <CopiedUrlHover isVisible={isCopied} />
                </button>
              </CopyToClipboard>
            </li>
            <li className="acquiring-settings-step-2__block-list-item">
              Нажмите кнопку подтвердить
            </li>
          </ul>
        </div>

        {/* <div className="acquiring-settings-step-2__block"> */}
        {/*   <h3 className="acquiring-settings-step-2__block-title"> */}
        {/*     Подтверждение с помощью html-файла */}
        {/*   </h3> */}

        {/*   <ul className="acquiring-settings-step-2__block-list"> */}
        {/*     <li className="acquiring-settings-step-2__block-list-item"> */}
        {/*       Скачайте txt-файл */}
        {/*       <div className="acquiring-settings-step-2__block-link-wrap"> */}
        {/*         <button */}
        {/*           type="button" */}
        {/*           onClick={downloadFileForConfirmation} */}
        {/*           className="acquiring-settings-step-2__block-link" */}
        {/*         > */}
        {/*           Скачать файл */}
        {/*         </button> */}

        {/*         {downloadFileStatus === LOADING && ( */}
        {/*           <CircleLoader */}
        {/*             className="acquiring-settings-step-2__loader" */}
        {/*             width={10} */}
        {/*             height={10} */}
        {/*             borderWidth={1} */}
        {/*           /> */}
        {/*         )} */}
        {/*       </div> */}
        {/*     </li> */}
        {/*     <li className="acquiring-settings-step-2__block-list-item"> */}
        {/*       Загрузите скачанный файл в корневой каталог вашего веб-сервера */}
        {/*     </li> */}
        {/*     <li className="acquiring-settings-step-2__block-list-item"> */}
        {/*       Убедитесь, что загруженный файл открывается по адресу: */}
        {/*       <div className="acquiring-settings-step-2__block-link-wrap"> */}
        {/*         <a */}
        {/*           href={`https://${domain}/${confirmToken}`} */}
        {/*           className="acquiring-settings-step-2__block-link" */}
        {/*         > */}
        {/*           https://{domain}/{confirmToken} */}
        {/*         </a> */}
        {/*       </div> */}
        {/*     </li> */}
        {/*     <li className="acquiring-settings-step-2__block-list-item"> */}
        {/*       Нажмите кнопку подтвердить */}
        {/*     </li> */}
        {/*   </ul> */}
        {/* </div> */}
      </div>

      <ProfileErrorMessage
        animationStart={domainConfirmationStatus === FAIL}
        className="acquiring-settings-step-2__error-message"
      >
        <div className="profile-error-message__text">
          <span className="profile-error-message__text profile-error-message__text_bold">
            Проверка не пройдена.
          </span>{' '}
          {'  '}
          meta-тег не найден. Возможно вы неверно указали протокол соединения - http(s), измените
          его на {'  '}
          <button type="button" onClick={returnBack} className="profile-error-message__link">
            предыдущем этапе
          </button>{' '}
          {'  '}и попробуйте еще раз. Если проверка все равно не проходит обратитесь в {'  '}
          <span className="js-help profile-error-message__link">службу поддержки</span> и
          ознакомьтесь с{' '}
          <span
            className="profile-error-message__link"
            onClick={() => window.open('https://docs.selfwork.ru/api/v/acquiring/', '_blank')}
          >
            документацией
          </span>
          .
        </div>
      </ProfileErrorMessage>

      <ProfileErrorMessage
        animationStart={downloadFileStatus === FAIL && domainConfirmationStatus !== FAIL}
        className="acquiring-settings-step-2__error-message"
      >
        <div className="profile-error-message__text">
          К сожалению, не удалось сгенерировать файл, повторите попытку позже. Если проблема не
          решится, обратитесь в {'  '}
          <span className="js-help profile-error-message__link">службу поддержки</span>.
        </div>
      </ProfileErrorMessage>

      <div className="acquiring-settings-step-2__buttons-list">
        <StandardButton
          title="Вернуться"
          isLink={false}
          onClick={returnBack}
          bgType={GREY_BORDER}
          className="acquiring-settings-step-2__buttons-item"
        />
        <StandardButton
          title="Подтвердить"
          isLink={false}
          onClick={submitStep2}
          bgType={BLUE}
          className="acquiring-settings-step-2__buttons-item"
          isLoading={domainConfirmationStatus === LOADING}
        />
      </div>
    </div>
  );
};

AcquiringSettingsStep2.propTypes = {
  returnBack: PropTypes.func.isRequired,
  submitStep2: PropTypes.func.isRequired,
  domain: PropTypes.string,
  confirmToken: PropTypes.string.isRequired,
  domainConfirmationStatus: PropTypes.string,
  downloadFileForConfirmation: PropTypes.func.isRequired,
  downloadFileStatus: PropTypes.string,
};

AcquiringSettingsStep2.defaultProps = {
  domainConfirmationStatus: null,
  downloadFileStatus: null,
  domain: '',
};

export default AcquiringSettingsStep2;
