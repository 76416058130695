import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTChildrenElement, PTClassNames, PTColor } from 'tools/types/propTypes';
import styled, { css } from 'styled-components';

import { LineText } from '../atoms';
import { TitleText } from '../molecules';

export const Link = ({
  children,
  onClick,
  url,
  width,
  title,
  color,
  size,
  disabled,
  className,
}) => {
  const onCurrentClick = useCallback(
    (event) => {
      if (!url) {
        event.preventDefault();
        onClick(event);
      }
    },
    [onClick, url],
  );

  return (
    <LinkStyled
      className={className}
      {...(url && !disabled ? { href: url } : {})}
      onClick={(e) => !url && !disabled && onCurrentClick(e)}
    >
      {title ? (
        <TitleText color={color} size={size}>
          {children}
        </TitleText>
      ) : (
        <LineText weight={width} color={color} size={size}>
          {children}
        </LineText>
      )}
    </LinkStyled>
  );
};

const LinkStyled = styled.a`
  ${({ disabled }) =>
    !disabled &&
    css`
      cursor: pointer;
    `}
`;

Link.propTypes = {
  children: PTChildrenElement.isRequired,
  onClick: PropTypes.func,
  url: PropTypes.string,
  width: PropTypes.string,
  title: PropTypes.bool,
  color: PTColor,
  size: PropTypes.string,
  disabled: PropTypes.bool,
  className: PTClassNames,
};

Link.defaultProps = {
  onClick: (f) => f,
  url: null,
  width: WEIGHT['circe-normal'],
  title: false,
  color: COLOR['grey-blue'],
  size: 'initial',
  disabled: false,
  className: '',
};
