import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';

import { AUTHORIZED_PROFILE_TAB, SZ_PROFILE_TAB } from 'constants/index';
import { changeLookOfPhone } from 'helpers';
import formatNumber from 'helpers/formatNumber';

import { getUser, logOut, setMenuOpen } from 'modules/authLegasy/actions';
import ProfileDropdown from 'modules/authLegasy/components/ProfileDropdown/ProfileDropdown';
import {
  getBusinessCardInfo,
  getUnreadMessages,
  markAllMessagesAsRead,
  readAllMessages,
  readExactMessage,
  setAuthStep,
  setProfileSettingsActiveTab,
  toggleBusinessCard,
} from 'modules/profileLegasy/actions';
import BusinessCardPopupContainer from 'modules/profileLegasy/components/BusinessCardPopupContainer/BusinessCardPopupContainer';
import NotificationsDropdown from 'modules/profileLegasy/components/NotificationsDropdown/NotificationsDropdown';
import HintNotifications from 'modules/shared/components/HintNotifications/HintNotifications';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Link, NavLink } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { ReactComponent as IconArrow } from 'static/assets/icon-dropdown-arrow.svg';
import { ReactComponent as IconNotifications } from 'static/assets/icon-notifications.svg';
import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';
import { ReactComponent as LogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as Logo } from 'static/assets/logo.svg';
import stdAvatar from 'static/assets/std-avatar.png';
import useToggleAndOutClick from 'use-toggle-and-outclick';

// Styles
import './Header.scss';

export const Header = (props) => {
  const {
    isMenuOpen,
    dispatch,
    user,
    unreadMessages,
    className,
    authStep,
    isBusinessCardOpen,
    szStatusFromDb,
    userTaxStatus,
    isOnGettingAuthStatus,
    isOnLkServices,
    isUserLoggedIn,
    isElementsWhite,
    isResponsiveElementsWhite,
    inNoShadow,
    isHidden,
    isDisabled,
  } = props;

  const [
    isProfileDropdownOpened,
    profileDropdownDropEl,
    profileDropdownToggleDrop,
    profileDropdownCloseExplicitly,
  ] = useToggleAndOutClick();

  const [
    isNotificationDropdownOpened,
    notificationDropdownDropEl,
    notificationDropdownToggleDrop,
    notificationDropdownCloseExplicitly,
  ] = useToggleAndOutClick();

  const [isNotificationOpened, setIsNotificationOpened] = useState(false);
  const [isNotificationEntered, setIsNotificationEntered] = useState(false);

  const [isNotificationsLoading, setIsNotificationsLoading] = useState(false);
  const [readMessages, setReadMessages] = useState({});

  const windowSize = useWindowSize();
  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/`;

  const zoneOffset = new Date().getTimezoneOffset();
  const [currentNotification, setCurrentNotification] = useState({});

  const notificationsTogglerRef = useRef();

  /** Click on burger to set isMenuOpen */
  const handleClick = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  /** Callback for log out from dropdown */
  const logout = () => {
    profileDropdownCloseExplicitly();
    if (isMenuOpen) {
      dispatch(setMenuOpen(false));
    }
    dispatch(logOut()).catch(() => {});
  };

  /** Open profile dropdown handler */
  const openNotificationsDropdown = () => {
    /** Если открываем дроп, то загружаем непрочитанные уведомления */
    if (!isNotificationDropdownOpened) {
      setIsNotificationsLoading(true);

      dispatch(getUnreadMessages(1, 5, zoneOffset))
        .then(() => {
          setIsNotificationsLoading(false);
        })
        .catch(() => {
          setIsNotificationsLoading(false);
        });
    }

    /** И тогглим дроп */
    notificationDropdownToggleDrop();
  };

  /** Клик по "отметить все как прочитанные" */
  const readAllMessagesHandler = (event) => {
    event.stopPropagation();

    /** Всем сообщениям в сторе проставляем read=true */
    dispatch(markAllMessagesAsRead());

    /**
     * И отправляем запрос на сервер для прочтения
     * При этом не сносим unreadMessages
     */
    dispatch(readAllMessages())
      .then(() => {
        /**
         * Берем инфу по юзеру, чтобы обновить в сторе информацию
         * по непрочитанным сообщениям (user.unreadMessages)
         * это надо чтобы в хэдере у колокольчика пропал модификатор
         * header__user-notification_has-notifications
         * */
        dispatch(getUser()).catch(() => {});
      })
      .catch(() => {});

    /**
     * Берем инфу по юзеру, чтобы обновить в сторе информацию
     * по непрочитанным сообщениям (user.unreadMessages)
     * это надо чтобы в хэдере у колокольчика пропал модификатор
     * header__user-notification_has-notifications
     * */
    dispatch(getUser()).catch(() => {});
  };

  /** Клик по уведомлению */
  const handleNotificationClick = (event, item) => {
    /**
     * Остановить всплытие события обязательно
     * для того чтобы не сработал аутклик
     * так как мы не хотим закрывать дроп при клике на другое сообщение
     * мы хотим, чтобы в дроп пробросился новый контент
     */
    event.stopPropagation();

    if (!isNotificationOpened) {
      setIsNotificationOpened(true);
    }

    setCurrentNotification(item);

    /**
     * Отмечаем уведомление как прочитанное
     * Отправляем запрос на сервер, но и отмечаем не дожидаясь ответа
     * в локальном стейте readMessages
     */
    if (!item.read && !readMessages[item.id]) {
      dispatch(readExactMessage(item.id)).then(() => {
        dispatch(getUser());
      });
      setReadMessages((prevState) => ({
        ...prevState,
        [item.id]: true,
      }));
    }

    notificationDropdownCloseExplicitly();
  };

  /** Возвращаемся на предыдущий экран при клике на стрелочку назад */
  const getLogoLink = () => (user ? '/lk' : '/');

  /** Закырвает ProfileDropdown + если меню открыто - закрыем его тоже */
  const closeProfileDrop = () => {
    profileDropdownCloseExplicitly();

    // dispatch(setProfileSettingsActiveTab(AUTHORIZED_PROFILE_TAB));

    if (isMenuOpen) {
      dispatch(setMenuOpen(false));
    }
  };

  const closeProfileDropFromSzLink = () => {
    profileDropdownCloseExplicitly();

    dispatch(setProfileSettingsActiveTab(SZ_PROFILE_TAB));

    if (isMenuOpen) {
      dispatch(setMenuOpen(false));
    }
  };

  /** Закрываем попап нотификейшна, открытого из NotificationsDropdown */
  const deactivateHintNotificationsModal = () => {
    setIsNotificationEntered(false);

    setTimeout(() => {
      setIsNotificationOpened(false);
    }, 0);
  };

  /** Закырвает NotificationsDropdown + если меню открыто - закрыем его тоже */
  const closeNotificationsDrop = () => {
    notificationDropdownCloseExplicitly();

    if (isMenuOpen) {
      dispatch(setMenuOpen(false));
    }
  };

  const closeMenuIfItWasOpen = () => {
    if (isMenuOpen) {
      dispatch(setMenuOpen(false));
    }
  };

  if (isDisabled) {
    return <></>;
  }

  return (
    <div
      className={cx('header', {
        'header_non-logged-in': !isUserLoggedIn,
        'header_white-elements': isElementsWhite,
        'header_responsive-white-elements': isResponsiveElementsWhite,
        'header_no-shadow': inNoShadow,
        'header_non-visible': isHidden,
        [className]: className,
      })}
      data-testid="header"
      data-cy="header"
    >
      <div className="header__container">
        {/* LEFT */}
        <div className="header__left-part">
          {/* ЕСЛИ ЮЗЕР НЕ ПРОХОДИЛ АВТОРИЗАЦИЮ */}
          {!isOnGettingAuthStatus && (
            <>
              {windowSize.innerWidth > 1194 ? (
                <Link
                  to={getLogoLink()}
                  className="header__logo-wrap"
                  aria-label={
                    getLogoLink() === '/' ? 'Перейти на главную' : 'Перейти в личный кабинет'
                  }
                  onClick={closeMenuIfItWasOpen}
                >
                  <Logo className="header__logo" />
                </Link>
              ) : (
                <Link
                  to={getLogoLink()}
                  className="header__logo-wrap"
                  aria-label={
                    getLogoLink() === '/' ? 'Перейти на главную' : 'Перейти в личный кабинет'
                  }
                  onClick={closeMenuIfItWasOpen}
                >
                  <LogoResponsive className="header__logo header__logo_responsive" />
                </Link>
              )}
            </>
          )}
          {/* /ЕСЛИ ЮЗЕР НЕ ПРОХОДИЛ АВТОРИЗАЦИЮ */}

          {/* СТРЕЛОЧКА В ПОЛУЧЕНИИ АВТОРИЗОВАННОЙ УЧЕТКИ (она только при < 1194) */}
          {windowSize.innerWidth < 1194 && isOnGettingAuthStatus && (
            <>
              {authStep === 1 && (
                <Link
                  to="/lk/profile-settings"
                  className="header__logo-wrap"
                  data-testid="header-logo-wrap_get-auth-step_0"
                >
                  <IconArrow className="header__back" />
                </Link>
              )}

              {authStep === 1 && (
                <button
                  type="button"
                  className="header__logo-wrap"
                  onClick={() => dispatch(setAuthStep(1))}
                  data-testid="header-logo-wrap_get-auth-step_1"
                >
                  <IconArrow className="header__back" />
                </button>
              )}
            </>
          )}
          {/* /СТРЕЛОЧКА В ПОЛУЧЕНИИ АВТОРИЗОВАННОЙ УЧЕТКИ (она только при < 1194) */}

          {/* !!! навигацию показываем на авторизованных страницах */}
          {/* NAVIGATION */}
          {user && windowSize.innerWidth > 1194 && (
            <nav className="header__nav">
              <NavLink
                exact
                to="/lk"
                className={cx('header__nav-item', {
                  'header__nav-item_active': isOnLkServices,
                })}
                activeClassName="header__nav-item_active"
              >
                Прием денег
              </NavLink>
              <NavLink
                exact
                to="/lk/money-transfer"
                className="header__nav-item__stub header__nav-item"
                activeClassName="header__nav-item_active"
              >
                Переводы
              </NavLink>
              <NavLink
                exact
                to="/lk/accounting"
                className="header__nav-item"
                activeClassName="header__nav-item_active"
              >
                Отчетность
              </NavLink>
              {/*<NavLink*/}
              {/*  exact*/}
              {/*  to="/lk/profile-settings/documents"*/}
              {/*  className="header__nav-item"*/}
              {/*  activeClassName="header__nav-item_active"*/}
              {/*>*/}
              {/*  Документы*/}
              {/*</NavLink>*/}
              {/*<NavLink*/}
              {/*  exact*/}
              {/*  to="/lk/profile-settings/partners"*/}
              {/*  className="header__nav-item"*/}
              {/*  activeClassName="header__nav-item_active"*/}
              {/*>*/}
              {/*  Партнеры*/}
              {/*</NavLink>*/}
              <NavLink
                exact
                to="/lk/history"
                className="header__nav-item"
                activeClassName="header__nav-item_active"
              >
                История
              </NavLink>
            </nav>
          )}
          {/* /NAVIGATION */}
        </div>
        {/* /LEFT */}

        {/* RIGHT */}
        <div className="header__right-part">
          {user && windowSize.innerWidth > 980 && (
            <div className="header__user">
              <div className="header__user-balance-block">
                <p className="header__user-balance-text" data-cy="header-balance-text">
                  Баланс счета
                </p>

                {user.clientAccounts &&
                  Array.isArray(user.clientAccounts) &&
                  user.clientAccounts[0] && (
                    <div className="header__user-balance">
                      {user.clientAccounts[0].balance !== 0 && (
                        <>
                          <span className="header__user-balance-rubles">
                            {formatNumber(Math.floor(user.clientAccounts[0].balance / 100))}
                          </span>
                          ,
                          <span className="header__user-balance-penny">
                            {user.clientAccounts[0].balance.toString().slice(-2)} {'    '}
                          </span>
                        </>
                      )}
                      {user.clientAccounts[0].balance === 0 && (
                        <>
                          <span className="header__user-balance-rubles">
                            {user.clientAccounts[0].balance}
                          </span>
                          ,<span className="header__user-balance-penny">00</span>
                        </>
                      )}
                      ₽
                    </div>
                  )}
              </div>

              <button
                className={cx('header__user-notification', {
                  'header__user-notification_has-notifications': user && user.unreadMessages > 0,
                })}
                type="button"
                onClick={openNotificationsDropdown}
                ref={notificationsTogglerRef}
                aria-label="Уведомления"
              >
                <IconNotifications className="header__user-notification-icon" />
              </button>

              <button
                type="button"
                onClick={profileDropdownToggleDrop}
                className="header__user-avatar-wrap"
                aria-label="Открыть меню пользователя из аватарки"
                data-cy="header-avatar-button"
              >
                <img
                  src={user && user.avatar ? avatarUrl + user.avatar : stdAvatar}
                  alt="Изображение пользователя"
                  className="header__user-avatar"
                />
              </button>

              {user && user.login && (
                <button
                  type="button"
                  onClick={profileDropdownToggleDrop}
                  className="header__user-phone"
                  aria-label="Открыть меню пользователя из номера"
                >
                  {changeLookOfPhone(user.login)}
                </button>
              )}

              <button
                type="button"
                onClick={profileDropdownToggleDrop}
                className="header__user-caret"
                aria-label="Открыть меню пользователя из каретки"
              >
                <IconArrow className="header__user-caret-icon" />
              </button>
            </div>
          )}

          {/* бургер показываем когда есть юзер при < 1194 или если нет юзера при любой width */}
          {/* BURGER */}
          {((user && windowSize.innerWidth < 1194) || !user) && (
            <button
              className={cx('header__burger', {
                'header__burger_menu-open': isMenuOpen,
              })}
              type="button"
              onClick={handleClick}
            >
              <div className="header__burger-inner">
                <IconLine1 className="header__burger-span" />
                <IconLine2 className="header__burger-span" />
                <IconLine3 className="header__burger-span" />
              </div>
            </button>
          )}
          {/* /BURGER */}

          {/* PROFILE DROPDOWN */}
          {user && (
            <CSSTransition
              in={user && windowSize.innerWidth > 700 && isProfileDropdownOpened}
              timeout={{
                enter: 600,
                exit: 200,
              }}
              unmountOnExit
              classNames="animation-from-top-to-bottom-normal"
            >
              <ProfileDropdown
                ref={profileDropdownDropEl}
                closeDrop={closeProfileDrop}
                closeProfileDropFromSzLink={closeProfileDropFromSzLink}
                user={user}
                szStatusFromDb={szStatusFromDb}
                userTaxStatus={userTaxStatus}
                avatar={user && user.avatar ? avatarUrl + user.avatar : stdAvatar}
                onLogOutButtonClick={logout}
                openBCL={() => dispatch(toggleBusinessCard())}
                className="header__profile-dropdown"
                resetProfileActiveTab={() =>
                  dispatch(setProfileSettingsActiveTab(AUTHORIZED_PROFILE_TAB))
                }
              />
            </CSSTransition>
          )}
          {/* /PROFILE DROPDOWN */}

          {/* NOTIFICATIONS DROPDOWN */}
          <CSSTransition
            in={user && windowSize.innerWidth > 700 && isNotificationDropdownOpened}
            timeout={{
              enter: 600,
              exit: 200,
            }}
            unmountOnExit
            classNames="animation-from-top-to-bottom-normal"
          >
            <NotificationsDropdown
              ref={notificationDropdownDropEl}
              closeDrop={closeNotificationsDrop}
              className="animation-from-top-to-bottom-normal"
              unreadMessages={unreadMessages}
              readAllMessagesHandler={readAllMessagesHandler}
              isNotificationsLoading={isNotificationsLoading}
              handleNotificationClick={handleNotificationClick}
              readMessages={readMessages}
              style={
                notificationsTogglerRef.current
                  ? (() => {
                      const box = notificationsTogglerRef.current.getBoundingClientRect();
                      const windowWidth = document.documentElement.clientWidth;
                      return {
                        right: windowWidth - box.right,
                      };
                    })()
                  : {}
              }
            />
          </CSSTransition>
          {/* /NOTIFICATIONS DROPDOWN */}

          {/* NOTIFICATION CONTENT */}
          {currentNotification && (
            <HintNotifications
              isNotificationOpened={isNotificationOpened}
              isNotificationEntered={isNotificationEntered}
              notification={currentNotification}
              hintCloseHandler={deactivateHintNotificationsModal}
              hintEnterHandler={() => setIsNotificationEntered(true)}
            >
              {/* eslint-disable react/no-danger */}
              <div
                className="hint-notifications__text"
                dangerouslySetInnerHTML={{ __html: currentNotification.message }}
              />
              {/* eslint-enable react/no-danger */}
            </HintNotifications>
          )}
          {/* /NOTIFICATION CONTENT */}
        </div>
      </div>
      {/* /RIGHT */}

      {/* ССЫЛКА-ВИЗИТКА */}
      {isBusinessCardOpen && user && (
        <BusinessCardPopupContainer
          closeDrop={() => dispatch(toggleBusinessCard())}
          user={user}
          getBusinessCardInfo={(randomUniqueId) => dispatch(getBusinessCardInfo(randomUniqueId))}
        />
      )}
      {/* /ССЫЛКА-ВИЗИТКА */}
    </div>
  );
};

Header.propTypes = {
  isMenuOpen: PropTypes.bool,
  className: PropTypes.string,
  user: PropTypes.shape({
    login: PropTypes.string,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    clientAccounts: PropTypes.arrayOf(
      PropTypes.shape({
        balance: PropTypes.number,
      }),
    ),
    avatar: PropTypes.string,
    unreadMessages: PropTypes.number,
  }),
  dispatch: PropTypes.func.isRequired,
  isOnGettingAuthStatus: PropTypes.bool,
  isOnLkServices: PropTypes.bool,
  unreadMessages: PropTypes.shape({
    messages: PropTypes.arrayOf(
      PropTypes.shape({
        dateTimeAdd: PropTypes.string,
        id: PropTypes.number,
        message: PropTypes.string,
        title: PropTypes.string,
      }),
    ),
  }).isRequired,
  authStep: PropTypes.number,
  isBusinessCardOpen: PropTypes.bool,
  szStatusFromDb: PropTypes.string,
  userTaxStatus: PropTypes.string,
  isUserLoggedIn: PropTypes.bool,
  isElementsWhite: PropTypes.bool,
  isResponsiveElementsWhite: PropTypes.bool,
  inNoShadow: PropTypes.bool,
  isHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
};

Header.defaultProps = {
  isMenuOpen: false,
  user: null,
  className: null,
  isOnGettingAuthStatus: false,
  isOnLkServices: false,
  authStep: null,
  userTaxStatus: null,
  szStatusFromDb: '',
  isUserLoggedIn: false,
  isBusinessCardOpen: false,
  isElementsWhite: false,
  isResponsiveElementsWhite: false,
  inNoShadow: false,
  isHidden: false,
  isDisabled: false,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  isMenuOpenWithTransition: state.auth.isMenuOpenWithTransition,
  user: state.auth.user,
  unreadMessages: state.profile.unreadMessages,
  authStep: state.profile.authStep,
  isBusinessCardOpen: state.profile.isBusinessCardOpen,
  szStatusFromDb: state.auth.szStatusFromDb,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
});

export default connect(mapStateToProps)(Header);
