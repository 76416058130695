import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';

import './InputWithLabelAnimation.scss';

const InputWithLabelAnimation = ({
  className,
  label,
  value,
  onChange,
  disabled,
  placeholder,
  children,
  customRightPadding,
  type,
  name,
  autoComplete,
  maxLength,
  isPhoneInput,
  prevText,
  prevTextWidth,
}) => {
  return (
    <div
      className={cx('input-with-label-animation', {
        [className]: className,
        'input-with-label-animation_focused': value !== '' || placeholder,
      })}
      data-testid="input-holder"
    >
      {prevText && <p className="input-with-label-animation__prev-text">{prevText}</p>}

      {isPhoneInput ? (
        <InputMask
          type="tel"
          className="input-with-label-animation__input"
          mask="+7\ (999) 999-99-99"
          maskChar={0}
          value={value}
          onChange={(event) => onChange(event)}
          disabled={disabled}
          aria-label="input"
          placeholder={placeholder}
          maxLength={maxLength}
          name={name}
          autoComplete={autoComplete}
          style={{ paddingRight: `${customRightPadding}px` }}
        />
      ) : (
        <input
          type={type}
          className="input-with-label-animation__input"
          value={value}
          onChange={(event) => onChange(event)}
          disabled={disabled}
          aria-label="input"
          placeholder={placeholder}
          maxLength={maxLength}
          name={name}
          autoComplete={autoComplete}
          style={{ paddingRight: `${customRightPadding}px`, paddingLeft: `${prevTextWidth}px` }}
        />
      )}

      <p className="input-with-label-animation__label">{label}</p>

      {children}
    </div>
  );
};

InputWithLabelAnimation.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  customRightPadding: PropTypes.number,
  maxLength: PropTypes.number,
  isPhoneInput: PropTypes.bool,
  type: PropTypes.string,
  name: PropTypes.string,
  autoComplete: PropTypes.string,
  prevText: PropTypes.string,
  prevTextWidth: PropTypes.number,
};

InputWithLabelAnimation.defaultProps = {
  children: null,
  className: null,
  disabled: false,
  onChange: () => {},
  placeholder: '',
  customRightPadding: null,
  maxLength: null,
  isPhoneInput: false,
  type: 'text',
  name: null,
  autoComplete: null,
  prevText: null,
  prevTextWidth: null,
};

export default InputWithLabelAnimation;
