/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTClassNames, PTColor, PTCWeight } from 'tools/types/propTypes';

import { LineTextMixin } from '../atoms';

export const ParagraphText = ({ children, color, className, weight, size }) => (
  <TextStyled size={size} color={color} weight={weight} className={className}>
    {children}
  </TextStyled>
);

ParagraphText.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.element,
    PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.element, PropTypes.string, PropTypes.func])),
  ]).isRequired,
  weight: PTCWeight,
  color: PTColor,
  // eslint-disable-next-line react/require-default-props
  size: PropTypes.string,
  className: PTClassNames,
};

ParagraphText.defaultProps = {
  weight: WEIGHT['circe-regular'],
  color: COLOR['dark-slate-blue'],
  className: null,
};

const TextStyled = styled.p.attrs((props) => ({
  className: props.className,
}))`
  ${LineTextMixin}
`;
