import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import { normalizePhone } from 'helpers';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { checkInnWithRequestInFns, setHintOpen, setINN } from 'modules/authLegasy/actions';
import HintHowToKnowINN from 'modules/authLegasy/components/Hint/HintHowToKnowINN';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import HintsList from 'modules/shared/components/HintsList/HintsList';
import StandardHoverButton from 'modules/shared/components/StandardHoverButton/StandardHoverButton';

import {
  ALIEN_INN,
  CONSISTENT_ANIMATIONS_DELAY,
  ERROR,
  NO,
  NO_PERMISSION,
  NOT_FOUND,
  NOT_MATCH,
  NOT_READY,
  NOT_READY_PERMISSION_REQUESTED,
  YES,
} from 'constants/index.js';
import checkValidInn from 'helpers/checkValidInn';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import phoneImg from 'static/assets/phone.png';

export const SelfEmployedEnterINN = ({
  onNextStepButtonClick,
  className,
  dispatch,
  INN,
  isMenuOpen,
  onSuccessSERegistration,
  onFailedSERegistration,
  onAlreadyRegistered,
  onNoSz,
  onNoRights,
}) => {
  const [valid, setValid] = useState(false);
  const [isRendered, setIsRendered] = useState(false);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  /** Валидация ИНН */
  useEffect(() => {
    setValid(false);

    if (INN && INN.length === 12 && checkValidInn(INN)) {
      setValid(true);
    }
  }, [INN]);

  const handleSubmitInn = () => {
    setIsLoading(true);
    dispatch(checkInnWithRequestInFns(INN))
      .then((data) => {
        /** Проверяем, если овтет с сервера NOT_READY или  NOT_READY_PERMISSION_REQUESTED отправляем
         * повторный запрос*/
        if (
          data &&
          data.data &&
          (data.data.status === NOT_READY || data.data.status === NOT_READY_PERMISSION_REQUESTED)
        ) {
          onNextStepButtonClick();
        }

        if (
          data &&
          data.data &&
          (data.data.status === NOT_FOUND ||
            data.data.status === ERROR ||
            data.data.status === NOT_MATCH)
        ) {
          onFailedSERegistration();
        }

        if (data && data.data && data.data.status === NO) {
          onNoSz();
        }

        if (data && data.data && data.data.status === NO_PERMISSION) {
          onNoRights();
        }

        if (data && data.data && data.data.status === ALIEN_INN) {
          onAlreadyRegistered();
        }

        if (data && data.data && data.data.status === YES) {
          onSuccessSERegistration();
        }

        setIsLoading(false);
      })
      .catch(() => {
        onFailedSERegistration();
        setIsLoading(false);
      });
  };

  const handleFieldChange = ({ target }) => {
    const normalInn = normalizePhone(target.value);
    dispatch(setINN(normalInn));
  };

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  const hints = [
    {
      id: 0,
      title: 'Как узнать свой ИНН?',
      titleForPopup: 'Как узнать свой ИНН?',
      action: () => toggleDrop(),
      openCondition: isDropOpen,
      popup: <HintHowToKnowINN />,
      dropEl: dropEl,
    },
  ];

  return (
    <div
      className={cx('se-register', {
        [className]: className,
      })}
      data-testid="se-register"
    >
      <div className="se-register__container container">
        <div className="se-register__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="se-register__title">Подтвердить статус</h1>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="se-register__desc-wrap">
              <div className="se-register__desc">
                Предоставьте нам права в приложении &laquo;Мой&nbsp;налог&raquo; или на сайте ФНС
                для полноценной работы с нашим сервисом
              </div>
            </div>
          </CSSTransition>

          <div className="se-register__form">
            <div className="se-register__form-block">
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={!isMenuOpen && isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="se-register__input-holder">
                  <InputMask
                    type="text"
                    className="se-register__input"
                    placeholder="Введите ИНН"
                    mask="9999-9999-9999"
                    maskChar={null}
                    onChange={handleFieldChange}
                    value={INN}
                    required
                    data-cy="se-register-input"
                  />

                  {valid && <IconCheck className="se-register__input-check" />}
                </div>
              </CSSTransition>

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={!isMenuOpen && isRendered && windowSize.innerWidth > 700}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <button
                  type="button"
                  className="se-register__next-button"
                  onClick={handleSubmitInn}
                  aria-label="Отправить ИНН на проверку"
                  disabled={!valid || isLoading}
                  data-cy="se-register-send-inn"
                >
                  {isLoading ? (
                    <CircleLoader />
                  ) : (
                    <IconArrow className="se-register__next-button-icon" />
                  )}
                </button>
              </CSSTransition>
            </div>

            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={!isMenuOpen && isRendered && windowSize.innerWidth <= 700}
              timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
              unmountOnExit
            >
              <StandardHoverButton
                isLink={false}
                title="Отправить на проверку"
                isLoading={isLoading}
                disabled={!valid || isLoading}
                onClick={handleSubmitInn}
                className="se-register__send-button"
              />
            </CSSTransition>
          </div>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
            unmountOnExit
          >
            <div className="se-register__desc-wrap">
              <HintsList hints={hints} closeHintHandler={closeExplicitly} />
            </div>
          </CSSTransition>
        </div>

        <img src={phoneImg} alt="" className="se-register__image" />
      </div>
    </div>
  );
};

SelfEmployedEnterINN.propTypes = {
  onNextStepButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  INN: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  onSuccessSERegistration: PropTypes.func.isRequired,
  onFailedSERegistration: PropTypes.func.isRequired,
  onAlreadyRegistered: PropTypes.func.isRequired,
  onNoSz: PropTypes.func.isRequired,
  onNoRights: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  INN: state.auth.INN,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(SelfEmployedEnterINN);
