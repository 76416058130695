import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import styles from './Tip.module.scss';

const Tip = ({
  // подсказка должна отобразиться сверху, со стрелкой вниз
  isToTop,
  className,
  children,
}) => {
  return (
    <div className={cx(styles.tip, isToTop && styles.tipToTop, className)} data-tip="">
      {children}
    </div>
  );
};

Tip.propTypes = {
  isToTop: PropTypes.bool,
  className: PropTypes.string,
  children: PropTypes.node,
};

export default Tip;
