import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { setRegistrationStep } from 'modules/authLegasy/actions';
import RegistrationEnterCode from 'modules/authLegasy/components/RegistrationEnterCode/RegistrationEnterCode';
import RegistrationEnterPassword from 'modules/authLegasy/components/RegistrationEnterPassword/RegistrationEnterPassword';
import RegistrationEnterPhone from 'modules/authLegasy/components/RegistrationEnterPhone/RegistrationEnterPhone';
import RegistrationSuccess from 'modules/authLegasy/components/RegistrationSuccess/RegistrationSuccess';

// Styles
import './Registration.scss';
import 'static/styles/form.scss';

const Registration = ({ dispatch, isMenuOpen, registrationStep }) => {
  return (
    <div
      className={cx('registration-container', {
        'registration-container_menu-open': isMenuOpen,
      })}
    >
      {registrationStep === 0 && (
        <RegistrationEnterPhone
          className="register__step-1"
          onNextStepButtonClick={() => dispatch(setRegistrationStep(registrationStep + 1))}
        />
      )}
      {registrationStep === 1 && (
        <RegistrationEnterCode
          className="register__step-2"
          onNextStepButtonClick={() => dispatch(setRegistrationStep(registrationStep + 1))}
          onChangePhone={() => dispatch(setRegistrationStep(registrationStep - 1))}
        />
      )}
      {registrationStep === 2 && (
        <RegistrationEnterPassword
          className="register__step-3"
          onNextStepButtonClick={() => dispatch(setRegistrationStep(registrationStep + 1))}
        />
      )}
      {registrationStep === 3 && <RegistrationSuccess className="register__step-5" />}
    </div>
  );
};

Registration.propTypes = {
  dispatch: PropTypes.func.isRequired,
  registrationStep: PropTypes.number,
  isMenuOpen: PropTypes.bool.isRequired,
};
Registration.defaultProps = {
  registrationStep: null,
};

const mapStateToProps = (state) => ({
  registrationStep: state.auth.registrationStep,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(Registration);
