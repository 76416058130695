import React from 'react';
import PropTypes from 'prop-types';
// import { CSSTransition } from 'react-transition-group';

// Components
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import EditButton from 'modules/profileLegasy/components/EditButton/EditButton';
// import SettingNewPhone from 'modules/profileLegasy/components/SettingNewPhone/SettingNewPhone';
// import Hint from 'modules/shared/components/Hint/Hint';

// Constants
// import { HINT_TIMEOUTS } from 'constants/index.js';

// Hooks
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';

// Misc
import { changeLookOfPhone } from 'helpers';
import { WindowUI } from '../../../../ui';

const ChangePhoneWrap = ({ userPhone, isMenuOpen }) => {
  /** Хука для попапа - смена номера телефона */
  const [
    isDropPhoneChangeOpened,
    dropElPhoneChange,
    toggleDropPhoneChange,
    closeExplicitlyPhoneChange,
  ] = useToggleAndOutClick();

  return (
    <>
      <InputWithLabelAnimation
        disabled
        value={changeLookOfPhone(userPhone)}
        label="Телефон"
        className="profile-settings-profile-tab__input"
      >
        <EditButton action={toggleDropPhoneChange} />
      </InputWithLabelAnimation>

      <div>
        {isDropPhoneChangeOpened && dropElPhoneChange && (
          <WindowUI.WindowUniversal
            header={'Смена телефона'}
            text={'Для смены номера телефона необходимо обратиться в техническую поддержку'}
            buttonText={'Хорошо'}
            onClose={() => {
              closeExplicitlyPhoneChange();
            }}
          />
        )}
      </div>
      {/* ПОПАП - СМЕНА НОМЕРА ТЕЛЕФОНА */}
      {/*<CSSTransition*/}
      {/*  in={isDropPhoneChangeOpened}*/}
      {/*  timeout={HINT_TIMEOUTS}*/}
      {/*  unmountOnExit*/}
      {/*  classNames="hint-auth"*/}
      {/*>*/}
      {/*  <Hint*/}
      {/*    ref={dropElPhoneChange}*/}
      {/*    onCloseBtnClick={closeExplicitlyPhoneChange}*/}
      {/*    className="profile-settings-profile-tab__change-phone-sidebar"*/}
      {/*    classNameInner="profile-settings-profile-tab__change-phone-inner"*/}
      {/*    isMenuOpen={isMenuOpen}*/}
      {/*  >*/}
      {/*    <SettingNewPhone />*/}
      {/*  </Hint>*/}
      {/*</CSSTransition>*/}
      {/* /ПОПАП - СМЕНА НОМЕРА ТЕЛЕФОНА */}
    </>
  );
};

ChangePhoneWrap.propTypes = {
  userPhone: PropTypes.string,
  isMenuOpen: PropTypes.bool.isRequired,
};

ChangePhoneWrap.defaultProps = {
  userPhone: '',
};

export default ChangePhoneWrap;
