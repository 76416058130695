import React from 'react';
import Select from 'react-select';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Misc
import { multiSelectStyles } from 'static/styles/multi-select';

// Styles
import './MultipleSelect.scss';

const MultipleSelect = ({
  options,
  className,
  handleSelectChange,
  defaultValue,
  label,
  isSomethingSelected,
}) => {
  return (
    <div
      className={cx('multiple-select', {
        [className]: className,
        'multiple-select_focused': (defaultValue && defaultValue.length) || isSomethingSelected,
      })}
    >
      <Select
        defaultValue={defaultValue}
        options={options}
        onChange={handleSelectChange}
        closeMenuOnSelect={false}
        styles={multiSelectStyles}
        classNamePrefix="multiple-select"
        isMulti
        noOptionsMessage={() => null}
      />

      <div className="multiple-select__label">{label}</div>
    </div>
  );
};

MultipleSelect.propTypes = {
  className: PropTypes.string,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
    }),
  ).isRequired,
  handleSelectChange: PropTypes.func.isRequired,
  label: PropTypes.string.isRequired,
  isSomethingSelected: PropTypes.bool,
};

MultipleSelect.defaultProps = {
  className: null,
  isSomethingSelected: false,
};

export default MultipleSelect;
