// получить информацию о максимльной сумме, комиссиях и прочей информации
export const GET_MONEY_TRANSFER_INFO_FETCHING = 'GET_MONEY_TRANSFER_INFO_FETCHING';
export const GET_MONEY_TRANSFER_INFO_SUCCESS = 'GET_MONEY_TRANSFER_INFO_SUCCESS';
export const GET_MONEY_TRANSFER_INFO_FAIL = 'GET_MONEY_TRANSFER_INFO_FAIL';

// запросить перевод
export const REQUEST_MONEY_TRANSFER_FETCHING = 'REQUEST_MONEY_TRANSFER_FETCHING';
export const REQUEST_MONEY_TRANSFER_SUCCESS = 'REQUEST_MONEY_TRANSFER_SUCCESS';
export const REQUEST_MONEY_TRANSFER_FAIL = 'REQUEST_MONEY_TRANSFER_FAIL';
export const REQUEST_MONEY_TRANSFER_FAIL_DUPLICATE = 'REQUEST_MONEY_TRANSFER_FAIL_DUPLICATE';
export const CONFIRM_MONEY_TRANSFER_DUPLICATE = 'CONFIRM_MONEY_TRANSFER_DUPLICATE';

// проверить успешность подтверждения с помощью push-уведомления
export const CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FETCHING =
  'CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FETCHING';
export const CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_SUCCESS =
  'CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_SUCCESS';
export const CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FAIL =
  'CHECK_MONEY_TRANSFER_CONFIRMATION_VIA_PUSH_FAIL';

// подтвердить перевод с помощью смс
export const CONFIRM_MONEY_TRANSFER_VIA_SMS_FETCHING = 'CONFIRM_MONEY_TRANSFER_VIA_SMS_FETCHING';
export const CONFIRM_MONEY_TRANSFER_VIA_SMS_SUCCESS = 'CONFIRM_MONEY_TRANSFER_VIA_SMS_SUCCESS';
export const CONFIRM_MONEY_TRANSFER_VIA_SMS_FAIL = 'CONFIRM_MONEY_TRANSFER_VIA_SMS_FAIL';

// отправить новый смс-код
export const SEND_MONEY_TRANSFER_SMS_CODE_FETCHING = 'SEND_MONEY_TRANSFER_SMS_CODE_FETCHING';
export const SEND_MONEY_TRANSFER_SMS_CODE_SUCCESS = 'SEND_MONEY_TRANSFER_SMS_CODE_SUCCESS';
export const SEND_MONEY_TRANSFER_SMS_CODE_FAIL = 'SEND_MONEY_TRANSFER_SMS_CODE_FAIL';

// отследить состояние перевода
export const CHECK_MONEY_TRANSFER_STATUS_FETCHING = 'CHECK_MONEY_TRANSFER_STATUS_FETCHING';
export const CHECK_MONEY_TRANSFER_STATUS_SUCCESS = 'CHECK_MONEY_TRANSFER_STATUS_SUCCESS';
export const CHECK_MONEY_TRANSFER_STATUS_FAIL = 'CHECK_MONEY_TRANSFER_STATUS_FAIL';

// изменить метод проверки перевода.
export const CHANGE_MONEY_TRANSFER_CONFIRMATION_METHOD =
  'CHANGE_MONEY_TRANSFER_CONFIRMATION_METHOD';
// установить сумму
export const SET_MONEY_TRANSFER_SUM = 'SET_MONEY_TRANSFER_SUM';
// установить комиссию
export const SET_MONEY_TRANSFER_COMISSION_FEE = 'SET_MONEY_TRANSFER_COMISSION_FEE';
// установить сообщение
export const SET_MONEY_TRANSFER_MESSAGE = 'SET_MONEY_TRANSFER_MESSAGE';
// установить номер карты или кошелька
export const SET_MONEY_TRANSFER_CARD_OR_WALLET_NUMBER = 'SET_MONEY_TRANSFER_CARD_OR_WALLET_NUMBER';
// установить банк СБП
export const SET_MONEY_TRANSFER_SBP_BANK = 'SET_MONEY_TRANSFER_SBP_BANK';
// отменить перевод денег
export const RESET_MONEY_TRANSFER = 'RESET_MONEY_TRANSFER';
// перейти в первому шагу
export const RESET_MONEY_TRANSFER_STEPS = 'RESET_MONEY_TRANSFER_STEPS';
// перейти к следующему шагу
export const SET_MONEY_TRANSFER_NEXT_STEP = 'SET_MONEY_TRANSFER_NEXT_STEP';
// перейти к предыдущему шагу
export const SET_MONEY_TRANSFER_PREVIOUS_STEP = 'SET_MONEY_TRANSFER_PREVIOUS_STEP';
// пересоздать подтверждение (в случае переключения с push на смс)
export const RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FETCHING =
  'RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FETCHING';
export const RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_SUCCESS =
  'RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_SUCCESS';
export const RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FAIL =
  'RECREATE_MONEY_TRANSFER_CONFIRMATION_VIA_SMS_FAIL';

// получить информацию о банковской карте
export const GET_MONEY_TRANSFER_BANK_CARD_INFO_FETCHING =
  'GET_MONEY_TRANSFER_BANK_CARD_INFO_FETCHING';
export const GET_MONEY_TRANSFER_BANK_CARD_INFO_SUCCESS =
  'GET_MONEY_TRANSFER_BANK_CARD_INFO_SUCCESS';
export const GET_MONEY_TRANSFER_BANK_CARD_INFO_FAIL = 'GET_MONEY_TRANSFER_BANK_CARD_INFO_FAIL';
