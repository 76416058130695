import React, { useState } from 'react';

import styles from './style.module.scss';
import cx from 'classnames';
import { checkPaymentEnabled, setPaymentEnabled } from '../../../../tools/utils/payments';
import { checkSBPEnabled, setSBPEnabled } from '../../../../tools/utils/sbp';

export const DevWidget = () => {
  const [checkedPaymentEnabled, setCheckedPaymentEnabled] = useState(checkPaymentEnabled());
  const [checkedSBPEnabled, setCheckedSBPEnabled] = useState(checkSBPEnabled());

  return (
    <div
      className={cx(styles.devWidget, {
        container: true,
      })}
    >
      <div className={cx(styles.devWidgetItem)}>
        <span>Payment Enable</span>
        <input
          type="checkbox"
          checked={checkPaymentEnabled()}
          onChange={() => {
            setCheckedPaymentEnabled(!checkedPaymentEnabled);
            setPaymentEnabled(!checkedPaymentEnabled);
          }}
        />
      </div>

      <div className={cx(styles.devWidgetItem)}>
        <span>SBP Enable</span>
        <input
          type="checkbox"
          checked={checkSBPEnabled()}
          onChange={() => {
            setCheckedSBPEnabled(!checkedSBPEnabled);
            setSBPEnabled(!checkedSBPEnabled);
          }}
        />
      </div>
    </div>
  );
};
