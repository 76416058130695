/* eslint-disable import/prefer-default-export */
import { PropTypes } from 'prop-types';
import React from 'react';

import { PTAccountType } from '../../utils/propTypes';
import { AccountTypeDetailsContsiner } from './containers';

export const AccountTypeDetailsWidget = ({
  filterGroup,
  urlToGetIdentificationOfSelfWorkPage,
  urlToGetIdentificationOfSvyaznoyPage,
  urlToGetIdentificationOfLetterPage,
}) => (
  <AccountTypeDetailsContsiner
    filterGroup={filterGroup}
    urlToGetIdentificationOfSelfWorkPage={urlToGetIdentificationOfSelfWorkPage}
    urlToGetIdentificationOfSvyaznoyPage={urlToGetIdentificationOfSvyaznoyPage}
    urlToGetIdentificationOfLetterPage={urlToGetIdentificationOfLetterPage}
  />
);

AccountTypeDetailsWidget.propTypes = {
  filterGroup: PTAccountType.isRequired,
  urlToGetIdentificationOfSelfWorkPage: PropTypes.string,
  urlToGetIdentificationOfSvyaznoyPage: PropTypes.string,
  urlToGetIdentificationOfLetterPage: PropTypes.string,
};

AccountTypeDetailsWidget.defaultProps = {
  urlToGetIdentificationOfSelfWorkPage: null,
  urlToGetIdentificationOfSvyaznoyPage: null,
  urlToGetIdentificationOfLetterPage: null,
};
