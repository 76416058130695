import PropTypes from 'prop-types';
import React from 'react';
import { PTChildrenElement } from 'tools/types/propTypes';
import styled from 'styled-components';

import { ButtonUI } from '../../../../organisms';
import { TitleText } from '../../../TitleText';
import { WindowModal } from '../atoms';

export const WindowAlert = ({ onClose, onClick, header, emoji, children, buttonText }) => {
  return (
    <WindowModal
      header={
        <HeaderWraperStyled>
          <TitleTextStyled>{header}</TitleTextStyled>
        </HeaderWraperStyled>
      }
      footer={
        <FooterWraperStyled>
          {!!buttonText && <ButtonNormalStyled onClick={onClick}>{buttonText}</ButtonNormalStyled>}
        </FooterWraperStyled>
      }
      onClose={onClose}
    >
      <BodyWraperStyled>
        <>
          {emoji}
          {children}
        </>
      </BodyWraperStyled>
    </WindowModal>
  );
};

WindowAlert.propTypes = {
  onClose: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  header: PropTypes.string,
  emoji: PTChildrenElement,
  children: PTChildrenElement,
  buttonText: PropTypes.string.isRequired,
};

WindowAlert.defaultProps = {
  header: '',
  emoji: null,
  children: null,
};

const HeaderWraperStyled = styled.div`
  width: 100%;
  display: inline-flex;
  justify-content: center;
  margin-bottom: 40px;
  @media (max-width: 400px) {
    margin-bottom: 30px;
  }
`;

const TitleTextStyled = styled(TitleText)`
  font-size: 36px;
  line-height: 40px;
  @media (max-width: 400px) {
    font-size: 22px;
    line-height: 26px;
    text-align: center;
  }
`;

const BodyWraperStyled = styled.div`
  margin-bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-self: center;
  @media (max-width: 400px) {
    margin-bottom: 40px;
  }
`;
const FooterWraperStyled = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: nowrap;
`;

const ButtonNormalStyled = styled(ButtonUI.Normal)`
  padding: 0 50px;
  width: fit-content;
`;
