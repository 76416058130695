/* eslint-disable import/prefer-default-export */
import React from 'react';

import img from 'static/assets/Group_1077237012.png';
import { SIZE } from 'tools/constants';

import {
  ImagesOfBlog,
  ItemListMarkerOfBlog,
  LinkOfBlog,
  ListOfBlog,
  ParagraphTextOfBlog,
  SupportLinkOfBlog,
  TitleTextOfBlog,
  WidgetWraper,
} from '../ui';

export const GetIdentificationOfLetterBlogContsiner = () => (
  <WidgetWraper title="Отправление заявления по почте">
    <ParagraphTextOfBlog>
      <b>Стоимость:</b> цены на услуги нотариуса и почты зависят от региона проживания.
    </ParagraphTextOfBlog>

    <ParagraphTextOfBlog>
      <b>Длительность:</b> 7—14 рабочих дней после отправки документов почтой.
    </ParagraphTextOfBlog>

    <TitleTextOfBlog size={SIZE.sm}>Необходимые документы:</TitleTextOfBlog>
    <ListOfBlog>
      <ItemListMarkerOfBlog>
        <ParagraphTextOfBlog>
          для граждан РФ — ксерокопия паспорта (главный разворот с фотографией и страница с
          регистрацией), заполненное и распечатанное заявление на идентификацию.
        </ParagraphTextOfBlog>
      </ItemListMarkerOfBlog>
      <ItemListMarkerOfBlog>
        <ParagraphTextOfBlog>
          иностранным гражданам — копия паспорта с нотариально заверенным переводом, загранпаспорта
          с визой, если вы въехали в РФ по визе, вида на жительство или разрешения на временное
          проживание в РФ, миграционной карты.
        </ParagraphTextOfBlog>
      </ItemListMarkerOfBlog>
    </ListOfBlog>

    <ParagraphTextOfBlog>
      <b>Нюанс:</b> если вы публичное должностное лицо или его родственник, у вас есть
      выгодоприобретатель или бенефициар, нужно{' '}
      <LinkOfBlog url="/">дополнительное заявление.</LinkOfBlog>
    </ParagraphTextOfBlog>

    <ParagraphTextOfBlog>
      Указанные документы, кроме заявления, необходимо заверить у нотариуса.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Если паспорт или нотариальное заверение не на русском языке, отправляйтесь к переводчику: он
      должен перевести документы на русский. Потом заверьте у нотариуса подпись переводчика.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Убедитесь, что нотариус поставил на документах гербовую печать — других заверений не нужно.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      <b>Куда отправить:</b> из любого отделения Почты России отправьте заказное письмо с
      документами по адресу 121596, г. Москва, БЦ «Гранд Сетунь Плаза», ул. Горбунова, д. 2 стр. 3,
      офис 311
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Учтите, что доставка почтой может занять несколько недель. Следить за доставкой можно на сайте
      Почты России по трек-номеру.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Если на сайте появился статус «Вручение адресату», значит служба поддержки Самозанятые.рф
      начала обрабатывать данные. На обработку уйдет не более 7 рабочих дней.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      После вам придет уведомление о том, что аккаунт идентифицирован.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Нажмите на «Подтвердите, что все верно». Если все данные введены корректно, завершите процесс
      идентификации.
    </ParagraphTextOfBlog>

    <ImagesOfBlog src={img} />

    <ParagraphTextOfBlog>
      Если вы нашли ошибку — свяжитесь со <SupportLinkOfBlog text={'службой поддержки'} />.
    </ParagraphTextOfBlog>
  </WidgetWraper>
);
