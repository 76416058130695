import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Overlay from '../Overlay';
import iconEmojiPuzzled from 'static/assets/icon-emoji-puzzled.png';

import { getDeclineRequest } from '../../selectors';
import { hidePopupDeclinePartner, declinePartnerRequest } from '../../actions';

import styles from './PopupDeclinePartner.module.scss';

const PopupDeclinePartner = ({ dispatch, request }) => {
  const text = `Вы собираетесь отклонить запрос от ${request.partner.fullName}. Если вы продолжите, он будет удален.`;

  function onClose() {
    dispatch(hidePopupDeclinePartner());
  }

  function onSuccess() {
    dispatch(declinePartnerRequest(request.partner.platform, request.id));
  }

  return createPortal(
    <Overlay
      title="Вы точно хотите отклонить запрос?"
      icon={iconEmojiPuzzled}
      text={() => text}
      nav={(props) => <PopupDeclinePartnerNav {...props} />}
      onClose={onClose}
      onSuccess={onSuccess}
    />,
    document.body,
  );
};

PopupDeclinePartner.propTypes = {
  dispatch: PropTypes.func.isRequired,
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    partner: PropTypes.shape({}),
  }),
};

const PopupDeclinePartnerNav = ({ onClose, onSuccess }) => (
  <div className={styles.popupDeclinePartnerNav}>
    <button onClick={onClose}>Отмена</button>
    <button onClick={onSuccess}>Отклонить</button>
  </div>
);

PopupDeclinePartnerNav.propTypes = {
  onClose: PropTypes.func.isRequired,
  onSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  request: getDeclineRequest()(state),
});

export default connect(mapStateToProps)(PopupDeclinePartner);
