import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import MoneyInput from '../MoneyInput/MoneyInput';

import {
  getCommissionText,
  getTotalSumByMoneyTransferMethod,
} from 'modules/money-transfer/selectors';

import formatNumber from 'helpers/formatNumber';
import pennyToRubles from '../../helpers/pennyToRubles';

import './MoneyInputAndPaymentButton.scss';

const MoneyInputAndPaymentButton = ({
  sum,
  onSumChange,
  onPay,
  isSumValid,
  isErrorSum,
  transferAll,
}) => {
  const { paymentMethod } = useParams();
  const commissionText = useSelector(getCommissionText(paymentMethod));
  const totalSum = pennyToRubles(useSelector(getTotalSumByMoneyTransferMethod(paymentMethod)));

  return (
    <div className="money-input-and-payment-button">
      <div className="money-input-and-payment-button__input-wrap">
        <MoneyInput
          className="money-input-and-payment-button__input"
          value={sum}
          onChange={onSumChange}
          isValid={isSumValid}
        />
        {isErrorSum && !isSumValid && (
          <div className="money-input-and-payment-button__error-message">{isErrorSum}</div>
        )}
        {!isErrorSum && !isSumValid && (
          <div className="money-input-and-payment-button__error-message">
            При проверке произошла ошибка
          </div>
        )}
        {isSumValid && (
          <div
            className="money-input-and-payment-button__commission-message"
            dangerouslySetInnerHTML={{ __html: commissionText }}
          ></div>
        )}
        {transferAll && (
          <div className="money-input-and-payment-button__transfer-all" onClick={transferAll}>
            Вывести все
          </div>
        )}
      </div>
      <div className="money-input-and-payment-button__button-wrap">
        <button
          className="money-input-and-payment-button__button"
          disabled={!sum || !isSumValid}
          onClick={onPay}
        >
          Оплатить {sum > 0 && `${formatNumber(totalSum.toFixed(2))} ₽`}
        </button>
      </div>
    </div>
  );
};

MoneyInputAndPaymentButton.propTypes = {
  sum: PropTypes.string.isRequired,
  onSumChange: PropTypes.func.isRequired,
  onPay: PropTypes.func.isRequired,
  isSumValid: PropTypes.bool.isRequired,
  isErrorSum: PropTypes.string,
};

export default MoneyInputAndPaymentButton;
