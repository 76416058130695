import React, { useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Components
import SelfEmployedEnterINN from 'modules/authLegasy/components/SelfEmployedEnterINN/SelfEmployedEnterINN';
import SelfEmployedWaiting from 'modules/authLegasy/components/SelfEmployedWaiting/SelfEmployedWaiting';
import SelfEmployedSuccess from 'modules/authLegasy/components/SelfEmployedSuccess/SelfEmployedSuccess';
import SelfEmployedFailed from 'modules/authLegasy/components/SelfEmployedFailed/SelfEmployedFailed';
import SelfEmployedNoSz from 'modules/authLegasy/components/SelfEmployedNoSz/SelfEmployedNoSz';
import SelfEmployedNoRights from 'modules/authLegasy/components/SelfEmployedNoRights/SelfEmployedNoRights';
import SelfEmployedAlreadyRegistered from 'modules/authLegasy/components/SelfEmployedAlreadyRegistered/SelfEmployedAlreadyRegistered';

// Styles
import './SelfEmployedRegistrationContainer.scss';

const SelfEmployedRegistration = ({ isMenuOpen }) => {
  const [currentStep, setCurrentStep] = useState(1);

  return (
    <div
      className={cx('se-registration-container', {
        'se-registration-container_menu-open': isMenuOpen,
      })}
      data-testid="se-registration-container"
    >
      {currentStep === 1 && (
        <SelfEmployedEnterINN
          className="se-register__step-1"
          onNextStepButtonClick={() => setCurrentStep(2)}
          onSuccessSERegistration={() => setCurrentStep(3)}
          onFailedSERegistration={() => setCurrentStep(4)}
          onAlreadyRegistered={() => setCurrentStep(5)}
          onNoSz={() => setCurrentStep(6)}
          onNoRights={() => setCurrentStep(7)}
        />
      )}

      {currentStep === 2 && (
        <SelfEmployedWaiting
          className="se-register__step-2"
          onSuccessSERegistration={() => setCurrentStep(3)}
          onFailedSERegistration={() => setCurrentStep(4)}
          onAlreadyRegistered={() => setCurrentStep(5)}
          onNoSz={() => setCurrentStep(6)}
          onNoRights={() => setCurrentStep(7)}
          onINNNumberChange={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 3 && <SelfEmployedSuccess className="se-register__step-3" />}

      {currentStep === 4 && (
        <SelfEmployedFailed className="se-register__step-4" onChangeINN={() => setCurrentStep(1)} />
      )}

      {currentStep === 5 && (
        <SelfEmployedAlreadyRegistered
          className="se-register__step-5"
          onChangeINN={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 6 && (
        <SelfEmployedNoSz
          className="se-register__step-no-sz"
          onChangeINN={() => setCurrentStep(1)}
        />
      )}

      {currentStep === 7 && (
        <SelfEmployedNoRights
          className="se-register__step-no-sz"
          onChangeINN={() => setCurrentStep(1)}
        />
      )}
    </div>
  );
};

SelfEmployedRegistration.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(SelfEmployedRegistration);
