import * as types from '../actions/action-types';

const requestStages = {
  fetching: false,
  success: false,
  fail: false,
};

const initialState = {
  // информация о конкректном партнере
  layers: {
    checkVisible: false,
    popupCheckVisible: false,
    popupCalendarVisible: false,
    inlineCalendarVisible: false,
    popupEditBankCardVisible: false,
    popupEditBankCardSuccessVisible: false,
    popupEditBankCardFailVisible: false,
    popupAddPartnerVisible: false,
    popupAddPartnerSuccessVisible: false,
    popupAddPartnerFailVisible: false,
    popupDeclinePartnerVisible: false,
  },
  data: {
    popupEditBankCardPartnershipId: 0,
    popupAddPartnerRequestId: 0,
    popupDeclinePartnerRequestId: 0,
    popupCheckPaymentId: 0,
    checkPaymentId: 0,
  },
  paymentsRangeTab: {
    current: 1,
    previous: 1,
  },
  paymentsRange: {
    startDate: null,
    endDate: null,
  },
  documentList: {
    items: [],
    hasMore: false,
  },
  payments: {
    count: 0,
    sum: 0,
    items: [],
    hasMore: false,
  },

  getPartnerDocumentList: { ...requestStages },
  getPartnerPaymentList: { ...requestStages },
  getPartnerPaymentStats: { ...requestStages },
  confirmPartner: { ...requestStages },
  declinePartner: { ...requestStages },
  editPartnerBankCard: { ...requestStages },
};

const partnersReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_PARTNER_DOCUMENT_LIST_FETCHING:
      return {
        ...state,
        getPartnerDocumentList: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_PARTNER_DOCUMENT_LIST_SUCCESS: {
      const hasMore = action.payload.length !== 0;

      return {
        ...state,
        getPartnerDocumentList: {
          fetching: false,
          success: true,
          fail: false,
        },
        documentList: {
          items: [...state.documentList.items, ...action.payload],
          hasMore,
        },
      };
    }

    case types.GET_PARTNER_DOCUMENT_LIST_FAIL:
      return {
        ...state,
        getPartnerDocumentList: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CLEAR_PARTNER_DOCUMENT_LIST:
      return {
        ...state,
        getPartnerDocumentList: {
          fetching: false,
          success: false,
          fail: false,
        },
        documentList: {
          items: [],
          hasMore: false,
        },
      };

    case types.GET_PARTNER_PAYMENT_LIST_FETCHING:
      return {
        ...state,
        getPartnerPaymentList: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_PARTNER_PAYMENT_LIST_SUCCESS: {
      const hasMore = action.payload.payouts.length === 50;

      return {
        ...state,
        getPartnerPaymentList: {
          fetching: false,
          success: true,
          fail: false,
        },
        payments: {
          count: action.payload.payoutsCount,
          sum: action.payload.payoutsSum,
          items: [...state.payments.items, ...action.payload.payouts],
          hasMore,
        },
      };
    }

    case types.GET_PARTNER_PAYMENT_LIST_FAIL:
      return {
        ...state,
        getPartnerPaymentList: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CLEAR_PARTNER_PAYMENT_LIST:
      return {
        ...state,
        getPartnerPaymentList: {
          fetching: false,
          success: false,
          fail: false,
        },
        payments: {
          count: 0,
          sum: 0,
          items: [],
          hasMore: false,
        },
      };

    case types.GET_PARTNER_PAYMENT_STATS_FETCHING:
      return {
        ...state,
        getPartnerPaymentStats: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.GET_PARTNER_PAYMENT_STATS_SUCCESS:
      return {
        ...state,
        getPartnerPaymentStats: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.GET_PARTNER_PAYMENT_STATS_FAIL:
      return {
        ...state,
        getPartnerPaymentStats: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.CONFIRM_PARTNER_REQUEST_FETCHING:
      return {
        ...state,
        confirmPartner: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.CONFIRM_PARTNER_REQUEST_SUCCESS:
      return {
        ...state,
        confirmPartner: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.CONFIRM_PARTNER_REQUEST_FAIL:
      return {
        ...state,
        confirmPartner: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.DECLINE_PARTNER_REQUEST_FETCHING:
      return {
        ...state,
        declinePartner: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.DECLINE_PARTNER_REQUEST_SUCCESS:
      return {
        ...state,
        declinePartner: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.DECLINE_PARTNER_REQUEST_FAIL:
      return {
        ...state,
        declinePartner: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.EDIT_PARTNER_BANK_CARD_FETCHING:
      return {
        ...state,
        editPartnerBankCard: {
          fetching: true,
          success: false,
          fail: false,
        },
      };

    case types.EDIT_PARTNER_BANK_CARD_SUCCESS:
      return {
        ...state,
        editPartnerBankCard: {
          fetching: false,
          success: true,
          fail: false,
        },
      };

    case types.EDIT_PARTNER_BANK_CARD_FAIL:
      return {
        ...state,
        editPartnerBankCard: {
          fetching: false,
          success: false,
          fail: true,
        },
      };

    case types.SHOW_CHECK:
      return {
        ...state,
        layers: {
          ...state.layers,
          checkVisible: true,
        },
        data: {
          ...state.data,
          checkPaymentId: action.payload,
        },
      };

    case types.HIDE_CHECK:
      return {
        ...state,
        layers: {
          ...state.layers,
          checkVisible: false,
        },
        data: {
          ...state.data,
          checkPaymentId: 0,
        },
      };

    case types.SHOW_POPUP_CHECK:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupCheckVisible: true,
        },
        data: {
          ...state.data,
          popupCheckPaymentId: action.payload,
        },
      };

    case types.HIDE_POPUP_CHECK:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupCheckVisible: false,
        },
        data: {
          ...state.data,
          popupCheckPaymentId: 0,
        },
      };

    case types.SHOW_POPUP_CALENDAR:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupCalendarVisible: true,
        },
      };

    case types.HIDE_POPUP_CALENDAR:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupCalendarVisible: false,
        },
      };

    case types.SHOW_INLINE_CALENDAR:
      return {
        ...state,
        layers: {
          ...state.layers,
          inlineCalendarVisible: true,
        },
      };

    case types.HIDE_INLINE_CALENDAR:
      return {
        ...state,
        layers: {
          ...state.layers,
          inlineCalendarVisible: false,
        },
      };

    case types.SHOW_POPUP_EDIT_BANK_CARD:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupEditBankCardVisible: true,
        },
        data: {
          ...state.data,
          popupEditBankCardPartnershipId: action.payload,
        },
      };

    case types.HIDE_POPUP_EDIT_BANK_CARD:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupEditBankCardVisible: false,
        },
        data: {
          ...state.data,
          popupEditBankCardPartnershipId: 0,
        },
      };

    case types.SHOW_POPUP_ADD_PARTNER:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupAddPartnerVisible: true,
        },
        data: {
          ...state.data,
          popupAddPartnerRequestId: action.payload,
        },
      };

    case types.HIDE_POPUP_ADD_PARTNER:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupAddPartnerVisible: false,
        },
        data: {
          ...state.data,
          popupAddPartnerRequestId: 0,
        },
      };

    case types.SHOW_POPUP_EDIT_BANK_CARD_SUCCESS:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupEditBankCardSuccessVisible: true,
        },
      };

    case types.HIDE_POPUP_EDIT_BANK_CARD_SUCCESS:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupEditBankCardSuccessVisible: false,
        },
      };

    case types.SHOW_POPUP_EDIT_BANK_CARD_FAIL:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupEditBankCardFailVisible: true,
        },
      };

    case types.HIDE_POPUP_EDIT_BANK_CARD_FAIL:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupEditBankCardFailVisible: false,
        },
      };

    case types.SHOW_POPUP_ADD_PARTNER_SUCCESS:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupAddPartnerSuccessVisible: true,
        },
      };

    case types.HIDE_POPUP_ADD_PARTNER_SUCCESS:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupAddPartnerSuccessVisible: false,
        },
      };

    case types.SHOW_POPUP_ADD_PARTNER_FAIL:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupAddPartnerFailVisible: true,
        },
      };

    case types.HIDE_POPUP_ADD_PARTNER_FAIL:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupAddPartnerFailVisible: false,
        },
      };

    case types.SHOW_POPUP_DECLINE_PARTNER:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupDeclinePartnerVisible: true,
        },
        data: {
          ...state.data,
          popupDeclinePartnerRequestId: action.payload,
        },
      };

    case types.HIDE_POPUP_DECLINE_PARTNER:
      return {
        ...state,
        layers: {
          ...state.layers,
          popupDeclinePartnerVisible: false,
        },
        data: {
          ...state.data,
          popupDeclinePartnerRequestId: 0,
        },
        declinePartner: {
          fetching: false,
          success: false,
          fail: false,
        },
      };

    case types.SET_PARTNER_PAYMENTS_RANGE:
      return {
        ...state,
        paymentsRange: {
          startDate: action.payload.startDate,
          endDate: action.payload.endDate,
        },
      };

    case types.SET_PARTNER_PAYMENTS_CURRENT_RANGE_TAB:
      return {
        ...state,
        paymentsRangeTab: {
          previous: state.paymentsRangeTab.current,
          current: action.payload,
        },
      };

    case types.RESTORE_PARTNER_PAYMENTS_CURRENT_RANGE_TAB:
      return {
        ...state,
        paymentsRangeTab: {
          ...state.paymentsRangeTab,
          current: state.paymentsRangeTab.previous,
        },
      };

    default:
      return state;
  }
};

export default partnersReducer;
