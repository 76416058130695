/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { LineText, WindowUI } from 'ui';

import { ReactComponent as DoubtSmile } from '../../assets/doubt-smile.svg';

export const AlertOfDeclineChangeStatus = ({ onDecline, closeDecline, isRequestError }) => (
  <WindowUI.Confirm
    header="Отклонить?"
    emoji={<DoubtSmile />}
    approveButtonText="Не отклонять"
    rejektButtonText="Отклонить"
    onClose={closeDecline}
    onApprove={closeDecline}
    onRejekt={onDecline}
  >
    <AlertContentWraperStyled>
      <LineTextStyled>
        Вы собираетесь отклонить документ, в этом случае
        <br /> он не будет подписан.
      </LineTextStyled>
      {isRequestError && <LineTextErrorStyled>{isRequestError}</LineTextErrorStyled>}
    </AlertContentWraperStyled>
  </WindowUI.Confirm>
);

AlertOfDeclineChangeStatus.propTypes = {
  closeDecline: PropTypes.func.isRequired,
  onDecline: PropTypes.func.isRequired,
  isRequestError: PropTypes.string,
};

AlertOfDeclineChangeStatus.defaultProps = {
  isRequestError: null,
};

const AlertContentWraperStyled = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
`;

const LineTextStyled = styled(LineText)`
  text-align: center;
`;

const LineTextErrorStyled = styled(LineTextStyled)`
  color: #ff2d55;
`;
