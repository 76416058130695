import PropTypes from 'prop-types';
import React, { useCallback } from 'react';
import styled from 'styled-components';
import { PTClassNames, PTChildrenElement } from 'tools/types/propTypes';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';

export const ButtonLink = ({ children, onClick, url, className }) => {
  const onCurrentClick = useCallback(
    (event) => {
      if (!url) {
        event.preventDefault();
        onClick(event);
      }
    },
    [onClick, url],
  );

  return (
    <LinkComponent className={className} url={url} onClick={onCurrentClick}>
      {children}
    </LinkComponent>
  );
};

const LinkComponent = styled.a.attrs(({ url, onClick, className }) => ({
  className,
  ...(url ? { href: url } : { onClick }),
}))`
  ${getFont(WEIGHT['rubik-regular'])}
  text-decoration: none;
  cursor: pointer;
`;

ButtonLink.propTypes = {
  children: PTChildrenElement.isRequired,
  onClick: PropTypes.func,
  url: PropTypes.string,
  className: PTClassNames,
};

ButtonLink.defaultProps = {
  onClick: (f) => f,
  url: null,
  title: false,
  className: '',
};
