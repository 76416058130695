import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { useParams } from 'react-router-dom';

import Card from '../Card/Card';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import CardHeaderSz from '../CardHeaderSz/CardHeaderSz';
import CardHeaderQiwi from '../CardHeaderQiwi/CardHeaderQiwi';
import CardHeaderUMoney from '../CardHeaderUMoney/CardHeaderUMoney';
import CardHeaderWebMoney from '../CardHeaderWebMoney/CardHeaderWebMoney';
import CardHeaderSBP from '../CardHeaderSBP/CardHeaderSBP';

import { ReactComponent as IconPayment } from 'static/assets/icon-payment-process.svg';

import { checkMoneyTransferStatus } from 'modules/money-transfer/actions';

import './StatusLoaderCard.scss';

const StatusLoaderCard = ({ dispatch }) => {
  const { paymentMethod } = useParams();

  /* eslint-disable react-hooks/exhaustive-deps */
  React.useEffect(() => {
    dispatch(checkMoneyTransferStatus(paymentMethod));
  }, []);

  return (
    <Card className="money-transfer-status-loader-card">
      <header className="money-transfer-status-loader-card__header">
        {paymentMethod === 'bankcard' && <CardHeaderSz />}
        {paymentMethod === 'sz' && <CardHeaderSz />}
        {paymentMethod === 'sbp' && <CardHeaderSBP />}
        {paymentMethod === 'webmoney' && <CardHeaderWebMoney />}
        {paymentMethod === 'qiwi' && <CardHeaderQiwi />}
        {paymentMethod === 'umoney' && <CardHeaderUMoney />}
      </header>

      <div className="money-transfer-status-loader-card__content">
        <div className="money-transfer-status-loader-card__content-loader">
          <CircleLoader width={60} height={60} />
          <IconPayment className="money-transfer-status-loader-card__content-loader-icon" />
        </div>
        <div className="money-transfer-status-loader-card__content-message">
          Платеж обрабатывается. Это может занять некоторое время. Пожалуйста, подождите.
        </div>
      </div>
    </Card>
  );
};

StatusLoaderCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(StatusLoaderCard);
