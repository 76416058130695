import React from 'react';

const HintHowMoneyboxWork = () => (
  <div className="hint__text-wrap">
    <div className="hint__text">
      <p>
        Воспользуйтесь сервисом “Копим,” если Вам нужно организовать массовый сбор денег. Создайте
        шаблон платежной формы, сгенерируйте ссылку или QR-код и отправьте его тем, кто хочет
        поучаствовать в Вашем сборе.
      </p>

      <p className="mod-no-margin-bottom">Чтобы создать шаблон платежной формы:</p>

      <ul>
        <li>Задайте фон платежной формы</li>
        <li>Опишите цель сбора, определите, какую сумму и срок (если нужно)</li>
        <li>Определите, какую информацию Вы хотите получить от участника сбора</li>
      </ul>

      <p>
        Затем сгенерируйте ссылку или QR-код, скопируйте на главной странице и перешлите всем
        участникам сбора.
      </p>

      <p className="mod-no-margin-bottom">
        Чтобы перечислить сумму сбора на Ваш кошелек, плательщику необходимо:
      </p>

      <ul>
        <li>определить сумму платежа, если Вы не задали фиксированную</li>
        <li>указать личные данные (если нужно)</li>
        <li>выбрать способ оплаты и заполнить платежные реквизиты</li>
        <li>нажать “Оплатить”</li>
      </ul>

      <p>
        Сумма платежа будет зачислена на Ваш баланс. Полученные деньги не регистрируются в ФНС как
        доход.
      </p>

      <p>
        Отслеживать баланс и срок до окончания сбора по каждой созданной копилке можно на главной
        странице раздела “Копим.”
      </p>
    </div>
  </div>
);

export default HintHowMoneyboxWork;
