import React from 'react';
import { Link } from 'react-router-dom';

const HintWhereAreMyRequests = () => {
  /**
   * Записываем в localStorage, что мы переходим на страницу
   * Истории со страницы запроса денег, чтобы открыть там нужную вкладку
   * */
  const handleRequestsHistoryLink = () => {
    window.localStorage.setItem('historyTab', '1');
  };

  return (
    <div className="hint__text-wrap">
      <div className="hint__text">
        <p>
          Все ваши созданные запросы хранятся в таблице раздела{' '}
          <Link to="/lk/history" className="hint__text-link">
            «История»
          </Link>
          . Таблица имеет закладки, поэтому нужно открыть вкладку{' '}
          <Link to="/lk/history" className="hint__text-link" onClick={handleRequestsHistoryLink}>
            «Запросы»
          </Link>
          .
        </p>
        <p>Там сможете посмотреть статус запроса или более подробную информацию о нем.</p>
      </div>
    </div>
  );
};

export default HintWhereAreMyRequests;
