import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import HintHowToLiftLimits from 'modules/profileLegasy/components/Hint/HintHowToLiftLimits';
import Hint from 'modules/shared/components/Hint/Hint';
import OpenPopupButton from 'modules/shared/components/OpenPopupButton/OpenPopupButton';

import { HINT_TIMEOUTS } from 'constants/index.js';

const LiftLimits = ({ isMenuOpen }) => {
  /** Хука для попапа - как повысить лимиты */
  const [
    isDropLimitsRiseHintOpened,
    dropElLimitsRiseHint,
    toggleDropLimitsRiseHint,
    closeExplicitlyLimitsRiseHint,
  ] = useToggleAndOutClick();

  return (
    <>
      <OpenPopupButton
        onClick={toggleDropLimitsRiseHint}
        title="Как повысить лимиты?"
        className="profile-settings-profile-tab__hint-item"
      />

      {/* ПОПАП - КАК ПОВЫСИТЬ ЛИМИТЫ */}
      <CSSTransition
        in={isDropLimitsRiseHintOpened}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <Hint
          ref={dropElLimitsRiseHint}
          onCloseBtnClick={closeExplicitlyLimitsRiseHint}
          className="profile-settings-profile-tab__lift-sidebar"
          classNameInner="profile-settings-profile-tab__lift-inner"
          title="Как повысить лимиты?"
          isMenuOpen={isMenuOpen}
        >
          <HintHowToLiftLimits />
        </Hint>
      </CSSTransition>
      {/* /ПОПАП - КАК ПОВЫСИТЬ ЛИМИТЫ */}
    </>
  );
};

LiftLimits.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default LiftLimits;
