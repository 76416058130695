import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import NumberFormat from 'react-number-format';

import './SumInput.scss';

const SumInput = ({
  className,
  label,
  value,
  setSum,
  disabled,
  isReadOnly,
  minSum,
  maxSum,
  required,
  placeholder,
}) => {
  /** Валидируем введенную сумму */
  const handleSumChange = (values) => {
    const { floatValue, value } = values;
    if (!maxSum && !minSum) {
      setSum(0);
    } else if (floatValue < minSum) {
      setSum(minSum);
    } else if (floatValue > maxSum) {
      setSum(maxSum);
    } else if (value === '') {
      setSum('');
    } else {
      setSum(value);
    }
  };

  return (
    <div
      className={cx('sum-input', {
        [className]: className,
        'sum-input_focused': value,
      })}
      data-testid="sum-input-wrap"
    >
      <NumberFormat
        className="sum-input__input"
        value={value}
        thousandSeparator=" "
        allowNegative={false}
        allowEmptyFormatting={false}
        decimalScale={2}
        isNumericString
        suffix=" ₽"
        allowedDecimalSeparators={[',', '.']}
        type="tel"
        disabled={disabled}
        readOnly={isReadOnly}
        required={required}
        onValueChange={(values) => handleSumChange(values)}
        placeholder={placeholder}
        isAllowed={(values) => {
          const { value } = values;

          if (value.charAt(0) === '0') {
            return value.charAt(1) === '.';
          }

          return true;
        }}
        data-testid="sum-input"
      />
      <p className="sum-input__label">{label}</p>
    </div>
  );
};

SumInput.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string.isRequired,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  setSum: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isReadOnly: PropTypes.bool,
  minSum: PropTypes.number,
  maxSum: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
};

SumInput.defaultProps = {
  value: '',
  className: null,
  disabled: false,
  isReadOnly: false,
  minSum: 1,
  maxSum: 15000,
  required: false,
  placeholder: '',
};

export default SumInput;
