import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as IconNice } from 'static/assets/icon-nice.svg';

// Styles
import './NoTaxesMessages.scss';

const NoTaxesMessages = ({ className }) => {
  return (
    <div
      className={cx('no-taxes-message', {
        [className]: className,
      })}
      data-testid="no-taxes-message"
    >
      <div className="no-taxes-message__icon-wrapper">
        <IconNice className="no-taxes-message__icon" />
      </div>

      <div className="no-taxes-message__text-wrap">
        <h2 className="no-taxes-message__title">Начисленных налогов нет</h2>
        <div className="no-taxes-message__text">
          В ФНС нет информации по вашим задолженностям НПД
        </div>
      </div>
    </div>
  );
};

NoTaxesMessages.propTypes = {
  className: PropTypes.string,
};

NoTaxesMessages.defaultProps = {
  className: null,
};

export default NoTaxesMessages;
