import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import StandardHoverButton from 'modules/shared/components/StandardHoverButton/StandardHoverButton';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

import iconRock from 'static/assets/icon-emoji-rock.png';
import { ReactComponent as IconLock } from 'static/assets/icon-lock.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

export const SelfEmployedSuccess = ({ className, userINN, isMenuOpen }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  return (
    <div
      className={cx('se-register', {
        [className]: className,
      })}
      data-testid="se-register"
      data-cy="se-register-success"
    >
      <div className="se-register__container container">
        <div className="se-register__content">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="se-register__title" data-cy="se-register-success-title">
              Статус самозанятого подтвержден
            </h1>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="se-register__desc-wrap animation-from-bottom-to-top">
              <div className="se-register__desc">
                Вы успешно подтвердили статус. Теперь можете пользоваться всеми разделами сервиса
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <div className="se-register__form animation-from-bottom-to-top">
              <div className="form form_register">
                <div className="form__block">
                  <div className="form__input-holder">
                    <InputMask
                      type="text"
                      mask="9999-9999-9999"
                      className="form__input form__input_success"
                      defaultValue={userINN}
                      disabled
                      readOnly
                      data-testid="se-inn-input"
                    />

                    {windowSize.innerWidth <= 700 && (
                      <IconLock className="form__password-secure-lock form__password-secure-lock_green" />
                    )}
                  </div>

                  {windowSize.innerWidth > 700 && (
                    <div className="form__loader-wrap">
                      <CircleProgress
                        value={0}
                        color="#00adb5"
                        className="form__loader"
                        radius={28}
                      />
                      <IconLock className="form__password-secure-lock form__password-secure-lock_green" />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <div className="se-register__btn">
              <StandardHoverButton
                title="Перейти в кошелек"
                isLink
                className="js-have-to-handle"
                href="/lk"
              />
            </div>
          </CSSTransition>
        </div>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={!isMenuOpen && isRendered && windowSize.innerWidth > 700}
          timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
          unmountOnExit
        >
          <div className="se-register__hint-block">
            <div className="se-register__hint">
              <p className="se-register__hint-text">
                Ура! Все отлично. Наслаждайтесь сервисом по полной!
                <img
                  src={iconRock}
                  alt=""
                  className="se-register__hint-icon se-register__hint-icon_rock"
                  data-testid="icon-rock"
                />
              </p>

              <IconTriangle className="se-register__hint-triangle" />
            </div>
          </div>
        </CSSTransition>
      </div>
    </div>
  );
};

SelfEmployedSuccess.propTypes = {
  className: PropTypes.string.isRequired,
  userINN: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  userINN: state.auth.INN,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(SelfEmployedSuccess);
