import { normalizePhone } from 'helpers';
import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';

// Actions
import { setUserPhone } from 'modules/authLegasy/actions';

// Icons
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';

// Styles
import './RegistrationPhoneInput.scss';

export const RegistrationPhoneInput = ({ isValid, phone, dispatch }) => {
  /** Set phone */
  const handleFieldChange = ({ target }) => {
    const normPhone = normalizePhone(target.value);
    dispatch(setUserPhone(normPhone));
  };

  return (
    <div className="registration-phone-input">
      <InputMask
        className="registration-phone-input__itself"
        type="tel"
        value={phone}
        onChange={handleFieldChange}
        maskChar={null}
        mask="+7\ (999) 999-99-99"
        placeholder="Введите номер телефона"
        required
        data-cy="register-phone-input"
      />

      {isValid && <IconCheck className="registration-phone-input__check" />}
    </div>
  );
};

RegistrationPhoneInput.propTypes = {
  isValid: PropTypes.bool.isRequired,
  dispatch: PropTypes.func.isRequired,
  phone: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  phone: state.auth.phone,
});

export default connect(mapStateToProps)(RegistrationPhoneInput);
