import React, { useState } from 'react';
import cx from 'classnames';

const HintHowToKnowINN = () => {
  const [currentTabId, setCurrentTabId] = useState(0);

  return (
    <>
      <div className="hint-auth__list">
        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 0,
            })}
            type="button"
            onClick={() => setCurrentTabId(0)}
          >
            <span className="hint-auth__tab-item-text">Через мобильное приложение «Мой налог»</span>
          </button>

          {currentTabId === 0 && (
            <ul className="hint-auth__tab-content">
              <li className="hint-auth__list-item">
                1. Запустите приложение
                <a
                  href="https://npd.nalog.ru/app/"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hint-auth__list-item-link"
                >
                  «Мой Налог»
                </a>
              </li>
              <li className="hint-auth__list-item">2. Зайдите в раздел «Настройки»</li>
              <li className="hint-auth__list-item">3. Найдите блок «ИНН» и скопируйте его</li>
            </ul>
          )}
        </div>

        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 1,
            })}
            type="button"
            onClick={() => setCurrentTabId(1)}
          >
            <span className="hint-auth__tab-item-text">Через веб-версию сайта</span>
          </button>

          {currentTabId === 1 && (
            <ul className="hint-auth__tab-content">
              <li className="hint-auth__list-item">
                1. Зайдите на
                <a
                  href="https://service.nalog.ru/inn.do"
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hint-auth__list-item-link"
                >
                  сервис ФНС
                </a>
              </li>
              <li className="hint-auth__list-item">
                2. Заполните обязательные поля ФИО, дата рождения
              </li>
              <li className="hint-auth__list-item">3. Выберите желаемый вид документа</li>
              <li className="hint-auth__list-item">4. Укажите серию и номер этого документа</li>
              <li className="hint-auth__list-item">
                5. Нажмите кнопку «Отправить запрос» и ожидайте ответа
              </li>
            </ul>
          )}
        </div>
      </div>
    </>
  );
};

export default HintHowToKnowINN;
