import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import './CircleWithEmoji.scss';

const CircleWithEmoji = ({ className, emoji }) => {
  return (
    <div
      className={cx('circle-with-emoji', {
        [className]: className,
      })}
      data-testid="circle-with-emoji"
    >
      <img
        src={emoji}
        alt=""
        className="circle-with-emoji__image"
        data-testid="circle-with-emoji-img"
      />
    </div>
  );
};

CircleWithEmoji.propTypes = {
  className: PropTypes.string,
  emoji: PropTypes.string.isRequired,
};

CircleWithEmoji.defaultProps = {
  className: null,
};

export default CircleWithEmoji;
