import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import phone3 from 'static/assets/phone-3.png';
import phone4 from 'static/assets/phone-4.png';

const ManualPageStepMyTaxPassport = ({ returnBack, setStepGoToWallet }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  const taxPassportRegistrationList = [
    {
      id: 1,
      text:
        'Укажите номер своего телефона, вам придет СМС с кодом для его подтверждения. Это защита от регистрации без вашего ведома.',
    },
    {
      id: 2,
      text: 'Укажите регион ведения деятельности.',
    },
    {
      id: 3,
      text: 'Отсканируйте паспорт, следуя инструкциям приложения.',
    },
    {
      id: 4,
      text: 'Далее приложение попросит вас сфотографироваться.',
    },
    {
      id: 5,
      text:
        'После того, как вы подтвердите регистрацию, ваше заявление будет отправлено в налоговую инспекцию.',
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          {windowSize.innerWidth > 760 && (
            <>
              <ReturnButton onClick={returnBack} className="manual-page__return-link" />

              <h1 className="manual-page__title">Регистрация по паспорту</h1>
            </>
          )}

          <ol className="manual-page__text-list">
            {taxPassportRegistrationList.map(item => (
              <li className="manual-page__list-item" key={item.id}>
                <p className="manual-page__list-item-number">{item.id}</p>
                <div className="manual-page__list-item-text">{item.text}</div>
              </li>
            ))}
          </ol>

          <div className="manual-page__button-list">
            <StandardButton
              title="Далее"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToWallet}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          {windowSize.innerWidth <= 760 && (
            <>
              <ReturnButton onClick={returnBack} className="manual-page__return-link" />

              <h1 className="manual-page__title">Регистрация по паспорту</h1>
            </>
          )}

          <img src={phone3} alt="" className="manual-page__phone manual-page__phone_3" />
          <img src={phone4} alt="" className="manual-page__phone manual-page__phone_4" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepMyTaxPassport.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToWallet: PropTypes.func.isRequired,
};

export default ManualPageStepMyTaxPassport;
