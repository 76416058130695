import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import StubPartners from 'modules/partners/components/StubPartners';
import { ReactComponent as IconArrowRight } from 'static/assets/icon-arrow-right.svg';

import { getPartnerList } from 'modules/profileLegasy/selectors';
import './ProfileSettingsPartnersTabPartners.scss';

const ProfileSettingsPartnersTabPartners = ({ dispatch, partnerList, getPartnerListSuccess }) => {
  if (!getPartnerListSuccess) {
    return null;
  }

  if (partnerList.length === 0) {
    return <StubPartners />;
  }

  return (
    <div className="profile-settings-partners-tab__partners">
      {partnerList.map((item) => {
        let status = '';
        let statusClass = '';

        switch (item.state) {
          case 'SELFEMPLOYED':
            status = 'Подключен';
            statusClass = 'connected';
            break;
          case 'ARCHIVE':
            status = 'В архиве';
            statusClass = 'archived';
            break;
          default:
            status = 'В обработке';
            statusClass = 'other';
        }

        return (
          <Link
            key={item.id}
            to={`/lk/partner/${item.partner.platform}/${item.id}`}
            className="profile-settings-partners-tab__partners__item"
          >
            <div
              className={cx(
                'profile-settings-partners-tab__partners__status',
                `profile-settings-partners-tab__partners__status_${statusClass}`,
              )}
            >
              {status}
            </div>
            <div className="profile-settings-partners-tab__partners__name">
              {item.partner.fullName}
            </div>
            <IconArrowRight className="profile-settings-partners-tab__partners__arrow" />
          </Link>
        );
      })}
    </div>
  );
};

ProfileSettingsPartnersTabPartners.propTypes = {
  dispatch: PropTypes.func.isRequired,
  getPartnerListSuccess: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  partnerList: getPartnerList()(state),
  getPartnerListSuccess: state.profile.getPartnerList.success,
});

export default connect(mapStateToProps)(ProfileSettingsPartnersTabPartners);
