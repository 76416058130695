/** CashBox */
export const GET_ALL_SHOW_CASE_SUCCESS = 'GET_ALL_SHOW_CASE_SUCCESS';
export const GET_ALL_SHOW_CASE_FAIL = 'GET_ALL_SHOW_CASE_FAIL';
export const CREATE_SHOW_CASE_SUCCESS = 'CREATE_SHOW_CASE_SUCCESS';
export const CREATE_SHOW_CASE_FAIL = 'CREATE_SHOW_CASE_FAIL';
export const CHANGE_SHOW_CASE_SUCCESS = 'CHANGE_SHOW_CASE_SUCCESS';
export const CHANGE_SHOW_CASE_FAIL = 'CHANGE_SHOW_CASE_FAIL';

/** Save money */
export const SAVE_MONEY_GET_ALL_MONEYBOX_SUCCESS = 'SAVE_MONEY_GET_ALL_MONEYBOX_SUCCESS';
export const SAVE_MONEY_GET_ALL_MONEYBOX_FAIL = 'SAVE_MONEY_GET_ALL_MONEYBOX_FAIL';
export const SAVE_MONEY_ADD_MONEYBOX_SUCCESS = 'SAVE_MONEY_ADD_MONEYBOX_SUCCESS';
export const SAVE_MONEY_CHANGE_MONEYBOX_SUCCESS = 'SAVE_MONEY_CHANGE_MONEYBOX_SUCCESS';
export const SAVE_MONEY_CHANGE_MONEYBOX_FAIL = 'SAVE_MONEY_CHANGE_MONEYBOX_FAIL';

export const DELETE_PAYIT_SUCCESS = 'DELETE_PAYIT_SUCCESS';
export const DELETE_PAYIT_FAIL = 'DELETE_PAYIT_FAIL';

export const SET_URL_LINK_ERROR = 'SET_URL_LINK_ERROR';
export const SET_URL_LINK_LOADING = 'SET_URL_LINK_LOADING';
