import React from 'react';
import { ReactComponent as QiwiLogo } from 'static/assets/qiwi-logo.svg';
import './CardHeaderQiwi.scss';

const CardHeaderQiwi = () => (
  <div className="money-transfer-card-header-qiwi">
    <QiwiLogo className="money-transfer-card-header-qiwi__logo" />
  </div>
);

export default CardHeaderQiwi;
