/* eslint-disable import/prefer-default-export */
import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { AccountsTypeWidget } from 'modules/profile';

import {
  AuthorizationAccountDetailsTemplate,
  IdentificationAccountDetailsTemplate,
  EDOAccountDetailsTemplate,
} from './templates';

export const AccountsTypeTemplate = () => {
  const { path, url } = useRouteMatch();

  return (
    <Switch>
      <Route path={`${path}/get-edo`} exact>
        <EDOAccountDetailsTemplate />
      </Route>
      <Route path={`${path}/get-authorization`} exact>
        <AuthorizationAccountDetailsTemplate />
      </Route>
      <Route path={`${path}/get-identification`} exact>
        <IdentificationAccountDetailsTemplate />
      </Route>
      <Route path={path}>
        <AccountsTypeWidget
          urlToEDOAccountDetailsPage={`${url}/get-edo`}
          urlToAuthorizationAccountDetailsPage={`${url}/get-authorization`}
          urlToIdentificationAccountDetailsPage={`${url}/get-identification`}
        />
      </Route>
    </Switch>
  );
};
