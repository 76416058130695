import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import EditButton from 'modules/profileLegasy/components/EditButton/EditButton';
import SettingNewPassword from 'modules/profileLegasy/components/SettingNewPassword/SettingNewPassword';
import Hint from 'modules/shared/components/Hint/Hint';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';

import { HINT_TIMEOUTS } from 'constants/index.js';

const ChangePasswordWrap = ({ isMenuOpen }) => {
  /** Хука для попапа - смена пароль */
  const [
    isDropPasswordChangeOpened,
    dropElPasswordChange,
    toggleDropPasswordChange,
    closeExplicitlyPasswordChange,
  ] = useToggleAndOutClick();

  return (
    <>
      <InputWithLabelAnimation
        disabled
        value="***********"
        label="Ваш пароль"
        className="profile-settings-profile-tab__input profile-settings-profile-tab__input_password"
      >
        <EditButton action={toggleDropPasswordChange} />
      </InputWithLabelAnimation>

      {/* ПОПАП - СМЕНА ПАРОЛЯ */}
      <CSSTransition
        in={isDropPasswordChangeOpened}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <Hint
          isMenuOpen={isMenuOpen}
          ref={dropElPasswordChange}
          className="profile-settings-profile-tab__password-change-sidebar"
          classNameInner="profile-settings-profile-tab__password-change-inner"
          onCloseBtnClick={closeExplicitlyPasswordChange}
        >
          <SettingNewPassword />
        </Hint>
      </CSSTransition>
      {/* /ПОПАП - СМЕНА ПАРОЛЯ */}
    </>
  );
};

ChangePasswordWrap.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default ChangePasswordWrap;
