import { useSelector, shallowEqual } from 'react-redux';
import { useEffect, useState } from 'react';
import hmacSHA256 from 'crypto-js/hmac-sha256';

import { useCarrotQuest } from './hooks';

export const useConfiguredCarrotQuest = () => {
  const isUserAuth = useSelector((store) => store.auth.isUserAuth);
  const user = useSelector((store) => store.auth.user, shallowEqual);

  const idConnectCarrotQuest = process.env.REACT_APP_CARROT_QUEST_CONNECT_KEY || '';
  const authKeyCarrotQuest = process.env.REACT_APP_CARROT_AUTH_KEY;

  const { isReady, onReady, identify, auth, logout } = useCarrotQuest(
    idConnectCarrotQuest,
    isUserAuth && user,
  );

  document.onclick = (ev) => {
    if (ev.target.classList.contains('js-help')) {
      return window?.carrotquest?.open();
    } else {
    }
  };

  const [isAuthCarrotQuest, setIsAuthCarrotQuest] = useState(false);
  const [isIdentifyNotAuthCarrotQuest, setIsIdentifyNotAuthCarrotQuest] = useState(false);
  const [isLogoutNotAuthCarrotQuest, setIsLogoutNotAuthCarrotQuest] = useState(false);

  useEffect(() => {
    if (onReady && isReady && !isIdentifyNotAuthCarrotQuest) {
      identify({
        tag: 'wallet',
      });
      setIsIdentifyNotAuthCarrotQuest(true);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [isReady, onReady, isIdentifyNotAuthCarrotQuest]);

  useEffect(() => {
    if (isReady && !isAuthCarrotQuest && user) {
      if (isUserAuth) {
        const userIDwPrefix = `wallet_${user.id}`;
        const hash = hmacSHA256(userIDwPrefix, authKeyCarrotQuest);
        auth(userIDwPrefix, hash);
      }

      identify({
        $name: `${user.personal?.lastName || ''} ${user.personal?.firstName || ''} ${
          user.personal?.middleName || ''
        }`,
        $phone: user.login,
        tag: 'wallet',
        worker_inn: user.taxStatus?.inn || user.personal?.inn || '',
      });

      setIsAuthCarrotQuest(true);
      setIsLogoutNotAuthCarrotQuest(false);
    }
    // eslint-disable-next-line  react-hooks/exhaustive-deps
  }, [user, isUserAuth, isReady, isAuthCarrotQuest]);

  useEffect(() => {
    if (!isUserAuth && isAuthCarrotQuest && !isLogoutNotAuthCarrotQuest) {
      setIsLogoutNotAuthCarrotQuest(true);
      setIsAuthCarrotQuest(false);
      setIsIdentifyNotAuthCarrotQuest(false);
      logout();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserAuth, isAuthCarrotQuest]);
};
