import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import ShopsDropdownItem from 'modules/acquiring/components/ShopsDropdownItem/ShopsDropdownItem';

import { SHOPS_BG } from 'constants/index';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';

import './ShopsDropdown.scss';

const ShopsDropdown = ({ className, shopsDropdownList, chosenShop }) => {
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();

  return (
    <div
      className={cx('shops-dropdown', {
        [className]: className,
      })}
      data-testid="shops-dropdown"
    >
      {chosenShop &&
        shopsDropdownList
          .filter((item) => item.uuid === chosenShop)
          .map((item) => (
            <ShopsDropdownItem
              onClick={toggleDrop}
              title={item.webDomain}
              id={item.uuid}
              background={SHOPS_BG[item.ordinalNumber]}
              isOnTop
              key={item.uuid}
              status={item.status}
              isDropOpen={isDropOpen}
            />
          ))}

      {isDropOpen && (
        <div className="shops-dropdown__main" ref={dropEl}>
          <Link
            to="/lk/acquiring-settings"
            className="shops-dropdown__add-new"
            data-testid="shops-dropdown-add-new"
          >
            <div className="shops-dropdown__add-new-icon" />
            <p className="shops-dropdown__add-new-text">Добавить новый магазин</p>

            <IconArrow className="shops-dropdown__add-new-arrow" />
          </Link>

          <div className="shops-dropdown__list">
            {shopsDropdownList.map((item) => (
              <ShopsDropdownItem
                onClick={() => {
                  item.onClick();
                  closeExplicitly();
                }}
                title={item.webDomain}
                id={item.uuid}
                background={SHOPS_BG[item.ordinalNumber]}
                status={item.status}
                key={item.uuid}
                isDropOpen={isDropOpen}
              />
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

ShopsDropdown.propTypes = {
  className: PropTypes.string,
  chosenShop: PropTypes.string,
  shopsDropdownList: PropTypes.arrayOf(
    PropTypes.shape({
      ordinalNumber: PropTypes.number,
      selfEmployed: PropTypes.bool,
      status: PropTypes.string,
      uuid: PropTypes.string,
      webDomain: PropTypes.string,
      onClick: PropTypes.func,
    }),
  ),
};

ShopsDropdown.defaultProps = {
  className: null,
  shopsDropdownList: null,
  chosenShop: null,
};

export default ShopsDropdown;
