import '@selfwork/sam-fonts';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import { LineText } from 'ui';
import {
  clearAction,
  getDocumentsCertificateThunk,
  useDocDataSelector,
  useDocRequestSelector,
} from '../../models';

export const CertificateWidget = ({ id: rqId, platform }) => {
  const { name, status, id, dateSms, signingMethod, performer, customer, shA256Fingerprint } =
    useDocDataSelector();
  const { pending, fulfilled, rejected, error } = useDocRequestSelector();

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDocumentsCertificateThunk({ platform, id: rqId }));
    return () => dispatch(clearAction());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <WrapperSd>
      {pending && <CircleLoaderSd />}
      {fulfilled && (
        <BoxSd>
          <TitleSd>Сертификат электронной подписи</TitleSd>
          <BodySd>
            <ListSd>
              {customer && (
                <ItemSd>
                  <KeySd>Заказчик</KeySd>
                  <InfoSd>{customer}</InfoSd>
                </ItemSd>
              )}
              {performer && (
                <ItemSd>
                  <KeySd>Исполнитель</KeySd>
                  <InfoSd>{performer}</InfoSd>
                </ItemSd>
              )}
              {name && (
                <ItemSd>
                  <KeySd>Наименование документа</KeySd>
                  <InfoSd>{name}</InfoSd>
                </ItemSd>
              )}
              {status && (
                <ItemSd>
                  <KeySd>Статус</KeySd>
                  <InfoStatusSd status={status}>{status}</InfoStatusSd>
                </ItemSd>
              )}
              {id && (
                <ItemSd>
                  <KeySd>Идентификатор документа</KeySd>
                  <InfoSd>{id}</InfoSd>
                </ItemSd>
              )}
              {shA256Fingerprint && (
                <ItemSd>
                  <KeySd>Отпечаток SHA 256</KeySd>
                  <InfoSd>{shA256Fingerprint}</InfoSd>
                </ItemSd>
              )}
              {dateSms && (
                <ItemSd>
                  <KeySd>Время подписания</KeySd>
                  <InfoSd>{dateSms}</InfoSd>
                </ItemSd>
              )}
              {signingMethod && (
                <ItemSd>
                  <KeySd>Способ подписания</KeySd>
                  <InfoSd>{signingMethod}</InfoSd>
                </ItemSd>
              )}
            </ListSd>
          </BodySd>
        </BoxSd>
      )}
      {rejected && (
        <BoxSd>
          <TitleSd>Сертификат электронной подписи</TitleSd>
          <BodySd>
            <ListSd>
              <ItemSd>
                <KeySd>Ошибка</KeySd>
                <InfoSd>Произошла ошибка. {error || ''}.</InfoSd>
              </ItemSd>
              <ItemSd>
                <KeySd>Поддержка</KeySd>
                <InfoSd>
                  <LinkSd href="mailto:support@selfwork.ru">
                    <LineText>support@selfwork.ru</LineText>
                  </LinkSd>
                  .
                </InfoSd>
              </ItemSd>
            </ListSd>
          </BodySd>
        </BoxSd>
      )}
    </WrapperSd>
  );
};

const WrapperSd = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  background: var(--pale-grey);
  @media (min-width: 650px) {
    min-height: calc(100vh - 48px - 82px);
  }
  @media (max-width: 651px) {
    min-height: calc(100vh - 48px - 144px);
  }
`;

const CircleLoaderSd = styled(CircleLoader)`
  margin-top: 74px;
  @media (max-width: 650px) {
    margin-top: 28px;
  }
`;

const BoxSd = styled.div`
  max-width: 480px;
  margin: 0 16px;
  margin-top: 74px;
  border-radius: 20px;
  background: #ffffff;
  height: fit-content;
  margin-bottom: 80px;
  @media (max-width: 650px) {
    margin-top: 28px;
  }
  transition: all 0.3s ease;
`;

const TitleSd = styled(LineText)`
  word-break: break-word;
  display: block;
  width: 100%;
  margin: 0 auto;
  padding: 24px 40px;
  background: #616a88;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  color: #ffffff;

  font-weight: bold;
  font-size: 22px;
  line-height: 24px;
  text-align: center;
  letter-spacing: -0.4px;
`;

const BodySd = styled.div`
  display: block;
  width: 100%;
  padding: 20px 24px;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
`;

const ListSd = styled.ul`
  display: block;
`;

const ItemSd = styled.li`
  display: flex;
  flex-direction: column;
  & + & {
    margin-top: 20px;
  }
`;

const KeySd = styled(LineText)`
  word-break: break-word;
  font-size: 17px;
  line-height: 24px;
  color: #a1a7ba;
  font-family: 'IBMPlexSans';
  font-weight: 'bold';
`;
const InfoSd = styled(LineText)`
  word-break: break-word;
  font-size: 17px;
  line-height: 24px;
  font-weight: bold;
  color: #26252d;
  font-family: 'IBMPlexSans';
  font-weight: 'normal';
`;

export const DOC_COLOR_OF_STATUSES_MAP = new Map([
  ['Документ подписан', '#05A19C'],
  ['Черновик', '#FFD254'],
  ['Ожидает подписи', '#FFD254'],
  ['Документ отклонен', '#FF2D55'],
  ['Истек срок действия', '#FF2D55'],
  ['Срок действия истек', '#FF2D55'],
  ['Документ заблокирован', '#FF2D55'],
  ['Документ расторгнут', '#FF2D55'],
]);

const InfoStatusSd = styled(InfoSd)`
  color: ${(props) => DOC_COLOR_OF_STATUSES_MAP.get(props.status)};
`;

const LinkSd = styled.a`
  text-decoration: none;
  &:hover * {
    color: #007aff;
  }
  @media (min-width: 651px) {
    & + & {
      margin-left: 24px;
    }
  }
`;
