/* eslint-disable import/prefer-default-export */
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { LineText } from 'ui/atoms/LineText';
import { COLOR, WEIGHT } from 'tools/constants';
import { PTClassNames } from 'tools/types/propTypes';

export const ValueOfKeyList = ({ list, className }) =>
  list.map(({ key, value }) => (
    <WraperItemStyled key={key} className={className}>
      <KeyTextStyled>{key}</KeyTextStyled>
      <ValueTextStyled>{value}</ValueTextStyled>
    </WraperItemStyled>
  ));

ValueOfKeyList.propTypes = {
  list: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string,
      value: PropTypes.string,
    }),
  ).isRequired,
  className: PTClassNames,
};

ValueOfKeyList.defaultProps = {
  className: '',
};

const WraperItemStyled = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  &:last-of-type {
    margin-bottom: 0;
  }
`;

const KeyTextStyled = styled(LineText).attrs(() => ({
  size: '12px',
  weight: WEIGHT['rubik-regular'],
  color: COLOR['light-grey-blue'],
}))`
  line-height: 16px;
  padding-bottom: 4px;
`;

const ValueTextStyled = styled(LineText).attrs(() => ({
  size: '16px',
  weight: WEIGHT['rubik-regular'],
  color: COLOR.mainBlueBackground,
}))`
  line-height: 24px;
`;
