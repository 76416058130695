import React from 'react';
import PropTypes from 'prop-types';
import { useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import BankCard from '../BankCard/BankCard';
import SzCard from '../SzCard/SzCard';
import SBPCard from '../SBPCard/SBPCard';
import UMoneyCard from '../UMoneyCard/UMoneyCard';
import WebMoneyCard from '../WebMoneyCard/WebMoneyCard';
import QiwiCard from '../QiwiCard/QiwiCard';
import ReceiptCard from '../ReceiptCard/ReceiptCard';
import ConfirmationCard from '../ConfirmationCard/ConfirmationCard';
import StatusLoaderCard from '../StatusLoaderCard/StatusLoaderCard';
import StatusErrorCard from '../StatusErrorCard/StatusErrorCard';
import StatusSuccessCard from '../StatusSuccessCard/StatusSuccessCard';

import './Container.scss';
import {
  confirmMoneyTransferDuplicate,
  requestMoneyTransfer,
  requestMoneyTransferFetching,
} from '../../actions';
import { DuplicateConfirmAlert } from '../DuplicateConfirmAlert/DuplicateConfirmAlert';

const Container = ({
  dispatch,
  currentStep,
  requestMoneyTransferFail,
  checkMoneyTransferConfirmationViaPushFail,
  checkMoneyTransferStatusFail,
  fail_duplicate,
}) => {
  const { paymentMethod } = useParams();

  function decline() {
    dispatch(dispatch(requestMoneyTransferFetching()));
  }

  function forceDuplicate() {
    dispatch(confirmMoneyTransferDuplicate(true));
    dispatch(requestMoneyTransfer(paymentMethod));
  }

  if (
    requestMoneyTransferFail ||
    checkMoneyTransferConfirmationViaPushFail ||
    checkMoneyTransferStatusFail
  ) {
    return (
      <div className="money-transfer__container">
        <StatusErrorCard />
      </div>
    );
  }

  return (
    <div className="money-transfer__container">
      {/* данные не были заполнены */}

      {currentStep === 1 && (
        <React.Fragment>
          {paymentMethod === 'bankcard' && <BankCard />}
          {paymentMethod === 'sz' && <SzCard />}
          {paymentMethod === 'sbp' && <SBPCard />}
          {paymentMethod === 'umoney' && <UMoneyCard />}
          {paymentMethod === 'webmoney' && <WebMoneyCard />}
          {paymentMethod === 'qiwi' && <QiwiCard />}
        </React.Fragment>
      )}

      {/* данные заполнены */}
      {currentStep === 2 && <ReceiptCard />}

      {/* реквизиты проверены */}
      {currentStep === 3 && <ConfirmationCard />}

      {/* запрос на перевод подтвержден */}
      {currentStep === 4 && <StatusLoaderCard />}

      {/* статус перевода определен */}
      {currentStep === 5 && <StatusSuccessCard />}

      {fail_duplicate && (
        <DuplicateConfirmAlert
          onDecline={() => {
            decline();
          }}
          onClose={() => {
            decline();
          }}
          onApprove={() => forceDuplicate(paymentMethod)}
        />
      )}
    </div>
  );
};

Container.propTypes = {
  dispatch: PropTypes.func.isRequired,
  requestMoneyTransferFail: PropTypes.bool.isRequired,
  checkMoneyTransferConfirmationViaPushFail: PropTypes.bool.isRequired,
  checkMoneyTransferStatusFail: PropTypes.bool.isRequired,
  fail_duplicate: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  currentStep: state.moneyTransfer.currentStep,
  requestMoneyTransferFail: state.moneyTransfer.requestMoneyTransfer.fail,
  checkMoneyTransferConfirmationViaPushFail:
    state.moneyTransfer.checkMoneyTransferConfirmationViaPush.fail,
  checkMoneyTransferStatusFail: state.moneyTransfer.checkMoneyTransferStatus.fail,
  user: state.auth.user,
  fail_duplicate: state.moneyTransfer.requestMoneyTransfer.fail_duplicate,
});

export default connect(mapStateToProps)(Container);
