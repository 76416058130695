import React, { useState } from 'react';
import cx from 'classnames';

const HintWhyConfirmRights = () => {
  const [currentTabId, setCurrentTabId] = useState(null);

  return (
    <>
      <div className="hint-auth__list">
        <div className="hint-auth__text-block">
          <p className="hint-auth__text-block-title">
            Чтобы вы могли проводить все операции как самозанятый, а мы могли:
          </p>
        </div>
        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 0,
            })}
            type="button"
            onClick={() => setCurrentTabId(0)}
          >
            <span className="hint-auth__tab-item-text">Отражать доход от вашего имени</span>
          </button>

          {currentTabId === 0 && (
            <p className="hint-auth__text-block">
              Регистрировать доход от профессиональной деятельности
            </p>
          )}
        </div>

        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 1,
            })}
            type="button"
            onClick={() => setCurrentTabId(1)}
          >
            <span className="hint-auth__tab-item-text">
              Получать информации по налоговым начислениям
            </span>
          </button>

          {currentTabId === 1 && (
            <p className="hint-auth__text-block">
              Получать информацию по Вашим налоговым начислениям, сформированным на основании
              зарегистрированного дохода от профессиональной деятельности
            </p>
          )}
        </div>

        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 2,
            })}
            type="button"
            onClick={() => setCurrentTabId(2)}
          >
            <span className="hint-auth__tab-item-text">Получать информации по доходам</span>
          </button>

          {currentTabId === 2 && (
            <p className="hint-auth__text-block">
              Получать детализированную информацию обо всем доходе от профессиональной деятельности,
              зарегистрированном от Вашего имени
            </p>
          )}
        </div>

        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 3,
            })}
            type="button"
            onClick={() => setCurrentTabId(3)}
          >
            <span className="hint-auth__tab-item-text">Получать сводную информацию о доходе</span>
          </button>

          {currentTabId === 3 && (
            <p className="hint-auth__text-block">
              Получать информацию по общему доходу и использованному бонусу
            </p>
          )}
        </div>

        <div className="hint-auth__tab">
          <button
            className={cx('hint-auth__tab-item', {
              'hint-auth__tab-item_active': currentTabId === 4,
            })}
            type="button"
            onClick={() => setCurrentTabId(4)}
          >
            <span className="hint-auth__tab-item-text">
              Корректировать сведения о доходах без ограничений
            </span>
          </button>

          {currentTabId === 4 && (
            <p className="hint-auth__text-block">
              Аннулировать от Вашего имени любой доход от профессиональной деятельности
            </p>
          )}
        </div>
      </div>
    </>
  );
};

export default HintWhyConfirmRights;
