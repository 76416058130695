import '@selfwork/sam-fonts';
import React from 'react';
import styled from 'styled-components';
import { LineText } from 'ui';

export const FooterCertificateWidget = () => (
  <WrapperSd>
    <FooterText>© {new Date().getFullYear()} Самозанятые.рф</FooterText>
    <LinkSd href="mailto:support@selfwork.ru">
      <LineText>support@selfwork.ru</LineText>
    </LinkSd>
    <LinkSd href="https://xn--80aapgyievp4gwb.xn--p1ai/legal" target="_blank" rel="noreferrer" s>
      <LineText>Юридическая информация</LineText>
    </LinkSd>
  </WrapperSd>
);

const WrapperSd = styled.div`
  display: flex;
  @media (max-width: 650px) {
    flex-direction: column;
    & > *:first-child {
      margin-bottom: 16px;
    }
  }
  width: 100%;
  padding: 22px 30px;
  background: rgba(20, 95, 184, 0.07);
  & * {
    font-family: 'IBMPlexSans';
    font-weight: 'normal';
    font-size: 14px;
    line-height: 24px;
    color: #a1a7ba;
  }
`;

const FooterText = styled(LineText)`
  flex-grow: 1;
`;

const LinkSd = styled.a`
  text-decoration: none;
  &:hover * {
    color: #007aff;
  }
  @media (min-width: 651px) {
    & + & {
      margin-left: 24px;
    }
  }
`;
