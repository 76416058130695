import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { registerUser } from 'modules/authLegasy/actions';
import Loader from 'modules/authLegasy/components/Loader/Loader';
import PasswordBlock from 'modules/shared/components/PasswordBlock/PasswordBlock';

import { CONSISTENT_ANIMATIONS_DELAY } from 'constants/index';

const Step3 = ({
  onNextStepButtonClick,
  className,
  password,
  dispatch,
  smsVerification,
  phone,
  isMenuOpen,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  const handleClick = () => {
    dispatch(registerUser(smsVerification.id, password, phone))
      .then(onNextStepButtonClick)
      .then(() => {
        if (window?.ym && !!process.env.REACT_APP_YANDEX_METRIC_ID) {
          window.ym(
            Number(process.env.REACT_APP_YANDEX_METRIC_ID),
            'reachGoal',
            'registrationCompleted',
          );
        }
      })
      .catch((result) => {
        setErrorMessage((result && result.result && result.result.message) || 'Ошибка');
        setIsLoading(false);
      });
  };

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  return (
    <div
      className={cx('register', {
        [className]: className,
      })}
    >
      <div className="register__container container">
        <div className="register__content">
          <CSSTransition
            classNames="animation-text"
            in={!isMenuOpen && isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <h1 className="register__title animation-text">Установите пароль</h1>
          </CSSTransition>

          <div className="register__form">
            <div className="form form_register">
              <PasswordBlock className="password-block_register" onNextButtonClick={handleClick} />

              <CSSTransition
                classNames="animation-text"
                in={!isMenuOpen && isRendered && !!errorMessage}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <div className="form__error-message">{errorMessage}</div>
              </CSSTransition>
            </div>
          </div>
        </div>
      </div>

      {isLoading && <Loader inverted />}
    </div>
  );
};

Step3.propTypes = {
  onNextStepButtonClick: PropTypes.func.isRequired,
  className: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  smsVerification: PropTypes.shape({
    id: PropTypes.number,
  }).isRequired,
  phone: PropTypes.string.isRequired,
  password: PropTypes.string.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  password: state.auth.password,
  phone: state.auth.phone,
  smsVerification: state.auth.smsVerification,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(Step3);
