import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';
import cx from 'classnames';
import { animated, useSpring } from 'react-spring';
import Header from '../Header';
import FloatingDetails from '../FloatingDetails';
import Footer from '../Footer';
import isBankCardNumberValid from '../../helpers/isBankCardNumberValid';
import { normalizePhone } from 'helpers';

import styles from './PartnerBankCard.module.scss';

const PartnerBankCard = ({
  title,
  description,
  notice,
  buttonText,
  initialCardNumber,
  onFinish,
  onClose,
}) => {
  const [cardNumber, setCardNumber] = React.useState(() => initialCardNumber || '');
  const [isCardNumberValid, setIsCardNumberValid] = React.useState(false);
  const isCardNumberEmpty = cardNumber.length === 0;

  const headerAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  const contentAnimation = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  const footerAnimation = useSpring({
    opacity: 1,
    from: { opacity: 0 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  React.useEffect(() => {
    if (isBankCardNumberValid(cardNumber)) {
      setIsCardNumberValid(true);
    } else {
      setIsCardNumberValid(false);
    }
  }, [cardNumber]);

  function onCardNumberChange(event) {
    setCardNumber(normalizePhone(event.target.value));
  }

  return (
    <div className={styles.partnerBankCard}>
      <FloatingDetails />
      <div className={styles.partnerBankCardWrapper}>
        <animated.div
          style={{
            opacity: headerAnimation.opacity,
          }}
        >
          <Header onClose={onClose} />
        </animated.div>
        <animated.div
          style={{
            opacity: contentAnimation.opacity,
            transform: contentAnimation.y.interpolate((y) => `translateY(${y}px)`),
          }}
        >
          <div className={styles.partnerBankCardTitle}>{title}</div>
          <div className={styles.partnerBankCardForm}>
            <div
              className={cx(
                styles.partnerBankCardInputWrap,
                isCardNumberEmpty && styles.partnerBankCardInputWrapEmpty,
              )}
            >
              {!isCardNumberEmpty && (
                <p className={styles.partnerBankCardInputTitle}>номер карты</p>
              )}
              <InputMask
                type="tel"
                className={styles.partnerBankCardInput}
                placeholder="Номер карты"
                maskChar={null}
                mask="9999 9999 9999 9999 999"
                value={cardNumber}
                x-autocompletetype="cc-number"
                onChange={onCardNumberChange}
                formatChars={{
                  9: '[0-9]',
                  a: '[A-Za-z]',
                  '*': '[A-Za-z0-9]',
                  7: '[5,4,2,6]',
                }}
              />
            </div>
            <button
              className={styles.partnerBankCardButton}
              disabled={!isCardNumberValid}
              onClick={() => onFinish(cardNumber)}
            >
              {buttonText}
            </button>
          </div>
          <div className={styles.partnerBankCardText}>
            {description && <div className={styles.partnerBankCardDescription}>{description}</div>}
            <div className={styles.partnerBankCardNotice}>{notice}</div>
          </div>
        </animated.div>
      </div>
      <div className={styles.partnerBankCardFooter}>
        <animated.div
          style={{
            opacity: footerAnimation.opacity,
          }}
        >
          <Footer />
        </animated.div>
      </div>
    </div>
  );
};

PartnerBankCard.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  notice: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  initialCardNumber: PropTypes.string,
  onFinish: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default PartnerBankCard;
