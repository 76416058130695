import PropTypes from 'prop-types';
import React from 'react';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import { normalizePhone, removeCodeFromPhoneNumber } from '../../../helpers';
import { PTClassNames } from '../../../tools/types/propTypes';
import { InputMixin } from './Input';

export const PhoneInput = ({
  value,
  onChange,
  isFail,
  placeholder,
  onKeyEnter,
  required,
  disabled,
  className,
  readOnly,
}) => {
  const handleChangePhone = ({ target }) => {
    onChange(normalizePhone(target.value));
  };

  const beforeMaskedValueChange = (newState, prevState, userInput) => {
    let { value } = newState;
    if (userInput && userInput.length >= 11)
      value = `${value.slice(0, 2)}${removeCodeFromPhoneNumber(normalizePhone(userInput))}`;
    return { ...newState, value };
  };

  return (
    <InputMaskStyled
      value={value}
      onChange={handleChangePhone}
      onKeyUp={(event) => event.key === 'Enter' && onKeyEnter()}
      isFail={isFail}
      type="tel"
      maskChar={null}
      disabled={disabled}
      readOnly={readOnly}
      mask="+7 (999) 999-99-99"
      placeholder={placeholder}
      required={required}
      name="tel"
      autoComplete="tel"
      beforeMaskedValueChange={beforeMaskedValueChange}
      className={className}
    />
  );
};

PhoneInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isFail: PropTypes.bool,
  disabled: PropTypes.bool,
  placeholder: PropTypes.string,
  onKeyEnter: PropTypes.func,
  required: PropTypes.bool,
  className: PTClassNames,
  readOnly: PropTypes.bool,
};

PhoneInput.defaultProps = {
  onChange: false,
  isFail: false,
  placeholder: null,
  disabled: false,
  onKeyEnter: (f) => f,
  required: false,
  className: '',
  readOnly: false,
};

const InputMaskStyled = styled(({ isFail, className, ...props }) => (
  <InputMask className={className} {...props} />
))`
  ${InputMixin}
`;
