import React from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import PropTypes from 'prop-types';
import cx from 'classnames';

import { ReactComponent as IconCopy } from 'static/assets/icon-copy-content.svg';

import './CopyItemButton.scss';

const CopyItemButton = ({ isDisabled, copyClick, text, showTitleCondition, title, className, textWithoutMainRoute }) => {
  return (
    <>
      {isDisabled ? (
        <div
          className={cx('copy-item-button copy-item-button_disabled', {
            [className]: className,
            'copy-item-button_with-text': showTitleCondition,
          })}
          data-testid="copy-wrap"
        >
          <div className="copy-item-button__icon-wrap">
            <IconCopy className="copy-item-button__icon" />
          </div>

          {showTitleCondition && <p className="copy-item-button__title">{title}</p>}
        </div>
      ) : (
        <CopyToClipboard
          text={textWithoutMainRoute ? text : `${process.env.REACT_APP_URL}${text}`}
          className={cx('copy-item-button', {
            [className]: className,
            'copy-item-button_with-text': showTitleCondition,
          })}
          onCopy={() =>
            copyClick(`${textWithoutMainRoute ? text : process.env.REACT_APP_URL}${text}`)
          }
          data-testid="copy-button-wrap"
        >
          <button
            type="button"
            name="copyButton"
            data-testid="copy-button"
            onClick={event => {
              event.preventDefault();
              event.stopPropagation();
            }}
          >
            <span className="copy-item-button__icon-wrap">
              <IconCopy className="copy-item-button__icon" />
            </span>

            {showTitleCondition && <p className="copy-item-button__title">{title}</p>}
          </button>
        </CopyToClipboard>
      )}
    </>
  );
};

CopyItemButton.propTypes = {
  isDisabled: PropTypes.bool,
  copyClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  showTitleCondition: PropTypes.bool,
  title: PropTypes.string,
  className: PropTypes.string,
  textWithoutMainRoute: PropTypes.bool,
};

CopyItemButton.defaultProps = {
  isDisabled: false,
  showTitleCondition: false,
  title: null,
  className: null,
  textWithoutMainRoute: false,
};

export default CopyItemButton;
