import React from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';

import { INVOICE, MONEY_REQUEST } from 'constants/index';

import './PayedInvoiceText.scss';

const PayedInvoiceText = ({ className, type }) => {
  return (
    <div
      className={cx('payed-invoice-text', {
        [className]: className,
      })}
    >
      Скорее всего этот
      {type === INVOICE ? ' счёт ' : ' запрос '}
      уже был оплачен. Вы можете связаться с владельцем, чтобы получить его повторно.
    </div>
  );
};

PayedInvoiceText.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string,
};

PayedInvoiceText.defaultProps = {
  className: null,
  type: MONEY_REQUEST,
};

export default PayedInvoiceText;
