import { getPrivacyPolicy } from 'api/pages';
import React, { useEffect, useState } from 'react';

import './style.scss';
import 'static/styles/info-box.scss';

export const PrivacyPolicyTemplate = () => {
  const [privacyPolicyText, setPrivacyPolicyText] = useState(null);
  const [errorMsg, setErrorMsg] = useState(null);

  /** Загружаем политику обработки персональных данных */
  useEffect(() => {
    getPrivacyPolicy()
      .then((data) => {
        if (data && data.data) {
          setPrivacyPolicyText(data.data);
        }
      })
      .catch((error) => {
        if (error) {
          setErrorMsg('Не удалось загрузить данные.');
        }
      });
  }, []);

  return (
    <div className="container">
      <div className="privacy-policy">
        {/* eslint-disable react/no-danger */}
        <div className="info-box" dangerouslySetInnerHTML={{ __html: privacyPolicyText }} />
        {/* eslint-enable react/no-danger */}

        {errorMsg && <div className="privacy-policy__error">{errorMsg}</div>}
      </div>
    </div>
  );
};
