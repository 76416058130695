/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { PropTypes } from 'prop-types';
import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import styled from 'styled-components';
import { Card, AnimatedUI, TitleText } from 'ui';

import { getUserAuthStatus } from '../../models/client/selectors';
import { getLimits } from '../../models/identifications/selectors';
import { ValueOfKeyList, WidgetWraper } from '../ui';
import { ButtonCard, LoadingDataCards } from './ui';

export const AccountsTypeContainer = ({
  urlToAuthorizationAccountDetailsPage,
  urlToEDOAccountDetailsPage,
  urlToIdentificationAccountDetailsPage,
}) => {
  const userAuthStatus = useSelector(getUserAuthStatus, shallowEqual);
  const limits = useSelector((state) => getLimits(state, userAuthStatus), shallowEqual);

  return (
    <WidgetWraper title="Типы учетной записи" disabledOfSwitchBack>
      {Array.isArray(limits) ? (
        <AnimatedUI>
          <CardsWraperStyled>
            {limits
              .map(({ status, options, name, actionText, accountType }) => (
                <CardStyled
                  key={`${status}_${name}_${accountType}`}
                  type={status}
                  hover={status === 'availabled'}
                >
                  <TitleCardWraperStyled>
                    <TitleCardStyled size="md">{name}</TitleCardStyled>
                  </TitleCardWraperStyled>
                  <ValueOfKeyList list={options} />
                  <ButtomWraper>
                    <ButtonCard
                      status={status}
                      accountType={accountType}
                      urlToIdentificationAccountDetailsPage={urlToIdentificationAccountDetailsPage}
                      urlToEDOAccountDetailsPage={urlToEDOAccountDetailsPage}
                      urlToAuthorizationAccountDetailsPage={urlToAuthorizationAccountDetailsPage}
                    >
                      {actionText}
                    </ButtonCard>
                  </ButtomWraper>
                </CardStyled>
              ))
              .slice(0, 3)}{' '}
            {/* // TODO(PROJECTS-6): убрать slice после интеграции со связным */}
          </CardsWraperStyled>
        </AnimatedUI>
      ) : (
        <CardsWraperStyled>
          <LoadingDataCards length={2} />
        </CardsWraperStyled>
      )}
    </WidgetWraper>
  );
};

AccountsTypeContainer.propTypes = {
  urlToAuthorizationAccountDetailsPage: PropTypes.string,
  urlToIdentificationAccountDetailsPage: PropTypes.string,
};

AccountsTypeContainer.defaultProps = {
  urlToAuthorizationAccountDetailsPage: null,
  urlToIdentificationAccountDetailsPage: null,
};

const CardsWraperStyled = styled.div`
  display: flex;
  justify-content: start;
  flex-wrap: wrap;
  max-width: 875px;
  width: 100%;
`;

const CardStyled = styled(Card)`
  width: 243px;
  margin: 30px 20px 20px 0;
  & > *:first-child {
    margin-bottom: 20px;
  }
  @media (max-width: 980px) {
    width: 100%;
    margin: 30px 0 20px 0;
  }
`;

const TitleCardWraperStyled = styled.div``;

const TitleCardStyled = styled(TitleText)`
  margin-bottom: 20px;
`;

const ButtomWraper = styled.div`
  margin-top: auto;
  flex-shrink: 0;
`;
