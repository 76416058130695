import React from 'react';
import { Redirect } from 'react-router-dom';
import DialogError from 'modules/shared/components/DialogError';

const PopupError = ({ className }) => {
  const [isRedirected, setIsRedirected] = React.useState(false);

  function onClose() {
    setIsRedirected(true);
  }

  if (isRedirected) {
    return <Redirect to="/lk/profile-settings" />;
  }

  return <DialogError onClose={onClose} />;
};

export default PopupError;
