import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import {
  checkINNStatus,
  getUser,
  setHintOpen,
  setInnWasSentOnCheck,
} from 'modules/authLegasy/actions';
import HintHowToGiveRights from 'modules/authLegasy/components/Hint/HintHowToGiveRights';
import HintWhyCheckIsSoLong from 'modules/authLegasy/components/Hint/HintWhyCheckIsSoLong';
import HintCancelRequestOnSzStatus from 'modules/profileLegasy/components/HintCancelRequestOnSzStatus/HintCancelRequestOnSzStatus';
import SESteps from 'modules/profileLegasy/components/SESteps/SESteps';
import Balloon from 'modules/shared/components/Balloon/Balloon';
import GetSzInnInput from 'modules/shared/components/GetSzInnInput/GetSzInnInput';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import HintsList from 'modules/shared/components/HintsList/HintsList';

import {
  ALIEN_INN,
  CONSISTENT_ANIMATIONS_DELAY,
  ERROR,
  HINT_TIMEOUTS,
  NO,
  NO_PERMISSION,
  NOT_FOUND,
  NOT_MATCH,
  NOT_READY,
  NOT_READY_PERMISSION_REQUESTED,
  YES,
} from 'constants/index.js';

import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import iconWink from 'static/assets/icon-emoji-wink.png';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';
import profileBg from 'static/assets/profile-bg.png';

const WAITING = 'WAITING';

export const SEFromLKWaiting = ({
  className,
  INN,
  dispatch,
  onSuccessSERegistration,
  onFailedSERegistration,
  cancelRequestOnSzStatus,
  onAlreadyRegistered,
  onServerError,
  onNoSz,
  onNoRights,
}) => {
  const [currentHintId, setCurrentHintId] = useState(null);
  const [isDropOpen, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const windowSize = useWindowSize();

  const [isRendered, setIsRendered] = useState(false);

  useEffect(() => {
    setIsRendered(true);
  }, []);

  useEffect(() => {
    dispatch(setHintOpen(isDropOpen));
    /* eslint-disable */
  }, [isDropOpen]);
  /* eslint-disable */

  /** Отправляем запрос на проверку ИНН при маунте компонента */
  useEffect(() => {
    requestInnCheck();
    /* eslint-disable */
  }, [dispatch]);
  /* eslint-enable */

  const requestInnCheck = () => {
    dispatch(checkINNStatus())
      .then((statusData) => {
        if (statusData && statusData.data.status === YES) {
          dispatch(setInnWasSentOnCheck(false));
          dispatch(getUser()).catch(() => {});
          onSuccessSERegistration();
        }

        if (
          statusData.data.status === NOT_READY ||
          statusData.data.status === NOT_READY_PERMISSION_REQUESTED
        ) {
          requestInnCheck();
        }

        if (statusData && statusData.data.status === NO) {
          dispatch(setInnWasSentOnCheck(false));
          onNoSz();
        }

        if (statusData && statusData.data.status === NOT_MATCH) {
          dispatch(setInnWasSentOnCheck(false));
          onFailedSERegistration();
        }

        if (statusData && statusData.data.status === NOT_FOUND) {
          dispatch(setInnWasSentOnCheck(false));
          onFailedSERegistration();
        }

        if (statusData && statusData.data.status === NO_PERMISSION) {
          dispatch(setInnWasSentOnCheck(false));
          onNoRights();
        }

        if (statusData && statusData.data.status === ALIEN_INN) {
          dispatch(setInnWasSentOnCheck(false));
          onAlreadyRegistered();
        }

        if (statusData && statusData.data.status === ERROR) {
          dispatch(setInnWasSentOnCheck(false));
          onServerError();
        }
      })
      .catch((result) => {
        if (result && result.data && result.data === 'inn.change-denied') {
          onFailedSERegistration();
        } else {
          onServerError();
        }
      });
  };

  const onCloseBtnClick = () => {
    closeExplicitly();
    setCurrentHintId(null);
  };

  const toggleHint1 = () => {
    toggleDrop();
    setCurrentHintId(1);
  };

  const toggleHint2 = () => {
    toggleDrop();
    setCurrentHintId(2);
  };

  /** Триггер - открытие попапа с отменой запроса на статус сз */
  const toggleHintCancelRequestOnSzStatus = () => {
    toggleDrop();
    setCurrentHintId(3);
  };

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    window.addEventListener('beforeunload', onBeforeUnload);
    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  const hints = [
    {
      id: 1,
      title: 'Как предоставить права?',
      titleForPopup: 'Как предоставить права?',
      action: toggleHint1,
      openCondition: isDropOpen && currentHintId === 1,
      popup: <HintHowToGiveRights />,
      dropEl,
    },
    {
      id: 2,
      title: 'Почему проверка длится долго?',
      titleForPopup: 'Почему проверка длится долго?',
      action: toggleHint2,
      openCondition: isDropOpen && currentHintId === 2,
      popup: <HintWhyCheckIsSoLong />,
      dropEl,
    },
  ];

  return (
    <div
      className={cx('se-from-lk', {
        [className]: className,
      })}
      data-testid="se-from-lk-waiting"
    >
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="se-from-lk__header">
          <div className="se-from-lk__header-container container container_small">
            <div className="se-from-lk__header-content">
              {/* ПРОГРЕСС ПОЛУЧЕНИЯ СТАТУСА СЗ */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <SESteps currentStep={2} totalSteps={3} className="se-from-lk__header-step" />
              </CSSTransition>
              {/* /ПРОГРЕСС ПОЛУЧЕНИЯ СТАТУСА СЗ */}

              {/* ЗАГОЛОВОК */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
                unmountOnExit
              >
                <h1 className="se-from-lk__header-title">
                  Ваш запрос отправлен в&nbsp;ФНС для подтверждения
                </h1>
              </CSSTransition>
              {/* /ЗАГОЛОВОК */}

              {/* ТЕКСТОВОЕ ОПИСАНИЕ */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <div className="se-from-lk__header-desc">
                  <IconInfo className="se-from-lk__header-desc-warning-icon" />
                  Вам необходимо предоставить для нас права в приложении &quot;Мой Налог&quot; или
                  на сайте ФНС
                </div>
              </CSSTransition>
              {/* /ТЕКСТОВОЕ ОПИСАНИЕ */}

              {/* ИНПУТ ДЛЯ ВВОДА ИНН ПРИ ШИРИНЕ ЭКРАНА > 980 */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="se-from-lk__form">
                  <GetSzInnInput
                    value={INN}
                    className="se-from-lk__form-input-holder"
                    isEditButton
                    onChangeInn={toggleHintCancelRequestOnSzStatus}
                    isLockVisible
                    status={WAITING}
                    disabled
                    readOnly
                  />
                </div>
              </CSSTransition>
              {/* /ИНПУТ ДЛЯ ВВОДА ИНН ПРИ ШИРИНЕ ЭКРАНА > 980 */}

              {/* ПОДСКАЗКА ПРИ ЭКРАНЕ > 980 */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered && windowSize.innerWidth > 980}
                timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
                unmountOnExit
              >
                <Balloon className="se-from-lk__info">
                  <div className="balloon__text">
                    Обработка ответа может занять некоторое время - это нормально
                    <img
                      src={iconWink}
                      alt=""
                      className="balloon__icon se-from-lk__info-icon_wink"
                      data-testid="balloon-emoji"
                    />
                  </div>
                </Balloon>
              </CSSTransition>
              {/* ПОДСКАЗКА ПРИ ЭКРАНЕ > 980 */}
            </div>
          </div>

          <img src={profileBg} alt="Profile bg" className="se-from-lk__header-bg" />

          {/* КНОПКА ЗАКРЫТИЯ ПОЛУЧЕНИЯ СТУТУСА СЗ */}
          <button
            className="se-from-lk__header-close"
            type="button"
            onClick={toggleHintCancelRequestOnSzStatus}
          >
            <IconArrow className="se-from-lk__header-close-icon" />
          </button>
          {/* /КНОПКА ЗАКРЫТИЯ ПОЛУЧЕНИЯ СТУТУСА СЗ */}

          {/* ПРОГРЕСС ПРИ ЭКРАНЕ <= 980 */}
          {windowSize.innerWidth <= 980 && (
            <SESteps currentStep={2} totalSteps={3} className="se-from-lk__header-step" />
          )}
          {/* /ПРОГРЕСС ПРИ ЭКРАНЕ <= 980 */}
        </div>
      </CSSTransition>

      <div className="se-from-lk__content container container_small">
        {/* ТЕКСТОВОЕ ОПИСАНИЕ ПРИ ЭКРАНЕ <= 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <div className="se-from-lk__header-desc">
            <IconInfo className="se-from-lk__header-desc-warning-icon" />
            Вам необходимо предоставить для нас права в приложении
            <a
              href="https://npd.nalog.ru/app/"
              rel="noopener noreferrer"
              className="se-from-lk__header-desc-link"
            >
              &quot;Мой Налог&quot;
            </a>
            или на
            <a
              href="https://lknpd.nalog.ru"
              rel="noopener noreferrer"
              className="se-from-lk__header-desc-link"
            >
              сайте ФНС
            </a>
          </div>
        </CSSTransition>
        {/* /ТЕКСТОВОЕ ОПИСАНИЕ ПРИ ЭКРАНЕ <= 980 */}

        {/* ПОЛЕЗНЫЕ ССЫЛКИ ПРИ ЭКРАНЕ > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <HintsList
            hints={hints}
            title="Вам будет полезно"
            closeHintHandler={onCloseBtnClick}
            className="se-from-lk__hint-block"
          />
        </CSSTransition>
        {/* /ПОЛЕЗНЫЕ ССЫЛКИ ПРИ ЭКРАНЕ > 980 */}

        {/* ИНПУТ ДЛЯ ВВОДА ИНН ПРИ ШИРИНЕ ЭКРАНА <= 980 */}
        <div className="se-from-lk__form">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth <= 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <GetSzInnInput
              value={INN}
              className="se-from-lk__form-input-holder"
              isEditButton
              onChangeInn={toggleHintCancelRequestOnSzStatus}
              isLockVisible
              status={WAITING}
              disabled
              readOnly
            />
          </CSSTransition>
        </div>
        {/* ИНПУТ ДЛЯ ВВОДА ИНН ПРИ ШИРИНЕ ЭКРАНА <= 980 */}

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[3]}
          unmountOnExit
        >
          <Balloon className="se-from-lk__info">
            <div className="balloon__text">
              Проверка статуса может занять некоторое время - это нормально
            </div>

            <img
              src={iconWink}
              alt=""
              className="balloon__icon se-from-lk__info-icon_wink"
              data-testid="balloon-emoji"
            />
          </Balloon>
        </CSSTransition>

        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth <= 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
          unmountOnExit
        >
          <HintsList
            hints={hints}
            title="Вам будет полезно"
            closeHintHandler={onCloseBtnClick}
            className="se-from-lk__hint-block"
          />
        </CSSTransition>
      </div>

      <CSSTransition
        in={isDropOpen && currentHintId === 3}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <HintAuth
          ref={dropEl}
          onCloseBtnClick={onCloseBtnClick}
          className="se-from-lk__why-so-long-sidebar"
        >
          <HintCancelRequestOnSzStatus
            cancelRequestOnSzStatus={cancelRequestOnSzStatus}
            continueRequest={onCloseBtnClick}
          />
        </HintAuth>
      </CSSTransition>
    </div>
  );
};

SEFromLKWaiting.propTypes = {
  className: PropTypes.string.isRequired,
  INN: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  onSuccessSERegistration: PropTypes.func.isRequired,
  onFailedSERegistration: PropTypes.func.isRequired,
  cancelRequestOnSzStatus: PropTypes.func.isRequired,
  onAlreadyRegistered: PropTypes.func.isRequired,
  onServerError: PropTypes.func.isRequired,
  onNoSz: PropTypes.func.isRequired,
  onNoRights: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  INN: state.auth.INN,
});

export default connect(mapStateToProps)(SEFromLKWaiting);
