import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

import { WindowAlert } from '../../molecules';
import { ParagraphText } from '../../../../ParagraphText';
import SadEmoji from '../WindowOnlySz/assents/sad-emoji.png';

export const WindowUniversalError = ({ header, text, buttonText, onClose }) => (
  <WindowAlert
    header={header}
    buttonText={buttonText}
    onClose={onClose}
    onClick={onClose}
    emoji={
      <SadEmojiWraperStyled>
        <SadEmojiStyled />
      </SadEmojiWraperStyled>
    }
  >
    <ParagraphTextStyled>{text}</ParagraphTextStyled>
  </WindowAlert>
);

WindowUniversalError.propTypes = {
  header: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
  buttonText: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
};

const ParagraphTextStyled = styled(ParagraphText)`
  font-size: 18px;
  text-align: center;
  line-height: 1.33;
  color: var(--bluey-grey);
  max-width: 435px;
`;

const SadEmojiWraperStyled = styled.div`
  width: 90px;
  height: 90px;
  box-shadow: 0 4px 27px 0 #e8ecf2;
  background-color: #fff;
  border-radius: 50%;
  margin-bottom: 30px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const SadEmojiStyled = styled.img.attrs(() => ({ src: SadEmoji }))`
  width: 40px;
  height: 40px;
`;
