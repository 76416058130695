import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import { formatDateHuman, formatTimeHuman } from 'helpers';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';

import { getDeals } from 'modules/profileLegasy/actions';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import MonthNavigation from 'modules/shared/components/MonthNavigation/MonthNavigation';
import NoOperationsMade from 'modules/shared/components/NoOperationsMade/NoOperationsMade';

import formatNumber from 'helpers/formatNumber';
import { removeZerosAfterDot } from 'helpers';

import { ReactComponent as IconBill } from 'static/assets/icon-bill.svg';
import { ReactComponent as IconClock } from 'static/assets/icon-clock.svg';

// Styles
import './Deals.scss';

const Deals = ({
  dispatch,
  deals,
  className,
  dealsItemClickHandler,
  isCheckOpen,
  startZoneOffset,
  startCurrentTime,
}) => {
  const [now, setNow] = useState(new Date(startCurrentTime));
  const [hasMore, setHasMore] = useState(true);
  const [year, setYear] = useState(now.getFullYear());
  const [month, setMonth] = useState(now.getMonth() + 1);
  const [zoneOffset, setZoneOffset] = useState(startZoneOffset);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailToLoad, setIsFailToLoad] = useState(false);
  const [currentOpenOperationId, setCurrentOpenOperationId] = useState(null);
  const [currentPage, setCurrentPage] = useState(0);

  const pageSize = 7;

  const [count, setCount] = useState(0);
  const [isStartDateLoading, setIsStartDateLoading] = useState(false);

  const [isNoDeals, setIsNoDeals] = useState(false);

  /**
   * Берем  последние 7 операций, чтобы определить месяц последней операции
   * устанавливаем этот месяц, как месяц отсчета
   * */
  useEffect(() => {
    setIsLoading(true);

    if (count < 1) {
      setIsStartDateLoading(true);

      dispatch(getDeals(0, 0, pageSize, year, zoneOffset))
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.items &&
            Array.isArray(res.data.items) &&
            res.data.items.length >= 1
          ) {
            setYear(new Date(res.data.items[0].operationTime).getFullYear());
            setMonth(new Date(res.data.items[0].operationTime).getMonth() + 1);
            setIsStartDateLoading(false);
            setCount(count + 1);
          }

          if (res && res.data && !res.data.items) {
            setIsNoDeals(true);
            setIsLoading(false);
            setIsStartDateLoading(false);
          }
        })
        .catch(() => {
          setIsLoading(false);
          setIsFailToLoad(true);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [count]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем  startZoneOffset и startCurrentTime с сервера, если они не равны нулю
   * записываем их в zoneOffset и now для построения фильтров, если же они равны 0
   * берем инфу о zoneOffset и now с браузера
   * */
  useEffect(() => {
    if (startZoneOffset !== 0) {
      setZoneOffset(startZoneOffset);
    } else {
      setZoneOffset(now.getTimezoneOffset());
    }

    if (startCurrentTime !== 0) {
      setNow(new Date(startCurrentTime));
    } else {
      setNow(new Date());
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startZoneOffset, startCurrentTime]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const windowSize = useWindowSize();

  const getDealsForPage = useCallback(
    (page) => {
      setIsLoading(true);
      setCurrentPage(page);

      const offset = page * pageSize;

      dispatch(getDeals(month, offset, pageSize, year, zoneOffset))
        .then((res) => {
          if (res && res.data) {
            setHasMore(res.data.lastPage);
          }
        })
        .catch((result) => {
          setHasMore(false);

          if (result && result.result && result.result.code === 400) {
            setIsLoading(false);
            setIsFailToLoad(true);
          }
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [month, year, dispatch, zoneOffset],
  );

  /** Загружаем первую порцию операций для текущего месяца и года */
  useEffect(() => {
    if (count > 0) {
      getDealsForPage(0);
    }
  }, [month, year, zoneOffset, getDealsForPage, count]);

  /** Клик -> переход к предыдущему месяцу */
  const handlePrevMonthClick = () => {
    if (month <= 1) {
      setMonth(12);
      setYear(Math.max(2019, year - 1));
    } else {
      setMonth(month - 1);
    }
  };

  /** Клик -> переход к следующему месяцу */
  const handleNextMonthClick = () => {
    if (month >= 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  /** Открываем сдеклку из списка по клику */
  const handleDealsItemClick = (deal) => {
    dealsItemClickHandler(deal);
    setCurrentOpenOperationId(deal.id);
  };

  /** Текущая дата, выбранная в навигации */
  const currentDate = new Date(Date.parse(`${year}-${month}-01`));
  const firstMonthDayOfNow = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0, 0);

  return (
    <div
      className={cx('deals', {
        [className]: className,
        'deals_check-open': isCheckOpen,
      })}
      data-testid="deals"
    >
      {/* ХЭДЕР ТАБЛИЦЫ ПРИ ШИРИНЕ ЭКРАНА > 1030 */}
      {windowSize.innerWidth > 1030 && (
        <div className="deals__header">
          <div className="deals__item-title-wrap">
            <IconClock className="deals__item-icon" />
            <p className="deals__item-title">Чеки</p>
          </div>
          <p className="deals__item-tax">Налог</p>
          <div className="deals__item-date">
            <MonthNavigation
              className="deals__item-date"
              month={month}
              year={year}
              isStartDateLoading={isStartDateLoading}
              isLoading={isLoading}
              isPrevButtonDisabled={year === 2019 && month === 1}
              isNextButtonDisabled={currentDate >= firstMonthDayOfNow}
              handlePrevMonthClick={handlePrevMonthClick}
              handleNextMonthClick={handleNextMonthClick}
            />
          </div>
          <p className="deals__item-sum">Сумма</p>
        </div>
      )}
      {/* /ХЭДЕР ТАБЛИЦЫ ПРИ ШИРИНЕ ЭКРАНА > 1030 */}

      {/* НАВИГАЦИЯ ПО МЕСЯЦАМ ПРИ ШИРИНЕ ЭКРАНА < 1030 */}
      {windowSize.innerWidth <= 1030 && (
        <div className="deals__date-wrap">
          <MonthNavigation
            className="deals__date"
            month={month}
            year={year}
            isStartDateLoading={isStartDateLoading}
            isLoading={isLoading}
            isPrevButtonDisabled={year === 2019 && month === 1}
            isNextButtonDisabled={currentDate >= firstMonthDayOfNow}
            handlePrevMonthClick={handlePrevMonthClick}
            handleNextMonthClick={handleNextMonthClick}
          />
        </div>
      )}
      {/* НАВИГАЦИЯ ПО МЕСЯЦАМ ПРИ ШИРИНЕ ЭКРАНА < 1030 */}

      <div className="deals__content-wrapper">
        <Scrollbar
          className="deals__content-scrollbar"
          wrapperProps={{
            /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
            renderer: ({ elementRef, ...restProps }) => {
              return (
                <span {...restProps} style={{ ...restProps.style, right: 0 }} ref={elementRef} />
              );
            },
          }}
          scrollerProps={{
            /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
            renderer: ({ elementRef, ...restProps }) => {
              return (
                <span
                  {...restProps}
                  style={{ ...restProps.style, paddingRight: 20, marginRight: -20 }}
                  ref={elementRef}
                />
              );
            },
          }}
          trackYProps={{
            /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
            renderer: ({ elementRef, style, ...restProps }) => {
              return <span {...restProps} ref={elementRef} className="deals__track" />;
            },
          }}
          thumbYProps={{
            renderer: ({ elementRef, style, ...restProps }) => {
              return <span {...restProps} ref={elementRef} className="deals__thumb" />;
            },
            /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
          }}
        >
          <InfiniteScroll
            pageStart={0}
            initialLoad={false}
            loadMore={getDealsForPage}
            hasMore={!isLoading && hasMore}
            useWindow={false}
            key={`${month}_${year}`}
            getScrollParent={() => document.querySelector('.ScrollbarsCustom-Scroller')}
          >
            {/* КОНТЕНТ ТАБЛТИЦЫ ПРИ ШИРИНЕ ЭКРАНА > 1030 */}
            {windowSize.innerWidth > 1030 && (
              <div className="deals__list">
                {(!isLoading || (isLoading && currentPage > 0)) &&
                  deals.map((item) => {
                    const operationTime = new Date(item.operationTime);

                    return (
                      <button
                        type="button"
                        className={cx('deals__item', {
                          deals__item_open: currentOpenOperationId === item.id,
                        })}
                        key={item.id}
                        onClick={() => handleDealsItemClick(item)}
                      >
                        <div className="deals__item-title-wrap">
                          <IconBill className="deals__item-icon" />
                          <div className="deals__item-title">
                            {item.services &&
                            Array.isArray(item.services) &&
                            item.services.length ? (
                              <>
                                {item.services.map((service) => (
                                  <span
                                    className="deals__item-title-service"
                                    key={`${service.name}-${service.amount}-${service.quantity}`}
                                  >
                                    {service.name}
                                  </span>
                                ))}
                              </>
                            ) : (
                              <p className="deals__item-title-service">{item.serviceName}</p>
                            )}
                          </div>
                        </div>

                        <p className="deals__item-tax">
                          {item.canceled ? 'Аннулирован' : `${formatNumber(item.tax / 100)} ₽`}
                        </p>

                        <p className="deals__item-date">{`${formatDateHuman(
                          operationTime,
                        )} (${formatTimeHuman(operationTime)})`}</p>

                        <p
                          className={cx('deals__item-sum', {
                            'deals__item-sum_canceled': item.canceled,
                          })}
                        >
                          {!item.canceled && '+ '}
                          {removeZerosAfterDot(formatNumber((item.totalAmount / 100).toFixed(2)))} ₽
                        </p>
                      </button>
                    );
                  })}
              </div>
            )}
            {/* /КОНТЕНТ ТАБЛТИЦЫ ПРИ ШИРИНЕ ЭКРАНА > 1030 */}

            {/* КОНТЕНТ ТАБЛТИЦЫ ПРИ ШИРИНЕ ЭКРАНА < 1030 */}
            {windowSize.innerWidth <= 1030 && (
              <div className="deals__list">
                {(!isLoading || (isLoading && currentPage > 0)) &&
                  deals.map((item) => {
                    const operationTime = new Date(item.operationTime);

                    return (
                      <button
                        type="button"
                        className={cx('deals__item', {
                          deals__item_open: currentOpenOperationId === item.id,
                        })}
                        key={item.id}
                        onClick={() => handleDealsItemClick(item)}
                        data-testid="deals-item"
                      >
                        <div className="deals__item-left-part">
                          <div className="deals__item-title-wrap">
                            <IconBill className="deals__item-icon" />

                            <div className="deals__item-title">
                              {item.services &&
                              Array.isArray(item.services) &&
                              item.services.length ? (
                                <>
                                  {item.services.map((service) => (
                                    <p
                                      className="deals__item-title-service"
                                      key={`${service.name}-${service.amount}-${service.quantity}`}
                                    >
                                      {service.name}
                                    </p>
                                  ))}
                                </>
                              ) : (
                                <p className="deals__item-title-service">{item.serviceName}</p>
                              )}
                            </div>
                          </div>

                          <p className="deals__item-date">{`${formatDateHuman(
                            operationTime,
                          )} (${formatTimeHuman(operationTime)})`}</p>
                        </div>

                        <div className="deals__item-right-part">
                          <p
                            className={cx('deals__item-sum', {
                              'deals__item-sum_canceled': item.canceled,
                            })}
                          >
                            {!item.canceled && '+ '}
                            {removeZerosAfterDot(
                              formatNumber((item.totalAmount / 100).toFixed(2)),
                            )}{' '}
                            ₽
                          </p>

                          <p className="deals__item-tax">
                            {item.canceled ? 'Аннулирован' : `${formatNumber(item.tax / 100)} ₽`}
                          </p>
                        </div>
                      </button>
                    );
                  })}
              </div>
            )}
            {/* /КОНТЕНТ ТАБЛТИЦЫ ПРИ ШИРИНЕ ЭКРАНА < 1030 */}

            {isLoading && (
              <div
                className={cx('deals__loading', {
                  'deals__loading_full-block': currentPage === 0,
                })}
              >
                <CircleLoader className="deals__loading-loader" />
              </div>
            )}
          </InfiniteScroll>

          {(!deals || !deals.length || isNoDeals) && !isLoading && !isFailToLoad && (
            <NoOperationsMade
              text="Здесь будут отображаться проведенные вами сделки и храниться чеки"
              className="deals__no-operations-message"
            />
          )}

          {isFailToLoad && !isLoading && (
            <NoOperationsMade className="deals__no-operations-message">
              <div className="no-operations-made__text">
                Произошла какая-то ошибка при обращении к ФНС. Не удалось загрузить данные.
                Попробуйте позже, а если ошибка не пройдет
                <button className="no-operations-made__text-link js-help" type="button">
                  напишите нам
                </button>
                .
              </div>
            </NoOperationsMade>
          )}
        </Scrollbar>
      </div>
    </div>
  );
};

Deals.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  deals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      operationTime: PropTypes.number,
      totalAmount: PropTypes.number,
      tax: PropTypes.number,
      canceled: PropTypes.bool,
      serviceName: PropTypes.string,
      services: PropTypes.arrayOf(
        PropTypes.shape({
          serviceAmount: PropTypes.number,
          serviceName: PropTypes.string,
          serviceQuantity: PropTypes.number,
        }),
      ),
    }),
  ).isRequired,
  dealsItemClickHandler: PropTypes.func,
  isCheckOpen: PropTypes.bool.isRequired,
  startZoneOffset: PropTypes.number.isRequired,
  startCurrentTime: PropTypes.number.isRequired,
};

Deals.defaultProps = {
  className: null,
  dealsItemClickHandler: () => {},
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  deals: state.profile.deals,
  startZoneOffset: state.auth.startZoneOffset || 0,
  startCurrentTime: state.auth.startCurrentTime,
});

export default connect(mapStateToProps)(Deals);
