import PropTypes from 'prop-types';
import React, { useMemo } from 'react';
import { animated, useSpring } from 'react-spring';
import { PTChildrenElement } from '../../tools/types/propTypes';

export const AnimatedUI = ({ children, id, finishId, isVisible, className }) => {
  const springOptions = useMemo(() => {
    if (id === null && finishId === null)
      return {
        opacity: 1,
        y: 0,
        from: { opacity: 0, y: 50 },
        config: { mass: 1, tension: 280, friction: 80 },
      };
    return {
      opacity: isVisible ? 1 : 0,
      y: isVisible ? 0 : 50,
      from: { opacity: 0, y: 50 },
      delay: isVisible ? (id + 1) * 100 : (finishId - id) * 100,
      config:
        id === 0 || id === 1
          ? { mass: 0.6, tension: 200, friction: 60 }
          : { mass: 0.7, tension: 300, friction: 90 },
    };
  }, [id, finishId, isVisible]);

  const spring = useSpring(springOptions);

  return (
    <animated.div
      className={className}
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate((y) => `translateY(${y}px)`),
      }}
    >
      {children}
    </animated.div>
  );
};

AnimatedUI.propTypes = {
  children: PTChildrenElement,
  isVisible: PropTypes.bool,
  id: PropTypes.number || null,
  finishId: PropTypes.number || null,
};

AnimatedUI.defaultProps = {
  children: '',
  isVisible: true,
  id: null,
  finishId: null,
};
