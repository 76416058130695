import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Loader.scss';

const Loader = ({ className, inverted }) => {
  return (
    <div
      className={cx('loader', {
        [className]: className,
        loader_inverted: inverted,
      })}
    >
      <div className="loader__loading">
        <div className="loader__loading-inner" />
        <div className="loader__loading-inner" />
        <div className="loader__loading-inner" />
        <div className="loader__loading-inner" />
      </div>
    </div>
  );
};

Loader.propTypes = {
  className: PropTypes.string,
  inverted: PropTypes.bool,
};

Loader.defaultProps = {
  className: '',
  inverted: false,
};

export default Loader;
