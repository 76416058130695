import styled from 'styled-components';
import { ButtonUI } from 'ui';
import { getFont } from 'tools';
import { WEIGHT } from 'tools/constants';

export const LinkOfBlog = styled(ButtonUI.Link)`
  ${getFont(WEIGHT['circe-normal'])}
  color: var(--deep-sky-blue);
  font-size: 18px;
  line-height: 24px;
`;
