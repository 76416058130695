import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';
// import parse from 'date-fns/parse';
// import format from 'date-fns/format';
// import { ru } from 'date-fns/locale';
import moment from 'moment/moment';
import 'moment/locale/ru';

moment.locale('ru');

export const useDocDataSelector = () =>
  useSelector(
    createSelector(
      (store) => store.documentsCertificate.data,
      (data) => {
        const {
          id,
          name,
          status,
          dateSms,
          performer,
          customer,
          shA256Fingerprint,
          autoSign,
          login,
        } = data || {};
        return {
          id,
          name,
          performer,
          customer,
          status: DOC_STATUSES_MAP.get(status) || 'Неизвестный статус',
          dateSms: dateSms && moment(dateSms).utcOffset(6).format('HH:mm, DD MMMM yyyy'), //dataFormat(dataParse(dateSms)),
          // eslint-disable-next-line keywords
          signingMethod: autoSign
            ? 'Документ был подписан автоматически'
            : login
            ? `Документ был подписан с помощью СМС, отправленного на номер +7******${login.slice(
                -4,
              )}`
            : false,
          shA256Fingerprint,
        };
      },
    ),
    shallowEqual,
  );

// const timeFormat = (date) =>
//   `${date.getHours()}:${date.getHours()}, ${date
//     .toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' })
//     .slice(0, -3)}`;

// const dataFormat = (date) =>
//   date.toLocaleDateString('ru-RU', { day: 'numeric', month: 'long', year: 'numeric' }).slice(0, -3);

// const dataFormat = (date) => format(date, 'HH:mm, dd MMMM yyyy', { locale: ru });
// const dataParse = (date) => parse(date, 'yyyy-MM-dd HH:mm:ss', new Date());

export const DOC_STATUSES_MAP = new Map([
  ['DRAFT', 'Черновик'],
  ['SEND_TO_SIGN', 'Ожидает подписи'],
  ['SIGNED', 'Документ подписан'],
  ['EXPIRED', 'Истек срок действия'],
  ['REJECTED', 'Документ отклонен'],
  ['BLOCKED', 'Документ заблокирован'],
  ['TERMINATED', 'Документ расторгнут'],
]);

export const useDocRequestSelector = () =>
  useSelector(
    createSelector(
      (store) => store.documentsCertificate.request,
      (request) => request,
    ),
    shallowEqual,
  );
