import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
//TODO: PRO-384
// import SupportButton from '../SupportButton';
import { ReactComponent as IconAppStore } from 'static/assets/icon-app-store.svg';
import { ReactComponent as IconPlayStore } from 'static/assets/icon-play-store.svg';
//TODO: PRO-384
// import iconHand from 'static/assets/icon-emoji-hand.png';
import { APPLE_STORE_LINK, GOOGLE_PLAY_LINK } from 'constants/index';
import styles from './PopupFooter.module.scss';

const PopupFooter = ({ className }) => (
  <footer className={cx(styles.popupFooter, className)}>
    {/** Ссылки на приложение в магазинах. */}
    <div className={styles.popupFooterLinks}>
      <p>Мы в Google Play и AppStore</p>
      <a
        href={APPLE_STORE_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.popupFooterLink}
      >
        <IconAppStore
          className={cx(styles.popupFooterLinkIcon, styles.popupFooterLinkIconAppStore)}
        />
      </a>
      <a
        href={GOOGLE_PLAY_LINK}
        target="_blank"
        rel="noopener noreferrer"
        className={styles.popupFooterLink}
      >
        <IconPlayStore
          className={cx(styles.popupFooterLinkIcon, styles.popupFooterLinkIconGooglePlay)}
        />
      </a>
    </div>
    {/** /Ссылки на приложение в магазинах. */}

    {/* TODO: PRO-384 */}
    {/* * Кнопка для открытия чата с поддержкой. */}
    {/* <SupportButton className={styles.popupFooterSupport}>
      Чат поддержки{' '}
      <div className={styles.popupFooterSupportIconWrapper}>
        <img src={iconHand} className={styles.popupFooterSupportIcon} alt="icon hand" />
      </div>
    </SupportButton> */}
    {/** /Кнопка для открытия чата с поддержкой. */}
  </footer>
);

PopupFooter.propTypes = {
  className: PropTypes.string,
};

export default PopupFooter;
