/* eslint-disable import/prefer-default-export */
import { fetchWrapper } from 'helpers';

/**
 * Запрос на получение списка документов пользователя
 * @param {number} page - номер страницы
 * @param {string} companyName - [фильтр] название компании
 * @param {string} state - [фильтр] статус договора
 * @param {string} partner - [фильтр] партнер
 * @param {'PERSONAL'|'BUSINESS'} platform - [фильтр] платформа
 * @return {Promise<void>}
 */
export const documentListGetAPI = async ({
  page = 1,
  companyName,
  state,
  partnerId,
  platformName,
}) => {
  try {
    const url = `${process.env.REACT_APP_API_URL}/partnerships/documents`;
    const queryParams = new URLSearchParams();
    queryParams.set('pageSize', 100);
    queryParams.set('page', page);
    if (companyName) queryParams.set('companyName', companyName);
    if (state) queryParams.set('state', state);
    if (partnerId) queryParams.set('partner', partnerId);
    if (platformName) queryParams.set('platform', platformName);

    const response = await fetchWrapper(`${url}?${queryParams}`);
    if (response.result.code !== 200) throw new Error(response.result.message);

    return response.data;
  } catch (error) {
    throw new Error(error?.result?.message || error);
  }
};
