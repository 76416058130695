/* eslint-disable import/prefer-default-export */
import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { CircleLoader, AnimatedUI } from 'ui';
import { SIZE } from 'tools/constants';
import { PTClassNames } from 'tools/types/propTypes';

import { ButtomOfBack, TitleTextOfBlog } from '../molecules';

export const WidgetWraper = ({ title, children, className, disabledOfSwitchBack }) => {
  const currentWindowSize = useWindowSize();

  return (
    <AnimatedUI>
      <WraperAfterStyled className={className}>
        <WraperBeforeStyled>
          {!disabledOfSwitchBack && <ButtomOfBackStyled />}
          {!!title && (
            <TitleTextStyled size={currentWindowSize.innerWidth >= 715 ? SIZE.hr : SIZE.lg}>
              {title}
            </TitleTextStyled>
          )}
          {children}
        </WraperBeforeStyled>
      </WraperAfterStyled>
    </AnimatedUI>
  );
};

WidgetWraper.propTypes = {
  title: PropTypes.oneOfType([PropTypes.bool, PropTypes.string, PropTypes.instanceOf(null)]),
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  className: PTClassNames,
  disabledOfSwitchBack: PropTypes.bool,
};

WidgetWraper.defaultProps = {
  title: null,
  children: CircleLoader,
  className: '',
  disabledOfSwitchBack: false,
};

const WraperAfterStyled = styled.div`
  width: 100%;
  padding-left: 110px;
  @media (max-width: 1100px) {
    padding-left: 0;
  }
`;

const WraperBeforeStyled = styled.div`
  margin: auto;
  width: auto;
  display: flex;
  flex-direction: column;
`;

const TitleTextStyled = styled(TitleTextOfBlog)`
  @media (max-width: 980px) {
    display: none;
  }
`;

const ButtomOfBackStyled = styled(ButtomOfBack)`
  @media (max-width: 980px) {
    margin-bottom: 30px;
  }
`;
