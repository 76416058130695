import {
  WindowModal,
  WindowAlert,
  WindowConfirm,
  WindowUnavailableContent,
  WindowOnlySz,
  WindowOnlyAuthorized,
  WindowUniversal,
  WindowUniversalError,
} from './ui';

export const WindowUI = WindowModal;

WindowUI.Modal = WindowModal;
WindowUI.Confirm = WindowConfirm;
WindowUI.Alert = WindowAlert;
WindowUI.UnavailableContent = WindowUnavailableContent;
WindowUI.OnlySz = WindowOnlySz;
WindowUI.OnlyAuthorized = WindowOnlyAuthorized;
WindowUI.WindowUniversal = WindowUniversal;
WindowUI.WindowUniversalError = WindowUniversalError;
