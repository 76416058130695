import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import { Link } from 'react-router-dom';

import ServiceCardContent from 'modules/profileLegasy/components/ServiceCardContent/ServiceCardContent';

// Styles
import './Service.scss';

const Service = ({
  isLink,
  title,
  desc,
  className,
  handleClick,
  disabled,
  href,
  isOutAppLink,
  isWhite,
  isBig,
  children,
  icon,
  isNew,
}) => {
  return (
    <>
      {isLink && href && !disabled ? (
        <>
          {!isOutAppLink ? (
            <Link
              to={href}
              className={cx('service', {
                [className]: className,
                service_white: isWhite,
                service_big: isBig,
              })}
              onClick={handleClick}
              data-testid="service-card"
            >
              {isNew && <div className="service__new-label">Новое</div>}

              {icon && (
                <div className="service__icon-wrap" data-testid="service-icon-wrap">
                  {icon}
                </div>
              )}

              {children}
              <ServiceCardContent title={title} isWhite={isWhite} desc={desc} icon={icon} />
            </Link>
          ) : (
            <a
              href={href}
              className={cx('service', {
                [className]: className,
                service_white: isWhite,
                service_big: isBig,
              })}
              onClick={handleClick}
              target="_blank"
              rel="noopener noreferrer"
              data-testid="service-card"
            >
              {isNew && <div className="service__new-label">Новое</div>}

              {icon && (
                <div className="service__icon-wrap" data-testid="service-icon-wrap">
                  {icon}
                </div>
              )}

              {children}
              <ServiceCardContent title={title} isWhite={isWhite} desc={desc} icon={icon} />
            </a>
          )}
        </>
      ) : (
        <button
          className={cx('service', {
            [className]: className,
            service_white: isWhite,
            service_big: isBig,
          })}
          onClick={handleClick}
          type="button"
          disabled={disabled}
          data-testid="service-card"
        >
          {isNew && <div className="service__new-label">Новое</div>}

          {icon && (
            <div className="service__icon-wrap" data-testid="service-icon-wrap">
              {icon}
            </div>
          )}

          {children}
          <ServiceCardContent title={title} isWhite={isWhite} desc={desc} icon={icon} />
        </button>
      )}
    </>
  );
};

Service.propTypes = {
  isLink: PropTypes.bool,
  href: PropTypes.string,
  className: PropTypes.string,
  title: PropTypes.string.isRequired,
  desc: PropTypes.string,
  icon: PropTypes.node,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
  isOutAppLink: PropTypes.bool,
  isWhite: PropTypes.bool,
  isBig: PropTypes.bool,
  isNew: PropTypes.bool,
  children: PropTypes.node,
};

Service.defaultProps = {
  isLink: false,
  href: null,
  className: null,
  desc: null,
  icon: null,
  handleClick: () => {},
  disabled: false,
  isOutAppLink: false,
  isWhite: false,
  isBig: false,
  isNew: false,
  children: null,
};

export default Service;
