import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';

export const useLoginSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginData || {},
      (data) => data,
    ),
    shallowEqual,
  );

export const useLoginIdSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginData.id || {},
      (id) => id,
    ),
    shallowEqual,
  );

export const useLoginFetchingSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginLoading.fetching,
      (fetching) => fetching,
    ),
  );

export const useLoginSuccessSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginLoading.success,
      (success) => success,
    ),
  );

export const useLoginFailSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginLoading,
      ({ fail, error }) => ({ fail, error }),
    ),
  );

export const useLoginErrorSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginLoading.error,
      (error) => error,
    ),
  );

export const useStateLoginSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginLoading.state,
      (state) => state,
    ),
    shallowEqual,
  );

export const useResendTimeSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginData.resendTime,
      (resendTime) => resendTime,
    ),
  );

export const useLoginSmsConfirmFetchingSelector = () =>
  useSelector(
    createSelector(
      (state) => state.login.loginLoading.fetching,
      (resendTime) => resendTime,
    ),
  );
