import { fetchWrapper } from 'helpers';

export async function getMoneyTransferInfo() {
  const url = `${process.env.REACT_APP_API_URL}/transfer/data?p=${new Date().getTime()}`;
  let response;

  try {
    response = await fetchWrapper(url);
  } catch (err) {
    throw err;
  }

  const { data, result } = response;

  if (!data) {
    throw new Error(result.message);
  }

  return data;
}

// type = 'bankcard' | 'qiwi' | 'sz' | 'webmoney' | 'umoney'
export async function requestMoneyTransfer(type, requestData) {
  if (type === 'umoney') {
    type = 'yamoney';
  }

  const url = `${process.env.REACT_APP_API_URL}/transfer/${type}`;
  let response;

  const body = {
    sumIn: requestData.sumIn,
    sumOut: requestData.sumOut,
  };

  if (requestData.duplicate) {
    body.flags = 0x01;
  }

  switch (type) {
    case 'bankcard': {
      body.pan = requestData.number;
      break;
    }
    case 'sbp': {
      body.phone = requestData.number;
      body.bankId = requestData.bankId;
      break;
    }
    case 'qiwi': {
      body.qiwiAccount = requestData.number;
      break;
    }
    case 'yamoney': {
      body.account = requestData.number;
      break;
    }
    case 'webmoney': {
      body.purse = requestData.number;
      break;
    }
    case 'sz': {
      body.account = requestData.number;
      body.message = requestData.message;
      break;
    }
    default:
      break;
  }

  try {
    response = await fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(body),
    });
  } catch (err) {
    if (err.data === 'operation.duplicate') {
      // eslint-disable-next-line
      throw {
        data: err.data,
        message: err.result.message,
      };
    }
    throw err;
  }

  const { data, result } = response;

  if (!data) {
    throw new Error(result.message);
  }

  return data;
}

export async function checkMoneyTransferStatus(transferMethod, paymentId) {
  if (transferMethod === 'umoney') {
    transferMethod = 'yamoney';
  }

  const url = `${process.env.REACT_APP_API_URL}/transfer/${transferMethod}/${paymentId}/state`;
  let response;

  try {
    response = await fetchWrapper(url);
  } catch (err) {
    throw err;
  }

  const { data, result } = response;

  if (!data) {
    throw new Error(result.message);
  }

  return data;
}

// пересоздать подтверждение (в случае переключения с push на смс)
export async function recreateMoneyTransferConfirmationViaSms(pinMessageId) {
  const url = `${process.env.REACT_APP_API_URL}/transfer/confirm/recreate/${pinMessageId}`;
  let response;

  try {
    response = await fetchWrapper(url, { method: 'POST' });
  } catch (err) {
    throw err;
  }

  const { data, result } = response;

  if (!data) {
    throw new Error(result.message);
  }

  return data;
}

// подтвердить платеж с помощью SMS
export async function confirmMoneyTransferViaSms(paymentId, pinCode) {
  const url = `${process.env.REACT_APP_API_URL}/transfer/confirm`;
  let response;

  try {
    response = await fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify({
        id: paymentId,
        pin: pinCode,
      }),
    });
  } catch (err) {
    throw err;
  }

  const { data, result } = response;

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return data;
}

// отправить новый смс-код
export async function sendMoneyTransferSmsCode(pinMessageId) {
  const url = `${process.env.REACT_APP_API_URL}/transfer/confirm/resend/${pinMessageId}`;
  let response;

  try {
    response = await fetchWrapper(url);
  } catch (err) {
    throw err;
  }

  const { data, result } = response;

  if (result.code !== 200) {
    throw new Error(result.message);
  }

  return data;
}

// получить информацию о карте (провайдер, цвет, банк, ...)
// bin: string
export async function getBankCardInfo(bin) {
  const url = `${process.env.REACT_APP_API_URL}/cardinfo/info?bin=${bin}`;
  const response = await fetchWrapper(url, {
    credentials: 'omit',
  });

  if (!response) {
    throw new Error('empty card info');
  }

  return response;
}

export async function getSBPBankList() {
  const url = `/fps_banks.json`;
  const response = await fetchWrapper(url);
  if (!response) {
    throw new Error('Ошибка загрузки банков СБП');
  }
  return response.banks;
}
