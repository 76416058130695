import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './SESteps.scss';

const SESteps = ({ className, currentStep, totalSteps }) => {
  return (
    <div
      className={cx('se-steps', {
        [className]: className,
      })}
      data-testid="se-steps"
    >
      <span className="se-steps__item se-steps__item_current">{currentStep}</span>
      <span className="se-steps__item">/ {totalSteps}</span>
    </div>
  );
};

SESteps.propTypes = {
  className: PropTypes.string,
  currentStep: PropTypes.number.isRequired,
  totalSteps: PropTypes.number.isRequired,
};

SESteps.defaultProps = {
  className: null,
};

export default SESteps;
