import { fetchWrapper } from 'helpers';
import * as types from './action-types';

export const createShowCase =
  (
    link,
    name,
    needEmail,
    needFio,
    needPhone,
    needShowEmail,
    needShowFio,
    needShowPhone,
    sum,
    sumHint,
  ) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/pay-it/cashbox`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'POST',
        body: JSON.stringify({
          link,
          name,
          needEmail,
          needFio,
          needPhone,
          needShowEmail,
          needShowFio,
          needShowPhone,
          sum,
          sumHint,
        }),
      })
        .then((res) => {
          dispatch({
            type: types.CREATE_SHOW_CASE_SUCCESS,
            payload: {
              res,
            },
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: types.CREATE_SHOW_CASE_FAIL,
          });
          reject(err);
        });
    });
  };

export const changeShowCase =
  (
    uid,
    link,
    name,
    needEmail,
    needFio,
    needPhone,
    needShowEmail,
    needShowFio,
    needShowPhone,
    sum,
    sumHint,
  ) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/pay-it/cashbox/${uid}`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'PUT',
        body: JSON.stringify({
          link,
          name,
          needEmail,
          needFio,
          needPhone,
          needShowEmail,
          needShowFio,
          needShowPhone,
          sum,
          sumHint,
        }),
      })
        .then((res) => {
          dispatch({
            type: types.CHANGE_SHOW_CASE_SUCCESS,
            payload: {
              res,
            },
          });
          resolve(res);
        })
        .catch((err) => {
          dispatch({
            type: types.CHANGE_SHOW_CASE_FAIL,
          });
          reject(err);
        });
    });
  };

export const getAllMoneyboxes = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/moneybox/all`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.SAVE_MONEY_GET_ALL_MONEYBOX_SUCCESS,
          payload: res.data,
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.SAVE_MONEY_GET_ALL_MONEYBOX_FAIL,
        });
        reject(err);
      });
  });
};

export const addSaveMoney = (moneybox) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/moneybox`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'POST',
      body: JSON.stringify(moneybox),
    })
      .then((data) => {
        dispatch({
          type: types.SAVE_MONEY_ADD_MONEYBOX_SUCCESS,
          payload: moneybox,
        });
        resolve(data);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const changeMoneybox =
  (
    moneyboxUid,
    background,
    commentary,
    dateEndPayment,
    link,
    name,
    needEmail,
    needFio,
    needPhone,
    sum,
    sumHint,
  ) =>
  (dispatch) => {
    const url = `${process.env.REACT_APP_API_URL}/pay-it/moneybox/${moneyboxUid}`;

    return new Promise((resolve, reject) => {
      fetchWrapper(url, {
        method: 'PUT',
        body: JSON.stringify({
          background,
          commentary,
          dateEndPayment,
          link,
          name,
          needEmail,
          needFio,
          needPhone,
          sum,
          sumHint,
        }),
      })
        .then((res) => {
          dispatch({
            type: types.SAVE_MONEY_CHANGE_MONEYBOX_SUCCESS,
            payload: res,
          });
          resolve();
        })
        .catch((err) => {
          dispatch({
            type: types.SAVE_MONEY_CHANGE_MONEYBOX_FAIL,
          });
          reject(err);
        });
    });
  };

export const deletePayIt = (uid, payItType) => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/${uid}?payItType=${payItType}`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url, {
      method: 'DELETE',
    })
      .then((res) => {
        dispatch({
          type: types.DELETE_PAYIT_SUCCESS,
          payload: {
            uid,
            payItType,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.DELETE_PAYIT_FAIL,
        });
        reject(err);
      });
  });
};

export const getAllShowCase = () => (dispatch) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/cashbox/all`;

  return new Promise((resolve, reject) => {
    fetchWrapper(url)
      .then((res) => {
        dispatch({
          type: types.GET_ALL_SHOW_CASE_SUCCESS,
          payload: {
            res,
          },
        });
        resolve(res);
      })
      .catch((err) => {
        dispatch({
          type: types.GET_ALL_SHOW_CASE_FAIL,
        });
        reject(err);
      });
  });
};

export const getSaveMoneyTransactionOperationStatus = (id) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/moneybox/result/${id}/state`;
  return fetchWrapper(url);
};

export const getShowCaseTransactionOperationStatus = (id) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/cashbox/result/${id}/state`;
  return fetchWrapper(url);
};

export const validateLink = (link, type) => {
  const url = `${process.env.REACT_APP_API_URL}/pay-it/${type}/validate-link?link=${link}`;
  return fetchWrapper(url);
};

export const setUrlLinkError = (isUrlLinkError) => ({
  type: types.SET_URL_LINK_ERROR,
  payload: {
    isUrlLinkError,
  },
});

export const setUrlLinkLoading = (isUrlLinkLoading) => ({
  type: types.SET_URL_LINK_LOADING,
  payload: {
    isUrlLinkLoading,
  },
});
