/* eslint-disable import/prefer-default-export */
/* eslint-disable prettier/prettier */
/* eslint-disable no-nested-ternary */
import { createSelector } from 'reselect';
import { currencyFormater } from 'tools';

export const getLimits = createSelector(
  (store) => store.auth.limits,
  (_, group) => group,
  (limits, group) => mapLimits(limits, group),
);

export const getOptionsLimit = createSelector(
  (store) => store.auth.limits,
  (_, filterGroup) => filterGroup,
  (limits, filterGroup) => mapOptionsLimit(limits, filterGroup),
);

const mapLimits = (limits, activeGroup) =>
  Array.isArray(limits) &&
  GROUP_PRIORITY_ARRAY.map((currentGroup) => {
    const currLimit = limits.filter((ii) => ii.groupCode === currentGroup)[0];

    let stateGroup;
    if (GROUP_PRIORITY_OBJ[currLimit.groupCode] < GROUP_PRIORITY_OBJ[activeGroup])
      stateGroup = {
        status: 'disabled',
        actionText: 'Пройдено',
      };
    // else if (
    //   GROUP_PRIORITY_OBJ[currLimit.groupCode] > GROUP_PRIORITY_OBJ[activeGroup] &&
    //   GROUP_PRIORITY_OBJ[currLimit.groupCode] - GROUP_PRIORITY_OBJ[activeGroup] === 1
    // )
    //   stateGroup = {
    //     status: 'inProgress',
    //     actionText: 'В процессе получения',
    //   };
    else if (GROUP_PRIORITY_OBJ[currLimit.groupCode] === GROUP_PRIORITY_OBJ[activeGroup])
      stateGroup = {
        status: 'actived',
        actionText: 'Ваш статус',
      };
    else
      stateGroup = {
        status: 'availabled',
        actionText: 'Получить статус',
      };

    return {
      ...stateGroup,
      name:
        currentGroup === 'REGISTERED'
          ? 'Неавторизованная'
          : currentGroup === 'EDO'
          ? 'Доступ к ЭДО'
          : currentGroup === 'AUTHORIZED'
          ? 'Авторизованная'
          : 'Идентифицированная',
      accountType: currLimit.groupCode,
      options: [
        {
          key: 'Можно хранить в Кошельке',
          value: currencyFormater(currLimit.balanceLimit / 100),
        },
        {
          key: 'Макс. операция через Кошелек',
          value: currencyFormater(currLimit.maxSum / 100),
        },
        {
          key: 'Вывод средств',
          value: `${currencyFormater(currLimit.monthLimit / 100)} / мес.`,
        },
      ],
    };
  });

const mapOptionsLimit = (limits, filterGroup) => {
  const currentLimit =
    Array.isArray(limits) && limits.filter((limit) => limit.groupCode === filterGroup)[0];
  return [
    {
      key: 'Можно хранить в Кошельке',
      value: currencyFormater(currentLimit.balanceLimit / 100),
    },
    {
      key: 'Макс. операция через Кошелек',
      value: currencyFormater(currentLimit.maxSum / 100),
    },
    {
      key: 'Вывод средств',
      value: `${currencyFormater(currentLimit.monthLimit / 100)} / мес.`,
    },
  ];
};

const GROUP_PRIORITY_OBJ = {
  REGISTERED: 0,
  EDO: 1,
  AUTHORIZED: 2,
  IDENTIFIED: 3,
};

const GROUP_PRIORITY_ARRAY = [
  'REGISTERED',
  'EDO',
  'AUTHORIZED',
  // TODO(PROJECTS-6): раскоментить после интеграции со связным
  // 'IDENTIFIED'
];
