import { createAsyncThunk } from '@reduxjs/toolkit';
import { getDocumentsCertificateAPI } from './api';

/**
 * [Thunk] Запрос для получении информации по документу с сертификатом электронной подписи
 * @return {data<obj>}
 */
export const getDocumentsCertificateThunk = createAsyncThunk(
  'documents/certificateThunk',
  async ({ platform, id }, { rejectWithValue }) => {
    try {
      if (platform !== 'B' && platform !== 'P' && platform !== 'T')
        throw new Error('Указана не верная платформа');

      return getDocumentsCertificateAPI(id, platform);
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);
