import * as Api from 'api/partners';
import addDays from 'date-fns/addDays';
import { formatDateHuman, setHoursAndMinutesTheEndOfDay, setHoursAndMinutesToZero } from 'helpers';
import { addPartner, hidePartnerRequestById } from 'modules/profileLegasy/actions';
import { getPartnerList } from 'modules/profileLegasy/actions';
import * as types from './action-types';

export const getPartnerDocumentListFetching = () => ({
  type: types.GET_PARTNER_DOCUMENT_LIST_FETCHING,
});

export const getPartnerDocumentListSuccess = (payload) => ({
  type: types.GET_PARTNER_DOCUMENT_LIST_SUCCESS,
  payload,
});

export const getPartnerDocumentListFail = () => ({
  type: types.GET_PARTNER_DOCUMENT_LIST_FAIL,
});

export const getPartnerDocumentList =
  (platformName, partnershipId, page) => (dispatch, getState) => {
    const state = getState();
    const partnership = state.profile.partnerList.find(
      (it) => it.id === parseInt(partnershipId) && it.partner.platform === platformName,
    );
    if (!partnership) {
      dispatch(getPartnerDocumentListFail());
    } else {
      const partnerId = partnership.partner.id;
      dispatch(getPartnerDocumentListFetching());
      Api.getPartnerDocumentList(platformName, partnerId, page)
        .then((payload) => dispatch(getPartnerDocumentListSuccess(payload)))
        .catch(() => dispatch(getPartnerDocumentListFail()));
    }
  };

export const clearPartnerDocumentList = () => ({
  type: types.CLEAR_PARTNER_DOCUMENT_LIST,
});

export const getPartnerPaymentListFetching = () => ({
  type: types.GET_PARTNER_PAYMENT_LIST_FETCHING,
});

export const getPartnerPaymentListSuccess = (payload) => ({
  type: types.GET_PARTNER_PAYMENT_LIST_SUCCESS,
  payload,
});

export const getPartnerPaymentListFail = () => ({
  type: types.GET_PARTNER_PAYMENT_LIST_FAIL,
});

export const getPartnerPaymentList = (partnershipId, page) => (dispatch, getState) => {
  const state = getState();
  const { paymentsRange } = state.partners;
  const partnership = state.profile.partnerList.find((it) => it.id === parseInt(partnershipId));
  if (!partnership) {
    dispatch(getPartnerPaymentListFail());
  } else {
    const { platform } = partnership?.partner;
    const partnerId = partnership?.partner.id;
    dispatch(getPartnerPaymentListFetching());
    Api.getPartnerPaymentList(
      platform,
      partnerId,
      formatDateHuman(paymentsRange.startDate || addDays(setHoursAndMinutesToZero(new Date()), -6)),
      formatDateHuman(paymentsRange.endDate || setHoursAndMinutesTheEndOfDay(new Date())),
      page,
    )
      .then((payload) => dispatch(getPartnerPaymentListSuccess(payload)))
      .catch(() => dispatch(getPartnerPaymentListFail()));
  }
};

export const clearPartnerPaymentList = () => ({
  type: types.CLEAR_PARTNER_PAYMENT_LIST,
});

export const getPartnerPaymentStatsFetching = () => ({
  type: types.GET_PARTNER_PAYMENT_STATS_FETCHING,
});

export const getPartnerPaymentStatsSuccess = (payload) => ({
  type: types.GET_PARTNER_PAYMENT_STATS_SUCCESS,
  payload,
});

export const getPartnerPaymentStatsFail = () => ({
  type: types.GET_PARTNER_PAYMENT_STATS_FAIL,
});

export const getPartnerPaymentStats = () => (dispatch) => {
  dispatch(getPartnerPaymentStatsFetching());

  Api.getPartnerPaymentStats()
    .then((payload) => dispatch(getPartnerPaymentStatsSuccess(payload)))
    .catch(() => dispatch(getPartnerPaymentStatsFail()));
};

export const confirmPartnerRequestFetching = () => ({
  type: types.CONFIRM_PARTNER_REQUEST_FETCHING,
});

export const confirmPartnerRequestSuccess = () => ({
  type: types.CONFIRM_PARTNER_REQUEST_SUCCESS,
});

export const confirmPartnerRequestFail = () => ({
  type: types.CONFIRM_PARTNER_REQUEST_FAIL,
});

export const confirmPartnerRequest = (cardNumber, platform, requestId) => (dispatch) => {
  dispatch(confirmPartnerRequestFetching());

  Api.confirmPartnerRequest(cardNumber, platform, requestId)
    .then((res) => {
      dispatch(showPopupAddPartnerSuccess());
      dispatch(confirmPartnerRequestSuccess());
      dispatch(addPartner(res));
    })
    .catch(() => {
      dispatch(confirmPartnerRequestFail());
      dispatch(showPopupAddPartnerFail());
    });
};

export const declinePartnerRequestFetching = () => ({
  type: types.DECLINE_PARTNER_REQUEST_FETCHING,
});

export const declinePartnerRequestSuccess = () => ({
  type: types.DECLINE_PARTNER_REQUEST_SUCCESS,
});

export const declinePartnerRequestFail = () => ({
  type: types.DECLINE_PARTNER_REQUEST_FAIL,
});

export const declinePartnerRequest = (platform, requestId) => (dispatch) => {
  dispatch(declinePartnerRequestFetching());

  Api.declinePartnerRequest(platform, requestId)
    .then(() => {
      dispatch(hidePopupDeclinePartner());
      dispatch(declinePartnerRequestSuccess());
      dispatch(hidePartnerRequestById(requestId));
    })
    .catch(() => {
      dispatch(declinePartnerRequestFail());
      dispatch(hidePopupDeclinePartner());
    });
};

export const editPartnerBankCardFetching = () => ({
  type: types.EDIT_PARTNER_BANK_CARD_FETCHING,
});

export const editPartnerBankCardSuccess = () => ({
  type: types.EDIT_PARTNER_BANK_CARD_SUCCESS,
});

export const editPartnerBankCardFail = () => ({
  type: types.EDIT_PARTNER_BANK_CARD_FAIL,
});

export const editPartnerBankCard =
  (cardNumber, platform, partnershipId) => (dispatch, getState) => {
    dispatch(editPartnerBankCardFetching());

    const state = getState();
    const partnership = Object.assign(
      state.profile.partnerList.find((it) => it.id === parseInt(partnershipId)),
    );
    const isMaskedPan = partnership?.maskedPan;
    const { id: partnerId } = partnership.partner;

    if (isMaskedPan)
      Api.editPartnerBankCard(cardNumber, platform, partnershipId)
        .then(() => {
          dispatch(editPartnerBankCardSuccess());
          dispatch(getPartnerList());
          dispatch(showPopupEditBankCardSuccess());
        })
        .catch(() => {
          dispatch(editPartnerBankCardFail());
          dispatch(showPopupEditBankCardFail());
        });
    else
      Api.addPartnerBankCard(cardNumber, platform, partnerId)
        .then((rs) => {
          console.log('rs :>> ', rs);

          dispatch(editPartnerBankCardSuccess());
          dispatch(getPartnerList());
          dispatch(showPopupEditBankCardSuccess());
        })
        .catch((err) => {
          console.log('err :>> ', err);
          console.log('err :>> ', err + '');
          console.log('err :>> ', JSON.stringify(err));
          dispatch(editPartnerBankCardFail());
          dispatch(showPopupEditBankCardFail());
        });
  };

export const showCheck = (payload) => ({
  type: types.SHOW_CHECK,
  payload,
});

export const hideCheck = () => ({
  type: types.HIDE_CHECK,
});

export const showPopupCheck = (payload) => ({
  type: types.SHOW_POPUP_CHECK,
  payload,
});

export const hidePopupCheck = () => ({
  type: types.HIDE_POPUP_CHECK,
});

export const showPopupCalendar = () => ({
  type: types.SHOW_POPUP_CALENDAR,
});

export const hidePopupCalendar = () => ({
  type: types.HIDE_POPUP_CALENDAR,
});

export const showInlineCalendar = () => ({
  type: types.SHOW_INLINE_CALENDAR,
});

export const hideInlineCalendar = () => ({
  type: types.HIDE_INLINE_CALENDAR,
});

export const showPopupAddPartner = (payload) => ({
  type: types.SHOW_POPUP_ADD_PARTNER,
  payload,
});

export const hidePopupAddPartner = () => ({
  type: types.HIDE_POPUP_ADD_PARTNER,
});

export const showPopupEditBankCard = (payload) => ({
  type: types.SHOW_POPUP_EDIT_BANK_CARD,
  payload,
});

export const hidePopupEditBankCard = () => ({
  type: types.HIDE_POPUP_EDIT_BANK_CARD,
});

export const showPopupEditBankCardSuccess = () => ({
  type: types.SHOW_POPUP_EDIT_BANK_CARD_SUCCESS,
});

export const hidePopupEditBankCardSuccess = () => ({
  type: types.HIDE_POPUP_EDIT_BANK_CARD_SUCCESS,
});

export const showPopupEditBankCardFail = () => ({
  type: types.SHOW_POPUP_EDIT_BANK_CARD_FAIL,
});

export const hidePopupEditBankCardFail = () => ({
  type: types.HIDE_POPUP_EDIT_BANK_CARD_FAIL,
});

export const showPopupAddPartnerSuccess = () => ({
  type: types.SHOW_POPUP_ADD_PARTNER_SUCCESS,
});

export const hidePopupAddPartnerSuccess = () => ({
  type: types.HIDE_POPUP_ADD_PARTNER_SUCCESS,
});

export const showPopupAddPartnerFail = () => ({
  type: types.SHOW_POPUP_ADD_PARTNER_FAIL,
});

export const hidePopupAddPartnerFail = () => ({
  type: types.HIDE_POPUP_ADD_PARTNER_FAIL,
});

export const showPopupDeclinePartner = (payload) => ({
  type: types.SHOW_POPUP_DECLINE_PARTNER,
  payload,
});

export const hidePopupDeclinePartner = () => ({
  type: types.HIDE_POPUP_DECLINE_PARTNER,
});

export const setPartnerPaymentsRange = (payload) => ({
  type: types.SET_PARTNER_PAYMENTS_RANGE,
  payload,
});

export const setPartnerPaymentsCurrentRangeTab = (payload) => ({
  type: types.SET_PARTNER_PAYMENTS_CURRENT_RANGE_TAB,
  payload,
});

export const restorePartnerPaymentsCurrentRangeTab = () => ({
  type: types.RESTORE_PARTNER_PAYMENTS_CURRENT_RANGE_TAB,
});
