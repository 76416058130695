import React from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import cx from 'classnames';
import ru from 'date-fns/locale/ru';
import addDays from 'date-fns/addDays';
import addMonths from 'date-fns/addMonths';
import endOfMonth from 'date-fns/endOfMonth';
import startOfMonth from 'date-fns/startOfMonth';
import useWindowSize from '@rehooks/window-size';
import useThrottle from 'hooks/useThrottle';
import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { MONTHSRU, WEEK_DAYS_LOWERCASE_RU, OPERATIONS_MONTHS_REDUCED_RU } from 'constants/index';
import { setHoursAndMinutesTheEndOfDay, setHoursAndMinutesToZero } from 'helpers';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './RangePicker.overwrite.scss';
import './CalendarRangePicker.scss';
import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';

const matchDayPatterns = {
  narrow: ['Вс', 'Пн', 'Вт', 'Чт', 'Пт', 'Сб', 'Вс'],
  short: ['Вс', 'Пн', 'Вт', 'Чт', 'Пт', 'Сб', 'Вс'],
  abbreviated: ['Вс', 'Пн', 'Вт', 'Чт', 'Пт', 'Сб', 'Вс'],
  wide: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
};

ru.localize.day = buildLocalizeFn({
  values: matchDayPatterns,
  defaultWidth: 'wide',
});

const CalendarRangePicker = React.forwardRef(
  ({ className, range, onChange, onAccept, onCancel }, ref) => {
    const windowSize = useWindowSize();
    const throttledFn = useThrottle(500);
    // по-умолчанию "свой период"
    const [activeRangeIndex, setActiveRangeIndex] = React.useState(5);
    const [months, setMonths] = React.useState(2);

    React.useEffect(() => {
      throttledFn(() => {
        if (windowSize.innerWidth < 600) {
          setMonths(1);
        } else {
          setMonths(2);
        }
      });
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [windowSize]);

    function renderNavigation(focusedDate, changeShownDate, props) {
      function showPreviousMonth() {
        changeShownDate(-1, 'monthOffset');
      }

      function showNextMonth() {
        changeShownDate(+1, 'monthOffset');
      }

      const currentMonthIndex = focusedDate.getMonth();
      const currentYear = focusedDate.getFullYear();
      let nextMonthIndex = currentMonthIndex + 1;
      let nextYear = currentYear;

      if (currentMonthIndex + 1 > 11) {
        nextMonthIndex = 0;
        nextYear += 1;
      }

      const currentMonth = MONTHSRU[currentMonthIndex];
      const nextMonth = MONTHSRU[nextMonthIndex];

      return (
        <div className="calendar-range-picker__calendar-controls">
          <nav className="calendar-range-picker__calendar-nav">
            <button onClick={showPreviousMonth}>
              <IconArrow className="calendar-range-picker__calendar-left-arrow" />
            </button>
            <span>
              {currentMonth} {currentYear}
            </span>
            <button onClick={showNextMonth}>
              <IconArrow />
            </button>
          </nav>
          <nav className="calendar-range-picker__calendar-nav">
            <button onClick={showPreviousMonth}>
              <IconArrow className="calendar-range-picker__calendar-left-arrow" />
            </button>
            <span>
              {nextMonth} {nextYear}
            </span>
            <button onClick={showNextMonth}>
              <IconArrow />
            </button>
          </nav>
        </div>
      );
    }

    return (
      <div className={cx('calendar-range-picker', className)} ref={ref}>
        <div className="calendar-range-picker__container">
          <div className="calendar-range-picker__calendar">
            <DateRange
              onChange={(item) => {
                // если сработало это событие, то пользователь кликнул на любую дату
                // и тем самым сделал "свой период". Поэтому мы делает этот период активным
                setActiveRangeIndex(5);
                onChange([item.selection]);
              }}
              months={months}
              showMonthAndYearPickers={false}
              showDateDisplay={false}
              showMonthArrow={false}
              ranges={range}
              locale={ru}
              direction={'horizontal'}
              maxDate={new Date()}
              navigatorRenderer={renderNavigation}
            />
          </div>
          <div className="calendar-range-picker__chosen-dates">
            <div className="calendar-range-picker__chosen-date">
              {WEEK_DAYS_LOWERCASE_RU[range[0].startDate.getDay() - 1]},{' '}
              {range[0].startDate.getDate()}{' '}
              {OPERATIONS_MONTHS_REDUCED_RU[range[0].startDate.getMonth()]}{' '}
              {range[0].startDate.getFullYear()}
            </div>
            <div className="calendar-range-picker__chosen-date">
              {WEEK_DAYS_LOWERCASE_RU[range[0].endDate.getDay() - 1]}, {range[0].endDate.getDate()}{' '}
              {OPERATIONS_MONTHS_REDUCED_RU[range[0].endDate.getMonth()]}{' '}
              {range[0].endDate.getFullYear()}
            </div>
          </div>
        </div>
        <aside className="calendar-range-picker__sidebar">
          <nav className="calendar-range-picker__range-nav">
            <button
              className={cx(activeRangeIndex === 0 && 'active')}
              onClick={() => {
                setActiveRangeIndex(0);
                onChange([
                  {
                    startDate: setHoursAndMinutesToZero(new Date()),
                    endDate: setHoursAndMinutesTheEndOfDay(new Date()),
                    key: 'selection',
                  },
                ]);
              }}
            >
              Сегодня
            </button>
            <button
              className={cx(activeRangeIndex === 1 && 'active')}
              onClick={() => {
                setActiveRangeIndex(1);
                onChange([
                  {
                    startDate: addDays(setHoursAndMinutesToZero(new Date()), -1),
                    endDate: addDays(setHoursAndMinutesTheEndOfDay(new Date()), -1),
                    key: 'selection',
                  },
                ]);
              }}
            >
              Вчера
            </button>
            <button
              className={cx(activeRangeIndex === 2 && 'active')}
              onClick={() => {
                setActiveRangeIndex(2);
                onChange([
                  {
                    startDate: addDays(setHoursAndMinutesToZero(new Date()), -6),
                    endDate: setHoursAndMinutesTheEndOfDay(new Date()),
                    key: 'selection',
                  },
                ]);
              }}
            >
              За 7 дней
            </button>
            <button
              className={cx(activeRangeIndex === 3 && 'active')}
              onClick={() => {
                setActiveRangeIndex(3);
                onChange([
                  {
                    startDate: startOfMonth(setHoursAndMinutesToZero(new Date())),
                    endDate: setHoursAndMinutesTheEndOfDay(new Date()),
                    key: 'selection',
                  },
                ]);
              }}
            >
              В этом месяце
            </button>
            <button
              className={cx(activeRangeIndex === 4 && 'active')}
              onClick={() => {
                setActiveRangeIndex(4);
                onChange([
                  {
                    startDate: addMonths(startOfMonth(setHoursAndMinutesToZero(new Date())), -1),
                    endDate: addMonths(endOfMonth(setHoursAndMinutesTheEndOfDay(new Date())), -1),
                    key: 'selection',
                  },
                ]);
              }}
            >
              В прошлом месяце
            </button>
            <button
              className={cx(activeRangeIndex === 5 && 'active')}
              onClick={() => {
                setActiveRangeIndex(5);
              }}
            >
              Свой диапазон
            </button>
          </nav>
          <nav className="calendar-range-picker__dialog-nav">
            <button
              className="calendar-range-picker__accept-button"
              onClick={() => {
                onAccept && onAccept();
              }}
            >
              Применить
            </button>
            <button
              className="calendar-range-picker__cancel-button"
              onClick={() => {
                onCancel && onCancel();
              }}
            >
              Отмена
            </button>
          </nav>
        </aside>
      </div>
    );
  },
);

CalendarRangePicker.propTypes = {
  className: PropTypes.string,
  range: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onAccept: PropTypes.func,
  onCancel: PropTypes.func,
};

export default CalendarRangePicker;
