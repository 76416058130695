import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import AriaModal from 'react-aria-modal';
import { animated, useSpring } from 'react-spring';

// Misc
import { HINT_TYPES } from 'constants/index';

// Icons
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

// Styles
import './HintNotifications.scss';

const HintNotifications = ({
  notification,
  children,
  hintCloseHandler,
  hintEnterHandler,
  isNotificationOpened,
  isNotificationEntered,
}) => {
  const transY = y => `translateY(${y}px)`;
  const [opacity, setOpacity] = useState(0);
  const [y, setY] = useState(50);

  useEffect(() => {
    setOpacity(!isNotificationEntered ? 0 : 1);
    setY(!isNotificationEntered ? 50 : 0);
  }, [isNotificationEntered]);

  const springOptions = {
    from: {
      opacity: 0,
      y: 50,
    },
    opacity,
    y,
    config: { mass: 0.6, tension: 200, friction: 60 },
  };

  const spring1 = useSpring({
    ...springOptions,
    delay: 300,
  });

  const spring2 = useSpring({
    ...springOptions,
    delay: 400,
    config: { mass: 0.6, tension: 200, friction: 60 },
  });

  let hintObject;
  if (notification.type && HINT_TYPES[notification.type]) {
    hintObject = HINT_TYPES[notification.type];
  } else {
    hintObject = HINT_TYPES.default;
  }

  useEffect(() => {
    document.body.style.overflow = '';

    if (isNotificationOpened) {
      document.body.style.overflow = 'hidden';
    }
  }, [isNotificationOpened]);

  return (
    <AriaModal
      underlayClass="hint-notifications"
      onExit={hintCloseHandler}
      onEnter={hintEnterHandler}
      titleText=" "
      underlayColor="rgba(10, 17, 37, 0.5)"
      mounted={isNotificationOpened}
      className="hint-notifications"
    >
      <div
        className={cx('hint-notifications__main', {
          'hint-notifications__main-enter': isNotificationOpened,
          'hint-notifications__main-enter-done': isNotificationEntered,
        })}
      >
        <div className="hint-notifications__top">
          <button className="hint-notifications__close" type="button" onClick={hintCloseHandler}>
            <IconClose className="hint-notifications__close-icon" />
          </button>
        </div>

        <div className="hint-notifications__inner-wrap">
          <div className="hint-notifications__logo-wrap">
            {hintObject.ext === 'svg' && (
              <img
                src={`${process.env.PUBLIC_URL}/${hintObject.image}.${hintObject.ext}`}
                alt=""
                width={`${hintObject.width}px`}
                height={`${hintObject.height}px`}
                className="hint-notifications__logo"
              />
            )}
            {hintObject.ext !== 'svg' && (
              <img
                src={`${process.env.PUBLIC_URL}/${hintObject.image}.${hintObject.ext}`}
                alt=""
                srcSet={`${process.env.PUBLIC_URL}/${hintObject.image}@2x.${hintObject.ext}`}
                className="hint-notifications__logo"
              />
            )}
          </div>

          <div className="hint-notifications__inner">
            {notification.title && (
              <animated.h2
                style={{
                  opacity: spring1.opacity,
                  transform: spring1.y.interpolate(transY),
                }}
                className="hint-notifications__title"
              >
                {notification.title}
              </animated.h2>
            )}

            <animated.div
              style={{
                opacity: spring2.opacity,
                transform: spring2.y.interpolate(transY),
              }}
            >
              {children}
            </animated.div>
          </div>
        </div>
      </div>
    </AriaModal>
  );
};

HintNotifications.propTypes = {
  notification: PropTypes.shape({
    title: PropTypes.string,
    type: PropTypes.number,
  }).isRequired,
  children: PropTypes.node.isRequired,
  hintCloseHandler: PropTypes.func.isRequired,
  hintEnterHandler: PropTypes.func.isRequired,
  isNotificationOpened: PropTypes.bool,
  isNotificationEntered: PropTypes.bool,
};

HintNotifications.defaultProps = {
  isNotificationOpened: false,
  isNotificationEntered: false,
};

export default HintNotifications;
