import * as Api from 'api/wallet-closing';
import * as types from './action-types';

export const getWalletClosingSmsCodeFetching = () => ({
  type: types.GET_WALLET_CLOSING_SMS_CODE_FETCHING,
});

export const getWalletClosingSmsCodeSuccess = (payload) => ({
  type: types.GET_WALLET_CLOSING_SMS_CODE_SUCCESS,
  payload,
});

export const getWalletClosingSmsCodeFail = () => ({
  type: types.GET_WALLET_CLOSING_SMS_CODE_FAIL,
});

/** Запросить код подтверждения для закрытия аккаунта. */
export const getWalletClosingSmsCode = () => (dispatch) => {
  dispatch(getWalletClosingSmsCodeFetching());

  Api.getWalletClosingSmsCode()
    .then((payload) => dispatch(getWalletClosingSmsCodeSuccess(payload)))
    .catch((err) => {
      if (
        err?.result?.message === 'Error while closing the ewallet. User has a processing operation.'
      ) {
        dispatch(showWalletClosingUnprocessingOperationPopup());
      } else {
        dispatch(showWalletClosingErrorPopup());
      }

      dispatch(getWalletClosingSmsCodeFail());
    });
};

export const resendWalletClosingSmsCodeFetching = () => ({
  type: types.RESEND_WALLET_CLOSING_SMS_CODE_FETCHING,
});

export const resendWalletClosingSmsCodeSuccess = (payload) => ({
  type: types.RESEND_WALLET_CLOSING_SMS_CODE_SUCCESS,
  payload,
});

export const resendWalletClosingSmsCodeFail = (payload) => ({
  type: types.RESEND_WALLET_CLOSING_SMS_CODE_FAIL,
  payload,
});

export const resendWalletClosingSmsCode = () => (dispatch, getState) => {
  const state = getState();
  const operationId = state.walletClosing.smsRequest.id;

  dispatch(resendWalletClosingSmsCodeFetching());

  Api.resendWalletClosingSmsCode(operationId)
    .then((payload) => dispatch(resendWalletClosingSmsCodeSuccess(payload)))
    .catch((err) => {
      let message = '';

      /** Достигнуто максимальное кол-во отправленных СМС сообщений. */
      if (err?.result?.code === 400 && err?.data === 'actionPinAccept.resend.count.error') {
        message = 'SMS_LIMIT';
      } else if (err?.result?.code === 400 && err?.data === 'actionPinAccept.resend.error') {
        /** Время жизни пин-кода истекло. */
        message = 'EXPIRED_CODE';
      } else {
        dispatch(showWalletClosingErrorPopup());
      }

      dispatch(resendWalletClosingSmsCodeFail(message));
    });
};

export const closeWalletFetching = () => ({
  type: types.CLOSE_WALLET_FETCHING,
});

export const closeWalletSuccess = () => ({
  type: types.CLOSE_WALLET_SUCCESS,
});

export const closeWalletFail = (payload) => ({
  type: types.CLOSE_WALLET_FAIL,
  payload,
});

/**
 * Закрыть кошелек.
 * @param {string} pin - пинкод из смс
 */
export const closeWallet = (pin) => (dispatch, getState) => {
  const state = getState();
  const actionPinAcceptId = state.walletClosing.smsRequest.id;

  dispatch(closeWalletFetching());

  Api.closeWallet(actionPinAcceptId, pin)
    .then(() => {
      dispatch(closeWalletSuccess());
      dispatch(showWalletClosingSuccessPopup());
    })
    .catch((err) => {
      let message = '';

      if (err?.data === 'pin.confirm.pin1.incorrect') {
        message = 'WRONG_CODE';
      }

      if (err?.data === 'pin.confirm.attempts-error') {
        message = 'ATTEMPTS_LIMIT';
      }

      if (err?.data === 'pin.confirm.end-wait') {
        message = 'EXPIRED_CODE';
      }

      if (!message) {
        dispatch(showWalletClosingErrorPopup());
      }

      dispatch(closeWalletFail(message));
    });
};

/** Показать попап с предложением вывода денег. */
export const showWalletClosingBalancePopup = () => ({
  type: types.SHOW_WALLET_CLOSING_BALANCE_POPUP,
});

/** Скрыть попап с предложением вывода денег. */
export const hideWalletClosingBalancePopup = () => ({
  type: types.HIDE_WALLET_CLOSING_BALANCE_POPUP,
});

/** Показать попап с уведомлением о том, что есть незавершенная операция */
export const showWalletClosingUnprocessingOperationPopup = () => ({
  type: types.SHOW_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP,
});

/** Скрыть попап с уведомлением о том, что есть незавершенная операция */
export const hideWalletClosingUnprocessingOperationPopup = () => ({
  type: types.HIDE_WALLET_CLOSING_UNPROCESSING_OPERATION_POPUP,
});

/** Показать попап с подтверждением с помощью смс-кода. */
export const showWalletClosingConfirmationPopup = () => ({
  type: types.SHOW_WALLET_CLOSING_CONFIRMATION_POPUP,
});

/** Скрыть попап с подтверждением с помощью смс-кода. */
export const hideWalletClosingConfirmationPopup = () => ({
  type: types.HIDE_WALLET_CLOSING_CONFIRMATION_POPUP,
});

/** Показать попап после завершения закрытия кошелька. */
export const showWalletClosingSuccessPopup = () => ({
  type: types.SHOW_WALLET_CLOSING_SUCCESS_POPUP,
});

/** Скрыть попап после завершения закрытия кошелька. */
export const hideWalletClosingSuccessPopup = () => ({
  type: types.HIDE_WALLET_CLOSING_SUCCESS_POPUP,
});

/** Показать попап с ошибкой */
export const showWalletClosingErrorPopup = () => ({
  type: types.SHOW_WALLET_CLOSING_ERROR_POPUP,
});

/** Скрыть попап с ошибкой */
export const hideWalletClosingErrorPopup = () => ({
  type: types.HIDE_WALLET_CLOSING_ERROR_POPUP,
});

/** Сбросить состояние до исходного */
export const resetState = () => ({
  type: types.RESET_WALLET_CLOSING_STATE,
});
