import PropTypes from 'prop-types';
import React from 'react';

import PaymentMessage from 'modules/profileLegasy/components/PaymentMessage/PaymentMessage';

import { PAYMENT_TRANSFER_FAIL, PAYMENT_TRANSFER_LOADING } from 'constants/index.js';

import { ReactComponent as Logo } from 'static/assets/logo-white.svg';
import profileBg from 'static/assets/profile-bg.png';

import './PaymentProcessCard.scss';

const PaymentProcessCard = ({ paymentStatus }) => {
  return (
    <div className="payment-process-card">
      <div className="payment-process-card__header">
        <Logo className="payment-process-card__header-logo" />

        <img
          src={profileBg}
          alt="Фирменный бэкгранунд Самозаняты.рф"
          className="payment-process-card__header-bg"
        />
      </div>

      {paymentStatus === PAYMENT_TRANSFER_LOADING && (
        <PaymentMessage
          className="payment-process-card__payment-message"
          status={paymentStatus}
          text="Платеж обрабатывается. Это может занять некоторое время. Пожалуйста, подождите."
        />
      )}

      {paymentStatus === PAYMENT_TRANSFER_FAIL && (
        <PaymentMessage className="payment-process-card__payment-message" status={paymentStatus}>
          <div className="payment-message__text">
            Упс. Что-то пошло не так и мы не смогли совершить платеж. Если проблема не будет решена,{' '}
            <span className="payment-message__text-link js-help">напишите нам</span>
          </div>
        </PaymentMessage>
      )}
    </div>
  );
};

PaymentProcessCard.propTypes = {
  paymentStatus: PropTypes.string.isRequired,
};

export default PaymentProcessCard;
