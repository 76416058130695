import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';

import { createApplePaySession } from 'modules/profileLegasy/actions';

// Style
import './ApplePayButton.scss';

const ApplePayButton = ({ dispatch, amount, disableApplePayButton, payByApplePay }) => {
  /** Клик по кнопке Apple Pay Plain */
  const handleApplePayBegin = () => {
    // if (window && window.PaymentRequest) {
    //   console.log('PAYMENT REQUEST IN WINDOW');
    //
    //   const applePayMethod = {
    //     supportedMethods: 'https://apple.com/apple-pay',
    //     data: {
    //       version: 3,
    //       merchantIdentifier: 'merchant.ru.selfwork',
    //       merchantCapabilities: ['supports3DS'],
    //       supportedNetworks: ['visa', 'masterCard'],
    //       countryCode: 'RU',
    //     },
    //   };
    //
    //   const paymentDetails = {
    //     total: {
    //       label: 'SAMOZANYATYE, OOO',
    //       amount: { value: amount, currency: 'RUB' },
    //     },
    //   };
    //
    //   const request = new PaymentRequest(applePayMethod, paymentDetails);
    //
    //   request.onmerchantvalidation =  (event) => {
    //     // Have your server fetch a payment session from event.validationURL.
    //
    //     /* eslint-disable no-console, react/no-this-in-sfc */
    //     if (event && event.validationURL) {
    //       dispatch(createApplePaySession(event.validationURL))
    //         .then(res => {
    //           console.log('STEP 1: onvalidatemerchant', JSON.parse(res.result.message));
    //           if (res && res.result && res.result.message) {
    //             session.completeMerchantValidation(JSON.parse(res.result.message));
    //           }
    //         })
    //         .catch(err => {
    //           console.log('ERROR IN CREATE APPLE SESSION', err);
    //           session.abort();
    //         });
    //       /* eslint-enable no-console, react/no-this-in-sfc */
    //     }
    //     const sessionPromise = fetchPaymentSession(event.validationURL);
    //     event.complete(sessionPromise);
    //   };
    // } else {
    //   console.log('NO PAYMENT REQUEST IN WINDOW');

    const request = {
      countryCode: 'RU',
      currencyCode: 'RUB',
      supportedNetworks: ['visa', 'masterCard'],
      merchantCapabilities: ['supports3DS'],
      total: { label: 'SAMOZANYATYE, OOO', amount },
    };

    const session = new window.ApplePaySession(1, request);

    session.begin();

    session.onpaymentauthorized = (data) => {
      console.log('STEP 2: onpaymentauthorized', data.payment.token.paymentData.signature);
      if (
        data &&
        data.payment &&
        data.payment.token &&
        data.payment.token.paymentData &&
        data.payment.token.paymentData.signature
      ) {
        payByApplePay(data.payment.token.paymentData.signature);
      }
    };

    session.onvalidatemerchant = (event) => {
      /* eslint-disable no-console, react/no-this-in-sfc */
      if (event && event.validationURL) {
        dispatch(createApplePaySession(event.validationURL))
          .then((res) => {
            if (res && res.result && res.result.message) {
              console.log('STEP 1: onvalidatemerchant');
              session.completeMerchantValidation(JSON.parse(res.result.message));
            }
          })
          .catch((err) => {
            console.log('ERROR IN CREATE APPLE SESSION', err);
            session.abort();
          });
        /* eslint-enable no-console, react/no-this-in-sfc */
      }
    };
    // }
  };

  return (
    <button
      type="button"
      className="apple-pay-button"
      onClick={handleApplePayBegin}
      disabled={disableApplePayButton}
      aria-label="Оплатить Apple Pay"
    />
  );
};

ApplePayButton.propTypes = {
  dispatch: PropTypes.func.isRequired,
  amount: PropTypes.number.isRequired,
  disableApplePayButton: PropTypes.bool,
  payByApplePay: PropTypes.func,
};

ApplePayButton.defaultProps = {
  disableApplePayButton: false,
  payByApplePay: () => {},
};

export default connect()(ApplePayButton);
