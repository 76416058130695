import React from 'react';

// Images
import { ReactComponent as AppStore } from 'static/assets/app-store.svg';
import { ReactComponent as GooglePlay } from 'static/assets/google-play.svg';

const HintNotRegisteredInFNS = () => (
  <>
    <div className="hint-auth__list hint-auth__list_middle">
      <div className="hint-auth__list-item">
        Вам нужно получить статус самозанятого в ФНС, то есть зарегистрироваться как плательщик НПД.
        Для этого скачайте приложение
        <a
          href="https://npd.nalog.ru/app/"
          target="_blank"
          rel="noopener noreferrer"
          className="hint-auth__list-item-link"
        >
          &quot;Мой Налог&quot;
        </a>
        или зайдите на сайт
        <a
          href="https://lknpd.nalog.ru"
          target="_blank"
          rel="noopener noreferrer"
          className="hint-auth__list-item-link"
        >
          https://lknpd.nalog.ru
        </a>
        и пройдите регистрацию
      </div>
    </div>

    <div className="hint-auth__app-links">
      <div className="hint-auth__app-links-item">
        <AppStore className="hint-auth__app-links-item-img hint-auth__app-links-item-img_app-store" />
      </div>

      <div className="hint-auth__app-links-item">
        <GooglePlay className="hint-auth__app-links-item-img hint-auth__app-links-item-img_google-play" />
      </div>
    </div>
  </>
);

export default HintNotRegisteredInFNS;
