import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';

import './OpenPopupButton.scss';

const OpenPopupButton = ({ onClick, title, className }) => {
  return (
    <div
      className={cx('open-popup-button', {
        [className]: className,
      })}
    >
      <button className="open-popup-button__item" type="button" onClick={onClick}>
        {title}
      </button>
    </div>
  );
};

OpenPopupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  className: PropTypes.string,
};

OpenPopupButton.defaultProps = {
  className: null,
};

export default OpenPopupButton;
