import React from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';
import PropTypes from 'prop-types';

// Icons
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';

// Styles
import './Warning.scss';

const Warning = ({ className, customText, isStatic, positionAbsolute }) => (
  <div
    className={cx('warning', {
      [className]: className,
      warning_static: isStatic,
      warning_position_absolute: positionAbsolute,
    })}
  >
    <div className="warning__container">
      <IconInfo className="warning__icon" />

      {customText ? (
        <>{customText}</>
      ) : (
        <div className="warning__text">
          Для совершения операций необходимо подтвердить статус «Самозанятого» в разделе
          <Link to="/self-employment-from-lk" className="warning__link">
            Мой статус
          </Link>
        </div>
      )}
    </div>
  </div>
);

Warning.propTypes = {
  className: PropTypes.string,
  customText: PropTypes.shape({}),
  isStatic: PropTypes.bool,
  positionAbsolute: PropTypes.bool,
};

Warning.defaultProps = {
  className: null,
  customText: null,
  isStatic: false,
  positionAbsolute: true,
};

export default Warning;
