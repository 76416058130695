import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import useToggleAndOutClick from 'use-toggle-and-outclick';
import { CSSTransition } from 'react-transition-group';

import { getAcquiringSettings } from 'modules/acquiring/actions';
import PagePreviewPopup from 'modules/acquiring/components/PagePreviewPopup/PagePreviewPopup';
import PaymentWaysItem from 'modules/acquiring/components/PaymentWaysItem/PaymentWaysItem';
import PageTitle from 'modules/shared/components/PageTitle/PageTitle';

import { ReactComponent as IconDemo } from 'static/assets/icon-go-to-demo.svg';
import { HINT_TIMEOUTS, FIRST_STYLE, SMART_PAYMENT } from 'constants/index';

import preview1 from 'static/assets/page-preview-1.png';
import preview2 from 'static/assets/page-preview-2.png';
import preview3 from 'static/assets/page-preview-3.png';
import preview4 from 'static/assets/page-preview-4.png';

import './PaymentWaysTab.scss';

const TURNED_ON = 'TURNED_ON';
const TURNED_OFF = 'TURNED_OFF';
const DISABLED = 'DISABLED';

export const PaymentWaysTab = ({ chosenShop }) => {
  const [connectedPaymentWays, setConnectedPaymentWays] = useState([]);
  const [isPaymentWaysLoading, setIsPaymentWaysLoading] = useState(false);
  const dispatch = useDispatch();
  const [titleForPopup, setTitleForPopup] = useState('');
  const [imageForPopup, setImageForPopup] = useState('');
  const [isHintOpen, hintEl, toggleDrop, closeHintExplicitly] = useToggleAndOutClick();

  /** Список айтемов способов оплаты */
  const list = [
    {
      id: 'BANK',
      title: 'Банковские карты',
      desc: 'Оплата банковскими картами VISA, Mastercard, Maestro, Мир.',
      status:
        !isPaymentWaysLoading &&
        connectedPaymentWays &&
        connectedPaymentWays.length > 0 &&
        connectedPaymentWays.find((item) => item === 'BANK')
          ? TURNED_ON
          : TURNED_OFF,
    },
    {
      id: 'KEEPER',
      title: 'Кошелек Самозанятые.рф',
      desc: 'Оплата из кошелька самозанятого или привязаных карт.',
      status: DISABLED,
    },
    // {
    //   id: 'APPLE_PAY',
    //   title: 'Apple Pay',
    //   desc: 'Оплата банковскими картами через Apple Pay.',
    //   status: DISABLED,
    // },
    // {
    //   id: 'GOOGLE_PAY',
    //   title: 'Google Pay',
    //   desc: 'Оплата банковскими картами через Google Pay. ',
    //   status: DISABLED,
    // },
  ];

  /** Устанавливаем заголовок для превью страницы оплаты */
  const setPopupTitle = (connectionMethod, pageStyle) => {
    if (connectionMethod === SMART_PAYMENT) {
      return pageStyle === FIRST_STYLE ? 'Светлый минимализм' : 'Яркая абстракция';
    } else {
      return pageStyle === FIRST_STYLE ? 'Модерн: темный' : 'Модерн: светлый';
    }
  };

  /** Устанавливаем картинку для превью страницы оплаты */
  const setPopupPreviewImage = (connectionMethod, pageStyle) => {
    if (connectionMethod === SMART_PAYMENT) {
      return pageStyle === FIRST_STYLE ? preview1 : preview2;
    } else {
      return pageStyle === FIRST_STYLE ? preview3 : preview4;
    }
  };

  /** Берем данные по мерчанту */
  useEffect(() => {
    setIsPaymentWaysLoading(true);
    dispatch(getAcquiringSettings(chosenShop))
      .then((res) => {
        if (res && res.data) {
          setConnectedPaymentWays(res.data.cashSources);
          setTitleForPopup(setPopupTitle(res.data.connectionMethod, res.data.paymentPageStyle));
          setImageForPopup(
            setPopupPreviewImage(res.data.connectionMethod, res.data.paymentPageStyle),
          );
          setIsPaymentWaysLoading(false);
        }
      })
      .catch(() => {
        setConnectedPaymentWays(['BANK']);
        setIsPaymentWaysLoading(false);
      });
  }, [chosenShop, dispatch]);

  //@todo: Когда будут доступны несколько вариантов оплаты, доделать метод выбора оплаты
  /* const changePaymentWays = () => {
    dispatch(updateMerchantPaymentWays(chosenShop, ["BANK"])).then((res) => {
      console.log(res);
    }).catch((err) => {
      console.log(err);})
  };

  useEffect(() => {
    changePaymentWays();
  }, []);*/

  return (
    <div className="payment-ways-tab">
      <div className="payment-ways-tab__top">
        <PageTitle title="Способы платжей" className="payment-ways-tab__title" />

        <button
          className="payment-ways-tab__go-to-demo"
          type="button"
          onClick={toggleDrop}
          data-testid="show-demo-button"
        >
          <span className="payment-ways-tab__go-to-demo-text">Демо страницы оплаты</span>
          <IconDemo className="payment-ways-tab__go-to-demo-icon" />
        </button>
      </div>

      <div className="payment-ways-tab__list">
        {list &&
          list.length &&
          list.map((item) => (
            <PaymentWaysItem
              status={item.status}
              title={item.title}
              key={item.id}
              desc={item.desc}
              className="payment-ways-tab__item"
              isPaymentWaysLoading={isPaymentWaysLoading}
            />
          ))}
      </div>

      <CSSTransition
        in={isHintOpen}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="page-preview-popup"
      >
        <PagePreviewPopup
          ref={hintEl}
          onCloseBtnClick={closeHintExplicitly}
          className="accounting__hint"
          title={titleForPopup}
          image={imageForPopup}
        />
      </CSSTransition>
    </div>
  );
};

PaymentWaysTab.propTypes = {
  chosenShop: PropTypes.string.isRequired,
};

export default PaymentWaysTab;
