import moment from 'moment/moment';

export const formatDateRFC3339 = (date) => {
  return moment(date).format('YYYY-MM-DD HH:mm:ssZ');
};

export const formatDateRFC3339WithCurrentTime = (date) => {
  const m = moment();
  return moment(date)
    .set({ hour: m.hour(), minute: m.minute(), second: m.second() })
    .format('YYYY-MM-DDTHH:mm:ss.000ZZ');
};
