import { LOG_OUT_SUCCESS } from 'modules/authLegasy/actions/action-types';
import * as types from 'modules/acquiring/actions/action-types';

const initialState = {
  acquiringActiveTab: 'OPERATIONS_ACQUIRING_TAB',
  chosenPaymentWaysFilters: [],
  chosenPaymentStatusFilters: [],
  chosenSumFromFilters: null,
  chosenSumUntilFilters: null,
  searchWord: null,
  datesRange: null,
  analyticsDatesRange: null,
  isOperationsCalendarFilterOpen: false,
  merchantsOperations: {},
  chosenShop: null,
  statusOfChoseShop: null,
};

const acquiringReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOG_OUT_SUCCESS:
      return {
        ...state,
        acquiringActiveTab: 'OPERATIONS_ACQUIRING_TAB',
        chosenPaymentWaysFilters: [],
        chosenPaymentStatusFilters: [],
        chosenSumFromFilters: null,
        chosenSumUntilFilters: null,
        searchWord: null,
        datesRange: null,
        analyticsDatesRange: null,
        isOperationsCalendarFilterOpen: false,
        merchantsOperations: {},
      };

    case types.SET_ACQUIRING_ACTIVE_TAB:
      return {
        ...state,
        acquiringActiveTab: action.payload.acquiringActiveTab,
      };

    case types.SET_CHOSEN_PAYMENT_WAYS_FILTERS:
      return {
        ...state,
        chosenPaymentWaysFilters: action.payload.chosenPaymentWaysFilters,
      };

    case types.SET_CHOSEN_PAYMENT_STATUS_FILTERS:
      return {
        ...state,
        chosenPaymentStatusFilters: action.payload.chosenPaymentStatusFilters,
      };

    case types.SET_CHOSEN_SUM_FROM_FILTERS:
      return {
        ...state,
        chosenSumFromFilters: action.payload.chosenSumFromFilters,
      };

    case types.SET_CHOSEN_SUM_UNTIL_FILTERS:
      return {
        ...state,
        chosenSumUntilFilters: action.payload.chosenSumUntilFilters,
      };

    case types.SET_SEARCH_WORD:
      return {
        ...state,
        searchWord: action.payload.searchWord,
      };

    case types.SET_SEARCH_DATES_RANGE:
      return {
        ...state,
        datesRange: action.payload.datesRange,
      };

    case types.SET_ANALYTICS_DATES_RANGE:
      return {
        ...state,
        analyticsDatesRange: action.payload.analyticsDatesRange,
      };

    case types.SET_IS_OPERATIONS_CALENDAR_FILTER_OPEN:
      return {
        ...state,
        isOperationsCalendarFilterOpen: action.payload.isOperationsCalendarFilterOpen,
      };

    case types.GET_MERCHANTS_OPERATIONS_LIST_SUCCESS:
      return {
        ...state,
        merchantsOperations:
          action.payload.page === 1
            ? action.payload.res.data
            : {
                ...state.merchantsOperations,
                payments: action.payload.res.data.payments
                  ? [...state.merchantsOperations.payments, ...action.payload.res.data.payments]
                  : state.merchantsOperations.payments,
              },
      };

    case types.SET_FAKE_OPERATIONS:
      return {
        ...state,
        merchantsOperations: action.payload.fakeOperations,
      };

    case types.SET_CHOSEN_SHOP:
      return {
        ...state,
        chosenShop: action.payload.chosenShop,
      };

    case types.SET_CHOSEN_SHOP_STATUS:
      return {
        ...state,
        statusOfChoseShop: action.payload.statusOfChoseShop,
      };

    default:
      return state;
  }
};

export default acquiringReducer;
