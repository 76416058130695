import React, { useState } from 'react';

const useOpenDropdown = () => {
  const [isDropOpen, setIsDropOpen] = useState(false);

  /** Here we keep the handler for out-click */
  const outsideClickHandler = React.useRef(event => {
    if (dropEl.current && !dropEl.current.contains(event.target)) {
      setIsDropOpen(false);
      window.removeEventListener('click', outsideClickHandler.current);
    }
  });

  const dropEl = React.useRef(null);

  const toggleDrop = () => {
    setIsDropOpen(true);
    window.addEventListener('click', outsideClickHandler.current);
  };

  const closeExplicitly = () => {
    setIsDropOpen(false);
    window.removeEventListener('click', outsideClickHandler.current);
  };

  return [isDropOpen, dropEl, toggleDrop, closeExplicitly];
};

export default useOpenDropdown;
