import React from 'react';
import { createPortal } from 'react-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Overlay from '../Overlay';
import iconEmojiHandshake from 'static/assets/icon-emoji-handshake.png';
import { hidePopupAddPartner, hidePopupAddPartnerSuccess } from '../../actions';
import { hidePartnerRequestById } from 'modules/profileLegasy/actions';
import { getAddPartnerRequest } from '../../selectors';

import styles from './PopupAddPartnerSuccess.module.scss';
import { useHistory } from 'react-router-dom';

const PopupAddPartnerSuccess = ({ dispatch, request }) => {
  const partnerName = request.partner.fullName;
  const history = useHistory();
  const text = `Вы успешно привязали банковскую карту и начали сотрудничество с ${partnerName}. Договоры и историю операций вы найдете в своем ЛК.`;

  function onClose() {
    dispatch(hidePopupAddPartner());
    dispatch(hidePopupAddPartnerSuccess());
    dispatch(hidePartnerRequestById(request.id));
  }

  function onCloseSuccessEdit() {
    dispatch(hidePopupAddPartner());
    dispatch(hidePopupAddPartnerSuccess());
    dispatch(hidePartnerRequestById(request.id));
    history.replace('/lk/profile-settings/partners');
  }

  return createPortal(
    <Overlay
      title="Партнер подключен"
      icon={iconEmojiHandshake}
      text={() => text}
      nav={(props) => <PopupAddPartnerSuccessNav {...props} />}
      onClose={onClose}
      onSuccess={onCloseSuccessEdit}
    />,
    document.body,
  );
};

PopupAddPartnerSuccess.propTypes = {
  dispatch: PropTypes.func.isRequired,
  request: PropTypes.shape({
    id: PropTypes.number.isRequired,
    partner: PropTypes.shape({
      fullName: PropTypes.string.isRequired,
    }),
  }),
};

const PopupAddPartnerSuccessNav = ({ onSuccess }) => (
  <div className={styles.popupAddPartnerSuccessNav}>
    <button onClick={onSuccess}>К списку партнеров</button>
  </div>
);

PopupAddPartnerSuccessNav.propTypes = {
  onSuccess: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  request: getAddPartnerRequest()(state),
});

export default connect(mapStateToProps)(PopupAddPartnerSuccess);
