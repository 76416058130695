import React from 'react';

import PopupPageWrap from 'modules/profileLegasy/components/PopupPageWrap/PopupPageWrap';
import AcquiringSettings from 'modules/acquiring/components/AcquiringSettings/AcquiringSettings';

import './AcquiringSettingsWrap.scss';

const AcquiringSettingsWrap = () => {
  return (
    <PopupPageWrap
      noHeader
      mainContent={<AcquiringSettings />}
      isMainContentFullWidth
      className="acquiring-settings-wrap"
      redirectPage="/lk/acquiring"
    />
  );
};

export default AcquiringSettingsWrap;
