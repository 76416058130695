import React from 'react';
import styled from 'styled-components';
import logoMobileSrc from './assets/logo-mobile.svg';
import logoDesktopSrc from './assets/logo.svg';

export const HeaderCertificateWidget = () => (
  <WrapperSd>
    <a href="https://xn--80aapgyievp4gwb.xn--p1ai/" target="_blank" rel="noreferrer">
      <LogoDesktopSd src={logoDesktopSrc} alt="самозанятые.рф" />
      <LogoMobileSd src={logoMobileSrc} alt="самозанятые.рф" />
    </a>
  </WrapperSd>
);

const WrapperSd = styled.div`
  width: 100%;
  padding: 22px 30px;
  padding-bottom: 0;
  background: var(--pale-grey);
  @media (max-width: 651px) {
    padding: 20px 16px;
  }
`;

const LogoDesktopSd = styled.img`
  display: none;
  @media (min-width: 651px) {
    display: block;
  }
`;
const LogoMobileSd = styled.img`
  transition: all 0.3s;
  display: none;
  @media (max-width: 650px) {
    display: block;
  }
`;
