import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { getUser, saveEmail } from 'modules/authLegasy/actions';
import { changeEmail, getUnconfirmedEmail } from 'modules/profileLegasy/actions';

import ChangeEmail from 'modules/profileLegasy/components/ChangeEmail/ChangeEmail';
import EditButton from 'modules/profileLegasy/components/EditButton/EditButton';
import Hint from 'modules/shared/components/Hint/Hint';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';

import { HINT_TIMEOUTS } from 'constants/index.js';

const ProfileSettingsEmailChanges = ({ userEmail, dispatch, isMenuOpen }) => {
  const [isLoadingEmailChange, setIsLoadingEmailChange] = useState(false);
  const [isNewEmailOk, setIsNewEmailOk] = useState(false);
  const [isNewEmailFailed, setIsNewEmailFailed] = useState(false);
  const [newEmailErrorMessage, setNewEmailErrorMessage] = useState(null);

  /** Хука для попапа - смена e-mail */
  const [
    isDropEmailChangeOpened,
    dropElEmailChange,
    toggleDropEmailChange,
    closeExplicitlyEmailChange,
  ] = useToggleAndOutClick();

  /** Устанавливаем новый e-mail */
  const setNewEmail = (data) => {
    setIsLoadingEmailChange(true);

    dispatch(saveEmail(data))
      .then(() => {
        setIsLoadingEmailChange(false);
        setIsNewEmailOk(true);
        dispatch(getUser()).catch(() => {});
        dispatch(getUnconfirmedEmail()).catch(() => {});
        // setIsEmailUnconfirmed(true);

        setTimeout(() => {
          closeExplicitlyEmailChange();
          setIsNewEmailOk(false);
          setIsNewEmailFailed(false);
        }, 2000);
      })
      .catch((err) => {
        setIsLoadingEmailChange(false);
        setIsNewEmailFailed(true);

        if (err && err.result && err.result.message) {
          setNewEmailErrorMessage(err.result.message);
        }
      });
  };

  /** Меняем e-mail */
  const changePreviousEmail = (data) => {
    setIsLoadingEmailChange(true);

    dispatch(changeEmail(data))
      .then(() => {
        dispatch(getUser()).catch(() => {});
        dispatch(getUnconfirmedEmail())
          .then(() => {})
          .catch(() => {});

        setIsLoadingEmailChange(false);
        setIsNewEmailOk(true);

        setTimeout(() => {
          closeExplicitlyEmailChange();
        }, 2000);
      })
      .catch((err) => {
        setIsLoadingEmailChange(false);
        setIsNewEmailFailed(true);

        if (err && err.result && err.result.message) {
          setNewEmailErrorMessage(err.result.message);
        }
      });
  };

  return (
    <>
      <InputWithLabelAnimation
        disabled
        value={userEmail}
        label="E-mail"
        placeholder="Не указан"
        className="profile-settings-profile-tab__input profile-settings-profile-tab__input_email input-with-label-animation_focused"
      >
        <EditButton action={toggleDropEmailChange} />
      </InputWithLabelAnimation>

      <CSSTransition
        in={isDropEmailChangeOpened}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <Hint
          ref={dropElEmailChange}
          onCloseBtnClick={closeExplicitlyEmailChange}
          className="hint_change-email"
          isMenuOpen={isMenuOpen}
          title={userEmail ? 'Измените e-mail адрес' : 'Установить e-mail адрес'}
        >
          <ChangeEmail
            isLoadingEmailChange={isLoadingEmailChange}
            isNewEmailFailed={isNewEmailFailed}
            onSetNewEmail={setNewEmail}
            onChangeEmail={changePreviousEmail}
            isNewEmailOk={isNewEmailOk}
            email={userEmail}
            isMenuOpen={isMenuOpen}
            newEmailErrorMessage={newEmailErrorMessage}
          />
        </Hint>
      </CSSTransition>
    </>
  );
};

ProfileSettingsEmailChanges.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userEmail: PropTypes.string,
  isMenuOpen: PropTypes.bool.isRequired,
};

ProfileSettingsEmailChanges.defaultProps = {
  userEmail: '',
};

const mapStateToProps = (state) => ({
  userEmail: state.auth.user && state.auth.user.email ? state.auth.user.email : '',
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(ProfileSettingsEmailChanges);
