/* eslint-disable prettier/prettier */

export const minSumDefault = 100;
export const maxSumDefault = 6000000;

export const COLOR = {
  'normal-grey-blue': 'normal-grey-blue', // #9DA6BA;
  'grey-blue': 'grey-blue', // #616A88
  'dark-slate-blue': 'dark-slate-blue', // #1D2E69
  'deep-sky-blue': 'deep-sky-blue', // #007AFF
  'light-grey-blue': 'light-grey-blue', // #b0b5c4,
  'dusk-blue': 'dusk-blue', // #284195;
  'pale-grey': 'pale-grey', // #f6f8fb,
  'light-blue': 'light-blue', // #f2f8ff;
  white: 'white', // #ffffff;
  'bluey-grey': 'bluey-grey', // #b0b5c4;
  'reddish-pink': 'reddish-pink', // #ff2d55;
  'light-mustard': 'light-mustard', // #ffd254;
  'turquoise-blue': 'turquoise-blue', // #00adb5;
  marigold: 'marigold', // #ffcc00;
  'robin-s-egg': 'robin-s-egg', // #5ac8fa;
  purpley: 'purpley', // #6c5ce7;
  dandelion: 'dandelion', // #fad961;
  pumpkin: 'pumpkin', // #f76b1c;
  orange: 'orange', // #ff8246;
};

export const WEIGHT = {
  'circe-normal': 'circe-normal',
  'circe-bold': 'circe-bold',
  'circe-regular': 'circe-regular',
  'circe-extra-bold': 'circe-extra-bold',
  'rubik-regular': 'rubik-regular',
  'rubik-medium': 'rubik-medium',
  'roboto-mono': 'roboto-mono',
  roboto: 'roboto',
};

export const SIZE = {
  hr: 'hr', // hard         |
  lg: 'lg', // large        |
  md: 'md', // medium       |
  sm: 'sm', // small        |
  tn: 'tn', // tiny         |
  htn: 'htn', // hard tiny   \|/
};
