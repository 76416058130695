import React from 'react';
import { SIZE } from '../../../../tools/constants';

import {
  ItemListMarkerOfBlog,
  LinkOfBlog,
  ListOfBlog,
  ParagraphTextOfBlog,
  SupportLinkOfBlog,
  TitleTextOfBlog,
  WidgetWraper,
} from '../ui';

export const GetIdentificationOfSvyaznoyBlogContsiner = () => (
  <WidgetWraper title="Идентификация через Связной">
    <ParagraphTextOfBlog>
      Вы можете пройти идентификацию в{' '}
      <LinkOfBlog url={'https://www.svyaznoy.ru/shops'}>один из офисов</LinkOfBlog> Связного.
    </ParagraphTextOfBlog>

    <ParagraphTextOfBlog>
      <b>Стоимость:</b> 300 рублей. Оплата только наличными.
    </ParagraphTextOfBlog>

    <ParagraphTextOfBlog>
      <b>Длительность:</b> 10-15 минут.
    </ParagraphTextOfBlog>

    <TitleTextOfBlog size={SIZE.sm}>Необходимые документы:</TitleTextOfBlog>
    <ListOfBlog>
      <ItemListMarkerOfBlog>
        <ParagraphTextOfBlog>
          для граждан РФ — паспорт, водительское удостоверение, паспорт моряка или военный билет.
        </ParagraphTextOfBlog>
      </ItemListMarkerOfBlog>
      <ItemListMarkerOfBlog>
        <ParagraphTextOfBlog>
          иностранным гражданам — паспорт с нотариально заверенным переводом, загранпаспорт с визой,
          если вы въехали в РФ по визе, вид на жительство или разрешение на временное проживание в
          РФ, миграционная карта.
        </ParagraphTextOfBlog>
      </ItemListMarkerOfBlog>
    </ListOfBlog>

    <ParagraphTextOfBlog>
      После вам придет уведомление о том, что аккаунт идентифицирован.
    </ParagraphTextOfBlog>
    <ParagraphTextOfBlog>
      Нажмите на «Подтвердите, что все верно». Если все данные введены корректно, завершите процесс
      идентификации.
    </ParagraphTextOfBlog>

    <ParagraphTextOfBlog>
      Если вы нашли ошибку — свяжитесь со <SupportLinkOfBlog text={'службой поддержки'} />.
    </ParagraphTextOfBlog>
  </WidgetWraper>
);
