import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { connect } from 'react-redux';
import Scrollbar from 'react-scrollbars-custom';

import { clearHistoryRequests, getRequests } from 'modules/profileLegasy/actions';
import HistoryRequestsItem from 'modules/profileLegasy/components/HistoryRequestsItem/HistoryRequestsItem';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import MonthNavigation from 'modules/shared/components/MonthNavigation/MonthNavigation';
import NoOperationsMade from 'modules/shared/components/NoOperationsMade/NoOperationsMade';

import { ReactComponent as IconClock } from 'static/assets/icon-clock.svg';

import './HistoryRequests.scss';

export const HistoryRequests = ({
  dispatch,
  className,
  requests,
  startZoneOffset,
  startCurrentTime,
  lastHistoryRequestsDate,
}) => {
  const [now, setNow] = useState(null);

  const [hasMore, setHasMore] = useState(true);
  const [year, setYear] = useState(null);
  const [month, setMonth] = useState(null);
  const [zoneOffset, setZoneOffset] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isFailToLoad, setIsFailToLoad] = useState(false);
  const [currentOpenOperationId, setCurrentOpenOperationId] = useState(null);

  const windowSize = useWindowSize();
  const pageSize = 7;

  /**
   * Берем  startZoneOffset и startCurrentTime с сервера, если они не равны нулю
   * записываем их в zoneOffset и now для построения фильтров, если же они равны 0
   * берем инфу о zoneOffset и now с браузера
   * */
  useEffect(() => {
    if (startCurrentTime && startCurrentTime !== 0) {
      setNow(new Date(startCurrentTime));
    } else {
      setNow(new Date());
    }

    if (startZoneOffset && startZoneOffset !== 0) {
      setZoneOffset(startZoneOffset);
    } else {
      now ? setZoneOffset(now.getTimezoneOffset()) : setZoneOffset(new Date().getTimezoneOffset());
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [startZoneOffset, startCurrentTime]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Берем инфу по тому, когда были совершены последние операции
   * и сравниваем месяц и год с текущими
   * если не совпадают с теущими, значит строим фильтр от тех,
   * что пришли с севера (на запрос о дате последних операций)
   * */
  useEffect(() => {
    if (lastHistoryRequestsDate && now) {
      setMonth(
        +lastHistoryRequestsDate.slice(5, 7) !== now.getMonth() + 1
          ? +lastHistoryRequestsDate.slice(5, 7)
          : now.getMonth() + 1,
      );

      setYear(
        +lastHistoryRequestsDate.slice(0, 4) !== now.getFullYear()
          ? +lastHistoryRequestsDate.slice(0, 4)
          : now.getFullYear(),
      );
    }

    /* eslint-disable react-hooks/exhaustive-deps */
  }, [lastHistoryRequestsDate, now]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Делаем запрос на денежные запросы юзера */
  const getRequestsForPage = useCallback(
    (page) => {
      setIsLoading(true);

      dispatch(getRequests(month, page, pageSize, year, zoneOffset))
        .then((res) => {
          if (res && res.data && res.data.numberOfPages != null) {
            setHasMore(!(res.data.numberOfPages <= page));
          }
        })
        .catch((result) => {
          if (result && result.result && result.result.code === 400) {
            setIsLoading(false);
            setIsFailToLoad(true);
          }
          setHasMore(false);
        })
        .then(() => {
          setIsLoading(false);
        });
    },
    [month, year, dispatch, zoneOffset, pageSize],
  );

  /** Загружаем первую порцию операций для текущего месяца и года */
  useEffect(() => {
    if (month && year) {
      getRequestsForPage(1);
    }
  }, [month, year, zoneOffset, getRequestsForPage]);

  /** Клик на предыдущий месяц */
  const handlePrevMonthClick = () => {
    /**
     * Это нужно для того, чтобы когда мы переключаемся на другой месяц,
     * а запрос на историю нового месяца сфелися, чтобы нам не показывались
     * операции из предыдущего месяца
     * */
    dispatch(clearHistoryRequests());

    if (month <= 1) {
      setMonth(12);
      setYear(Math.max(2019, year - 1));
    } else {
      setMonth(month - 1);
    }
  };

  /** Клик на следующий месяц */
  const handleNextMonthClick = () => {
    /**
     * Это нужно для того, чтобы когда мы переключаемся на другой месяц,
     * а запрос на историю нового месяца сфелися, чтобы нам не показывались
     * операции из предыдущего месяца
     * */
    dispatch(clearHistoryRequests());

    if (month >= 12) {
      setMonth(1);
      setYear(year + 1);
    } else {
      setMonth(month + 1);
    }
  };

  /** Клик по элементу списка запросов */
  const handleClickOperationItem = (uid) => {
    setCurrentOpenOperationId((prevState) => (prevState === uid ? null : uid));
  };

  /** Текущая дата, выбранная в навигации */
  const currentDate = new Date(Date.parse(`${year}-${month}-01`));
  /** Парсим данные по последним операциям с сервера в формат даты */
  const lastHistoryRequestsDateInDateFormat = new Date(Date.parse(lastHistoryRequestsDate));
  /**
   * Добавляем один месяц к дате в навигации
   * чтобы сравнивать эту дату с датой последних операций
   * */
  const limitForNextMonthClick = new Date(currentDate.setMonth(currentDate.getMonth() + 1));

  return (
    <div
      className={cx('history-requests', {
        [className]: className,
      })}
    >
      {/* ХЭДЕР ТАБЛИЦЫ */}
      {windowSize.innerWidth > 980 ? (
        <div className="history-requests__header">
          {/* ЗАГОЛОВОК ТАБЛИЦЫ */}
          <div className="history-requests__header-title-wrap">
            <IconClock className="history-requests__header-icon" />
            <p
              className="history-requests__header-title"
              data-testid="history-requests-table-title"
            >
              История запросов
            </p>
          </div>
          {/* ЗАГОЛОВОК ТАБЛИЦЫ */}

          {/* НАВИГАЦИЯ ПО МЕСЯЦАМ */}
          <MonthNavigation
            className="history-requests__header-date"
            month={month}
            year={year}
            isLoading={isLoading}
            isPrevButtonDisabled={year === 2019 && month === 1}
            isNextButtonDisabled={limitForNextMonthClick >= lastHistoryRequestsDateInDateFormat}
            handlePrevMonthClick={handlePrevMonthClick}
            handleNextMonthClick={handleNextMonthClick}
          />
          {/* /НАВИГАЦИЯ ПО МЕСЯЦАМ */}

          {/* ЗАГОЛОВОК ДЛЯ СУММЫ */}
          <p className="history-requests__header-sum">Сумма</p>
          {/* /ЗАГОЛОВОК ДЛЯ СУММЫ */}
        </div>
      ) : (
        <MonthNavigation
          className="history-requests__month-navigation"
          month={month}
          year={year}
          isLoading={isLoading}
          isPrevButtonDisabled={year === 2019 && month === 1}
          isNextButtonDisabled={limitForNextMonthClick >= lastHistoryRequestsDateInDateFormat}
          handlePrevMonthClick={handlePrevMonthClick}
          handleNextMonthClick={handleNextMonthClick}
        />
      )}
      {/* /ХЭДЕР ТАБЛИЦЫ */}

      {/* КОНТЕНТ ТАБЛИЦЫ */}
      <div className="history-requests__content">
        <Scrollbar
          className="history-requests__content-scrollbar"
          style={{ height: 300 }}
          trackYProps={{
            /* eslint-disable react/prop-types, react/jsx-props-no-spreading */
            renderer: ({ elementRef, style, ...restProps }) => {
              return <span {...restProps} ref={elementRef} className="history-requests__track" />;
            },
          }}
          thumbYProps={{
            renderer: ({ elementRef, style, ...restProps }) => {
              return <span {...restProps} ref={elementRef} className="history-requests__thumb" />;
            },
            /* eslint-enable react/prop-types, react/jsx-props-no-spreading */
          }}
        >
          <InfiniteScroll
            pageStart={1} // It is second actual
            initialLoad={false}
            loadMore={getRequestsForPage}
            hasMore={hasMore}
            useWindow={false}
            key={`${month}_${year}`}
            getScrollParent={() => document.querySelector('.ScrollbarsCustom-Scroller')}
          >
            <div className="history-requests__list">
              {requests &&
                requests.payIts &&
                requests.payIts.map((item) => (
                  <HistoryRequestsItem
                    historyRequestsItemData={item}
                    currentOpenOperationId={currentOpenOperationId}
                    itemClick={(id) => handleClickOperationItem(id)}
                    key={item.uid}
                  />
                ))}
            </div>

            {isLoading && (
              <div className="history-requests__loading">
                <CircleLoader className="history-requests__loading-loader" />
              </div>
            )}
          </InfiniteScroll>

          {/* СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}
          {requests && requests.payIts === null && !isLoading && (
            <NoOperationsMade
              text="Здесь пока пусто. Как только вы начнете совершать запросы, они будут отражаться в этом разделе"
              className="history-requests__no-operations-message"
            />
          )}
          {/* /СООБЩЕНИЕ КОГДА НЕТ ОПЕРАЦИЙ */}

          {/* СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
          {isFailToLoad && !isLoading && (
            <NoOperationsMade className="deals__no-operations-message">
              <div className="no-operations-made__text">
                Произошла какая-то ошибка. Не удалось загрузить данные. Попробуйте позже, а если
                ошибка не пройдет
                <button className="no-operations-made__text-link js-help" type="button">
                  напишите нам
                </button>
                .
              </div>
            </NoOperationsMade>
          )}
          {/* /СООБЩЕНИЕ КОГДА НЕ УДАЛОСЬ ЗАГРУЗИТЬ ОПЕРАЦИИ */}
        </Scrollbar>
      </div>
      {/* /КОНТЕНТ ТАБЛИЦЫ */}
    </div>
  );
};

HistoryRequests.propTypes = {
  className: PropTypes.string,
  dispatch: PropTypes.func.isRequired,
  requests: PropTypes.shape({
    numberOfPages: PropTypes.number,
    pageSize: PropTypes.number,
    payIts: PropTypes.arrayOf(
      PropTypes.shape({
        type: PropTypes.string,
        commentary: PropTypes.string,
        paid: PropTypes.bool,
        dateTimeAdd: PropTypes.string,
        dateTimePayment: PropTypes.string,
        sum: PropTypes.number,
        writeOff: PropTypes.bool,
        uid: PropTypes.string,
        email: PropTypes.string,
      }),
    ),
    total: PropTypes.number,
  }).isRequired,
  startZoneOffset: PropTypes.number.isRequired,
  startCurrentTime: PropTypes.number.isRequired,
  lastHistoryRequestsDate: PropTypes.string,
};

HistoryRequests.defaultProps = {
  className: null,
  lastHistoryRequestsDate: null,
};

const mapStateToProps = (state) => ({
  requests: state.profile.requests,
  startZoneOffset: state.auth.startZoneOffset || 0,
  startCurrentTime: state.auth.startCurrentTime,
  lastHistoryRequestsDate: state.profile.lastHistoryRequestsDate,
});

export default connect(mapStateToProps)(HistoryRequests);
