import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import { disableRussianLetters, disableSpaceEnter } from '../../../helpers';
import { ReactComponent as IconPasswordClosed } from '../../../static/assets/icon-password-closed.svg';
import { ReactComponent as IconPasswordOpen } from '../../../static/assets/icon-password-open.svg';
import { PTClassNames } from '../../../tools/types/propTypes';
import { InputMixin } from './Input';

export const PasswordInput = ({
  value,
  onChange,
  onKeyEnter,
  placeholder,
  isFail,
  required,
  notPasswordVisible,
  className,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  return (
    <Wrapper className={className}>
      <InputStyled
        value={value}
        onChange={(event) => onChange(disableSpaceEnter(disableRussianLetters(event.target.value)))}
        onKeyUp={(event) => event.key === 'Enter' && onKeyEnter()}
        onFocus={(event) => (event.target.placeholder = '')}
        onBlur={(event) => (event.target.placeholder = placeholder || 'Пароль')}
        required={required}
        isFail={isFail}
        isPasswordVisible={isPasswordVisible}
        isPasswordValue={!!value}
        placeholder={placeholder || 'Пароль'}
        type={!notPasswordVisible && isPasswordVisible ? 'text' : 'password'}
        name="password"
        autoComplete="password"
      />
      <PasswordVisibilityButton
        className={cx('password-visibility-button')}
        type="button"
        onClick={() => setIsPasswordVisible((state) => !state)}
      >
        {isPasswordVisible ? <IconPasswordOpenStyled /> : <IconPasswordClosedStyled />}
      </PasswordVisibilityButton>
    </Wrapper>
  );
};

PasswordInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  isFail: PropTypes.bool,
  placeholder: PropTypes.string,
  onKeyEnter: PropTypes.func,
  required: PropTypes.bool,
  className: PTClassNames,
};

PasswordInput.defaultProps = {
  isFail: false,
  placeholder: null,
  onKeyEnter: (f) => f,
  required: false,
  className: '',
};

const Wrapper = styled.div`
  position: relative;
`;

const InputStyled = styled.input`
  ${InputMixin};
  &,
  &:-webkit-autofill {
    ${({ isPasswordVisible }) =>
      isPasswordVisible
        ? css`
            letter-spacing: initial;
            font-size: 18px;
          `
        : css`
            font-size: 24px;
            ${({ value }) =>
              value &&
              css`
                letter-spacing: 12px;
              `}
          `}
    padding-right: 42px;
  }
`;

const PasswordVisibilityButton = styled.button`
  position: absolute;
  right: 16.5px;
  top: 18.5px;
  display: flex;
  width: 24px;
  height: 24px;
`;

const IconPasswordOpenStyled = styled(IconPasswordOpen)`
  pointer-events: none;
  margin-top: 1px;
`;

const IconPasswordClosedStyled = styled(IconPasswordClosed)`
  position: absolute;
  pointer-events: none;
  margin-top: 1px;
`;
