// Actions
// Components
import PropTypes from 'prop-types';
import React from 'react';
import { Route } from 'react-router-dom';

import { PTChildrenElement } from 'tools/types/propTypes';
import { AccessRouteContainer } from './container';

export const AccessRoute = ({
  component: Component,
  children: Children,
  isPrivate,
  locationDetector,
  needToAcceptNewPolicy,
  ...rest
}) => {
  return (
    // eslint-disable-next-line react/jsx-props-no-spreading
    <Route {...rest}>
      <AccessRouteContainer
        isPrivate={isPrivate}
        locationDetector={locationDetector}
        needToAcceptNewPolicy={needToAcceptNewPolicy}
      >
        {Children || <Component />}
      </AccessRouteContainer>
    </Route>
  );
};

AccessRoute.propTypes = {
  // eslint-disable-next-line react/require-default-props
  component: PropTypes.object,
  // eslint-disable-next-line react/require-default-props
  children: PTChildrenElement,
  isPrivate: PropTypes.bool,
  locationDetector: PropTypes.shape({
    pattern: PropTypes.string,
    propName: PropTypes.string,
  }),
  needToAcceptNewPolicy: PropTypes.bool,
};

AccessRoute.defaultProps = {
  locationDetector: null,
  needToAcceptNewPolicy: false,
  isPrivate: false,
};
