import { createSelector } from '@reduxjs/toolkit';
import { useSelector } from 'react-redux';

export const useRequestFulfilledSelector = () =>
  useSelector(
    createSelector(
      (store) => store.reSendSms.reSendSmsRequest?.fulfilled,
      (fulfilled) => fulfilled,
    ),
  );

export const useRequestErrorSelector = () =>
  useSelector(
    createSelector(
      (store) => store.reSendSms?.reSendSmsRequest.error,
      (error) => error,
    ),
  );
