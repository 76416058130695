import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

import './Switcher.scss';

const Switcher = ({ isChecked, setIsChecked, ariaLabel, title, className, subTitle }) => {
  return (
    <label
      className={cx('switcher', {
        [className]: className,
        withSubTitle: subTitle,
      })}
      data-testid="switcher"
    >
      <button
        className="switcher__checkbox"
        role="checkbox"
        type="button"
        aria-label={ariaLabel}
        aria-checked={isChecked}
        onClick={setIsChecked}
      />
      <div>
        <p className="switcher__title">{title}</p>
        {subTitle && <p className="switcher__subtitle">{subTitle}</p>}
      </div>
    </label>
  );
};

Switcher.propTypes = {
  isChecked: PropTypes.bool.isRequired,
  setIsChecked: PropTypes.func.isRequired,
  ariaLabel: PropTypes.string.isRequired,
  title: PropTypes.string,
  className: PropTypes.string,
  subTitle: PropTypes.string,
};

Switcher.defaultProps = {
  title: null,
  className: null,
  subTitle: null,
};

export default Switcher;
