import React from 'react';
import { animated, useSpring } from 'react-spring';

import cardImage from 'static/assets/partners-card.png';
import ruble1Image from 'static/assets/partners-ruble-1.png';
import ruble2Image from 'static/assets/partners-ruble-2.png';
import ruble3Image from 'static/assets/partners-ruble-3.png';
import sphere1Image from 'static/assets/partners-sphere-1.png';
import sphere2Image from 'static/assets/partners-sphere-2.png';
import sphere3Image from 'static/assets/partners-sphere-3.png';
import sphere4Image from 'static/assets/partners-sphere-4.png';
import sphere5Image from 'static/assets/partners-sphere-5.png';

import styles from './FloatingDetails.module.scss';

const FloatingDetails = () => {
  const calc = (x, y) => [x - window.innerWidth / 2, y - window.innerHeight / 2];
  const trans1 = (x, y) => `translate3d(${x / 23}px, ${y / 23}px, 0)`;
  const trans2 = (x, y) => `translate3d(${x / 23}px, ${y / 23}px, 0)`;
  const trans3 = (x, y) => `translate3d(${x / 23}px, ${y / 23}px, 0)`;
  const trans4 = (x, y) => `translate3d(${x / 22}px, ${y / 22}px, 0)`;
  const trans5 = (x, y) => `translate3d(${x / 20}px, ${y / 20}px, 0)`;
  const trans6 = (x, y) => `translate3d(${x / 19}px, ${y / 19}px, 0)`;
  const trans7 = (x, y) => `translate3d(${x / 15}px, ${y / 15}px, 0)`;
  const trans8 = (x, y) => `translate3d(${x / 17}px, ${y / 17}px, 0)`;
  const trans9 = (x, y) => `translate3d(${x / 17}px, ${y / 17}px, 0)`;
  const percent = (x) => `${x}%`;

  const [shape1, setShape1] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(52.27),
      left: percent(93.97),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 100,
      friction: 140,
    },
  }));

  const [shape2, setShape2] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(24.09),
      left: percent(0),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 180,
      friction: 140,
    },
  }));

  const [shape3, setShape3] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(94.42),
      left: percent(31.83),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 180,
      friction: 140,
    },
  }));

  const [shape4, setShape4] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(33.24),
      left: percent(44.83),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 200,
      friction: 140,
    },
  }));

  const [shape5, setShape5] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(4.7),
      left: percent(59.2),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 220,
      friction: 140,
    },
  }));

  const [shape6, setShape6] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(61.77),
      left: percent(70.69),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 240,
      friction: 140,
    },
  }));

  const [shape7, setShape7] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(7.5),
      left: percent(48.11),
      scaleNumber: 1,
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 300,
      friction: 140,
    },
  }));

  const [shape8, setShape8] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(29.09),
      left: percent(45.85),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 280,
      friction: 140,
    },
  }));

  const [shape9, setShape9] = useSpring(() => ({
    from: {
      xy: [1000, -300],
      opacity: 0,
      top: percent(49.59),
      left: percent(-0.39),
    },
    to: {
      xy: [0, 0],
      opacity: 1,
    },
    config: {
      mass: 10,
      tension: 280,
      friction: 140,
    },
  }));

  React.useEffect(() => {
    function onMouseMove(event) {
      const x = event.clientX;
      const y = event.clientY;

      setShape1({ xy: calc(x, y) });
      setShape2({ xy: calc(x, y) });
      setShape3({ xy: calc(x, y) });
      setShape4({ xy: calc(x, y) });
      setShape5({ xy: calc(x, y) });
      setShape6({ xy: calc(x, y) });
      setShape7({ xy: calc(x, y) });
      setShape8({ xy: calc(x, y) });
      setShape9({ xy: calc(x, y) });
    }

    document.addEventListener('mousemove', onMouseMove);
    return () => document.removeEventListener('mousemove', onMouseMove);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // все фигуры отсортированы по удаленности
  // сначала те, что в далеке (маленькие по размерам)
  // а в конце те, что на переднем плане (большие по размерам)
  return (
    <div className={styles.floatingDetails}>
      <animated.img
        className={styles.floatingDetailsShape1}
        src={sphere1Image}
        alt="sphere"
        style={{
          transform: shape1.xy.interpolate(trans1),
          left: shape1.left.interpolate(percent),
          top: shape1.top.interpolate(percent),
          opacity: shape1.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape2}
        src={sphere5Image}
        alt="sphere"
        style={{
          transform: shape2.xy.interpolate(trans2),
          left: shape2.left.interpolate(percent),
          top: shape2.top.interpolate(percent),
          opacity: shape2.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape3}
        src={sphere4Image}
        alt="sphere"
        style={{
          transform: shape3.xy.interpolate(trans3),
          left: shape3.left.interpolate(percent),
          top: shape3.top.interpolate(percent),
          opacity: shape3.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape4}
        src={ruble2Image}
        alt="ruble"
        style={{
          transform: shape4.xy.interpolate(trans4),
          left: shape4.left.interpolate(percent),
          top: shape4.top.interpolate(percent),
          opacity: shape4.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape5}
        src={sphere2Image}
        alt="sphere"
        style={{
          transform: shape5.xy.interpolate(trans5),
          left: shape5.left.interpolate(percent),
          top: shape5.top.interpolate(percent),
          opacity: shape5.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape6}
        src={ruble1Image}
        alt="ruble"
        style={{
          transform: shape6.xy.interpolate(trans6),
          left: shape6.left.interpolate(percent),
          top: shape6.top.interpolate(percent),
          opacity: shape6.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape7}
        src={cardImage}
        alt="card"
        style={{
          transform: shape7.xy.interpolate(trans7),
          left: shape7.left.interpolate(percent),
          top: shape7.top.interpolate(percent),
          opacity: shape7.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape8}
        src={sphere3Image}
        alt="sphere"
        style={{
          transform: shape8.xy.interpolate(trans8),
          left: shape8.left.interpolate(percent),
          top: shape8.top.interpolate(percent),
          opacity: shape8.opacity,
        }}
      />
      <animated.img
        className={styles.floatingDetailsShape9}
        src={ruble3Image}
        alt="ruble"
        style={{
          transform: shape9.xy.interpolate(trans9),
          left: shape9.left.interpolate(percent),
          top: shape9.top.interpolate(percent),
          opacity: shape9.opacity,
        }}
      />
    </div>
  );
};

export default FloatingDetails;
