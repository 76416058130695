export const GET_PARTNER_DOCUMENT_LIST_FETCHING = 'GET_PARTNER_DOCUMENT_LIST_FETCHING';
export const GET_PARTNER_DOCUMENT_LIST_SUCCESS = 'GET_PARTNER_DOCUMENT_LIST_SUCCESS';
export const GET_PARTNER_DOCUMENT_LIST_FAIL = 'GET_PARTNER_DOCUMENT_LIST_FAIL';
export const CLEAR_PARTNER_DOCUMENT_LIST = 'CLEAR_PARTNER_DOCUMENT_LIST';

export const GET_PARTNER_PAYMENT_LIST_FETCHING = 'GET_PARTNER_PAYMENT_LIST_FETCHING';
export const GET_PARTNER_PAYMENT_LIST_SUCCESS = 'GET_PARTNER_PAYMENT_LIST_SUCCESS';
export const GET_PARTNER_PAYMENT_LIST_FAIL = 'GET_PARTNER_PAYMENT_LIST_FAIL';
export const CLEAR_PARTNER_PAYMENT_LIST = 'CLEAR_PARTNER_PAYMENT_LIST';

export const CONFIRM_PARTNER_REQUEST_FETCHING = 'CONFIRM_PARTNER_REQUEST_FETCHING';
export const CONFIRM_PARTNER_REQUEST_SUCCESS = 'CONFIRM_PARTNER_REQUEST_SUCCESS';
export const CONFIRM_PARTNER_REQUEST_FAIL = 'CONFIRM_PARTNER_REQUEST_FAIL';

export const DECLINE_PARTNER_REQUEST_FETCHING = 'DECLINE_PARTNER_REQUEST_FETCHING';
export const DECLINE_PARTNER_REQUEST_SUCCESS = 'DECLINE_PARTNER_REQUEST_SUCCESS';
export const DECLINE_PARTNER_REQUEST_FAIL = 'DECLINE_PARTNER_REQUEST_FAIL';

export const EDIT_PARTNER_BANK_CARD_FETCHING = 'EDIT_PARTNER_BANK_CARD_FETCHING';
export const EDIT_PARTNER_BANK_CARD_SUCCESS = 'EDIT_PARTNER_BANK_CARD_SUCCESS';
export const EDIT_PARTNER_BANK_CARD_FAIL = 'EDIT_PARTNER_BANK_CARD_FAIL';

export const SHOW_CHECK = 'SHOW_CHECK';
export const HIDE_CHECK = 'HIDE_CHECK';

export const SHOW_POPUP_CHECK = 'SHOW_POPUP_CHECK';
export const HIDE_POPUP_CHECK = 'HIDE_POPUP_CHECK';

export const SHOW_POPUP_CALENDAR = 'SHOW_POPUP_CALENDAR';
export const HIDE_POPUP_CALENDAR = 'HIDE_POPUP_CALENDAR';

export const SHOW_INLINE_CALENDAR = 'SHOW_INLINE_CALENDAR';
export const HIDE_INLINE_CALENDAR = 'HIDE_INLINE_CALENDAR';

export const SHOW_POPUP_ADD_PARTNER = 'SHOW_POPUP_ADD_PARTNER';
export const HIDE_POPUP_ADD_PARTNER = 'HIDE_POPUP_ADD_PARTNER';

export const SHOW_POPUP_EDIT_BANK_CARD = 'SHOW_POPUP_EDIT_BANK_CARD';
export const HIDE_POPUP_EDIT_BANK_CARD = 'HIDE_POPUP_EDIT_BANK_CARD';

export const SHOW_POPUP_EDIT_BANK_CARD_SUCCESS = 'SHOW_POPUP_EDIT_BANK_CARD_SUCCESS';
export const HIDE_POPUP_EDIT_BANK_CARD_SUCCESS = 'HIDE_POPUP_EDIT_BANK_CARD_SUCCESS';

export const SHOW_POPUP_EDIT_BANK_CARD_FAIL = 'SHOW_POPUP_EDIT_BANK_CARD_FAIL';
export const HIDE_POPUP_EDIT_BANK_CARD_FAIL = 'HIDE_POPUP_EDIT_BANK_CARD_FAIL';

export const SHOW_POPUP_ADD_PARTNER_SUCCESS = 'SHOW_POPUP_ADD_PARTNER_SUCCESS';
export const HIDE_POPUP_ADD_PARTNER_SUCCESS = 'HIDE_POPUP_ADD_PARTNER_SUCCESS';

export const SHOW_POPUP_ADD_PARTNER_FAIL = 'SHOW_POPUP_ADD_PARTNER_FAIL';
export const HIDE_POPUP_ADD_PARTNER_FAIL = 'HIDE_POPUP_ADD_PARTNER_FAIL';

export const SHOW_POPUP_DECLINE_PARTNER = 'SHOW_POPUP_DECLINE_PARTNER';
export const HIDE_POPUP_DECLINE_PARTNER = 'HIDE_POPUP_DECLINE_PARTNER';

export const SET_PARTNER_PAYMENTS_RANGE = 'SET_PARTNER_PAYMENTS_RANGE';
export const SET_PARTNER_PAYMENTS_CURRENT_RANGE_TAB = 'SET_PARTNER_PAYMENTS_CURRENT_RANGE_TAB';
export const RESTORE_PARTNER_PAYMENTS_CURRENT_RANGE_TAB =
  'RESTORE_PARTNER_PAYMENTS_CURRENT_RANGE_TAB';

export const GET_PARTNER_PAYMENT_STATS_FETCHING = 'GET_PARTNER_PAYMENT_STATS_FETCHING';
export const GET_PARTNER_PAYMENT_STATS_SUCCESS = 'GET_PARTNER_PAYMENT_STATS_SUCCESS';
export const GET_PARTNER_PAYMENT_STATS_FAIL = 'GET_PARTNER_PAYMENT_STATS_FAIL';
