import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Prompt, Redirect, useLocation, useParams } from 'react-router-dom';

import { setMenuOpen } from 'modules/authLegasy/actions';
import Footer from 'modules/authLegasy/components/Footer/Footer';
import Header from 'modules/authLegasy/components/Header/Header';
import Loader from 'modules/authLegasy/components/Loader/Loader';
import {
  getInvoiceById,
  getInvoiceTransactionCheck,
  getInvoiceTransactionOperationStatus,
} from 'modules/profileLegasy/actions';
import CardsFloatingDetails from 'modules/profileLegasy/components/CardsFloatingDetails/CardsFloatingDetails';
import InvoicePaymentRequestLink from 'modules/profileLegasy/components/InvoicePaymentRequestLink/InvoicePaymentRequestLink';

import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';
import { ReactComponent as IconLogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as Logo } from 'static/assets/logo.svg';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './InvoicePaymentRequestContainer.scss';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';
const STATUS_SUCCESS = 'success';

/**
 * Этот компонент выводит форму оплаты по ссылке вида /invoice/:checkId
 * До оплаты по ссылке вида /invoice/:checkId
 * При оплате происходит редирект на /pay-it/invoice/:operationId/wait
 * Как получен результат происзодит дальше редирект на
 * /pay-it/invoice/:operationId/success или
 * /pay-it/invoice/error
 */
const InvoicePaymentRequestContainer = ({ dispatch, user, isMenuOpen }) => {
  const windowSize = useWindowSize();
  const location = useLocation();
  const { registeredCheckId, registeredCheckHash, checkId, operationId } = useParams();

  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isReplenishmentBlocked, setIsReplenishmentBlocked] = useState(false);
  const [isNotAvailable, setIsNotAvailable] = useState(false);

  const [isPayed, setIsPayed] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [opData, setOpData] = useState(null);
  const [error, setError] = useState('');

  const [isSending, setIsSending] = useState(false);

  const [redirectToErrorPage, setRedirectToErrorPage] = useState(false);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(null);

  const isMoneyTransferOk = Boolean(registeredCheckId && registeredCheckHash);
  const isMoneyTransferFailed = location.pathname.indexOf('error') > -1;
  const isRegisteredCheckFailed = location.pathname.indexOf('not-register-deal') > -1;

  const [isCheckLoading, setIsCheckLoading] = useState(false);
  const [checkData, setCheckData] = useState(null);
  const [isCheckSuccessPage, setIsCheckSuccessPage] = useState(false);

  /**
   * Узнаем детали операции по уникальному
   * id из url при нахождени на /invoice/guiIgdsm28/
   */
  useEffect(() => {
    if (!checkId) {
      return;
    }

    setIsLoading(true);

    dispatch(getInvoiceById(checkId))
      .then((res) => {
        try {
          setOpData(res.data);
          if (res.data && res.data.payed) {
            setIsPayed(true);
          } else if (res.data && res.data.clientBlockedByAdmin) {
            setIsUserBlocked(true);
          } else if (res.data && res.data.clientBlockedReceipt) {
            setIsReplenishmentBlocked(true);
          } else if (res.data && !res.data.available) {
            setIsNotAvailable(true);
          }

          /**
           * Записываем аватар в localStorage, чтобы после
           * возвращения с 3ds он отображался правильно
           *  */
          window.localStorage.setItem(
            'invoicePaymentRequestAvatar',
            res.data && res.data.avatarInBase64 ? `${res.data.avatarInBase64}` : stdAvatar,
          );
        } catch (_error) {
          setError('К сожалению, запрос с таким идентификатором не найден');
        }
      })
      .catch((res) => {
        setError(
          res && res.result && res.result.message
            ? res.result.message
            : 'К сожалению, запрос с таким идентификатором не найден',
        );
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [dispatch, checkId]);

  const updateTimeout = useRef(null);

  const updateOperationStatus = useCallback(() => {
    if (!(operationId && location.pathname.indexOf('wait') > -1)) {
      clearTimeout(updateTimeout.current);
      return;
    }

    setIsSending(true);
    dispatch(getInvoiceTransactionOperationStatus(operationId))
      .then((res) => {
        if (res && res.data) {
          if (res.data.state === STATUS_WAIT) {
            updateTimeout.current = setTimeout(updateOperationStatus, 1000);
          } else if (res.data.state === STATUS_ERROR) {
            setRedirectToErrorPage(true);
          } else if (res.data.state === STATUS_SUCCESS && res.data.url) {
            const index = res.data.url.indexOf('/pay-it');
            const url = res.data.url.substr(index);
            setRedirectToSuccessPage(url);
          }

          if (res.data.transactionStateMessage) {
            window.sessionStorage.setItem(
              'transactionStateMessage',
              res.data.transactionStateMessage,
            );
          }
        }
      })
      .catch(() => {
        setRedirectToErrorPage(true);
      });
  }, [dispatch, location.pathname, operationId]);

  /** Узнаем результаты проведения операции при нахождении на /wait/ */
  useEffect(() => {
    updateOperationStatus();
    return () => {
      clearTimeout(updateTimeout.current);
    };
  }, [updateOperationStatus, location.pathname, operationId]);

  useEffect(() => {}, []);

  /** Запрашиваем чек при нахождении на соотв. урле */
  useEffect(() => {
    if (registeredCheckId && registeredCheckHash) {
      setIsCheckLoading(true);

      getInvoiceTransactionCheck(registeredCheckId, registeredCheckHash)
        .then((res) => {
          if (res && res.data && res.data.id) {
            setCheckData(res.data);
            setIsCheckSuccessPage(location.pathname.indexOf(`/${res.data.id}/`) > -1);
          }
        })
        .catch(() => {})
        .then(() => {
          setIsCheckLoading(false);
        });
    }
  }, [location.pathname, registeredCheckId, registeredCheckHash]);

  useEffect(() => {}, []);

  /** Добавляем overflow: hidden для body при открытии портала */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    if (isSending) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  /** Устанваливаем isMenuOpen при клике на бургер */
  const handleClick = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  if (isLoading) {
    return <Loader className="check-payment-request-container__loader" inverted />;
  }

  if (error) {
    return (
      <div className="check-payment-request-container__error">
        <p className="check-payment-request-container__error-text">{error}</p>
      </div>
    );
  }

  if (redirectToErrorPage) {
    return <Redirect to="/pay-it/invoice/error" />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  return createPortal(
    <div
      className={cx('check-payment-request-container', {
        'check-payment-request-container_menu-open': isMenuOpen,
      })}
    >
      {/* ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}
      <Prompt
        when={isSending}
        message="Операция выполняется, вы уверены что хотите уйти со страницы не дожидаясь результата?"
      />
      {/* /ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}

      {/* ХЭДЕР ДЛЯ ЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {user && windowSize.innerWidth > 980 && <Header isUserLoggedIn />}
      {/* /ХЭДЕР ДЛЯ ЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}

      {/* ХЭДЕР ДЛЯ НЕЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {!user && windowSize.innerWidth > 980 && (
        <div
          className={cx('check-payment-request-container__header', {
            'check-payment-request-container__header_menu-open': isMenuOpen,
          })}
        >
          {windowSize.innerWidth > 1194 ? (
            <Link to="/" className="check-payment-request-container__header-start-link">
              <Logo className="check-payment-request-container__header-logo" />
            </Link>
          ) : (
            <Link to="/" className="check-payment-request-container__header-start-link">
              <IconLogoResponsive className="check-payment-request-container__header-logo" />
            </Link>
          )}

          <button
            className={cx('check-payment-request-container__burger', {
              'check-payment-request-container__burger_menu-open': isMenuOpen,
            })}
            type="button"
            onClick={handleClick}
          >
            <IconLine1 className="check-payment-request-container__burger-span" />
            <IconLine2 className="check-payment-request-container__burger-span" />
            <IconLine3 className="check-payment-request-container__burger-span" />
          </button>
        </div>
      )}
      {/* /ХЭДЕР ДЛЯ НЕЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}

      {/* КАРТОЧКА ЗАПРОСА */}
      {!isLoading &&
        !error &&
        (opData ||
          isSending ||
          isMoneyTransferOk ||
          isMoneyTransferFailed ||
          isRegisteredCheckFailed) && (
          <div className="check-payment-request-container__link-wrap">
            <InvoicePaymentRequestLink
              className="check-payment-request-container__link"
              opData={opData}
              checkId={checkId}
              checkData={checkData}
              isMoneyTransferOk={isMoneyTransferOk}
              isMoneyTransferFailed={isMoneyTransferFailed}
              isRegisteredCheckFailed={isRegisteredCheckFailed}
              isCheckSuccessPage={isCheckSuccessPage}
              isSending={isSending}
              isCheckLoading={isCheckLoading}
              userAvatar={
                window &&
                window.localStorage &&
                window.localStorage.getItem('invoicePaymentRequestAvatar')
              }
              isUserBlocked={isUserBlocked}
              isReplenishmentBlocked={isReplenishmentBlocked}
              isNotAvailable={isNotAvailable}
              isPayed={isPayed}
            />
          </div>
        )}
      {/* /КАРТОЧКА ЗАПРОСА */}

      {/* ПЛАВАЮЩИЕ ДЕТАЛИ СПРАВА И СЛЕВА ОТ КАРТЧОКИ ЗАПРОСА */}
      {windowSize.innerWidth > 980 && <CardsFloatingDetails />}
      {/* /ПЛАВАЮЩИЕ ДЕТАЛИ СПРАВА И СЛЕВА ОТ КАРТЧОКИ ЗАПРОСА */}

      {/* ФУТЕР ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {windowSize.innerWidth > 980 && <Footer />}
      {/* /ФУТЕР ПРИ ШИРИНЕ ЭКРАНА > 980 */}
    </div>,
    document.body,
  );
};

InvoicePaymentRequestContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape(),
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(InvoicePaymentRequestContainer);
