import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import WarningText from 'modules/profileLegasy/components/Warning/WarningText';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';

import { CONSISTENT_ANIMATIONS_DELAY, HINT_TIMEOUTS } from 'constants/index';

import { ReactComponent as LogoGosuslugi } from 'static/assets/gosuslugi-logo-desktop.svg';
import { ReactComponent as LogoGosuslugiMobile } from 'static/assets/gosuslugi-logo-mobile.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import iconSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as IconInfo } from 'static/assets/icon-information.svg';
import penImg from 'static/assets/pen-img.png';
import profileBg from 'static/assets/profile-bg.png';

const AuthStatusAlreadyRegistered = ({ className, onEnterPassportData, resetAuthStep }) => {
  const windowSize = useWindowSize();

  const [isGosUslugiPopupOpen, setIsGosUslugiPopupOpen] = useState(false);
  const [isRendered, setIsRendered] = useState(false);

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 100);
  }, []);

  return (
    <div
      className={cx('auth-status', {
        [className]: className,
      })}
      data-testid="auth-status-already-registered"
    >
      <CSSTransition
        classNames="animation-from-top-to-bottom"
        in={isRendered}
        timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
        unmountOnExit
      >
        <div className="auth-status__header">
          <div className="auth-status__header-container container container_small">
            <div className="auth-status__header-content">
              <h1 className="auth-status__header-title">Такой пользователь уже зарегистрирован</h1>

              {windowSize.innerWidth > 980 ? (
                <div className="auth-status__header-desc">
                  Войдите под своей учетной записью или напишите письмо в поддержку клиентов
                  <IconInfo className="auth-status__header-desc-warning" />
                </div>
              ) : (
                <div className="auth-status__header-desc">
                  Возможно вы вошли в систему не под своим логином
                </div>
              )}
            </div>
          </div>
          <img src={profileBg} alt="Profile bg" className="auth-status__header-bg" />

          {/* КНОПКА ЗАКРЫТИЯ - ПЕРЕХОД НА ПРОФИЛЬ */}
          <Link
            to="/lk/profile-settings/account-type"
            className="auth-status__header-close"
            onClick={resetAuthStep}
            data-testid="return-to-profile-settings"
          >
            <IconClose className="auth-status__header-close-icon" />
          </Link>
          {/* /КНОПКА ЗАКРЫТИЯ - ПЕРЕХОД НА ПРОФИЛЬ */}
        </div>
      </CSSTransition>

      <div className="auth-status__content container container_small">
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth < 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
          unmountOnExit
        >
          <WarningText
            className="animation-from-bottom-to-top"
            text="Войдите под своей учетной записью или напишите письмо в поддержку клиентов"
          />
        </CSSTransition>

        <div className="auth-status__ways-list">
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
            unmountOnExit
          >
            <button
              className="auth-status__ways-item"
              type="button"
              onClick={onEnterPassportData}
              data-testid="on-enter-passport-data-button"
            >
              <p className="auth-status__ways-item-title">Заполнить анкету</p>

              <div className="auth-status__ways-item-desc">
                Запрос на проверку может обрабатываться до 24 часов
              </div>
              <img
                src={penImg}
                alt=""
                className="auth-status__ways-item-img auth-status__ways-item-img_pen"
              />
            </button>
          </CSSTransition>

          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered}
            timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
            unmountOnExit
          >
            <button
              className="auth-status__ways-item"
              type="button"
              onClick={() => setIsGosUslugiPopupOpen(true)}
              data-testid="open-popup-no-gosuslugi"
            >
              <p className="auth-status__ways-item-title">Через ГосУслуги</p>
              <div className="auth-status__ways-item-desc">
                Самый быстрый способ, если у вас уже есть подтвержденная учетная запись на портале
              </div>

              {windowSize.innerWidth > 980 ? (
                <LogoGosuslugi className="auth-status__ways-item-img auth-status__ways-item-img_gosuslugi" />
              ) : (
                <LogoGosuslugiMobile className="auth-status__ways-item-img auth-status__ways-item-img_gosuslugi" />
              )}
            </button>
          </CSSTransition>
        </div>
      </div>

      {/* ПОПАП - АВТОРИЗАЦИЯ ЧЕРЕЗ ГОСУСЛУГИ ПОКА НЕДОСТУПНА */}
      <CSSTransition in={isGosUslugiPopupOpen} timeout={HINT_TIMEOUTS} unmountOnExit>
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          emoji={iconSad}
          mounted={isGosUslugiPopupOpen}
          title="Упс!"
          closePopup={() => setIsGosUslugiPopupOpen(false)}
          buttonArray={[
            {
              id: 1,
              title: 'Понятно',
              action: () => setIsGosUslugiPopupOpen(false),
              colorType: 'grey-border',
            },
          ]}
        >
          <p>
            Сервис ещё не готов, но вскором времени станет доступен. А пока вы можете подтвердить
            данные, заполнив анкету.
          </p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - АВТОРИЗАЦИЯ ЧЕРЕЗ ГОСУСЛУГИ ПОКА НЕДОСТУПНА */}
    </div>
  );
};

AuthStatusAlreadyRegistered.propTypes = {
  className: PropTypes.string.isRequired,
  onEnterPassportData: PropTypes.func.isRequired,
  resetAuthStep: PropTypes.func.isRequired,
};

export default AuthStatusAlreadyRegistered;
