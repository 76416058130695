import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import CheckboxWithLabel from 'modules/cashbox/components/CheckboxWithLabel/CheckboxWithLabel';
import CreateLinkInput from 'modules/cashbox/components/CreateLinkInput/CreateLinkInput';
import InputWithLabelAnimation from 'modules/shared/components/InputWithLabelAnimation/InputWithLabelAnimation';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import SumInput from 'modules/shared/components/SumInput/SumInput';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';

import { ANY_SUM, BLUE, FIXED_SUM } from 'constants/index';

import './ShowCaseForm.scss';

const KASSA = 'cashbox';

const ShowCaseForm = ({
  className,
  name,
  onChangeName,
  activeTab,
  fixedSumTabClick,
  anySumTabClick,
  sum,
  onChangeSum,
  isNameVisible,
  onChangeIsNameVisible,
  isPhoneVisible,
  onChangeIsPhoneVisible,
  isEmailVisible,
  onChangeIsEmailVisible,
  url,
  saveDataClick,
  isSaveActionLoading,
  userFio,
  userEmail,
  minSum,
  maxSum,
  isEditingShowCase,
  setUrl,
  isUrlLinkError,
  isUrlLinkLoading,
  isPayerNameNeeded,
  isPayerPhoneNeeded,
  isPayerEmailNeeded,
  onChangeIsPayerNameNeeded,
  onChangeIsPayerPhoneNeeded,
  onChangeIsPayerEmailNeeded,
}) => {
  const [isSaveButtonActive, setIsSaveButtonActive] = useState(false);
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);

  const navTabsArray = [
    {
      title: 'Любая сумма',
      click: anySumTabClick,
    },
    {
      title: 'Фикс. сумма',
      click: fixedSumTabClick,
    },
  ];

  /** Устанавливаем активное состояние кнопки - Опбликовать витрину */
  useEffect(() => {
    setIsSaveButtonActive(false);

    if (
      activeTab === ANY_SUM &&
      name &&
      name.length >= 1 &&
      url &&
      url.length >= 1 &&
      !isUrlLinkError &&
      !isUrlLinkLoading
    ) {
      setIsSaveButtonActive(true);
    }

    if (
      activeTab === FIXED_SUM &&
      name &&
      name.length >= 1 &&
      sum &&
      +sum >= 1 &&
      url &&
      url.length >= 1 &&
      !isUrlLinkError &&
      !isUrlLinkLoading
    ) {
      setIsSaveButtonActive(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [activeTab, name, sum, url, isUrlLinkError, isUrlLinkLoading]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Set isRendered for animations start */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div
      className={cx('show-case-form', {
        [className]: className,
      })}
      data-testid="show-case-form"
    >
      <div className="show-case-form__content">
        {windowSize.innerWidth <= 600 && (
          <TabsWithScrollableActiveState
            className="show-case-form__tabs"
            tabArray={navTabsArray}
            currentTabId={activeTab === ANY_SUM ? 0 : 1}
            isRendered={isRendered}
          />
        )}
        {/* ЦЕЛЬ КАССЫ */}
        <div className="show-case-form__block">
          <InputWithLabelAnimation
            label="Наименование услуги или товара"
            value={name}
            maxLength={256}
            onChange={onChangeName}
          />
        </div>
        {/* /ЦЕЛЬ КАССЫ */}

        {/* ТИП СУММЫ */}
        {windowSize.innerWidth > 600 && (
          <nav className="show-case-creation__form-nav">
            <button
              type="button"
              className={cx('show-case-creation__form-nav-item', {
                'show-case-creation__form-nav-item_active': activeTab === ANY_SUM,
              })}
              onClick={anySumTabClick}
            >
              Любая сумма
            </button>

            <button
              type="button"
              className={cx('show-case-creation__form-nav-item', {
                'show-case-creation__form-nav-item_active': activeTab === FIXED_SUM,
              })}
              onClick={fixedSumTabClick}
            >
              Фиксированная сумма
            </button>
          </nav>
        )}
        {/*  ТИП СУММЫ */}

        {activeTab === ANY_SUM && (
          <div className="show-case-form__block-text">
            Выбирая Любую сумму вы даёте возможность клиенту самому ввести любую сумму
          </div>
        )}

        {activeTab === FIXED_SUM && (
          <div className="show-case-form__block-text">
            Выбирая Фиксированную сумму, вы получите от клиента ту сумму, которую укажите
          </div>
        )}

        {/* ВВОД СУММЫ */}
        <div className="show-case-form__block">
          <SumInput
            setSum={onChangeSum}
            label={activeTab === ANY_SUM ? 'Сумма подсказки' : 'Сумма'}
            value={sum}
            required={activeTab === FIXED_SUM}
            maxSum={maxSum}
            minSum={minSum}
          />
        </div>
        {/* /ВВОД СУММЫ */}

        {/* ИНФОМРАЦИЯ О ПРОДАВЦЕ */}
        <div className="show-case-form__block show-case-form__block_info">
          <p className="show-case-form__block-title">Показать информацию:</p>

          <div className="show-case-form__checkbox-list">
            {/* ФИО ЧЕКБОКС */}
            {userFio && (
              <CheckboxWithLabel
                title="ФИО"
                ariaLabel="ФИО владельца витрины"
                isChecked={isNameVisible}
                onClick={onChangeIsNameVisible}
                className="show-case-form__checkbox-wrap"
              />
            )}
            {/* /ФИО ЧЕКБОКС */}

            {/* ТЕЛЕФОН ЧЕКБОКС */}
            <CheckboxWithLabel
              title="Телефон"
              ariaLabel="Телефон владельца витрины"
              isChecked={isPhoneVisible}
              onClick={onChangeIsPhoneVisible}
              className="show-case-form__checkbox-wrap"
            />
            {/* /ТЕЛЕФОН ЧЕКБОКС */}

            {/* EMAIL ЧЕКБОКС */}
            {userEmail && (
              <CheckboxWithLabel
                title="E-mail"
                ariaLabel="E-mail владельца витрины"
                isChecked={isEmailVisible}
                onClick={onChangeIsEmailVisible}
                className="show-case-form__checkbox-wrap"
              />
            )}
            {/* /EMAIL ЧЕКБОКС */}
          </div>
        </div>
        {/* /ИНФОМРАЦИЯ О ПРОДАВЦЕ */}

        {/* ИНФОМРАЦИЯ ДЛЯ ЗАПРОСА У ПОКУПАТЕЛЯ */}
        <div className="show-case-form__block show-case-form__block_info">
          <p className="show-case-form__block-title">Попросить у плательщика:</p>

          <div className="show-case-form__checkbox-list">
            {/* ФИО ЧЕКБОКС */}
            <CheckboxWithLabel
              title="ФИО"
              ariaLabel="ФИО плательщика"
              isChecked={isPayerNameNeeded}
              onClick={onChangeIsPayerNameNeeded}
              className="show-case-form__checkbox-wrap"
            />
            {/* /ФИО ЧЕКБОКС */}

            {/* ТЕЛЕФОН ЧЕКБОКС */}
            <CheckboxWithLabel
              title="Телефон"
              ariaLabel="Телефон плательщика"
              isChecked={isPayerPhoneNeeded}
              onClick={onChangeIsPayerPhoneNeeded}
              className="show-case-form__checkbox-wrap"
            />
            {/* /ТЕЛЕФОН ЧЕКБОКС */}

            {/* EMAIL ЧЕКБОКС */}
            <CheckboxWithLabel
              title="E-mail"
              ariaLabel="E-mail плательщика"
              isChecked={isPayerEmailNeeded}
              onClick={onChangeIsPayerEmailNeeded}
              className="show-case-form__checkbox-wrap"
            />
            {/* /EMAIL ЧЕКБОКС */}
          </div>
        </div>
        {/* /ИНФОМРАЦИЯ ДЛЯ ЗАПРОСА У ПОКУПАТЕЛЯ */}

        {/* СГЕНЕРИРОВАННАЯ ССЫЛКА */}
        <div className="show-case-form__block">
          <CreateLinkInput type={KASSA} isEditing={isEditingShowCase} setUrl={setUrl} url={url} />
        </div>
        {/* /СГЕНЕРИРОВАННАЯ ССЫЛКА */}

        {/* КНОПКА СОХРАНИТЬ */}
        <div className="show-case-form__button-wrap">
          <StandardButton
            isLink={false}
            title={isEditingShowCase ? 'Изменить витрину' : 'Опубликовать витрину'}
            disabled={isSaveActionLoading || !isSaveButtonActive || !url}
            bgType={BLUE}
            onClick={saveDataClick}
            isLoading={isSaveActionLoading}
            className="show-case-form__button"
          />
        </div>
        {/* /КНОПКА СОХРАНИТЬ */}
      </div>
    </div>
  );
};

ShowCaseForm.propTypes = {
  className: PropTypes.string,
  name: PropTypes.string.isRequired,
  onChangeName: PropTypes.func.isRequired,
  activeTab: PropTypes.string.isRequired,
  fixedSumTabClick: PropTypes.func.isRequired,
  anySumTabClick: PropTypes.func.isRequired,
  sum: PropTypes.string.isRequired,
  onChangeSum: PropTypes.func.isRequired,
  isNameVisible: PropTypes.bool.isRequired,
  onChangeIsNameVisible: PropTypes.func.isRequired,
  isPhoneVisible: PropTypes.bool.isRequired,
  onChangeIsPhoneVisible: PropTypes.func.isRequired,
  isEmailVisible: PropTypes.bool.isRequired,
  onChangeIsEmailVisible: PropTypes.func.isRequired,
  url: PropTypes.string,
  setUrl: PropTypes.func.isRequired,
  saveDataClick: PropTypes.func.isRequired,
  isSaveActionLoading: PropTypes.bool,
  userFio: PropTypes.bool,
  userEmail: PropTypes.bool,
  minSum: PropTypes.number,
  maxSum: PropTypes.number,
  isEditingShowCase: PropTypes.bool,
  isUrlLinkError: PropTypes.bool,
  isUrlLinkLoading: PropTypes.bool,
  isPayerNameNeeded: PropTypes.bool.isRequired,
  isPayerPhoneNeeded: PropTypes.bool.isRequired,
  isPayerEmailNeeded: PropTypes.bool.isRequired,
  onChangeIsPayerNameNeeded: PropTypes.func.isRequired,
  onChangeIsPayerPhoneNeeded: PropTypes.func.isRequired,
  onChangeIsPayerEmailNeeded: PropTypes.func.isRequired,
};

ShowCaseForm.defaultProps = {
  className: null,
  isSaveActionLoading: false,
  userFio: false,
  userEmail: false,
  minSum: 1,
  maxSum: Infinity,
  isEditingShowCase: false,
  url: '',
  isUrlLinkError: false,
  isUrlLinkLoading: false,
};

export default ShowCaseForm;
