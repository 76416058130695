import cx from 'classnames';
import { ru } from 'date-fns/esm/locale';
import { formatDate, normalizePhone, normalizeString } from 'helpers';
import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React, { useEffect, useRef, useState } from 'react';
import DatePicker, { registerLocale } from 'react-datepicker';
import InputMask from 'react-input-mask';
import { connect } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';
import queryString from 'query-string';
import { setAuthStep } from 'modules/profileLegasy/actions';
import HintDontHaveRussianPassport from 'modules/profileLegasy/components/Hint/HintDontHaveRussianPassport';
import DatePickerCustomInput from 'modules/shared/components/DatePickerCustomInput/DatePickerCustomInput';
import HintAuth from 'modules/shared/components/HintAuth/HintAuth';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';
import {
  HINT_TIMEOUTS,
  CONSISTENT_ANIMATIONS_DELAY,
  MONTHSRU,
  WEEK_DAYS_FIRST_SUNDAY_RU,
  REGISTERED,
  EDO,
} from 'constants/index';
import checkValidInn from 'helpers/checkValidInn';

import { ReactComponent as IconCalendar } from 'static/assets/icon-calendar.svg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import iconSad from 'static/assets/icon-emoji-sad.png';
import profileBg from 'static/assets/profile-bg.png';

// Styles
import './AuthStatusEnterPassportData.scss';

export const AuthStatusEnterPassportData = ({ className, dispatch, user, sendPassportData }) => {
  const history = useHistory();

  const [currentFormTab, setCurrentFormTab] = useState(0);
  const [isDropOpened, dropEl, toggleDrop, closeExplicitly] = useToggleAndOutClick();
  const [currentOpenElement, setCurrentOpenElement] = useState(null);
  const [isSendingData, setIsSendingData] = useState(false);

  /** States for person's data */
  const [surname, setSurname] = useState('');
  const [name, setName] = useState('');
  const [patronymic, setPatronymic] = useState(null);
  const [birthDay, setBirthDay] = useState(null);
  const [birthPlace, setBirthPlace] = useState('');
  const [passportNumber, setPassportNumber] = useState('');
  const [passportSerie, setPassportSerie] = useState('');
  const [passportIssueDate, setPassportIssueDate] = useState(null);
  const [inn, setInn] = useState('');
  const [passportIssuer, setPassportIssuer] = useState('');
  const [passportIssuedBy, setPassportIssuedBy] = useState('');

  const [birthDayForServer, setBirthDayForServer] = useState('');
  const [passportIssueDateForServer, setPassportIssueDateForServer] = useState('');

  /** States for checking if inputs are filled */
  const [isFirstTabInputsFilled, setIsFirstTabInputsFilled] = useState(false);
  const [isEveryInputFilled, setIsEveryInputFilled] = useState(false);

  /** States for datePicker */
  registerLocale('ru', {
    ...ru,
    localize: {
      ...ru?.localize,
      month: (n) => MONTHSRU[n],
      day: (n) => WEEK_DAYS_FIRST_SUNDAY_RU[n],
    },
  });

  /** Попап - госуслуги пока что недоступны */
  const [isGosUslugiPopupOpen, setIsGosUslugiPopupOpen] = useState(false);

  /** States for tabs */
  const tab1 = useRef(null);
  const tab2 = useRef(null);
  const [tabsBarTotalStyle, setTabsBarTotalStyle] = useState(null);
  const [tabsBarActiveStyle, setTabsBarActiveStyle] = useState(null);

  const [isRendered, setIsRendered] = useState(false);

  /** ИНН */
  const [valid, setValid] = useState(false);
  const [validInnErrorMsg, setValidInnErrorMsg] = useState('');

  const location = useLocation();

  const [authorizationType, setAuthorizationType] = useState(
    queryString.parse(location.search).type,
  );

  /** Валидация ИНН */
  useEffect(() => {
    setValid(false);

    if (inn && inn.length === 12 && !checkValidInn(inn)) {
      setValidInnErrorMsg('Указанный ИНН некорректен');
    } else if (inn.length === 12 && checkValidInn(inn)) {
      setValid(true);
      setValidInnErrorMsg('');
    } else {
      setValid(false);
      setValidInnErrorMsg('');
    }
  }, [inn]);

  /** Set active tab styles */
  useEffect(() => {
    if (isRendered) {
      const tab1Width = tab1.current.offsetWidth;
      const tab2Width = tab2.current.offsetWidth;

      setTabsBarTotalStyle({
        width: tab1Width + 30 + tab2Width,
      });
      if (currentFormTab === 0) {
        setTabsBarActiveStyle({
          width: tab1Width + 19,
          left: 0,
        });
      } else {
        setTabsBarActiveStyle({
          width: tab2Width + 19,
          left: tab1Width + 19,
        });
      }
    }
  }, [currentFormTab, isRendered]);

  /** Check if inputs from first tab are full */
  useEffect(() => {
    if (surname !== '' && name !== '' && birthDay !== null) {
      setIsFirstTabInputsFilled(true);
    } else {
      setIsFirstTabInputsFilled(false);
    }
  }, [surname, name, patronymic, birthDay]);

  /** Check if all inputs are full */
  useEffect(() => {
    if (
      isFirstTabInputsFilled &&
      normalizePhone(inn).length === 12 &&
      valid &&
      birthPlace &&
      passportNumber &&
      passportNumber.length >= 6 &&
      passportIssueDate &&
      (authorizationType !== 'EDO'
        ? passportNumber && passportNumber.length === 6
        : passportNumber && passportNumber.length >= 6) &&
      (authorizationType !== 'EDO' ? passportSerie && passportSerie.length === 4 : true) &&
      (authorizationType !== 'EDO' ? passportIssuer.length === 7 : true) &&
      passportIssuedBy
    ) {
      setIsEveryInputFilled(true);
    } else {
      setIsEveryInputFilled(false);
    }
  }, [
    isFirstTabInputsFilled,
    inn,
    passportSerie,
    passportNumber,
    passportIssueDate,
    valid,
    birthPlace,
    passportIssuedBy,
    passportIssuer,
    authorizationType,
  ]);

  /** Set inn if it's already set */
  useEffect(() => {
    if (
      user &&
      user.taxStatus &&
      user.taxStatus.status &&
      user.taxStatus.status === 'YES' &&
      user.taxStatus.inn
    ) {
      setInn(user.taxStatus.inn);
    }
    if (user && user.group === REGISTERED && !authorizationType) {
      setAuthorizationType('EDO');
    } else if (user && user.group === EDO && !authorizationType) {
      setAuthorizationType('AUTHORIZED');
    }
    /* eslint-disable */
  }, [authorizationType]);
  /* eslint-enable */

  useEffect(() => {
    if (birthDay) {
      setBirthDayForServer(formatDate(birthDay));
    }
  }, [birthDay]);

  useEffect(() => {
    if (passportIssueDate) {
      setPassportIssueDateForServer(formatDate(passportIssueDate));
    }
  }, [passportIssueDate]);

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setTimeout(() => {
      setIsRendered(true);
    }, 100);
  }, []);

  const handleSendDataClick = () => {
    setIsSendingData(true);

    sendPassportData({
      firstName: name, //имя
      lastName: surname, //фамилия
      birthDay: birthDayForServer, //день рождения
      birthPlace, //место рождения
      inn: normalizePhone(inn), //инн
      issueDate: passportIssueDateForServer, //дата выдачи
      passportIssuedBy, //паспорт выданный адрес
      number: passportSerie.length ? passportNumber : passportNumber.slice(2), //номер паспорта
      serie: passportSerie.length ? passportSerie : passportNumber.slice(0, 2), //серия паспорта
      passportIssuer: passportIssuer.length ? passportIssuer : '000-000', // паспорт выдан подразделение
      ...(!!patronymic ? { middleName: patronymic } : {}), //отчество
      ...(!!authorizationType ? { clientGroup: authorizationType } : {}),
    });
  };

  /** Открытие попапа - что делсть если у меня нет российского паспорта */
  const toggleNoRussianPassportHint = () => {
    toggleDrop();
    setCurrentOpenElement(1);
  };

  const onKeyUpTab0 = (event) => {
    if (event.key === 'Enter' && isFirstTabInputsFilled) {
      setCurrentFormTab(1);
    }
  };

  const onKeyUpTab1 = (event) => {
    if (event.key === 'Enter' && isEveryInputFilled) {
      handleSendDataClick();
    }
  };

  /** Закрытие попапа - возращение authStep в состояние 0 */
  const handleCloseButtonClick = () => {
    history.goBack();
    dispatch(setAuthStep(1));
  };

  const handleOnChangeInn = (value) => {
    setInn(value);
  };

  return (
    <>
      <div
        className={cx('auth-status', {
          [className]: className,
        })}
        data-testid="auth-status-enter-passport-data"
      >
        <CSSTransition
          classNames="animation-from-top-to-bottom"
          in={isRendered}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <div className="auth-status__header animation-from-top-to-bottom">
            <div className="auth-status__header-container container container_small">
              <nav className="auth-status__nav">
                <span className="auth-status__nav-close" onClick={handleCloseButtonClick}>
                  <IconClose className="auth-status__header-close-icon" />
                </span>
              </nav>

              <div className="auth-status__header-content">
                <h1 className="auth-status__header-title">Подтвердить данные</h1>
                <div className="auth-status__header-desc">
                  Для получения{' '}
                  {authorizationType === EDO ? 'доступа к ЭДО' : 'авторизованной учетной записи'}{' '}
                  заполните все поля анкеты
                </div>
              </div>
            </div>
            <img src={profileBg} alt="Profile bg" className="auth-status__header-bg" />
          </div>
        </CSSTransition>

        <div className="auth-status__content container container_small">
          <div className="auth-status__form">
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered}
              timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
              unmountOnExit
            >
              <div className="auth-status__personal-info animation-from-bottom-to-top">
                {/* TABS */}
                <div className="auth-status__personal-info-tabs">
                  <div
                    className={cx('auth-status__personal-info-tabs-item', {
                      'auth-status__personal-info-tabs-item_active': currentFormTab === 0,
                    })}
                    ref={tab1}
                  >
                    ФИО
                  </div>

                  <div
                    className={cx('auth-status__personal-info-tabs-item', {
                      'auth-status__personal-info-tabs-item_active': currentFormTab === 1,
                    })}
                    ref={tab2}
                  >
                    Паспорт
                  </div>
                </div>
                <div className="auth-status__personal-info-bar" style={tabsBarTotalStyle}>
                  <div
                    className="auth-status__personal-info-bar-active"
                    style={tabsBarActiveStyle}
                  />
                </div>

                {/* TABS */}
              </div>
            </CSSTransition>

            {/* FIRST TAB CONTENT */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered && currentFormTab === 0}
              timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
              unmountOnExit
            >
              <div className="auth-status__personal-info-content animation-from-bottom-to-top">
                <div className="auth-status__first-tab-content">
                  <div
                    className={cx('form-input', 'auth-status__first-tab-content__surname', {
                      'form-input_focused': surname !== '',
                    })}
                  >
                    <input
                      type="text"
                      className="form-input__itself"
                      onChange={(event) => setSurname(normalizeString(event.target.value))}
                      value={normalizeString(surname)}
                      onKeyUp={onKeyUpTab0}
                      data-cy="auth-surname-input"
                      data-testid="auth-surname-input"
                    />
                    <p className="form-input__label">Фамилия</p>
                  </div>

                  <div
                    className={cx('form-input', 'auth-status__first-tab-content__name', {
                      'form-input_focused': name !== '',
                    })}
                  >
                    <input
                      type="text"
                      className="form-input__itself"
                      onChange={(event) => setName(normalizeString(event.target.value))}
                      value={normalizeString(name)}
                      onKeyUp={onKeyUpTab0}
                      data-cy="auth-name-input"
                      data-testid="auth-name-input"
                    />
                    <p className="form-input__label">Имя</p>
                  </div>

                  <div
                    className={cx('form-input', 'auth-status__first-tab-content__patronymic', {
                      'form-input_focused': !!patronymic,
                    })}
                  >
                    <input
                      type="text"
                      className="form-input__itself"
                      onChange={(event) => setPatronymic(normalizeString(event.target.value))}
                      value={normalizeString(patronymic || '')}
                      onKeyUp={onKeyUpTab0}
                      data-cy="auth-patronymic-input"
                      data-testid="auth-patronymic-input"
                    />
                    <p className="form-input__label">Отчество</p>
                  </div>

                  <div
                    className={cx('form-input', 'auth-status__first-tab-content__birthDay', {
                      'form-input_focused': birthDay !== '',
                    })}
                  >
                    <DatePicker
                      selected={birthDay}
                      onChange={(date) => setBirthDay(date)}
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      placeholderText="__/__/____"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={99}
                      customInput={<DatePickerCustomInput />}
                      maxDate={new Date().setFullYear(new Date().getFullYear() - 10)}
                      minDate={new Date().setFullYear(new Date().getFullYear() - 109)}
                    />

                    <IconCalendar className="form-input__calendar-icon" />
                    <p className="form-input__label">Дата рождения дд.мм.гггг</p>
                  </div>
                </div>

                <div className="auth-status__btn-wrap">
                  <button
                    className={cx('auth-status__btn', {
                      'auth-status__btn_disabled': !isFirstTabInputsFilled,
                    })}
                    disabled={!isFirstTabInputsFilled}
                    type="button"
                    onClick={() => setCurrentFormTab(1)}
                    onKeyUp={onKeyUpTab0}
                    data-cy="auth-status-next-step-button"
                    data-testid="auth-status-next-step-button"
                  >
                    Далее
                  </button>
                </div>
              </div>
            </CSSTransition>

            {/* SECOND TAB CONTENT */}
            <CSSTransition
              classNames="animation-from-bottom-to-top"
              in={isRendered && currentFormTab === 1}
              timeout={{
                enter: 100,
                enterDelay: 300,
                exit: 500,
              }}
              unmountOnExit
            >
              <div className="auth-status__personal-info-content">
                <div className="auth-status__second-tab-content">
                  <div
                    className={cx('form-input', 'auth-status__second-tab-content__passport-serie', {
                      'form-input_focused': passportSerie !== '',
                    })}
                  >
                    <InputMask
                      type="text"
                      mask="****"
                      maskChar={null}
                      formatChars={{ '*': '[A-Za-zА-Яа-я0-9]' }}
                      autoComplete="nope"
                      className="form-input__itself"
                      onChange={(event) => setPassportSerie(event.target.value.toLocaleUpperCase())}
                      value={passportSerie}
                      onKeyUp={onKeyUpTab1}
                      data-cy="auth-passport-serie-input"
                    />
                    <p className="form-input__label">Серия паспорта</p>
                    {passportSerie &&
                      ((authorizationType !== EDO && passportSerie.length === 4) ||
                        (authorizationType === EDO && passportSerie.length === 4)) && (
                        <IconCheck className="form-input__input-check" />
                      )}
                  </div>

                  <div
                    className={cx(
                      'form-input',
                      'auth-status__second-tab-content__passport-number',
                      {
                        'form-input_focused': passportNumber !== '',
                      },
                    )}
                  >
                    <InputMask
                      type="text"
                      mask={authorizationType !== 'EDO' ? '******' : '************'}
                      formatChars={{ '*': '[A-Za-zА-Яа-я0-9]' }}
                      maskChar={null}
                      autoComplete="nope"
                      className="form-input__itself"
                      onChange={(event) => setPassportNumber(event.target.value)}
                      value={passportNumber}
                      onKeyUp={onKeyUpTab1}
                      data-cy="auth-passport-number-input"
                    />
                    <p className="form-input__label">Номер паспорта</p>
                    {passportNumber && passportNumber.length >= 6 && (
                      <IconCheck className="form-input__input-check" />
                    )}
                  </div>

                  <div
                    className={cx(
                      'form-input',
                      { 'form-input_focused': passportIssueDate !== '' },
                      'auth-status__second-tab-content__date',
                    )}
                    data-cy="auth-passport-issue-input-wrap"
                  >
                    <DatePicker
                      selected={passportIssueDate}
                      onChange={(date) => setPassportIssueDate(date)}
                      locale="ru"
                      dateFormat="dd.MM.yyyy"
                      placeholderText="__/__/____"
                      showYearDropdown
                      scrollableYearDropdown
                      yearDropdownItemNumber={99}
                      customInput={<DatePickerCustomInput />}
                      maxDate={new Date()}
                      minDate={new Date().setFullYear(new Date().getFullYear() - 99)}
                    />

                    <IconCalendar className="form-input__calendar-icon" />
                    <p className="form-input__label">Дата выдачи</p>
                  </div>

                  <div
                    className={cx('form-input', 'auth-status__second-tab-content__passportIssuer', {
                      'form-input_focused': passportIssuer !== '',
                    })}
                  >
                    <InputMask
                      type="text"
                      mask="999-999"
                      maskChar={null}
                      className="form-input__itself"
                      onChange={(event) => setPassportIssuer(event.target.value)}
                      value={passportIssuer}
                      onKeyUp={onKeyUpTab1}
                    />
                    <p className="form-input__label">Код подразделения</p>
                  </div>

                  <div
                    className={cx(
                      'form-input',
                      'auth-status__second-tab-content__passportIssuedBy',
                      {
                        'form-input_focused': passportIssuedBy !== '',
                      },
                    )}
                  >
                    <InputMask
                      type="text"
                      maskChar={null}
                      className="form-input__itself"
                      onChange={(event) =>
                        setPassportIssuedBy(event.target.value.toLocaleUpperCase())
                      }
                      value={passportIssuedBy}
                      onKeyUp={onKeyUpTab1}
                    />
                    <p className="form-input__label">Кем выдан</p>
                  </div>

                  <div
                    className={cx('form-input', 'auth-status__second-tab-content__birthPlace', {
                      'form-input_focused': birthPlace !== '',
                    })}
                  >
                    <InputMask
                      type="text"
                      maskChar={null}
                      className="form-input__itself"
                      onChange={(event) => setBirthPlace(event.target.value.toLocaleUpperCase())}
                      value={birthPlace}
                      onKeyUp={onKeyUpTab1}
                    />
                    <p className="form-input__label">Место рождения</p>
                  </div>

                  <div
                    className={cx(
                      'form-input',
                      { 'form-input_focused': inn !== '' },
                      'auth-status__second-tab-content__inn',
                    )}
                  >
                    <InputMask
                      mask="999999999999"
                      maskChar={null}
                      type="text"
                      className={cx('form-input__itself', {
                        'form-input__itself_disabled':
                          user &&
                          user.taxStatus &&
                          user.taxStatus.status &&
                          user.taxStatus.status === 'YES' &&
                          user.taxStatus.inn,
                        'form-input__itself_error': inn && inn.length > 0 && !checkValidInn(inn),
                      })}
                      onChange={({ target }) => handleOnChangeInn(target.value)}
                      value={inn}
                      disabled={
                        user &&
                        user.taxStatus &&
                        user.taxStatus.status &&
                        user.taxStatus.status === 'YES' &&
                        user.taxStatus.inn
                      }
                      onKeyUp={onKeyUpTab1}
                      data-cy="auth-inn-input"
                    />
                    <p className="form-input__label">ИНН</p>

                    {valid && <IconCheck className="form-input__input-check" />}

                    {!valid && validInnErrorMsg !== '' && (
                      <div className="form-input__input-error" data-cy="inn-error-message">
                        {validInnErrorMsg}
                      </div>
                    )}
                  </div>
                </div>

                <div className="auth-status__btn-wrap">
                  <StandardButton
                    isLink={false}
                    title="Отправить"
                    onClick={handleSendDataClick}
                    onKeyUp={onKeyUpTab1}
                    disabled={!isEveryInputFilled || isSendingData}
                    className="auth-status__btn"
                    isLoading={isSendingData}
                  />
                </div>
              </div>
            </CSSTransition>
          </div>

          <div className="auth-status__hint-block">
            <div className="auth-status__hint-list">
              <div className="auth-status__hint-item-wrap">
                {authorizationType !== 'EDO' && (
                  <CSSTransition
                    classNames="animation-from-bottom-to-top"
                    in={isRendered}
                    timeout={CONSISTENT_ANIMATIONS_DELAY[4]}
                    unmountOnExit
                  >
                    <button
                      className="auth-status__hint-item"
                      type="button"
                      onClick={toggleNoRussianPassportHint}
                    >
                      У меня нет российского паспорта
                    </button>
                  </CSSTransition>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>

      <CSSTransition
        in={isDropOpened && currentOpenElement === 1}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <HintAuth
          ref={dropEl}
          onCloseBtnClick={closeExplicitly}
          className="auth-status__hint-passport-sidebar"
          classNameInner="auth-status__hint-passport-inner"
          title="У меня нет российского паспорта"
        >
          <HintDontHaveRussianPassport />
        </HintAuth>
      </CSSTransition>

      {/* ПОПАП - АВТОРИЗАЦИЯ ЧЕРЕЗ ГОСУСЛУГИ ПОКА НЕДОСТУПНА */}
      <CSSTransition in={isGosUslugiPopupOpen} timeout={HINT_TIMEOUTS} unmountOnExit>
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          emoji={iconSad}
          mounted={isGosUslugiPopupOpen}
          title="Упс!"
          closePopup={() => setIsGosUslugiPopupOpen(false)}
          const
          buttonArray={[
            {
              id: 1,
              title: 'Понятно',
              action: () => setIsGosUslugiPopupOpen(false),
              colorType: 'grey-border',
            },
          ]}
        >
          <p>
            Сервис ещё не готов, но вскором времени станет доступен. А пока вы можете подтвердить
            данные, заполнив анкету.
          </p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - АВТОРИЗАЦИЯ ЧЕРЕЗ ГОСУСЛУГИ ПОКА НЕДОСТУПНА */}
    </>
  );
};

AuthStatusEnterPassportData.propTypes = {
  className: PropTypes.string.isRequired,
  sendPassportData: PropTypes.func.isRequired,
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({
    taxStatus: PropTypes.shape({
      inn: PropTypes.string,
      status: PropTypes.string,
    }),
  }),
};

AuthStatusEnterPassportData.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  isMenuOpen: state.auth.isMenuOpen,
  user: state.auth.user,
});

export default connect(mapStateToProps)(AuthStatusEnterPassportData);
