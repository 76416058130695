import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import screen2 from 'static/assets/manual-screen-2.png';
import phone9 from 'static/assets/phone-9.png';

const ManualPageStepWebVersionGosuslugi = ({ returnBack, setStepGoToWallet }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">
            Регистрация
            <br />
            через Госуслуги
          </h1>

          {windowSize.innerWidth <= 760 && (
            <div className="manual-page__images-container">
              <img src={phone9} alt="" className="manual-page__phone manual-page__phone_9" />
              <img src={screen2} alt="" className="manual-page__screen manual-page__screen_2" />
            </div>
          )}

          <div className="manual-page__text-list">
            <div className="manual-page__text">
              При наличии подтвержденной учетной записи на Госуслугах, в приложение можно войти без
              ИНН и пароля от ЛК. Вам нужно будет лишь авторизоваться обычным способом.
            </div>

            <div className="manual-page__text">
              Будьте внимательны: после авторизации через Госуслуги вы автоматически станете
              плательщиком налога на профессиональный доход.
            </div>
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="Далее"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToWallet}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered && windowSize.innerWidth > 760}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={phone9} alt="" className="manual-page__phone manual-page__phone_9" />
          <img src={screen2} alt="" className="manual-page__screen manual-page__screen_2" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepWebVersionGosuslugi.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToWallet: PropTypes.func.isRequired,
};

export default ManualPageStepWebVersionGosuslugi;
