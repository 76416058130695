import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { animated, useSpring } from 'react-spring';

import { getUnconfirmedEmail } from 'modules/profileLegasy/actions';
import AccountLimits from 'modules/profileLegasy/components/AccountLimits/AccountLimits';
import AvatarUpload from 'modules/profileLegasy/components/AvatarUpload/AvatarUpload';
import ChangePasswordWrap from 'modules/profileLegasy/components/ChangePasswordWrap/ChangePasswordWrap';
import ChangePhoneWrap from 'modules/profileLegasy/components/ChangePhoneWrap/ChangePhoneWrap';
import LiftLimits from 'modules/profileLegasy/components/LiftLimits/LiftLimits';
import ProfileErrorMessage from 'modules/profileLegasy/components/ProfileErrorMessage/ProfileErrorMessage';
import ProfileSettingsEmailChanges from 'modules/profileLegasy/components/ProfileSettingsEmailChanges/ProfileSettingsEmailChanges';
import ProfileSettingsWalletClosingButton from 'modules/profileLegasy/components/ProfileSettingsWalletClosingButton/ProfileSettingsWalletClosingButton';
import UnconfirmedEmailMessage from 'modules/profileLegasy/components/UnconfirmedEmailMessage/UnconfirmedEmailMessage';

import { AUTHORIZED, REGISTERED } from 'constants/index.js';

import './ProfileSettingsProfileTab.scss';

export const ProfileSettingsProfileTab = ({
  dispatch,
  limits,
  isMenuOpen,
  userPhone,
  userStatusGroup,
  isAvatarError,
  unconfirmedEmail,
}) => {
  const windowSize = useWindowSize();

  const [isRendered, setIsRendered] = useState(false);
  const [authorizedGroupArrayIndex, setAuthorizedGroupArrayIndex] = useState(null);
  const [registeredGroupArrayIndex, setRegisteredGroupArrayIndex] = useState(null);

  /** Ставим анимацию сразу после маунта */
  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Устанавливаем даныне для вывода лимитов */
  useEffect(() => {
    if (limits && Array.isArray(limits)) {
      const groupCodeArr = limits.map((item) => {
        return item.groupCode;
      });

      setRegisteredGroupArrayIndex(groupCodeArr.indexOf('REGISTERED'));
      setAuthorizedGroupArrayIndex(groupCodeArr.indexOf('AUTHORIZED'));
    }
  }, [limits]);

  /** Проверяем, есть ли не подтвержденный e-mail */
  useEffect(() => {
    dispatch(getUnconfirmedEmail())
      .then(() => {})
      .catch(() => {});
  }, [dispatch]);

  const transY = (y) => `translateY(${y}px)`;

  const spring = useSpring({
    opacity: 1,
    y: 0,
    from: { opacity: 0, y: 50 },
    config: { mass: 1, tension: 280, friction: 80 },
  });

  return (
    <animated.div
      className="profile-settings-profile-tab"
      style={{
        opacity: spring.opacity,
        transform: spring.y.interpolate(transY),
      }}
      data-testid="profile-settings-profile-tab"
    >
      {/* ЗАГОЛОВОК + АВАТАР + СООБЩЕНИЕ ОБ ОШИБКЕ */}
      <div className="profile-settings-profile-tab__top">
        {/* ЗАГОЛОВОК + АВАТАР */}
        <div className="profile-settings-profile-tab__top-main">
          {windowSize.innerWidth > 980 && (
            <h2 className="profile-settings-profile-tab__title">Настройки профиля</h2>
          )}

          {/* АВАТАР */}
          <div className="profile-settings-profile-tab__avatar">
            <div className="profile-settings-profile-tab__content-col">
              <AvatarUpload smallAvatar={false} />
            </div>
            {/* СООБЩЕНИЕ ОБ ОШИБКЕ ЗАГРУЗКИ АВТАРА */}
            <div className="profile-settings-profile-tab__content-col">
              <ProfileErrorMessage
                animationStart={isRendered && isAvatarError}
                className="profile-settings-profile-tab__error-massage"
                content=" Не удалось обновить фотографию профиля. Возможно выбран неверный формат или превышен
            допустимый размер изображения"
              />
            </div>
            {/* /СООБЩЕНИЕ ОБ ОШИБКЕ ЗАГРУЗКИ АВТАРА */}
          </div>
          {/* /АВАТАР */}
        </div>
        {/* /ЗАГОЛОВОК + АВАТАР */}

        {/* СООБЩЕНИЕ ОБ НЕПОДТВЕРЖДЕННОМ E-MAIL */}
        <UnconfirmedEmailMessage
          animationStart={isRendered && Boolean(unconfirmedEmail) && !isAvatarError}
          unconfirmedEmail={unconfirmedEmail}
          className="profile-settings-profile-tab__error-massage"
        />
        {/* /СООБЩЕНИЕ ОБ НЕПОДТВЕРЖДЕННОМ E-MAIL */}
      </div>
      {/* /ЗАГОЛОВОК + АВАТАР + СООБЩЕНИЕ ОБ ОШИБКЕ */}

      <div className="profile-settings-profile-tab__content">
        {/* ТЕЛЕФОН, E-MAIL, ПАРОЛЬ */}
        <div className="profile-settings-profile-tab__content-col">
          {/* ТЕЛЕФОН */}
          <ChangePhoneWrap userPhone={userPhone} isMenuOpen={isMenuOpen} />
          {/* /ТЕЛЕФОН */}

          {/* E-MAIL */}
          <ProfileSettingsEmailChanges />
          {/* /E-MAIL */}

          {/* ПАРОЛЬ */}
          <ChangePasswordWrap isMenuOpen={isMenuOpen} />
          {/* /ПАРОЛЬ */}

          {/* кнопка для закрытия кошелька */}
          <ProfileSettingsWalletClosingButton />
          {/* /кнопка для закрытия кошелька */}
        </div>
        {/* /ТЕЛЕФОН, E-MAIL, ПАРОЛЬ */}

        {/* ЛИМИТ */}
        <div className="profile-settings-profile-tab__content-col">
          <AccountLimits
            className={cx('profile-settings-profile-tab__limits', {
              'account-limits_simple': userStatusGroup === REGISTERED,
              'account-limits_auth': userStatusGroup === AUTHORIZED,
            })}
            limits={
              userStatusGroup === REGISTERED
                ? limits && Array.isArray(limits) && limits[registeredGroupArrayIndex]
                : limits && Array.isArray(limits) && limits[authorizedGroupArrayIndex]
            }
          />

          {/* КАК ПОВЫСИТЬ ЛИМИТЫ КНОПКА + ПОПАП */}
          {userStatusGroup !== AUTHORIZED && (
            <div className="profile-settings-profile-tab__hint-list">
              <LiftLimits isMenuOpen={isMenuOpen} />
            </div>
          )}
          {/* /КАК ПОВЫСИТЬ ЛИМИТЫ КНОПКА + ПОПАП */}
        </div>
        {/* /ЛИМИТ */}
      </div>
    </animated.div>
  );
};

ProfileSettingsProfileTab.propTypes = {
  dispatch: PropTypes.func.isRequired,
  limits: PropTypes.arrayOf(
    PropTypes.shape({
      balanceLimit: PropTypes.number,
      monthLimit: PropTypes.number,
      maxSum: PropTypes.number,
    }),
  ),
  isMenuOpen: PropTypes.bool.isRequired,
  userPhone: PropTypes.string,
  userStatusGroup: PropTypes.string,
  isAvatarError: PropTypes.bool.isRequired,
  unconfirmedEmail: PropTypes.string,
};

ProfileSettingsProfileTab.defaultProps = {
  limits: null,
  userPhone: '',
  userStatusGroup: null,
  unconfirmedEmail: '',
};

const mapStateToProps = (state) => ({
  userPhone: state.auth.user && state.auth.user.login ? state.auth.user.login : '',
  userStatusGroup: state.auth.user && state.auth.user.group ? state.auth.user.group : null,
  limits: state.auth.limits,
  isMenuOpen: state.auth.isMenuOpen,
  isAvatarError: state.profile.isAvatarError,
  unconfirmedEmail: state.profile.unconfirmedEmail,
});

export default connect(mapStateToProps)(ProfileSettingsProfileTab);
