/* eslint-disable import/prefer-default-export */
import { WEIGHT } from './constants';

export const getFont = (fontType) => {
  switch (fontType) {
    case WEIGHT['circe-bold']:
      return `
            font-family: 'Circe-Bold', sans-serif;
        `;
    case WEIGHT['circe-extra-bold']:
      return `
            font-family: 'Circe-ExtraBold', sans-serif;
        `;
    case WEIGHT['circe-regular']:
      return `
            font-family: 'Circe-Regular', sans-serif;
        `;
    case WEIGHT['rubik-regular']:
      return `
            font-family: 'Rubik', sans-serif;
            font-weight: normal;
        `;
    case WEIGHT['rubik-medium']:
      return `
            font-family: 'Rubik', sans-serif;
            font-weight: 400;
        `;
    case WEIGHT['roboto-mono']:
      return `
            font-family: 'Roboto Mono', monospace;
            font-weight: 400;
        `;
    case WEIGHT.roboto:
      return `
            font-family: 'Roboto', sans-serif;
            font-weight: 400;
        `;
    default:
      return `
            font-family: 'Circe-Regular', sans-serif;
        `;
  }
};

export const currencyFormater = (number, currency = 'RUB') =>
  Intl.NumberFormat('ru-RU', { style: 'currency', currency }).format(number);
