import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import { Link, Redirect } from 'react-router-dom';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import { ReactComponent as LogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import styles from './PopupHeader.module.scss';

const PopupHeader = ({ className, onClose, href }) => {
  const [isRedirected, setIsRedirected] = React.useState(false);

  function onCloseButtonClick() {
    if (onClose) {
      onClose();
    }

    setIsRedirected(true);
  }

  if (isRedirected) {
    return <Redirect to={href} />;
  }

  return (
    <header className={cx(styles.popupHeader, className)}>
      <div className={styles.popupHeaderDesktop}>
        <PopupHeaderWithBalance />
        <PopupCloseWithText
          className={styles.popupHeaderDesktopClose}
          closePopupHandler={onCloseButtonClick}
        />
      </div>
      <div className={styles.popupHeaderMobile}>
        <Link to={href} className={styles.popupHeaderLink}>
          <LogoResponsive className={styles.popupHeaderLogo} />
        </Link>
        <button className={styles.popupHeaderClose} onClick={onCloseButtonClick}>
          <IconClose className={styles.popupHeaderCloseIcon} />
        </button>
      </div>
    </header>
  );
};

PopupHeader.defaultProps = {
  href: '/',
};

PopupHeader.propTypes = {
  className: PropTypes.string,
  onClose: PropTypes.func,
  href: PropTypes.string.isRequired,
};

export default PopupHeader;
