import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import cx from 'classnames';

// Components
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Style
import './StandardHoverButton.scss';

const StandardHoverButton = ({
  isLink,
  title,
  href,
  onClick,
  className,
  disabled,
  isLoading,
  icon,
  type,
}) => {
  return (
    <>
      {isLink && href && !disabled ? (
        <Link
          to={href}
          className={cx('standard-hover-button', {
            [className]: className,
          })}
          data-testid="standard-hover-button"
          data-cy="standard-hover-button"
          onClick={onClick}
        >
          <div className="standard-hover-button__hover" />

          <div className="standard-hover-button__main">
            {icon}
            <span className="standard-hover-button__text">{title}</span>
          </div>
        </Link>
      ) : (
        <button
          type={type || 'button'}
          onClick={onClick}
          className={cx('standard-hover-button', {
            [className]: className,
          })}
          data-testid="standard-hover-button"
          disabled={disabled || isLoading}
          data-cy="standard-hover-button"
        >
          {isLoading ? (
            <div className="standard-hover-button__main">
              <CircleLoader />
            </div>
          ) : (
            <>
              <div className="standard-hover-button__hover" />

              <div className="standard-hover-button__main">
                {icon}
                <span className="standard-hover-button__text">{title}</span>
              </div>
            </>
          )}
        </button>
      )}
    </>
  );
};

StandardHoverButton.propTypes = {
  className: PropTypes.string,
  isLink: PropTypes.bool.isRequired,
  icon: PropTypes.shape({}),
  title: PropTypes.string.isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  type: PropTypes.string,
};

StandardHoverButton.defaultProps = {
  className: null,
  icon: null,
  href: null,
  onClick: () => {},
  disabled: false,
  isLoading: false,
  type: 'button',
};

export default StandardHoverButton;
