import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Prompt, Redirect, useLocation, useParams } from 'react-router-dom';

import { getUser, setMenuOpen } from 'modules/authLegasy/actions';
import Footer from 'modules/authLegasy/components/Footer/Footer';
import Header from 'modules/authLegasy/components/Header/Header';
import Loader from 'modules/authLegasy/components/Loader/Loader';
import { getBusinessCardInfo, getTransactionOperationStatus } from 'modules/profileLegasy/actions';
import BusinessCard from 'modules/profileLegasy/components/BusinessCard/BusinessCard';
import CardsFloatingDetails from 'modules/profileLegasy/components/CardsFloatingDetails/CardsFloatingDetails';

import { ReactComponent as IconLine1 } from 'static/assets/line-1.svg';
import { ReactComponent as IconLine2 } from 'static/assets/line-2.svg';
import { ReactComponent as IconLine3 } from 'static/assets/line-3.svg';
import { ReactComponent as IconLogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as Logo } from 'static/assets/logo.svg';
import stdAvatar from 'static/assets/std-avatar.png';

// Styles
import './BusinessCardContainer.scss';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';

/**
 * Этот компонент выводит визитку пользователя с формой оплаты картой
 * До оплаты url вида /to/:uniqueId
 * При оплате происходит редирект на /receipt/businesscard/:operationId/wait
 * Как получен результат происзодит дальше редирект на
 * /receipt/businesscard/:operationId/success или
 * /receipt/businesscard/error
 */
const BusinessCardContainer = ({ dispatch, user, isMenuOpen }) => {
  const windowSize = useWindowSize();
  const location = useLocation();
  const { operationId, uniqueId } = useParams();

  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isReplenishmentBlocked, setIsReplenishmentBlocked] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [userData, setUserData] = useState(null);
  const [error, setError] = useState('');
  const [isSending, setIsSending] = useState(false);

  const [redirectToErrorPage, setRedirectToErrorPage] = useState(false);
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState(false);

  const isMoneyTransferOk = Boolean(location.pathname.indexOf('success') > -1 && operationId);
  const isMoneyTransferFailed = location.pathname.indexOf('error') > -1;
  /**
   * Узнаем детали кошелька пользователя по уникальному
   * id из url при нахождени на /to/guiIgdsm28/
   */
  useEffect(() => {
    if (!uniqueId) {
      return;
    }

    setIsLoading(true);

    dispatch(getBusinessCardInfo(uniqueId))
      .then((res) => {
        try {
          setUserData(res.data);
          if (res.data && res.data.adminBlocked) {
            setIsUserBlocked(true);
          }

          if (res.data && res.data.blockedReceipt) {
            setIsReplenishmentBlocked(true);
          }

          /**
           * Записываем аватар в localStorage, чтобы после
           * возвращения с 3ds он отображался правильно
           *  */
          window.localStorage.setItem(
            'businessCardAvatar',
            res.data && res.data.avatar ? `${res.data.avatar}` : stdAvatar,
          );
        } catch (_error) {
          setError('К сожалению, пользователь с таким идентификатором не найден');
        }
      })
      .catch(() => {
        setError('К сожалению, пользователь с таким идентификатором не найден');
      })
      .then(() => {
        setIsLoading(false);
      });
  }, [dispatch, uniqueId]);

  const updateTimeout = useRef(null);

  const updateOperationStatus = useCallback(() => {
    if (!(operationId && location.pathname.indexOf('wait') > -1)) {
      clearTimeout(updateTimeout.current);
      return;
    }

    setIsSending(true);
    dispatch(getTransactionOperationStatus(operationId))
      .then((res) => {
        if (res && res.data) {
          if (res.data.state === STATUS_WAIT) {
            updateTimeout.current = setTimeout(updateOperationStatus, 1000);
            dispatch(getUser()).catch(() => {});
          } else if (res.data.state === STATUS_ERROR) {
            setRedirectToErrorPage(true);
          } else {
            setRedirectToSuccessPage(true);
            dispatch(getUser()).catch(() => {});
          }

          if (res.data.transactionStateMessage) {
            window.localStorage.setItem(
              'transactionStateMessage',
              res.data.transactionStateMessage,
            );
          }
        }
      })
      .catch(() => {
        setRedirectToErrorPage(true);
      });
  }, [dispatch, location.pathname, operationId]);

  /** Узнаем результаты проведения операции при нахождении на /wait/ */
  useEffect(() => {
    updateOperationStatus();
    return () => {
      clearTimeout(updateTimeout.current);
    };
  }, [updateOperationStatus, location.pathname, operationId]);

  /** Добавляем overflow: hidden для body при открытии портала */
  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = '';
    };
  });

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    if (isSending) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  /** Устанваливаем isMenuOpen при клике на бургер */
  const handleClick = () => {
    dispatch(setMenuOpen(!isMenuOpen));
  };

  if (isLoading) {
    return <Loader className="business-card-container__loader" inverted />;
  }

  if (error) {
    return (
      <div className="business-card-container__error">
        <p className="business-card-container__error-text">{error}</p>
      </div>
    );
  }

  if (redirectToErrorPage) {
    return <Redirect to="/receipt/businesscard/error" />;
  }

  if (redirectToSuccessPage && operationId) {
    return <Redirect to={`/receipt/businesscard/${operationId}/success`} />;
  }

  return createPortal(
    <div
      className={cx('business-card-container', {
        'business-card-container_menu-open': isMenuOpen,
      })}
    >
      {/* ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}
      <Prompt
        when={isSending}
        message="Операция выполняется, вы уверены что хотите уйти со страницы не дожидаясь результата?"
      />
      {/* /ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}

      {/* ХЭДЕР ДЛЯ ЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {user && windowSize.innerWidth > 980 && <Header isUserLoggedIn />}
      {/* /ХЭДЕР ДЛЯ ЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}

      {/* ХЭДЕР ДЛЯ НЕЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {!user && windowSize.innerWidth > 980 && (
        <div className="business-card-container__header">
          {windowSize.innerWidth > 1194 ? (
            <Link to="/" className="business-card-container__header-start-link">
              <Logo className="business-card-container__header-logo" />
            </Link>
          ) : (
            <Link to="/" className="business-card-container__header-start-link">
              <IconLogoResponsive className="business-card-container__header-logo" />
            </Link>
          )}

          <button
            className={cx('business-card-container__burger', {
              'business-card-container__burger_menu-open': isMenuOpen,
            })}
            type="button"
            onClick={handleClick}
          >
            <IconLine1 className="business-card-container__burger-span" />
            <IconLine2 className="business-card-container__burger-span" />
            <IconLine3 className="business-card-container__burger-span" />
          </button>
        </div>
      )}
      {/* /ХЭДЕР ДЛЯ НЕЗАЛОГИНЕНОГО ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА > 980 */}

      {/* ССЫЛКА-ВИЗИТКА */}
      {!isLoading &&
        !error &&
        (userData || isSending || isMoneyTransferOk || isMoneyTransferFailed) && (
          <div className="business-card-container__link-wrap">
            <BusinessCard
              className="business-card-container__link"
              userData={userData}
              clientUniqueId={uniqueId}
              isMoneyTransferOk={isMoneyTransferOk}
              isMoneyTransferFailed={isMoneyTransferFailed}
              isSending={isSending}
              isUserBlocked={isUserBlocked}
              isReplenishmentBlocked={isReplenishmentBlocked}
              userAvatar={
                window && window.localStorage && window.localStorage.getItem('businessCardAvatar')
              }
            />
          </div>
        )}
      {/* /ССЫЛКА-ВИЗИТКА */}

      {/* ПЛАВАЮЩИЕ ДЕТАЛИ СПРАВА И СЛЕВА ОТ ССЫЛКИ-ВИЗИТКИ */}
      {windowSize.innerWidth > 980 && <CardsFloatingDetails />}
      {/* /ПЛАВАЮЩИЕ ДЕТАЛИ СПРАВА И СЛЕВА ОТ ССЫЛКИ-ВИЗИТКИ */}

      {/* ФУТЕР ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {windowSize.innerWidth > 980 && <Footer />}
      {/* /ФУТЕР ПРИ ШИРИНЕ ЭКРАНА > 980 */}
    </div>,
    document.body,
  );
};

BusinessCardContainer.propTypes = {
  dispatch: PropTypes.func.isRequired,
  user: PropTypes.shape({}),
  isMenuOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  isMenuOpen: state.auth.isMenuOpen,
});

export default connect(mapStateToProps)(BusinessCardContainer);
