import { getPartnerList } from 'modules/profileLegasy/actions';
import PopupPageWrap from 'modules/profileLegasy/components/PopupPageWrap/PopupPageWrap';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import React, { useEffect, useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, useRouteMatch } from 'react-router-dom';
import {
  hidePopupEditBankCard,
  hidePopupEditBankCardFail,
  hidePopupEditBankCardSuccess,
} from '../../actions';
import { getPartnerNameByPartnershipId } from '../../selectors';
import PartnerNav from '../PartnerNav';
import PartnerNavContainer from '../PartnerNavContainer';
import PopupEditBankCard from '../PopupEditBankCard';
import PopupEditBankCardFail from '../PopupEditBankCardFail';
import PopupEditBankCardSuccess from '../PopupEditBankCardSuccess';
import ScrollToTopButton from '../ScrollToTopButton';

const Partner = () => {
  const {
    params: { platformName, partnershipId },
  } = useRouteMatch('/lk/partner/:platformName/:partnershipId');
  const partnerName = useSelector(getPartnerNameByPartnershipId(platformName, partnershipId));

  const getPartnerListFetching = useSelector((store) => store.profile.getPartnerList.fetching);
  const getPartnerListSuccess = useSelector((store) => store.profile.getPartnerList.success);
  const getPartnerListFail = useSelector((store) => store.profile.getPartnerList.fail);
  const isPopupEditBankCardFailVisible = useSelector(
    (store) => store.partners.layers.popupEditBankCardFailVisible,
  );
  const isPopupEditBankCardVisible = useSelector(
    (store) => store.partners.layers.popupEditBankCardVisible,
  );
  const isPopupEditBankCardSuccessVisible = useSelector(
    (store) => store.partners.layers.popupEditBankCardSuccessVisible,
  );
  const dispatch = useDispatch();

  const [isRendered, setIsRendered] = useState(false);
  const [isRedirected, setIsRedirected] = useState(false);
  const scrollableAreaRef = useRef();

  useEffect(() => {
    setIsRendered(true);
    dispatch(getPartnerList());
    return () => {
      dispatch(hidePopupEditBankCard());
      dispatch(hidePopupEditBankCardSuccess());
      dispatch(hidePopupEditBankCardFail());
    };
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  useEffect(() => {
    if (getPartnerListFail) {
      setIsRedirected(true);
    }
  }, [getPartnerListFail]);

  if (isRedirected) return <Redirect to="/lk/profile-settings/partners" />;

  return (
    isRendered && (
      <PopupPageWrap
        textTitle={partnerName}
        mainContent={
          <>
            {getPartnerListFetching && <CircleLoader />}
            {getPartnerListSuccess && (
              <>
                <PartnerNav />
                <PartnerNavContainer />
              </>
            )}
            <ScrollToTopButton ref={scrollableAreaRef} />
            {isPopupEditBankCardVisible && <PopupEditBankCard />}
            {isPopupEditBankCardSuccessVisible && <PopupEditBankCardSuccess />}
            {isPopupEditBankCardFailVisible && <PopupEditBankCardFail />}
          </>
        }
        isMainContentFullWidth
        isOpen={isRendered}
        onOpen={(state) => {
          setIsRendered(!state);
          setIsRedirected(true);
        }}
      />
    )
  );
};

export default Partner;
