/* eslint-disable import/prefer-default-export */
export const ACCOUNTS_TYPES = {
  REGISTERED: 'REGISTERED',
  EDO: 'EDO',
  AUTHORIZED: 'AUTHORIZED',
  IDENTIFIED: 'IDENTIFIED',
};

export const DOC_STATUSES = new Map([
  ['DRAFT', 'Черновик'],
  ['SEND_TO_SIGN', 'На подпись'],
  ['SIGNED', 'Подписан'],
  ['EXPIRED', 'Истек срок действия'],
  ['REJECTED', 'Отклонен'],
  ['BLOCKED', 'Заблокирован'],
  ['TERMINATED', 'Расторгнут'],
]);
