import useWindowSize from '@rehooks/window-size';
import cx from 'classnames';
import { disableSpaceEnter, validateEmail } from 'helpers';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';
import {
  EmailShareButton,
  //TODO?: функционал был выключен в рамках задачи SWPS-192
  //TODO?: функционал был выключен в в связи блокировкой на территории РФ
  // FacebookShareButton,
  OKShareButton,
  TelegramShareButton,
  VKShareButton,
  WhatsappShareButton,
} from 'react-share';

import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Images
import headerBg from 'static/assets/card-header-bg.jpg';
import { ReactComponent as IconCheck } from 'static/assets/icon-check.svg';
import { ReactComponent as IconCoins } from 'static/assets/icon-coins.svg';
import { ReactComponent as IconCopyContent } from 'static/assets/icon-copy-content.svg';
import { ReactComponent as IconEnvelope } from 'static/assets/icon-envelope.svg';
//TODO?: функционал был выключен в рамках задачи SWPS-192
//TODO?: функционал был выключен в в связи блокировкой на территории РФ
// import { ReactComponent as IconFbShare } from 'static/assets/icon-fb-share.svg';
import { ReactComponent as IconLock } from 'static/assets/icon-lock-closed.svg';
import { ReactComponent as IconShare } from 'static/assets/icon-share.svg';
import { ReactComponent as IconTriangle } from 'static/assets/icon-triangle.svg';

import { ReactComponent as IconShareTg } from 'static/assets/icon-share-tg.svg';
import { ReactComponent as IconShareVk } from 'static/assets/icon-share-vk.svg';
import { ReactComponent as IconShareWa } from 'static/assets/icon-share-wa.svg';
import { ReactComponent as IconShareOk } from 'static/assets/icon-share-ok.svg';

// Styles
import './MoneyRequestCard.scss';

const LINK_TAB = 'LINK_TAB';
const EMAIL_TAB = 'EMAIL_TAB';

const MoneyRequestCard = ({
  className,
  handleSubmitClick,
  isSucceed,
  isFailed,
  isLoading,
  link,
  minSum,
  maxSum,
  isFailedOverLimit,
  currentTabId,
  linkTabCLick,
  emailTabCLick,
}) => {
  const windowSize = useWindowSize();

  const [sum, setSum] = useState('');
  const [isSumValid, setIsSumValid] = useState(false);

  const [purpose, setPurpose] = useState('');
  const [isPurposeValid, setIsPurposeValid] = useState(false);

  const [email, setEmail] = useState('');
  const [isEmailValid, setIsEmailValid] = useState(false);

  const [isCopied, setIsCopied] = useState(false);

  const circleLoaderValue = 0;

  /** Check if sum input is valid */
  useEffect(() => {
    setIsSumValid(false);
    if (!minSum && !maxSum && !Number(sum)) {
      setIsSumValid(false);
    } else if (sum && +sum >= minSum / 100 && +sum <= maxSum / 100) {
      setIsSumValid(true);
    }
  }, [sum, minSum, maxSum]);

  const handlePurposeFieldChange = (event) => {
    setPurpose(event.target.value);
  };

  /** Check if purpose input is valid */
  useEffect(() => {
    setIsPurposeValid(false);

    if (purpose) {
      setIsPurposeValid(true);
    }
  }, [purpose]);

  const handleEmailFieldChange = (event) => {
    setEmail(disableSpaceEnter(event.target.value));
  };

  /** Check if email input is valid */
  useEffect(() => {
    setIsEmailValid(false);

    if (email && validateEmail(email)) {
      setIsEmailValid(true);
    }
  }, [email, currentTabId]);

  const isFieldsCompleted = () => {
    if (currentTabId === LINK_TAB && isPurposeValid && isSumValid) {
      return true;
    }

    if (currentTabId === EMAIL_TAB && isPurposeValid && isSumValid && isEmailValid) {
      return true;
    }

    return false;
  };

  const moneyRequestHandler = () => {
    handleSubmitClick(purpose, email, sum);
  };

  /**
   * Записываем в localStorage, что мы переходим на страницу
   * Истории со страницы запроса денег, чтобы открыть там нужную вкладку
   * */
  const handleRequestsHistoryLink = () => {
    window.localStorage.setItem('historyTab', '1');
  };

  return (
    <div
      className={cx('money-request-card', {
        [className]: className,
      })}
    >
      {/* ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА > 980 */}
      {windowSize.innerWidth > 980 && (
        <div className="money-request-card__header" style={{ backgroundImage: `url(${headerBg})` }}>
          {!isFailed && (
            <div className="money-request-card__header-icon-wrap">
              <IconCoins className="money-request-card__header-icon" />
            </div>
          )}
        </div>
      )}
      {/* /ХЭДЕР ПРИ ШИРИНЕ ЭКРАНА > 980 */}

      {/* ДО ТОГО, КАК ЗАПРОС ОТПРАВЛЕН */}
      {!isSucceed && !isFailed && (
        <div className="money-request-card__content">
          <div className="money-request-card__left-part">
            {/* НАЗНАЧЕНИЕ ПЛАТЕЖА */}
            <div className="money-request-card__input-holder">
              <p className="money-request-card__input-label">НАЗНАЧЕНИЕ</p>
              <input
                type="text"
                maxLength={256}
                className="money-request-card__input"
                placeholder="Например, за кофе"
                onChange={(event) => handlePurposeFieldChange(event)}
                value={purpose}
                readOnly={isLoading}
              />

              {isPurposeValid && <IconCheck className="money-request-card__input-check" />}
            </div>
            {/* /НАЗНАЧЕНИЕ ПЛАТЕЖА */}

            {/* СУММА */}
            <div className="money-request-card__input-holder">
              <p className="money-request-card__input-label">СУММА</p>
              <NumberFormat
                className="money-request-card__input"
                placeholder="Введите сумму"
                value={sum}
                thousandSeparator=" "
                readOnly={isLoading}
                allowNegative={false}
                allowEmptyFormatting={false}
                decimalScale={2}
                isNumericString
                allowedDecimalSeparators={[',', '.']}
                type="tel"
                onValueChange={(values) => {
                  const { floatValue, value } = values;

                  if (floatValue < minSum / 100) {
                    setSum(minSum / 100);
                  } else if (floatValue > maxSum / 100) {
                    setSum(maxSum / 100);
                  } else if (value === '') {
                    setSum('');
                  } else {
                    setSum(value);
                  }
                }}
                isAllowed={(values) => {
                  const { value } = values;

                  if (value.charAt(0) === '0') {
                    return value.charAt(1) === '.';
                  }

                  return true;
                }}
              />
              {isSumValid && <IconCheck className="money-request-card__input-check" />}
            </div>
            {/* /СУММА */}

            {/* E-MAIL - ЕСЛИ ВЫБРАН СПОСОБ ОТПРАВКИ - ПО ПОЧТЕ */}
            {currentTabId === EMAIL_TAB && (
              <div className="money-request-card__input-holder">
                <p className="money-request-card__input-label">ВВЕДИТЕ E-MAIL</p>
                <input
                  type="text"
                  className="money-request-card__input"
                  placeholder="Введите e-mail"
                  onChange={(event) => handleEmailFieldChange(event)}
                  value={email}
                  readOnly={isLoading}
                />
                {isEmailValid && <IconCheck className="money-request-card__input-check" />}
              </div>
            )}
            {/* /E-MAIL -ЕСЛИ ВЫБРАН СПОСОБ ОТПРАВКИ - ПО ПОЧТЕ */}

            {/* КНОПКА СОЗДАТЬ ССЫЛКУ ПРИ ШИРИНЕ ЭКРАНА < 980 */}
            {windowSize.innerWidth <= 980 && (
              <div className="money-request-card__button-wrap">
                <button
                  className="money-request-card__button"
                  type="button"
                  disabled={!isFieldsCompleted() || isLoading}
                  onClick={moneyRequestHandler}
                >
                  {isLoading ? (
                    <CircleLoader className="money-request-card__button-loader" />
                  ) : (
                    'Отправить запрос'
                  )}
                </button>
              </div>
            )}
            {/* /КНОПКА СОЗДАТЬ ССЫЛКУ ПРИ ШИРИНЕ ЭКРАНА < 980 */}
          </div>

          <div className="money-request-card__right-part">
            <div className="money-request-card__right-part-top">
              {/* ВЫБОР ОТПРАВКИ ЗАПРОС: ПО ПОЧТЕ/ ССЫЛКОЙ */}
              <div className="money-request-card__tabs">
                <button
                  className={cx('money-request-card__tabs-item', {
                    'money-request-card__tabs-item_active': currentTabId === LINK_TAB,
                  })}
                  type="button"
                  disabled={isLoading}
                  onClick={linkTabCLick}
                >
                  Ссылкой
                </button>
                <button
                  className={cx('money-request-card__tabs-item', {
                    'money-request-card__tabs-item_active': currentTabId === EMAIL_TAB,
                  })}
                  type="button"
                  disabled={isLoading}
                  onClick={emailTabCLick}
                >
                  На почту
                </button>
              </div>
              {/* /ВЫБОР ОТПРАВКИ ЗАПРОС: ПО ПОЧТЕ/ ССЫЛКОЙ */}

              {/* ЕСЛИ ВЫБРАН СПОСОБ ОТПАРВКИ - ССЫЛКОЙ */}
              {currentTabId === LINK_TAB && (
                <p className="money-request-card__tip">
                  На следующем шаге мы создадим для вас ссылку
                </p>
              )}
              {/* /ЕСЛИ ВЫБРАН СПОСОБ ОТПАРВКИ - ССЫЛКОЙ */}

              {/* ЕСЛИ ВЫБРАН СПОСОБ ОТПРАВКИ - ПО ПОЧТЕ */}
              {currentTabId === EMAIL_TAB && (
                <p className="money-request-card__tip">
                  Мы отправим письмо с ссылкой на форму оплаты на указанный e-mail
                </p>
              )}
              {/* /ЕСЛИ ВЫБРАН СПОСОБ ОТПРАВКИ - ПО ПОЧТЕ */}
            </div>

            {/* КНОПКА ОТПРАВИТЬ ЗАПРОС ПРИ ШИРИНЕ ЭКРАНА > 980 */}
            {windowSize.innerWidth > 980 && (
              <div className="money-request-card__button-wrap">
                <button
                  type="button"
                  className="money-request-card__button"
                  disabled={!isFieldsCompleted() || isLoading}
                  onClick={moneyRequestHandler}
                >
                  {isLoading ? (
                    <CircleLoader className="money-request-card__loader" />
                  ) : (
                    'Отправить запрос'
                  )}
                </button>
              </div>
            )}
            {/* /КНОПКА ОТПРАВИТЬ ЗАПРОС ПРИ ШИРИНЕ ЭКРАНА > 980 */}
          </div>
        </div>
      )}
      {/* /ДО ТОГО, КАК ЗАПРОС ОТПРАВЛЕН */}

      {/* ЗАПРОС УСЕШНО ОТПРАВЛЕН */}
      {isSucceed && !isFailed && (
        <div className="money-request-card__content">
          <div className="money-request-card__info">
            <div className="money-request-card__info-block">
              <p className="money-request-card__info-block-label">Способ отправки</p>
              {currentTabId === LINK_TAB && (
                <p className="money-request-card__info-block-value">По ссылке</p>
              )}

              {currentTabId === EMAIL_TAB && (
                <p className="money-request-card__info-block-value">На e-mail</p>
              )}
            </div>

            {/* НАЗНАЧЕНИЕ */}
            <div className="money-request-card__info-block">
              <p className="money-request-card__info-block-label">Назначение</p>
              <p className="money-request-card__info-block-value">{purpose}</p>
            </div>
            {/* /НАЗНАЧЕНИЕ */}

            {/* СУММА */}
            <div className="money-request-card__info-block">
              <p className="money-request-card__info-block-label">Сумма</p>
              <p className="money-request-card__info-block-value"> {sum} ₽</p>
            </div>
            {/* /СУММА */}

            {/* ЭЛЕКТРОПОЧТА */}
            {currentTabId === EMAIL_TAB && (
              <div className="money-request-card__info-block">
                <p className="money-request-card__info-block-label">E-mail</p>
                <p className="money-request-card__info-block-value"> {email}</p>
              </div>
            )}
            {/* /ЭЛЕКТРОПОЧТА */}

            {/* ССЫЛКА НА ИСТОРИЮ ЗАПРОСОВ ПРИ ШИРИНЕ ЭКРАНА < 980 */}
            {windowSize.innerWidth <= 980 && (
              <>
                <p className="money-request-card__tip">
                  Уведомление об оплате появится у вас в личном кабинете
                </p>

                <div className="money-request-card__requests-history-link-wrap">
                  <Link
                    to="/lk/history"
                    className="money-request-card__requests-history-link"
                    onClick={handleRequestsHistoryLink}
                  >
                    История запросов
                  </Link>
                </div>
              </>
            )}
            {/* /ССЫЛКА НА ИСТОРИЮ ЗАПРОСОВ ПРИ ШИРИНЕ ЭКРАНА < 980 */}
          </div>

          <div className="money-request-card__main">
            <div className="money-request-card__link-block">
              {/* ССЫЛКА НА ЗАПРОС */}
              <CopyToClipboard text={link} onCopy={() => setIsCopied(true)}>
                <button className="money-request-card__link-wrap" type="button">
                  <IconCopyContent className="money-request-card__link-icon" />
                  <p className="money-request-card__link">{link}</p>
                </button>
              </CopyToClipboard>
              {/* /ССЫЛКА НА ЗАПРОС */}

              <div
                className={cx('money-request-card__link-share-block', {
                  'money-request-card__link-share-block_with-copy': isCopied,
                })}
              >
                {/* УВЕДОМЛЕНИЕ, ЕСЛИ ССЫЛКА СКОПИРОВАНА */}
                {isCopied && <div className="money-request-card__copy-success">скопировано</div>}
                {/* /УВЕДОМЛЕНИЕ, ЕСЛИ ССЫЛКА СКОПИРОВАНА */}

                {/* ШАРИНГ */}
                <div className="money-request-card__link-share-list">
                  <CopyToClipboard text={link} onCopy={() => setIsCopied(true)}>
                    <button className="money-request-card__link-share-item" type="button">
                      <IconShare className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_share" />
                    </button>
                  </CopyToClipboard>

                  <EmailShareButton className="money-request-card__link-share-item" url={link}>
                    <IconEnvelope className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_envelope" />
                  </EmailShareButton>

                  <VKShareButton className="money-request-card__link-share-item" url={link}>
                    <IconShareVk className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_vk" />
                  </VKShareButton>

                  <TelegramShareButton className="money-request-card__link-share-item" url={link}>
                    <IconShareTg className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_tg" />
                  </TelegramShareButton>

                  <OKShareButton className="money-request-card__link-share-item" url={link}>
                    <IconShareOk className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_ok" />
                  </OKShareButton>

                  <WhatsappShareButton className="money-request-card__link-share-item" url={link}>
                    <IconShareWa className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_wa" />
                  </WhatsappShareButton>

                  {
                    //TODO?: функционал был выключен в рамках задачи SWPS-192
                    //TODO?: функционал был выключен в в связи блокировкой на территории РФ
                  }
                  {/* <FacebookShareButton className="money-request-card__link-share-item" url={link}>
                    <IconFbShare className="money-request-card__link-share-item-icon money-request-card__link-share-item-icon_fb" />
                  </FacebookShareButton> */}
                </div>
                {/* /ШАРИНГ */}
              </div>
            </div>

            {/* ССЫЛКА НА ИСТОРИЮ ЗАПРОСОВ ПРИ ШИРИНЕ ЭКРАНА > 980 */}
            {windowSize.innerWidth > 980 && (
              <>
                <p className="money-request-card__tip">
                  Уведомление об оплате появится у вас в личном кабинете
                </p>

                <div className="money-request-card__requests-history-link-wrap">
                  <Link
                    to="/lk/history"
                    className="money-request-card__requests-history-link"
                    onClick={handleRequestsHistoryLink}
                  >
                    История запросов
                  </Link>
                </div>
              </>
            )}
            {/* /ССЫЛКА НА ИСТОРИЮ ЗАПРОСОВ ПРИ ШИРИНЕ ЭКРАНА > 980 */}
          </div>
        </div>
      )}
      {/* /ЗАПРОС УСЕШНО ОТПРАВЛЕН */}

      {/* НЕУДАЧНЫЙ ЗАПРОС */}
      {isFailed && !isSucceed && (
        <div className="money-request-card__content money-request-card__content_error">
          <div className="money-request-card__error-icon-wrap">
            <CircleProgress
              className="money-request-card__error-progress"
              value={circleLoaderValue}
              color="var(--reddish-pink)"
              radius={28}
            />
            <IconLock className="money-request-card__error-icon" />
          </div>

          {isFailedOverLimit && (
            <div className="money-request-card__error-message">
              Запрос не был отправлен. Превышен лимит на создание запросов в сутки. Попробуйте
              создать запрос через 24 часа
              {windowSize.innerWidth < 980 && (
                <IconTriangle className="money-request-card__error-message-triangle" />
              )}
            </div>
          )}

          {!isFailedOverLimit && (
            <div className="money-request-card__error-message">
              Упс! Что-то пошло не так и запрос не был отправлен. Попробуйте создать запрос позже.
              Если проблема не решится,{' '}
              <span className="money-request-card__error-message-link js-help">напишите нам</span>
              {windowSize.innerWidth < 980 && (
                <IconTriangle className="money-request-card__error-message-triangle" />
              )}
            </div>
          )}

          <div className="money-request-card__error-link-wrap">
            <Link to="/lk" className="money-request-card__error-link">
              В кошелек
            </Link>
          </div>
        </div>
      )}
      {/* /НЕУДАЧНЫЙ ЗАПРОС */}
    </div>
  );
};

MoneyRequestCard.propTypes = {
  className: PropTypes.string,
  handleSubmitClick: PropTypes.func.isRequired,
  isSucceed: PropTypes.bool,
  isFailed: PropTypes.bool,
  link: PropTypes.string.isRequired,
  isLoading: PropTypes.bool.isRequired,
  maxSum: PropTypes.number.isRequired,
  minSum: PropTypes.number.isRequired,
  isFailedOverLimit: PropTypes.bool.isRequired,
  currentTabId: PropTypes.string,
  linkTabCLick: PropTypes.func.isRequired,
  emailTabCLick: PropTypes.func.isRequired,
};

MoneyRequestCard.defaultProps = {
  className: null,
  isSucceed: false,
  isFailed: true,
  sumLimits: null,
  currentTabId: LINK_TAB,
};

export default MoneyRequestCard;
