import React, { useState, useEffect, useRef } from 'react';
import cx from 'classnames';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import useWindowSize from '@rehooks/window-size';

// Components
import NotificationsList from 'modules/profileLegasy/components/NotificationsList/NotificationsList';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Actions
import {
  getAllMessages,
  getUnreadMessages,
  readExactMessage,
  markAllMessagesAsRead,
  clearUnreadMessages,
  readAllMessages,
} from 'modules/profileLegasy/actions';
import { getUser } from 'modules/authLegasy/actions';

// Styles
import './Notifications.scss';

const NEW_MESSAGES_TAB = 'NEW_MESSAGES_TAB';
const ALL_MESSAGES_TAB = 'ALL_MESSAGES_TAB';
const PAGE_SIZE = 5;
const ZONE_OFFSET = new Date().getTimezoneOffset();

const Notifications = ({ dispatch, unreadMessages, allMessages }) => {
  const [isInitialLoading, setIsInitialLoading] = useState(true);
  const [unreadMessagesPage, setUnreadMessagesPage] = useState(1);
  const [allMessagesPage, setAllMessagesPage] = useState(1);

  const [currentOpenMessagesTabId, setCurrentOpenMessagesTabId] = useState(NEW_MESSAGES_TAB);
  const [isLoading, setIsLoading] = useState(false);
  const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  const [hasToMarkAllAsRead, setHasToMarkAllAsRead] = useState(false);

  const windowSize = useWindowSize();

  const topRef = useRef();

  /** Загружаем непрочитанные и все уведомления */
  useEffect(() => {
    getAllAndUnreadMessages();
    /* eslint-disable */
  }, []);
  /* eslint-enable */

  const getAllAndUnreadMessages = () => {
    setIsLoading(true);

    Promise.all([
      dispatch(getUnreadMessages(unreadMessagesPage, PAGE_SIZE, ZONE_OFFSET)),
      dispatch(getAllMessages(allMessagesPage, PAGE_SIZE, ZONE_OFFSET)),
    ])
      .then((values) => {
        /** Если нет непрочитанных сообщений - автоматически переключаем на вкладку "все" */
        try {
          if (values[0].data.total < 1) {
            dispatch(getUser()).catch(() => {});
            setCurrentOpenMessagesTabId(ALL_MESSAGES_TAB);
          }
        } catch (error) {
          // Do nothing
        }

        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
      });
  };

  /** Загрузка непрочитанных сообщений при смене страницы */
  useEffect(() => {
    if (!isInitialLoading) {
      scrollToTop();
      setIsNewPageLoading(true);

      dispatch(getUnreadMessages(unreadMessagesPage, PAGE_SIZE, ZONE_OFFSET))
        .then(() => {
          setIsNewPageLoading(false);
        })
        .catch(() => {
          setIsNewPageLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [unreadMessagesPage, isInitialLoading, dispatch]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /** Загрузка всех сообщений при смене страницы */
  useEffect(() => {
    if (!isInitialLoading) {
      scrollToTop();
      setIsNewPageLoading(true);

      dispatch(getAllMessages(allMessagesPage, PAGE_SIZE, ZONE_OFFSET))
        .then(() => {
          setIsNewPageLoading(false);
        })
        .catch(() => {
          setIsNewPageLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [allMessagesPage, isInitialLoading, dispatch]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const scrollToTop = () => {
    if (windowSize.innerHeight <= 800) {
      window.scrollTo({ top: 90, behavior: 'smooth' });
    }
  };

  /** Читаем конкретное сообщение */
  const readMessage = (id) => {
    dispatch(readExactMessage(id));
    dispatch(getUser()).catch(() => {});
  };

  const markAllAsRead = async () => {
    /** Сначала ждем когда всем сообщениям в сторе проставится read=true */
    await dispatch(markAllMessagesAsRead());

    /**
     * Далее - ставим списку флаг убирания всех сообщений в true
     * Компонент списка вызовет вызовет callback когда все сообщения закончат анимацию
     */
    setHasToMarkAllAsRead(true);
  };

  const hasToMarkAllAsReadCallback = () => {
    /** Колбэк в первую очередь должен вернуть флаг в false */
    setHasToMarkAllAsRead(false);

    /** Переключаем таб на "ВСЕ" */
    setCurrentOpenMessagesTabId(ALL_MESSAGES_TAB);

    /** И только сейчас отправляем запрос на прочтение всех сообщений */
    dispatch(readAllMessages())
      .then(() => {
        /**
         * Берем инфу по юзеру, чтобы обновить в сторе информацию
         * по непрочитанным сообщениям (user.unreadMessages)
         * это надо чтобы в хэдере у колокольчика пропал модификатор
         * header__user-notification_has-notifications
         * */
        dispatch(getUser()).catch(() => {});
      })
      .catch(() => {});

    /**
     * Берем инфу по юзеру, чтобы обновить в сторе информацию
     * по непрочитанным сообщениям (user.unreadMessages)
     * это надо чтобы в хэдере у колокольчика пропал модификатор
     * header__user-notification_has-notifications
     * */
    dispatch(getUser()).catch(() => {});

    /** И сносим unreadMessages в сторе */
    dispatch(clearUnreadMessages());
  };

  const setPageForNewMessages = (page) => {
    setIsInitialLoading(false);
    setUnreadMessagesPage(page);
  };

  const setPageForAllMessages = (page) => {
    setIsInitialLoading(false);
    setAllMessagesPage(page);
  };

  const newMessagesTabClick = () => {
    setCurrentOpenMessagesTabId(NEW_MESSAGES_TAB);

    setTimeout(() => {
      dispatch(getUnreadMessages(unreadMessagesPage, PAGE_SIZE, ZONE_OFFSET))
        .then(() => {
          setIsNewPageLoading(false);
        })
        .catch(() => {
          setIsNewPageLoading(false);
        });
    }, 500);
  };

  const allMessagesTabClick = () => {
    setCurrentOpenMessagesTabId(ALL_MESSAGES_TAB);

    dispatch(getAllMessages(allMessagesPage, PAGE_SIZE, ZONE_OFFSET))
      .then(() => {
        setIsNewPageLoading(false);
      })
      .catch(() => {
        setIsNewPageLoading(false);
      });
  };

  return (
    <div className="notifications" ref={topRef}>
      <div className="notifications__top">
        <div className="notifications__main">
          <h1 className="notifications__title">Уведомления</h1>

          {!isLoading && (
            <div className="notifications__info">
              {unreadMessages && (
                <button
                  className={cx('notifications__info-item', {
                    'notifications__info-item_active':
                      currentOpenMessagesTabId === NEW_MESSAGES_TAB,
                  })}
                  type="button"
                  onClick={newMessagesTabClick}
                  disabled={!unreadMessages.total}
                >
                  Новые: {unreadMessages.total || 0}
                </button>
              )}

              {allMessages && (
                <button
                  className={cx('notifications__info-item', {
                    'notifications__info-item_active':
                      currentOpenMessagesTabId === ALL_MESSAGES_TAB,
                  })}
                  type="button"
                  onClick={allMessagesTabClick}
                >
                  Всего: {allMessages.total || 0}
                </button>
              )}
            </div>
          )}
        </div>

        {!isLoading && currentOpenMessagesTabId === NEW_MESSAGES_TAB && (
          <button className="notifications__mark-all-as-read" type="button" onClick={markAllAsRead}>
            <i className="notifications__mark-all-as-read-icon" />
            <span className="notifications__mark-all-as-read-text">отметить все прочитанными</span>
          </button>
        )}
      </div>

      {isLoading && (
        <div className="notifications__loading">
          <CircleLoader className="notifications__loading-icon" width={50} height={50} />
        </div>
      )}

      {!isLoading &&
        currentOpenMessagesTabId === NEW_MESSAGES_TAB &&
        unreadMessages &&
        unreadMessages.messages && (
          <NotificationsList
            className="notifications__list"
            messages={unreadMessages.messages}
            currentPage={unreadMessagesPage}
            setPage={setPageForNewMessages}
            onUnreadItemClick={readMessage}
            numberOfPages={unreadMessages.numberOfPages}
            hasToInitMarkAllAsRead={hasToMarkAllAsRead}
            hasToMarkAllAsReadCallback={hasToMarkAllAsReadCallback}
            isLoading={isNewPageLoading}
          />
        )}

      {!isLoading &&
        currentOpenMessagesTabId === ALL_MESSAGES_TAB &&
        allMessages &&
        allMessages.messages && (
          <NotificationsList
            className="notifications__list"
            messages={allMessages.messages}
            currentPage={allMessagesPage}
            setPage={setPageForAllMessages}
            numberOfPages={allMessages.numberOfPages}
            isLoading={isNewPageLoading}
          />
        )}
    </div>
  );
};

Notifications.propTypes = {
  dispatch: PropTypes.func.isRequired,
  unreadMessages: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({})),
    numberOfPages: PropTypes.number,
    total: PropTypes.number,
  }),
  allMessages: PropTypes.shape({
    messages: PropTypes.arrayOf(PropTypes.shape({})),
    numberOfPages: PropTypes.number,
    total: PropTypes.number,
  }),
};

Notifications.defaultProps = {
  unreadMessages: null,
  allMessages: null,
};

const mapStateToProps = (state) => ({
  unreadMessages: state.profile.unreadMessages,
  allMessages: state.profile.allMessages,
});

export default connect(mapStateToProps)(Notifications);
