import addDays from 'date-fns/addDays';

export const APPLE_STORE_LINK = 'https://apps.apple.com/ru/app/id1474600470';
export const GOOGLE_PLAY_LINK =
  'https://play.google.com/store/apps/details?id=com.selfwork.android';

export const EDO = 'EDO';
export const REGISTERED = 'REGISTERED';
export const PARTIALLY_AUTHORIZED = 'PARTIALLY_AUTHORIZED';
export const AUTHORIZED = 'AUTHORIZED';
export const IDENTIFIED = 'IDENTIFIED';

export const YES = 'YES';
export const NO = 'NO';
export const NOT_READY = 'NOT_READY';
export const NOT_MATCH = 'NOT_MATCH';
export const NOT_READY_PERMISSION_REQUESTED = 'NOT_READY_PERMISSION_REQUESTED';
export const NOT_FOUND = 'NOT_FOUND';
export const ALIEN_INN = 'ALIEN_INN';
export const ERROR = 'ERROR';
export const PARTLY_YES = 'PARTLY_YES';
export const NO_PERMISSION = 'NO_PERMISSION';
export const PAYMENT_TRANSFER_OK = 'PAYMENT_TRANSFER_OK';
export const PAYMENT_TRANSFER_FAIL = 'PAYMENT_TRANSFER_FAIL';
export const PAYMENT_TRANSFER_LOADING = 'PAYMENT_TRANSFER_LOADING';
export const PAYMENT_TRANSFER_IN_PROCESS = 'PAYMENT_TRANSFER_IN_PROCESS';
export const WS_CHANGED_BALANCE = 'balance-changed';
export const WS_NEW_MESSAGE = 'internal-msg-delivered';
export const WS_CHANGED_AUTH_STATUS = 'identification-completed';
export const WS_CHANGED_SZ_STATUS = 'sz-status-updated';
export const HINT_TIMEOUTS = {
  enter: 600,
  exit: 400,
};

export const HINT_TYPES = {
  65: {
    title: 'Уведомление от ФНС',
    image: '65-fns',
    ext: 'jpg',
  },
  70: {
    title: 'Подтвержден статус самозанятого',
    image: '70-se-status',
    ext: 'svg',
    width: 44,
    height: 39,
  },
  67: {
    title: 'Запрос оплачен',
    image: '67-request-paid',
    ext: 'svg',
    width: 20,
    height: 25,
  },
  68: {
    title: 'Авторизация в сервисе',
    image: '68-auth-in-service',
    ext: 'png',
  },
  66: {
    title: 'Ваш счет оплачен',
    image: '68-bill-paid',
    ext: 'svg',
    width: 25,
    height: 31,
  },
  8: {
    title: 'Попытка входа в аккаунт',
    image: '8-login-attemp',
    ext: 'svg',
    width: 40,
    height: 40,
  },
  13: {
    title: 'Пополнение кошелька',
    image: '13-login-attemp',
    ext: 'svg',
    width: 20,
    height: 25,
  },
  2: {
    title: 'Выполнен перевод',
    image: '2-transfer-done',
    ext: 'svg',
    width: 20,
    height: 25,
  },
  28: {
    title: 'Номер телефона изменен',
    image: '28-phone-changed',
    ext: 'svg',
    width: 40,
    height: 40,
  },
  69: {
    title: 'Авторизация в сервисе не пройдена',
    image: '69-auth-failed',
    ext: 'svg',
    width: 40,
    height: 40,
  },
  57: {
    title: 'Запрос на сотрудничество',
    image: '57-coop-request',
    ext: 'svg',
    width: 40,
    height: 40,
  },
  88: {
    title: 'Статус учетной записи',
    image: '57-coop-request',
    ext: 'svg',
    width: 40,
    height: 40,
  },
  default: {
    title: 'Уведомление',
    image: '57-coop-request',
    ext: 'svg',
    width: 40,
    height: 40,
  },
};

export const INCOME_REG_FROM_INDIVIDUAL = 1;
export const INCOME_REG_FROM_LEGAL_ENTITY = 2;
export const INCOME_REG_FROM_FOREIGN_AGENCY = 3;
export const INCOME_REG_WORD_MAP = {
  1: 'FROM_INDIVIDUAL',
  2: 'FROM_LEGAL_ENTITY',
  3: 'FROM_FOREIGN_AGENCY',
};

export const MONTHSRU = [
  'Январь',
  'Февраль',
  'Март',
  'Апрель',
  'Май',
  'Июнь',
  'Июль',
  'Август',
  'Сентябрь',
  'Октябрь',
  'Ноябрь',
  'Декабрь',
];

export const MONTHS_REDUCED_RU = [
  'Янв',
  'Фев',
  'Март',
  'Апр',
  'Май',
  'Июнь',
  'Июль',
  'Авг',
  'Сент',
  'Окт',
  'Нояб',
  'Дек',
];

export const OPERATIONS_MONTHS_REDUCED_RU = [
  'янв.',
  'фев.',
  'марта',
  'апр.',
  'мая',
  'июня',
  'июля',
  'авг.',
  'сент.',
  'окт.',
  'нояб.',
  'дек.',
];

export const WEEK_DAYS_RU = ['ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ', 'ВС'];
export const WEEK_DAYS_FIRST_SUNDAY_RU = ['ВС', 'ПН', 'ВТ', 'СР', 'ЧТ', 'ПТ', 'СБ'];
export const WEEK_DAYS_LOWERCASE_RU = ['пн', 'вт', 'ср', 'чт', 'пт', 'сб', 'вс'];

export const CONSISTENT_ANIMATIONS_DELAY = [
  {
    enter: 100,
    exit: 100,
  },
  {
    enter: 150,
    exit: 100,
  },
  {
    enter: 200,
    exit: 100,
  },
  {
    enter: 250,
    exit: 100,
  },
  {
    enter: 300,
    exit: 100,
  },
  {
    enter: 350,
    exit: 100,
  },
  {
    enter: 400,
    exit: 100,
  },
];

export const AUTHORIZED_PROFILE_TAB = 'AUTHORIZED_PROFILE_TAB';
export const SZ_PROFILE_TAB = 'SZ_PROFILE_TAB';
export const PARTNERS_PROFILE_TAB = 'PARTNERS_PROFILE_TAB';
export const SETTINGS_PROFILE_TAB = 'SETTINGS_PROFILE_TAB';
export const PERSONAL_DATA_PROFILE_TAB = 'PERSONAL_DATA_PROFILE_TAB';
export const USER_CARDS_PROFILE_TAB = 'USER_CARDS_PROFILE_TAB';
export const DOCUMENTS_PROFILE_TAB = 'DOCUMENTS_PROFILE_TAB';

export const GREY_BORDER = 'GREY_BORDER';
export const BLUE = 'BLUE';
export const BLUE_BORDER = 'BLUE_BORDER';
export const LIGHT_BLUE = 'LIGHT_BLUE';
export const VIOLET = 'VIOLET';

export const AVAILABLE = 'AVAILABLE';
export const CONNECTED = 'CONNECTED';
export const REQUESTED = 'REQUESTED';

// export const KOPIM_URL = 'kop.im/na/';
export const KOPIM_URL = `${window.location.origin}/kopim/`;
export const KASSA_URL = `${window.location.origin}/kassa/`;

export const MONEY_REQUEST = 'MONEY_REQUEST';
export const INVOICE = 'INVOICE';

export const FIXED_SUM = 'FIXED_SUM';
export const ANY_SUM = 'ANY_SUM';

export const CASHBOX = 'CASHBOX';
export const MONEYBOX = 'MONEYBOX';
export const COMPANY_BILL = 'COMPANY_BILL';

export const SZ_PAYMENT = 'SZ_PAYMENT';
// Yandex Money is deprecated
export const YANDEX_PAYMENT = 'YANDEX_PAYMENT';
export const WEBMONEY_PAYMENT = 'WEBMONEY_PAYMENT';
export const BANK_CARD_PAYMENT = 'BANK_CARD_PAYMENT';
export const QIWI_PAYMENT = 'QIWI_PAYMENT';
export const UMONEY_PAYMENT = 'UMONEY_PAYMENT';

export const GET_MONEY_ANIMATIONS = [
  {
    in: 350,
    out: 1000,
  },
  {
    in: 300,
    out: 1050,
  },
  {
    in: 250,
    out: 1100,
  },
  {
    in: 200,
    out: 1150,
  },
  {
    in: 150,
    out: 1200,
  },
  {
    in: 100,
    out: 1250,
  },
  {
    in: 1000,
    out: 350,
  },
  {
    in: 1050,
    out: 300,
  },
  {
    in: 1100,
    out: 250,
  },
  {
    in: 1150,
    out: 200,
  },
  {
    in: 1200,
    out: 150,
  },
  {
    in: 1250,
    out: 100,
  },
  {
    in: 250,
    out: 700,
  },
  {
    in: 200,
    out: 750,
  },
  {
    in: 150,
    out: 800,
  },
  {
    in: 100,
    out: 850,
  },
  {
    in: 700,
    out: 250,
  },
  {
    in: 750,
    out: 200,
  },
  {
    in: 800,
    out: 150,
  },
  {
    in: 850,
    out: 100,
  },
];

export const GET_MONEY_MAIN_ANIMATIONS_PARAMETERS = [
  {
    in: 70,
    out: 0,
  },
  {
    in: 70,
    out: 0,
  },
  {
    in: 0,
    out: 1,
  },
  {
    in: 1,
    out: 0,
  },
];

export const OPERATIONS_ACQUIRING_TAB = 'OPERATIONS_ACQUIRING_TAB';
export const ANALYTICS_ACQUIRING_TAB = 'ANALYTICS_ACQUIRING_TAB';
export const SHOP_STATUS_ACQUIRING_TAB = 'SHOP_STATUS_ACQUIRING_TAB';
export const PAYMENT_WAYS_ACQUIRING_TAB = 'PAYMENT_WAYS_ACQUIRING_TAB';
export const PAYMENT_PAGES_ACQUIRING_TAB = 'PAYMENT_PAGES_ACQUIRING_TAB';
export const SHOP_PARAMETERS_ACQUIRING_TAB = 'SHOP_PARAMETERS_ACQUIRING_TAB';

export const SHOPS_BG = ['var(--purpley)', '#FF8246', '#3490DE', '#3DCEBE'];
export const MERCHANT_STATUS_COLOR = [
  '#B0B5C4',
  'var(--light-mustard)',
  'var(--turquoise-blue)',
  'var(--reddish-pink)',
];

export const FIRST_STYLE = 'FIRST_STYLE';
export const SECOND_STYLE = 'SECOND_STYLE';
export const SMART_PAYMENT = 'SMART_PAYMENT';
export const WIDGET = 'WIDGET';

export const PAYED = 'PAYED';
export const IN_PROGRESS = 'IN_PROGRESS';
export const PROCESS = 'PROCESS';
export const PAY_BACK = 'PAY_BACK';
export const REJECTED = 'REJECTED';
export const PARTIAL_PAY_BACK = 'PARTIAL_PAY_BACK';
export const NOT_PAYED = 'NOT_PAYED';
export const CANCELED = 'CANCELED';

export const LOADING = 'LOADING';
export const SUCCESS = 'SUCCESS';
export const FAIL = 'FAIL';

export const WEEK = 'WEEK';
export const YEAR = 'YEAR';
export const MONTH = 'MONTH';
export const PERIOD = 'PERIOD';

export const AVERAGE_CHECK_STAT_MONTH = [
  { x: addDays(new Date(), -30), y: 200000 },
  { x: addDays(new Date(), -29), y: 58942 },
  { x: addDays(new Date(), -28), y: 10000 },
  { x: addDays(new Date(), -27), y: 500000 },
  { x: addDays(new Date(), -26), y: 1000 },
  { x: addDays(new Date(), -25), y: 1805 },
  { x: addDays(new Date(), -24), y: 85642 },
  { x: addDays(new Date(), -23), y: 598522 },
  { x: addDays(new Date(), -22), y: 158900 },
  { x: addDays(new Date(), -21), y: 15000 },
  { x: addDays(new Date(), -20), y: 10000 },
  { x: addDays(new Date(), -19), y: 10000 },
  { x: addDays(new Date(), -18), y: 100995 },
  { x: addDays(new Date(), -17), y: 8545 },
  { x: addDays(new Date(), -16), y: 69558 },
  { x: addDays(new Date(), -15), y: 125888 },
  { x: addDays(new Date(), -14), y: 100000 },
  { x: addDays(new Date(), -13), y: 100770 },
  { x: addDays(new Date(), -12), y: 125888 },
  { x: addDays(new Date(), -11), y: 890000 },
  { x: addDays(new Date(), -10), y: 1000000 },
  { x: addDays(new Date(), -9), y: 10000 },
  { x: addDays(new Date(), -8), y: 10000 },
  { x: addDays(new Date(), -7), y: 10000 },
  { x: addDays(new Date(), -6), y: 10000 },
  { x: addDays(new Date(), -5), y: 10000 },
  { x: addDays(new Date(), -4), y: 99900 },
  { x: addDays(new Date(), -3), y: 5500 },
  { x: addDays(new Date(), -2), y: 10000 },
  { x: addDays(new Date(), -1), y: 50000 },
  { x: new Date(), y: 50000 },
];
export const AVERAGE_CHECK_STAT_YEAR = [
  { x: 0, y: 1000000 },
  { x: 1, y: 1500000 },
  { x: 2, y: 10000 },
  { x: 3, y: 50000 },
  { x: 4, y: 1000 },
  { x: 5, y: 18000000 },
  { x: 6, y: 1000000 },
  { x: 7, y: 5900000 },
  { x: 8, y: 158900 },
  { x: 9, y: 1500000 },
  { x: 10, y: 1000000 },
  { x: 11, y: 1000000 },
];
export const AVERAGE_CHECK_STAT_WEEK = [
  { x: 0, y: 1000000 },
  { x: 1, y: 1500000 },
  { x: 2, y: 10000 },
  { x: 3, y: 50000 },
  { x: 4, y: 1000 },
  { x: 5, y: 18000 },
  { x: 6, y: 1000000 },
];

export const REFUNDS_STAT_MONTH = [
  { x: addDays(new Date(), -30), y: 0 },
  { x: addDays(new Date(), -29), y: 0 },
  { x: addDays(new Date(), -28), y: 10000 },
  { x: addDays(new Date(), -27), y: 50000 },
  { x: addDays(new Date(), -26), y: 0 },
  { x: addDays(new Date(), -25), y: 180895 },
  { x: addDays(new Date(), -24), y: 0 },
  { x: addDays(new Date(), -23), y: 598522 },
  { x: addDays(new Date(), -22), y: 0 },
  { x: addDays(new Date(), -21), y: 10000 },
  { x: addDays(new Date(), -20), y: 0 },
  { x: addDays(new Date(), -19), y: 0 },
  { x: addDays(new Date(), -18), y: 0 },
  { x: addDays(new Date(), -17), y: 0 },
  { x: addDays(new Date(), -16), y: 10258 },
  { x: addDays(new Date(), -15), y: 0 },
  { x: addDays(new Date(), -14), y: 98755 },
  { x: addDays(new Date(), -13), y: 0 },
  { x: addDays(new Date(), -12), y: 1000000 },
  { x: addDays(new Date(), -11), y: 890000 },
  { x: addDays(new Date(), -10), y: 80000 },
  { x: addDays(new Date(), -9), y: 5088 },
  { x: addDays(new Date(), -8), y: 0 },
  { x: addDays(new Date(), -7), y: 0 },
  { x: addDays(new Date(), -6), y: 0 },
  { x: addDays(new Date(), -5), y: 0 },
  { x: addDays(new Date(), -4), y: 0 },
  { x: addDays(new Date(), -3), y: 5500 },
  { x: addDays(new Date(), -2), y: 10000 },
  { x: addDays(new Date(), -1), y: 5000 },
  { x: new Date(), y: 50000 },
];
export const REFUNDS_STAT_YEAR = [
  { x: 0, y: 5500 },
  { x: 1, y: 852 },
  { x: 2, y: 98526 },
  { x: 3, y: 50000 },
  { x: 4, y: 1000 },
  { x: 5, y: 98562 },
  { x: 6, y: 1055 },
  { x: 7, y: 98742 },
  { x: 8, y: 158900 },
  { x: 9, y: 1500000 },
  { x: 10, y: 5900000 },
  { x: 11, y: 158900 },
];
export const REFUNDS_STAT_WEEK = [
  { x: 0, y: 15888 },
  { x: 1, y: 895 },
  { x: 2, y: 89745 },
  { x: 3, y: 6854 },
  { x: 4, y: 1000 },
  { x: 5, y: 85694 },
  { x: 6, y: 10000 },
];

export const REVENUES_STAT_MONTH = [
  { x: addDays(new Date(), -30), y: 15555 },
  { x: addDays(new Date(), -29), y: 85525 },
  { x: addDays(new Date(), -28), y: 95555 },
  { x: addDays(new Date(), -27), y: 10000 },
  { x: addDays(new Date(), -26), y: 874552 },
  { x: addDays(new Date(), -25), y: 180895 },
  { x: addDays(new Date(), -24), y: 84449 },
  { x: addDays(new Date(), -23), y: 598522 },
  { x: addDays(new Date(), -22), y: 15000 },
  { x: addDays(new Date(), -21), y: 10000 },
  { x: addDays(new Date(), -20), y: 10000 },
  { x: addDays(new Date(), -19), y: 89555 },
  { x: addDays(new Date(), -18), y: 8445 },
  { x: addDays(new Date(), -17), y: 15555 },
  { x: addDays(new Date(), -16), y: 10258 },
  { x: addDays(new Date(), -15), y: 20000 },
  { x: addDays(new Date(), -14), y: 98755 },
  { x: addDays(new Date(), -13), y: 98752 },
  { x: addDays(new Date(), -12), y: 5888 },
  { x: addDays(new Date(), -11), y: 95500 },
  { x: addDays(new Date(), -10), y: 12544 },
  { x: addDays(new Date(), -9), y: 5088 },
  { x: addDays(new Date(), -8), y: 87415 },
  { x: addDays(new Date(), -7), y: 2522 },
  { x: addDays(new Date(), -6), y: 4588 },
  { x: addDays(new Date(), -5), y: 0 },
  { x: addDays(new Date(), -4), y: 0 },
  { x: addDays(new Date(), -3), y: 5500 },
  { x: addDays(new Date(), -2), y: 10000 },
  { x: addDays(new Date(), -1), y: 5000 },
  { x: new Date(), y: 50000 },
];
export const REVENUES_STAT_YEAR = [
  { x: 0, y: 10000000 },
  { x: 1, y: 87945621 },
  { x: 2, y: 1500000 },
  { x: 3, y: 98565623 },
  { x: 4, y: 100000 },
  { x: 5, y: 874621 },
  { x: 6, y: 500000 },
  { x: 7, y: 98742 },
  { x: 8, y: 158900 },
  { x: 9, y: 1500000 },
  { x: 10, y: 5900000 },
  { x: 11, y: 9000000 },
];
export const REVENUES_STAT_WEEK = [
  { x: 0, y: 874621 },
  { x: 1, y: 4588 },
  { x: 2, y: 98560 },
  { x: 3, y: 1000085 },
  { x: 4, y: 985655 },
  { x: 5, y: 1400085 },
  { x: 6, y: 1800085 },
];

export const INITIAL_MERCHANT_STAT_MONTH = [
  { x: addDays(new Date(), -30), y: 0 },
  { x: addDays(new Date(), -29), y: 0 },
  { x: addDays(new Date(), -28), y: 0 },
  { x: addDays(new Date(), -27), y: 0 },
  { x: addDays(new Date(), -26), y: 0 },
  { x: addDays(new Date(), -25), y: 0 },
  { x: addDays(new Date(), -24), y: 0 },
  { x: addDays(new Date(), -23), y: 0 },
  { x: addDays(new Date(), -22), y: 0 },
  { x: addDays(new Date(), -21), y: 0 },
  { x: addDays(new Date(), -20), y: 0 },
  { x: addDays(new Date(), -19), y: 0 },
  { x: addDays(new Date(), -18), y: 0 },
  { x: addDays(new Date(), -17), y: 0 },
  { x: addDays(new Date(), -16), y: 0 },
  { x: addDays(new Date(), -15), y: 0 },
  { x: addDays(new Date(), -14), y: 0 },
  { x: addDays(new Date(), -13), y: 0 },
  { x: addDays(new Date(), -12), y: 0 },
  { x: addDays(new Date(), -11), y: 0 },
  { x: addDays(new Date(), -10), y: 0 },
  { x: addDays(new Date(), -9), y: 0 },
  { x: addDays(new Date(), -8), y: 0 },
  { x: addDays(new Date(), -7), y: 0 },
  { x: addDays(new Date(), -6), y: 0 },
  { x: addDays(new Date(), -5), y: 0 },
  { x: addDays(new Date(), -4), y: 0 },
  { x: addDays(new Date(), -3), y: 0 },
  { x: addDays(new Date(), -2), y: 0 },
  { x: addDays(new Date(), -1), y: 0 },
  { x: new Date(), y: 0 },
];
export const INITIAL_MERCHANT_STAT_YEAR = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 2, y: 0 },
  { x: 3, y: 0 },
  { x: 4, y: 0 },
  { x: 5, y: 0 },
  { x: 6, y: 0 },
  { x: 7, y: 0 },
  { x: 8, y: 0 },
  { x: 9, y: 0 },
  { x: 10, y: 0 },
  { x: 11, y: 0 },
];
export const INITIAL_MERCHANT_STAT_WEEK = [
  { x: 0, y: 0 },
  { x: 1, y: 0 },
  { x: 2, y: 0 },
  { x: 3, y: 0 },
  { x: 4, y: 0 },
  { x: 5, y: 0 },
  { x: 6, y: 0 },
];

export const MONEY_TRANSFER_STATUS_DELAY = 2 * 1000;
export const MONEY_TRANSFER_CONFIRMATION_PUSH_METHOD = 'PUSH_MOBILE';
export const MONEY_TRANSFER_CONFIRMATION_SMS_METHOD = 'SMS';

export const PARTNER_STATUS_NEW = 'NEW';
export const PARTNER_STATUS_ARCHIVE = 'ACHIVE';

/** Вид на жительство. */
export const IDENTIFICATION_DOCUMENT_RESIDENCE_PERMIT = 'RU12';
/** Имиграционная виза */
export const IDENTIFICATION_DOCUMENT_IMMIGRANT_VISA = 'RU98';
/** Виза */
export const IDENTIFICATION_DOCUMENT_VISA = 'RU99';
/** Разрешение на временное проживание в РФ */
export const IDENTIFICATION_DOCUMENT_TEMPORARY_RESIDENCE_PERMIT = 'RU15';
/** Удостоверение беженца */
export const IDENTIFICATION_DOCUMENT_REFUGEE_ID = 'RU13';
