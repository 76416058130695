import * as types from 'modules/authLegasy/actions/action-types';

const initialState = {
  /**
   * Этот флаг получили ли ответ от сервера по поводу текущео пользователя
   * используется только для того, чтобы показать Loader
   */
  isUserGot: false,

  /**
   * Этот флаг используется чтобы не позволять ходить на приватные роуты
   * апп автоматом редиректит с приватных роутов при на / при false
   * и с антиприватных на /lk при true
   */
  isUserAuth: false,

  /** Вся инфа по юзеру всегда тут */
  user: null,
  phone: '',
  password: '',
  email: '',
  /** Это флаг открытости меню */
  isMenuOpen: false,
  /** Это флаг того, что меню появилось на экране */
  isMenuOpenWithTransition: false,
  isHintOpen: false,
  smsVerification: {},
  INN: '',
  registrationStep: 0,
  isInnSentOnCheck: false,
  limits: null,
  redirectTo: null,
  startYear: 0,
  startZoneOffset: 0,
  startCurrentTime: 0,
  changedAuthStatus: '',
  szStatusFromDb: '',
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case types.GET_USER_SUCCESS:
      return {
        ...state,
        user: action.payload.user.data,
        isUserAuth: true,
        isUserGot: true,
        startYear: action.payload.user.data.startYear,
        startZoneOffset: action.payload.user.data.zoneOffset,
        startCurrentTime: action.payload.user.data.currentTime,
      };

    case types.GET_USER_FAIL:
      return {
        ...state,
        user: null,
        isUserAuth: false,
        isUserGot: true,
      };

    case types.SET_PHONE:
      return {
        ...state,
        phone: action.payload.phone,
      };

    case types.SET_USER_BALANCE:
      return {
        ...state,
        user: {
          ...state.user,
          clientAccounts: [
            {
              ...state.user.clientAccounts[0],
              balance: action.payload.userBalance,
            },
          ],
        },
      };

    case types.SET_NEW_USER_MESSAGES:
      return {
        ...state,
        user: {
          ...state.user,
          unreadMessages: state.user.unreadMessages ? state.user.unreadMessages + 1 : 1,
        },
      };

    case types.SET_CHANGED_TAX_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          taxStatus: {
            ...state.user.taxStatus,
            status: action.payload.taxStatus,
          },
        },
      };

    case types.SET_CHANGED_AUTH_STATUS:
      return {
        ...state,
        user: {
          ...state.user,
          group: action.payload.changedAuthStatus || state.user.group,
        },
        changedAuthStatus: action.payload.changedAuthStatus,
      };

    case types.SET_INN:
      return {
        ...state,
        INN: action.payload.INN,
      };

    case types.SET_PASSWORD:
      return {
        ...state,
        password: action.payload.password,
      };

    case types.SET_EMAIL:
      return {
        ...state,
        email: action.payload.email,
      };

    case types.REQUEST_SMS_SUCCESS:
      return {
        ...state,
        smsVerification: action.payload.data.data,
      };

    case types.START_RECOVERY_PASSWORD_SUCCESS:
      return {
        ...state,
        smsVerification: action.payload.data.data,
      };

    case types.SET_MENU_OPEN:
      return {
        ...state,
        isMenuOpen: action.payload.isMenuOpen,
      };

    case types.SET_MENU_OPEN_WITH_TRANSITION:
      return {
        ...state,
        isMenuOpenWithTransition: action.payload.isMenuOpen,
      };

    case types.SET_HINT_OPEN:
      return {
        ...state,
        isHintOpen: action.payload.isHintOpen,
        phone: '',
      };

    /**
     *  При установке пароля и до установки e-mail
     *  пользоаватель уже логинится, но показать это в интерфейсе
     *  нужно только на экране удачной регистрации
     */
    case types.FIRST_LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.payload.user.data,
        isUserAuth: false,
      };

    case types.LOG_IN_SUCCESS:
      return {
        ...state,
        user: action.payload.data.data,
        isUserAuth: true,
        isUserGot: true,
        password: '',
      };

    /**
     *  При переходе с экрана удачной регистрации
     *  ставим флаг авторизации
     */
    case types.LOG_IN_AFTER_REGISTRATION_SUCCESS:
      return {
        ...state,
        isUserAuth: true,
        isUserGot: true,
        redirectTo: action.payload.redirectTo,
        registrationStep: 0,
        password: '',
      };

    case types.REMOVE_REDIRECT:
      return {
        ...state,
        redirectTo: null,
      };

    case types.SAVE_EMAIL_SUCCESS:
      return {
        ...state,
      };

    case types.CHECK_INN_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          taxStatus: {
            ...state.user.taxStatus,
            status: action.payload.data.data.status,
          },
        },
      };

    case types.CHECK_INN_STATUS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          taxStatus: {
            ...state.user.taxStatus,
            status: action.payload.data.data.status,
          },
        },
      };

    case types.CHECK_INN_WITH_REQUEST_IN_FNS_SUCCESS:
      return {
        ...state,
        user: {
          ...state.user,
          taxStatus: {
            ...state.user.taxStatus,
            status: action.payload.data.data.status,
          },
        },
      };

    case types.LOG_OUT_SUCCESS:
      return {
        ...state,
        user: null,
        isUserAuth: false,
        isUserGot: true,
        registrationStep: 0,
        phone: '',
        password: '',
        email: '',
        INN: '',
        szStatusFromDb: '',
      };

    case types.SET_REGISTRATION_STEP:
      return {
        ...state,
        registrationStep: action.payload.registrationStep,
      };

    case types.GET_LIMITS_SUCCESS:
      return {
        ...state,
        limits: action.payload.limits.data,
      };

    case types.SET_SZ_STATUS_FROM_DB:
      return {
        ...state,
        szStatusFromDb: action.payload.szStatusFromDb,
      };

    default:
      return state;
  }
};

export default authReducer;
