/* eslint-disable import/prefer-default-export */
import styled, { css } from 'styled-components';
import { TitleText } from 'ui';
import { SIZE } from 'tools/constants';

export const TitleTextOfBlog = styled(TitleText)`
  display: block;
  margin-top: 14px;
  margin-bottom: 16px;
  ${({ size }) =>
    (size === SIZE.hr || size === SIZE.lg) &&
    css`
      margin-top: 16px;

      @media screen and (min-width: 715px) {
        margin-bottom: 30px;
      }
    `}
`;
