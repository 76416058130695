import React from 'react';
import PropTypes from 'prop-types';
import { Redirect, useParams } from 'react-router-dom';
import { connect } from 'react-redux';

import Card from '../Card/Card';
import CircleProgress from 'modules/authLegasy/components/CircleProgress/CircleProgress';
import CardHeaderSz from '../CardHeaderSz/CardHeaderSz';
import CardHeaderQiwi from '../CardHeaderQiwi/CardHeaderQiwi';
import CardHeaderUMoney from '../CardHeaderUMoney/CardHeaderUMoney';
import CardHeaderWebMoney from '../CardHeaderWebMoney/CardHeaderWebMoney';
import CardHeaderSBP from '../CardHeaderSBP/CardHeaderSBP';

import { ReactComponent as IconPayment } from 'static/assets/icon-payment-process.svg';

import { resetMoneyTransferSteps } from 'modules/money-transfer/actions';

import './StatusErrorCard.scss';

const StatusErrorCard = ({ dispatch }) => {
  const { paymentMethod } = useParams();
  const [canRedirectToLk, setCanRedirectToLk] = React.useState(false);

  function redirectToLk() {
    setCanRedirectToLk(true);
  }

  function retry() {
    dispatch(resetMoneyTransferSteps());
  }

  if (canRedirectToLk) {
    return <Redirect to="/lk" />;
  }

  return (
    <div className="money-transfer-status-error-card">
      <Card className="money-transfer-status-error-card__card">
        <header className="money-transfer-status-error-card__header">
          {paymentMethod === 'bankcard' && <CardHeaderSz />}
          {paymentMethod === 'sz' && <CardHeaderSz />}
          {paymentMethod === 'sbp' && <CardHeaderSBP />}
          {paymentMethod === 'webmoney' && <CardHeaderWebMoney />}
          {paymentMethod === 'qiwi' && <CardHeaderQiwi />}
          {paymentMethod === 'umoney' && <CardHeaderUMoney />}
        </header>

        <div className="money-transfer-status-error-card__content">
          <div className="money-transfer-status-error-card__content-loader">
            <CircleProgress
              className="money-transfer-status-error-card__content-loader-circle"
              color="var(--reddish-pink)"
              value={0}
            />
            <IconPayment className="money-transfer-status-error-card__content-loader-icon" />
          </div>
          <div className="money-transfer-status-error-card__content-message">
            Упс. Что-то пошло не так и мы не смогли совершить платеж. Если проблема не будет решена,
            напишите нам
          </div>
        </div>
      </Card>

      <div className="money-transfer-status-error-card__nav">
        <button className="money-transfer-status-error-card__nav-lk-button" onClick={redirectToLk}>
          Вернуться в кошелек
        </button>
        <button className="money-transfer-status-error-card__nav-retry-button" onClick={retry}>
          Попробовать снова
        </button>
      </div>
    </div>
  );
};

StatusErrorCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
};

export default connect()(StatusErrorCard);
