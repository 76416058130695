import { createSelector } from '@reduxjs/toolkit';
import { shallowEqual, useSelector } from 'react-redux';

export const getUserLimits = () => (state) => {
  const limits = state.auth.limits || [];
  const userGroup = state.auth.user ? state.auth.user.group : '';

  return (
    limits.find((item) => item.groupCode === userGroup) || {
      maxSum: 0,
      minSum: 0,
      balanceLimit: 0,
    }
  );
};

// получить список новых патнеров
export const getPartnerRequestList = () => (state) => {
  return state.profile.partnerRequestList;
};

// получить список всех партнеров, исключая "новые"
export const getPartnerList = () => (state) => {
  return state.profile.partnerList;
};

/**
 * HOOK usePartnerIdSelector
 * @param {number} idPartnership - ID партнерства
 * @return {number} - ID партнера
 */
export const usePartnerIdSelector = (platformName, idPartnership) =>
  useSelector(
    createSelector(
      (state) => {
        return state.profile.partnerList.filter(
          (partnerItem) =>
            partnerItem.id === idPartnership && partnerItem.partner.platform === platformName,
        )[0]?.partner.id;
      },
      (partnerId) => partnerId,
    ),
    shallowEqual,
  );
