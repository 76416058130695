import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';

import { GetIdentificationOfLetterBlogWidget } from 'modules/profile';

export const GetIdentificationOfLetterTemplate = () => {
  const match = useRouteMatch();
  const { path } = match;
  return (
    <>
      <Switch>
        <Route path={path}>
          <GetIdentificationOfLetterBlogWidget />
        </Route>
      </Switch>
    </>
  );
};
