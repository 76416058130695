import useToggleAndOutClick from 'hooks/useToggleAndOutClick';
import PropTypes from 'prop-types';
import React from 'react';
import { CSSTransition } from 'react-transition-group';

import Hint from 'modules/shared/components/Hint/Hint';
import HintHowToChangeData from 'modules/profileLegasy/components/Hint/HintHowToChangeData';
import OpenPopupButton from 'modules/shared/components/OpenPopupButton/OpenPopupButton';

import { HINT_TIMEOUTS } from 'constants/index.js';

const ChangePersonalDataWrap = ({ isMenuOpen }) => {
  const [
    isDropChangeDataHintOpened,
    dropElChangeDataHint,
    toggleDropChangeDataHint,
    closeExplicitlyChangeDataHint,
  ] = useToggleAndOutClick();

  return (
    <>
      <OpenPopupButton
        onClick={toggleDropChangeDataHint}
        title="Как изменить данные?"
        className="profile-settings-personal-data-tab__hint-item"
      />

      {/* ПОПАП - КАК ИЗМЕНИТЬ ДАННЫЕ */}
      <CSSTransition
        in={isDropChangeDataHintOpened}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
        classNames="hint-auth"
      >
        <Hint
          ref={dropElChangeDataHint}
          onCloseBtnClick={closeExplicitlyChangeDataHint}
          className="profile-settings__change-data-sidebar"
          classNameInner="profile-settings__change-data-inner"
          title="Как изменить данные?"
          isMenuOpen={isMenuOpen}
        >
          <HintHowToChangeData />
        </Hint>
      </CSSTransition>
      {/* /ПОПАП - КАК ИЗМЕНИТЬ ДАННЫЕ */}
    </>
  );
};

ChangePersonalDataWrap.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
};

export default ChangePersonalDataWrap;
