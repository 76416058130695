import useWindowSize from '@rehooks/window-size';
import { AccountsTypeTemplate } from './templates';
import PropTypes from 'prop-types';
import React from 'react';
import { Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';
import {
  ProfileNavigationWidget,
  MobileDropdownNavigationWidget,
  TaxHoldWidget,
} from 'modules/profile';

import { DocumentsTabledWidget, IntegrationWidget } from 'modules/profile/widgets';
import ProfileSettingsPartnersTab from 'modules/profileLegasy/components/ProfileSettingsPartnersTab/ProfileSettingsPartnersTab';
import ProfileSettingsPersonalDataTab from 'modules/profileLegasy/components/ProfileSettingsPersonalDataTab/ProfileSettingsPersonalDataTab';
import ProfileSettingsProfileTab from 'modules/profileLegasy/components/ProfileSettingsProfileTab/ProfileSettingsProfileTab';
import ProfileSettingsSzTab from 'modules/profileLegasy/components/ProfileSettingsSzTab/ProfileSettingsSzTab';

import { NAV_LIST } from './tools/constants';
import styles from './style.module.scss';

const Profile = () => {
  const windowSize = useWindowSize();

  return (
    <div className={styles.profileSettings} data-testid="profile-settings">
      <MobileDropdownNavigationWidget
        navList={NAV_LIST}
        navigationComponent={ProfileNavigationWidget}
      />
      <div className={styles.container}>
        {/* НАВИГАЦИЯ ДЕСКТОП*/}
        {windowSize.innerWidth > 980 && <ProfileNavigationWidget navList={NAV_LIST} />}

        {/* ОСНОВНОЙ КОНТЕНТ */}
        <div className={styles.main}>
          <Switch>
            <Route exact path="/lk/profile-settings/" component={ProfileSettingsProfileTab} />
            <Route
              path="/lk/profile-settings/personal"
              component={ProfileSettingsPersonalDataTab}
            />
            <Route path="/lk/profile-settings/account-type" component={AccountsTypeTemplate} />
            <Route path="/lk/profile-settings/status" component={ProfileSettingsSzTab} />
            <Route path="/lk/profile-settings/partners" component={ProfileSettingsPartnersTab} />
            <Route path="/lk/profile-settings/documents" component={DocumentsTabledWidget} />
            <Route path="/lk/profile-settings/integration" component={IntegrationWidget} />
            <Route path="/lk/profile-settings/tax-hold" component={TaxHoldWidget} />
            <Route component={ProfileSettingsProfileTab} />
          </Switch>
        </div>
        {/* /ОСНОВНОЙ КОНТЕНТ */}
      </div>
    </div>
  );
};

Profile.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  profileSettingsActiveTab: PropTypes.string.isRequired,
};

const mapStateToProps = (state) => ({
  profileSettingsActiveTab: state.profile.profileSettingsActiveTab,
  isMenuOpen: state.auth.isMenuOpen,
});

export const ProfileTemplate = connect(mapStateToProps)(Profile);
