import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';
import useWindowSize from '@rehooks/window-size';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import checkImg from 'static/assets/check-img.png';
import manualSzStatus from 'static/assets/manual-sz-status.png';
import shape1 from 'static/assets/shape-1.png';
import shape3 from 'static/assets/shape-3-small.png';
import shape4 from 'static/assets/shape-4.png';

const ManualPageStepRegistrationForRf = ({
  returnBack,
  setStepGoToMyTax1,
  setStepGoToWebVersion,
}) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">
            Регистрация
            <br />
            для граждан РФ
          </h1>

          {windowSize.innerWidth > 760 && (
            <>
              <img src={checkImg} className="manual-page__check" alt="" />

              <img src={manualSzStatus} className="manual-page__status" alt="" />

              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_1" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_2" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_3" />
              <img src={shape1} alt="" className="manual-page__shape manual-page__shape_4" />

              <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_1" />
              <img src={shape4} alt="" className="manual-page__ruble manual-page__ruble_2" />
              <img src={shape3} alt="" className="manual-page__ruble manual-page__ruble_3" />
            </>
          )}
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <div className="manual-page__text">
            Чтобы стать самозанятым, нужно пройти регистрацию. Это быстро и не требует посещения
            инспекции. Доступно несколько способов регистрации: в мобильном приложении «Мой налог»,
            а также в его веб-версии в случае отсутствия смартфона или через уполномоченные банки.
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="Через приложение"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToMyTax1}
              bgType={BLUE_BORDER}
            />

            <StandardButton
              title="Через браузер"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToWebVersion}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepRegistrationForRf.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToMyTax1: PropTypes.func.isRequired,
  setStepGoToWebVersion: PropTypes.func.isRequired,
};

export default ManualPageStepRegistrationForRf;
