import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';

// Components
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

// Misc
import { formatDateHuman } from 'helpers';
import formatNumber from 'helpers/formatNumber';

// Styles
import './IncomeRegistrationSummary.scss';

const IncomeRegistrationSummary = ({
  className,
  purpose,
  buyer,
  date,
  sum,
  onSubmit,
  onBackClick,
  isLoading,
}) => {
  return (
    <div
      className={cx('income-registration-summary', {
        [className]: className,
      })}
    >
      <div className="income-registration-summary__list">
        {purpose && (
          <div className="income-registration-summary__item">
            <p className="income-registration-summary__item-label">Услуга:</p>
            <p className="income-registration-summary__item-text">{purpose}</p>
          </div>
        )}

        {buyer && (
          <div className="income-registration-summary__item">
            <p className="income-registration-summary__item-label">Покупатель:</p>
            <p className="income-registration-summary__item-text">{buyer}</p>
          </div>
        )}

        {date && (
          <div className="income-registration-summary__item">
            <p className="income-registration-summary__item-label">Дата оказания:</p>
            <p className="income-registration-summary__item-text">{formatDateHuman(date)}</p>
          </div>
        )}

        {sum && (
          <div className="income-registration-summary__item">
            <p className="income-registration-summary__item-label">Сумма:</p>
            <p className="income-registration-summary__item-text">{`${formatNumber(sum)} ₽`}</p>
          </div>
        )}
      </div>

      <div className="income-registration-summary__button-wrap">
        <button
          className="income-registration-summary__button income-registration-summary__button_stroked"
          type="button"
          onClick={onBackClick}
          disabled={isLoading}
        >
          Вернуться назад
        </button>

        <button
          className="income-registration-summary__button"
          type="button"
          onClick={onSubmit}
          disabled={isLoading}
        >
          {isLoading ? (
            <CircleLoader className="income-registration-summary__button-loader" />
          ) : (
            'Зарегистрировать доход'
          )}
        </button>
      </div>
    </div>
  );
};

IncomeRegistrationSummary.propTypes = {
  className: PropTypes.string,
  purpose: PropTypes.string,
  buyer: PropTypes.string,
  date: PropTypes.instanceOf(Date).isRequired,
  sum: PropTypes.string.isRequired,
  onSubmit: PropTypes.func.isRequired,
  onBackClick: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
};

IncomeRegistrationSummary.defaultProps = {
  className: null,
  purpose: null,
  buyer: null,
};

export default IncomeRegistrationSummary;
