import cx from 'classnames';
import PropTypes from 'prop-types';
import React from 'react';
import AriaModal from 'react-aria-modal';

import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

import './SmallPopupWithEmoji.scss';

const SmallPopupWithEmoji = ({
  className,
  closePopup,
  title,
  children,
  emoji,
  mounted,
  buttonArray,
  isLoading,
}) => {
  return (
    <AriaModal
      underlayClass={className}
      onExit={closePopup}
      mounted={mounted}
      titleText=" "
      underlayColor="rgba(10, 17, 37, 0.5)"
    >
      <div className="small-popup-with-emoji__popup" data-testid="small-popup-with-emoji-popup">
        {isLoading ? (
          <div className="small-popup-with-emoji__loader">
            <CircleLoader />
          </div>
        ) : (
          <>
            <h2 className="small-popup-with-emoji__title">{title}</h2>

            <div className="small-popup-with-emoji__icon-wrap">
              <img src={emoji} alt="" className="small-popup-with-emoji__icon" />
            </div>

            <div className="small-popup-with-emoji__text" data-testid="small-popup-with-emoji-text">
              {children}
            </div>

            <div className="small-popup-with-emoji__button-list">
              {buttonArray &&
                Array.isArray(buttonArray) &&
                buttonArray.length >= 1 &&
                buttonArray.map(item => (
                  <button
                    key={item.id}
                    className={cx('small-popup-with-emoji__button', {
                      // colorType может быть:
                      // - grey-border
                      // - red-border
                      // - blue-border
                      // - blue
                      [`small-popup-with-emoji__button_${item.colorType}`]: item.colorType,
                    })}
                    type="button"
                    onClick={item.action}
                    data-testid="small-popup-with-emoji-button"
                  >
                    {item.isLoading ? <CircleLoader /> : <>{item.title}</>}
                  </button>
                ))}
            </div>
            <button
              className="small-popup-with-emoji__close-button"
              onClick={closePopup}
              type="button"
              data-testid="small-popup-with-emoji-close-button"
            >
              <IconClose className="small-popup-with-emoji__close-button-icon" />
            </button>
          </>
        )}
      </div>
    </AriaModal>
  );
};

SmallPopupWithEmoji.propTypes = {
  className: PropTypes.string,
  mounted: PropTypes.bool,
  children: PropTypes.node,
  closePopup: PropTypes.func.isRequired,
  title: PropTypes.string,
  emoji: PropTypes.string.isRequired,
  buttonArray: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      title: PropTypes.string,
      action: PropTypes.func,
      colorType: PropTypes.string,
    }),
  ).isRequired,
  isLoading: PropTypes.bool,
};

SmallPopupWithEmoji.defaultProps = {
  className: null,
  title: null,
  children: null,
  mounted: false,
  isLoading: false,
};

export default SmallPopupWithEmoji;
