import { getMoneybox } from 'api/moneybox';
import PropTypes from 'prop-types';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { Prompt, Redirect, useLocation, useParams } from 'react-router-dom';

import { getSaveMoneyTransactionOperationStatus } from 'modules/cashbox/actions';
import SaveMoneyPreview from 'modules/cashbox/components/SaveMoneyPreview/SaveMoneyPreview';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';

import { getDaysLeft } from 'helpers/date';

// Styles
import './SaveMoney.scss';
import { maxSumDefault } from '../../../../tools/constants';

const STATUS_WAIT = 'wait';
const STATUS_ERROR = 'error';

const SaveMoney = ({ user }) => {
  const location = useLocation();
  const { operationId, moneyboxId } = useParams();

  const [isUserBlocked, setIsUserBlocked] = useState(false);
  const [isReplenishmentBlocked, setIsReplenishmentBlocked] = useState(false);
  const [isNotAvailable, setIsNotAvailable] = useState(false);

  const [error, setError] = useState('');
  const [moneybox, setMoneybox] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const [isSending, setIsSending] = useState(false);

  const [redirectToErrorPage, setRedirectToErrorPage] = useState('');
  const [redirectToSuccessPage, setRedirectToSuccessPage] = useState('');

  const isMoneyTransferOk =
    location.pathname.indexOf(`/pay-it/moneybox/${operationId}/success`) > -1;
  const isMoneyTransferFail =
    location.pathname.indexOf(`/pay-it/moneybox/${operationId}/error`) > -1 ||
    location.pathname === '/pay-it/moneybox/error' ||
    location.pathname === `/pay-it/moneybox/${moneyboxId}/error`;

  const [moneyBoxId, setMoneyBoxId] = useState(moneyboxId);
  const [amountFromLocalStorage, setAmountFromLocalStorage] = useState(0);

  const getSaveMoneyById = () => {
    setIsLoading(true);

    getMoneybox(moneyBoxId)
      .then((res) => {
        try {
          if (!res.data) {
            throw new Error();
          }
          setMoneybox(res.data);

          if (res.data && res.data.clientBlockedByAdmin) {
            setIsUserBlocked(true);
          } else if (res.data && res.data.clientBlockedReceipt) {
            setIsReplenishmentBlocked(true);
          } else if (res.data && !res.data.available) {
            setIsNotAvailable(true);
          }

          window.sessionStorage.setItem(
            'moneyboxData',
            JSON.stringify({ ...res.data, moneyBoxId }),
          );
          setIsLoading(false);
        } catch (_error) {
          setError('К сожалению, копилка с таким идентификатором не найдена');
        }
      })
      .catch(() => {
        setError('К сожалению, копилка с таким идентификатором не найдена');
      })
      .then(() => {
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (!moneyBoxId) {
      return;
    }

    if (
      location.pathname.indexOf('/pay-it/moneybox/') <= -1 ||
      location.pathname === `/pay-it/moneybox/${moneyboxId}/error`
    ) {
      getSaveMoneyById();
    } else if (window.sessionStorage && window.sessionStorage.getItem('moneyboxData')) {
      const moneyboxData = JSON.parse(window.sessionStorage.getItem('moneyboxData'));
      setMoneybox(moneyboxData);
    } else {
      getSaveMoneyById();
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [moneyBoxId, location.pathname, window.sessionStorage]);
  /* eslint-enable react-hooks/exhaustive-deps */

  const updateTimeout = useRef(null);

  const updateOperationStatus = useCallback(() => {
    if (!(operationId && location.pathname.indexOf(`/pay-it/moneybox/${operationId}/wait`) > -1)) {
      clearTimeout(updateTimeout.current);
      return;
    }

    setIsSending(true);
    getSaveMoneyTransactionOperationStatus(operationId)
      .then((res) => {
        if (res && res.data) {
          setMoneyBoxId(res.data.uuid);

          if (res.data.state === STATUS_WAIT) {
            updateTimeout.current = setTimeout(updateOperationStatus, 1000);
          } else if (res.data.state === STATUS_ERROR) {
            setRedirectToErrorPage(`/pay-it/moneybox/${operationId}/error/${res.data.uuid}`);
          } else {
            setRedirectToSuccessPage(`/pay-it/moneybox/${operationId}/success/${res.data.uuid}`);
          }

          if (res.data.transactionStateMessage) {
            window.sessionStorage.setItem(
              'transactionStateMessage',
              res.data.transactionStateMessage,
            );
          }
        }
      })
      .catch(() => {
        setRedirectToErrorPage(`/pay-it/moneybox/${operationId}/error`);
      });
  }, [location.pathname, operationId]);

  /** Узнаем результаты проведения операции при нахождении на /wait/ */
  useEffect(() => {
    updateOperationStatus();
    return () => {
      clearTimeout(updateTimeout.current);
    };
  }, [updateOperationStatus, location.pathname, operationId]);

  const onBeforeUnload = (e) => {
    // Cancel the event
    e.preventDefault();
    // Chrome requires returnValue to be set
    e.returnValue = '';
  };

  /** Предупреждаем юзера, что при перезагрузке страницы он может потерять несохраненные данные */
  useEffect(() => {
    if (isSending) {
      window.addEventListener('beforeunload', onBeforeUnload);
    }

    return () => {
      window.removeEventListener('beforeunload', onBeforeUnload);
    };
  });

  useEffect(() => {
    if (window && window.sessionStorage) {
      setAmountFromLocalStorage(+window.sessionStorage.getItem('moneyBoxTransferAmount'));
    }
  }, []);

  if (redirectToErrorPage) {
    return <Redirect to={redirectToErrorPage} />;
  }

  if (redirectToSuccessPage) {
    return <Redirect to={redirectToSuccessPage} />;
  }

  if (error) {
    return (
      <div className="money-request-container__error">
        <p className="money-request-container__error-text">{error}</p>
      </div>
    );
  }

  return (
    <div className="save-money">
      {/* ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}
      <Prompt
        when={isSending}
        message="Операция выполняется, вы уверены что хотите уйти со страницы не дожидаясь результата?"
      />
      {/* /ИЗБЕЖАНИЕ УХОДА НА ДРУГУЮ СТРАНИЦУ БЕЗ ПОДТВЕРЖДЕНИЯ */}

      {isLoading ? (
        <div className="save-money__loader">
          <CircleLoader />
        </div>
      ) : (
        <SaveMoneyPreview
          moneyboxId={moneyboxId}
          isPreview={false}
          colorTheme={+moneybox.background}
          daysLeft={getDaysLeft(moneybox.dateEndPayment)}
          purpose={moneybox.name}
          commentary={moneybox.commentary}
          isUserLoggedIn={Boolean(user)}
          sumHint={moneybox.sumHint}
          sum={moneybox.sum}
          maxLimitSum={moneybox.maxLimitSum || maxSumDefault}
          needFio={moneybox.needFio}
          needPhone={moneybox.needPhone}
          needEmail={moneybox.needEmail}
          avatar={moneybox.avatarInBase64}
          fio={moneybox.fio !== 'null null' ? moneybox.fio : 'Анонимный пользователь'}
          loginLastChars={moneybox.loginLastChars}
          isMoneyTransferOk={isMoneyTransferOk}
          isMoneyTransferFail={isMoneyTransferFail}
          isSending={isSending}
          urlForRepeatPayment={`/kopim/${moneyboxId || moneyBoxId}` || null}
          amountFromLocalStorage={amountFromLocalStorage}
          isMoneyCollectingFinished={new Date(moneybox.dateEndPayment) <= new Date()}
          isUserBlocked={isUserBlocked}
          isNotAvailable={isNotAvailable}
          isReplenishmentBlocked={isReplenishmentBlocked}
        />
      )}
    </div>
  );
};

SaveMoney.propTypes = {
  user: PropTypes.shape({}),
};

SaveMoney.defaultProps = {
  user: null,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
});

export default connect(mapStateToProps)(SaveMoney);
