import React from 'react';
import PropTypes from 'prop-types';
import { Link, Redirect } from 'react-router-dom';

import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import { ReactComponent as LogoResponsive } from 'static/assets/logo-responsive.svg';
import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';

import styles from './Header.module.scss';

const Header = ({ onClose }) => {
  const [isRedirected, setIsRedirected] = React.useState(false);

  function onCloseClick() {
    if (onClose) {
      return onClose();
    }

    setIsRedirected(true);
  }

  if (isRedirected) {
    return <Redirect to="/lk/profile-settings" />;
  }

  return (
    <div className={styles.header}>
      <div className={styles.headerDesktop}>
        <PopupHeaderWithBalance />
        <PopupCloseWithText closePopupHandler={onCloseClick} />
      </div>
      <div className={styles.headerMobile}>
        <Link to="/" className={styles.headerLink}>
          <LogoResponsive className={styles.headerLogo} />
        </Link>
        <button className={styles.headerCloseButton} onClick={onCloseClick}>
          <IconClose className={styles.headerCloseButtonIcon} />
        </button>
      </div>
    </div>
  );
};

Header.propTypes = {
  onClose: PropTypes.func,
};

export default Header;
