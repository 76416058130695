import useWindowSize from '@rehooks/window-size';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { CSSTransition } from 'react-transition-group';

import ReturnButton from 'modules/shared/components/ReturnButton/ReturnButton';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { BLUE_BORDER } from 'constants/index';

import screen1 from 'static/assets/manual-screen-1.png';
import phone8 from 'static/assets/phone-8.png';

const ManualPageStepWebVersionLk = ({ returnBack, setStepGoToWallet }) => {
  const [isRendered, setIsRendered] = useState(false);
  const windowSize = useWindowSize();

  const webLkRegistrationList = [
    {
      id: 1,
      text:
        'Укажите ИНН и пароль, который вы используете для доступа в личный кабинет физлица на сайте nalog.ru.',
    },
    {
      id: 2,
      text: (
        <>
          Нажмите
          <b> Далее.</b>
        </>
      ),
    },
  ];

  useEffect(() => {
    setIsRendered(true);
  }, []);

  return (
    <div className="manual-page__content-wrap">
      <CSSTransition
        classNames="animation-manual"
        in={isRendered}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <ReturnButton onClick={returnBack} className="manual-page__return-link" />

          <h1 className="manual-page__title">Регистрация через ЛК</h1>

          {windowSize.innerWidth <= 760 && (
            <div className="manual-page__images-container">
              <img src={phone8} alt="" className="manual-page__phone manual-page__phone_8" />
              <img src={screen1} alt="" className="manual-page__screen manual-page__screen_1" />
            </div>
          )}

          <ol className="manual-page__text-list">
            {webLkRegistrationList.map(item => (
              <li className="manual-page__list-item" key={item.id}>
                <p className="manual-page__list-item-number">{item.id}</p>
                <div className="manual-page__list-item-text">{item.text}</div>
              </li>
            ))}
          </ol>

          <div className="manual-page__text">
            Что делать, если у вас еще нет личного кабинета на nalog.ru можно узнать {'  '}
            <a
              href="https://www.nalog.ru/rn77/fl/interest/lk-account/"
              target="_blank"
              rel="noopener noreferrer"
              className="manual-page__text-link"
            >
              тут
            </a>
            .
          </div>

          <div className="manual-page__button-list">
            <StandardButton
              title="Далее"
              isLink={false}
              className="manual-page__button-item"
              onClick={setStepGoToWallet}
              bgType={BLUE_BORDER}
            />
          </div>
        </div>
      </CSSTransition>

      <CSSTransition
        classNames="animation-manual"
        in={isRendered && windowSize.innerWidth > 760}
        timeout={{ enter: 100, exit: 100, enterDelay: 100 }}
        unmountOnExit
      >
        <div className="manual-page__col">
          <img src={phone8} alt="" className="manual-page__phone manual-page__phone_8" />
          <img src={screen1} alt="" className="manual-page__screen manual-page__screen_1" />
        </div>
      </CSSTransition>
    </div>
  );
};

ManualPageStepWebVersionLk.propTypes = {
  returnBack: PropTypes.func.isRequired,
  setStepGoToWallet: PropTypes.func.isRequired,
};

export default ManualPageStepWebVersionLk;
