import cx from 'classnames';
import Hammer from 'hammerjs';
import PropTypes from 'prop-types';
import React, { useEffect, useRef } from 'react';

// Icons
import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';

// Styles
import './QuarterNavigation.scss';

const QuarterNavigation = props => {
  const {
    handlePrevQuarterClick,
    handleNextQuarterClick,
    year,
    className,
    isLoading,
    isPrevButtonDisabled,
    isNextButtonDisabled,
    quarter,
  } = props;

  const container = useRef(null);

  const romaDigits = {
    1: 'I',
    2: 'II',
    3: 'III',
    4: 'IV',
  };

  /** Навешиваем события свайпа */
  useEffect(() => {
    const ref = container.current;
    let manager;
    if (ref) {
      manager = new Hammer.Manager(ref);
      const swipe = new Hammer.Swipe();
      manager.add(swipe);

      manager.on('swipe', e => {
        const direction = e.offsetDirection;
        if (direction === 2 && !isPrevButtonDisabled) {
          handlePrevQuarterClick();
        }
        if (direction === 4 && !isNextButtonDisabled) {
          handleNextQuarterClick();
        }
      });
    }

    return () => {
      if (ref && manager) {
        manager.off('swipe');
      }
    };
  }, [handleNextQuarterClick, handlePrevQuarterClick, isPrevButtonDisabled, isNextButtonDisabled]);

  return (
    <div
      className={cx('quarter-navigation', {
        [className]: className,
      })}
      ref={container}
    >
      <button
        className={cx('quarter-navigation__btn quarter-navigation__btn_prev', {
          'quarter-navigation__btn_invisible': isPrevButtonDisabled,
        })}
        type="button"
        onClick={handlePrevQuarterClick}
        disabled={isLoading || isPrevButtonDisabled}
        aria-label="Предыдущий квартал"
      >
        <IconArrow className="quarter-navigation__btn-icon" />
      </button>
      <p className="quarter-navigation__date">
        {year} - {romaDigits[quarter]} Квартал
      </p>
      <button
        className={cx('quarter-navigation__btn quarter-navigation__btn_next', {
          'quarter-navigation__btn_invisible': isNextButtonDisabled,
        })}
        type="button"
        onClick={handleNextQuarterClick}
        disabled={isLoading || isNextButtonDisabled}
        aria-label="Следующий квартал"
      >
        <IconArrow className="quarter-navigation__btn-icon" />
      </button>
    </div>
  );
};

QuarterNavigation.propTypes = {
  handlePrevQuarterClick: PropTypes.func.isRequired,
  handleNextQuarterClick: PropTypes.func.isRequired,
  year: PropTypes.number.isRequired,
  quarter: PropTypes.number.isRequired,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isPrevButtonDisabled: PropTypes.bool,
  isNextButtonDisabled: PropTypes.bool,
};

QuarterNavigation.defaultProps = {
  className: null,
  isLoading: false,
  isPrevButtonDisabled: false,
  isNextButtonDisabled: false,
};

export default QuarterNavigation;
