import cx from 'classnames';

/* eslint-disable import/prefer-default-export */
export const selectStyles = {
  menuPortal: provided => ({
    ...provided,
    zIndex: 9999,
  }),
  container: provided => ({
    ...provided,
    height: 60,
  }),
  control: provided => ({
    ...provided,
    border: '1px solid var(--dark-slate-blue)',
    boxShadow: 'none',
    borderRadius: 8,
  }),
  indicatorSeparator: provided => ({
    ...provided,
    display: 'none',
  }),
  singleValue: provided => ({
    ...provided,
    color: 'var(--dark-slate-blue)',
    fontSize: 18,
    fontFamily: `'Circe-Regular', sans-serif`,
  }),
  dropdownIndicator: provided => ({
    ...provided,
    color: 'var(--dark-slate-blue)',
  }),
  option: (provided, state) => ({
    ...provided,
    fontSize: 18,
    color: cx({
      white: state.isSelected && !state.isDisabled,
      black: !state.isSelected && !state.isDisabled,
      '#cccccc': state.isDisabled,
    }),
    fontFamily: `'Circe-Regular', sans-serif`,
  }),
  menuList: provided => ({
    ...provided,
    maxHeight: 240,
  }),
};
export const selectStylesCondensed = {
  ...selectStyles,
  dropdownIndicator: provided => ({
    ...provided,
    color: '#4A90E2',
    padding: '0 4px 0 0',
  }),
};
