import { getScrollbarWidth } from 'helpers';
import React, { useEffect } from 'react';
import { Provider } from 'react-redux';

import store from 'store/store';
import { TextDecoder, TextEncoder } from 'text-encoding';

import { AppContainer } from './AppContainer';

export const App = () => {
  document.documentElement.style.setProperty('--scroll-bar-width', `${getScrollbarWidth()}px`);
  /** Фикс для Edge - для stomp */
  useEffect(() => {
    if (!window.TextDecoder) window.TextDecoder = TextDecoder;
    if (!window.TextEncoder) window.TextEncoder = TextEncoder;
  }, []);

  return (
    <Provider store={store}>
      <AppContainer />
    </Provider>
  );
};
