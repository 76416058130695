import React from 'react';
import { Switch, Route } from 'react-router-dom';

import BusinessCardContainer from 'modules/profileLegasy/components/BusinessCardContainer/BusinessCardContainer';

/**
 * ШАБЛОН: ССЫЛКА-ВИЗИТКА
 */
export const BusinessCardTemplate = () => (
  <Switch>
    <Route
      exact
      path={[
        '/to/:uniqueId', //САМА ССЫЛКА-ВИЗИТКА
        '/apple-pay-business-card/:uniqueId', //ССЫЛКА-ВИЗИТКА с APPLE PAY
        '/receipt/businesscard/:operationId/wait', //ПРОВЕРКА СТАТУСА ПЕРЕВОДА
        '/receipt/businesscard/:operationId/success', //ЭКРАН УСПЕШНОГО ПЕРЕВОДА
        '/receipt/businesscard/error', //ЭКРАН ОШИБКИ ПЕРЕВОДА
      ]}
    >
      <BusinessCardContainer />
    </Route>
  </Switch>
);
