import useWindowSize from '@rehooks/window-size';
import { getCashBoxContext, getShowCase } from 'api/cashbox';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { connect } from 'react-redux';
import { Link, Redirect } from 'react-router-dom';
import { CSSTransition } from 'react-transition-group';

import { getUserLimits } from 'modules/profileLegasy/selectors';
import { changeShowCase, createShowCase } from 'modules/cashbox/actions';
import QrCodeSlider from 'modules/cashbox/components/QrCodeSlider/QrCodeSlider';
import ShowCaseForm from 'modules/cashbox/components/ShowCaseForm/ShowCaseForm';
import ShowCaseMobilePopup from 'modules/cashbox/components/ShowCaseMobilePopup/ShowCaseMobilePopup';
import ShowCasePreview from 'modules/cashbox/components/ShowCasePreview/ShowCasePreview';
import CircleLoader from 'modules/shared/components/CircleLoader/CircleLoader';
import PopupCloseWithText from 'modules/shared/components/PopupCloseWithText/PopupCloseWithText';
import PopupHeaderWithBalance from 'modules/shared/components/PopupHeaderWithBalance/PopupHeaderWithBalance';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import TabsWithScrollableActiveState from 'modules/shared/components/TabsWithScrollableActiveState/TabsWithScrollableActiveState';
import TitleWithProgressBar from 'modules/shared/components/TitleWithProgressBar/TitleWithProgressBar';

import {
  ANY_SUM,
  CONSISTENT_ANIMATIONS_DELAY,
  FIXED_SUM,
  HINT_TIMEOUTS,
  KASSA_URL,
  PARTLY_YES,
  YES,
} from 'constants/index';

import { ReactComponent as IconClose } from 'static/assets/icon-close.svg';
import emodjiOk from 'static/assets/icon-emoji-ok.png';
import emodjiSad from 'static/assets/icon-emoji-sad.png';
import { ReactComponent as ResponsiveLogo } from 'static/assets/logo-responsive.svg';

import './ShowCaseCreation.scss';
import { calculateCommission, findMinSum } from '../../../../tools/utils/commission-calculation';
import { maxSumDefault, minSumDefault } from '../../../../tools/constants';
import { OnlyAuthorizedIdentifyPopup } from '../../../../tools/utils/only-authorized';

export const ShowCaseCreation = ({
  user,
  dispatch,
  userTaxStatus,
  userFirstName,
  userLastName,
  userMiddleName,
  userLogin,
  userEmail,
  szStatusFromDb,
  isUrlLinkLoading,
  isUrlLinkError,
  userLimits,
}) => {
  const windowSize = useWindowSize();
  const [isRendered, setIsRendered] = useState(false);
  const [redirectOnCashBox, setRedirectOnCashBox] = useState(false);

  const [activeTab, setActiveTab] = useState(ANY_SUM);
  const [calculatedProgress, setCalculatedProgress] = useState(0);

  const [name, setName] = useState('');
  const [sum, setSum] = useState('');
  const [minSum, setMinSum] = useState(minSumDefault);
  const [maxSum, setMaxSum] = useState(maxSumDefault);
  // eslint-disable-next-line no-unused-vars
  const [commission, setCommission] = useState(null);
  const [url, setUrl] = useState('');
  const [isNameVisible, setIsNameVisible] = useState(false);
  const [isPhoneVisible, setIsPhoneVisible] = useState(false);
  const [isEmailVisible, setIsEmailVisible] = useState(false);

  const [isPayerNameNeeded, setIsPayerNameNeeded] = useState(false);
  const [isPayerPhoneNeeded, setIsPayerPhoneNeeded] = useState(false);
  const [isPayerEmailNeeded, setIsPayerEmailNeeded] = useState(false);

  const [isSaveActionFailed, setIsSaveActionFailed] = useState(false);
  const [isSaveActionSucceed, setIsSaveActionSucceed] = useState(false);
  const [isSaveActionLoading, setIsSaveActionLoading] = useState(false);
  const [isSuccessPopupOpen, setIsSuccessPopupOpen] = useState(false);
  const [isSuccessShowCaseChange, setIsSuccessShowCaseChange] = useState(false);
  const [isFailPopupOpen, setIsFailPopupOpen] = useState(false);

  const [isPreviewMobilePopupOpen, setIsPreviewMobilePopupOpen] = useState(false);
  const [isQrCodeMobilePopupOpen, setIsQrCodeMobilePopupOpen] = useState(false);

  const [currentResultTabId, setCurrentResultTabId] = useState(0);

  const [isLoading, setIsLoading] = useState(false);
  const [showCaseData, setShowCaseData] = useState(null);

  const resultTabArray = [
    {
      title: 'Страница',
      click: () => setCurrentResultTabId(0),
    },
    {
      title: 'QR-код',
      click: () => setCurrentResultTabId(1),
    },
  ];

  /**
   * Проверяекм, сз ли юзер:
   * если у него статус не YES и при этом нет
   * дополнительного статуса szStatusFromDb = PARTLY_YES,
   * то редиректим на личный кабинет
   * */
  useEffect(() => {
    if (
      !userTaxStatus ||
      (userTaxStatus !== YES && (!szStatusFromDb || szStatusFromDb !== PARTLY_YES))
    ) {
      setRedirectOnCashBox(true);
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, [userTaxStatus, szStatusFromDb]);
  /* eslint-enable react-hooks/exhaustive-deps */

  /**
   * Если пользваотель перешел на страницу по ссылке "Отредактировать",
   *  то в localStorage есть  showCaseId, в таком случе отправляем на
   *  сервер запрос на данные оп этому id, чтобы отобразить данные по редактируемой витрине
   *  */
  useEffect(() => {
    if (window && window.localStorage && window.localStorage.getItem('showCaseId')) {
      const showCaseId = window.localStorage.getItem('showCaseId');
      setIsLoading(true);

      getShowCase(showCaseId)
        .then((res) => {
          setShowCaseData(res.data);
          if (window && window.localStorage) {
            window.localStorage.removeItem('showCaseId');
          }

          setName(res.data.name);
          setIsNameVisible(Boolean(res.data.fio));
          setIsPhoneVisible(Boolean(res.data.phone));
          setIsEmailVisible(Boolean(res.data.email));
          setIsPayerNameNeeded(res.data.needFio);
          setIsPayerPhoneNeeded(res.data.needPhone);
          setIsPayerEmailNeeded(res.data.needEmail);
          setUrl(showCaseId);

          if (res.data.sum) {
            setActiveTab(FIXED_SUM);
            setSum((res.data.sum / 100).toString());
          } else {
            setActiveTab(ANY_SUM);

            if (res.data.sumHint) {
              setSum((res.data.sumHint / 100).toString());
            }
          }

          setIsLoading(false);
        })
        .catch(() => {
          if (window && window.localStorage) {
            window.localStorage.removeItem('showCaseId');
          }
          setIsLoading(false);
        });
    }
    /* eslint-disable react-hooks/exhaustive-deps */
  }, []);
  /* eslint-enable react-hooks/exhaustive-deps */

  useEffect(() => {
    setIsRendered(true);
  }, []);

  /** Устанавливаем лимиты для максимальной и минимальной суммы */
  useEffect(() => {
    dispatch(getCashBoxContext())
      .then((res) => {
        if (res && res.data) {
          setCommission(res.data.commission || null);
          if (res.data.hasOwnProperty('minSum') && res.data.hasOwnProperty('maxSum')) {
            setMinSum(findMinSum([res.data.minSum, res.data.maxSum]));
            setMaxSum(
              calculateCommission({
                sum: findMinSum([userLimits.balanceLimit, res.data.maxSum]),
                commission: res.data.commission || null,
                inverted: true,
              }),
            );
          }
        }
      })
      .catch(() => {});
  }, [dispatch, userLimits]);

  /**
   * Устанавливаем ширину прогресс бара в зависимости
   * от выбранного таба и заполненных полей
   * */
  useEffect(() => {
    setCalculatedProgress(0);

    const isNameFilled = name && name.length >= 1 && name.length <= 50;
    const isUrlFilled = url && url.length >= 1 && url.length <= 20;
    const isSumFilled = sum && +sum >= 1;

    if (activeTab === ANY_SUM) {
      if (isNameFilled && isUrlFilled && isSaveActionSucceed) {
        setCalculatedProgress(100);
      } else if (isNameFilled && isUrlFilled && !isSaveActionSucceed) {
        setCalculatedProgress(66);
      } else if (isNameFilled || isUrlFilled) {
        setCalculatedProgress(33);
      } else {
        setCalculatedProgress(0);
      }
    }

    if (activeTab === FIXED_SUM) {
      if (isNameFilled && isSumFilled && isUrlFilled && isSaveActionSucceed) {
        setCalculatedProgress(100);
      } else if (isNameFilled && isSumFilled && isUrlFilled) {
        setCalculatedProgress(75);
      } else if (
        (isNameFilled && isSumFilled) ||
        (isNameFilled && isUrlFilled) ||
        (isSumFilled && isUrlFilled)
      ) {
        setCalculatedProgress(50);
      } else if (isNameFilled || isSumFilled || isUrlFilled) {
        setCalculatedProgress(25);
      } else {
        setCalculatedProgress(0);
      }
    }
  }, [activeTab, name, url, sum, isSaveActionSucceed]);

  /** Сохраняем инфу по новой копилке */
  const saveShowCaseClick = () => {
    setIsSaveActionFailed(false);
    setIsSaveActionSucceed(false);
    setIsSaveActionLoading(true);
    setIsSuccessPopupOpen(false);
    setIsSuccessShowCaseChange(false);

    dispatch(
      createShowCase(
        url,
        name,
        isPayerEmailNeeded,
        isPayerNameNeeded,
        isPayerPhoneNeeded,
        isEmailVisible,
        isNameVisible,
        isPhoneVisible,
        activeTab === FIXED_SUM ? sum * 100 : 0,
        activeTab === ANY_SUM ? sum * 100 : null,
      ),
    )
      .then(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionSucceed(true);
        setIsSuccessPopupOpen(true);
      })
      .catch(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionFailed(true);
        setIsFailPopupOpen(true);
      });
  };

  /** Меняем инфу по существующей копилке */
  const changeShowCaseClick = () => {
    setIsSaveActionFailed(false);
    setIsSaveActionSucceed(false);
    setIsSaveActionLoading(true);
    setIsSuccessPopupOpen(false);
    setIsSuccessShowCaseChange(true);

    dispatch(
      changeShowCase(
        url,
        url,
        name,
        isPayerEmailNeeded,
        isPayerNameNeeded,
        isPayerPhoneNeeded,
        isEmailVisible,
        isNameVisible,
        isPhoneVisible,
        activeTab === FIXED_SUM ? sum * 100 : 0,
        activeTab === ANY_SUM ? sum * 100 : null,
      ),
    )
      .then(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionSucceed(true);
        setIsSuccessPopupOpen(true);
      })
      .catch(() => {
        setIsSaveActionLoading(false);
        setIsSaveActionFailed(true);
        setIsFailPopupOpen(true);
      });
  };

  /** Редирект на Кассу */
  if (redirectOnCashBox) {
    return <Redirect to="/lk/kassa" />;
  }

  return createPortal(
    <>
      <OnlyAuthorizedIdentifyPopup user={user} redirect={'/lk'} />
      <div className="show-case-creation">
        <div className="show-case-creation__container">
          {/* ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}
          <CSSTransition
            classNames="animation-from-bottom-to-top"
            in={isRendered && windowSize.innerWidth > 980}
            timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
            unmountOnExit
          >
            <PopupHeaderWithBalance className="show-case-creation__header" />
          </CSSTransition>
          {/* /ХЭДЕР С БАЛАНСОМ И НОМЕРОМ АККАУНТА ЮЗЕРА ПРИ ШИРИНЕ ЭКРАНА  > 980 */}

          {windowSize.innerWidth <= 980 && (
            <div className="show-case-creation__header">
              <Link to="/lk" className="show-case-creation__header-logo-wrap">
                <ResponsiveLogo className="show-case-creation__header-logo" />
              </Link>

              <button
                type="button"
                className="show-case-creation__close"
                onClick={() => setRedirectOnCashBox(true)}
              >
                <IconClose className="show-case-creation__close-icon" />
              </button>
            </div>
          )}

          {isLoading ? (
            <div className="show-case-creation__loader">
              <CircleLoader />
            </div>
          ) : (
            <>
              {/* ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}
              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[1]}
                unmountOnExit
              >
                <TitleWithProgressBar
                  title={showCaseData ? 'Редактирование витрины' : 'Создание витрины'}
                  progress={calculatedProgress}
                />
              </CSSTransition>
              {/* /ЗАГОЛОВОК  С ПРОГРЕСС БАРОМ */}

              <CSSTransition
                classNames="animation-from-bottom-to-top"
                in={isRendered}
                timeout={CONSISTENT_ANIMATIONS_DELAY[2]}
                unmountOnExit
              >
                <div className="show-case-creation__content">
                  {/* FORM */}
                  <div className="show-case-creation__form-wrap">
                    <ShowCaseForm
                      name={name}
                      onChangeName={(event) => setName(event.target.value)}
                      activeTab={activeTab}
                      fixedSumTabClick={() => setActiveTab(FIXED_SUM)}
                      anySumTabClick={() => setActiveTab(ANY_SUM)}
                      sum={sum.toString()}
                      onChangeSum={setSum}
                      isNameVisible={isNameVisible}
                      onChangeIsNameVisible={() => setIsNameVisible((prevState) => !prevState)}
                      isPhoneVisible={isPhoneVisible}
                      onChangeIsPhoneVisible={() => setIsPhoneVisible((prevState) => !prevState)}
                      isEmailVisible={isEmailVisible}
                      onChangeIsEmailVisible={() => setIsEmailVisible((prevState) => !prevState)}
                      url={url}
                      setUrl={setUrl}
                      saveDataClick={showCaseData ? changeShowCaseClick : saveShowCaseClick}
                      isSaveActionLoading={isSaveActionLoading}
                      userTaxStatus={userTaxStatus}
                      userFio={Boolean(userFirstName || userMiddleName || userLastName)}
                      userEmail={Boolean(userEmail)}
                      minSum={minSum / 100}
                      maxSum={maxSum / 100}
                      isEditingShowCase={Boolean(showCaseData)}
                      isUrlLinkLoading={isUrlLinkLoading}
                      isUrlLinkError={isUrlLinkError}
                      isPayerNameNeeded={isPayerNameNeeded}
                      isPayerPhoneNeeded={isPayerPhoneNeeded}
                      isPayerEmailNeeded={isPayerEmailNeeded}
                      onChangeIsPayerNameNeeded={() =>
                        setIsPayerNameNeeded((prevState) => !prevState)
                      }
                      onChangeIsPayerPhoneNeeded={() =>
                        setIsPayerPhoneNeeded((prevState) => !prevState)
                      }
                      onChangeIsPayerEmailNeeded={() =>
                        setIsPayerEmailNeeded((prevState) => !prevState)
                      }
                    />
                  </div>
                  {/* /FORM */}

                  {windowSize.innerWidth > 980 && (
                    <div className="show-case-creation__result">
                      <TabsWithScrollableActiveState
                        className="show-case-creation__result-nav"
                        tabArray={resultTabArray}
                        currentTabId={currentResultTabId}
                        isRendered={isRendered}
                      />

                      <div className="show-case-creation__preview-wrap">
                        {currentResultTabId === 0 && (
                          <ShowCasePreview
                            className="show-case-creation__preview show-case-preview_preview"
                            name={name}
                            placeHolderSum={+sum}
                            sum={+sum}
                            isNameVisible={isNameVisible}
                            isPhoneVisible={isPhoneVisible}
                            isEmailVisible={isEmailVisible}
                            isPreview
                            userFirstName={userFirstName}
                            userLastName={userLastName}
                            userMiddleName={userMiddleName}
                            userLogin={userLogin}
                            userEmail={userEmail}
                            isUserLoggedIn
                            isPayerNameNeeded={isPayerNameNeeded}
                            isPayerPhoneNeeded={isPayerPhoneNeeded}
                            isPayerEmailNeeded={isPayerEmailNeeded}
                          />
                        )}

                        {currentResultTabId === 1 && (
                          <QrCodeSlider
                            url={`${KASSA_URL}${url}`}
                            className="show-case-creation__slider"
                          />
                        )}
                      </div>
                    </div>
                  )}

                  {windowSize.innerWidth <= 980 && (
                    <nav className="show-case-creation__result-nav">
                      <div className="show-case-creation__result-nav-item-wrap">
                        <button
                          onClick={() => setIsPreviewMobilePopupOpen(true)}
                          type="button"
                          className="show-case-creation__result-nav-item"
                        >
                          Страница
                        </button>
                      </div>

                      <div className="show-case-creation__result-nav-item-wrap">
                        <button
                          onClick={() => setIsQrCodeMobilePopupOpen(true)}
                          type="button"
                          className="show-case-creation__result-nav-item"
                        >
                          QR-код
                        </button>
                      </div>
                    </nav>
                  )}
                </div>
              </CSSTransition>
            </>
          )}
        </div>
        {/* КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
        <CSSTransition
          classNames="animation-from-bottom-to-top"
          in={isRendered && windowSize.innerWidth > 980}
          timeout={CONSISTENT_ANIMATIONS_DELAY[0]}
          unmountOnExit
        >
          <PopupCloseWithText closePopupHandler={() => setRedirectOnCashBox(true)} />
        </CSSTransition>
        {/* /КНОПКА ЗАКРЫТИЯ ПОПАПА ПРИ ШИРИНЕ ЭКРАНА > 980 */}
        {/* ПОПАП - УДАЧНОЕ СОЗДАНИЕ ВИТРИНЫ */}
        <CSSTransition
          in={isSaveActionSucceed && isSuccessPopupOpen}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <SmallPopupWithEmoji
            className="small-popup-with-emoji"
            mounted={isSaveActionSucceed && isSuccessPopupOpen}
            emoji={emodjiOk}
            closePopup={() => {
              setIsSuccessPopupOpen(false);
              setRedirectOnCashBox(true);
            }}
            title={isSuccessShowCaseChange ? `Витрина изменена!` : `Витрина создана!`}
            buttonArray={[
              {
                id: 1,
                title: 'Перейти в Кассу',
                action: () => setRedirectOnCashBox(true),
                colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
              },
            ]}
          >
            <p>
              Отлично! Витрина была успешно {isSuccessShowCaseChange ? ` изменена!` : ` создана!`}.
              Вы всегда можете ее найти в разделе {'   '}
              <Link to="/lk/kassa">Касса</Link>,{'   '}
              чтобы отредактировать или посмотреть статистику.
            </p>
          </SmallPopupWithEmoji>
        </CSSTransition>
        {/* /ПОПАП - УДАЧНОЕ СОЗДАНИЕ ВИТРИНЫ */}
        {/* ПОПАП - НЕУДАЧА ПРИ СОЗДАНИИ ВИТРИНЫ */}
        <CSSTransition
          in={isFailPopupOpen && isSaveActionFailed}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <SmallPopupWithEmoji
            className="small-popup-with-emoji"
            mounted={isFailPopupOpen && isSaveActionFailed}
            emoji={emodjiSad}
            closePopup={() => setIsFailPopupOpen(false)}
            title="Ой! Ошибка!"
            buttonArray={[
              {
                id: 1,
                title: 'Вернуться',
                action: () => setIsFailPopupOpen(false),
                colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
              },
            ]}
          >
            <p>
              Что-то пошло не так, попробуйте повторить попытку позже или{' '}
              <span className="js-help">напишите в тех. поддержку</span>.
            </p>
          </SmallPopupWithEmoji>
        </CSSTransition>
        {/* /ПОПАП - НЕУДАЧА ПРИ СОЗДАНИИ ВИТРИНЫ */}
        {/* ПОПАП - МОБИЛЬНЫЙ ПРЕВЬЮ СТРАНИЦЫ */}
        <CSSTransition
          in={isPreviewMobilePopupOpen && windowSize.innerWidth <= 980}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <ShowCaseMobilePopup
            className="show-case-mobile-popup"
            mounted={isPreviewMobilePopupOpen && windowSize.innerWidth <= 980}
            closePopup={() => setIsPreviewMobilePopupOpen(false)}
            title="Превью страницы"
          >
            <ShowCasePreview
              className="show-case-creation__preview show-case-preview_preview"
              name={name}
              placeHolderSum={+sum}
              sum={activeTab === FIXED_SUM ? +sum : null}
              isNameVisible={isNameVisible}
              isPhoneVisible={isPhoneVisible}
              isEmailVisible={isEmailVisible}
              isPreview
              userFirstName={userFirstName}
              userLastName={userLastName}
              userMiddleName={userMiddleName}
              userLogin={userLogin}
              userEmail={userEmail}
              isUserLoggedIn
              isPayerNameNeeded={isPayerNameNeeded}
              isPayerPhoneNeeded={isPayerPhoneNeeded}
              isPayerEmailNeeded={isPayerEmailNeeded}
            />
          </ShowCaseMobilePopup>
        </CSSTransition>
        {/* /ПОПАП - МОБИЛЬНЫЙ ПРЕВЬЮ СТРАНИЦЫ */}
        {/* ПОПАП - МОБИЛЬНЫЙ ВЬЮ QR-КОДА */}
        <CSSTransition
          in={isQrCodeMobilePopupOpen && windowSize.innerWidth <= 980}
          timeout={HINT_TIMEOUTS}
          unmountOnExit
        >
          <ShowCaseMobilePopup
            className="show-case-mobile-popup"
            mounted={isQrCodeMobilePopupOpen && windowSize.innerWidth <= 980}
            closePopup={() => setIsQrCodeMobilePopupOpen(false)}
            title="QR-код"
          >
            <QrCodeSlider url={`${KASSA_URL}${url}`} className="show-case-creation__slider" />
          </ShowCaseMobilePopup>
        </CSSTransition>
        )
      </div>
    </>,
    document.body,
  );
};

ShowCaseCreation.propTypes = {
  dispatch: PropTypes.func.isRequired,
  className: PropTypes.string,
  userTaxStatus: PropTypes.string,
  userFirstName: PropTypes.string,
  userLastName: PropTypes.string,
  userMiddleName: PropTypes.string,
  userLogin: PropTypes.string,
  userEmail: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  isUrlLinkLoading: PropTypes.bool,
  isUrlLinkError: PropTypes.bool,
  userLimits: PropTypes.shape({
    minSum: PropTypes.number.isRequired,
    balanceLimit: PropTypes.number.isRequired,
  }).isRequired,
};

ShowCaseCreation.defaultProps = {
  user: null,
  className: null,
  userTaxStatus: null,
  userFirstName: null,
  userLastName: null,
  userMiddleName: null,
  userLogin: null,
  userEmail: null,
  szStatusFromDb: '',
  isUrlLinkLoading: false,
  isUrlLinkError: false,
};

const mapStateToProps = (state) => ({
  user: state.auth.user,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
  userFirstName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.firstName
      ? state.auth.user.personal.firstName
      : null,
  userLastName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.lastName
      ? state.auth.user.personal.lastName
      : null,
  userMiddleName:
    state.auth.user && state.auth.user.personal && state.auth.user.personal.middleName
      ? state.auth.user.personal.middleName
      : null,
  userLogin: state.auth.user && state.auth.user.login ? state.auth.user.login : null,
  userEmail: state.auth.user && state.auth.user.email ? state.auth.user.email : null,
  szStatusFromDb: state.auth.szStatusFromDb,
  isUrlLinkLoading: state.cashBox.isUrlLinkLoading,
  isUrlLinkError: state.cashBox.isUrlLinkError,
  userLimits: getUserLimits()(state),
});

export default connect(mapStateToProps)(ShowCaseCreation);
