import { AUTHORIZED } from '../../constants';
import { Redirect, useHistory } from 'react-router-dom';
import React, { useState } from 'react';
import { WindowUI } from '../../ui';
import PropTypes from 'prop-types';

export const checkPaymentOnlyAuthorizedIdentifyEnabled = (user) => {
  const identifyStatus = user && user.group ? user.group : null;
  return identifyStatus === AUTHORIZED;
};

export const OnlyAuthorizedIdentifyPopup = ({ user, onClose, redirect, noHistoryBack }) => {
  let history = useHistory();
  const [
    isOpenAlertWindowOnlyAuthorizedIdentifyPopup,
    setIsOpenAlertWindowOnlyAuthorizedIdentifyPopup,
  ] = useState(!checkPaymentOnlyAuthorizedIdentifyEnabled(user));
  const [redirectOn, setRedirectOn] = React.useState(false);

  if (redirectOn && !noHistoryBack) {
    //if (history.length > 2) {
    //  history.goBack();
    //} else {
    return <Redirect to={redirect} />;
    //}
  }

  return (
    <div>
      {isOpenAlertWindowOnlyAuthorizedIdentifyPopup && (
        <WindowUI.OnlyAuthorized
          onClose={() => {
            if (onClose) {
              onClose();
            }
            if (redirect) {
              setRedirectOn(true);
            }
            setIsOpenAlertWindowOnlyAuthorizedIdentifyPopup(false);
          }}
          onClick={() => {
            setIsOpenAlertWindowOnlyAuthorizedIdentifyPopup(false);
            history.push('/get-authorization?type=AUTHORIZED');
          }}
        />
      )}
    </div>
  );
};

OnlyAuthorizedIdentifyPopup.propTypes = {
  onClose: PropTypes.func,
  redirect: PropTypes.string,
  user: PropTypes.object,
};
