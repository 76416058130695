import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { connect } from 'react-redux';
import { CSSTransition } from 'react-transition-group';

import { removeFnsRights } from 'modules/profileLegasy/actions';
import SmallPopupWithEmoji from 'modules/shared/components/SmallPopupWithEmoji/SmallPopupWithEmoji';
import StandardButton from 'modules/shared/components/StandardButton/StandardButton';

import { GREY_BORDER, HINT_TIMEOUTS, PARTLY_YES, YES } from 'constants/index';

import { ReactComponent as IconBag } from 'static/assets/icon-bag.svg';
import emojiOk from 'static/assets/icon-emoji-ok.png';
import emojiPuzzled from 'static/assets/icon-emoji-puzzled.png';
import emojiSad from 'static/assets/icon-emoji-sad.png';
import stdAvatar from 'static/assets/std-avatar.png';

import './SzStatusCard.scss';

const CHECK_IF_USER_SURE = 'CHECK_IF_USER_SURE';
const LOADING = 'LOADING';
const FAIL = 'FAIL';
const SUCCESS = 'SUCCESS';

export const SzStatusCard = ({
  userTaxStatus,
  userAvatar,
  className,
  szStatusFromDb,
  dispatch,
}) => {
  const avatarUrl = `${process.env.REACT_APP_API_URL}/settings/avatar/`;
  const szCondition = userTaxStatus === YES || (szStatusFromDb && szStatusFromDb === PARTLY_YES);
  const [removingRightsState, setRemovingRightsState] = useState(null);

  const removeFnsRightsClick = () => {
    setRemovingRightsState(LOADING);

    dispatch(removeFnsRights())
      .then(() => {
        setRemovingRightsState(SUCCESS);
      })
      .catch(() => {
        setRemovingRightsState(FAIL);
      });
  };

  return (
    <div
      className={cx('sz-status-card', {
        [className]: className,
      })}
    >
      <div className="sz-status-card__main">
        <div className="sz-status-card__top">
          <div className="sz-status-card__top-text">
            <p className="sz-status-card__service-title" data-testid="profile-sz-title">
              {szCondition ? 'Ваша учетная запись' : 'Подтвердите статус'}
            </p>

            <h3 className="sz-status-card__title">Самозанятый</h3>
          </div>

          {szCondition ? (
            <div className="sz-status-card__avatar-wrap">
              <img
                src={userAvatar ? avatarUrl + userAvatar : stdAvatar}
                alt=""
                className="sz-status-card__top-avatar"
                data-testid="profile-settings-avatar"
              />

              <div className="sz-status-card__top-avatar-badge">
                <IconBag className="sz-status-card__top-avatar-badge-icon" />
              </div>
            </div>
          ) : (
            <div className="sz-status-card__img-wrap">
              <IconBag className="sz-status-card__img-icon" />
            </div>
          )}
        </div>

        <div className="sz-status-card__text">
          {szCondition
            ? 'Вы всегда можете отвязать права приложения «Мой налог» с нашим сервисом. Чтобы узнать подробнее или отвязать права, нажмите кнопку ниже'
            : 'Подтвердите статус самозанятого для полноценной работы с сервисом. Вам потребуется лишь указать ваш ИНН'}
        </div>
      </div>

      <div className="sz-status-card__button-wrap">
        {szCondition ? (
          <StandardButton
            title="Отвязать права"
            isLink={false}
            onClick={() => setRemovingRightsState(CHECK_IF_USER_SURE)}
            bgType={GREY_BORDER}
            isLoading={removingRightsState === LOADING}
            className="sz-status-card__button"
          />
        ) : (
          <StandardButton title="Получить" isLink href="/self-employment-from-lk" />
        )}
      </div>

      {/* ПОПАП - УТОЧНЯЕМ ХОЧЕТ ЛИ ЮЗЕР ОТВЯЗАТЬ ПРАВА */}
      <CSSTransition
        in={removingRightsState === CHECK_IF_USER_SURE || removingRightsState === LOADING}
        timeout={HINT_TIMEOUTS}
        unmountOnExit
      >
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          mounted={removingRightsState === CHECK_IF_USER_SURE || removingRightsState === LOADING}
          emoji={emojiPuzzled}
          closePopup={() => setRemovingRightsState(null)}
          title="Вы действительно хотите отозвать права от ФНС?"
          buttonArray={[
            {
              id: 1,
              title: 'Вернуться в профиль',
              action: () => setRemovingRightsState(null),
              colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
            },
            {
              id: 2,
              title: 'Отвязать права',
              action: removeFnsRightsClick,
              colorType: 'blue-border', // colorType может быть: grey-border, red-border, blue-border, blue
              isLoading: removingRightsState === LOADING,
            },
          ]}
        >
          <p>Вы сможете вернуть их в любой момент.</p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - УТОЧНЯЕМ ХОЧЕТ ЛИ ЮЗЕР ОТВЯЗАТЬ ПРАВА */}

      {/* ПОПАП - УДАЧНАЯ ОТВЯЗКА ПРАВ */}
      <CSSTransition in={removingRightsState === SUCCESS} timeout={HINT_TIMEOUTS} unmountOnExit>
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          mounted={removingRightsState === SUCCESS}
          emoji={emojiOk}
          closePopup={() => setRemovingRightsState(null)}
          title="Вы успешно отвязали права"
          buttonArray={[
            {
              id: 1,
              title: 'Вернуться в профиль',
              action: () => setRemovingRightsState(null),
              colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
            },
          ]}
        >
          <p>Вы успешно отвязали права! Cтатус учетной записи обновится в течение 1 минуты</p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - УДАЧНАЯ ОТВЯЗКА ПРАВ */}

      {/* ПОПАП - НЕУДАЧНАЯ ОТВЯЗКА ПРАВ */}
      <CSSTransition in={removingRightsState === FAIL} timeout={HINT_TIMEOUTS} unmountOnExit>
        <SmallPopupWithEmoji
          className="small-popup-with-emoji"
          mounted={removingRightsState === FAIL}
          emoji={emojiSad}
          closePopup={() => setRemovingRightsState(null)}
          title="Упс! Что-то пошло не так"
          buttonArray={[
            {
              id: 1,
              title: 'Вернуться в профиль',
              action: () => setRemovingRightsState(null),
              colorType: 'blue', // colorType может быть: grey-border, red-border, blue-border, blue
            },
          ]}
        >
          <p>
            К сожалению, мы не смогли отвязать права сейчас. Попробуйте повторить операцию позже.
            Также вы можете отвязать права в {'  '}
            <a href="https://lkfl2.nalog.ru/lkfl" rel="noopener noreferrer" target="_blank">
              личном кабинете налогоплательщика
            </a>
          </p>
        </SmallPopupWithEmoji>
      </CSSTransition>
      {/* /ПОПАП - НЕУДАЧНАЯ ОТВЯЗКА ПРАВ */}
    </div>
  );
};

SzStatusCard.propTypes = {
  dispatch: PropTypes.func.isRequired,
  userAvatar: PropTypes.string,
  userTaxStatus: PropTypes.string,
  szStatusFromDb: PropTypes.string,
  className: PropTypes.string,
};

SzStatusCard.defaultProps = {
  userAvatar: null,
  userTaxStatus: null,
  szStatusFromDb: '',
  className: null,
};

const mapStateToProps = (state) => ({
  userAvatar: state.auth.user && state.auth.user.avatar ? state.auth.user.avatar : null,
  szStatusFromDb: state.auth.szStatusFromDb,
  userTaxStatus:
    state.auth.user && state.auth.user.taxStatus && state.auth.user.taxStatus.status
      ? state.auth.user.taxStatus.status
      : null,
});

export default connect(mapStateToProps)(SzStatusCard);
