import { createAsyncThunk } from '@reduxjs/toolkit';
import { reSendSmsAPI } from './api';

/**
 * [Thunk] Запрос на повторную отправку СМС-кода для авторизованного подтверждения документа
 * @return {data<obj>}
 */
export const reSendSmsAPIThunk = createAsyncThunk(
  'reSendSms/request',
  async (id, { rejectWithValue, getState }) => {
    try {
      return await reSendSmsAPI(id);
    } catch (error) {
      return rejectWithValue(error?.message?.toString() || error.toString());
    }
  },
);
