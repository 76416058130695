import React from 'react';
import { getLocalStorageValue, setLocalStorageValue } from '../hooks/useLocalStorage';
import { WindowUI } from '../../ui';
import { useState } from 'react';
import PropTypes from 'prop-types';
import { Redirect, useHistory } from 'react-router-dom';

const storageNamePaymentEnabled = 'paymentEnabled';
const defaultPaymentEnabled =
  process.env.REACT_APP_ENABLE_PAYMENTS !== undefined
    ? process.env.REACT_APP_ENABLE_PAYMENTS === 'true'
    : true;

export const checkPaymentEnabled = () => {
  if (process.env.REACT_APP_ENABLE_PAYMENTS_LOCALSTORAGE === 'true') {
    return getLocalStorageValue(storageNamePaymentEnabled, defaultPaymentEnabled);
  } else {
    return defaultPaymentEnabled;
  }
};

export const setPaymentEnabled = (enabled) => {
  return setLocalStorageValue(storageNamePaymentEnabled, enabled);
};

if (defaultPaymentEnabled) {
  setPaymentEnabled(true);
}

export const PaymentDisabledPopup = ({ onClose, redirect }) => {
  let history = useHistory();
  const [isOpenAlertWindowDisabledPopup, setIsOpenAlertWindowDisabledPopup] = useState(
    !checkPaymentEnabled(),
  );
  const [redirectOn, setRedirectOn] = React.useState(false);

  if (redirectOn) {
    if (history.length > 2) {
      history.goBack();
    } else {
      return <Redirect to={redirect} />;
    }
  }

  return (
    <div>
      {isOpenAlertWindowDisabledPopup && (
        <WindowUI.UnavailableContent
          onClose={() => {
            if (onClose) {
              onClose();
            }
            if (redirect) {
              setRedirectOn(true);
            }
            setIsOpenAlertWindowDisabledPopup(false);
          }}
        />
      )}
    </div>
  );
};

PaymentDisabledPopup.propTypes = {
  onClose: PropTypes.func,
  redirect: PropTypes.string,
  user: PropTypes.object,
};
