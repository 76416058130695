import cx from 'classnames';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

import FnsErrorMessage from 'modules/profileLegasy/components/FnsErrorMessage/FnsErrorMessage';
import TaxesAmount from 'modules/tax/components/TaxesAmount/TaxesAmount';
import TaxesProgressBar from 'modules/tax/components/TaxesProgressBar/TaxesProgressBar';

import { MONTHSRU } from 'constants/index.js';
import formatNumber from 'helpers/formatNumber';

import './TaxesIncomeCard.scss';

const TaxesIncomeCard = ({
  taxInfo,
  now,
  isTaxInfoLoading,
  isTaxInfoError,
  taxInfoErrorMessage,
  className,
  yearIncomes,
  isYearIncomesLoading,
  isYearIncomesFail,
}) => {
  const yearIncomeLimit = 240000000;
  const [limitRest, setLimitRest] = useState(yearIncomeLimit);
  const [isLimitNear, setIsLimitNear] = useState(false);
  const [progress, setProgress] = useState(0);

  /** Считаем лимит на выручку */
  useEffect(() => {
    if (yearIncomes) {
      setLimitRest(yearIncomeLimit - yearIncomes);

      /** Считаем прогресс дохода за год по отношению к лимиту */
      setProgress(Math.floor((yearIncomes / yearIncomeLimit) * 100));

      /**
       * Смотрим, не достигли ли мы отметки,
       * после которой предупреждаем визуально юзера
       * о близости лимита
       * */
      setIsLimitNear(yearIncomes >= 225000000);
    }
  }, [yearIncomeLimit, yearIncomes]);

  return (
    <div
      className={cx('taxes-income-card', {
        [className]: className,
      })}
      data-testid="taxes-income-card"
    >
      {isTaxInfoError ? (
        <FnsErrorMessage className="taxes-to-pay-card__fns-error" text={taxInfoErrorMessage} />
      ) : (
        <>
          <div className="taxes-income-card__top">
            <p className="taxes-income-card__title">
              Выручка за {MONTHSRU[now.getMonth()].toLowerCase()}
            </p>

            {/* ВЫРУЧКА ЗА ТЕКУЩИЙ МЕСЯЦ */}
            <TaxesAmount
              isLoading={isTaxInfoLoading}
              amount={taxInfo && taxInfo.totalAmount}
              className="taxes-income-card__month-income"
            />
            {/* /ВЫРУЧКА ЗА ТЕКУЩИЙ МЕСЯЦ */}
          </div>

          <div className="taxes-income-card__bottom">
            <div className="taxes-income-card__list">
              {/* ВЫРУЧКА ЗА ГОД */}
              <div className="taxes-income-card__item">
                <p className="taxes-income-card__item-title">Выручка за год</p>

                {!isYearIncomesFail ? (
                  <TaxesAmount
                    isLoading={isYearIncomesLoading}
                    amount={yearIncomes}
                    className="taxes-income-card__item-sum"
                  />
                ) : (
                  <p className="taxes-income-card__item-sum">
                    К сожалению данные по доходу сейчас не доступны
                  </p>
                )}
              </div>
              {/* /ВЫРУЧКА ЗА ГОД */}

              {/* ОСТАТОК ЛИМИТА */}
              <div
                className={cx('taxes-income-card__item taxes-income-card__item_limit', {
                  'taxes-income-card__item_limit-is-near': isLimitNear,
                })}
              >
                <p className="taxes-income-card__item-title">Остаток лимита</p>

                {!isYearIncomesFail ? (
                  <TaxesAmount
                    isLoading={isTaxInfoLoading}
                    amount={limitRest}
                    className="taxes-income-card__item-sum"
                  />
                ) : (
                  <p className="taxes-income-card__item-sum">
                    К сожалению данные по остатку лимита сейчас не доступны
                  </p>
                )}
              </div>
              {/* /ОСТАТОК ЛИМИТА */}
            </div>

            <TaxesProgressBar isLimitNear={isLimitNear} progress={progress} />

            {/* ЛИМИТ НА ГОД */}
            <div className="taxes-income-card__limit-wrap">
              <p className="taxes-income-card__limit-title">Лимит на доход в год:</p>
              <div className="taxes-income-card__limit-sum">
                {formatNumber(yearIncomeLimit / 100)},00 ₽
              </div>
            </div>
            {/* /ЛИМИТ НА ГОД */}
          </div>
        </>
      )}
    </div>
  );
};

TaxesIncomeCard.propTypes = {
  className: PropTypes.string,
  taxInfo: PropTypes.shape({
    currency: PropTypes.string,
    debtAmount: PropTypes.number,
    payUntil: PropTypes.string,
    totalAmount: PropTypes.number,
    unpaidAmount: PropTypes.number,
  }),
  now: PropTypes.instanceOf(Date).isRequired,
  isTaxInfoLoading: PropTypes.bool.isRequired,
  isTaxInfoError: PropTypes.bool,
  taxInfoErrorMessage: PropTypes.string,
  yearIncomes: PropTypes.number,
  isYearIncomesLoading: PropTypes.bool,
  isYearIncomesFail: PropTypes.bool,
};

TaxesIncomeCard.defaultProps = {
  className: null,
  taxInfo: {},
  isTaxInfoError: false,
  taxInfoErrorMessage: null,
  yearIncomes: null,
  isYearIncomesLoading: false,
  isYearIncomesFail: false,
};

export default TaxesIncomeCard;
