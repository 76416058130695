export const getPartnershipInfo = (partnershipId) => (state) => {
  return state.profile.partnerList.find((item) => item.id === parseInt(partnershipId));
};

export const getPartnerNameByPartnershipId = (platformName, partnershipId) => (state) => {
  const partner = state.profile.partnerList.find(
    (item) => item.id === parseInt(partnershipId) && item.partner.platform === platformName,
  );

  if (!partner) {
    return null;
  }

  return partner.partner.fullName;
};

export const getPartnerBankCardNumber = (partnerId) => (state) => {
  const partner = state.profile.partnerList.find((item) => item.id === parseInt(partnerId));

  if (!partner) {
    return null;
  }

  return partner.maskedPan;
};

export const getEditedPartnership = () => (state) => {
  const partnershipId = state.partners.data.popupEditBankCardPartnershipId;

  return state.profile.partnerList.find((item) => item.id === partnershipId);
};

export const getAddPartnerRequest = () => (state) => {
  const requestId = state.partners.data.popupAddPartnerRequestId;

  return state.profile.partnerRequestList.find((item) => item.id === requestId);
};

export const getDeclineRequest = () => (state) => {
  const requestId = state.partners.data.popupDeclinePartnerRequestId;

  return state.profile.partnerRequestList.find((item) => item.id === requestId);
};

export const getPopupCheckPayment = () => (state) => {
  const paymentId = state.partners.data.popupCheckPaymentId;
  return state.partners.payments.items.find((item) => item.id === paymentId);
};

export const getCheckPayment = () => (state) => {
  const paymentId = state.partners.data.checkPaymentId;

  return state.partners.payments.items.find((item) => item.id === paymentId);
};
