/* eslint-disable prettier/prettier */
/* eslint-disable import/prefer-default-export */
import { formatDateHumanWithUTCOffset } from 'helpers';
import PropTypes from 'prop-types';
import React, { useState } from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import { ReactComponent as IconArrowRight } from 'static/assets/icon-arrow-right.svg';
import styled, { css } from 'styled-components';
import { CircleLoader, LineText } from 'ui';

import {
  useDocumentById,
  useDocumentListSelector,
  useHasMoreDocumentsSelector,
  useIsDocumentListLoadingFetchingSelector,
  useIsFetchingByFilterSelector,
} from '../../../models/documentList';
//! TODO: Поправить на UNSIGNED после правки со стороны сервиса
import { DOC_STATUSES } from '../../../utils/constants';
import { DocumentInfoPopupWidget } from '../../DocumentInfoWidget';

export const ContentTable = ({ onLoadDocumentList, includesColumns }) => {
  const documentList = useDocumentListSelector();
  const hasMoreDocuments = useHasMoreDocumentsSelector();
  const isDocumentListLoadingFetching = useIsDocumentListLoadingFetchingSelector();
  const isFetchingByFilter = useIsFetchingByFilterSelector();
  const [openPopupId, setOpenPopupId] = useState(false);
  const [docSelectId, setDocSelectId] = useState(false);
  const doc = useDocumentById(docSelectId);
  return (
    <>
      {openPopupId && doc && (
        <DocumentInfoPopupWidget
          docId={docSelectId}
          isOpen={openPopupId}
          onOpen={() => setOpenPopupId((state) => !state)}
        />
      )}
      <TableWrapperStyled>
        {isDocumentListLoadingFetching && isFetchingByFilter && (
          <FullCircleLoaderWrapperStyled>
            <CircleLoaderStyled />
          </FullCircleLoaderWrapperStyled>
        )}
        <InfiniteScroll
          hasMore={!isDocumentListLoadingFetching && hasMoreDocuments}
          loadMore={() => onLoadDocumentList()}
        >
          <TableStyled isFetchingByFilter={isFetchingByFilter}>
            <tbody>
              {documentList.map((item, id) => (
                <TrStyled
                  key={id}
                  onClick={() => {
                    setOpenPopupId((state) => !state);
                    setDocSelectId(item.id);
                  }}
                  isData={includesColumns.data}
                  isDocumentName={includesColumns.documentName}
                  isCompanyName={includesColumns.companyName}
                  isState={includesColumns.state}
                >
                  {includesColumns.data && (
                    <TdDataStyled>
                      <LineTextStyled>
                        <DataStyled>{formatDateHumanWithUTCOffset(item.dateAdd, 6)}</DataStyled>
                      </LineTextStyled>
                    </TdDataStyled>
                  )}
                  {includesColumns.documentName && (
                    <TdDocNameStyled>
                      <TitleStyled>{item.documentName}</TitleStyled>
                    </TdDocNameStyled>
                  )}
                  {includesColumns.companyName && (
                    <TdCompanyNameStyled>
                      <CompanyNameStyled>{item.companyName}</CompanyNameStyled>
                    </TdCompanyNameStyled>
                  )}
                  {includesColumns.state && (
                    <TdStateStyled>
                      <StatusWraperStyled>
                        <StatusStyled
                          statusDraft={item.state === 'DRAFT'}
                          statusUnsigned={item.state === 'SEND_TO_SIGN'}
                          statusSigned={item.state === 'SIGNED'}
                          statusDeclined={
                            item.state === 'REJECTED' ||
                            item.state === 'EXPIRED' ||
                            item.state === 'BLOCKED' ||
                            item.state === 'TERMINATED'
                          }
                        >
                          {DOC_STATUSES.get(item.state) || ''}
                        </StatusStyled>
                        <IconArrowRight />
                      </StatusWraperStyled>
                    </TdStateStyled>
                  )}
                </TrStyled>
              ))}
            </tbody>
          </TableStyled>
        </InfiniteScroll>
      </TableWrapperStyled>
    </>
  );
};

ContentTable.propTypes = {
  onLoadDocumentList: PropTypes.func.isRequired,
  includesColumns: PropTypes.shape({
    documentName: PropTypes.bool,
    companyName: PropTypes.bool,
    data: PropTypes.bool,
    state: PropTypes.bool,
  }),
};

ContentTable.defaultProps = {
  includesColumns: {
    documentName: true,
    companyName: true,
    data: true,
    state: true,
  },
};

const TableWrapperStyled = styled.div`
  /* width: 100%; */
  @media (max-width: 600px) {
    margin-left: -20px;
    margin-right: -20px;
    width: calc(100% + 40px);
  }
`;

const TableStyled = styled.table`
  width: 100%;
  table-layout: fixed;
  ${({ isFetchingByFilter }) =>
    isFetchingByFilter &&
    css`
      opacity: 0.3;
    `} @media(max-width: 600 px) {
    display: block;
  }
`;

const TrStyled = styled.tr`
  cursor: pointer;
  transition: background-color 0.3s;
  width: 100%;
  display: inline-grid;
  grid-template: 'data docName companyName state';
  align-items: center;
  &:hover {
    background-color: var(--pale-grey);
  }
  @media (min-width: 601px) {
    column-gap: 20px;
    height: 40px;
    &:first-of-type {
      border-radius: 12px 12px 0 0;
      padding-top: 2px;
    }
    &:last-of-type {
      border-radius: 0 0 12px 12px;
    }
    & > *:first-child {
      margin-left: 20px;
    }
  }
  @media (max-width: 600px) {
    justify-content: space-between;
    grid-template:
      ${({ isState, isData }) =>
        isState &&
        isData &&
        css`
      "state data" 32px
      `}
      ${({ isDocumentName }) =>
        isDocumentName &&
        css`
      "docName docName" 22px
      `}
      ${({ isCompanyName }) =>
        isCompanyName &&
        css`
        "companyName companyName" 20px
        `}
      / 1fr 1fr;
    border-bottom: 1px #f6f8fb solid;
    padding: 20px;
  }
`;

const TdStyled = styled.td`
  overflow: hidden;
  text-overflow: ellipsis;
`;

const TdDataStyled = styled(TdStyled)`
  grid-area: data;
  display: inline-block;
  column-width: 120px;
  @media (max-width: 600px) {
    text-align: right;
    display: flex;
    justify-content: end;
  }
`;
const TdDocNameStyled = styled(TdStyled)`
  grid-area: docName;
  color: var(--dark-slate-blue);
  display: inline-block;
  column-width: 230px;
`;
const TdCompanyNameStyled = styled(TdStyled)`
  grid-area: companyName;
  color: var(--bluey-grey);
  display: inline-block;
  column-width: 230px;
`;
const TdStateStyled = styled(TdStyled)`
  grid-area: state;
  @media (min-width: 601px) {
    min-width: 160px;
    padding-right: 12px;
    margin-right: 0;
    margin-left: auto;
  }
  svg {
    opacity: 0;
    ${TrStyled}:hover & {
      opacity: 1;
      transition: opacity 0.3s;
    }
  }
  @media (max-width: 600px) {
    align-items: center;
    svg {
      display: none;
    }
  }
`;

const StatusWraperStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const LineTextStyled = styled(LineText)`
  line-height: 20px;
  font-size: 14px;
`;

const DataStyled = styled.div`
  color: var(--bluey-grey);
`;

const TitleStyled = styled(LineTextStyled)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--dark-slate-blue);
  display: block;
  max-width: 230px;
  width: 100%;
`;

const CompanyNameStyled = styled(LineTextStyled)`
  flex-basis: content;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  color: var(--bluey-grey);
  display: block;
  max-width: 230px;
  width: 100%;
`;

const StatusStyled = styled(LineTextStyled)`
  ${({ statusUnsigned }) =>
    statusUnsigned &&
    css`
      color: var(--turquoise-blue);
    `}
  ${({ statusSigned }) =>
    statusSigned &&
    css`
      color: var(--grey-blue);
    `}
  ${({ statusDeclined }) =>
    statusDeclined &&
    css`
      color: var(--bluey-grey);
    `}
    ${({ statusDraft }) =>
    statusDraft &&
    css`
      color: var(--bluey-grey);
    `}
`;

const FullCircleLoaderWrapperStyled = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  width: 100%;
  height: 100%;
`;

const CircleLoaderStyled = styled(CircleLoader)`
  margin: 143px auto;
`;
