import React from 'react';
import PropTypes from 'prop-types';
import { DateRange } from 'react-date-range';
import cx from 'classnames';
import ru from 'date-fns/locale/ru';
import { ReactComponent as IconArrow } from 'static/assets/icon-arrow.svg';
import { MONTHSRU, WEEK_DAYS_LOWERCASE_RU, OPERATIONS_MONTHS_REDUCED_RU } from 'constants/index';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import '../CalendarRangePicker/RangePicker.overwrite.scss';
import './MobileCalendarRangePicker.scss';

import buildLocalizeFn from 'date-fns/locale/_lib/buildLocalizeFn';

const matchDayPatterns = {
  narrow: ['Вс', 'Пн', 'Вт', 'Чт', 'Пт', 'Сб', 'Вс'],
  short: ['Вс', 'Пн', 'Вт', 'Чт', 'Пт', 'Сб', 'Вс'],
  abbreviated: ['Вс', 'Пн', 'Вт', 'Чт', 'Пт', 'Сб', 'Вс'],
  wide: ['Воскресенье', 'Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота'],
};

ru.localize.day = buildLocalizeFn({
  values: matchDayPatterns,
  defaultWidth: 'wide',
});

const MobileCalendarRangePicker = React.forwardRef(({ className, range, onChange }, ref) => {
  function renderNavigator(focusedDate, changeShownDate, props) {
    function showPreviousMonth() {
      changeShownDate(-1, 'monthOffset');
    }

    function showNextMonth() {
      changeShownDate(+1, 'monthOffset');
    }

    const currentMonth = MONTHSRU[focusedDate.getMonth()];
    const currentYear = focusedDate.getFullYear();

    return (
      <div className="mobile-calendar-range-picker__calendar-controls">
        <nav className="mobile-calendar-range-picker__calendar-nav">
          <button onClick={showPreviousMonth}>
            <IconArrow className="mobile-calendar-range-picker__calendar-left-arrow" />
          </button>
          <span>
            {currentMonth} {currentYear}
          </span>
          <button onClick={showNextMonth}>
            <IconArrow />
          </button>
        </nav>
      </div>
    );
  }

  return (
    <div className={cx('mobile-calendar-range-picker', className)} ref={ref}>
      <div className="mobile-calendar-range-picker__calendar">
        <DateRange
          onChange={(item) => onChange([item.selection])}
          months={1}
          showMonthAndYearPickers={false}
          showDateDisplay={false}
          showMonthArrow={false}
          ranges={range}
          locale={ru}
          direction={'horizontal'}
          maxDate={new Date()}
          navigatorRenderer={renderNavigator}
        />
      </div>
      <div className="mobile-calendar-range-picker__chosen-dates">
        <div className="calendar-range-picker__chosen-date">
          {WEEK_DAYS_LOWERCASE_RU[range[0].startDate.getDay() - 1]}, {range[0].startDate.getDate()}{' '}
          {OPERATIONS_MONTHS_REDUCED_RU[range[0].startDate.getMonth()]}{' '}
          {range[0].startDate.getFullYear()}
        </div>
        <div className="calendar-range-picker__chosen-date">
          {WEEK_DAYS_LOWERCASE_RU[range[0].endDate.getDay() - 1]}, {range[0].endDate.getDate()}{' '}
          {OPERATIONS_MONTHS_REDUCED_RU[range[0].endDate.getMonth()]}{' '}
          {range[0].endDate.getFullYear()}
        </div>
      </div>
    </div>
  );
});

MobileCalendarRangePicker.propTypes = {
  className: PropTypes.string,
  range: PropTypes.arrayOf(
    PropTypes.shape({
      startDate: PropTypes.instanceOf(Date),
      endDate: PropTypes.instanceOf(Date),
      key: PropTypes.string,
    }),
  ).isRequired,
  onChange: PropTypes.func.isRequired,
};

export default MobileCalendarRangePicker;
